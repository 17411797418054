<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
        <div class="rigt-part">
        <h3>Job Post</h3>
        <div class="gt-started-frm">
        <h4>Account info</h4>
        <div class="rw-gap"> 
        <div class="tw-clm"><label>Your Name *</label> <input type="text" placeholder=""/></div> 
        <div class="tw-clm"><label>Your Company Name *</label> <input type="text" placeholder=""/> </div>
        </div>

        <div class="rw-gap"> 
        <div class="tw-clm"><label>Phone Number *</label> <input type="text" placeholder=""/> </div> 
        <div class="tw-clm"><label>What is the schedule for this job?*</label>  <select><option value="Evening shift">Evening shift </option></select> </div>
        </div>

        <h4>Salary & benefits</h4>
        <div class="rw-gap">
        <div class="three-clm to-text"><input type="text" placeholder="Dollar: Example 5000"></div>
        <div class="three-clm"><input type="text" placeholder="Dollar: Example 8000"></div>
        <div class="three-clm"><select><option value="per month">Per Month</option></select></div>
        </div>

        <h4>Application Settings </h4>
        <div class="rw-gap"> 
        <div class="tw-clm"><label>How Do You Want To Receive Applications?</label> <input type="email" placeholder="Email"/> </div> 
        <div class="tw-clm"><label>Do You Want Applicants To Submit A Resume?</label> <select><option value="Yes">Yes</option></select> </div> 
        </div>
        <div class="rw-gap"> 
        <label>Daily Updates About This Job And candidates will be sent to:</label>
        <input type="text" placeholder="hello.leader@gmail.com">
        </div>
        <div class="rw-gap"> 
        <label>Add Addition Email</label>
        <label class="input"><input type="checkbox"> Also send an individual email update every time a new candidate applies</label>
        </div>
        <div class="rw-gap"> 
        <h4>Job description *</h4>
        <textarea></textarea>
        </div>
        <input type="button" class="back-btn" value="Back"> <input type="submit" value="CONTINUE">
        </div>
        </div>
</div>
</div>
</template>


<script>
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
export default {
name: 'DashboardAccountinfo',
components:{
Dashboardheader,
Dashboardsidebar
}
}
</script>