<template>
  <div class="gt-started-frm tabinfo" id="general_info" v-if="firstShow">
        <h4 >
            Have you completed EP Training Level ? 
        </h4>
        <span >
            <button @click="epLevelClick('yes')" type="submit" class="save_btn" :disabled="buttonclick">Yes</button>
            <button @click="epLevelClick('no')" type="submit" class="login_btn" :disabled="buttonclick">No</button> 
        </span>
  </div>
  <div class="gt-started-frm tabinfo" id="general_info" v-if="doYouEpLevels=='no'">
        <h4 >
            Do you want to join EP Training ? 
        </h4>
        <span >
            <a href="https://www.executiveprotectiontrainingday.com/eptd-masters-enrollment1635187064705">
            <button type="submit" class="save_btn" >Click to Join EP Training</button>
            </a>
            
        </span>
  </div>
  <div class="gt-started-frm tabinfo" id="general_info" v-if="doYouEpLevels=='yes'" style="min-height:800px;">
      <h4 >
            Training Level Details
        </h4>
        <!-- <div v-if="resmessage != ''" class="success">{{ resmessage }}</div>
        <div v-if="errormessage != ''" class="error">{{ errormessage }}</div> -->
        <form id="jobpost-form" v-on:submit.prevent="updateLevelDetails" enctype="multipart/form-data">
        
        <div class="bottom_cd" style="width: 100% !important;display: flex;">
          <div class="cards">
              <label for="" > Select Certificate Level : </label>
              <div class="card" style="background: rgb(0, 117, 178);"  v-bind:class="{ active: isActive=='level1' }">
                <label for="level1" style="cursor:pointer;">
                  <input v-on:click="isActiveFunction('level1')" v-model="state.level" type="radio" name="level" id="level1" value="level1" style="display:;position: absolute;top: 10px;right: 10px;"/>
                  <div class="blog_wpr" style="padding: 10px;">
                    <div class="thirty-three-text" >
                        <h3>{{levelList[0].level}}</h3>  
                        <h5>{{levelList[0].title}}</h5>  
                        <p v-on:click="levelList[0].showDiv = !levelList[0].showDiv" class="tt-u sub-text" style="cursor:pointer;color:red;font-size:20px;margin-bottom:10px">
                            
                            <strong style="color:white;float:right;font-size: 10px;" v-show="levelList[0].showDiv==false || levelList[0].showDiv==null" >View More <i class="fas fa-caret-down"></i></strong>
                            <strong style="color:white;float:right;    font-size: 10px;" v-show="levelList[0].showDiv" >View Less <i class="fas fa-caret-up"></i></strong>
                        </p>
                        <p v-show="levelList[0].showDiv" class="tt-u sub-text" style="color:white;">
                          <span style="font-size:15px;color:white;font-weight:bold;">{{levelList[0].subtitle}}</span><br>
                          <span style="font-size:11px;color:white;" v-html="levelList[0].details"></span>
                          <!-- <span style="font-size:10px;color:white;">1. 20+ Hours: Beginner To Advanced Skills</span><br>
                          <span style="font-size:10px;color:white;">2. Certification: Provided Upon Completion</span> -->
                       </p> 
                    </div>
                  </div>
                </label>
              </div>
              
              <div class="card" style="background: rgb(240, 119, 49);" v-bind:class="{ active: isActive=='level2' }">
                <label for="level2" style="cursor:pointer;">
                  <input v-on:click="isActiveFunction('level2')" type="radio" v-model="state.level" name="level" id="level2" value="level2" style="display:;position: absolute;top: 10px;right: 10px;"/>
                  <div class="blog_wpr" style="padding: 10px;">
                    <div class="thirty-three-text" >
                        <h3 >{{levelList[1].level}}</h3>  
                        <h5>{{levelList[1].title}}</h5>

                        <p v-on:click="levelList[1].showDiv = !levelList[1].showDiv" class="tt-u sub-text" style="cursor:pointer;color:red;font-size:20px;margin-bottom:10px">
                            
                            <strong style="color:white;float:right;font-size: 10px;" v-show="levelList[1].showDiv==false || levelList[1].showDiv==null" >View More <i class="fas fa-caret-down"></i></strong>
                            <strong style="color:white;float:right;font-size: 10px;" v-show="levelList[1].showDiv" >View Less <i class="fas fa-caret-up"></i></strong>
                        </p>
                        <p v-show="levelList[1].showDiv" class="tt-u sub-text" style="color:white;">
                          <span style="font-size:15px;color:white;font-weight:bold;">{{levelList[1].subtitle}}</span><br>
                           <span style="font-size:11px;color:white;" v-html="levelList[1].details"></span>
                          
                       </p> 
                    </div>
                </div>
                </label>
              </div>
              
              <div class="card" style="background: rgb(237, 30, 12);"  v-bind:class="{ active: isActive=='level3' }">
                <label for="level3" style="cursor:pointer;">
                  <input v-on:click="isActiveFunction('level3')" type="radio" v-model="state.level" name="level" id="level3" value="level3" style="display:;position: absolute;top: 10px;right: 10px;"/>
                  <div class="blog_wpr" style="padding: 10px;">
                    <div class="thirty-three-text" >
                        <h3>{{levelList[2].level}}</h3> 
                        <h5>{{levelList[2].title}}</h5>   
                        <p v-on:click="levelList[2].showDiv = !levelList[2].showDiv" class="tt-u sub-text" style="cursor:pointer;color:red;font-size:20px;margin-bottom:10px">
                            
                            <strong style="color:white;float:right;font-size: 10px;" v-show="levelList[2].showDiv==false || levelList[2].showDiv==null" >View More <i class="fas fa-caret-down"></i></strong>
                            <strong style="color:white;float:right;font-size: 10px;" v-show="levelList[2].showDiv" >View Less <i class="fas fa-caret-up"></i></strong>
                        </p>
                        <p v-show="levelList[2].showDiv" class="tt-u sub-text" style="color:white;"> 
                          <span style="font-size:15px;color:white;font-weight:bold;">{{levelList[2].subtitle}}</span><br>
                          <span style="font-size:11px;color:white;" v-html="levelList[2].details"></span>
                        </p> 
                    </div>
                  </div>
                </label>
              </div>
              <br/>
              
          </div>
        
        </div>
          <span v-if="v$.level.$error">
                {{ v$.level.$errors[0].$message }}
              </span>
          <ul class="general_information" v-if="userType == 3">
              <li>
                  <label>Certificate Name :</label>
                  <input type="" style="width:100%;color:#818182;" v-model="state.certificateName" name="certificateName" id="certificateName" placeholder="Certificate Name" />
                  <span v-if="v$.certificateName.$error">
                    {{ v$.certificateName.$errors[0].$message }}
                  </span>
              </li>
              
              <li>
                  <label>From Date:</label>
                  <datepicker placeholder="Enter Job Start Date" v-model="state.startDate" :upperLimit="new Date()"/>
                  <span v-if="v$.startDate.$error">
                    {{ v$.startDate.$errors[0].$message }}
                  </span>
              </li>
              
              <li v-if = "certificateImage!=''">
                  <label>Certificate Image :</label>
                  <!-- <img class="logo" v-bind:src="imagepath+'levels/'+certificateImage" /> -->
                  <div class="imgWpr" >
                    <img v-bind:src="imagepath+'levels/'+certificateImage" />
                    <a @click.prevent="removeimage('levels')" class="removeicon"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </div>
              </li>
              <li v-else>
                  <label>Certificate Image :</label>
                  <input
                      type="file"
                      name="certificateImage"
                      class="hlffile"
                      placeholder="Certificate Image"
                      style="width: 100%;"
                      ref="certificateImage" @input="certificateImagess" id="certificateImage" accept="image/*"
                  >
                  
              </li>
              <li>
                  <label>to Date:</label>
                  <datepicker placeholder="Enter Job Start Date" v-model="state.toDate"/>
                  <span v-if="v$.toDate.$error">
                    {{ v$.toDate.$errors[0].$message }}
                  </span>
              </li>
          </ul>
          <button type="submit" class="save_btn" :disabled="buttonclick">
              <span v-if="buttonclick == true" style="color: white"
              >Updating. . . . .</span
              >
              <span v-else style="color: white"> Update</span>
          </button>
          
          <button type="button" @click="$router.go(-1)" class="login_btn">
              Cancel
          </button>
      </form>
  </div>
</template>
<script>
</script>

<script>
//import { ref } from 'vue';
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../config";
import { API_BASE_URL } from "../config";
import { API_IMAGE_PATH } from "../config";
import { API_IMAGE_PATH3 } from "../config";
import { reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { createLocal } from "the-storages";
import Datepicker from "vue3-datepicker";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const mirror = createLocal();
const storage = mirror._prx;

//let imageFile = ref('');
//let imageUrl = ref('');
export default {
  name: "EpA",
  components: {
    Datepicker,
  },
  setup() {
    const state = reactive({
      level:"",
      certificateName: "",
      startDate:"",
      toDate: "",
      certificateImage:""
     
    });
    const rules = computed(() => {
      return {
        level:{ required },
        certificateName: { required },
        startDate: { required },
        toDate: { required },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      v$,
    };
  },
  data() {
    return {
      startDate: this.state.startDate,
      toDate: this.state.toDate,
      errors: [],
      errormessage: "",
      resmessage: "",
      storageData: mirror,
      storage: storage,
      errorStr: null,
      imagepath: {},
      showDiv:false,
      doYouEpLevels:'',
      isActive: '',
      userNewId:'',
      firstShow:true,
      levelList:[],
    };
  },

  async created() {
    this.userNewId = mirror.AUTHID;
    const responseLevel = await axios.get(API_BASE_URL + "levelList");
    this.levelList = responseLevel.data;

    // console.log("1. ",this.state.certificateName);
    // console.log("2. ",this.certificateImage);
    // console.log("3. ",this.state.startDate);
    // console.log("4. ",this.state.toDate);
    // console.log("5. ",this.state.level);

    this.imagepath = API_IMAGE_PATH3;
    this.userType = mirror.USERTYPE;
    this.membershipType = mirror.MEMBERSHIP_TYPE;
    this.getUserDetails();
    this.getUserLevelDetails();
    

    //this.specialisties=userData.data.specialisties
  },

  methods: {
    async getUserLevelDetails(){
      let levelDetails = await axios.post(API_BASE_URL + "getLevelById",{"userId":this.userNewId});
  
      console.log("Level Details: ",levelDetails.data)
      this.state.certificateName = levelDetails.data.certificateName;
      this.certificateImage = (levelDetails.data.certificateImage)? levelDetails.data.certificateImage : '';
      console.log("IMAGE: ",this.certificateImage);
      if(levelDetails.data.startDate){
        this.state.startDate = new Date(levelDetails.data.startDate);
      } else {
        this.state.startDate = new Date();
      }

      if(levelDetails.data.endDate){
        this.state.toDate = new Date(levelDetails.data.endDate);
      } else {
        this.state.toDate = new Date();
      }
      //this.state.endDate = levelDetails.data.endDate;
      
      this.state.level = levelDetails.data.levelName;
    },
      isActiveFunction(value){
        if(value=='level1'){
            this.isActive = 'level1';
        } else if(value=='level2') {
            this.isActive = 'level2';
        } else if(value=='level3') {
            this.isActive = 'level3';
        }
      },
      epLevelClick(value){
        this.firstShow = false;
          if(value=='yes'){
              this.doYouEpLevels = value;
              
          } else {
              this.doYouEpLevels = value;
          }
          
          //console.log("EP LEVELS CLICK: ",this.doYouEpLevels);
      },
    async getUserDetails() {
      const userData = await axios.post(API_BASE_URL + "userDetails", {
        userId: mirror.AUTHID,
      });
      console.log("User Details:", userData);
      console.log("DOB", userData.data.dob);
      this.state.firstName = userData.data.fname;
      this.state.lastName = userData.data.lname;
      this.state.email = userData.data.email;
      this.state.number = userData.data.phone_no;
      //this.state.dob        =  userData.data.dob;
      this.state.designation = userData.data.designation;
      this.state.stateName = userData.data.state;
      this.state.country = userData.data.country.id;
      this.state.biography = userData.data.biography;
      this.state.profileImage = userData.data.profile_image;
      this.state.bannerImage = userData.data.banner_image;
      this.state.companyLogoUpload = userData.data.logo;
      this.state.uploadCv = userData.data.cv_image;

      if(userData.data.IsEProtectionSchool=='yes'){
        this.schooldivShow = true;
        this.state.IsEProtectionSchool = userData.data.IsEProtectionSchool;
        this.state.epSchoolCertificate = userData.data.epCertificate;
      }
      
      // this.picked=new Date(userData.data.dob);
      if (userData.data.profile_image != "") {
        this.profilepath = API_IMAGE_PATH + userData.data.profile_image;
      }
      if (userData.data.banner_image != "") {
        this.bannerpath = API_IMAGE_PATH + userData.data.banner_image;
      }
      if (userData.data.cv_image != "") {
        this.cvPath = API_IMAGE_PATH + userData.data.cv_image;
      }
      if (userData.data.driving_certificate != "") {
        this.drivingpath = API_IMAGE_PATH + userData.data.driving_certificate;
      }
      if (userData.data.medical_certificate != "") {
        this.medicalpath = API_IMAGE_PATH + userData.data.medical_certificate;
      }
      if (userData.data.logo != "") {
        this.logopath = API_IMAGE_PATH + userData.data.logo;
      }
      this.state.dob = new Date(userData.data.dob);
      this.shortnote = userData.data.shortnote;
      if (
        userData.data.specialisties != "" &&
        userData.data.specialisties != null
      ) {
        this.specialisties = JSON.parse(userData.data.specialisties);
      }

      this.state.levelType = userData.data.levelType;
      this.state.protectionSchool = userData.data.protectionSchool;
      
      
      this.state.experience = userData.data.experience;
      this.totalPoints = userData.data.totalPoints;

      if (this.state.drivingCourse == "Yes") {
        this.drivingStatus(userData.data.drivingCourse);
        this.state.drivingCourse = userData.data.drivingCourse;
        this.drivingShow = true;
      }
      if (this.state.medical == "Yes") {
        this.medicalStatus(userData.data.medical);
        this.state.medical = userData.data.medical;
        this.medicalShow = true;
      }

      this.state.drivingIssueDate = new Date(userData.data.driving_issue_date);
      this.state.drivingExpiryDate = new Date(
        userData.data.driving_expiry_date
      );
      this.state.medicalIssueDate = new Date(userData.data.medical_issue_date);
      this.state.medicalExpiryDate = new Date(
        userData.data.medical_expiry_date
      );

      console.log("MEDICAL", this.state.medicalDocument);
      console.log("DRIVING", this.state.drivingDocument);
    },
    async updateLevelDetails() {
      
      this.buttonclick = true;
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      this.v$.$validate(this.v$.$error);

      //console.log("DATASS: ", this.state);

      //console.log("Image : ",this.state.certificateImage);
     
      if (!this.v$.$error) {
        let formData = new FormData();
        
        if (this.state.certificateImage != "")
          formData.append("certificateImage", this.state.certificateImage);
        formData.append("userId", this.userNewId);
        formData.append("level", this.state.level);
        formData.append("certificateName", this.state.certificateName);

        formData.append("startDate", moment(this.state.startDate).format("YYYY-MM-DD"));
        formData.append("toDate", moment(this.state.toDate).format("YYYY-MM-DD"));

        console.log("1.",this.state.certificateImage);
        console.log("1.",this.userNewId);
        console.log("1.",this.state.level);
        console.log("1.",this.state.certificateName);
        console.log("1.",this.state.startDate);
        console.log("1.",this.state.toDate);

        if (
          (this.state.certificateImage || this.certificateImage!='')&&
          this.state.level &&
          this.state.startDate &&
          this.state.toDate &&
          this.state.certificateName 
        ) {

          //alert("hii");
          //console.log("33");
          axios.get(BASE_URL + "sanctum/csrf-cookie").then((responses) => {
            axios
              .post(API_BASE_URL + "edit-levels", formData, config)
              .then((response) => {
                console.log(response);
                if (response.data.result == "success") {
                  this.errormessage = "";
                  this.resmessage = response.data.message;
                  //this.$router.push({ name: "JobList" });
                  this.getUserLevelDetails();
                  
                  this.buttonclick = false;
                  this.showToast("Success",this.message,"success"); 
                  //this.certificateImage = levelDetails.data.certificateImage;
                } else {
                  this.errormessage = response.data.message;
                  this.resmessage = "";
                  this.buttonclick = false;
                  this.showToast("Failed",this.errormessage,"danger"); 
                }
                var element = document.getElementById("general_info");
                element.scrollIntoView();
              })
              .catch((error) => {
                //console.log('error-data:', error)
                console.error(error);
              });
            console.log(responses);
          });
        } else {
          this.errormessage = "All Fields are Mandatory";
          this.buttonclick = false;
          this.showToast("Warning!",this.errormessage,"warning"); 
        }
      } else {
        this.errormessage = "All Fields are Mandatory";
        this.buttonclick = false;
        this.showToast("Warning!",this.errormessage,"warning"); 
      }
      // setTimeout(() => {
      //     this.errormessage = '';
      //     this.resmessage = '';
      //     console.log(this.errormessage);
      // }, 5000);
      //this.getUserDetails();
    },
    certificateImagess(e) {
      const file = e.target.files[0]
      this.state.certificateImage = file
      this.logo = URL.createObjectURL(file);
      console.log("image1",this.logo)
    },
    removeimage(type) {
      axios.get(BASE_URL + "sanctum/csrf-cookie").then((responses) => {
        const inputData = {
          userId: mirror.AUTHID,
          imageType: type,
        };
        axios
          .post(API_BASE_URL + "user/removeuserimage", inputData)
          .then((response) => {
            if (response.data.result == "success") {
              console.log("data:", response);
              if (type == "levels") {
                this.certificateImage = "";
                this.profilepath = "";
                this.state.certificateImage="";
                this.getUserLevelDetails();
              }
              
              this.errormessage = "";
              //this.message = response.data.messase;
              //this.showToast("Success",this.message,"success"); 
              //this.$router.push({ name: "JobList" });
            } else {
              this.errormessage = response.data.message;
              //this.message = "";
              //this.showToast("Failed",this.errormessage,"danger"); 
            }
          })
          .catch((error) => {
            //console.log('error-data:', error)
            console.error(error);
          });
        console.log("responses", responses);
      });
    },
    showToast(title,description,type){
      createToast({
          title: title,
          description: description
      },
      {
          type: type,
          position: 'bottom-right',
          // hideProgressBar: 'true',
          // toastBackgroundColor: color,
      })
  },
  },
};
</script>
<style scoped>
.success {
    padding: 10px 20px 10px 45px !important;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    position: relative;
    background: green;
}
.error {
    padding: 10px 20px 10px 45px !important;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    position: relative;
    background: #dd1e1e;
}
.dropdown dd, .dropdown dt {
    margin:0px;
    padding:0px;
}
.dropdown ul {
    margin: -1px 0 0 0;
}
.dropdown dd {
    position:relative;
}
.dropdown a, 
.dropdown a:visited {
    color:#fff;
    text-decoration:none;
    outline:none;
    font-size: 12px;
}
.dropdown dt a {
    background-color:#212122;
    display:block;
    padding: 5px 5px 5px 20px;
    min-height: 25px;
    line-height: 24px;
    overflow: hidden;
    border:0;
    width:100%;
}
.dropdown dt a span, .multiSel span {
    cursor:pointer;
    display:inline-block;
    padding: 0 3px 2px 0;
}
.dropdown dd ul {
    background-color: #212122;
    border:0;
    color:#fff;
    /* display:none; */
    left:0px;
    padding: 2px 15px 2px 5px;
    position:absolute;
    top:2px;
    width:100%;
    list-style:none;
    max-height: 190px;
    overflow: auto;
    z-index:1;
    border-radius: 10px;
}
.dropdown span.value {
    display:none;
}
.dropdown dd ul li a {
    padding:5px;
    display:block;
}
.dropdown dd ul li a:hover {
    background-color:#fff;
}

/* /////////////////////////// */
span {
  color: red;
}
.general_information {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.general_information li {
  padding: 5px 15px 10px;
  width: 50%;
}
.general_information li.w-100 {
  width: 100%;
}
.general_information li.w-33 {
  width: 33.333%;
}
.general_information li {
  display: block;
}
.general_information li input,
.general_information li select {
  padding: 10px 15px !important;
  margin: 3px 0 0 !important;
  background: #212122;
  border: 1px solid #3d3d3d;
  /* width: 100%; */
}
.general_information li textarea {
  padding: 10px 15px !important;
  margin: 3px 0 0 !important;
  background: #212122;
  border: 1px solid #3d3d3d;
  width: 100%;
}
.general_information li .imgWpr {
  position: relative;
}
.general_information li .imgWpr img {
  width: 100%;
  /* height: 200px; */
  object-fit: cover;
}
.general_information li .imgWpr .removeicon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c71e1e;
  color: #fff;
  font-size: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.specialisties {
  margin-top: 5px;
}
.specialisties li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}
.specialisties li input {
  flex: 1 1 auto;
  width: auto;
  margin: 0 !important;
  color: #d9d9d9;
  height: 45px;
  background: #212122;
  padding: 0 15px;
  border: 1px solid #3d3d3d;
}
.specialisties li .remove_btn {
  padding: 5px 13px;
  margin-left: 5px;
  background: #c71e1e;
  color: #fff;
  font-size: 18px;
}
button.save_btn {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #c71e1e;
  background: #c71e1e;
  text-transform: uppercase;
  font-family: "Play", sans-serif;
  cursor: pointer;
  margin-top: 15px;
}
@media (max-width: 991px) {
  .general_information li .imgWpr img {
    /* height: 150px; */
  }
}
@media (max-width: 575px) {
  .general_information li,
  .general_information li.w-33 {
    width: 100%;
  }
}
</style>

<style scoped>
/* Cosmetics styles */ 
body {
  margin: .5em;
  background: lightgrey;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 1 0 20%;
  position: relative;
  margin: .5em;
  padding: 0em;
  min-height: 4em;
  /* background: white; */
  /* border: 3px solid grey; */
  border-radius: 5px;
  width: 278px;
}

.active { border-color: orangered; }
input[type="text"] {
  position: absolute;
  top: .5em;
  left:.5em;
}

/* This is where the magic happens */
input[type="checkbox"] {
  position: absolute;
  top: .5em;
  left:.5em;
}



@media (pointer: coarse) { 
  input[type="checkbox"] {
    height: 2em;
    width: 2em;
  }
}

/* Use z-index to make it accessible to keyboard navigation */
@media (hover: hover) {
              input[type="checkbox"] { z-index: -1 }
  .card:hover input[type="checkbox"],
              input[type="checkbox"]:focus,
              input[type="checkbox"]:checked { z-index: auto }
}
</style>
<style scoped>
    .general_information input[type="text"] {
      width: 100%;
      background: #212122;
      border: solid 1px #3d3d3d;
      height: 45px;
      font-size: 14px;
      line-height: 45px;
      margin: 3px 0 0;
      color: #818182;
      padding: 10px;
  }
    .mb {
        margin-bottom:65px;
    }
    .new_submit{
        background: #c71e1e;
        border: 0;
        font-size: 11px;
        line-height: 14px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        padding: 6px 8px;
        margin: 2px;
    }
    .sign_submit {
        width: 100%;
        height: 55px;
        background: black;
        border-radius: 4px;
        border: 5px solid #cda53a;
        font-size: 19px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        cursor:pointer;
        margin-top: 15px;
        margin-bottom: 3px;
    }
    .thirty-three .ribbon-wrapper { 
        width: 88px; 
        height: 88px; 
        overflow: hidden; 
        float: right;
        /* position: absolute;
        
        top: 211px;
        
        margin-left: 368px; */
        }
    .thirty-three .ribbon-tag { 
        text-align: center; 
        -webkit-transform: rotate(45deg); 
        -moz-transform: rotate(45deg); 
        -ms-transform: rotate(45deg); 
        -o-transform: rotate(45deg); 
        position: relative; padding: 6px 0; 
        left: -4px; top: 15px; width: 120px; 
        color: #ffffff; -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3); 
        -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3); 
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3); 
        text-shadow: rgba(255,255,255,0.5) 0px 1px 0px; 
        background: #343434; 
        }

    .thirty-three .ribbon-tag:before, .ribbon-tag:after { content: ""; 
    border-top: 3px solid #50504f; border-left: 3px solid transparent; 
    border-right: 3px solid transparent; position:absolute; bottom: -3px;
    }
    .thirty-three .ribbon-tag:before { left: 0;}
    .thirty-three .ribbon-tag:after { right: 0;}
    .thirty-three .ribbon-tag { background: rgba(203,96,179,1); }
    .thirty-three .ribbon-tag { background: green; }

    /* p , ul{
        margin-left: 18px !important;
        list-style-type: disclosure-closed !important;
    }
    p , ul, li {
        margin-left: 18px !important;
        list-style-type: disclosure-closed !important;
    } */
    .pagination {
        cursor: pointer;
            justify-content: center;
            padding: 10px;
        }

        .pagination {
            margin-top: 0;
        }
    .pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width:100%;
    }
    .priodType {
        background: #202023;
        padding: 10px;
        margin-bottom: 15px;
        margin-top: 7px;
    }
    /* .active {
        
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    } */


    .pagination a.next {
        color: #d70b0b;
        border-color: #d70b0b;
        margin-left: 10px;
    }

    .pagination a.next:hover {
        background: #d70b0b;
        color: #fff;
    }
    .read_more {
        color: #939393;
        border: solid 1px #939393;
        padding: 7px 25px;
        text-decoration: none;
        display: inline-block;
        margin-top: 20px;
        font-size: 16px;
    }

    .read_more:hover {
        background: #cfcfcf;
        color: #000;
    }

    .joblist_Wpr{
    background: #1b1b1b;
    padding: 30px 60px;
    position: relative;
    }
    .joblist_Wpr:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-image: url('~@/assets/figure-bg.png');
    background-position: 100% 100%;
    background-repeat: no-repeat ;
    }
    .job_list > li{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    padding: 40px 0;
    position: relative;
    z-index: 2;
    }
    .job_list > li:last-child{
    border:0;
    }
    @media(max-width:991px){
    .joblist_Wpr{
        padding: 10px 20px;
    }
    .job_list > li{
        padding: 20px 0;
    }
    .modal_body{overflow-y: scroll;}
    .modal_body .form_grp .item_grp > div {
    margin:0 1px!important;
    }
    .general_information li .imgWpr .removeicon {
      width: 26px;
      height: 26px;
      font-size: 13px;
      right: 6px;
      top: 6px;
    }
    .card {
      flex: auto;
    }
    }

    @media(max-width:740px){
    .form_grp {
    width: 100% !important; float:none !important;
    }
    .modal_body .form_grp .item_grp > div {
    width: 100% !important;
    float: none !important;
    margin-left: 0 !important;
    }
    .modal_header, .modal_body{overflow-y: scroll;}
    }

    @media(max-width:575px){
    .srch-lft:first-of-type{
        width: 100%;
        order: 2;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }

    }
</style>