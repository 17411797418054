<template>
  <div class="dashboard_main">
    <Dashboardheader />
    <div class="middle-part dashboard">
      <Dashboardsidebar />
      <div class="rigt-part">
        <div class="content_wpr dshbrd">
          <div class="profile_body">
            <div class="profile_banner">
              <img
                v-bind:src="imagepath + details.banner_image"
                v-bind:alt="details.fname"
                v-if="details.banner_image"
              />
              <img src="@/assets/avatar.png" alt="" v-else />
              <div class="profile_pic">
                <img
                  v-bind:src="imagepath + details.profile_image"
                  v-bind:alt="details.fname"
                  v-if="details.profile_image"
                />
                <img src="@/assets/avatar.png" alt="" v-else />
              </div>
              <router-link to="/settings" class="view_btn"
                ><button type="button"><i class="fas fa-camera"></i></button
              ></router-link>
              <div class="tag-lift" v-if="level!=''">
                <div class="badge sky" v-if="level=='level1' || level=='level2' || level=='level3' ">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="50px" height="75px" viewBox="0 0 216 232">
                    <path fill="#2B2B2B" d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z"/>
                  </svg>
                  <p class="title sky" style="background: rgb(0, 117, 178);border-bottom: 6px solid #947f08;padding: 0px;">Level
                    <!-- <br><span style="font-size:14px;">{{levelList[0].title}}</span> -->
                  </p>
                  <p class="subtitle">1</p>
                </div>
                <div class="badge orange"  v-if="level=='level2' || level=='level3'">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="50px;" height="75px" viewBox="0 0 216 232">
                    <path fill="#2B2B2B" d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z"/>
                  </svg>
                  <p class="title orange" style="background:rgb(240, 119, 49);border-bottom: 6px solid #947f08;padding: 0px;">Level
                    <!-- <br><span style="font-size:14px;">{{levelList[1].title}}</span> -->
                  </p>
                  <p class="subtitle">2</p>
                  
                </div>
                <div class="badge red" v-if="level=='level3'">
                  <svg style="" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="50px" height="75px" viewBox="0 0 216 232">
                    <path fill="#2B2B2B" d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z"/>
                  </svg>
                  <p class="title red" style="background: rgb(237, 30, 12);border-bottom: 6px solid #947f08;padding: 0px;">Level
                    <!-- <br><span style="font-size:14px;">{{levelList[2].title}}</span> -->
                  </p>
                  <p class="subtitle">3</p>
                </div>
              </div>
            </div>
            <div class="progressbar-align" >
              <!-- <div class="progressbar-align" v-if="userType == 3"> -->
              
              <div class="profile-prgrs" v-if="details.totalPoints>0"> 
                <div :style="{ background: activeColor, width: details.totalPoints + '%' }"></div>
                <div class="text-ade">{{details.totalPoints}}% Profile Completed</div>
              </div >
              <div class="profile-prgrs" v-else style="background:#202023;"> </div>

            </div>
            <!-- <div v-if="userType == 3" class="profile-prgrs">
              <div
                :style="{
                  background: activeColor,
                  width: details.totalPoints + '%',
                }"
              >
                {{ details.totalPoints }}% Profile Completed
              </div>
            </div> -->
            <div class="profile_info">
              <!-- <div class="button_wpr">
               <br /><br />
              </div> -->
              <h2>
                {{ details.fname }} {{ details.lname }} ( 000{{(details.id==19)? "0":details.id}} )
                <!-- <span><i class="fas fa-star"></i> Verified</span> -->
                <span v-if="details.totalPoints == '100'"
                  ><i class="fas fa-star"></i> Verified</span
                >
                <span style="margin-left: 7px !important"
                  ><i class="fas fa-certificate"></i>
                  {{ details.membershipType }}</span
                >
                <span
                  style="margin-left: 7px !important"
                  v-if="details.is_email_verify == 'yes'"
                  ><i class="fas fa-check-circle"></i> Email Verified</span
                >
                <span style="margin-left: 7px !important" v-else
                  ><i class="fas fa-times-circle"></i> Email Not Verify</span
                >
              </h2>
              <p v-if="details.shortnote!='' && details.shortnote!='null'">{{ details.shortnote }}</p>
              <div class="extra_info">
                {{ countryname }} . {{ details.state }}
                <!--<span>Contact info</span>-->
              </div>
              <div class="extra_info">
                <span style="color: #cda53a"><i style="color:#fff;background: #c8a139;padding: 5px;border-radius: 50%;" class="fas fa-envelope"></i>  {{ details.email }}</span
                ><span style="color: #cda53a"><i style="color:#fff;background: #c8a139;padding: 5px;border-radius: 50%;" class="fas fa-phone"></i>  {{ details.phone_no }}</span>
              </div>
              <!-- <div class="extra_info">
                                {{details.country.nicename}} . {{details.state}} <span>Contact info</span>
                            </div> -->
            </div>
            <div class="about">
              <h1>About Us</h1>
              <span style="color:#999;" v-html="details.biography"></span>
            </div>

            <div class="about" v-if="userType == 3" style="margin-bottom: 20px">
              <h1>Other Details</h1>
              <div class="main-details">
                <div class="fifty-rw">
                  <p>
                    Level : <strong>{{ details.levelType }}</strong>
                  </p>
                </div>
                <div class="fifty-rw">
                  <p>
                    Protection School :
                    <strong>{{ details.protectionSchool }} </strong>
                  </p>
                </div>
                <div class="fifty-rw">
                  <p>
                    Driving Course :
                    <strong>{{ details.drivingCourse }} </strong>
                  </p>
                </div>
                <div class="fifty-rw" v-if="details.drivingCourse == 'Yes'">
                  <p>
                    Issue & Expiry Date :
                    <strong
                      >{{ details.driving_issue_date }} &
                      {{ details.driving_expiry_date }}
                    </strong>
                  </p>
                </div>
                <div class="fifty-rw" v-if="details.drivingCourse == 'Yes'">
                  <p>Document : <strong></strong></p>
                </div>
                <div class="fifty-rw" v-if="details.drivingCourse == 'Yes'">
                  <p>
                    <strong v-if="details.driving_certificate"
                      >{{ details.driving_certificate }}
                      <span
                        @click="clickedDownload(details.driving_certificate)"
                        class="dwnld-btn-cv"
                        title="Click here to Download"
                        ><i class="fas fa-download"></i
                      ></span>
                    </strong>
                  </p>
                </div>
              </div>
              <div class="main-details">
                <div class="fifty-rw">
                  <p>
                    Medical Training : <strong>{{ details.medical }} </strong>
                  </p>
                </div>
                <div class="fifty-rw" v-if="details.medical == 'Yes'">
                  <p>
                    Issue & Expiry Date :
                    <strong
                      >{{ details.medical_issue_date }} &
                      {{ details.medical_expiry_date }}</strong
                    >
                  </p>
                </div>
                <div class="fifty-rw" v-if="details.medical == 'Yes'">
                  <p>Document : <strong></strong></p>
                </div>
                <div class="fifty-rw" v-if="details.medical == 'Yes'">
                  <p>
                    <!-- <strong>
                                            <a :href="imagepath+details.medical_certificate" class="dwnld-btn-cv" title="Click here to Download"><i class="fas fa-download"></i></a>
                                        </strong> -->
                    <strong v-if="details.medical_certificate">
                      {{ details.medical_certificate }}
                      <span
                        @click="clickedDownload(details.medical_certificate)"
                        class="dwnld-btn-cv"
                        title="Click here to Download"
                        ><i class="fas fa-download"></i
                      ></span>
                    </strong>
                  </p>
                </div>
              </div>
              <div class="main-details">
                <div class="fifty-rw">
                  <p>
                    Experience : <strong>{{ details.experience }} </strong>
                  </p>
                </div>
                <div class="fifty-rw">
                  <p>
                    Agent CV : {{ details.cv_image }}
                    <strong v-if="details.cv_image">
                      <span
                        @click="clickedDownload(details.cv_image)"
                        class="dwnld-btn-cv"
                        title="Click here to Download"
                        ><i class="fas fa-download"></i
                      ></span>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <!--<div class="other_card">
                            <div class="card_content">
                                <h3>Activity</h3>
                                <p>Posts Byron Rodgers created, shared, or commented on in the last 90 days are displayed here.</p>
                                <ul class="activity_list">
                                    <li>
                                        <img src="@/assets/activity1.jpg" alt="">
                                        <h5>Call Of Duty Back OPS Cold War</h5>
                                    </li>
                                </ul>
                            </div>
                            <router-link to="/" class="see_more">See All Activity</router-link>
                        </div>-->
            <div class="other_card" v-if="experienceList?.length > 0">
              <div class="card_content">
                <h3>
                  Experience
                  <!-- <router-link style="courser:pointer;" to="/settings" class="view_all">View All</router-link> -->
                </h3>
                <ul class="experience_list">
                  <li
                    v-bind:key="experience.id"
                    v-for="(experience, key) in experienceList"
                  >
                    <JobCard2 :experienceData="experience" :keys="key" />
                  </li>
                  <!-- <li>
                                        <JobCard2 title="Detail Leader" icon="icon1.png"/>
                                    </li>
                                    <li>
                                        <JobCard2 title="Tactical Commander" icon="icon1.png"/>
                                    </li>
                                    <li>
                                        <JobCard2 title="Assistant Detail Leader" icon="icon1.png"/>
                                    </li> -->
                </ul>
              </div>
            </div>
            <div class="other_card" v-if="specialistiesArray?.length > 0">
              <div class="card_content">
                <h3>Specialisties</h3>
                <ul class="specialist">
                  <li
                    v-bind:key="specialisties.value"
                    v-for="(specialisties, key) in specialistiesArray"
                  >
                    <span>{{ key + 1 }}</span>
                    {{ specialisties.value }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="gallery" v-if="gallerys?.length > 0">
              <h1>Gallery</h1>
              <vue-easy-lightbox
                :visible="visible"
                :imgs="imgs"
                :index="index"
                @hide="handleHide"
              >
              </vue-easy-lightbox>
              <ul>
                <li
                  :key="index"
                  v-for="(gallery, index) in gallerys"
                  class="pic"
                  @click="() => showImg(index)"
                  style="cursor: pointer"
                >
                  <img
                    v-bind:src="imagepath + 'gallery/' + gallery.photo"
                    v-bind:alt="gallery.photo"
                    v-if="gallery.photo"
                  />
                </li>
              </ul>
              <!-- <router-link to="/" class="view_all">View All</router-link> -->
            </div>
            <div class="gallery" v-if="videoList?.length > 0">
              <h1>Expertise Videos</h1>
              <ul class="videos">
                <li
                  v-for="video in videoList"
                  v-bind:key="video"
                  style="cursor: pointer"
                >
                  <img
                    v-if="video.type != 'upload'"
                    v-bind:src="video.videoimage"
                    v-bind:alt="video.value"
                    @click="
                      showVideo(video.type, video.video_file, video.embed_url)
                    "
                  />
                  <img
                    v-else
                    src="@/assets/thumb.png"
                    alt=""
                    @click="
                      showVideo(video.type, video.video_file, video.embed_url)
                    "
                  />
                  <button
                    type="button"
                    class="play_btn"
                    style="cursor: pointer"
                  >
                    <img
                      src="@/assets/play2.png"
                      alt=""
                      @click="
                        showVideo(video.type, video.video_file, video.embed_url)
                      "
                    />
                  </button>
                  <!-- <span class="duration">15:31</span> -->
                  <!-- <span  v-html="video.embed_url"></span> -->
                  <!-- <span v-if="video.type=='youtube' || video.type=='vimeo'" v-html="video.embed_url"></span>
                                    <span v-if="video.type=='upload'">
                                    <video width="580" controls>
                                        <source v-bind:src="imagepath+video.embed_url" type="video/mp4">
                                        Your browser does not support HTML video.
                                    </video>
                                    </span> -->
                </li>
                <!-- <li><img src="@/assets/poster2.jpg" alt=""><button type="button" class="play_btn"><img src="@/assets/play2.png" alt=""></button><span class="duration">12:04</span></li>
                                <li><img src="@/assets/poster1.jpg" alt=""><button type="button" class="play_btn"><img src="@/assets/play2.png" alt=""></button><span class="duration">15:31</span></li>
                                <li><img src="@/assets/poster3.jpg" alt=""><button type="button" class="play_btn"><img src="@/assets/play2.png" alt=""></button><span class="duration">23:21</span></li> -->
              </ul>
            </div>
            <div class="other_card" v-if="certificateList?.length > 0">
              <div class="card_content">
                <h3>Certificates</h3>
                <ul class="experience_list">
                  <li
                    v-bind:key="certificate.id"
                    v-for="(certificate, key) in certificateList"
                  >
                    <JobCard3 :certificate="certificate" :keys="key" />
                  </li>
                </ul>
                <!-- <ul class="interests_list">
                                    <li>
                                        <img src="@/assets/forbes.jpg" alt="">
                                        <div class="details">
                                            <h5>Forbes</h5>
                                            <h6>17,085,943 followers</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="@/assets/tory.jpg" alt="">
                                        <div class="details">
                                            <h5>Tory Burch</h5>
                                            <h6>Founder,  Designer &amp; Executive Chairman</h6>
                                            <h6>169,552 followers</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="@/assets/lifestyle.jpg" alt="">
                                        <div class="details">
                                            <h5>Lifestyle, Cooking</h5>
                                            <h6>64,713 followers</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="@/assets/bill.jpg" alt="">
                                        <div class="details">
                                            <h5>Forbes</h5>
                                            <h6>Co-chair, Bill &amp; Melinda Gates Foundation</h6>
                                            <h6>34,217,419 followers</h6>
                                        </div>
                                    </li>
                                </ul> -->
              </div>
              <!-- <router-link to="/settings" class="see_more">See All Certificates</router-link> -->
            </div>
          </div>
          <div class="sidebar">
            <div class="sidebar_poster">
              <div class="poster_cont">
                <img src="@/assets/poster-heading.png" alt="" />
                <div class="btn_wpr">
                  <img src="@/assets/training-heading2.png" alt="" />
                  <router-link to="/">Join Now</router-link>
                </div>
              </div>
            </div>
            <!--   <div class="know_people">
                            <h3>People You may Know</h3>
                            <ul>
                                <li><PeopleCard name="Matt Damon" rank="Commander" img="people1.jpg"/></li>
                                <li><PeopleCard name="Jack Nicholson" rank="Commander" img="people2.jpg"/></li>
                                <li><PeopleCard name="Hugh Jackman" rank="Commander" img="people3.jpg"/></li>
                                <li><PeopleCard name="Christian Bale" rank="Commander" img="people4.jpg"/></li>
                                <li><PeopleCard name="Oscar Isaac" rank="Commander" img="people5.jpg"/></li>
                                <li><PeopleCard name="Michele Morrone" rank="Commander" img="people6.jpg"/></li>
                            </ul>
                        </div>
                        <div class="know_people">
                            <h3>Learning</h3>
                            <p>Add new skills with these courses, free for 24 hours</p>
                            <ul class="skill_list">
                                <li>
                                    <div class="video"><img src="@/assets/skill1.jpg" alt=""><button type="button"><i class="fas fa-play"></i></button></div>
                                    <h5>Service Centered Executive Protection Agent</h5>
                                </li>
                                <li>
                                    <div class="video"><img src="@/assets/skill2.jpg" alt=""><button type="button"><i class="fas fa-play"></i></button></div>
                                    <h5>Service Centered Executive Protection Agent</h5>
                                </li>
                                <li>
                                    <div class="video"><img src="@/assets/skill3.jpg" alt=""><button type="button"><i class="fas fa-play"></i></button></div>
                                    <h5>Service Centered Executive Protection Agent</h5>
                                </li>
                            </ul>
                            <router-link to="/" class="see_more">See More Courses</router-link>
                        </div>
                    -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="playVideo">
      <div class="content_wrapper">
        <div class="vid_player">
          <button @click="playVideo = false">
            <i class="fas fa-times"></i>
          </button>
          <span
            v-if="type_popups == 'youtube' || type_popups == 'vimeo'"
            v-html="embed_url_popups"
          ></span>
          <span v-if="type_popups == 'upload'">
            <video width="580" controls>
              <source
                v-bind:src="imagepath + videoFilr_popups"
                type="video/mp4"
              />
              Your browser does not support HTML video.
            </video>
          </span>
          <!-- <iframe width="560" height="230" src="https://www.youtube.com/embed/46MegoVLEj8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboardheader from "../components/Dashboardheader.vue";
import Dashboardsidebar from "../components/Dashboardsidebar.vue";
import JobCard2 from "@/components/JobCard2.vue";
import JobCard3 from "@/components/JobCard3.vue";
//import PeopleCard from '@/components/PeopleCard'
import axios from "axios";
//import { BASE_URL } from '../config'
import { API_BASE_URL } from "../config";
import { API_IMAGE_PATH } from "../config";
//import moment from "moment";
import { createLocal } from "the-storages";
//const gallerys=getResults()
import VueEasyLightbox from "vue-easy-lightbox";
const mirror = createLocal();
const storage = mirror._prx;
export default {
  name: "DashboardProfile",
  components: {
    Dashboardheader,
    Dashboardsidebar,
    JobCard2,
    JobCard3,
    //PeopleCard,
    VueEasyLightbox,
  },
  data() {
    return {
      storageData: mirror,
      storage: storage,
      isLoading: true,
      imagepath: {},
      details: {},
      specialistiesArray: [],
      gallerys: [],
      videoList: [],
      experienceList: [],
      certificateList: [],
      index: 0,
      imgs: [],
      playVideo: false,
      countryname: "",
      visible: false,
      activeColor: "#3c3c3cc4",
      levelList:[],
      level:'',
    };
  },
  async created() {
    this.AuthUserId = mirror.AUTHID;
    this.userType = mirror.USERTYPE;
    //console.log(mirror);

    const param = {
      userId: mirror.AUTHID,
    };
    const response = await axios.post(API_BASE_URL + "userDetails", param);
    this.details = response.data;
    this.specialisties = response.data.specialisties;
    this.specialistiesArray = JSON.parse(this.specialisties);

    this.imagepath = API_IMAGE_PATH;
    this.countryname = this.details.country.nicename;

    const responseLevel = await axios.get(API_BASE_URL + "levelList");
    this.levelList = responseLevel.data;

    console.log("Level list",this.levelList);

    //const response2= await axios.post(API_BASE_URL + 'view_gallery',param);
    //this.galleryList = response2.data;

    const response3 = await axios.post(API_BASE_URL + "userVideoList", param);
    this.videoList = response3.data;
    //console.log("video list",response3);

    const response4 = await axios.post(API_BASE_URL + "experience4List", param);
    this.experienceList = response4.data;

    const response5 = await axios.post(
      API_BASE_URL + "certificate3List",
      param
    );
    this.certificateList = response5.data;

    console.log("certificateList:", response5.data);
    console.log("experienceList:", response4.data);

    this.activeColor = this.details.totalPoints <= 50 ? "red" : "green";

    //this.AuthUserId = mirror.AUTHID;
    let levelDetailss = await axios.post(API_BASE_URL + "getLevelById",{"userId":this.AuthUserId});
    this.level = levelDetailss.data.levelName;
    //console.log("LEVEL1:",levelDetailss.data);
    //console.log("LEVEL2:",this.level);

  },
  mounted() {
    this.getResults();
  },
  methods: {
    showVideo: function (type, video_file, embed_url) {
      this.playVideo = true;
      this.type_popups = type;
      this.videoFilr_popups = video_file;
      this.embed_url_popups = embed_url;
    },
    getResults() {
      axios
        .get(API_BASE_URL + "galleryimages/" + mirror.AUTHID)
        .then((response) => {
          this.gallerys = response.data;
          for (var i = 0; i < this.gallerys.length; i++) {
            this.imgs.push(
              API_IMAGE_PATH + "gallery/" + this.gallerys[i].photo
            );
          }
          this.imagepath = API_IMAGE_PATH;
          console.log(this.imgs);
        });
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    async clickedDownload(name) {
      let linkData = API_IMAGE_PATH + name; //'http://74.207.234.105:8080/img/logo.7b4b26fa.png';

      axios.get(linkData, { responseType: "blob" }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (storage.get("TOKEN")) {
      next();
    } else {
      next("/login");
    }
  },
};
</script>

<style scoped>
.sidebar_poster .poster_cont .btn_wpr a:hover  {
  background: #ffffff;
  color: #000000;
}

.sidebar_poster .poster_cont .btn_wpr a {
    transition: all 0.3s ease-in-out;
}
.progressbar-align {
  display: flex;
  justify-content: flex-end;
}
.profile-prgrs {
    float: none;
    background: #ccc;
    width: 250px;
    border-radius: 25px;
    margin: 60px 0 0;
    position: relative;
    height: 10px;
}
.profile-prgrs>div {
    background: #b81d1d;
    border-radius: 25px 25px 25px 25px;
    width: 50%;
    text-align: right;
    padding: 0;
    height: 100%;
}

.content_wpr {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.content_wpr.dshbrd {
  display: block;
}
.sidebar {
  /* width: 35%; */
  flex: 1 1 420px;
  padding: 0 25px;
}
.sidebar_poster {
  height: 400px;
  background: url("~@/assets/soldier2.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
}
.sidebar_poster .poster_cont {
  flex: 0 1 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.sidebar_poster .poster_cont .btn_wpr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.sidebar_poster .poster_cont > img {
  width: 100%;
  height: auto;
  padding-bottom: 7px;
}
.sidebar_poster .poster_cont .btn_wpr img {
  padding-right: 5%;
  width: 60%;
}
.sidebar_poster .poster_cont .btn_wpr a {
  width: 40%;
  border: 1px solid #fff;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 5px;
}
.sidebar .know_people {
  margin: 40px 0;
  background: #161818;
}
.sidebar .know_people h3 {
  font-size: 27px;
  line-height: 33px;
  font-weight: 800;
  color: #fff;
  padding: 30px 40px 0;
  text-transform: uppercase;
}
.sidebar .know_people p {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #d9d9d9;
  padding: 10px 40px 0;
}
.sidebar .know_people a {
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar .know_people ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 25px 40px 30px;
}
.sidebar .know_people ul li:nth-child(even) {
  background: rgba(255, 255, 255, 0.02);
}
.sidebar .know_people ul li:last-child {
  border: 0;
}
.sidebar .know_people .skill_list li {
  display: flex;
}
.sidebar .know_people .skill_list li .video {
  position: relative;
  width: 40%;
}
.sidebar .know_people .skill_list li .video button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.sidebar .know_people .skill_list li .video img {
  width: 100%;
  height: auto;
}
.sidebar .know_people .skill_list li h5 {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: #d9d9d9;
  text-transform: capitalize;
  padding-left: 15px;
  width: 60%;
}
.profile_body {
  /* width: 65%; */
  flex: 1 1 780px;
  padding: 0 25px;
}
.profile_banner {
  position: relative;
}
.profile_banner img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.profile_banner .profile_pic {
  position: absolute;
  left: 50px;
  bottom: 0px;
  transform: translateY(40%);
      border-radius: 50%;
    border: 8px solid #202023;
}
.profile_banner .profile_pic img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
.profile_banner button {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #d9d9d9;
  cursor: pointer;
}
.button_wpr {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
  margin: 0 -4px;
}
.button_wpr .btn {
  height: 37px;
  font-size: 15px;
  line-height: 21px;
  padding: 7px 15px;
  color: #d71111;
  text-transform: uppercase;
  border: 1px solid #d71111;
  font-family: "Play", sans-serif;
  font-weight: 400;
  letter-spacing: 1.5px;
  background: transparent;
  margin: 4px;
  cursor: pointer;
}
.button_wpr .btn i {
  margin-right: 6px;
}
.button_wpr .btn.message {
  color: #d9d9d9;
  border-color: #d9d9d9;
}
.button_wpr .btn.more {
  color: #888;
  border-color: #888;
}
.profile_info h2 {
  font-size: 34px;
  line-height: 40px;
  color: #fff;
  font-weight: 400;
}
.profile_info p {
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: #888;
  max-width: 805px;
}
.profile_info .extra_info {
  font-size: 19px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  font-family: "Play", sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 20px;
}
.profile_info .extra_info span {
  font-size: 16px;
  line-height: 22px;
  color: #d71111;
  display: inline-block;
  padding-left: 10px;
  margin-left: 15px;
  position: relative;
}
.profile_info .extra_info span:before {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  /* background: #d71111; */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
}

.profile-prgrs > div {
  background: #b81d1d;
  border-radius: 25px 25px 25px 25px;
  width: 50%;
  text-align: right;
  padding: 0 20px;
}
.profile-prgrs .text-ade{
  position: absolute;
  background: none;
  border-radius: 0;
  width: 100%;
  height: auto;
  text-align: left;
  padding: 0;
  line-height: 1;
  font-size: 12px;
  top: 14px;
}
.profile_info h2 span {
  font-size: 12px;
  background: #947f08;
  padding: 0 10px;
  border-radius: 15px;
  line-height: 24px;
  display: inline-block;
  margin-left: 40px;
}
.about h1 {
  font-size: 45px;
  line-height: 52px;
  color: #fff;
  font-weight: 400;
  font-family: "Play", sans-serif;
}
.about {
  overflow: hidden;
  padding: 40px 0;
  border-top: 1px solid #333;
  margin-top: 50px;
}
.about p {
  font-size: 18px;
  line-height: 25px;
  color: #999;
  font-weight: 400;
  font-family: "Play", sans-serif;
}
.other_card {
  background: #161618;
  margin-bottom: 40px;
}
.other_card .card_content {
  padding: 30px 45px 20px;
}
.other_card .card_content h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Play", sans-serif;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.other_card .card_content .view_all {
  height: 27px;
  font-size: 12px;
  line-height: 15px;
  padding: 6px 15px;
  color: #d9d9d9;
  text-transform: uppercase;
  border: 1px solid #666;
  font-family: "Play", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  background: transparent;
  cursor: pointer;
}
.other_card .card_content p {
  font-size: 20px;
  line-height: 27px;
  color: #999;
  font-weight: 400;
  font-family: "Play", sans-serif;
}
.other_card .card_content .activity_list,
.other_card .card_content .interests_list {
  padding: 15px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.other_card .card_content .activity_list li,
.other_card .card_content .interests_list li {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding: 15px 0;
}
.other_card .card_content .activity_list li img,
.other_card .card_content .interests_list li img {
  margin-right: 15px;
  max-width: 90px;
  height: auto;
}
.other_card .card_content .activity_list li h5 {
  font-size: 19px;
  line-height: 25px;
  color: #fff;
  font-weight: 400;
  font-family: "Play", sans-serif;
  max-width: 150px;
}
.other_card .card_content .interests_list h5 {
  font-size: 23px;
  line-height: 28px;
  color: #fff;
  font-weight: 400;
  font-family: "Play", sans-serif;
}
.other_card .card_content .interests_list h6 {
  font-size: 16px;
  line-height: 21px;
  color: #adadad;
  font-weight: 400;
  font-family: "Play", sans-serif;
  margin-top: 5px;
}
.other_card .card_content .specialist {
  padding: 20px 0;
}
.other_card .card_content .specialist li {
  font-size: 22px;
  line-height: 27px;
  color: #999;
  font-weight: 500;
  position: relative;
  padding: 10px 0 10px 40px;
}
.other_card .card_content .specialist li span {
  position: absolute;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  border: 1px solid #999;
  left: 0;
  top: 13px;
  border-radius: 50%;
}
.other_card .see_more {
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.gallery {
  margin-bottom: 40px;
}
.gallery h1 {
  font-size: 55px;
  line-height: 65px;
  color: #fff;
  font-weight: 400;
}
.gallery ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.gallery ul li {
  width: 25%;
  padding: 2px;
  line-height: 0;
}
.gallery ul li img {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
.gallery .view_all {
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  padding: 25px 10px;
  border-bottom: 1px solid #444;
  color: #fff;
}
.gallery .videos {
  display: flex;
  flex-wrap: wrap;
}
.gallery .videos li {
  width: 33.333%;
  padding: 3px;
  position: relative;
}
/*.gallery .videos li:first-child{
        width: 100%;
    }
    .gallery .videos li:first-child img{
        width: 100%;
        height: 500px;
        object-fit: cover;
    }*/
.gallery .videos li img {
  width: 100%;
  height: 190px;
  object-fit: cover;
}
.gallery .videos li .play_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
}
.gallery .videos li .play_btn img {
  max-width: 50px;
  height: auto;
}
/*.gallery .videos li:first-child .play_btn img{
        max-width: 100%;
    }*/
.gallery .videos li .duration {
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  padding: 4px 10px;
  position: absolute;
  right: 10px;
  bottom: 7px;
}
.gallery .videos li:first-child .duration {
  font-size: 14px;
  line-height: 17px;
  right: 15px;
  bottom: 10px;
}
@media (max-width: 991px) {
  .experience_list li {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .tag-lift[data-v-ea1d1ace] {
    position: static;
    float: none;
    display: flex;
    margin-top: 50px;
    justify-content: center;
  }
  .profile_body,
  .sidebar {
    padding: 0 10px;
  }
  .profile_info h2 {
    font-size: 28px;
    line-height: 35px;
  }
  .profile_info .extra_info {
    font-size: 17px;
    line-height: 21px;
  }
  .about h1,
  .gallery h1 {
    font-size: 26px;
    line-height: 2;
  }
  .about p {
    font-size: 16px;
    line-height: 23px;
  }
  .other_card .card_content h3 {
    font-size: 25px;
    line-height: 30px;
  }
  .profile_banner img {
    height: 300px;
  }
  .experience_list li {
    width: 50%;
  }
  .other_card .card_content {
    padding: 30px 30px 20px;
  }
  .other_card .card_content .specialist li {
    font-size: 18px;
    line-height: 24px;
    padding: 8px 0 8px 35px;
  }
  .other_card .card_content .specialist li span {
    width: 24px;
    height: 24px;
    font-size: 13px;
    line-height: 22px;
  }
  .gallery ul {
    margin-top: 15px;
  }
  .gallery ul li img {
    height: 100px;
  }
  .gallery .videos li img {
    height: 130px;
  }
  .profile_info .extra_info span {
    margin: 0;
  }
  .gallery ul li {
    width: 25% !important;
    float: none;
  }
  .profile_banner .profile_pic {
    position: absolute !important;
    left: 50% !important;
    bottom: 100px !important;
    transform: translatex(-50%) !important;
    /* height: 165px;width: 165px; */
  
  }
  .profile-prgrs {
    margin: 20px 0 0;
  }
  .progressbar-align {
    justify-content: center;
  }
  .profile_banner .profile_pic img {
    width: 120px;
    height: 120px;
}



}
@media (max-width: 575px) {
  .profile_body,
  .sidebar {
    padding: 0;
  }
  .profile_banner img {
    height: 200px;
  }
  .profile_banner .profile_pic img {
    width: 120px;
    height: 120px;
  }
  .gallery .videos li {
    width: 50%;
  }
  .gallery ul li {
    width: 33.333%;
  }
  .experience_list li {
    width: 100%;
  }
}
.green {
  color: green;
}
.red {
  color: red;
}

.profile_info .extra_info {
  display: block;
}
</style>
<style scoped>
/* @import "compass/css3"; */

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
.tag-lift{
    position: absolute;
    right: 20px;
    bottom: -55px;
    float: none;
}
.tag-lift .title{
  color: #fff;
}
.tag-lift .badge{
  margin: 6px !important;
}
.badge{
  position: relative;
  width: 50px;
  display: inline-block;
  margin: 10px;
  
}
.middle-part.dashboard .rigt-part p {
    padding-top: 13px;
}
.badge .title{
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    top: 0px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    width: 100%;
    background: red;
    padding: 20px 0;
    color:#fff;
    border-bottom: 4px solid #947f08 !important;
  }
  .profile_info {
    margin-top: 30px;
  }
.tag-lift .badge .subtitle{
    position: absolute;
    font-family: "Fjalla One", sans-serif;
    font-size: 16px;
    width: 100%;
    text-align: center;
    color: white;
    position: absolute;
    top: 20px;
    padding-top: 50px;
    font-weight: 600;
    /* &:after{
        content: "★ ★ ★";
        display: block;
        font-size: .4em;
        position: relative;
        margin: 15px 0 0;
        transition: all .3s;
      } */
  }
  /* &:hover .subtitle:after{
      word-spacing: 30px; 
  } */

/* //@mixin setColor($color){ */
  .badge .subtitle:after{
      color: white;
  }
/* //} */

.badge .title .red{
  /* color: #DC514E; */
  background: rgb(237, 30, 12);
}
.badge .title .sky {
  background: rgb(0, 117, 178);
}
.badge .title .orange {
  background:rgb(240, 119, 49)
}
.green{
  color: #2ecc71;
}

.gray{
 color: #95a5a6;
}

.yellow{
  color: #f1c40f;
}

.blue{
  color: #3498db;
}

</style>
<style>
.about table{
  margin: 10px 0;
    border:1px solid #bfbfbf;
    text-align: center;
    padding: 0 !important;
    border-collapse: collapse;
}
.about table *{
    padding: 0 !important;
}
.about table tr td{
    border-right:1px solid #bfbfbf;
    border-bottom:1px solid #bfbfbf;
    padding: 10px !important;
}
.experience_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.experience_list li {
  width: 33.333%;
  padding: 15px;
}
.vid_player {
  padding: 0;
  position: relative;
  line-height: 0;
}
.vid_player button {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 25px;
  height: 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #dd1e1e;
  cursor: pointer;
}
.vid_player button i {
  margin: 0;
}
.vid_player iframe {
  width: 100%;
  height: 336px;
}
.other_card .card_content .activity_list li .icon img {
  max-width: 90px;
  height: auto;
}
.other_card .card_content .activity_list li .job_info {
  padding-right: 15px;
}
.other_card .card_content .activity_list li .job_info h3 {
  line-height: 30px;
  font-weight: 400;
}
.other_card .card_content .activity_list li .job_info .role {
  flex-wrap: wrap;
  margin: 5px -10px;
}
.other_card .card_content .activity_list li .job_info .role li {
  color: #888;
  padding: 2px 10px;
}
.other_card .card_content .activity_list li .job_info .role li:after {
  top: 2px;
}

.fifty-rw {
  width: 50%;
  float: left;
}
.dwnld-btn-cv {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 7px 10px;
  color: #fff;
  border: 1px solid #c71e1e;
  background: #c71e1e;
  text-transform: uppercase;
  font-family: "Play", sans-serif;
  cursor: pointer;
  margin-left: 5px;
}
#google_translate_element {
  width: 300px;
  float: right;
  text-align: right;
  display: block;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}

</style>
