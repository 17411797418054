<template>
	<Navigation />
	<div class="training">
		<div class="container">
			<div class="training_form">
				<img src="@/assets/executive.png" alt="" draggable="false">
				<div class="training_logo">
				<img src="@/assets/training.png" alt="" draggable="false">
				</div>
				<div v-if="successmessage" class="success">{{successmessage}}</div>
				<div v-if="errormessage" class="error">{{errormessage}}</div>
				<form id="jobpost-form" v-on:submit.prevent="saveTrainingPost">
				<div class="form-group">
					
					<label>Name<span>*</span></label>
					<div class="d-flex">
						<div class="input_wpr">
							<input type="text" name="fName" placeholder="First Name" v-model="state.fName">
						</div>
						<div class="input_wpr">
							<input type="text" name="lName" placeholder="Last Name" v-model="state.lName">
						</div>
					</div>
					<span v-if="v$.fName.$error">
						{{ v$.fName.$errors[0].$message }}
					</span>
				</div>
				<div class="form-group">
					<label>Email Address<span>*</span></label>
					<div class="d-flex">
						<div class="input_wpr">
							<input type="email" name="email" placeholder="" v-model="state.email">
						</div>
					</div>
					<span v-if="v$.email.$error">
						{{ v$.email.$errors[0].$message }}
					</span>
				</div>
				<div class="form-group">
					<label>Phone Number<span>*</span></label>
					<div class="d-flex">
						<div class="input_wpr">
							<input type="tel" name="phoneNumber" placeholder="" v-model="state.phoneNumber" @input="acceptNumber">
						</div>
					</div>
					<span v-if="v$.phoneNumber.$error">
						{{ v$.phoneNumber.$errors[0].$message }}
					</span>
				</div>
				<div class="form-group">
					<label>Birthday<span>*</span></label>
					<div class="d-flex birthday">
						<div class="input_wpr">
							<select v-model="state.year" autocomplete="off" class="hlfinput"  @change="mydatelist()">
								<option value="">Year</option>
								<option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
							</select>
						</div>
						<div class="input_wpr">
							<select class="hlfinput"  v-model="state.month"  @change="mydatelist()">
								<option value="">Month</option>
								<option value="01">January</option>
								<option value="02">February</option>
								<option value="03">March</option>
								<option value="04">April</option>
								<option value="05">May</option>
								<option value="06">June</option>
								<option value="07">July</option>
								<option value="08">August</option>
								<option value="09">September</option>
								<option value="10">October</option>
								<option value="11">November</option>
								<option value="12">December</option>
							</select>
						</div>
						<div class="input_wpr">
							<select v-model="state.day" autocomplete="off" class="hlfinput">
								<option value="">Day</option>
								<option v-for="day in days" v-bind:value="day < 10 ? '0' + day : day" v-bind:key="day">
									{{day>=10 ? day:'0'+day}}
								</option>
							</select>
						</div>
					</div>
					<span v-if="v$.year.$error">
						{{ v$.year.$errors[0].$message }}
					</span>
				</div>
				<div class="form-group">
					<label>Location<span>*</span></label>
					<div class="d-flex location">
						<div class="input_wpr">
							<select v-model="state.country" autocomplete="off" class="hlfinput">
								<option value="">Select Country</option>
								<option v-for="country in countryList" :value="country.id" v-bind:key="country.id">{{ country.name }}</option>
							</select>
							<span v-if="v$.country.$error">
						{{ v$.country.$errors[0].$message }}
					</span>
						</div>
						<div class="input_wpr">
							<input type="text" class="hlfinput" name="statename" placeholder="State" v-model="state.statename">
							<span v-if="v$.statename.$error">
						{{ v$.statename.$errors[0].$message }}
					</span>
						</div>
					</div>
					
				</div>
				<div class="form-group">
					<label>Select Schedule For Training<span>*</span></label>
					<div class="d-flex">
						<div class="input_wpr schedule_training">
							<datepicker type="text" id="datePicker" name="trainingSchedule" v-model="state.trainingSchedule"></datepicker>
							<div class="cal_icon"><img src="@/assets/calender.png" alt=""></div>
						</div>
					</div>
					<span v-if="v$.trainingSchedule.$error">
						{{ v$.trainingSchedule.$errors[0].$message }}
					</span>
				</div>
				<div class="form-group">
					<label>Contact Through Mail or Phone No<span>*</span></label>
					<div class="d-flex">
						<div class="input_wpr">
							<select class="hlfinput" name="contactThrough" v-model="state.contactThrough">
								<option value="email">Mail</option>
								<option value="phone">Phone</option>
							</select>
						</div>
					</div>
					<span v-if="v$.contactThrough.$error">
						{{ v$.contactThrough.$errors[0].$message }}
					</span>
				</div>
				<div class="form-group">
					<label>Message</label>
					<div class="d-flex">
						<div class="input_wpr">
							<textarea v-model="state.message" name="message"></textarea>
						</div>
					</div>
				</div>
				<!-- <input type="submit" value="Send us a message" name="submit" class="btn_send"> -->
				<button type="submit" class="login_btn" :disabled="buttonclick" style="width: 100%;margin-left:0px;cursor:pointer;background: red;border: red;">
					<span v-if="buttonclick==true" style="color:white">Sending. . .</span>
					<span v-else style="color:white"> Send us a message</span>
				</button>
				<!-- <div v-if="errormessage!=''" style="color:red;" class="errormessage">{{errormessage}}</div> -->
				</form>
			</div>
		</div>
	</div>

	<Footer />
</template>
<script>
	import Datepicker from 'vue3-datepicker'
	//import { ref } from 'vue'
	import axios from 'axios'
	import { BASE_URL } from '../config'
	import { API_BASE_URL } from '../config'
	import { reactive, computed } from "vue"
	import useValidate from "@vuelidate/core"
	import { required, maxLength } from "@vuelidate/validators"
	import Navigation from '../components/Navigation.vue'
	import Footer from '../components/Footer.vue'
	import { createLocal } from 'the-storages'
	//import { API_MAIL_URL } from '../config'
	//import { ref } from 'vue'
	//const picked = ref(new Date())

	const mirror = createLocal()
	const storage = mirror._prx

	export default {
		name: 'Trainingform',
		components: {
			Navigation,
			Footer,
			Datepicker
		},
		setup() {
			const state = reactive({
				fName: '',
				lName: '',
				email: '',
				phoneNumber: '',
				month: '',
				day: '',
				year: '',
				country: '',
				statename: '',
				trainingSchedule: '',
				contactThrough: '',
				message: '',
                budget:'',
			})
			const rules = computed(() => {
				return {
					fName: { required },
					lName: { required },
					email: { required },
					phoneNumber: { required },
					month: { required },
					day: { required, maxLength: maxLength(2) },
					year: { required, maxLength: maxLength(4) },
					country: { required },
                    statename: { required },
					trainingSchedule: { required },
					contactThrough: { required },
					// cnfPassword:{ required, sameAsPassword: sameAs("userPassword") },
				}
			})
			const v$ = useValidate(rules, state)
			return {
				state,
				v$,
			}
		},
		data() {
			return {
				errormessage: '',
				successmessage: '',
				fName: '',
				lName: '',
				email: '',
				phoneNumber: '',
				month: '',
				day: '',
				year: '',
				country: '',
				countryList: {},
                statename: '',
				trainingSchedule: '',
				contactThrough: '',
				message: '',
				errors: [],
				// errormessage: '',
				storageData: mirror,
				storage: storage,
				budget: '',
				buttonclick:false,
			}
		},
		computed: {
			years() {
				const year = new Date().getFullYear()
				return Array.from({ length: year - 1900 }, (value, index) => year - index)
			},
			days() {
				//alert(this.state.month)
				const year =this.state.year;
				const month=parseInt(this.state.month);
				var daysno = new Date(year, month, 0).getDate();
				
				//console.log('datalis:',Array.from({ length:daysno }, (value, index) => 1+ index))
                return Array.from({ length:daysno }, (value, index) => 1+ index)
            }
		},
        
		
		methods: { 
			async mydatelist(){ 
				this.state.day='01';
			},
			acceptNumber() {
			var x = this.state.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			this.state.phoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
			},    
			async saveTrainingPost() {
				this.buttonclick = true;
				this.v$.$validate(this.v$.$error);

				if (!this.v$.$error) {
					if (this.state.fName && this.state.lName && this.state.email && this.state.phoneNumber && this.state.month && this.state.day && this.state.year && this.state.country && this.state.statename && this.state.trainingSchedule && this.state.contactThrough) {
						const paramss = {
							fName: this.state.fName,
							lName: this.state.lName,
							email: this.state.email,
							phoneNumber: this.state.phoneNumber,
							month: this.state.month,
							day: this.state.day,
							year: this.state.year,
							country: this.state.country,
							state: this.state.statename,
							trainingSchedule: this.state.trainingSchedule,
							contactThrough: this.state.contactThrough,
							message: this.state.message
						};
						
						
						axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
							axios.post(BASE_URL + 'api/add-training',paramss ).then(response => {
								if (response.data.success == true) {
									console.log('data:', response.data)
									//this.errormessage = response.data.message;
									this.message = response.data.message;
									this.successmessage = response.data.message;
									this.errormessage = '';
									this.state = {};
									document.getElementById("jobpost-form").reset();
									this.buttonclick = false;
								} else {
									
									this.successmessage = '';
									this.errormessage = response.data.message;
									this.buttonclick = false;
								}
							})
							.catch(error => {
								console.log(error);
							});
							console.log(responses);
						});
						axios.post(API_BASE_URL + 'trainingmail',paramss );
					} else {
						this.errormessage = 'All Fields are Mandatory';
						this.buttonclick = false;
					}
				} else {
					this.errormessage = 'All Fields are Mandatory';
					this.buttonclick = false;
				}
				setTimeout(() => {
					this.errormessage = '';
					this.successmessage = '';
					
				}, 5000);
			}
		},
		async created() {
			/* let countryData = await axios.get(API_BASE_URL + 'country');
			this.countryList = countryData.data;*/
			this.state.year=new Date().getFullYear();
			this.state.day=new Date().getDate();
			this.state.month=new Date().getMonth()+1;
			let countryData = await axios.get(API_BASE_URL + 'country');
			this.countryList = countryData.data;
			
		},
	}
</script>
<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.training {
		padding: 160px 0 80px;
		background: url('~@/assets/log_bg.jpg') no-repeat #111112;
		background-size: cover;
	}

		.training .container {
			max-width: 1440px;
			width: 100%;
			margin-right: auto;
			margin-left: auto;
		}

		.training .training_form {
			width: 710px;
			border-top: 1px solid #b91e1e;
			padding: 50px;
			margin: 0 auto;
			box-shadow: 0px 0px 36px 0px rgba(3,3,3,0.75);
			background: #212123;
		}

			.training .training_form img {
				max-width: 100%;
				height: auto;
				vertical-align: middle;
				border-style: none;
				margin-bottom: 15px;
			}

			.training .training_form .training_logo img {
				max-width: 100%;
				height: auto;
				vertical-align: middle;
				border-style: none;
				margin-bottom: 40px;
			}

			.training .training_form .form-group input[type=text], input[type=tel], input[type=email], input[type=tel], input[type=number], textarea, select {
				position: relative;
				font-family: 'Play',sans-serif;
				padding: 13px 15px;
				font-size: 15px;
				font-weight: 400;
				background-color: #1f1f1f;
				border: 1px solid #3d3d3d;
				color: #8f8f8f;
				display: block;
				width: 100%;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}

				.training .training_form .form-group input[type=text], input[type=email], input[type=tel], input[type=number], textarea, select:focus {
					background: #1f1f1f;
					border: 1px solid #3d3d3d;
					outline: 0;
					box-shadow: none;
				}

			.training .training_form .form-group select.hlfinput {
				background-image: url('~@/assets/arrow.png');
				background-position: 96% center;
				background-repeat: no-repeat;
			}

			.training .training_form .form-group textarea {
				height: 120px;
				resize: none;
			}

			.training .training_form span {
				color: #dd0505;
			}

			.training .training_form .form-group .d-flex {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
				margin: 0 -5px;
			}
			.input_wpr{
				flex: 1 1 33.333%;
				padding: 5px;
			}

			.training .training_form .form-group label {
				position: relative;
				display:block;
				font-family: 'Play',sans-serif;
				font-size: 16px;
				font-weight: 400;
				color: #d0d0d0;
				margin: 0;
			}

			.training .training_form .form-group {
				margin-bottom: 20px;
			}

			.training .training_form .btn_send {
				font-family: 'Play',sans-serif;
				font-size: 18px;
				line-height: 1;
				font-weight: 700;
				background-color: #c71e1e;
				color: #fff;
				padding: 20px 0;
				border-radius: 2px;
				text-transform: uppercase;
				cursor: pointer;
				border: none;
				display: block;
				width: 100%;
				letter-spacing: 1px;
			}

			.training .training_form .cal_icon {
				position: absolute;
				right: 20px;
				top: 14px;
				pointer-events: none;
			}

				.training .training_form .cal_icon img {
					width: 24px;
					height: auto;
					margin: 0;
				}

	

	.training_logo {
		display: flex;
		flex-wrap: wrap;
	}

	@media(max-width:768px) {
		.training {
			padding: 100px 15px 50px;
		}

			.training .training_form {
				width: 100%;
			}

			.training .training_form {
				width: 100%;
				padding: 20px;
			}

				.training .training_form img {
					width: 110px;
					height: auto;
					vertical-align: middle;
					border-style: none;
					margin-bottom: 5px;
				}

				.training .training_form .training_logo img {
					width: 200px;
					height: auto;
					vertical-align: middle;
					border-style: none;
					margin-bottom: 20px;
				}

				.training .training_form .form-group {
					margin-bottom: 15px;
				}

					.training .training_form .form-group input[type=text], input[type=tel], input[type=email], input[type=tel], input[type=number], textarea {
						padding: 15px 16px;
						font-size: 14px;
					}

					.training .training_form .form-group textarea {
						height: 100px;
					}

					.training .training_form .form-group label {
						font-size: 14px;
					}

				.training .training_form .btn_send {
					font-size: 15px;
				}

				.training .training_form .form-group select {
					padding: 15px 16px;
				}
	}
	@media(max-width:450px){
		.input_wpr{
			flex: 1 1 100%;
		}
	}
</style>

<style>
	.schedule_training .v3dp__datepicker{
		width:100%;
	}
	.schedule_training input[type=text] {
		background-color: #1f1f1f;
		border: 1px solid #3d3d3d;
		color: #8f8f8f;
		padding: 14px 15px;
		width:100%;
	}
</style>