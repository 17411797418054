<template>
  <div
    class="podcast_box"
    style="cursor: pointer"
    v-on:click="execute(podcastData.slug)"
  >
    <div class="podcast_box_pic">
      <img
        class="bg_img"
        v-if="!podcastData.featured_image"
        src="@/assets/default.png"
      />
      <img
        class="bg_img"
        v-if="podcastData.featured_image"
        v-bind:src="imagepath + podcastData.featured_image"
        v-bind:alt="podcastData.title"
      />
      <div class="overlay_podcast"></div>
      <div class="podcast_season">
        <span v-if="podcastData.podcasts_category.name">{{
          podcastData.podcasts_category.name
        }}</span>
      </div>
      <div class="podcast_box_content">
        <div class="podcast_box_content_left">
          <h3>{{ podcastData.title }}</h3>
          <h4>
            {{ podcastData.podcasts_category.name }}
            <span> {{ podcastData.episode }}</span>
          </h4>
        </div>
        <div class="podcast_box_content_right">
          <div class="play" style="#cda53a">
            <!-- <img src="@/assets/play.png"> -->
            <!-- <router-link :to="'/podcast/' + podcastData.slug"> -->
            <img src="@/assets/play.png" />
          </div>
          <span v-if="podcastData.total_view != 0">{{
            podcastData.total_view
          }}</span>
          <span v-if="!podcastData.total_view">00</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from 'axios'
//import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from "../config";
import { createLocal } from "the-storages";
const mirror = createLocal();
const storage = mirror._prx;
export default {
  name: "PodcastCard",
  props: ["podcastData", "callback"],
  data() {
    return {
      imagepath: {},
      storageData: mirror,
      storage: storage,
    };
  },
  async created() {
    this.imagepath = API_IMAGE_PATH;
    console.log(this.imagepath);
  },
  methods: {
    execute(slug) {
      // var param = {
      //     "slugs":slugs,
      //     "userId":mirror.AUTHID
      // }
      // await axios.post(API_BASE_URL + 'podcastUserCounter',param)
      // .then(response => {
      // 	console.log("DETAILS:",response.data);
      //     //this.$router.push({name:'PodcastDetails', params: {slug: slugs}});
      // });
      //alert(slug);
      if (this.callback) {
        this.callback(slug);
      }
    },
  },
};
</script>
<style>
.podcast_box {
  width: 50%;
  height: 400px;
  background: #0d0e0f;
  padding: 20px;
  float: left;
}

.podcast_box_pic {
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden;
}
.podcast_box_pic .bg_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay_podcast {
  background: linear-gradient(
    180deg,
    rgba(13, 14, 15, 0.05368) 100%,
    rgba(13, 14, 15, 1) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.podcast_box_content {
  width: 100%;
  height: auto;
  padding: 0 20px 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 2;
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: space-between;
}

.podcast_box_content_left {
  width: calc(100% - 50px);
  text-align: left;
}

.podcast_box_content_left h3 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.podcast_box_content_left h4 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  margin: 5px 0 0;
}

.podcast_box_content_left h4 span {
  color: #eb0404;
}

.podcast_box_content_right {
  width: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.play {
  width: 36px;
  height: 36px;
  border: solid 1px #cda53a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.podcast_box_content_right span {
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  margin-top: 5px;
  display: block;
}

.podcast_season {
  background: #e10000;
  padding: 4px 10px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  /* display: inline-block; */
  display: none;
  position: absolute;
  top: 30px;
  left: 1px;
  z-index: 2;
}

/* debraj 5 dec 2022*/
.podcast_box_pic:hover::after,
.podcast_box_pic:hover .podcast_season{
  display: block;
}
.podcast_box_pic:hover .podcast_box_content{
  display: flex;
}
.podcast_box_pic::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    background-color: #000000a6;
}
.podcast_box_pic::after,
.podcast_box_pic .podcast_box_content,
.podcast_box_pic .podcast_season{
  animation: scale-display .3s;
}
@keyframes scale-display {
	0% {
		opacity: 0;
		transform: scale(0);
    -webkit-transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
    -webkit-transform: scale(1);
	}
}

.filter-hide {
  display: none;
}
@media only screen and (min-width: 240px) and (max-width: 767px) {
  .podcast_box_content {
    top: 10%;
    padding: 0 10px;
  }
  .podcast_box_content_left {
    width: 100%;
  }
  .podcast_box_content_left h3 {
    font-size: 15px;
  }
  .podcast_box_content_left h4 {
    font-size: 12px;
    margin: 0;
  }
}
</style>