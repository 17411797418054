<template>
    <div class="testimnial" >
        <div class="test_header">
            <img v-if="!image" class="bg_img" src="@/assets/avatar.png" >
            <img v-if="image" v-bind:src="imagepath+image" v-bind:alt="name">

            <!-- <img v-bind:src="imagepath+image" v-bind:alt="name"> -->
            <div class="user">
                <h4>{{name}}</h4>
                <div class="review"></div>
            </div>
        </div>
        <div class="test_body">
            <p >{{ description }}</p>
            <!-- <p v-else>{{ description.substring(0,250)+"..." }}</p> -->
        </div>
    </div>
</template>

<script>
import { API_IMAGE_PATH } from '../config'
export default {
    
    data() {
        return {
            imagepath:{}
        }
    },
    name: 'TestimonialCardDetails',
    props: ["name","image","description"],
    async created () {
        this.imagepath=API_IMAGE_PATH
        console.log(this.imagepath);
    }
}
</script>

<style scoped>
    .testimnial{
        background: #1e1e1e;
        display: flex;
        flex-wrap: wrap;
    }
    .test_header{
        flex: 0 1 245px;
        display: flex;
        align-items: center;
        padding: 30px;
        background: #1a1a1a;
    }
    .test_body{
        flex: 1 1 475px;
        padding: 45px 30px;
    }
    .test_header img{
        margin-right: 20px;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid #484848;
    }
    .test_header .user h4{
        font-size: 20px;
        line-height: 26px;
        color: #fff;
        padding-bottom: 5px;
        font-weight: 400;
    }
    .test_body p{
        font-size: 16px;
        line-height: 23px;
        color: #b1b1b1;
        padding-left: 40px;
        position: relative;
    }
    .test_body p:before{
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 27px;
        height: 21px;
        background: url('~@/assets/quote.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media(max-width: 767px){
        .test_header{
            flex: 1 1 100%;
            padding: 15px 30px;
        }
        .test_header img{
            width: 45px;
            height: 45px;
        }
        .test_body{
            flex: 1 1 100%;
            padding: 30px;
        }
    }
    @media(max-width:575px){
        .test_body{
            padding: 20px;
        }
        .test_header{
            padding: 15px 20px;
        }
        .test_body p{
            font-size: 14px;
            line-height: 19px;
            padding-left: 30px;
        }
        .test_body p:before{
            width: 18px;
            height: 14px;
        }
        .test_header img{
            margin: 0 12px;
        }
        .test_header .user h4{
            font-size: 18px;
            line-height: 23px;
        }
    }
</style>