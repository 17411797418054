<template>
  <div class="gt-started-frm tabinfo" id="general_info">
    <form
      id="jobpost-form"
      v-on:submit.prevent="updateUserDetails"
      enctype="multipart/form-data"
    >
      <h4 style="margin-bottom: 50px">
        General Information 
        <span
          v-if="userType == 3"
          style="
            float: right;
            color: white;
            background: rgb(205, 165, 58);
            padding: 5px;
            border-radius: 50px;
          "
          >Profile Completed : {{ totalPoints }} %</span
        >
      </h4>
      <!-- <div v-if="resmessage != ''" class="success">{{ resmessage }}</div>
      <div v-if="errormessage != ''" class="error">{{ errormessage }}</div> -->
      <ul class="general_information">
        <li>
          <label>First Name:</label>
          <input
            type="text"
            placeholder="Enter First name"
            v-model="state.firstName"
            name="firstName"
            id="firstName"
            autocomplete="off"
          />
          <span v-if="v$.firstName.$error">
            {{ v$.firstName.$errors[0].$message }}
          </span>
        </li>
        <li>
          <label>Last Name:</label>
          <input
            type="text"
            placeholder="Enter Last name"
            v-model="state.lastName"
            name="lastName"
            id="lastName"
            autocomplete="off"
          />
          <span v-if="v$.lastName.$error">
            {{ v$.lastName.$errors[0].$message }}
          </span>
        </li>
        <li>
          <label>Your Email:</label>
          <input
            type="text"
            readonly
            placeholder="Enter Email"
            v-model="state.email"
            name="email"
            id="email"
            autocomplete="off"
          />
          <span v-if="v$.email.$error">
            {{ v$.email.$errors[0].$message }}
          </span>
        </li>
        <li>
          <label>Phone Number:</label>
          <input
            type="text"
            placeholder="Enter Phone number"
            v-model="state.number"
            name="number"
            id="number"
            autocomplete="off"
            @input="acceptNumber"
          />
          <span v-if="v$.number.$error">
            {{ v$.number.$errors[0].$message }}
          </span>
        </li>
        <li>
          <label>Designation<span>*</span>:</label>
          
          <input
            type="text"
            placeholder="Enter Designation"
            v-model="state.designation"
            name="designation"
            id="designation"
            autocomplete="off"
          />
          <span v-if="v$.designation.$error">
            {{ v$.designation.$errors[0].$message }}
          </span>
        </li>
        <li>
          <label>Date of Birth:</label>

          <datepicker v-model="state.dob" />
          <span v-if="v$.dob.$error">
            {{ v$.dob.$errors[0].$message }}
          </span>
        </li>
        <li>
          <label>Country:</label>

          <select v-model="state.country" autocomplete="off" class="hlfinput">
            <option value="">Select Country</option>
            <option
              v-for="country in countryList"
              :value="country.id"
              v-bind:key="country.id"
            >
              {{ country.name }}
            </option>
          </select>
          <span v-if="v$.country.$error">
            {{ v$.country.$errors[0].$message }}
          </span>
        </li>
        <li>
          <label>State:</label>
          <input
            type="text"
            placeholder="Enter State"
            v-model="state.stateName"
            name="stateName"
            id="stateName"
            autocomplete="off"
          />
          <span v-if="v$.stateName.$error">
            {{ v$.stateName.$errors[0].$message }}
          </span>
        </li>
        <li class="w-100">
          <label>Biography <span>*</span>:</label>
          <!-- <textarea
            name="biography"
            id="biography"
            cols="30"
            rows="10"
            placeholder="Enter Biography"
            v-model="state.biography"
            style="height:350px"
          ></textarea> -->

          <ckeditor 
              style="color:black;"  
              :editor="editor" 
              v-model="state.biography" 
              :config="editorConfig" 
              name="biography" 
              placeholder="Enter Biography"
              id="biography"
              >
          </ckeditor>

          <span v-if="v$.biography.$error">
            {{ v$.biography.$errors[0].$message }}
          </span>
        </li>
        <li class="w-100">
          <label>Short Note:</label>
          <textarea
            name="shortnote"
            id="shortnote"
            cols="30"
            rows="5"
            placeholder="Enter Short Note"
            v-model="shortnote"
          ></textarea>
        </li>
      </ul>
      <h4 v-if="userType == 3">Other Details <span>*</span></h4>
      <ul class="general_information" v-if="userType == 3">
        <li>
          <label>Level:</label>
          <select
            v-model="state.levelType"
            name="levelType"
            class="hlfinput"
            placeholder="Experience"
          >
            <!-- <option value="">Select Level</option> -->
            <option value="Experience">Experience</option>
            <option value="Certification">Certification</option>
          </select>
        </li>
        <li>
          <label>Protection School:</label>
          <!-- <select
            v-model="state.protectionSchool"
            name="protectionSchool"
            class="hlfinput"
          >
            
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select> -->
          <input 
            type="text" 
            v-model="state.protectionSchool"
            id="protectionSchool"
            name="protectionSchool">
        </li>
      </ul>
      <ul class="general_information" v-if="userType == 3">
        <li>
          <label>Is Executive Protection School:</label>
          
          <select
            v-model="state.IsEProtectionSchool"
            name="IsEProtectionSchool"
            class="hlfinput"
            @change="SchoolStatus(state.IsEProtectionSchool)"
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </li>
        <li v-show="schooldivShow">
          <label>Executive School Certificate:</label>
            <dl class="dropdown">
            <dt>
              <a href="#" v-on:click="showDiv = !showDiv" style="border:solid 1px #3d3d3d;">
                <span id="placeholder" style="color:#818182;font-size:14px;">Select Executive School Certificate  </span>    <i class="fa fa-angle-down" style="float:right;padding: 10px 0px 10px 0px;"></i>
                <p id="selected"></p>  
              </a>
            </dt>
            <dd >     
              <ul id="options" v-show="showDiv" style="display:npne;border: 1px solid #3d3d3d;">
                <li v-for="epSchoolss in epSchoolList" v-bind:key="epSchoolss.id" style="border-bottom:solid 1px #3d3d3d;width:100%">
                  <input checked="true"  type="checkbox" v-model="state.epSchoolCertificate" :value="epSchoolss.name" :id="epSchoolss.name"/>  
                  <label :for="epSchoolss.name" style="display: unset;cursor:pointer;color:#818182">
                    {{epSchoolss.name}}
                  </label>
                </li>
              </ul>
            </dd>
          </dl>
        </li>
      </ul>
      <ul class="general_information" v-if="userType == 3">
        <li>
          <label>Select Driving Course:</label>
          <select
            v-model="state.drivingCourse"
            @change="drivingStatus(state.drivingCourse)"
            name="drivingCourse"
            class="hlfinput"
          >
            <!-- <option value="">Select Driving Course</option> -->
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </li>
        <li v-show="drivingShow">
          <label>Driving Document :</label>
          <input
            v-if="((state.drivingDocumnet == '' || state.drivingDocumnet == NULL) && drivingpath == '')"
            type="file"
            ref="drivingDocumnet"
            @input="drivingImageUpload"
            name="drivingDocumnet"
            id="drivingDocumnet"
            style="width:100%;"
          />


          <div class="imgWpr" v-else-if="drivingpath!=''">
             Click To Download Driving Document <br/>({{drivingfilename}})
            <a v-if="drivingpath" v-bind:alt="drivingfilename"  :href="drivingpath" target="_blank" style="color: red" :title="drivingfilename">
              <i class="fas fa-download"></i
            ></a>
            <a @click.prevent="removeimage('driving')" class="removeicon"><i class="far fa-trash-alt"></i></a>
          </div>
         
          <!-- <div class="imgWpr" v-else-if="drivingpath!=''">
            <img v-if="drivingpath" v-bind:src="drivingpath" v-bind:alt="state.drivingDocumnet">
            
          </div> -->
        </li>
      </ul>
      <ul class="general_information" v-if="userType == 3">
        <li v-show="drivingShow">
          <label>Issue Date:</label>
          <datepicker v-model="state.drivingIssueDate" :upperLimit="new Date()"/>
        </li>
        <li v-show="drivingShow">
          <label>Expiry Date:</label>
          <datepicker v-model="state.drivingExpiryDate" />
        </li>
        <li>
          <label>Select Medical Training:</label>
          <select
            v-model="state.medical"
            name="medical"
            class="hlfinput"
            @change="medicalStatus(state.medical)"
          >
            <!-- <option value="">Select Medical Training</option> -->
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </li>
        <li v-show="medicalShow">
          <label>Medical Document :</label>
          <input
            v-if="((state.medicalDocument == '' || state.medicalDocument == NULL) && medicalpath=='')"
            type="file"
            ref="medicalDocument"
            @input="medicalImageUpload"
            name="medicalDocument"
            id="medicalDocument"
            style="width:100%;"
          />

          <div class="imgWpr" v-else-if="medicalpath">
             Click To Download Medical Document <br/>({{mediclefilename}})
            <a v-if="medicalpath" v-bind:alt="mediclefilename"  :href="medicalpath" target="_blank" style="color: red" :title="mediclefilename">
              <i class="fas fa-download"></i
            ></a>
            <a @click.prevent="removeimage('medical')" class="removeicon"><i class="far fa-trash-alt"></i></a>
          </div>
          
         
        </li>
      </ul>
      <ul class="general_information" v-if="userType == 3">
        <li v-show="medicalShow">
          <label>Issue Date:</label>
          <datepicker v-model="state.medicalIssueDate" :upperLimit="new Date()"/>
        </li>
        <li v-show="medicalShow">
          <label>Expiry Date:</label>
          <datepicker v-model="state.medicalExpiryDate" />
        </li>
        <li>
          <label>Experience:</label>
          <select v-model="state.experience" name="experience" class="hlfinput">
            <!-- <option value="">Select Experience</option> -->
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="10+">10+</option>
          </select>
        </li>
      </ul>
      <!-- <h4 v-if="membershipType != 'Free' && userType == 3">Upload CV <span>*</span></h4> -->
      <h4 v-if="userType == 3">Upload CV <span>*</span></h4>
      <ul
        class="general_information"
        v-if=" userType == 3"
      >
        <li>
          <label>Upload CV *:</label>
          <input
            v-if="state.uploadCv == '' || state.uploadCv == NULL"
            type="file"
            ref="uploadCv"
            @input="uploadCvData"
            name="uploadCv"
            id="uploadCv"
            accept="application/document*"
          />
          <div class="imgWpr" v-else>
             Click To Download CV ({{state.uploadCv}})
            <a :href="cvPath" target="_blank" style="color: red" :title="state.uploadCv">
              <i class="fas fa-download"></i
            ></a>
            <!-- <img v-bind:src="cvPath" v-bind:alt="state.uploadCv">
              <a @click.prevent="removeimage('cv')" class="removeicon"><i class="far fa-trash-alt"></i></a> -->
            <a @click.prevent="removeimage('cv')" class="removeicon"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
        </li>
      </ul>
      <!-- <ul class="general_information"> -->
      <h4>Specialisties <span>*</span></h4>
      <ul
        class="specialisties"
        v-for="(specialistie, index) in specialisties"
        v-bind:key="index"
      >
        <li>
          <input v-model="specialistie.value" />
          <button class="remove_btn" @click.prevent="deleteFind(index)">
            <i class="far fa-trash-alt"></i>
          </button>
        </li>
      </ul>
      <button
        class="save_btn"
        @click.prevent="admorefield()"
        style="margin-bottom: 30px"
      >
        Add Specialisties
      </button>
      <h4>Upload Images </h4>
      <ul class="general_information">
        <li class="w-33">
          <label>Profile Image<span>*</span> :</label>
          <input
            v-if="state.profileImage == '' || state.profileImage == NULL"
            type="file"
            ref="profileImage"
            @input="profileImageUpload"
            name="profileImage"
            id="profileImage"
            accept="image/*"
          />
          <div class="imgWpr" v-else>
            <img v-bind:src="profilepath" v-bind:alt="state.profileImage" />
            <a @click.prevent="removeimage('profile')" class="removeicon"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
          <!-- <span v-if="v$.profileImage.$error">
              {{ v$.profileImage.$errors[0].$message }}
          </span> -->
        </li>

        <li class="w-33" v-if="userType != 3">
          <label>Company Logo:</label>
          <input
            v-if="
              state.companyLogoUpload == '' || state.companyLogoUpload == NULL
            "
            type="file"
            ref="companyLogoUpload"
            @input="companyLogoImageUpload"
            name="companyLogoUpload"
            id="companyLogoUpload"
            autocomplete="off"
            accept="image/*"
          />
          <div class="imgWpr" v-else>
            <img v-bind:src="logopath" v-bind:alt="state.companyLogoUpload" />
            <a @click.prevent="removeimage('logo')" class="removeicon"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
          <!-- <span v-if="v$.companyLogo.$error">
              {{ v$.companyLogo.$errors[0].$message }}
          </span> -->
        </li>

        <li class="w-33">
          <label>Banner Image:</label>
          <input
            v-if="state.bannerImage == '' || state.bannerImage == NULL"
            type="file"
            ref="bannerImage"
            @input="bannerImageUpload"
            name="bannerImage"
            id="bannerImage"
            accept="image/*"
          />
          <div class="imgWpr" v-else>
            <img v-bind:src="bannerpath" v-bind:alt="state.bannerImage" />
            <a @click.prevent="removeimage('banner')" class="removeicon"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
          <!-- <span v-if="v$.bannerImage.$error">
              {{ v$.bannerImage.$errors[0].$message }}
          </span> -->
        </li>
      </ul>
      <button type="submit" class="save_btn" :disabled="buttonclick">
        <span v-if="buttonclick == true" style="color: white"
          >Updating. . . . .</span
        >
        <span v-else style="color: white"> Update</span>
      </button>
      <!-- <button type="submit" class="save_btn">Update</button> -->
      <button type="button" @click="$router.go(-1)" class="login_btn">
        Cancel
      </button>
    </form>
  </div>
</template>
<script>
</script>

<script>
//import { ref } from 'vue';
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../config";
import { API_BASE_URL } from "../config";
import { API_IMAGE_PATH } from "../config";
import { reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { createLocal } from "the-storages";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue'
import Datepicker from "vue3-datepicker";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal();
const storage = mirror._prx;

//let imageFile = ref('');
//let imageUrl = ref('');
export default {
  name: "EpA",
  components: {
    Datepicker,
    ckeditor: CKEditor.component
  },
  setup() {
    const state = reactive({
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      designation: "",
      dob: "",
      country: "",
      stateName: "",
      biography: "",
      profileImage: "",
      bannerImage: "",
      companyLogoUpload: "",
      medicalDocument: "",
      drivingDocument: "",
      epeProtectionSchool:"",
      IsEProtectionSchool:"",
      epSchool:"",
      epSchoolCertificate:[],
      drivingCourse:"",
      medical:"",
    });
    const rules = computed(() => {
      return {
        firstName: { required },
        lastName: { required },
        email: { required },
        number: { required },
        designation: { required },
        dob: { required },
        country: { required },
        stateName: { required },
        biography: { required },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      v$,
    };
  },
  data() {
    return {
      totalPoints: 0,
      membershipType: "",
      userType: "",
      levelType: "",
      protectionSchool: "",
      drivingIssueDate: new Date(),
      drivingExpiryDate: new Date(),
      medicalIssueDate: new Date(),
      medicalExpiryDate: new Date(),
      
      experience: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      number: "",
      designation: "",
      dob: new Date(),
      country: "",
      countryList: {},
      epSchoolList: {},
      stateName: "",
      biography: "",
      profileImage: "",
      bannerImage: "",
      companyLogoUpload: "",
      errors: [],
      errormessage: "",
      resmessage: "",
      storageData: mirror,
      storage: storage,
      errorStr: null,
      imagepath: {},
      picked: new Date(),
      profilepath: "",
      uploadCv: "",
      cvPath: "",
      logopath: "",
      bannerpath: "",
      drivingpath: "",
      medicalpath: "",
      shortnote: "",
      // IsEProtectionSchool:"",
      // epSchool:"",
      specialisties: [],
      drivingShow: false,
      medicalShow: false,
      buttonclick: false,
      SchoolStatusDiv:false,
      showDiv:false,
      editor: ClassicEditor,
      epSchoolCertificateuserdata:[],
      drivingfilename:'',
      mediclefilename:'',
      editorConfig: {
                    // The configuration of the editor.
                }
    };
  },

  async created() {
    //alert("hi");
    let countryData = await axios.get(API_BASE_URL + "country");
    this.countryList = countryData.data;

    let epSchoolData = await axios.get(API_BASE_URL + "epSchoolCertificate");
    this.epSchoolList = epSchoolData.data;

    console.log("EP School Data: ",epSchoolData.data)

    this.imagepath = API_IMAGE_PATH;
    this.userType = mirror.USERTYPE;
    this.membershipType = mirror.MEMBERSHIP_TYPE;
    this.getUserDetails();

    //this.specialisties=userData.data.specialisties
  },

  methods: {
    async getUserDetails() {
      const userData = await axios.post(API_BASE_URL + "userDetails", {
        userId: mirror.AUTHID,
      });
      console.log("User Details:", userData);
      console.log("DOB", userData.data.dob);
      this.state.firstName = userData.data.fname;
      this.state.lastName = userData.data.lname;
      this.state.email = userData.data.email;
      this.state.number = userData.data.phone_no;
      //this.state.dob        =  userData.data.dob;
      this.state.designation = userData.data.designation;
      this.state.stateName = userData.data.state;
      this.state.country = userData.data.country.id;
      this.state.biography = (userData.data.biography)?userData.data.biography:"";
      this.state.profileImage = userData.data.profile_image;
      this.state.bannerImage = userData.data.banner_image;
      this.state.companyLogoUpload = userData.data.logo;
      this.state.uploadCv = userData.data.cv_image;
      if(userData.data.epCertificate)
      this.state.epSchoolCertificate= userData.data.epCertificate.split(',');

      if(userData.data.IsEProtectionSchool=='yes'){
        this.schooldivShow = true;
        this.state.IsEProtectionSchool = userData.data.IsEProtectionSchool;
        //this.state.epSchoolCertificate = userData.data.epCertificate;
      }
      
      // this.picked=new Date(userData.data.dob);
      if (userData.data.profile_image != "") {
        this.profilepath = API_IMAGE_PATH + userData.data.profile_image;
      }
      if (userData.data.banner_image != "") {
        this.bannerpath = API_IMAGE_PATH + userData.data.banner_image;
      }
      if (userData.data.cv_image != "") {
        this.cvPath = API_IMAGE_PATH + userData.data.cv_image;
      }
      if (userData.data.driving_certificate != "") {
        this.drivingpath = API_IMAGE_PATH + userData.data.driving_certificate;
        //alert(userData.data.driving_certificate)
        this.drivingfilename=userData.data.driving_certificate;
      }
      if (userData.data.medical_certificate != "") {
        this.medicalpath = API_IMAGE_PATH + userData.data.medical_certificate;
        this.mediclefilename=userData.data.medical_certificate;
      }
      if (userData.data.logo != "") {
        this.logopath = API_IMAGE_PATH + userData.data.logo;
      }
      this.state.dob = new Date(userData.data.dob);
      this.shortnote = (userData.data.shortnote)?userData.data.shortnote:'';
      if (
        userData.data.specialisties != "" &&
        userData.data.specialisties != null
      ) {
        this.specialisties = JSON.parse(userData.data.specialisties);
      }

      this.state.levelType = userData.data.levelType;
      this.state.protectionSchool = (userData.data.protectionSchool)?userData.data.protectionSchool:'';
      
      
      this.state.experience = userData.data.experience;
      this.totalPoints = userData.data.totalPoints;

      if (this.state.drivingCourse == "Yes" || userData.data.drivingCourse == "Yes") {
        this.drivingStatus(userData.data.drivingCourse);
        this.state.drivingCourse = userData.data.drivingCourse;
        //this.state.drivingCourse = userData.data.drivingCourse;
        this.drivingpath = API_IMAGE_PATH + userData.data.driving_certificate;
        this.drivingShow = true;
      }
      if (this.state.medical == "Yes" || userData.data.medical == "Yes") {
        this.medicalStatus(userData.data.medical);
        this.state.medical = userData.data.medical;
        this.medicalShow = true;
      }

      this.state.drivingIssueDate = (userData.data.driving_issue_date)? new Date(userData.data.driving_issue_date):null;
      this.state.drivingExpiryDate = (userData.data.driving_expiry_date)? new Date(userData.data.driving_expiry_date):null;

      this.state.medicalIssueDate = (userData.data.medical_issue_date)? new Date(userData.data.medical_issue_date):null;
      this.state.medicalExpiryDate = (userData.data.medical_expiry_date)? new Date(userData.data.medical_expiry_date):null;

      //console.log("MEDICAL", this.state.medicalDocument);
      //console.log("DRIVING", this.state.drivingDocument);
    },
    // selectImage () {
    //   this.$refs.bannerImage.click()
    // },
    admorefield() {
      this.specialisties.push({ value: "" });
    },
    acceptNumber() {
      var x = this.state.number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.state.number = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    deleteFind: function (index) {
      console.log(index);
      console.log(this.specialisties);
      this.specialisties.splice(index, 1);
    },
    async updateUserDetails() {
      this.buttonclick = true;
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      this.v$.$validate(this.v$.$error);
      //const datavalue=New array()
      //console.log("Input-Params",slugs)
      //console.log("11");
      // console.log(this.state);
      /*console.log('asdasdasdas',JSON.stringify(this.specialisties));
      for(var i=0;i<this.specialisties.length;i++){
      console.log(this.specialisties[i]['value'])
      }*/
      if (!this.v$.$error) {
        let formData = new FormData();
        console.log(this.state.profileImage);
        if (this.state.profileImage != "")
          formData.append("profileImage", this.state.profileImage);
        if (this.state.bannerImage != "")
          formData.append("bannerImage", this.state.bannerImage);
        if (this.state.companyLogoUpload != "")
          formData.append("companyLogoUpload", this.state.companyLogoUpload);
        if (this.state.uploadCv != "")
          formData.append("uploadCv", this.state.uploadCv);

        formData.append("levelType", this.state.levelType);
        formData.append("protectionSchool", this.state.protectionSchool);

        if (this.state.drivingDocument != "")
          formData.append("drivingDocument", this.state.drivingDocument);
        formData.append("drivingCourse", this.state.drivingCourse);
        formData.append(
          "drivingIssueDate",
          moment(this.state.drivingIssueDate).format("YYYY-MM-DD")
        );
        formData.append(
          "drivingExpiryDate",
          moment(this.state.dodrivingExpiryDateb).format("YYYY-MM-DD")
        );

        if (this.state.medicalDocument != "")
          formData.append("medicalDocument", this.state.medicalDocument);
        formData.append("medical", this.state.medical);
        formData.append(
          "medicalIssueDate",
          moment(this.state.medicalIssueDate).format("YYYY-MM-DD")
        );
        formData.append(
          "medicalExpiryDate",
          moment(this.state.medicalExpiryDate).format("YYYY-MM-DD")
        );

        formData.append("experience", this.state.experience);
        formData.append("firstName", this.state.firstName);
        formData.append("lastName", this.state.lastName);
        formData.append("email", this.state.email);
        formData.append("number", this.state.number);
        formData.append("dob", moment(this.state.dob).format("YYYY-MM-DD"));
        formData.append("designation", this.state.designation);
        formData.append("country", this.state.country);
        formData.append("stateName", this.state.stateName);
        formData.append("biography", this.state.biography);
        formData.append("userId", mirror.AUTHID);
        formData.append("shortnote", this.shortnote);
        
        formData.append("IsEProtectionSchool", this.state.IsEProtectionSchool);
        formData.append("epCertificate", this.state.epSchoolCertificate);
        
        formData.append("specialisties", JSON.stringify(this.specialisties));

        //alert(this.state.IsEProtectionSchool);
        //alert(this.state.epSchoolCertificate);
        //console.log(this.picked);
        //console.log(moment(this.picked).format('YYYY-MM-DD'))
        //console.log("22");
        if (
          this.state.firstName &&
          this.state.lastName &&
          this.state.email &&
          this.state.number &&
          this.state.dob &&
          this.state.designation &&
          this.state.country &&
          this.state.stateName &&
          this.state.biography
        ) {
          // console.log("33");
          axios.get(BASE_URL + "sanctum/csrf-cookie").then((responses) => {
            axios
              .post(API_BASE_URL + "edit-user", formData, config)
              .then((response) => {
                console.log(response);
                if (response.data.result == "success") {
                  this.errormessage = "";
                  this.resmessage = response.data.message;
                  //this.$router.push({ name: "JobList" });
                  this.getUserDetails();
                  if (this.state.profileImage != "") {
                    this.storage.set(
                      "PROFILEIMAGE",
                      response.data.profile_image
                    );
                    console.log("Result : ", response.data.profile_image);
                    console.log("PROFILEIMAGE: ", mirror.PROFILEIMAGE);
                  }
                  this.buttonclick = false;
                  this.showToast("Success",this.resmessage,"success"); 
                } else {
                  this.errormessage = response.data.message;
                  
                  this.buttonclick = false;
                  this.showToast("Failed",this.errormessage,"danger"); 
                  this.resmessage = "";
                }
                var element = document.getElementById("general_info");
                element.scrollIntoView();
              })
              .catch((error) => {
                //console.log('error-data:', error)
                console.error(error);
              });
            console.log(responses);
          });
        } else {
          this.errormessage = "All Fields are Mandatory";
          this.buttonclick = false;
          this.showToast("Warning!",this.errormessage,"warning"); 
        }
      } else {
        this.errormessage = "All Fields are Mandatory";
        this.buttonclick = false;
        this.showToast("Warning!",this.errormessage,"warning"); 
      }
      // setTimeout(() => {
      //     this.errormessage = '';
      //     console.log(this.errormessage);
      // }, 5000);
      //this.getUserDetails();
    },
    uploadCvData(e) {
      const file = e.target.files[0];
      this.state.uploadCv = file;
      this.cvPath = URL.createObjectURL(file);
      console.log("image4", this.uploadCv);
    },
    profileImageUpload(e) {
      const file = e.target.files[0];
      this.state.profileImage = file;
      this.profilepath = URL.createObjectURL(file);
      console.log("image1", this.profileImage);
    },
    bannerImageUpload(e) {
      const file = e.target.files[0];
      this.state.bannerImage = file;
      this.bannerpath = URL.createObjectURL(file);
      console.log("image2", this.state.bannerImage);
    },
    companyLogoImageUpload(e) {
      const file = e.target.files[0];
      this.state.companyLogoUpload = file;
      this.logopath = URL.createObjectURL(file);
      console.log("image3", this.companyLogoUpload);
    },
    drivingImageUpload(e) {
      const file = e.target.files[0];
      this.state.drivingDocument = file;
      this.drivingpath = URL.createObjectURL(file);
      console.log("imaged", this.state.drivingDocumnet);
    },
    medicalImageUpload(e) {
      const file = e.target.files[0];
      this.state.medicalDocument = file;
      this.medicalpath = URL.createObjectURL(file);
      console.log("imagem", this.state.drivingDocumnet);
    },
    drivingStatus(status) {
      //alert(status)
      if (status == "Yes") {
        this.drivingShow = true;
      } else {
        this.drivingShow = false;
      }
      //this.state.drivingDocument = '';
      console.log("DRIVING:", this.drivingShow);
    },
    SchoolStatus(status){
      //alert(status)
      if (status == "yes") {
        this.schooldivShow = true;
      } else {
        this.schooldivShow = false;
      }
      //this.state.drivingDocument = '';
      console.log("SCHOOL:", this.schooldivShow);
    },
    medicalStatus(status) {
      if (status == "Yes") {
        this.medicalShow = true;
      } else {
        this.medicalShow = false;
      }
      //this.state.medicalDocument = '';
      console.log("MEDICAL:", this.medicalShow);
    },
    removeimage(type) {
      axios.get(BASE_URL + "sanctum/csrf-cookie").then((responses) => {
        const inputData = {
          userId: mirror.AUTHID,
          imageType: type,
        };
        axios
          .post(API_BASE_URL + "user/removeuserimage", inputData)
          .then((response) => {
            if (response.data.result == "success") {
              console.log("data:", response);
              if (type == "profile") {
                this.state.profileImage = "";
                this.profilepath = "";
              }
              if (type == "banner") {
                //alert('test')
                this.bannerpath = "";
                this.state.bannerImage = "";
              }
              if (type == "logo") {
                this.logopath = "";
                this.state.companyLogoUpload = "";
              }
              if (type == "cv") {
                this.cvPath = "";
                this.state.uploadCv = "";
              }
              if (type == "medical") {
                this.medicalpath = "";
                this.state.medicalDocument = "";
              }
              if (type == "driving") {
                this.drivingpath = "";
                this.state.drivingDocument = "";
              }
              this.errormessage = "";
              this.message = response.data.messase;
              //this.$router.push({ name: "JobList" });
            } else {
              this.errormessage = response.data.message;
              this.message = "";
            }
          })
          .catch((error) => {
            //console.log('error-data:', error)
            console.error(error);
          });
        console.log("responses", responses);
      });
    },
    showToast(title,description,type){
      createToast({
          title: title,
          description: description
      },
      {
          type: type,
          position: 'bottom-right',
          // hideProgressBar: 'true',
          // toastBackgroundColor: color,
      })
  },
  },
};
</script>
<style scoped>
.dropdown dd, .dropdown dt {
    margin:0px;
    padding:0px;
}
.dropdown ul {
    margin: -1px 0 0 0;
}
.dropdown dd {
    position:relative;
}
.dropdown a, 
.dropdown a:visited {
    color:#fff;
    text-decoration:none;
    outline:none;
    font-size: 12px;
}
.dropdown dt a {
    background-color:#212122;
    display:block;
    padding: 5px 5px 5px 20px;
    min-height: 25px;
    line-height: 24px;
    overflow: hidden;
    border:0;
    width:100%;
}
.dropdown dt a span, .multiSel span {
    cursor:pointer;
    display:inline-block;
    padding: 0 3px 2px 0;
}
.dropdown dd ul {
    background-color: #212122;
    border:0;
    color:#fff;
    /* display:none; */
    left:0px;
    padding: 2px 15px 2px 5px;
    position:absolute;
    top:2px;
    width:100%;
    list-style:none;
    max-height: 190px;
    overflow: auto;
    z-index:1;
    border-radius: 0;
}
.dropdown span.value {
    display:none;
}
.dropdown dd ul li:last-child{
  border-bottom: none !important;
}
.dropdown dd ul li label{
  margin-left: 5px;
}
.dropdown dd ul li a {
    padding:5px;
    display:block;
}
.dropdown dd ul li a:hover {
    background-color:#fff;
}

/* /////////////////////////// */
span {
  color: red;
}
.general_information {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.general_information li {
  padding: 5px 15px 10px;
  width: 50%;
}
.general_information li.w-100 {
  width: 100%;
}
.general_information li.w-33 {
  width: 33.333%;
}
.general_information li {
  display: block;
}
.general_information li input,
.general_information li select {
  padding: 10px 15px !important;
  margin: 3px 0 0 !important;
  background: #212122;
  border: 1px solid #3d3d3d;
  /* width: 100%; */
}
.general_information li textarea {
  padding: 10px 15px !important;
  margin: 3px 0 0 !important;
  background: #212122;
  border: 1px solid #3d3d3d;
  width: 100%;
}
.general_information li .imgWpr {
  position: relative;
  background: #343434;
    padding: 14px;
}
.general_information li .imgWpr img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.general_information li .imgWpr .removeicon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c71e1e;
  color: #fff;
  font-size: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.specialisties {
  margin-top: 5px;
}
.specialisties li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}
.specialisties li input {
  flex: 1 1 auto;
  width: auto;
  margin: 0 !important;
  color: #d9d9d9;
  height: 45px;
  background: #212122;
  padding: 0 15px;
  border: 1px solid #3d3d3d;
}
.specialisties li .remove_btn {
  padding: 5px 13px;
  margin-left: 5px;
  background: #c71e1e;
  color: #fff;
  font-size: 18px;
}
button.save_btn {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #c71e1e;
  background: #c71e1e;
  text-transform: uppercase;
  font-family: "Play", sans-serif;
  cursor: pointer;
  margin-top: 15px;
}
@media (max-width: 991px) {
  .general_information li .imgWpr img {
    height: 150px;
  }
}
@media (max-width: 575px) {
  .general_information li,
  .general_information li.w-33 {
    width: 100%;
  }
  .tabinfo h4 span  {
    float: left !important;
    padding: 5px 10px !important;
    margin-top: 20px !important;
  }
}
</style>