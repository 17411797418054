<template>
         <div class="item_slider_dash">
              <div class="stories-box">
               <div class="stories-img"><router-link :to="'/podcast/' + podcastData.slug">
               <img class="bg_img" v-if="!podcastData.featured_image" src="@/assets/default.png" >
            <img class="bg_img" v-if="podcastData.featured_image" v-bind:src="imagepath+podcastData.featured_image" v-bind:alt="podcastData.title" >
             </router-link>
               </div>
               <div class="stories-text">
                 <!-- <h3><router-link :to="'/podcast/' + podcastData.slug">{{podcastData.title}}</router-link></h3> -->
                 <h3 p v-if="podcastData.title.length<75"><router-link :to="'/podcast/' + podcastData.slug">{{ podcastData.title }}</router-link></h3>
                 <h3 v-else><router-link :to="'/podcast/' + podcastData.slug">{{ podcastData.title.substring(0,75)+"..." }}</router-link></h3>

                 <p>{{podcastData.podcasts_category.name}} <span>{{podcastData.episode}}</span></p>
                 <div class="play-btn">
                 <!-- <img src="@/assets/dash/video-play-icon.png" alt=""/>  -->
                 <router-link :to="'/podcast/' + podcastData.slug">See Details </router-link></div>
                 
               </div>
              </div>
            </div>
</template>

<script>
import { API_IMAGE_PATH } from '../config'
export default {
    name: 'DashboardPodcastslidercard',
    props: ['podcastData'],
    data() {
    return {
      imagepath:'',
      }
    },
    created () {
      this.imagepath = API_IMAGE_PATH
	},
}
</script>

<style scoped>
.item_slider_dash .stories-text h3 {font-size: 19px;line-height:25px;font-weight:400;color:#fff;}
.item_slider_dash .stories-text h3 a{color:inherit;}
.item_slider_dash .stories-text {padding:10px 15px;}
.item_slider_dash .stories-img{width: 144px; height:163px;}
.item_slider_dash .stories-img a{display:block;height:100%;}
.item_slider_dash .stories-img img{width:100px;height:100%;object-fit:cover;}
.item_slider_dash .play-btn a{ float:right; background:#c61e1e; padding:4px 8px; margin: 14px 0 0;font-size: 12px;line-height: 17px; color:#fff;}
.item_slider_dash .play-btn img{ float:left;}
.item_slider_dash .play-btn img {float: left;max-width: 100% !important;width:inherit !important; padding: 2px 4px 0;}
.item_slider_dash .stories-box{display: flex;background: #28282b;box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);}
.item_slider_dash .stories-text p{font-size:14px;line-height:19px;color:#999;}
.item_slider_dash .stories-text p span{ color:#c61e1e;font-weight:600;}
.item_slider_dash.stories-box {display: flex;background: #28282b;box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);}
@media(max-width:575px){
  .item_slider_dash .stories-text h3 {font-size: 16px;line-height:21px;}
  .item_slider_dash .stories-text p{font-size:12px;line-height:16px;}
}
</style>