<template>
    <div class="experience_card">
        <div class="experience_head">
            <h5>Certificate {{keys+1}}</h5>
            <!-- <div class="action_btn">
                <button class="edit_btn" @click="editPop(certificate.id)"><i class="fas fa-pencil-alt"></i></button>
                <button class="delete_btn" @click="deletePop(certificate.id)"><i class="far fa-trash-alt"></i></button>
            </div> -->
        </div>
        <div class="experience_body">
            <label for="">Course Name:</label>
            <p>{{certificate.course_name}}</p>
            <label for="">Certificate:</label>
                <!-- <p v-if="certificate.certificate_file"><img v-bind:src="imagepath+certificate.certificate_file" v-bind:alt="certificate.id" ></p>
                <p v-else><img src="@/assets/certificate1.jpg" alt="" ></p> -->
              <p v-if="certificate.certificate_file && (getFileType(certificate.certificate_file)=='jpeg' || getFileType(certificate.certificate_file)=='jpg')">
                <img v-bind:src="imagepath+certificate.certificate_file" v-bind:alt="certificate.id" >
              </p>
              <p v-else-if="certificate.certificate_file && getFileType(certificate.certificate_file)=='pdf'" >
                <img src="@/assets/pdf.png" alt="" >
              </p>
              <p v-else-if="certificate.certificate_file && getFileType(certificate.certificate_file)=='docx'" >
                <img src="@/assets/docx.png" alt="" >
              </p>
              <p v-else>
                <!-- <img src="@/assets/no-image.jpg" alt="" style="height:300px;width:220px;"> -->
              </p>
        </div>
    </div>
</template>

<script>
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
export default{
    name: 'JobCard3',
    props:['certificate','keys'],
    data() {
        return {
            imagepath:{},
            moment:moment
        }
    },
    async created () {
        this.imagepath=API_IMAGE_PATH+"certificates/"
        //console.log(this.imagepath);
    },
    methods:{
      getFileType(imageName){
        var fileName = imageName;
        var fileExtension = fileName.split('.').pop();
        return fileExtension;
      },
    },
}
</script>
<style scoped>
.experience_list{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.experience_list li{
  width: 33.333%;
  padding: 15px;
}
.experience_list li .experience_card{
  background: #161618;
  border: 1px solid #d9d9d9;
}
.experience_head{
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience_head h5{
  font-size: 20px;
  line-height: 26px;
}
.experience_head .action_btn{
  display: flex;
  flex-wrap: wrap;
}
.action_btn .edit_btn{
  border: 1px solid #999;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: transparent;
  color: #fff;
  cursor: pointer
}
.action_btn .delete_btn{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: #c71e1e;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}
.experience_body{
  padding: 15px 25px;
}
.experience_body label{
  font-size: 14px;
  line-height: 17px;
  color: #d3d3d3;
  font-weight: 500;
}
.experience_body p{
  font-size: 13px;
  line-height: 18px;
  color: #999;
  font-weight: 300;
  padding: 2px 0 10px !important;
}
.experience_body p img{
  width: 100%;
  height: 300px;
  object-fit: cover;
}
button.save_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #c71e1e;
  background: #c71e1e;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  cursor:pointer;
}
button.add_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  background: transparent;
  margin-left: 25px;
  cursor:pointer;
  float:right;
}
button.add_btn i{
  margin-right: 5px;
}
</style>