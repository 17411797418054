<template>
<header class="dashboard ">
<div class="nav-btn" @click="openNav()"><img src="@/assets/dash/nav-btn.png" alt=""></div>
<div id="logo"><router-link class="logo" to="/"><img src="@/assets/logo.png" alt="" style="height: 40px;"/></router-link></div>
<div class="rt-prt">
<div class="rt-prt-lft">
<!--<div class="btn-hd"><a href="#" class="ad-candidate">Add Candidate</a></div>
<div class="btn-hd"><div class="notificatn-btn"><img src="@/assets/dash/message-icon.png" alt=""/><span class="notification-text">2</span></div></div>
<div class="btn-hd"><div class="notificatn-btn"><img src="@/assets/dash/message-icon2.png" alt=""/><span class="notification-text">2</span></div></div>-->
<div class="btn-hd admin-relation"><div id="navbtn"  @click="userDrop()"><div class="admin-img">
<img v-if="storage.get('PROFILEIMAGE')" v-bind:src="imagepath+storage.PROFILEIMAGE" v-bind:alt="storage.NAME" title="storage.NAME"/>
<img v-else src="@/assets/avatar.png" v-bind:alt="storage.NAME" title="storage.NAME"/>
</div>
<div class="admin-text">
<h4>{{storage.NAME}}</h4><p  v-if="storage.get('DESIGNATION')" >{{storage.DESIGNATION}}</p>
</div> </div> <ul class="nav-menu"><a href="#" @click.prevent="logout()"> <img src="@/assets/dash/click-btn.png" alt=""/> Logout</a></ul>
</div>
</div> </div>
</header>
</template>

<script>
import { createLocal } from 'the-storages';
import { BASE_URL } from '../config';
import { API_IMAGE_PATH } from '../config';
import axios from 'axios';

const mirror = createLocal();
const storage = mirror._prx;

export default {
	name: 'Dashboardheader',
	data() {
		return {
			scrollPosition: null,
			isBtn: false,
			imagepath: '',
			storageData: mirror,
			storage: storage,
		}
	},
	methods:{
		logout() {
			//console.log("USER:",mirror.AUTHID);
			const parms = {"userId":mirror.AUTHID};
			//console.log("PARAMS:",parms);

			axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
			axios.post(BASE_URL + 'api/logout',parms)
			.then(response => {
			if (response.data.success) {
					console.log('dsfdsfsd' + response.data)
					//storage.removeItem('AUTHID', true)
					storage.removeItem('NAME', true)
					storage.removeItem('USEREMAIL', true)
					storage.removeItem('USERTYPE', true)
					storage.removeItem('TOKEN', true)
					storage.removeItem('PROFILEIMAGE', true)
					storage.removeItem('DESIGNATION', true)
					storage.clear();
					this.storage.set('AUTHID', '')
					this.$router.push({ name: "Home" ,hash:"#show"});
				}
			})
			.catch(error => {
			console.error(error);
			//window.location.href = '/login';
			});
			console.warn(response)
			})
		},
		openNav: function() {
		const x = document.getElementById('mySidenav');
		if(x.classList.contains('mini')){
			x.classList.remove('mini')
		}else{
			x.classList.add('mini')
		}
		},
		userDrop: function(){
		const y = document.querySelector('.nav-menu');
		if(y.classList.contains('active')){
			y.classList.remove('active')
		}else{
			y.classList.add('active')
		}
		},
	},
	async created() {
		this.imagepath = API_IMAGE_PATH;
		if(mirror.AUTHID){
				axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
					axios.post(BASE_URL + 'api/updateLoginHistory',{"userId":mirror.AUTHID})
					.then(response => {
						if (response.data.success) {
							console.log('Login Details',response.data);
							//this.chatCount = response.data.chatCount;
							//chatCount
							//this.$router.push({ name: "Home" });
						}
					})
					.catch(error => {
					console.error(error);
						//window.location.href = '/login';
						//this.$router.push({ name: "Login" });
					});
					console.warn(response)
				})
			} else {
				console.log("User Not Found");
			}
	},
}
</script>
<style scoped>
*{ margin:0; padding:0; box-sizing:border-box;}
div{ display:block;}
div:after{ visibility:hidden; clear:both; content:""; display:block;}
a{ text-decoration:none; color:#00F;}
img{ max-width:100%; height:auto; vertical-align:top;}
body{ background:#202023;  font:400 14px/18px  'Play', sans-serif; color:#fff;}
.tt-u{ text-transform:uppercase;}
.tt-c{ text-transform:capitalize;}
</style>
<style>
header.dashboard{ padding:0 20px; border-bottom:solid 1px #333333;background:#202023;width:100%; display:flex;position:sticky;top:0;left:0;z-index:1!important;align-items:center;}
header.dashboard #logo{padding:20px 0;}
header.dashboard #logo img{max-width:200px;}
header.dashboard #navbtn{cursor:pointer;display: flex;align-items:center;}
header.dashboard .rt-prt{ margin-left: auto;}
header.dashboard .btn-hd{ float:left; padding:15px 25px; min-height: 48px;}
header.dashboard .btn-hd+.btn-hd{ border-left:solid 1px #333;padding-top:25px;}
header.dashboard .ad-candidate{ background:url(~@/assets/dash/admin.png) no-repeat 10px 50%; font:400 16px/42px  'Play', sans-serif; color:#fff; border:solid 1px #909091; padding:0 26px 0 60px; display:inline-block;text-transform:uppercase;}
header.dashboard .notificatn-btn{ position:relative;}
header.dashboard .notification-text{ position:absolute; width:25px; height:25px; border-radius:100%; background:#c71e1e; color:#fff; font-size:16px; line-height:25px; text-align:center; right:-5px; top:0;}
header.dashboard .rt-prt-lft{ float:left;}
header.dashboard .admin-box{ float:right;}
header.dashboard .admin-text {padding:0 20px 0 15px; background:url(~@/assets/dash/drop-down.png) no-repeat 100% 50%;}
header.dashboard .admin-relation{ position:relative;}
header.dashboard .admin-relation ul.nav-menu{position:absolute; display:none; left:0; top:100%; width:100%; background:#303033;}
header.dashboard .admin-relation ul.nav-menu a {text-align: center;font-size: 18px;padding: 10px 0;color: #fcfcfc;display:block;}
header.dashboard .admin-relation .nav-menu.active{display:block;}
header.dashboard .admin-img{ border-radius: 50%;border: 2px solid #82671f;}
header.dashboard .admin-img img{ width:50px; height:50px; object-fit:cover; border-radius:50%;}
header.dashboard .admin-text h4 {font-size: 16px;}
header.dashboard .admin-text p {font-size: 14px;line-height:17px;}
header.dashboard .admin-text h4 _ p{font-size:13px;}
header.dashboard .nav-btn{padding: 0 30px 0 0;line-height:0;}
header.dashboard .nav-btn img{ cursor:pointer;}
header.dashboard .btn-hd + .btn-hd.admin-relation{padding:13px 29px;}
@media(max-width: 1199px){
	header.dashboard .btn-hd{
		padding: 15px 0 15px 15px;
	}
}
@media(max-width:575px){
	header.dashboard #logo img{max-width:140px;}
	header.dashboard .nav-btn{padding-right:20px;}
	header.dashboard .nav-btn img{max-width:25px;}
	header.dashboard .admin-img img{ width:35px; height:35px;}
    header.dashboard .admin-text{padding:10px;}
    header.dashboard .admin-text h4, header.dashboard .admin-text p{display:none;}
}

</style>
