<template>
 <Navigation/>
      <Banner class="blog_banner" bg="blog-banner.jpg">
        <div class="inr_text blog">
			<img src="@/assets/event-heading.png">	
            <h3 style="background: -webkit-linear-gradient(#ffba00, #50472e);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">{{details.event_title}}</h3>
        </div>
     </Banner>
    
	<section class="main_podcast blog_details">
	<div class="container">
	<div class="wrapper">
		<div class="left-part">
		
			<div class="blog-img" v-if="details.event_featured_image!=''"><img v-bind:src="imagepath+details.event_featured_image" v-bind:alt="details.event_title"/>
			</div>
			<div class="blog-img" v-else><img src="@/assets/blog-details.jpg" alt="" /></div>
			
			
			<div class="details-box">
				<div class="blog_content_top">
					<div class="heading-left">
						<h3>{{details.event_title}}</h3>
						<h6> <span class="red-text" style="color:#cda53a;">{{ moment(details.evevt_date).startOf("month").format('MMMM')}}</span> {{ moment(details.event_date).format("DD, YYYY") }} {{ details.event_time }}</h6> 
					</div>
					<div class="heading-right">
						<h5>Location :<span class="red-text" style="color:#cda53a;"> {{details.event_location}}</span></h5>
					</div>
				</div>
				<div class="content" v-html="details.event_description">
				</div>
				
			</div>
		</div>
		<div class="right-part-sec">
			<div class="gallery" v-if="gallerys.length>0">
                <h2>Photos</h2>
                <vue-easy-lightbox
                    :visible="visible"
                    :imgs="imgs"
                    :index="index"
                    @hide="handleHide"
                    >
                </vue-easy-lightbox>
                <ul>
                    <li :key="index" v-for="(gallery,index) in gallerys" class="pic" @click="() => showImg(index)" style="cursor:pointer;">
                        <img v-bind:src="imagepath+'gallery/'+gallery.photo" v-bind:alt="imagepath+'gallery/'+gallery.photo" v-if="gallery.photo">
                    </li>
                </ul>
                <!-- <router-link to="/" class="view_all">View All</router-link> -->
            </div>
		</div>
	</div>
	</div>
	</section>
	<Buttomads/>
	<Footer/>
</template>


<script>
import Banner from '../components/Banner.vue'
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { useRoute } from 'vue-router'
import Buttomads from '../components/Buttomads.vue'
//import Contactform from '../components/Contactform.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import { createLocal } from 'the-storages'
const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'EventDetails',
    components:{
        Banner,
        Buttomads,
        Navigation,
		Footer,
        VueEasyLightbox
    },
    data() {
        return {
            storageData: mirror,
			storage: storage,
            visible:false,
            index: 0, 
			imgs: [],
            isLoading: true,
            gallerys:[],
            imagepath:{},
            details: {},
            slugname:'',
            catname:'',
            authorname:'',
            moment: moment,
        }
    },
    mounted() {
        this.getResults();
    },  
    async created () {
		const route=useRoute();
		const response = await axios.get(API_BASE_URL + 'event/'+route.params.slug);
		this.imagepath=API_IMAGE_PATH+'event/'
		this.details = response.data;

		this.isLoading = false;
		
		//console.warn(response.data);
	},
    methods: {
        getResults() {	
            const route2=useRoute();		
            axios.get(API_BASE_URL + 'eventImages/'+route2.params.slug)
            .then(response => {
            this.gallerys = response.data;
            //console.log("EVENT IMAGE: ",response.data);
            for(var i=0;i<this.gallerys.length;i++){
                this.imgs.push(API_IMAGE_PATH+'event/gallery/'+this.gallerys[i].photo)
            }
            //this.imagepath=API_IMAGE_PATH
                console.log(this.imgs);
            });
        },
        showImg(index) {
            this.index = index
            this.visible = true
        },
        handleHide() {
            this.visible = false
        }
    },
    beforeRouteEnter(to, from, next) {
        if (storage.get('TOKEN')) {
            next();
        }
        else{
            next('/login');
        }
    }
}
</script>


<style scoped>
.blog_details{
	margin:60px 0;
	float:left;
	width:100%;
}
.blog_details .left-part{ 
	float:left; 
	width:72%;
}
.blog_details .right-part-sec { 
	width:26%; 
	float:right;
}
.blog_details .blog-img{
	line-height: 0;
}
.blog_details .heading-left{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.blog_details .blog-img img {
width:100%
}

.blog_details .details-box {
background:#1a1a1a;
padding:25px 40px;
border:solid 1px #242424;
/* float:left */
}

.blog_details .details-box h3 {
font-size:42px;
line-height:50px;
color:#fff;
flex: 1 1 auto;

}

.blog_details .details-box .heading-left h6 {
flex: 0 0 135px;
font-size: 15px;
line-height: 20px;
}

.blog_details .details-box .heading-right {
width:100%;
float:left;
margin:10px 0
}

.blog_details .details-box .heading-right h5 {
width:100%;
float:none;
text-align:left;
padding:6px 0;
font-size: 18px;
line-height: 24px;
}
.blog_details .details-box .heading-right h5 a{
	color: #999;
	transition: all 0.3s ease-in-out;
	display: inline-block;
	margin-right: 5px;
}
.blog_details .details-box .heading-right h5 a:hover{
	color: #fff;
}

.blog_details .details-box p {
color:#939393;
font-size:15px;
padding:12px 0 0;
}

.blog_details .blog_content_top span.red-text {
	color:#c61e1e;
}
.blog_details .details-box h5 {
font-size:15px;
color:#d9d9d9;
}

.blog_details .bottom-gap-top {
padding-top:30px
}

.blog_details .right-prt ul li {
float:left;
background:url(~@/assets/icon-arrow.png) no-repeat 0 6px;
padding-left:16px;
padding-right:15px;
color:#fff;
font-size:13px
}

.blog_details .gap-top {
padding-top:25px
}

.blog_details .follow {
color:#fff
}

.blog_details .follow img {
border:solid 1px #242424;
margin:20px 0 0
}

.blog_details .left-part .left-part {
float:left;
width:40%
}

.blog_details .right-part-sec .right-part {
width:50%;
float:right
}


@media only screen and (min-width:240px) and (max-width:1024px) {
.blog_details .left-part{
    width: 100%;
}
.blog_details .right-part-sec{
    width: 100%;
}
}

</style>

<style>
.gallery{
        margin-bottom: 40px;
    }
    .gallery h1{
        font-size: 55px;
        line-height: 65px;
        color: #fff;
        font-weight: 400;
    }
    .gallery ul{
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
    }
    .gallery ul li{
        width: 25%;
        padding: 2px;
        line-height: 0;
    }
    .gallery ul li img{
        width: 100%;
        height:140px;
        object-fit: cover;
    }
    .gallery .view_all{
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
        padding: 25px 10px;
        border-bottom: 1px solid #444;
        color: #fff;
    }
    .gallery .videos{
        display: flex;
        flex-wrap: wrap;
    }
    .gallery .videos li{
        width: 33.333%;
        padding: 3px;
        position: relative;
    }
    /*.gallery .videos li:first-child{
        width: 100%;
    }
    .gallery .videos li:first-child img{
        width: 100%;
        height: 500px;
        object-fit: cover;
    }*/
    .gallery .videos li img{
        width: 100%;
        height: 190px;
        object-fit: cover;
    }
    .gallery .videos li .play_btn{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
    }
    .gallery .videos li .play_btn img{
        max-width: 50px;
        height: auto;
    }
    /*.gallery .videos li:first-child .play_btn img{
        max-width: 100%;
    }*/
    .gallery .videos li .duration{
        display: inline-block;
        background: rgba(0,0,0,0.6);
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        padding: 4px 10px;
        position: absolute;
        right: 10px;
        bottom: 7px;
    }
    .gallery .videos li:first-child .duration{
        font-size: 14px;
        line-height: 17px;
        right: 15px;
        bottom: 10px;
    }
.blog_details .details-box .content p {
	color:#888 !important;
	font-size:15px !important;
	line-height: 24px !important;
	padding:20px 0 0 !important;
	font-weight: 300 !important;
}
 @media(max-width:767px){
        .gallery ul{
            margin-top: 15px;
        }
        .gallery ul li img{
            height:100px;
        }
        .gallery .videos li img{
            height: 130px;
        }
    }
    @media(max-width:575px){

        .gallery .videos li{
            width:50%;
        }
        .gallery ul li{
            width:33.333%;
        }

    }
</style>