<template>
 <Navigation/>
      <Banner class="blog_banner" bg="blog-banner.jpg">
			<div class="inr_text blog">
			<img src="@/assets/blog-heading.png">
			<h3 style=" background: -webkit-linear-gradient(#ffba00, #50472e);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">Executive Protection</h3>
			</div>
    </Banner>
   <section class="main_podcast" id="container">
        <div class="container" >
			<ul class="all_blog">
				<li v-bind:key="blog.id" v-for="blog in blogs">
					<div class="blog_part" @click="getBlogDetails(blog.id,blog.slug)">
						<!-- <router-link :to="'/blog/' + blog.slug" class=""> -->
						<div class="blog_pic" v-if="blog.featured_image!=''" style="cursor:pointer;">
							<img v-bind:src="imagepath+blog.featured_image" v-bind:alt="blog.title"/>
						</div>
						<div class="blog_pic" v-else style="cursor:pointer;">
							<img src="@/assets/b-1.jpg">
						</div>
						<!-- </router-link> -->
						<div class="blog_content">
							<div class="blog_content_top">
								<div class="category">
									<router-link :to="'/blog/category/' + blog.category.slug" v-if="blog.category">{{blog.category.name}}</router-link>
									<span>. {{blog.user.fname}} {{blog.user.lname}}</span>
								</div>
								<div class="date"><b>{{ moment(blog.created_at).startOf("month").format('MMMM')}}</b> {{ moment(blog.created_at).format("DD,YYYY") }}</div>
							</div>
							<div class="blog_content_details">
								<!-- <router-link :to="'/blog/' + blog.slug" class=""> -->
								<h3 style="cursor:pointer;">{{blog.title}}</h3>
								<!-- </router-link> -->
								<p>{{blog.excerpt}}</p>
								<!-- <router-link :to="'/blog/' + blog.slug" class="read_more"> -->
								<span class="read_more">Read More</span>
								<!-- </router-link> -->
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div class="pagination">
			<pagination :data="laravelData" @pagination-change-page="getResults">
			
			<a v-if="current>1" @click="prev" class="read_more previous">Previous</a>
			<a v-if="totalpage>current" @click="next()" class="read_more next">Next</a>
			</pagination>
			
				
			</div>
		</div>
    </section>
	<Buttomads/>
	<Footer/>
	<div class="modal" v-show="modalLoginMembership">
        <div class="content_wrapper">
            <div class="modal_header">
            Go to the details page ? Please <router-link to="/login" class="">Sign in</router-link> or
          <router-link to="/signup" class="">Signup</router-link>
            <!-- <button @click="close"><i class="fas fa-times"></i></button> -->
            </div>
            <div class="modal_body">
           
                <button class="sign_submit" style="float:right;" @click="close">Cancel</button>
                
            </div>
        </div>
    </div>
</template>


<script>
import Banner from '../components/Banner.vue'
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import Buttomads from '../components/Buttomads.vue'
import { createLocal } from 'the-storages'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'Blog',
    components:{
        Banner,
        Buttomads,
        Navigation,
		Footer
    },
   data() {
        return {
            isLoading: true,
            imagepath:{},
            blogs: {},
            moment: moment,
            laravelData: {},
            current: 1,
            totalpage:1,
			pageSize: 4,
            storageData: mirror,
            storage: storage,
			modalLoginMembership:false,
        }
    },
    computed: {
        indexStart() {
          return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
          return this.indexStart + this.pageSize;
        },
        paginated() {
          return this.blogs.slice(this.indexStart, this.indexEnd);
        }
      },
	created(){
        //this.imagepath=API_IMAGE_PATH;
        this.userId=mirror.AUTHID;
        //this.userType=mirror.USERTYPE;
        //console.log(this.imagepath);
    },
    mounted() {
		// Fetch initial results
		this.getResults();
	},
	methods: {
		// Our method to GET results from a Laravel endpoint
		getBlogDetails: function (id,slug){
			//alert("hiii");
			if(this.userId) { 
				//blog/' + blog.slug
                this.$router. push('/blog/'+slug);
            } else {
                this.modalLoginMembership = true;
            }
            //this.$router. push('/jobDetails/'+slug);
        },
        close: function(){
            this.modalLoginMembership = false;
        },	
		prev() {
			this.current--;
			this.getResults();
			var element = document.getElementById("container");
			element.scrollIntoView();
        },
        next() {
			this.current++;
			this.getResults();
			var element = document.getElementById("container");
            element.scrollIntoView();
        },        
		getResults() {			
			axios.get(API_BASE_URL + 'blogs?page='+  this.current)
			.then(response => {
				this.laravelData = response.data;
				this.blogs = response.data.data;
				this.totalpage=response.data.last_page;
				//console.log(response.data.data);
				this.imagepath=API_IMAGE_PATH
				this.isLoading = false
			});
		}
	},
	beforeRouteEnter(to, from, next) {
		if (storage.get('TOKEN')) {
			next();
		}
		else {
			next('/login');
		}
	}
   /* async created () {
	const response = await axios.get(API_BASE_URL + 'blogs')
	this.blogs = response.data.data
	this.imagepath=API_IMAGE_PATH
	this.isLoading = false
	console.log(this.blogs)
    }*/
}
</script>

<style scoped>

.all_blog {
	display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
	margin: 80px -15px 0 -15px;
}
.all_blog li{
	width: 33.333%;
	padding: 0 15px;
}
.blog_part {
	background: #1a1a1a;
	border: solid 1px #242424;
	margin-bottom: 35px;
}

.blog_pic img {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.blog_content {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	border-bottom: 2px solid #b91e1e;
}
.main_podcast{margin-bottom:50px;}

.blog_content_top {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	font-size: 12px;
	line-height: 15px;
	min-height: 45px;
	flex-wrap: wrap;
}
.blog_content_top .category{
	flex: 1 0 220px;
	display: flex;
	flex-wrap: wrap;
}
.blog_content_top .category a{
	color: #999;
	margin-right: 5px;
	transition: all 0.3s ease-in-out;
}
.blog_content_top .category a:hover{
	color: #fff;
}
.blog_content_top .date {
	color: #fff;
	flex: 0 0 106px;
}

.blog_content_top .date b {
	/* color: #c71e1e; */
	color: #cda53a;
}

.blog_content_details h3 {
	font-size: 26px;
	line-height: normal;
	color: #fff;
	font-weight: 500;
	margin-bottom: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.blog_content_details p {
	color: #939393;
	font-size: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.read_more {
	color: #939393;
	border: solid 1px #939393;
	padding: 7px 25px;
	text-decoration: none;
	display: inline-block;
	margin-top: 20px;
	font-size: 16px;
	cursor: pointer;
}

.read_more:hover {
	background: #cfcfcf;
	color: #000;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width:100%;
}


.pagination a.next {
	color: #d70b0b;
	border-color: #d70b0b;
	margin-left: 10px;
}

.pagination a.next:hover {
	background: #d70b0b;
	color: #fff;
}

.blo_pro_right img{
	max-width:600px;
	width:100%;
}
.blo_pro_right p {
	font-size: 15px;
	color: #fff;
	text-transform: uppercase;
	margin: 10px 0;
	line-height: 24px;
}

@media(max-width:1199px){
	.all_blog{
		margin-top:50px;
	}
	.blog_pic img{
		height: 210px;
	}
}
@media(max-width:991px){
	.all_blog li{
		width: 50%;
	}
}

@media only screen and (min-width:320px) and (max-width:767px) {
	.all_blog {
		padding: 10px 15px;
	}
	.all_blog li{
		width: 100%;
	}
	.blog_part {
		width: 100%;
		margin-bottom: 20px;
	}
	.blog_content {
		padding: 15px 10px;
	}

	.blog_content_top {
		margin-bottom: 10px;
	}

	.blog_content_top span, .blog_content_top .date {
		font-size: 12px;
	}

	.blog_content_details h3 {
		font-size: 18px;
		margin-bottom: 10px;
	}

	.blog_content_details p {
		font-size: 14px;
	}

	.blog_content_details a {
		margin-top: 15px;
	}

	.pagination {
		justify-content: center;
		padding: 10px;
	}

	.pagination {
		margin-top: 0;
	}

	.blog_pro {
		padding: 30px 0;
	}
}
</style>