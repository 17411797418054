<template>
    <Navigation />
    <div class="signup_main">
        <div class="container">
            <div class="sign_up_box">
                <h4>Verifying your email</h4>
                <div class="successmessage" >
                    <p>Thank you!</p>
                    <p>Thanks for verifying your email! You can now login use ep-specialist acount details.</p>
                </div>
                
               
            </div>
        </div>
    </div>
    <Footer />
</template>


<script>   
    import axios from 'axios'
    import { API_BASE_URL } from '../config'   
    import { useRoute } from 'vue-router'
    import Navigation from '../components/Navigation.vue'
    import Footer from '../components/Footer.vue'

    export default {
        name: 'Blogdetails',
        components: {
            Navigation,
            Footer
        },
        data() {
            return {
                isLoading: false,
                email: '',
            }
        },        
        async created() {
            const route = useRoute();
            const response = await axios.get(API_BASE_URL + 'activation/' + route.params.email);
            if (response.data.success == 'success') {
                this.isLoading = true
            } else {
                this.$router.push({ name: "Login" });
            }
             
        }
    }
</script>


<style scoped>
span { color:red;}
.signup_main{padding:160px 0 80px;background: url('~@/assets/log_bg.jpg') no-repeat #111112;background-size:cover;}
.sign_up_box h4 {margin: 0 0 10px;font-size: 22px;color: #fff;text-transform: uppercase;}
.sign_up_box {min-height:275px;max-width: 540px;height: auto;background-color: #212122;box-shadow: 0 3px 5px #000;margin: auto;float: none;padding: 20px 40px 30px;background-image: url(~@/assets/user.png);background-position: bottom right;background-repeat: no-repeat;border-top: solid 2px #b71d1d;}
.sign_up_box input[type="text"],input[type="number"], .sign_up_box input[type="email"], .sign_up_box input[type="tel"], .sign_up_box input[type="password"],
.sign_up_box input[type="url"] {width: 100%;height: 50px;border: solid 1px #3d3d3d;background:#1f1f1f;box-sizing: border-box;color: #737373;font-size: 16px;
padding: 10px;}
.sign_up_box select{width: 100%;height: 50px;border: solid 1px #3d3d3d;background:#1f1f1f;box-sizing: border-box;color: #737373;font-size: 16px;
padding: 10px;}
.fifty-div{ width:49.50%;float: left;margin: 0 0 0 1%;}
.fifty-div:nth-child(2n+1){ margin-left:0;}
.mnth-box{ width:32.666%; float:left; margin-left:1%;}
.mnth-box:nth-child(3n+1){ margin-left:0;}
.sign_up_box label{ font-size:16px; letter-spacing:1px; color:#fff; padding:10px 0; display:block;width:100%;float:left;}
.sign_up_box input[type="submit"], .train_in_box input[type="button"]{ background:#c71e1e; font-size:19px; line-height:53px; letter-spacing:2px; text-transform:uppercase; color:#fff; width:100%; text-align:center; border:none; margin:20px 0 0;}
.sign_up_box p{ padding: 12px 0 0;color: #dbdbdb;font-size: 14px;float: left;width: 100%;}
.sign_up_box p + label{float:left;width:100%;color:#dbdbdb;font-size: 14px;}
@media only screen and (min-width:200px) and (max-width:767px) {
.signup_main{padding:80px 0;}
.wrapper {width:98%;}
.sign_up_box{padding:20px 15px;}
.signin_bg {padding: 40px 20px;}
.sign_in_box {width: auto;max-width: 100%;padding: 15px;}
.fifty-div{ width:100%; float:none; margin:10px 0 0;}
.mnth-box{width:100%; float:none; margin:10px 0 0;}
}
</style>