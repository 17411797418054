<template>
<div class="dashboard_main">
	<Dashboardheader/>
		<div class="middle-part dashboard">
		<Dashboardsidebar/>
			<div class="rigt-part">
				<h3>Interview Schedule</h3>
			<div class="gt-started-frm tabinfo" id="general_info">
			<!-- <div v-if="message!=''" class="success">{{message}}</div>
			<div v-if="errormessage!=''" class="error">{{errormessage}}</div> -->
			<form id="jobpost-form" v-on:submit.prevent="addInterviewSchedule" enctype="multipart/form-data">

			<h4>Interview Schedule Details</h4>
			<ul class="general_information">

			<li>
			<label>First Name:</label>
			<input type="text" readonly placeholder="Enter First name" v-model="state.firstName" name="firstName" id="firstName" autocomplete="off"/>
			<span v-if="v$.firstName.$error">
			{{ v$.firstName.$errors[0].$message }}
			</span>
			</li> 
			<li>
			<label>Last Name:</label>
			<input type="text" readonly placeholder="Enter Last name" v-model="state.lastName" name="lastName" id="lastName" autocomplete="off"/>
			<span v-if="v$.lastName.$error">
			{{ v$.lastName.$errors[0].$message }}
			</span>
			</li>
			<li>
			<label>Your Email:</label>
			<input type="text" readonly placeholder="Enter Email" v-model="state.email" name="lastName" id="lastName" autocomplete="off"/>
			<span v-if="v$.email.$error">
			{{ v$.email.$errors[0].$message }}
			</span>
			</li>
			<li>
			<label>Phone Number:</label>
			<input type="text" readonly placeholder="Enter Phone number" v-model="state.number" name="number" id="number" autocomplete="off" @input="acceptContactNumber" />
			<span v-if="v$.number.$error">
			{{ v$.number.$errors[0].$message }}
			</span>
			</li>
			<li>
			<label>Interview Date:</label>
			<datepicker  v-model="state.date" modelValue="2021-12-08" placeholder="Enter Interview Date"/>
      <span v-if="interviewerrormessage">
      {{ interviewerrormessage }}
      </span>
			<span v-if="v$.date.$error">
			{{ v$.date.$errors[0].$message }}
			</span>
			</li>
			<li>
			<label>Interview Time:</label>
			<input type="time" v-model="state.time" placeholder="Enter Interview time" style="color:#d9d9d9"/>
			<span v-if="v$.time.$error">
			{{ v$.time.$errors[0].$message }}
			</span>
			</li>
			<li>
			<label>Interview Type:</label>
			<select placeholder="Select Interview Type" v-model="state.interviewType" style="padding: 10px 15px !important;margin: 3px 0 0 !important;background: #212122;border: 1px solid #3d3d3d;width: 100%;height:45px;">
			<option value="" >Select Interview Type </option>
			<option value="Face to Face Interview" > Face to Face Interview </option>
			<option value="Telephonic Interview" > Telephonic Interview </option>
			<option value="Dicussion with CEO" > Dicussion with CEO </option>
			</select>
			<!-- <input type="text" placeholder="Enter Interview Type" v-model="state.number" name="number" id="number" autocomplete="off" @input="acceptNumber" /> -->
			<span v-if="v$.interviewType.$error">
			{{ v$.interviewType.$errors[0].$message }}
			</span>
			</li>
			</ul>
			<h4>Contact Person Details</h4>
			<ul class="general_information">
			<li>
			<label>Contact Person Name:</label>
			<input type="text" placeholder="Enter Contact Person name" v-model="state.contactName" name="contactName" id="contactName" autocomplete="off"/>

			</li>
			<li>
			<label> Email:</label>
			<input type="text" placeholder="Enter Contact Email" v-model="state.contactEmail" name="contactEmail" id="contactEmail" autocomplete="off"/>

			</li>
			<li>
			<label>Phone Number:</label>
			<input type="text" placeholder="Enter Contact Phone number" v-model="state.contactNumber" name="contactNumber" id="contactNumber" autocomplete="off" @input="acceptNumber" />
			</li>
			<li>
			<label>Other Details:</label>
			<input type="text" placeholder="Enter Other Details" v-model="state.otherDetails" name="otherDetails" id="otherDetails" autocomplete="off" />
			</li>
			</ul>
			<button type="submit" class="save_btn" :disabled="buttonclick">
			<span v-if="buttonclick==true" style="color:white">Submitting. . . . .</span>
			<span v-else style="color:white"> Submit</span>
			</button>
			<router-link :to="'/dashboard'" class="login_btn">Cancel</router-link>
			</form>
			</div>
        </div> 
		</div>
	</div>
</template>
<script>
//import { ref } from 'vue';
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import moment from "moment";
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { createLocal } from 'the-storages'
import Datepicker from 'vue3-datepicker'
import { useRoute } from 'vue-router'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'InterviewSchedule',
	components: {
    Dashboardheader,
		Dashboardsidebar,
		Datepicker
	},
  setup() {
    const state = reactive({
      firstName: '',
      lastName: '',
      email: '',
      number: '',
      date: '',
      time: '',
      contactPerson: '',
      contactEmail: '',
      contactNumber:'',
      interviewType:''
    })
    const rules = computed(() => {
      return {
        firstName: { required },
        lastName: { required },
        email: { required },
        number: { required },
        time: { required },
        date: { required },
        interviewType: { required }
      }
    })
    const v$ = useValidate(rules, state)
    return {
      state,
      v$
    }
  },
  data() {
    return {
      buttonclick:false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      number: '',
      interviewType:'',
      time: '',
      date:new Date(),
      contactEmail: '',
      contactName:'',
      contactNumber: '',
      otherDetails:'',
      errors: [],
      errormessage: '',
      message: '',
      storageData: mirror,
      storage: storage,
      errorStr: null,
      imagepath:{},
      picked: new Date(),
      interviewerrormessage:''
    }
  },

  async created() {
    //alert("hi");
    this.imagepath=API_IMAGE_PATH

    //this.picked = new Date(userData.data.dob);
    //this.state.date   =  new Date(userData.data.date);
    //this.state.time   = userData.data.time;
    this.getCandidateDetails();
	},
  methods: {
    async getCandidateDetails(){
      const route=useRoute();
      const userData = await axios.post(API_BASE_URL + 'userDetailsByEncodedId', {userId: route.params.candidateId});
      console.log("User Details:",userData);
      this.state.firstName        =  userData.data.fname;
      this.state.lastName  =  userData.data.lname;
      this.state.email         =  userData.data.email;
      this.state.number       =  userData.data.phone_no;
      this.state.contactPerson  =  userData.data.contactPerson;
      this.state.contactEmail  =  userData.data.contactEmail;
      this.state.contactNumber =  userData.data.contactNumber;
      this.state.contactDescription =  userData.data.contactDescription;
      this.state.candidateId =  userData.data.id;
    },
		acceptNumber() {
			var x = this.state.contactNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			this.state.contactNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		},
    acceptContactNumber() {
			var x = this.state.number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			this.state.number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		},
		deleteFind: function (index) {
			console.log(index);
			console.log(this.specialisties);
			this.specialisties.splice(index, 1);
		},
    timestampConversion(passDate) {
      var myDate = passDate;
      myDate = myDate.split("-");
      var newDate = new Date( myDate[2], myDate[1] - 1, myDate[0]);
      console.log(newDate.getTime());
      return newDate.getTime();
    },
		async addInterviewSchedule() {
      this.buttonclick = true;
			const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }        
      this.v$.$validate(this.v$.$error);
      if (!this.v$.$error) {
        let formData = new FormData();
        formData.append('firstName', this.state.firstName);   
        formData.append('lastName', this.state.lastName);   
        formData.append('email', this.state.email);
        formData.append('number', this.state.number);   
        formData.append('date', moment(this.state.date).format('YYYY-MM-DD'));   
        formData.append('time', this.state.time);

        formData.append('contactNumber', this.state.contactNumber);   
        formData.append('contactName',this.state.contactName);   
        formData.append('contactEmail',this.state.contactEmail);
        formData.append('userId', mirror.AUTHID);
        formData.append('candidateId', this.state.candidateId);
        formData.append('otherDetails', this.state.otherDetails);
        formData.append('interviewType', this.state.interviewType);
        //formData.append('specialisties', JSON.stringify(this.specialisties));
        this.currentDate = moment(new Date()).format("DD-MM-YYYY");
        if(this.timestampConversion(this.currentDate)<=this.timestampConversion(moment(this.state.date).format('DD-MM-YYYY'))){
          if (this.state.firstName && this.state.interviewType && this.state.lastName && this.state.email && this.state.number && this.state.date && this.state.time ) {
              //console.log("DATA: ", formData);
              axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                  axios.post(BASE_URL + 'api/add-interview-details', formData,config)
                      .then(response => {                        	
                      console.log(response)                        	
                      if (response.data.result == 'success') {
                          this.errormessage ='';
                          this.message = response.data.message;
                          this.buttonclick = false;
                          //this.$router.push({ name: "JobList" });
                          this.showToast("Success",this.message,"success"); 
                      } else {
                          this.errormessage = response.data.message;
                          this.resmessage = '';
                          this.buttonclick = false;
                          this.showToast("Failed",this.errormessage,"danger"); 
                      }
                      var element = document.getElementById("general_info");
                      element.scrollIntoView();
                      //e.target.reset();
                      this.getCandidateDetails();
                      })
                      .catch(error => {
                          console.error(error);
                      });
                  console.log(responses);
              });
          } else {
              this.errormessage = 'All Fields are Mandatory';
              this.buttonclick = false;
              this.showToast("Warning!",this.errormessage,"warning"); 
          } 
          this.interviewerrormessage = '';
        } else {
          this.interviewerrormessage = 'Interview date must be present or future date';
					this.buttonclick = false;
          this.showToast("Warning!",this.interviewerrormessage,"warning"); 
        }
      } else {
          this.errormessage = 'All Fields are Mandatory';
          this.buttonclick = false;
          this.showToast("Warning!",this.errormessage,"warning"); 
      }
    },
    showToast(title,description,type){
			createToast({
				title: title,
				description: description
			},
			{
				type: type,
				position: 'bottom-right',
				// hideProgressBar: 'true',
				// toastBackgroundColor: color,
			})
		},
  },
}

</script>
<style scoped>
    span {
        color: red;
    }
  .general_information{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .general_information li{
    padding: 5px 15px 10px;
    width: 50%;
  }
  .general_information li.w-100{
    width: 100%;
  }
  .general_information li.w-33{
    width: 33.333%;
  }
  .general_information li{
    display: block;
  }
  .general_information li input, .general_information li select{
    padding: 10px 15px !important;
    margin: 3px 0 0 !important;
    background: #212122;
    border: 1px solid #3d3d3d;
    width: 100%;
  }
  .general_information li input[type="time"]::-webkit-calendar-picker-indicator{
    filter: invert(1);
  }
  .general_information li textarea{
    padding: 10px 15px !important;
    margin: 3px 0 0 !important;
    background: #212122;
    border: 1px solid #3d3d3d;
    width: 100%;
  }
  .general_information li .imgWpr{
    position: relative;
  }
  .general_information li .imgWpr img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .general_information li .imgWpr .removeicon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #c71e1e;
    color: #fff;
    font-size: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 5px rgba(0,0,0,0.2);
  }
  .specialisties {
    margin-top: 5px;
  }
  .specialisties li{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .specialisties li input{
    flex: 1 1 auto;
    width: auto;
    margin: 0 !important;
    color: #d9d9d9;
    height: 45px;
    background: #212122;
    padding: 0 15px;
    border: 1px solid #3d3d3d;
  }
  .specialisties li .remove_btn{
    padding: 5px 13px;
    margin-left: 5px;
    background: #c71e1e;
    color: #fff;
    font-size: 18px;
  }
  button.save_btn{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 30px;
    color: #fff;
    border: 1px solid #c71e1e;
    background: #c71e1e;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    cursor:pointer;
    margin-top: 15px;
  }
  @media(max-width:991px){
      .general_information li .imgWpr img{
        height: 150px;
      }
      button.save_btn{ padding:12px 15px;}
  }
  @media(max-width:575px){
      .general_information li, .general_information li.w-33{
        width: 100%;
      }
  }
</style>