<template>
<a :class="(groupData.id==groupId)?'nav-link active':'nav-link'">
    <div class="user_details">
        <div class="picture">
            <img class="img-fluid" :src="require(`@/assets/users-color.png`)" alt="" >
        </div>
        <div class="details">
            <h2 style="font-size: 13px !important;">{{groupData.group_name}} 
                </h2>
            <h3 v-if="lastChatMessage!=''">{{lastChatMessage.message}}</h3>
            <h3 v-else></h3>
        </div>
        <div class="details_time">
            <div class="date" v-if="lastChatMessage!=''">
                <h2 style="font-size:10px !important;line-height:1 !important;">{{moment(lastChatMessage.created_at).fromNow()}}</h2>
                
            </div>
        </div>
    </div>
</a>

</template>
<script>
import axios from 'axios'
import { API_BASE_URL } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'
import { API_IMAGE_PATH } from '../config'
const mirror = createLocal()
const storage = mirror._prx

export default{
    name: 'GroupChatCard',
    props:['groupData', 'icon','callback','groupId'],
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            imagepath:{},
            blockStataus:'',
            userType:'',
            lastChatMessage:'',
            blockStatausAuth:''
        }
    },
    async created(){
        this.imagepath=API_IMAGE_PATH;
        this.userId=mirror.AUTHID;
        this.userType=mirror.USERTYPE;
        //console.log("M:",this.groupData.users);
        this.userType = mirror.USERTYPE;
        
        const param    = {
            userId:mirror.AUTHID,
            groupId:this.groupData.id
        }
        
        const response2= await axios.post(API_BASE_URL + 'lastGroupChatMessage',param);
        this.lastChatMessage = response2.data.messageData;
        //console.log("Last Chat Details : ",response2.data.messageData);
    },
    methods: {
        showPopups(test,status) {
            //console.log("TEST:",test);
			if (this.callback) {
				this.callback(test,status)
			}
		}
  } 
}
</script>
<style scoped>
.middle-part.dashboard .rigt-part.chat {
    height: 100%;
    /* overflow-y: hidden; */
}

.middle-part.dashboard .rigt-part p {
    padding-top: 0;
}

.middle-part.dashboard .rigt-part.chat h3 {
    margin-bottom: 20px;
}

.main-content-chat .container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.main-content-chat .container-fluid .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
}

.main-content-chat .container-fluid .row .right-sec {
    padding-right: 0;
    padding-left: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.main-content-chat .container-fluid .row .left-sec {
    padding-right: 0;
    padding-left: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.main-content-chat .container-fluid .row .chat_user {
    background: #1a1a1a;
    border: solid 1px #242424;
    height: 811px;
    padding: 35px 36px 209px 20px;
    overflow-y: scroll;
}

.main-content-chat .container-fluid .row .chat_user .force-overflow {
    min-height: 0;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.main-content-chat .container-fluid .row .chat_user .search {
    margin-bottom: 20px;
}

.main-content-chat .container-fluid .row .chat_user .search input[type=text] {
    position: relative;
    padding: 14px 50px;
    font-size: 15px;
    font-weight: 400;
    background-color: #222222;
    border: solid 1px #565657;
    color: #a3a3a3;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.main-content-chat .container-fluid .row .chat_user .search span {
    position: absolute;
    top: 50px;
    left: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
}

.main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details {
    width: 100%;
    border-radius: 50px;
    background: #202023;
    display: flex;
    align-items: center;
    padding: 4px 10px 4px 4px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details:last-child {
    margin-bottom: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background: #2a2a2c;
    margin-right: 5px;
    float: left;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture img {
    width: 53px;
    height: 53px;
    border-radius: 50px;
    padding: 6px;;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .active {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .away {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #f7ca2f;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .distrub {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #999999;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details {
    width: 66%;
    height: 50px;
    border-right: 1px solid #3b3b3c;
}
.main-content-chat .container-fluid .row .chat_user .user .active .user_details .details {
    border-right: 1px solid #fff;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details h2 {
    font-size: 15px;
    font-weight: 600;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;

}
.chat_user .user .user_details .details h2 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.main-content-chat .container-fluid .row .chat_user .user .user_details .details h3 {
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    color: #fff9;
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time {
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time .count {
    margin: 0 0 0 10px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time .count h2 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    background: #161618;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details {
    background: #62522a;
    border: solid 1px #333333;
    color:white !important;
    padding-left: 4px;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details .details_time .count h2 {
    border: 2px solid #fff;
}

.main-content-chat .container-fluid .row .chat_user .user .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff9;
    background-color: transparent;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details h2 {
    color: #fff;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details h3 {
     color: #fff9;
}



.main-content-chat .container-fluid .row .message .tab-content>.active {
    display: block;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .user_det {
    width: 100%;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected a {
    background: #222 no-repeat scroll right center;
    display: block;
    padding-right: 20px;
    border: 1px solid #e6e6e6;
    width: 150px;
    border-radius: 2px;
    text-decoration: none;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected a span {
    cursor: pointer;
    display: block;
    padding: 5px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .option {
    position: relative;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul {
    background: #fff none repeat scroll 0 0;
    display: none;
    list-style: none;
    padding: 0px 0px;
    position: absolute;
    left: 0px;
    top: 32px;
    width: auto;
    min-width: 170px;
    border: 1px solid #d7d7d7;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected span.value,
.drop-down .options span.value {
    display: none;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul li a {
    padding: 5px;
    display: block;
    text-decoration: none;
    color: #d9d9d9;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul li a:hover {
    background: #1a1a1a;
    color: #fff;
    transition: 0.2s ease;
}

.main-content-chat .container-fluid .row .message .message_content {
    height: 698px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .picture {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .picture img {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .details {
    position: relative;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
    position: absolute;
    top: 16px;
    left: 25%;
    bottom: 0;
    width: 15px;
    height: 15px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body {
    padding: 0 20px 0 20px;
    height: 634px;
    overflow-y: scroll;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .time {
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    color: #000;
    text-align: right;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .time p {
    padding-top: 0;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent {
    display: flex;
    align-items: center;
    margin: 0 0 40px 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .picture {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .picture img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talk-bubble {
    margin: 0;
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: #161618;
    border: 1px solid transparent;
    border-radius: 10px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
    padding: 18px 80px 20px 40px;
    text-align: left;
    line-height: 1.5em;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #e9e9e9;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #161618 transparent transparent #161618;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .message_block:nth-child(3) .time {
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    color: #000;
    text-align: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received {
    display: flex;
    align-items: center;
    margin: 0 0 40px 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talk-bubble {
    margin: 0;
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: #e6e6e6;
    border: 1px solid transparent;
    border-radius: 10px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
    padding: 18px 80px 20px 40px;
    text-align: left;
    line-height: 1.5em;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #433630;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #e2e8f3 transparent transparent #e2e8f3;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea {
    position: relative;
    padding: 0 0px 0 30px;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea textarea {
    position: relative;
    padding: 16px 20px;
    height: 50px;
    font-size: 17px;
    font-weight: 400;
    background-color: #ebebeb;
    border: 1px solid #afb8b9;
    color: #161618;
    display: block;
    width: 100%;
    border: none;
    appearance: none;
    resize: none;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg {
    width: 70%;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn {
    width: 30%;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn .sign_submit {
    width: 100%;
    height: 50px;
    background: #161618;
    border: 0;
    font-size: 14px;
    line-height: 1;
    color: #e6e6e6;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
    position: relative;
    top: 7px;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea span {
    position: absolute;
    top: 12px;
    right: 0;
}

@media only screen and (max-width:1366px) {
    .main-content-chat .container-fluid .row .chat_user {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 58%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        left: 50%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0 0px 0 30px;
    }

}

@media(max-width:1199px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: 570px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .left-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 394px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: 550px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 23%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

}

@media(max-width:991px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: 570px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .left-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 394px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: 550px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 23%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

}

@media(max-width:767px){
    .main-content-chat .container-fluid .row .chat_user .user .user_details .picture img {
        width: 45px;
        height: 45px;
    }
    .main-content-chat .container-fluid .row .chat_user .user .user_details .picture {
        width: 45px;
        height: 45px;
        margin-left: 6px;
    }
    .main-content-chat .container-fluid .row .chat_user {
        padding: 5px;
    }
    .main-content-chat .container-fluid .row .chat_user .user .user_details .details h3 {
        font-size: 12px;
    }
    .main-content-chat .container-fluid .row .chat_user .user .user_details .details_time[data-v-71503f17] {
        margin: 0 0 0 6px;
    }
}


@media(max-width:767px){
    .main-content-chat .message-wrapper .message_content_head .user_det .picture[data-v-15c78484] {
        width: 45px;
        height: 45px;
    }
    .main-content-chat .message-wrapper .message_content_head .user_det .details h3[data-v-15c78484] {
        font-size: 14px;
    }
    .main-content-chat .message-wrapper .message_content_head .user_det .details p[data-v-15c78484] {
        font-size: 12px;
    }
    .distrub1[data-v-15c78484] {
        width: 13px;
        height: 13px;
    }

}

@media(max-width:767px){
    .main-content-chat .message-wrapper .message_content_head .user_det .picture[data-v-15c78484] {
        width: 45px;
        height: 45px;
    }
    .main-content-chat .message-wrapper .message_content_head .user_det .details h3[data-v-15c78484] {
        font-size: 14px;
    }
    .main-content-chat .message-wrapper .message_content_head .user_det .details p[data-v-15c78484] {
        font-size: 12px;
    }
    .distrub1[data-v-15c78484] {
        width: 13px;
        height: 13px;
    }

}

@media(max-width:767px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: 570px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 50%;
        padding-right: 2px;
        min-width: 120px;
    }

    .main-content-chat .container-fluid .row .left-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 484px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 20px 0px 0 0;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: 660px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 60%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn {
        width: 30%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn .sign_submit {
        font-size: 13px;
    }

}
</style>