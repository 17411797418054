<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part cd">
<div>
    <h5>Membership </h5>
    <div class="bottom_cd" id="container" style="flex-wrap: unset !important;">
        <div v-if="resmessage!=''" class="success" style="width: 100%; margin-left: 15px;margin-right: 15px;">{{resmessage}}</div>
        <div v-if="errormessage!=''" class="error" style="width: 100%; margin-left: 15px;margin-right: 15px;">{{errormessage}}</div>
        
        <div class="wrapper-flex" v-bind:key="member.id" v-for="(member) in membership" :class="member.membershipDetails.length>0 ? 'block' : 'none'">
            <div class="thirty-three" v-if="member.membershipDetails.length>0" >
                <div class="ribbon-wrapper" v-if="member.membershipType == membershipType">
                    <div class="ribbon-tag">Activate</div>
                </div>
                <div class="blog_wpr">
                    <!-- <router-link :to="'/event/'" class="">
                    <img src="@/assets/b-1.jpg" >	
                    </router-link> -->
                    
                    <div class="thirty-three-text" >
                        <h1 style="margin-bottom: 25px; background: -webkit-linear-gradient(#ffba00, #50472e);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">{{member.membershipType}}</h1>
                        <!-- <h1><router-link :to="'/event/'" class="">{{member.membershipDetails}}</router-link></h1> -->
                        <div :class="(details.periodType == periodType && member.membershipType == membershipType)? 'priodType active':'priodType'" v-bind:key="details.id" v-for="(details) in member.membershipDetails">
                            <span v-if="member.periodType != periodType">
                                <p v-on:click="details.showDiv = !details.showDiv" class="tt-u sub-text" style="cursor:pointer;color:red;font-size:20px;margin-bottom:10px">
                                    <strong style="color:red;float:color:#cda53a;">{{details.periodType}} </strong>
                                    <strong v-show="details.showDiv==false || details.showDiv==null" style="float:right;"><i class="fas fa-caret-down"></i></strong>
                                    <strong v-show="details.showDiv" style="float:right;"><i class="fas fa-caret-up"></i></strong>
                                </p> 
                                <p v-show="details.showDiv" style="text-transform: inherit;-webkit-transition:1s;" class="tt-u sub-text" v-html="details.description"></p>
                                <h4 v-if="details.membershipType!='Free'">Main Price: $ {{formatPrice(details.price)}}</h4>
                                <h4 v-else>Main Price: $ 0.00</h4>
                                <h4 v-if="details.membershipType!='Free'">Discount Price: $ {{formatPrice(details.price-details.discountPrice)}} 
                                    <span style="color:red;font-size:15px;color:#cda53a" class="tt-u sub-text">(Discount uo to <br>$ {{formatPrice(details.discountPrice)}})</span>
                                </h4>
                                <h4 v-else>Discount Price: $ 0.00 
                                    <span style="color:red;font-size:15px;color:#cda53a" class="tt-u sub-text">(Discount uo to <br>$ 0.00)</span>
                                </h4>
                                <p :class="((details.periodType != periodType || member.membershipType != membershipType) && (details.membershipType!='Free'))? '' : 'mb'" >Valid Till : 
                                    <strong v-if="details.periodType=='Monthly'">
                                        <span style="color:#cda53a">{{ moment().startOf("month").format('MMMM')}}</span>
                                        {{ moment().format("Do YYYY") }} - 
                                        <span style="color:#cda53a">{{ moment().startOf("month").add(1, 'months').format('MMMM') }} </span>
                                        {{ moment().add(1, 'months').format("Do YYYY") }}
                                    </strong>
                                    <strong v-if="details.periodType=='Yearly'">
                                        <span style="color:#cda53a">{{ moment().startOf("month").format('MMMM')}}</span>
                                        {{ moment().format("Do YYYY") }} - 
                                        <span style="color:#cda53a">{{ moment().startOf("month").add(1, 'years').format('MMMM') }} </span>
                                        {{ moment().add(1, 'years').format("Do YYYY") }}
                                    </strong>
                                    <strong v-if="details.periodType=='Free'">
                                        <!-- <span>{{ moment(details.created_at).startOf("month").format('MMMM')}}</span>
                                        {{ moment(details.created_at).format("Do YYYY") }} - 
                                        <span>{{ moment(details.created_at).startOf("month").add(7, 'days').format('MMMM') }} </span>
                                        {{ moment(details.created_at).add(7, 'days').format("Do YYYY") }} -->
                                        Free to use
                                    </strong>
                                </p>
                                <button @click="showPaymentCard(member.membershipType,details.periodType,details.price-details.discountPrice,details.id)" v-if="(details.periodType != periodType || member.membershipType != membershipType) && details.membershipType!='Free'" class="sign_submit"> Pay </button>
                            </span>
                            <span v-else>
                                <p v-on:click="details.showDiv = !details.showDiv" class="tt-u sub-text" style="cursor:pointer;color:red;font-size:20px;margin-bottom:10px">
                                    <strong style="color:red;float:;color:#cda53a;">{{details.periodType}} </strong>
                                    <strong v-show="details.showDiv==false || details.showDiv==null" style="float:right;"><i class="fas fa-caret-down"></i></strong>
                                    <strong v-show="details.showDiv" style="float:right;"><i class="fas fa-caret-up"></i></strong>
                                </p> 
                                <p v-show="details.showDiv" style="text-transform: inherit;-webkit-transition:1s;" class="tt-u sub-text" v-html="details.description"></p>
                                <h4 v-if="details.membershipType!='Free'">Main Price: $ {{formatPrice(details.price)}}</h4>
                                <h4 v-else>Main Price: $ 0.00</h4>
                                <h4 v-if="details.membershipType!='Free'">Discount Price: $ {{formatPrice(details.price-details.discountPrice)}} 
                                    <span style="color:red;font-size:15px" class="tt-u sub-text">(Discount uo to <br>$ {{formatPrice(details.discountPrice)}})</span>
                                </h4>
                                <h4 v-else>Discount Price: $ 0.00 
                                    <span style="color:red;font-size:15px;color:#cda53a;" class="tt-u sub-text">(Discount uo to <br>$ 0.00)</span>
                                </h4>
                                <p style="margin-bottom:65px;">Valid Till : 
                                    <strong v-if="details.periodType=='Monthly'">
                                        <span style="color:#cda53a;">{{ moment(details.created_at).startOf("month").format('MMMM')}}</span>
                                        {{ moment(details.created_at).format("Do YYYY") }} - 
                                        <span style="color:#cda53a;">{{ moment(details.created_at).startOf("month").add(1, 'months').format('MMMM') }} </span>
                                        {{ moment(details.created_at).add(1, 'months').format("Do YYYY") }}
                                    </strong>
                                    <strong v-if="details.periodType=='Yearly'">
                                        <span style="color:#cda53a;">{{ moment(details.created_at).startOf("month").format('MMMM')}}</span>
                                        {{ moment(details.created_at).format("Do YYYY") }} - 
                                        <span style="color:#cda53a;">{{ moment(details.created_at).startOf("month").add(1, 'years').format('MMMM') }} </span>
                                        {{ moment(details.created_at).add(1, 'years').format("Do YYYY") }}
                                    </strong>
                                    <strong v-if="details.periodType=='Free'" >
                                        <span style="color:#cda53a;">{{ moment(details.created_at).startOf("month").format('MMMM')}}</span>
                                        {{ moment(details.created_at).format("Do YYYY") }} - 
                                        <span style="color:#cda53a;">{{ moment(details.created_at).startOf("month").add(7, 'days').format('MMMM') }} </span>
                                        {{ moment(details.created_at).add(7, 'days').format("Do YYYY") }}
                                    </strong>
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
</div>
</div>
</div>
<div class="modelPopups" v-if="paymentCard" style="display:block">
    <div class="content_wrapper" style="width:100% !important;height:100% !important;">
        <div class="modal_header">
            <h2>Payment</h2>
            <button @click="close" class="new_submit"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body" style="height:90%;">
            <div v-if="resmessage!=''" class="success" style="width: 100%; margin-bottom: 15px;background: green;padding: 10px;text-align: center;">{{resmessage}}</div>
            <div v-if="errormessage!=''" class="error" style="width: 100%; margin-bottom: 15px;background: #c71e1e;padding: 10px;text-align: center;">{{errormessage}}</div>
            <form id="jobpost-form" v-on:submit.prevent="payNow" >
            <div class="form_grp" style="width:48%;float:left;">
                <h2 v-if="types==2">Company Details</h2>
                <h2 v-if="types==3">Agent Details</h2>
                <input type="hidden" v-model="link" >
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>First Name: </label> 
                        <input type="text" v-model="state.fname" placeholder="Enter First Name" name="fname">
                        <!-- <span v-if="v$.fname.$error">
                            {{ v$.fname.$errors[0].$message }}
                        </span> -->
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Last Name: </label>
                        <input type="text" v-model="state.lname" placeholder="Enter Last Name" name="lname">
                        <!-- <span v-if="v$.lname.$error">
                            {{ v$.lname.$errors[0].$message }}
                        </span> -->
                    </div>
                </div> 
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Email: </label>
                        <input type="text" v-model="state.email" placeholder="Enter Email" name="email">
                        <!-- <span v-if="v$.email.$error">
                            {{ v$.email.$errors[0].$message }}
                        </span> -->
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Phone: </label>
                        <input type="text" v-model="state.phone" placeholder="Enter Phone Number" name="phone">
                        <!-- <span v-if="v$.lname.$error">
                            {{ v$.lname.$errors[0].$message }}
                        </span> -->
                    </div>
                </div> 
                <div class="item_grp">
                    <div >
                        <label>Address: </label>
                        <input type="text" v-model="state.address" placeholder="Enter Address" name="address">
                        <!-- <span v-if="v$.email.$error">
                            {{ v$.email.$errors[0].$message }}
                        </span> -->
                    </div>
                   
                </div> 
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Country: </label>
                        <select v-model="state.country.id" autocomplete="off" class="hlfinput">
							<option value="">Select Country</option>
							<option v-for="cntry in countryList" :value="cntry.iso" v-bind:key="cntry.iso">{{ cntry.nicename }}</option>
						</select>
                        
                    </div>
                    <div style="width:48%;float:right;">
                        <label>State: </label>
                        <input type="text" v-model="state.state" placeholder="Enter State" name="state">
                        
                    </div>
                </div> 
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>City: </label>
                        <input type="text" v-model="state.city" placeholder="Enter City" name="city">
                        <!-- <span v-if="v$.email.$error">
                            {{ v$.email.$errors[0].$message }}
                        </span> -->
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Pin Code: </label>
                        <input type="text" v-model="state.pincode" placeholder="Enter Pincode" name="pincode">
                        <!-- <span v-if="v$.lname.$error">
                            {{ v$.lname.$errors[0].$message }}
                        </span> -->
                    </div>
                </div> 
                <!-- <div class="item_grp">
                    <button type="submit" class="new_submit">Join Live Events Now</button>
                </div> -->
            </div>
            <div class="form_grp" style="width:48%;float:right;">
                <h2>Payment Details</h2>
                <!-- <input type="hidden" v-model="meetingId" >
                <input type="hidden" v-model="link" > -->
                <div class="item_grp">
                    <div >
                        <label>Name On Card: </label>
                        <input type="text" v-model="state.cardName" placeholder="Enter Name on Card" name="email">
                        <!-- <span v-if="v$.email.$error">
                            {{ v$.email.$errors[0].$message }}
                        </span> -->
                    </div>
                </div>
                <div class="item_grp">
                    <div >
                        <label>Card Number: </label> 
                        <input size='16' @input="acceptCardNumber" type="number" v-model="state.cardNumber" placeholder="Enter Card Number" name="cardNumber">
                        <span v-if="cardNumberError">
                            Card Number Must be 16 Degits
                        </span>
                    </div>
                </div>
                <div class="item_grp">
                    <div style="width:32%;float:left;">
                        <label>Month: </label>
                        <!-- <input size='2' @input="acceptMonth" type="number" v-model="state.cardMonth" placeholder="Enter Month" name="cardMonth"> -->
                        <select @change="acceptMonth" v-model="state.cardMonth" name="cardMonth">
                            <!-- <option value ="">Select Month</option> -->
                            <option value ="1">01</option>
                            <option value ="2">02</option>
                            <option value ="3">03</option>
                            <option value ="4">04</option>
                            <option value ="5">05</option>
                            <option value ="6">06</option>
                            <option value ="6">07</option>
                            <option value ="8">08</option>
                            <option value ="9">09</option>
                            <option value ="10">10</option>
                            <option value ="01">11</option>
                            <option value ="12">12</option>
                        </select>
                        <span v-if="cardMonthError">
                            Month Must be 2 Degits
                        </span>
                    </div>
                    <div style="width:33%;float:left;margin-left:10px;">
                        <label>Year: </label>
                        <input size='2' @input="acceptYear" type="number" v-model="state.cardYear" placeholder="Enter Year" name="cardYear">
                        <!-- <select @change="acceptYear" v-model="state.cardYear" name="cardYear">
                            <option value ="">Select Year</option>
                            
                            <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                        </select> -->
                        <span v-if="cardYearError">
                            Year Must be 2 Degits
                        </span>
                    </div>
                    <div style="width:32%;float:right;">
                        <label>CVC: </label>
                        <input size='4' @input="acceptCVC" type="number" v-model="state.cardCVC" placeholder="Enter CVC" name="cardCVC">
                        <span v-if="cardCVCError">
                            CVC Must be 3 or 4 Degits
                        </span>
                    </div>
                </div>  
                <h2>Package Details</h2>
                <div class="item_grp">
                    <div >
                        <label>Package Name: {{membershipPayType}} Package ({{planPayType}})</label>
                        <input type="hidden" v-model="planId" name="planId" >
                        <input type="hidden" v-model="membershipPayType" name="membershipPayType" >
                        <input type="hidden" v-model="planPayType" name="planPayType" >
                    </div>
                </div>
                <div class="item_grp">
                    <div >
                        <label>Package Price: ${{ formatPrice(discountPayPrice)}}</label>
                    </div>
                </div> 
                <div class="item_grp">
                    <button type="submit" class="sign_submit" :disabled="buttonclick" style="margin-top:0px; height:45px;">
                        <span v-if="buttonclick==true" style="color:white">Loading. . . . .</span>
                        <span v-else style="color:white"> Pay Now</span>
                    </button>
                    <!-- <button type="submit" class="sign_submit">Pay Now</button> -->
                </div>
            </div>
            </form>
        </div>
    </div>
</div>

</template>


<script>
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'

const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'Membership',
    components:{
        Dashboardheader,
        Dashboardsidebar
    },
    data() {
        return {
            moment:moment,
            storageData: mirror,
            storage: storage,
            isLoading: true,
            imagepath:{},
            membership:{},
            memberDetails:{},
            showDiv:false,
            membershipType:'',
            periodType:'',
            today:'',
            paymentCard:false,
            state:'',
            countryList:{},
            membershipPayType:'',
            planPayType:'',
            discountPayPrice:'',
            resmessage:'',
            errormessage:'',
            planId:'',
            cardNumberError:false,
            cardMonthError:false,
            cardYearError:false,
            cardCVCError:false,
            yearList:[],
            buttonclick:false,
            types:''
            //enrollYear: new Date().getFullYear(),
        }
        //years: [...Array(6)].map((a,b)=> new Date().getFullYear() + b),
    },
    computed: {
        years() {
            const year = new Date().getFullYear()
            return Array.from({ length: year + 10 }, (value, index) => year + index);
        },
    },
    async created () {
        this.getMembershipDetails();
        this.getResults();
        console.log("MIRROR: ", mirror);
        this.types = mirror.USERTYPE;
        // console.log("MT",this.membershipType);
        // console.log("PT",this.periodType);
       //this.yearList = 
	},
    mounted() {
        this.getResults();
    },
    methods:{
        getMembershipDetails(){
            this.AuthUserId = mirror.AUTHID;
            this.userType = mirror.USERTYPE;
            this.membershipType = mirror.MEMBERSHIP_TYPE;
            this.periodType = mirror.PERIOD_TYPE;
            this.imagepath = API_IMAGE_PATH+'event/';
            this.today = moment().format();
        },
        async showPaymentCard(membershipType,planType,discountPrice,planId){
            this.membershipPayType = membershipType;
            this.planPayType = planType;
            this.discountPayPrice = discountPrice;
            this.planId = planId;
            this.paymentCard = true;
            //alert("hi");
            const inputData = {
                userId: mirror.AUTHID
            };
            const responce1 = await axios.post(API_BASE_URL + `userDetails`, inputData);
            this.state = responce1.data;

            let countryData = await axios.get(API_BASE_URL + 'country');
            this.countryList = countryData.data;

            //alert("hi2");
            console.log("RESP:",this.state);

            //this.fname = responce.data.fname;

            //const responce = axios.post(API_BASE_URL + `userDetails`, inputData);
            //console.log("RESP:",responce);
        },
        getResults() {
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `membershipList`, inputData)
                .then(response => {
                    this.membership = response.data;
                    console.log("Data:",response.data);
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        async payNow(){
            this.buttonclick = true;
            //this.v$.$validate(this.v$.$error);
            if (this.cardNumberError==false || this.cardMonthError==false || this.cardYearError==false || this.cardCVCError==false) {
                if (this.state.cardNumber && this.state.cardMonth && this.state.cardYear && this.state.cardCVC ) {
                    const param    = {
                        userId:(mirror.AUTHID)? mirror.AUTHID:"",
                        userName:this.state.fname+' '+this.state.lname,
                        email:this.state.email,
                        planId:this.planId,
                        cardName:this.state.cardName,
                        cardNumber:this.state.cardNumber,
                        cardMonth:this.state.cardMonth,
                        cardYear:this.state.cardYear,
                        cardCVC:this.state.cardCVC,

                        phone:this.state.phone,
                        address:this.state.address,
                        city:this.state.city,
                        country:this.state.country.id,
                        state:this.state.state,
                        pincode:this.state.pincode,
                        membershipPayType:this.membershipPayType,
                        planPayType:this.planPayType
                    }
                    console.log("param",param);
                    await axios.post(API_BASE_URL + 'paymentDetailSubmitted', param)
                    
                    .then(response => { 
                        //this.showZoomForm = false;  
                        //this.addModal = false;                     	
                        console.log("PAYMENT DATA: ",response);
                        // this.state.fname = '';
                        // this.state.lname = '';
                        // this.state.email = '';
                        if(response.data.success=='success'){
                            this.storage.set('MEMBERSHIP_TYPE', response.data.membershipType)
							this.storage.set('PERIOD_TYPE', response.data.planType)
                            this.paymentCard = false;
                            this.errormessage = '';
                            this.resmessage = response.data.message;
                            this.getMembershipDetails();
                            var element = document.getElementById("container");
                            element.scrollIntoView();
                            this.buttonclick = false;
                        } else {
                            this.resmessage = '';
                            this.errormessage = response.data.message;
                            this.buttonclick = false;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                } else {
                   this.errormessage = 'All Fields are Mandatory';
                   this.buttonclick = false;
                }
            } else {
                this.errormessage = 'All Fields are Mandatory';
                this.buttonclick = false;
            }
            setTimeout(() => {
                this.errormessage = '';
                this.resmessage = '';
                //console.log(this.errormessage);
            }, 5000);
            //this.addModal = false;
        },
        acceptNumber() {
			var x = this.state.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			this.state.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		},
        acceptCardNumber(event) {
			const value = event.target.value
            //console.log(value, this.cardNumber)
            if (String(value).length <= 16) {
                this.cardNumberError = false;
            } else {
                this.cardNumberError = true;
            }
            //this.$forceUpdate();
		},
        acceptMonth(event) {
			const value = event.target.value
            //console.log(value, this.cardNumber)
            if (String(value).length <= 2) {
                this.cardMonthError = false;
            } else {
                this.cardMonthError = true;
            }
            //this.$forceUpdate();
		},
        acceptYear(event) {
			const value = event.target.value
            //console.log(value, this.cardNumber)
            if (String(value).length <= 2) {
                this.cardYearError = false;
            } else {
                this.cardYearError = true;
            }
            //this.$forceUpdate();
		},
        acceptCVC(event) {
			const value = event.target.value
            //console.log(value, this.cardNumber)
            if (String(value).length <= 4) {
                this.cardCVCError = false;
            } else {
                this.cardCVCError = true;
            }
            //this.$forceUpdate();
		},
        close(){
            this.paymentCard = false
        },
    },
    beforeRouteEnter(to, from, next) {
        if (storage.get('TOKEN')) {
            next();
        }
        else {
            next('/login');
        }
    }
}
</script>

<style scoped>
.mb {
    margin-bottom:65px;
}
.wrapper-flex.block{
    display: block;
}
.wrapper-flex.none{
    display: none;
}
.wrapper-flex{
    display: flex;
    flex-wrap: wrap;
}
.middle-part.dashboard .rigt-part.cd .bottom_cd {
    display: grid;
    /* gap: 15px; */
    grid-template-columns: 1fr 1fr;
}
.middle-part.dashboard .rigt-part.cd .thirty-three {
    width: 100%;
    /* padding: 15px; */
}
.wrapper-flex>*{
    flex-grow: 1;
    width: auto;
}
.new_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    /* padding: 6px 8px; */
    margin: 7px;
}
.sign_submit {
	width: 100%;
	height: 55px;
	background: black;
	border-radius: 4px;
	border: 5px solid #cda53a;
	font-size: 19px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
    cursor:pointer;
    margin-top: 15px;
    margin-bottom: 3px;
}
.thirty-three .ribbon-wrapper { 
    width: 88px; 
    height: 88px; 
    overflow: hidden; 
    float: right;
    /* position: absolute;
    
    top: 211px;
    
    margin-left: 368px; */
    }
.thirty-three .ribbon-tag { 
    text-align: center; 
    -webkit-transform: rotate(45deg); 
    -moz-transform: rotate(45deg); 
    -ms-transform: rotate(45deg); 
    -o-transform: rotate(45deg); 
    position: relative; padding: 6px 0; 
    left: -4px; top: 15px; width: 120px; 
    color: #ffffff; -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3); 
    -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3); 
    text-shadow: rgba(255,255,255,0.5) 0px 1px 0px; 
    background: #343434; 
    }

.thirty-three .ribbon-tag:before, .ribbon-tag:after { content: ""; 
border-top: 3px solid #50504f; border-left: 3px solid transparent; 
border-right: 3px solid transparent; position:absolute; bottom: -3px;
}
.thirty-three .ribbon-tag:before { left: 0;}
.thirty-three .ribbon-tag:after { right: 0;}
.thirty-three .ribbon-tag { background: rgba(203,96,179,1); }
.thirty-three .ribbon-tag { background: green; }

/* p , ul{
    margin-left: 18px !important;
    list-style-type: disclosure-closed !important;
}
p , ul, li {
    margin-left: 18px !important;
    list-style-type: disclosure-closed !important;
} */
.pagination {
    cursor: pointer;
		justify-content: center;
		padding: 10px;
	}

	.pagination {
		margin-top: 0;
	}
.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width:100%;
}
.priodType {
    background: #202023;
    padding: 10px;
    margin-bottom: 15px;
    margin-top: 7px;
}
.active {
    border-bottom: 10px solid green;
}

.pagination a.next {
	color: #d70b0b;
	border-color: #d70b0b;
	margin-left: 10px;
}

.pagination a.next:hover {
	background: #d70b0b;
	color: #fff;
}
.read_more {
	color: #939393;
	border: solid 1px #939393;
	padding: 7px 25px;
	text-decoration: none;
	display: inline-block;
	margin-top: 20px;
	font-size: 16px;
}

.read_more:hover {
	background: #cfcfcf;
	color: #000;
}

.joblist_Wpr{
  background: #1b1b1b;
  padding: 30px 60px;
  position: relative;
}
.joblist_Wpr:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-image: url('~@/assets/figure-bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat ;
}
.job_list > li{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255,255,255,0.05);
  padding: 40px 0;
  position: relative;
  z-index: 2;
}
.job_list > li:last-child{
  border:0;
}
@media(max-width:991px){
  .joblist_Wpr{
    padding: 10px 20px;
  }
  .job_list > li{
    padding: 20px 0;
  }
  .modal_body{overflow-y: scroll;}
  .modal_body .form_grp .item_grp > div {
  margin:0 1px!important;}
}

@media(max-width:740px){
.form_grp {
  width: 100% !important; float:none !important;
}
.modal_body .form_grp .item_grp > div {
  width: 100% !important;
  float: none !important;
  margin-left: 0 !important;
}
.modal_header, .modal_body{overflow-y: scroll;}
.new_submit{
    margin: 20px !important;
}
}

@media(max-width:575px){
  .srch-lft:first-of-type{
    width: 100%;
    order: 2;
  }
}
</style>