import { createApp } from 'vue'
import vueTheStorages from 'vue-the-storages'
import App from './App.vue'
import router from './routes'
import VueGoogleMaps from '@fawmi/vue-google-maps';
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

createApp(App).use(router, vueTheStorages).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBJjw1rH0n5vRpKNCNwDzbkRAqdyAvmjRk',
        libraries: "places"
    },
}).use(moshaToast).mount('#app')


