<template>

<footer>
    <div class="arrow" style="bottom: auto;height: 28px;margin-top:-35px;"></div>
    <div class="container">
        <div class="testimonial" style="margin-bottom:35px;">
            <h3 class="section_header text-center">Click to Join Live Event</h3>
            <ZoomSlider/>
        </div>
        <div class="footer">
            <div class="logo"><img src="@/assets/logo.png" alt=""></div>
            <p>All the information contained in this website is highly confidential. We will take all the precautions and we reserve any legal action in the relevant forum in case of an improper use of the information contained in this website. Our site uses cookies to save shopping cart data, browsing history and personalize the experience with automatically triggered emails.</p>
        </div>
        <div class="copyright">
            <ul class="social">
				<li><a href="https://www.facebook.com/B.c.rodge" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
				<li><a href="https://www.instagram.com/byron_rodgers" target="_blank"><i class="fab fa-instagram"></i></a></li>
				<li><a href="https://twitter.com/byron_rodgers" target="_blank"><i class="fab fa-twitter"></i></a></li>
				<li><a href="https://www.linkedin.com/in/byronrodgers" target="_blank"><i class="fab fa-linkedin"></i></a></li>
				<li><a href="https://www.youtube.com/user/TheByronRodgers" target="_blank"><i class="fab fa-youtube"></i></a></li>
            </ul>
            <p><router-link :to="'/privacy-and-policy'" target="_blank" style="color:white;border-bottom-style:inset;">Privacy Policy</router-link> <b style="color:#cda53a;">&</b> <router-link :to="'/terms-and-condition'" target="_blank" style="color:white;border-bottom-style:inset;"><b>Terms of Service</b></router-link></p>
            <p>Copyright © {{moment().format('YYYY')}} EP Specialist</p>
        </div>
    </div>
</footer>
</template>

<script>
import moment from "moment";
import ZoomSlider from '../components/ZoomSlider.vue'
export default {
    name: 'Footer',
    components: {
        ZoomSlider
    },
    data() {
        return {
            moment: moment,
            //modalLoginMembership : false,
        }
    }
}
</script>

<style scoped>
    footer{
        border-top: 1px solid rgba(255,255,255,0.1);
        padding: 55px 0 40px;
        float:left;
        width:100%;
    }
    .footer{
        display: flex;
        align-items: center;
        padding-bottom: 45px;
    }
    .footer .logo{
        margin-right:30px;
        line-height: 0;
    }
    .footer .logo img{
        max-width: 212px;
    }
    .footer p{
        font-size: 15px;
        line-height: 20px;
        color: #a1a1a1;
        padding-left: 30px;
        border-left: 2px solid #c61e1e;
    }
    .copyright{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .copyright p{
        margin-bottom: 5px;
    }
    .social{
        display: flex;
        align-items: center;
    }
    .social li{
        padding-right: 5px;
    }
    .social li a{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #fff;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background: -webkit-linear-gradient(#ffba00, #94824f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
    }

    @media(max-width:767px){
        .footer{
            flex-direction: column;
        }
        .footer .logo{
            margin: 0 0 15px 0;
            padding-bottom: 15px;
            border-bottom: 2px solid #c61e1e;
        }
        .footer p{
            padding-left: 0;
            border: 0;
            text-align: center;
        }
        .copyright p{
        margin-bottom: 10px;
        font-size: 14px;
    }
    }
    @media(max-width:575px){
        .copyright{
            flex-direction: column;
        }
        .social{
            margin-bottom: 15px;
        }
    }

    
</style>
<style>
.arrow {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 100px;
  border-right: 1px solid #fff;
}
.arrow::before {
  position: absolute;
  content: "";
  left: -6px;
  bottom: 100%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.arrow::after {
  position: absolute;
  content: "";
  left: -4px;
  bottom: 0;
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
}
i.fab.fa-facebook-f:hover{
        color:#2856b1;
    }
    i.fab.fa-instagram:hover{
        color:#ad1d92;
    }
    i.fab.fa-twitter:hover{
        color:#52c8fb;
    }
    i.fab.fa-linkedin:hover{
        color:#038dcf;
    }
    i.fab.fa-youtube:hover{
        color:red;
    }
</style>
