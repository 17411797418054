<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part" id="container">
    <h3>Agent List 
        <span v-if="searchShow" @click="resetData" style="color:rgba(255, 255, 255, 0.6);margin-left:5px !important;box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;float:right;background:#28282b;border-radious:50%;padding: 5px 10px 5px 10px;cursor:pointer;">Reset</span>
        <span @click="searchShow = !searchShow" style="box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;float:right;background:#28282b;border-radious:50%;color:red;padding: 5px 10px 5px 10px;cursor:pointer;"><i class="fas fa-search"></i></span>
    </h3>
    <div>
    <div v-show="searchShow" class="srch-prt" style="box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;padding: 20px 20px 20px !important;background:#28282b;margin-top: 20px;">
        <form class="search-form" v-on:submit.prevent="filter">
            <div class="search-box" style="margin-bottom:">
                <input v-on:keyup="filter" v-model="aName" type="text" name="agentNamess" placeholder="Agent Name" style="border:none;height:30px !important;background: #28282b;border-bottom: solid 1px #3d3d3d;" > 
            </div>
            <div class="search-box">
                <select v-on:change="filter" v-model="isVerified" style="height:30px !important;background: #28282b;border:none;border-bottom: solid 1px #3d3d3d;">
                    <option value="" disabled selected hidden >Select Is Verified</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div class="search-box">
                <select v-on:change="filter" v-model="membership" style="height:30px !important;background: #28282b;border:none;border-bottom: solid 1px #3d3d3d;">
                    <option value="" disabled selected hidden>Select Membership Type</option>
                    <option value="Free">Free</option>
                    <option value="Basic">Basic</option>
                    <option value="Premium">Premium</option>
                </select>
            </div>
            <div class="search-box">
                <select v-on:change="filter" v-model="level" style="height:30px !important;background: #28282b;border:none;border-bottom: solid 1px #3d3d3d;">
                    <option value="" disabled selected hidden>Select Level</option>
                    <option value="Experience">Experience </option>
                    <option value="Certification">Certification </option>
                </select>
            </div>

            <div class="search-box">
                <select v-on:change="filter" v-model="driving" style="height:30px !important;background: #28282b;border:none;border-bottom: solid 1px #3d3d3d;">
                    <option value="" disabled selected hidden>Select Driving Course</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div class="search-box">
                <select v-on:change="filter" v-model="medical" style="height:30px !important;background: #28282b;border:none;border-bottom: solid 1px #3d3d3d;">
                    <option value="" disabled selected hidden>Select Medical Training</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div class="search-box">
                <select v-on:change="filter" v-model="experience" style="height:30px !important;background: #28282b;border:none;border-bottom: solid 1px #3d3d3d;">
                    <option value="" disabled selected hidden>Select Experience</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div class="search-box">
                <select v-on:change="filter" v-model="protectSchool" style="height:30px !important;background: #28282b;border:none;border-bottom: solid 1px #3d3d3d;">
                    <option value="" disabled selected hidden>Select Protection School</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
        </form>
    </div>
    </div>
    <!-- <div class="gt-started-frm" v-if="totalCandidateCount >0"> -->
    <div >
        <!--<button v-on:click="filter" type="button" class="filter_btn"><i class="fas fa-align-left"></i></button>-->
        <div class="candidate_list">
            <ul>
                <AgentCardMain :callback="appliedJobsDetails" :agentData="agent" v-for="agent in allAgentList.data" v-bind:key="agent" icon="icon2.png" />
            </ul>
        </div>
        <v-pagination
            v-if="is_show" 
			v-model="page"
			:pages="allAgentList.last_page"
			:range-size="1"
			active-color="#DCEDFF"
			@update:modelValue="filter"
			/>
    </div>
    <div v-if="nofound">
        <p>Sorry! no data found.</p>
    </div>

</div>
</div>
</div>
<!-- <div class="modelPopups" v-if="addModal" style="display:block">
    <div class="content_wrapper">
        <div class="modal_header">
            <h2>View Application of {{jobDetails.fname}} {{jobDetails.lname}}</h2>
            <button @click="close"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="form_grp">
                <input type="hidden" v-model="jobId" >
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>First Name: <span>{{jobDetails.fname}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Last Name: <span>{{jobDetails.lname}}</span></label>
                    </div>
                </div> 
                <div class="item_grp"><label>Primary Email: <span>{{jobDetails.email}}</span></label> 
                </div>
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Experience Level: <span>{{experience_new}}</span></label>
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Area(s) of Interest: <span>{{area_interest}}</span></label> 
                    </div>
                </div> 
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Communities of Interest: <span>{{comm_interest}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Preferred Language: <span>{{preferred_language}}</span></label> 
                    </div>
                </div>

                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Primary Skill Set: <span>{{primary_skill}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Primary Country: <span>{{country}}</span></label> 
                    </div>
                </div>
                <div class="item_grp">
                    <label>Resume: 
                        <span> {{jobDetails.resume}}
                        
                        <a :href="url" target="_blank" v-if="jobDetails.resume!==null">
                            Click To Download
                        </a>
                        </span>
                    </label>
                </div>  
            </div>
        </div>
    </div>
</div> -->
</template>

<script>

import axios from 'axios'
// { BASE_URL } from '../config'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
//import AgentCard from '../components/AgentCard.vue'
import AgentCardMain from '../components/AgentCardMain.vue'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import { createLocal } from 'the-storages'
import moment from "moment";
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'DashboardGetstarted',
    components:{
        Dashboardheader,
        Dashboardsidebar,
        AgentCardMain,
        VPagination
    },
    data() {
        return {
            searchShow:false,
            storageData: mirror,
            storage: storage,
            moment: moment,
            allAgentList: {},
            totalAgentCount:this.totalAgentCount,
            current: 1,
            error: '',
            error2:'',
            is_show:false,
			nofound:false,
            is_loader:true,
            jobDetails:{},
            addModal:false,
            imagepath:'',
            isVerified:'',
            membership:'',
            level:'',
            driving:'',
            medical:'',
            experience:'',
            aName:'',
            protectSchool:'',
        }
    },
    mounted() {
        this.filter();
    },
    created(){
        this.imagepath = API_IMAGE_PATH+'agent/';
    },
    methods:{
        resetData(){
            this.isVerified='';
            this.membership='';
            this.level='';
            this.driving='';
            this.medical='';
            this.experience='';
            this.aName='';
            this.protectSchool='';
            this.filter();
        },
        filter(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID,
                    agentName:this.aName,
                    isVerified:this.isVerified,
                    membership:this.membership,
                    level:this.level,
                    driving:this.driving,
                    medical:this.medical,
                    experience:this.experience,
                    protectSchool:this.protectSchool,
                };
                axios.post(API_BASE_URL + `allAgentList?page=${page}`, inputData)
                .then(response => {
                    this.allAgentList = response.data;
                    this.totalAgentCount = response.data.total;
                    console.log("DATA3:",response.data);

                    this.is_loader=true;
                    this.is_show=this.allAgentList.last_page>1?true:false;

                    if(this.allAgentList.data?.length>0){
                        this.nofound=false;
                        this.is_loader=false;
                    }   else    {
                        this.nofound=true;
                        this.is_loader=false;
                    }
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
            var element = document.getElementById("container");
	element.scrollIntoView();
            // return this.postList.filter(post => {
            //     return post.title.toLowerCase().includes(this.search.toLowerCase())
            // })
        },
        async appliedJobsDetails(id) {
			//console.log(id)
            const param    = {
                userId:mirror.AUTHID,
                jobId:id
            }
            const response2= await axios.post(API_BASE_URL + 'appliedJobDetails',param);
            this.jobDetails = response2.data;
            
            this.experience_new = response2.data.experience_new==null ? '':response2.data.experience_new.experience;
            this.area_interest = response2.data.area_interest==null ? '':response2.data.area_interest.name;
            this.comm_interest = response2.data.comm_interest==null ? '':response2.data.comm_interest.name;
            this.preferred_language = response2.data.preferred_language==null ? '':response2.data.preferred_language.name;
            this.primary_skill = response2.data.primary_skill==null ? '':response2.data.primary_skill.name;
            this.country = response2.data.country==null ? '':response2.data.country.name;
            this.url = response2.data.resume ? this.imagepath+response2.data.resume : '';
            console.log("URL:",this.url);
            console.log("RESULT",response2.data);
            this.addModal = true;
        },
        close(){
            this.addModal = false
        },
	},
	beforeRouteEnter(to, from, next) {
		if (storage.get('TOKEN')) {
			if(storage.USERTYPE!=3) {
				next();
                //console.log("TYPE: ",storage.USERTYPE);
            } else {
				next('/login');	
            }
        } else {
            next('/login');
        }
    }
}

</script>

<style scoped>
.modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
}
.candidate_list ul{
    margin-top: 20px;
    background: #28282b;
    box-shadow: 0 0 6px rgb(0,0,0,0.5);
}
.candidate_list ul li{
    padding: 20px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.search-form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
}
.search-form .search-box{
    float: unset;
    width: 24%;
    margin: 0;
}
.middle-part.dashboard .rigt-part select{ padding:0;}
@media (max-width: 1399px) {
}
@media (max-width: 1199px) {
    

}
@media (max-width: 767px) {
    
    .search-form .search-box{
        width: 48%;
    }
}
</style>