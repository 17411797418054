<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
 <div class="rigt-part cd">
 
<div class="candidate-sec">
 <div class="fifty-candidate browse-job"><h2>Browse Jobs</h2><h4>Total Jobs <span>2144</span></h4><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
  <div class="srch-btm">
   <div class="srch-lft"><a href="#" class="search-btn">SEARCH</a></div>
    <div class="srch-lft">
      <div class="prfl-total">
        <div class="prfl-box"><img src="@/assets/dash/search-file-img1.jpg" alt=""/></div>
         <div class="prfl-box"><img src="@/assets/dash/search-file-img2.jpg" alt=""/></div>
          <div class="prfl-box"><img src="@/assets/dash/search-file-img3.jpg" alt=""/></div>
            <a href="#" class="plus"><img src="@/assets/dash/plus.jpg" alt=""/></a>
      </div>
    
    </div>
  </div>
 </div>
 <div class="fifty-candidate">
 <div class="famly-img">
   <div class="family-text">
     <div>
       <img src="@/assets/dash/apply-joining-text.png" alt=""/>
       <a href="#" class="search-btn">Apply Now</a>
        </div>
   </div>
   </div> 
  
 </div>
</div>

<h5>Executive Protection Podcast </h5>

<div class="prodcast_slider">
<DashboardPodcastslider/>
<div class="stroke_clip"></div>
</div>
            
<h5>Recent Applied Jobs </h5> 

<div class="recent_aj">
<div> <div class="tble-rw">
   <div class="tbl-rw-fifty">
    <div class="tbl-brand"><img src="@/assets/dash/fusd.png" alt=""/></div>
    <div class="tbl-text"> <h4>Protective Intelligence Agent</h4> <p><span>September</span> 20, 2021</p></div>
   </div>
    <div class="tbl-rw-fifty">
       <div class="rw-rt">
         <div class="rw-three"><h4>New York, US</h4></div>  
          <div class="rw-three"> <a href="#" class="svd-us">Saved Jobs</a></div>  
          <div class="rw-three"> <a href="#" class="apply-us">Apply Now</a></div>  
      </div>
    </div>
 </div>
  <div class="tble-rw">
   <div class="tbl-rw-fifty">
    <div class="tbl-brand"><img src="@/assets/dash/griffin.png" alt=""/></div>
    <div class="tbl-text"> <h4>Detail Leader</h4> <p><span>September</span> 20, 2021</p></div>
   </div>
    <div class="tbl-rw-fifty">
       <div class="rw-rt">
         <div class="rw-three"><h4>New York, US</h4></div>  
          <div class="rw-three"> <a href="#" class="svd-us">Saved Jobs</a></div>  
          <div class="rw-three"> <a href="#" class="apply-us">Apply Now</a></div>  
      </div>
    </div>
 </div>
  <div class="tble-rw">
   <div class="tbl-rw-fifty">
    <div class="tbl-brand"><img src="@/assets/dash/static-agent.png" alt=""/></div>
    <div class="tbl-text"> <h4>Static Agent</h4> <p><span>September</span> 20, 2021</p></div>
   </div>
    <div class="tbl-rw-fifty">
       <div class="rw-rt">
         <div class="rw-three"><h4>New York, US</h4></div>  
          <div class="rw-three"> <a href="#" class="svd-us">Saved Jobs</a></div>  
          <div class="rw-three"> <a href="#" class="apply-us">Apply Now</a></div>  
      </div>
    </div>
 </div>
 <div class="tble-rw">
   <div class="tbl-rw-fifty">
    <div class="tbl-brand"><img src="@/assets/dash/quarintex.png" alt=""/></div>
    <div class="tbl-text"> <h4>Protective Intelligence Agent</h4> <p><span>September</span> 20, 2021</p></div>
   </div>
    <div class="tbl-rw-fifty">
       <div class="rw-rt">
         <div class="rw-three"><h4>New York, US</h4></div>  
          <div class="rw-three"> <a href="#" class="svd-us">Saved Jobs</a></div>  
          <div class="rw-three"> <a href="#" class="apply-us">Apply Now</a></div>  
      </div>
    </div>
 </div>
  <div class="tble-rw">
   <div class="tbl-rw-fifty">
    <div class="tbl-brand"><img src="@/assets/dash/hudello.png" alt=""/></div>
    <div class="tbl-text"> <h4>Protective Intelligence Agent</h4> <p><span>September</span> 20, 2021</p></div>
   </div>
    <div class="tbl-rw-fifty">
       <div class="rw-rt">
         <div class="rw-three"><h4>New York, US</h4></div>  
          <div class="rw-three"> <a href="#" class="svd-us">Saved Jobs</a></div>  
          <div class="rw-three"> <a href="#" class="apply-us">Apply Now</a></div>  
      </div>
    </div>
 </div>
 </div>
<div class="pagination">
 <ul>
  <li class="active"><a href="#">1</a></li>
   <li><a href="#">2</a></li>
   <li><a href="#">3</a></li>
   <li><a href="#">4</a></li>
   <li><a href="#">...</a></li>
 </ul>
 </div>
  
</div>
<br>
 
 <h5>Executive Protection Blog </h5> 

<div class="bottom_cd">
<div class="thirty-three">
  <img src="@/assets/dash/executive-img1.jpg" alt=""/>
 <div class="thirty-three-text">
   <p><strong><span>March</span> 11, 2021</strong> </p> <p class="tt-u sub-text"> EP Training  . <strong>Byron Rodager</strong></p> 
   <h4>Executive Protection Training Day Field Note #62</h4>  
  </div>
  </div>
 <div class="thirty-three">
  <img src="@/assets/dash/executive-img2.jpg" alt=""/>
 <div class="thirty-three-text">
    <p><strong><span>April</span> 12, 2021</strong> </p> <p class="tt-u sub-text"> EP Training  . <strong>Byron Rodager</strong></p> 
   <h4>Executive Protection Training Day Field Note #75</h4>  
  </div>
  </div>
   <div class="thirty-three">
  <img src="@/assets/dash/executive-img3.jpg" alt=""/>
 <div class="thirty-three-text">
   <p><strong><span>March</span> 11, 2021</strong> </p> <p class="tt-u sub-text"> EP Training  . <strong>Byron Rodager</strong></p> 
   <h4>Executive Protection Training Day Testimonial</h4>  
  </div>
  </div>

 </div>
 </div>
</div>
</div>
</template>


<script>
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import DashboardPodcastslider from '../components/DashboardPodcastslider.vue'

export default {
name: 'DashboardRecruiter',
components:{
Dashboardheader,
Dashboardsidebar,
DashboardPodcastslider
}
}
</script>

