<template>
    <div class="dashboard_main">
        <Dashboardheader />
        <div class="middle-part dashboard">
            <Dashboardsidebar />
            <div class="rigt-part">
                <h3>Change Password</h3>
					<div class="gt-started-frm tabinfo">
					

					
					<form id="changepassword-form" v-on:submit.prevent="savePassword">
                        <div v-if="message!=''" class="success">{{message}}</div>
					<div v-if="errormessage!=''" class="error">{{errormessage}}</div>
                    <h4>Let's get started</h4>
					<div class="rw-gap">
					<div class="tw-clm">
					<label>New Password *</label>
					<input :type="passwordFieldType" placeholder="Enter New Password" v-model="state.newPassword" name="newPassword" id="newPassword" autocomplete="off" />
					<span v-if="v$.newPassword.$error">
					{{ v$.newPassword.$errors[0].$message }}
					</span>
					</div>
					</div>
					<div class="rw-gap">
					<div class="tw-clm">
					<label>Confirm Password *</label>
					<input :type="passwordFieldType" placeholder="Enter Confirm Password" v-model="state.confPassword" name="confPassword" id="confPassword" autocomplete="off" />
					<span v-if="v$.confPassword.$error">
					{{ v$.confPassword.$errors[0].$message }}
					</span>
					</div>
					</div>
					<div class="rw-gap">
					<div class="tw-clm">
					<p>Use 8 or more characters with a mix of letters, numbers &amp; symbols</p>
					<label class="input"><input type="checkbox" v-on:click="switchVisibilitys">Show password</label>
					</div>
					</div>
					<div class="action_wpr">
					<!-- <input type="submit" value="SAVE"> -->
					<button type="submit" class="login_btn" :disabled="buttonclick" style="margin-left:0px;cursor:pointer;background: red;border: red;">
					<span v-if="buttonclick==true" style="color:white">Saving. . . . .</span>
					<span v-else style="color:white"> Save</span>
					</button>
					<button type="button" @click="$router.go(-1)" class="login_btn">Cancel</button>
					<!-- <div v-if="errormessage!=''" class="errormessage">{{errormessage}}</div> -->
					</div>
					</form>
					</div>
					</div>
        </div>
    </div>
</template>
<script>
    import { reactive, computed } from "vue"
    import useValidate from "@vuelidate/core"
    import { helpers,sameAs ,required,minLength } from "@vuelidate/validators"
    import Dashboardheader from '../components/Dashboardheader.vue'
    import Dashboardsidebar from '../components/Dashboardsidebar.vue'
    import { BASE_URL } from '../config'
    //import { API_BASE_URL } from '../config'
    import axios from 'axios'
    import { createLocal } from 'the-storages'

    const mirror = createLocal()
    const storage = mirror._prx
    export default {
        name: 'DashboardGetstarted',
        components: {
            Dashboardheader,
            Dashboardsidebar
        },
        setup() {
            const state = reactive({
                newPassword: '',
                confPassword: ''
            })
            const rules = computed(() => {
                return {
                    newPassword: { 
                                  required ,
                                  minLength:minLength(8), 
                                  containsPasswordRequirement: helpers.withMessage(
                                    () => 'The password requires an uppercase, lowercase, number and special character', 
                                    (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)),
                                },
                    confPassword: { required, sameAsPassword: sameAs(state.newPassword) } 
                }
            })
            const v$ = useValidate(rules, state)
            return {
                state,
                v$
            }
        },
        data() {
            return {
                //state:{
                newPassword: '',
                confPassword: '',
                //},
                storageData: mirror,
                storage: storage,
                errormessage:'',
                message:'',
                passwordFieldType: 'password',
                buttonclick:false,
            }
        },
        created() {
            //console.log("storage:",mirror.AUTHID);
        },
        methods: {
            async savePassword() {
                this.buttonclick = true;
                this.v$.$validate(this.v$.$error);
                if (!this.v$.$error) {
                    if (this.state.confPassword && this.state.newPassword) {
                        axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                            axios.post(BASE_URL +'api/changePassword', {
                                newPassword: this.state.newPassword,
                                confPassword: this.state.confPassword,
                                userId: mirror.AUTHID,
                            })
                            .then(response => {
                                if (response.data.success == true) {
                                    console.log('data:', response.data)
                                    this.message = response.data.message;
                                    this.buttonclick = false;
                                } else {
                                    this.errormessage = response.data.message;
                                    this.buttonclick = false;
                                    //this.message = response.data.messase;
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                            console.log(responses);
                        });
                        setTimeout(() => {
                            this.message = '';
                            this.errormessage = '';
                            console.log(this.errormessage);
                        }, 5000);
                    } else {
                        this.errormessage = 'All Fields are Mandatory';
                        this.buttonclick = false;
                    }
                } else {
                    this.errormessage = 'All Fields are Mandatory';
                    this.buttonclick = false;
                }
                setTimeout(() => {
                    this.errormessage = '';
                    console.log(this.errormessage);
                }, 5000);
            },
            switchVisibilitys() {
                this.passwordFieldType = this.passwordFieldType==="password"?"text":"password";
                }
        }
    }
</script>
<style scoped>
.success {
    padding: 10px 20px 10px 45px !important;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    position: relative;
    background: green;
}
.error {
    padding: 10px 20px 40px 10px !important;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    position: relative;
    background: #dd1e1e;
}
    label.input{
        display: flex;
        align-items: center;
    }
    label.input input[type=checkbox]{
        margin-right:7px;
    }
    span {
        color: #c71e1e;
    }
    .dashboard_main input[type="text"],
    .dashboard_main input[type="password"]
    {
        width: 100%;
        height: 45px;
        border: solid 1px #3d3d3d;
        background:#1f1f1f;
        box-sizing: border-box;
        color: #d9d9d9;
        font-size: 14px;
        padding: 10px 15px;
    }
    .action_wpr{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    button.add_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #c71e1e;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  background: transparent;
  margin-left: 25px;
  cursor:pointer;
  float:right;
}
</style>