<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
 <div class="rigt-part cd">
 
<div class="candidate-sec">
 <div class="fifty-candidate browse-job"><h2>Browse Jobs</h2><h4>Total Jobs <span>{{totalJobCount}}</span></h4><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
  <div class="srch-btm">
   <div class="srch-lft"><router-link to="/jobs" class="search-btn">SEARCH</router-link></div>
    <div class="srch-lft">
      <div class="prfl-total">
        <div class="prfl-box"><img src="@/assets/dash/search-file-img1.jpg" alt=""/></div>
         <div class="prfl-box"><img src="@/assets/dash/search-file-img2.jpg" alt=""/></div>
          <div class="prfl-box"><img src="@/assets/dash/search-file-img3.jpg" alt=""/></div>
            <a href="#" class="plus"><img src="@/assets/dash/plus.jpg" alt=""/></a>
      </div>
    
    </div>
  </div>
 </div>
 <div class="fifty-candidate">
 <div class="famly-img">
   <div class="family-text">
     <div>
       <img src="@/assets/dash/apply-joining-text.png" alt=""/>
       <router-link to="/training" class="search-btn">Apply Now</router-link>
        </div>
   </div>
   </div> 
  
 </div>
</div>

<h5>Executive Protection Podcast </h5>

<div class="prodcast_slider">
<DashboardPodcastslider2 :podcastList="podcastList" />
<div class="stroke_clip"></div>
</div>
            
<h5>Recent Applied Jobs </h5> 

<div class="recent_aj">
<div> 
 
  <div class="tble-rw" v-bind:key="candidate.value" v-for="(candidate) in allCandidateList.data">
   <div class="tbl-rw-fifty">
     <router-link :to="'/jobDetails/'+candidate.slug">
    <div class="tbl-brand"><img style="height:83px;width:78px;" v-bind:src="imagepath+candidate.logo" v-bind:alt="candidate.job_title"/></div>
     </router-link>
    <div class="tbl-text"> <h4>{{candidate.job_title}}</h4> <p><span>{{ moment(candidate.apply_date).startOf("month").format('MMMM')}}</span> {{ moment(candidate.apply_date).startOf("month").format('DD,YYYY')}}</p></div>
   </div>
    <div class="tbl-rw-fifty">
       <div class="rw-rt">
         <div class="rw-three"><h4>{{candidate.country}}, {{candidate.city}}</h4></div>  
          <div class="rw-three"> <a href="#" class="svd-us">Saved Jobs</a></div>  
          <div class="rw-three"> <a href="#" class="apply-us">Apply Now</a></div>  
      </div>
    </div>
 </div>

 </div>
<v-pagination
			v-model="page"
			:pages="allCandidateList.last_page"
			:range-size="1"
			active-color="#DCEDFF"
			@update:modelValue="getResults"
			/>
  
</div>
<br>
 
 <h5>Executive Protection Blog </h5> 

<div class="bottom_cd">
<div class="thirty-three" v-bind:key="blog.id" v-for="(blog) in blogList">
  <!-- <router-link :to="'/blogs/'+blog.slug"> -->
  <img v-bind:src="imagepath+blog.featured_image" v-bind:alt="blog.featured_image"/>
  <!-- </router-link> -->
 <div class="thirty-three-text">
   <p><strong><span>{{ moment(blog.created_at).startOf("month").format('MMMM')}}</span> {{ moment(blog.created_at).format("DD,YYYY") }}</strong> </p> <p class="tt-u sub-text"> EP Training  . <strong>{{blog.user.fname}} {{blog.user.lname}}</strong></p> 
   <h4>{{blog.title}}</h4>  
  </div>
  </div>
 </div>
 </div>
</div>
</div>
</template>


<script>
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import DashboardPodcastslider2 from '../components/DashboardPodcastslider2.vue'
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'
const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'DashboardRecruiter',
  components:{
    Dashboardheader,
    Dashboardsidebar,
    DashboardPodcastslider2
  },
  data() {
    return {
      moment:moment,
			storageData: mirror,
			storage: storage,
			isLoading: true,
			imagepath:{},
			totalJobCount: '',
      totalCandidateCount:this.totalCandidateCount,
			allCandidateList:{},
			podcastList:[],
			blogList:[],
			playVideo: false,
			countryname:'',
			visible: false,
      current: 1,
    }
  },
  async created () {
      this.AuthUserId = mirror.AUTHID
      this.userType = mirror.USERTYPE
      //console.log(mirror);
      const param    = {
          userId:mirror.AUTHID
      }

      this.imagepath = API_IMAGE_PATH
      const response= await axios.post(API_BASE_URL + 'agentWiseDashboardData',param);

      //this.savedCandidateList     = response.data.candidateShortlistData;
      this.totalJobCount          = response.data.totalJobCount;
      this.podcastList            = response.data.podcastList;
      this.blogList               = response.data.blogList;

      console.log("DATA:",response.data);
	},  
  mounted() {
      this.getResults();
  },
  methods:{
        getResults(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `agentJobsCandidateList?page=${page}`, inputData)
                .then(response => {
                    this.allCandidateList = response.data;
                    this.totalCandidateCount = response.data.total;
                    console.log("DATA3:",response.data);
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        }
	},
}
</script>

