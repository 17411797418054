<template>
  <Navigation />
  <!-- <Banner bg="banner1.png"> -->
  <Banner bg="home-home-banner-main.png">
    <!-- <div class="banner_img">
      <img src="@/assets/img2.png" alt="" />
    </div> -->
    <!-- <div class="cont_text">
      <span class="box">Home</span> Of <br />Executive
      <span
        class="strong"
        style="
          background: -webkit-linear-gradient(#ffba00, #94824f);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        "
        >Protection</span
      >
      Lifestyle
    </div> -->
    <div class="banner_full">
        <div class="banner_width">
          <div class="banner_left_text">
            <h2>EXECUTIVE </h2>
            <h1>PROTECTION</h1>
            <h3>Specialist Community</h3>
            <router-link to="/signup">JOIN COMMUNITY
              <span>
                <img src="@/assets/right-arrow_ep_banner.png" alt=""/>
              </span>
            </router-link>
          </div>
        </div>
        <div class="banner_width">
          <div class="banner_right_text">
            <h2><span>Top Rated</span> Career</h2>
            <h1>DEVELOPMENT</h1>
            <h3>& TRAINING</h3>
            <a href="https://www.epspecialist.com/about-us53880251" target="_blank">ONLINE TRAINING
              <span>
                <img src="@/assets/right-arrow_ep_banner.png" alt=""/>
              </span>
            </a>
          </div>
        </div>
    </div>
    <!-- <div class="banner_full">
        <div class="banner_width">
          <div class="banner_left_text">
            <h2>EXECUTIVE </h2>
            <h1>PROTECTION</h1>
            <h3>Specialist Community</h3>
            <router-link to="/">JOIN COMMUNITY
              <span>
                <img src="@/assets/right-arrow_ep_banner.png" alt=""/>
              </span>
            </router-link>
          </div>
        </div>
        <div class="banner_width">
          <div class="banner_right_text">
            <h2><span>Top Rated</span> Career</h2>
            <h1>DEVELOPMENT</h1>
            <h3>& TRAINING</h3>
            <router-link to="/">ONLINE TRAINING
              <span>
                <img src="@/assets/right-arrow_ep_banner.png" alt=""/>
              </span>
            </router-link>
          </div>
        </div>
    </div> -->
  </Banner>
  <div class="container">
    <div class="intro">
      <div class="intro_cont">
        <h1>EpSpecialist</h1>
        <p>We are <span>1%</span> of the protection industry</p>
      </div>
      <div class="social_info">
        <router-link to="/login" class="login_btn">Login to Enter</router-link>
        <ul>
          <li>
            <a href="https://www.facebook.com/B.c.rodge" target="_blank"
              ><i class="fab fa-facebook-f"></i
            ></a>
          </li>
          <li>
            <a href="https://www.instagram.com/byron_rodgers" target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
          </li>
          <li>
            <a href="https://twitter.com/byron_rodgers" target="_blank"
              ><i class="fab fa-twitter"></i
            ></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/byronrodgers" target="_blank"
              ><i class="fab fa-linkedin"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/user/TheByronRodgers"
              target="_blank"
              ><i class="fab fa-youtube"></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="arrow"></div>
    </div>
    <div class="training">
      <!-- <div class="section_img">
        <img src="@/assets/newProtector.jpg" alt=""  />
      </div> -->
      <img src="@/assets/newProtector1.png" alt="" class="section_img" />
      <div class="content">
        <div class="left_area">
          <h3 class="section_header">About The Brand</h3>
          <p style>
            The executive protection lifestyle brand is designed for all agents of the private security industry. Here, private sector professionals can congregate, network, find jobs, be discovered, and keep a pulse on the culture of one of the fastest-growing industries in the world. <br>
            <br>
            This is the executive protection industry's first online home, created to foster a community where professionals may advance their skills and careers to the next level. With digital courses and live events, you'll learn how to implement and leverage the tools that have helped the best in our industry do what they do.<br>
            
            <br>
            With your company profile, you'll be able to find the agents you need to forge lasting relationships with your clients, principles, and stakeholders that will position your company for success long-term.
            <br>
            <br>
            This is a place for us, the private security operators, and the companies that help us put food on the table. This is a place for us to see and be seen, to be heard and understood, and to work together to improve ourselves. This is our primary web presence and our primary social media channel.
            <br>
            <br>
            I've said it before and I will say it again, protection is more than just a job, it's a lifestyle and this platform is where we can live that lifestyle in a way that contributes to our careers companies, and the private security industry. Welcome to executiveprotectionlifestyle.com
            <br></p>
        </div>
        <div class="right_area">
          <img src="@/assets/brand.png" alt="" />
          <!-- <router-link to="https://www.executiveprotectiontrainingday.com/eptd-masters-enrollment1635187064705" class="read_btn">Join Training</router-link> -->
          <a
            href="https://www.executiveprotectiontrainingday.com/eptd-masters-enrollment1635187064705"
            class="read_btn"
            >Join Training</a
          >
        </div>
      </div>
    </div>
    <div class="job_section">
      <div class="left_area">
        <h4 class="sub_header">Find Your Next</h4>
        <h3 class="section_header">Protection Job</h3>
      </div>
      <div class="right_area">
        <div class="">
          <!-- <div class="joblist_Wpr"> -->
          <ul class="job_list">
            <JobCardHome
            class="jobLi"
              :callback="clickToGetJobDetails"
              v-bind:key="job.id"
              v-for="job in jobList"
              :jobData="job"
              icon="icon2.png"
            />
          </ul>
        </div>
        <router-link to="/jobs" class="read_btn">View All Jobs</router-link>
      </div>
    </div>
  </div>
  <!-- <div class="" style="border-top: 1px solid rgba(255,255,255,0.1);border-bottom: 1px solid rgba(255,255,255,0.1);">
    <img src="@/assets/the-procetor.jpg" alt="" style="width:100%" >
  </div> -->

  <!-- ------------------------------Add Section------------------------- -->
  
  <section class="epBanners" >
    <a href="https://www.executiveprotectiontrainingday.com/eptd-masters-enrollment1635187064705" target="_blank" >
        <div class="epBannersImage">
            <img src="@/assets/images/ad/Ep-Specialist-banner.jpg">
        </div>
        <div class="epBannersText">
            <h3>Official Certification Program For</h3>
            <h1>Executive</h1><br>
            <div class="epst">
            <h2>Protection Specialists</h2>
            </div>

        </div>
      </a>
    </section>
  <!-- <section class="ads"> -->
    
        <!-- <div class="adsTextOne">
            <img src="@/assets/images/ad/comeing.png">
        </div>
        <div class="adsTextTwo">
            <img src="@/assets/images/ad/come.png">
        </div>
        <div class="container-fluid">
            <div class="row adsCenter">
                <div class="adText">
                    <img src="@/assets/images/banner/bannerText1.png">
                      <p>INSPIRE PROTECTORS OF ALL WALKS OF LIFE</p>
                      
                        <div class="adBtn">
                          <a href="https://www.indiegogo.com/" target="_blank">Invest and Get Benefited</a>
                        </div>
                      
                  </div>
            </div>
        </div> -->
    <!-- </section> -->
      <!-- ------------------------------------------------------- -->

  
  <div class="container">
    
    <div class="testimonial">
      
      <h3 class="section_header text-center">Testimonial</h3>
      <TestimonialSlider />
    </div>
  </div>
  <Footer />
  <!-- <div class="modal" v-show="modalLoginMembership">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>
          To go to the details page please <router-link to="/login" class="">Sign in</router-link> or
          <router-link to="/signup" class="">Signup</router-link>
        </h2>
        
      </div>
      <div class="modal_body">
        <button class="sign_submit" style="float: right" @click="close">
          Cancel
        </button>
      </div>
    </div>
  </div> -->
  <div class="cust-modal" v-show="modalLoginMembership">
    <div class="modal-content">
        <h3>To go to details page please</h3>
        <div class="wk-btn">
            <button><router-link to="/login" >sign in</router-link></button>
            or  
            <button><router-link to="/signup" class="" style="color:black;">sign up</router-link></button>
        </div>
    </div>
    <div class="ftr">
        <button @click="close"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" class="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg></button>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { API_BASE_URL } from "../config";
import Banner from "../components/Banner.vue";
import JobCardHome from "../components/jobCardListHome.vue";
import TestimonialSlider from "../components/TestimonialSlider.vue";
import Navigation from "../components/Navigation.vue";
import Footer from "../components/Footer.vue";
import { createLocal } from "the-storages";
const mirror = createLocal();

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const storage = mirror._prx;
export default {
  name: "Home",
  components: {
    Banner,
    JobCardHome,
    TestimonialSlider,
    Navigation,
    Footer,
  },
  data() {
    return {
      isBtn: false,
      storageData: mirror,
      storage: storage,
      current: 1,
      jobList: {},
      error: "",
      error2: "",
      is_show: false,
      keyword: "",
      experience: [],
      hiring_role: [],
      job_type: [],
      location: [],
      budget: [],
      modalLoginMembership: false,
    };
  },
  mounted() {
    this.filter();
  },
  created() {
    //this.imagepath=API_IMAGE_PATH;
    this.userId = mirror.AUTHID;
    //this.userType=mirror.USERTYPE;
    //console.log(this.imagepath);
    this.hash = location.hash;
    if(this.hash=='#show'){
        this.showToast("Successfully Log out","","success");
        location.hash = '';
    }
  },
  methods: {
    filter() {
      axios
        .get(API_BASE_URL + `feturedjobs`)
        .then((response) => {
          this.jobList = response.data;
          console.log(this.jobList);
        })
        .catch(({ response }) => {
          this.error = response;
        });
      this.is_show = true;
    },
    async clickToGetJobDetails(jobId, slug) {
      //const slug = 1;
      //console.log("JOB:",jobId,"-SLUG:",slug);
      if (this.userId) {
        // this.$router. push('/jobDetails/'+slug);
        if (mirror.MEMBERSHIP_TYPE == "Free") {
          const inputData = {
            userId: mirror.AUTHID,
            jobId: jobId,
          };
          await axios
            .post(API_BASE_URL + `membershipCheckingForJobList`, inputData)
            .then((response) => {
              this.data = response.data;

              console.log("A1: ", this.data);
              if (this.data.result == "success") {
                if (this.data.count > 0 && this.data.count <= 5) {
                  console.log("THIS SECTION 1");
                  this.$router.push("/jobDetails/" + slug);
                } else {
                  console.log("THIS SECTION 2");
                  this.modalMembership = true;
                }
              } else {
                console.log("THIS SECTION 3");
                this.modalMembership = true;
              }
            })
            .catch(({ response }) => {
              this.error = response;
            });
        } else {
          this.$router.push("/jobDetails/" + slug);
        }
      } else {
        this.modalLoginMembership = true;
      }
      //this.$router. push('/jobDetails/'+slug);
    },
    close: function () {
      this.modalLoginMembership = false;
    },
    showToast(title,description,type){
        createToast({
            title: title,
            description: description
        },
        {
            type: type,
            position: 'bottom-right',
            // timeout:600000
            // hideProgressBar: 'true',
            // toastBackgroundColor: color,
        })
    },
  },
};
</script>
<style scoped>
/* *  {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    } */
    .banner_full {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        align-items: center;
        min-height: 700px;
        padding: 20px 0 50px;
    }
    .banner_full .banner_width {
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
    }
    .banner_full .banner_width .banner_left_text,
    .banner_full .banner_width .banner_right_text{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        max-width: 500px;
    }
    .banner_full .banner_width .banner_left_text h2,
    .banner_full .banner_width .banner_right_text h2{
        font-weight: 500 !important;
        line-height: normal;
        font-family: 'Play', sans-serif;
        text-transform: uppercase;
        font-size: 31px;
        letter-spacing: 9px;
        color: #ffffff;
        font-weight: 400;
        filter: drop-shadow(-1.414px 1.414px 1.5px #000000);
    }
    .banner_full .banner_width .banner_right_text h1,
    .banner_full .banner_width .banner_left_text h1 {
        letter-spacing: 1px;
        display: inline-block;
        font-family: "IRON MAN OF WAR 002 NCV";
        line-height: 60px;
        margin: 9px 0 20px;
        font-weight: 700;
        font-size: 69px;
    }
    .banner_full .banner_width .banner_left_text h1{
        background: -webkit-linear-gradient(#e91b1b,#741610);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(6px 0px 11px #000);
    }
    .banner_full .banner_width .banner_right_text h1{
        background: -webkit-linear-gradient(#f4ca59, #745d2a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(6px 0px 11px #000);
    }
    .banner_full .banner_width .banner_left_text h3,
    .banner_full .banner_width .banner_right_text h3{
        font-size: 35px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 700;
        filter: drop-shadow(-1.414px 1.414px 1.5px #000000);
    }
    .banner_full .banner_width .banner_left_text a,
    .banner_full .banner_width .banner_right_text a {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        line-height: normal;
        border: 1px solid #ffffff;
        padding: 16px 33px;
        margin-top: 24px;
        transition: all ease-in-out .3s;
        display: inline-block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .banner_full .banner_width .banner_left_text a:hover,
    .banner_full .banner_width .banner_right_text a:hover {
        border: 1px solid #fff;
        background-image: unset;
        background: #fff;
        color: #000;
        -webkit-text-fill-color: inherit;
        -moz-tet-fill-color: inherit;
    }
    .banner_full .banner_width .banner_left_text a span img,
    .banner_full .banner_width .banner_right_text a span img{
      filter: brightness(0) invert(1);
    }
    .banner_full .banner_width .banner_left_text a:hover span,
    .banner_full .banner_width .banner_right_text a:hover span{
      filter: brightness(0);
    }
    .banner_full .banner_width .banner_left_text a span,
    .banner_full .banner_width .banner_right_text a span{
        padding-left: 24px;
    }
    .banner_full .banner_width .banner_left_text a span img,
    .banner_full .banner_width .banner_right_text a span img{
        filter: brightness(0) invert(1);
    }
   
    .epBanners  {
        position: relative;
    }
    .epBannersImage  {
        position: relative;
    }
    .epBannersImage img  {
        width: 100%;
        display: block;
    }
    .epBannersText {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(0% , -50%);
    }
    .eptext  {
        padding-right: 72px;
        width: 100%;
    }
    .epBannersText h3  {
        font-size: 30px;
        font-family: 'Play', sans-serif;
        padding-bottom: 70px;
        color: #fff;
        display: inline-block;
    }
    .epBannersText h1  {
        font-size: 92px;
        /* font-family: 'iron_man_of_war_002_ncvRg'; */
        background: -webkit-linear-gradient(#865901, #f9b122);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(6px 0px 11px #000);
        letter-spacing: 3px;
        display: inline-block;
        padding-bottom: 50px;
        /* background: -webkit-linear-gradient(#ffba00, #50472e);
        -webkit-background-clip: text; */
        /* -webkit-text-fill-color: unset; */
        font-family: "IRON MAN OF WAR 002 NCV";
        display: inline;
        /* color: #f9b122; */
    }
    .epst {
    padding-top: 30px;
}
    .epst h2  {
        font-size: 59px;
        /* font-family: 'iron_man_of_war_002_ncvRg'; */
        background: -webkit-linear-gradient(#fc0000, #fc0000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(6px 0px 11px #000);
        letter-spacing: 2px;
        /* display: inline-block;
        color: #fc0000; */
        display: inline;
    }

    @media (max-width: 1200px)  {
        .banner_full{
            min-height: 546px;
            
        }
        .banner_full .banner_width .banner_left_text h1,
        .banner_full .banner_width .banner_right_text h1{
            font-size: 50px;
            margin: 6px 0 10px;
            line-height: normal;
        }
        .banner_full .banner_width .banner_left_text h3,
        .banner_full .banner_width .banner_right_text h3{
            font-size: 32px;
        }
        .banner_full .banner_width .banner_left_text a,
        .banner_full .banner_width .banner_right_text a{
            font-size: 16px;
            padding: 11px 27px;
            margin-top: 30px;
        }
        .epBannersText {
            transform: translate(0% , -50%);
        }
        .epBannersText h3  {
            font-size: 26px;
            padding-bottom: 10px;
        }
        .epBannersText h1  {
            font-size: 50px;
        }
        .epBannersText h2  {
            font-size: 32px;
        }
        .epst[data-v-5a90ec03] {
    padding-top: 0px !important;
}
    }

    @media (max-width: 991px)  {
      .banner_full {
          min-height: 555px;
          padding: 80px 0 32px;
      }
      .banner_full .banner_width {
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 40px;
      }
      .banner_full .banner_width .banner_left_text,
      .banner_full .banner_width .banner_right_text{
          text-align: center;
      }
        .epBannersText {
            transform: translate(0% , -50%);
        }
        .epBannersText h3  {
            font-size: 18px;
            padding-bottom: 10px;
        }
        .epBannersText h1  {
            font-size: 44px;
        }
        .epBannersText h2  {
            font-size: 28px;
        }
    }

    @media (max-width: 767px)  {
      .content {
          padding-bottom: 29px;
      }
      .job_section {
    padding-bottom: 48px;
}
      .banner_full {
          min-height: initial;
          padding: 32px 0 32px;
      }
        .epBannersText {
            transform: translate(0% , -50%);
        }
        .epBannersText h3  {
            font-size: 16px;
            padding-bottom: 10px;
        }
        .epBannersText h1  {
            font-size: 40px;
        }
        .epBannersText h2  {
            font-size: 22px;
        }
        .banner {
          padding-top: 100px;
        }
    }

    @media (max-width: 625px)  {
        .epBannersText {
            transform: translate(0% , -50%);
        }
        .epBannersText h3  {
            font-size: 14px;
            padding-bottom: 10px;
        }
        .epBannersText h1  {
            font-size: 36px;
        }
        .epBannersText h2  {
            font-size: 18px;
        }
    }
    @media (max-width: 576px){
      .banner_full .banner_width .banner_left_text h2,
      .banner_full .banner_width .banner_right_text h2 {
          font-size: 20px;
      }
      .banner_full .banner_width .banner_left_text h1,
      .banner_full .banner_width .banner_right_text h1 {
          font-size: 40px;
          margin: 6px 0;
      }
      .banner_full .banner_width .banner_left_text h3,
      .banner_full .banner_width .banner_right_text h3{
          font-size: 20px;
          line-height: 22px;
      }
      .banner_full .banner_width .banner_left_text a,
      .banner_full .banner_width .banner_right_text a{
          font-size: 16px;
          padding: 11px 18px;
          margin-top: 23px;
      }
    }
    @media (max-width: 480px)  {
        .epBannersText {
            transform: translate(0% , -50%);
        }
        .epBannersText h3  {
            font-size: 12px;
            padding-bottom: 5px;
        }
        .epBannersText h1  {
            font-size: 30px;
        }
        .epBannersText h2  {
            font-size: 14px;
        }
    }

    @media (max-width: 400px)  {
        .epBannersText {
            transform: translate(0% , -50%);
        }
        .epBannersText h3  {
            font-size: 10px;
            padding-bottom: 5px;
        }
        .epBannersText h1  {
            font-size: 26px;
        }
        .epBannersText h2  {
            font-size: 12px;
        }
    }
/*ads start*/

.ads  {
    /* background-image: url("~@/assets/images/ad/banner.png");Banner-new.jpg */
    background-image: url("~@/assets/images/ad/Banner-new.jpg");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 522px;
    background-position: 8% 75%;
}
.adText p  {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-bottom: 30px;
}
.adsTextOne  {
    position: absolute;
    top: 15px;
    left: 15px;
}
.adsTextTwo  {
    position: absolute;
    top: 0px;
    right: 0;
}
.adText  {
    height: 522px;
    max-width: 651px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
}
.adText img  {
    width: 100%;
    display: block;
    padding-bottom: 30px;
}
.adBtn a  {
  width: 283px;
  height: 55px;
  border-color: rgb(188, 0, 0);
  border-radius: 2px;
  background-color: rgb(199, 30, 30);
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adsCenter  {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
}

/*ads end*/
</style>
<style>
.cust-modal .ftr button:hover {
    border: 2px  solid red !important;
}
.modal_header button {
    font-size: 12px !important;
    border: 4px solid #000000ed !important;
}
.modal_header button:hover {
  border: 2px  solid red;
}
/* .sign_submit:hover {
    border: 2px  solid red;
} */
</style>
<style scoped>
.jobLi {
  background: #1b1b1b;
  margin: 20px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 4px solid #b91e1e;
  /* padding: 40px 0; */
  padding: 25px 25px;
  position: relative;
  z-index: 2;
}
a :hover {
  /* color:black !important; */
}
a {
  color:white;
}
.aaaa :hover{
  color:black !important;
}
.cust-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 200;
    padding: 0 30px;
    width: fit-content;
    transform: translate(-50%, -50%);
}
.cust-modal::after{
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    z-index: -1;
    transform: translate(-50%, -50%);
    height: 100vh;
    background: #000;
    opacity: 79%;
}
.cust-modal .modal-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px 30px;
}
.cust-modal .modal-content h3{
    font-size: 18px;
    font-weight: 300;
    flex-shrink: 0;
}
.cust-modal .modal-content .wk-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.cust-modal .modal-content .wk-btn button{
    color: #fff;
    padding: 7px 23px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 2px;
    transition: all .3s;
    flex-shrink: 0;
    text-transform: uppercase;
}

.cust-modal .modal-content .wk-btn button:first-child{
    border: 1px solid #fff;
    background: none;
}
.cust-modal .modal-content .wk-btn button:first-child:hover{
    background: #AC8F3E;
    border: none;
    color: #000 !important;
    border: 1px solid #AC8F3E;
}
.cust-modal .modal-content .wk-btn button:last-child{
    background: #AC8F3E;
    border: none;
    color: #000;
}
.cust-modal .modal-content{
    background: #212123;
    padding: 18px 30px;
    border-radius: 5px;
    
}
.cust-modal .ftr{
    position: absolute;
    right: 15px;
    top: -15px;
    display: flex;
}
.cust-modal .ftr button{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    border-radius: 50%;
    cursor: pointer;
    border: 5px solid #030303;
}

@media(max-width:599px){
    .cust-modal .modal-content h3{
        flex-shrink: unset;
        text-align: center;
    }
    .cust-modal .modal-content .wk-btn{
        flex-direction: column;
        width: 100%;
    }
    .chat-vr-slider>.item{
        margin-bottom: 12px;
    }
}
</style>
<style scoped>

  i.fab.fa-facebook-f:hover{
        color:#2856b1;
    }
    i.fab.fa-instagram:hover{
        color:#ad1d92;
    }
    i.fab.fa-twitter:hover{
        color:#52c8fb;
    }
    i.fab.fa-linkedin:hover{
        color:#038dcf;
    }
    i.fab.fa-youtube:hover{
        color:red;
    }
.intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0 100px;
  position: relative;
}
.intro_cont h1 {
  font-size: 80px;
  line-height: 85px;
  font-family: "IRON MAN OF WAR 002 NCV";
  font-weight: normal;
  letter-spacing: 50px;
  margin-bottom: 10px;
  background: -webkit-linear-gradient(#ffba00, #50472e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.intro_cont p {
  font-size: 17px;
  line-height: 22px;
  color: #a4a4a4;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.intro_cont p span {
  /* color: #fff; */
  font-weight: 700;
  /* background: -webkit-linear-gradient(#ffba00, #94824f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.social_info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.social_info ul {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.social_info li {
  padding-right: 5px;
}
.social_info li a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: -webkit-linear-gradient(#ffba00, #94824f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.sign_submit {
  padding: 8px 15px;
  background: #c71e1e;
  border-radius: 4px;
  border: none;
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  margin: 4px 4px 0 0;
  border-radius: 0;
  margin-bottom: 20px;
}
.arrow {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 100px;
  border-right: 1px solid #fff;
}
.arrow::before {
  position: absolute;
  content: "";
  left: -6px;
  bottom: 100%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.arrow::after {
  position: absolute;
  content: "";
  left: -4px;
  bottom: 0;
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
}

.training {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 70px 0 30px;
  margin-bottom: 25px;
  position: relative;
}
.training::after {
    content: "";
    width: 100%;
    max-width: 635px;
    height: 3px;
    left: 0;
    bottom: -4px;
    position: absolute;
    z-index: 1;
    background: #c71e1e;
}
.section_img {
  width: 32%;
  height: auto;
}

.content {
  width: 68%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.training .left_area {
  flex: 1 1 520px;
  padding: 0 20px;
  margin: 0 auto;
}
.training .right_area {
  flex: 0 0 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.training .right_area img {
  max-width: 100%;
  height: auto;
}
.content p {
  font-size: 14px;
  line-height: 25px;
  color: #c0c0c0;
}
.read_btn {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-bottom: 5px;
  margin-top: 20px;
  color: #c0c0c0;
  letter-spacing: 0.5px;
  display: inline-block;
}
.read_btn:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 2px;
  right: 0;
  border-bottom: 1px solid #c71e1e;
}

.job_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;
}
.job_section .left_area {
  padding: 15px;
  width: 31%;
}
.job_section .right_area {
  width: 69%;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}
.job_section .joblist_Wpr {
  padding: 30px 60px;
  background: #1b1b1b;
  position: relative;
  border-top: 2px solid #c71e1e;
}
.job_section .joblist_Wpr:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-image: url("~@/assets/figure-bg.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.job_list > li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 4px solid #b91e1e;
  /* padding: 40px 0; */
  padding: 40px 25px;
  position: relative;
  z-index: 2;

  
  background-image: url("~@/assets/figure-bg.png");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 15% 200%;
}
/* .job_list > li:last-child {
  border: 0;
} */
.job_section .read_btn {
  padding-bottom: 15px;
  margin-left: 15px;
}

.testimonial {
  padding: 20px 0 50px;
}

@media (max-width: 1399px) {
  .intro_cont h1 {
    letter-spacing: 30px;
  }
}
@media (max-width: 1199px) {
  .job_section .joblist_Wpr {
    padding: 30px;
  }
  .job_list > li {
    padding: 25px 25px;
  }
  .intro {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .social_info {
    margin-top: 30px;
  }
  .social_info .login_btn {
    margin-left: 0;
  }
  .arrow {
    height: 50px;
  }
  .intro_cont h1 {
    font-size: 65px;
    line-height: 70px;
    letter-spacing: 30px;
  }
  .content p {
    font-size: 14px;
    line-height: 21px;
  }
  button.login_btn {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .section_img img{
  max-width: 100%;
}
  .training {
    flex-direction: column;
  }
  .section_img {
    width: 100%;
    /* height: 520px; */
    object-fit: cover;
    object-position: top;
    margin-bottom: 30px;
  }
  .content {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 50px;
  }
  .training .left_area {
    margin: 0;
  }
  .job_section {
    flex-direction: column;
  }
  .job_section .left_area,
  .job_section .right_area {
    width: 100%;
    text-align: center;
  }
  .job_section .joblist_Wpr {
    padding: 15px 30px;
    text-align: left;
  }
  .testimonial ul li {
    width: 50%;
  }
  .job_list > li {
    padding: 20px 20px;
  }
}
@media (max-width: 767px) {
  .job_section .joblist_Wpr {
    padding: 10px 20px;
  }
  .intro_cont h1 {
    letter-spacing: 15px;
    font-size: 50px;
    line-height: 60px;
  }
  .training .right_area {
    flex: 0 0 100%;
    align-items: flex-start;
    padding: 20px 0;
  }
  .training .left_area {
    padding: 0;
  }
  .training {
      padding: 70px 0 0;
  }
  .content {
      padding-bottom: 12px;
  }
}
@media (max-width: 575px) {
  .intro_cont h1 {
    letter-spacing: 10px;
    font-size: 40px;
    line-height: 50px;
  }
  .social_info {
    flex-direction: column;
    align-items: center;
  }
  .social_info ul {
    margin: 20px 0 0;
  }
  .adText img  {
    width: 75%;
    display: block;
    padding-bottom: 30px;
}
}
</style>

