
<template>
<div class="dashboard_main">
    <Dashboardheader />
    <div class="middle-part dashboard">
        <Dashboardsidebar />
        <div class="rigt-part ">
            <!-- <h3>Chat</h3> -->
            <div class="main-content-chat">
                <div class="container-fluid">
                    <div class="row" v-if="!nofound">
                        <!-- first part -->
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 right-sec px-0" >
                            <div class="chat_user chat_user-bar scrollbar" id="style-3" >
                                <div class="force-overflow" >
                                    <div class="user" >
                                        <div class="nav coll-scroll" >
                                            <CandidateChatCard2 :receiverId="receiverId" :callback="userBlock" :candidateData="candidate" v-for="candidate in allCandidateList.data" v-bind:key="candidate" icon="icon2.png" style="cursor:pointer;" v-on:click="selectCandidate(candidate.users.unique_user_id)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- second part -->
                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 left-sec px-0 rela column-char__bar">
                           <div class="message-wrapper" v-if="msgDiv">
                               <div class="message_content_head" v-if="receiverDetails.fname">
                                   <div class="user_det" >
                                       <div class="picture" >
                                            <img style="width:100%;" v-if="receiverDetails.profile_image" v-bind:src="imagepath+receiverDetails.profile_image" v-bind:alt="receiverDetails.fname" />
                                            <img style="width:100%;" :src="require(`@/assets/avatar.png`)" alt="" v-else>
                                        </div>
                                        <div class="details">
                                            <h3>{{receiverDetails.fname}} 000{{(receiverDetails.id==19)?"0":receiverDetails.id}}</h3>
                                            
                                            <div v-if="receiverDetails.login_history!=null" >
                                                <p class="status-pers" v-if="receiverDetails.login_history.login_status=='yes'" ><span class="active"></span> Online</p>
                                                <p class="status-pers" v-else ><span class="distrub1"></span> Offline</p>
                                            </div>
                                            <div v-else ><p class="status-pers"><span class="distrub1"></span> Offline</p></div>
                                            
                                        </div>
                                   </div>
                                   <div class="drop-down" v-if="userType==2">
                                        <!-- <div class="selected" style="cursor: pointer;" >
                                            <a class="modal-ac-btn" v-if="blockStataus=='Block'" v-on:click="userBlock(receiverDetails.id,'unblock')" style="cursor:pointer;color:white;margin-top:10px;">Unblock</a>
                                            <a class="modal-ac-btn" v-if="blockStataus=='Unblock'" v-on:click="userBlock(receiverDetails.id,'block')" style="color:white;">Block</a>
                                        </div> -->
                                        <div class="selected" style="cursor: pointer;" v-on:click="membersBlockModel = !membersBlockModel">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </div>
                                        <div v-show="membersBlockModel" class="dropdown-content">
                                            <router-link :to="'/profile/' + receiverDetails.unique_user_id" style="padding: 0px;">
                                                <a href="#" >View Profile</a>
                                            </router-link>
                                            <a href="#" v-if="blockStataus=='Block'" v-on:click="userBlock(receiverDetails.id,'unblock')" >Unblock</a>
                                            <a href="#" v-if="blockStataus=='Unblock'" v-on:click="userBlock(receiverDetails.id,'block')" >Block</a>
                                        </div>
                                    </div>
                                    <div class="drop-down" v-else>
                                        <div class="selected" @click="isShow = !isShow">
                                            <a class="modal-ac-btn" v-if="blockStataus=='Block'" style="cursor:pointer;color:white;margin-top:10px;">You Blocked</a>
                                           
                                        </div>
                                    </div>
                               </div>
                               <div class="message_content_head" v-else>
                                   <div class="user_det" >
                                       <div class="picture" style="background:#3b3b3c;">
                                            <span style="background:#3b3b3c;"></span>
                                        </div>
                                        <div class="details">
                                            <h3 style="background:#3b3b3c;color:#3b3b3c">Dummmm Contenttt</h3>
                                            <div style=" width:55%;">
                                                <p class="status-pers" >
                                                    <span class=""  style="background:#3b3b3c;color:#3b3b3c">00</span>
                                                    <span class=""  style="background:#3b3b3c;color:#3b3b3c;">Offlineee</span> 
                                                     
                                                </p>
                                            </div>
                                            
                                        </div>
                                   </div>
                                   
                                    <div class="drop-down" >
                                        
                                    </div>
                               </div>
                               <!-- chat -->
                            <div class="chat-vr-slider" ref="messageBox" id="style-4">
                                <!-- v-if="totalpage>current" -->
                                <!-- {{last_page}} > {{current_page}} |  -->
                                <div style="text-align:center;">
                                    <button :disabled='isDisabled' class="previousbtn" v-if="last_page>current_page" type="button" @click="loadPreviousChat()">
                                        <span v-if="isDisabled==true">
                                            <!-- <img :src="require(`@/assets/loager2.gif`)" style="width:50px;"/> -->
                                            <b> . . . . . .</b>
                                            </span>
                                        <span v-else>
                                            
                                            <i class="fa fa-redo-alt"></i> Load Previous Chat</span>
                                        </button>
                                </div>
                                
                                <!-- <span v-for="(chat) in messages.slice().reverse()" v-bind:key="chat.id"> -->
                                    <span v-for="(chat) in messages.slice().reverse()" v-bind:key="chat.id">
                                    <!-- {{index}}-{{chat.id}} -->
                                    
                                    <span v-if="chat.user!=null">
                                        <!-- <div class="chat-dd-end" v-if="getValue(chat.created_at,index)!=''">
                                            <h6 v-if="moment(chat.created_at).format('YYYY-MM-DD')==today">Today</h6>
                                            <h6 v-else-if="moment(chat.created_at).format('YYYY-MM-DD')==yesterday">Yesterday</h6>
                                            <h6 v-else-if="(moment(chat.created_at).format('YYYY-MM-DD')!=yesterday) && (moment(chat.created_at).format('YYYY-MM-DD')!=today)">{{moment(chat.created_at).format('dddd, MMMM Do YYYY')}}</h6>
                                        </div> -->
                                        <!-- chat right -->
                                        <div class="item" v-if="chat.user.id==myId" style="margin-top: 10px;margin-bottom: 20px;">
                                            <div class="relpy">
                                                <div class="time-view">
                                                    <h6>{{moment(chat.created_at).fromNow()}}</h6>
                                                </div>
                                                <div class="text-warrp">
                                                    <div class="text-item">
                                                        <p>{{chat.message}}</p>
                                                    </div>
                                                </div>
                                                <div class="viw-ur">
                                                    <img v-if="storage.get('PROFILEIMAGE')" v-bind:src="imagepath+storage.PROFILEIMAGE" v-bind:alt="storage.NAME" />
                                                    <img v-else src="@/assets/avatar.png" v-bind:alt="storage.NAME" />
                                                    <!-- <img src="http://executiveprotectionlifestyle.epspecialist.com/admin/public/media/1638881416eps15.jpg"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <!-- chat left -->
                                        <div class="item" v-if="chat.user.id!==myId" style="margin-top: 10px;margin-bottom: 10px;">
                                            <div class="sent-sect">
                                                <div class="time-dtls">
                                                    <h6>{{chat.user.fname}} 
                                                        000{{(chat.user.id==19)?"0":chat.user.id}} | 
                                                          {{moment.utc(chat.created_at).fromNow()}}</h6>
                                                </div>
                                                <div class="content">
                                                    <div class="upx">
                                                        <img v-if="receiverDetails.profile_image" v-bind:src="imagepath+receiverDetails.profile_image" v-bind:alt="receiverDetails.fname" style="width: 40px;height: 40px;"/>
                                                        <img style="width: 40px;height: 40px;" :src="require(`@/assets/avatar.png`)" alt="" v-else>
                                                    </div>
                                                    <div class="text-warrp">
                                                        <div class="text-item">
                                                            <p>{{chat.message}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    
                                </span>
                                <span v-if="receiverDetails.fname!='' && messages.length==0 && dummyDiv=='true'">
                                    <span >
                                        <!-- chat right -->
                                        <div class="item" style="margin-top: 10px;margin-bottom: 20px;">
                                            <div class="relpy">
                                                <div class="time-view">
                                                    <h6 style="background:#3b3b3c;color:#3b3b3c;">111111 11</h6>
                                                </div>
                                                <div class="text-warrp" >
                                                    <div class="text-item" style="background:#3b3b3c;color:#3b3b3c">
                                                        <p style="background:#3b3b3c;color:#3b3b3c">1111111111111 1111111111 11111</p>
                                                    </div>
                                                </div>
                                                <div class="viw-ur" style="background:#3b3b3c;"></div>
                                            </div>
                                        </div>
                                        <!-- chat left -->
                                        <div class="item" style="margin-top: 10px;margin-bottom: 10px;">
                                            <div class="sent-sect">
                                                <div class="time-dtls">
                                                    <h6 style="background:#3b3b3c;color:#3b3b3c;width:30%">Dummmii Content  00:00 00</h6>
                                                </div>
                                                <div class="content">
                                                    <div class="upx" style="background:#3b3b3c;">
                                                        
                                                    </div>
                                                    <div class="text-warrp" >
                                                        <div class="text-item" style="background:#3b3b3c;color:#3b3b3c">
                                                            <p style="background:#3b3b3c;color:#3b3b3c">1111111111111 1111111111 11111</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <span >
                                        <!-- chat right -->
                                        <div class="item" style="margin-top: 10px;margin-bottom: 20px;">
                                            <div class="relpy">
                                                <div class="time-view">
                                                    <h6 style="background:#3b3b3c;color:#3b3b3c;">111111 11</h6>
                                                </div>
                                                <div class="text-warrp" >
                                                    <div class="text-item" style="background:#3b3b3c;color:#3b3b3c">
                                                        <p style="background:#3b3b3c;color:#3b3b3c">1111111111111 1111111111 11111</p>
                                                    </div>
                                                </div>
                                                <div class="viw-ur" style="background:#3b3b3c;"></div>
                                            </div>
                                        </div>
                                        <!-- chat left -->
                                        <div class="item" style="margin-top: 10px;margin-bottom: 10px;">
                                            <div class="sent-sect">
                                                <div class="time-dtls">
                                                    <h6 style="background:#3b3b3c;color:#3b3b3c;width:30%">Dummmii Content  00:00 00</h6>
                                                </div>
                                                <div class="content">
                                                    <div class="upx" style="background:#3b3b3c;">
                                                        
                                                    </div>
                                                    <div class="text-warrp" >
                                                        <div class="text-item" style="background:#3b3b3c;color:#3b3b3c">
                                                            <p style="background:#3b3b3c;color:#3b3b3c">1111111111111 1111111111 11111</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </span>
                                
                            </div>

                            <form v-on:submit.prevent="sendMessage" class="submit-area-chat">
                                    <div class="type-navbar" v-if="blockStataus!='Block'">
                                        <div class="srch-g">
                                            <input type="search" placeholder="Type a messages" v-model="messageVal" >
                                        
                                            <button type="submit" :disabled='isDisabled' style="cursor:pointer;">
                                                <span v-if="isDisabled==true" > 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-send-check-fill" viewBox="0 0 16 16">
                                                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47L15.964.686Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                                                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
                                                    </svg> 
                                                    
                                                    </span>
                                                <span v-else > 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-send-fill" viewBox="0 0 16 16">
                                                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                                                    </svg> 
                                                </span>
                                            </button>
                                        </div>
                                    
                                    </div>
                                    <div class="type-navbar" v-else>
                                        <p> Candidate Temporary Blocked</p>
                                    </div>
                                </form>

                           </div>
                           
                            <div class="loader-char-area" v-else-if="is_loader" id="style-4"></div>
                            <div class="message_content lod-modal" v-else style="text-align:center;" id="style-4"> No Candidate Selected Yet !</div>
                        </div>
                    </div>
                    <div v-else> No Data Found 
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modelPopups" v-if="addModal" style="display:block">
    <div class="content_wrapper">
        <div class="modal_header">
            <h2>Do you want to {{status}} {{userDetails.fname}} {{userDetails.lname}} ?</h2>
            <button @click="close"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="form_grp">
                <input type="hidden" v-model="userDetails.id" >
            </div>
            <div class="item_grp" style="text-align: center;">
                <button @click="close" type="submit" class="sign_submit">Cancel</button>
                <button @click="confirm(userDetails.id,status)" type="submit" class="sign_submit">Confirm</button>
            </div>
        </div>
        
    </div>
</div>
</template>
<script>
import Pusher from 'pusher-js';
import axios from 'axios'
// { BASE_URL } from '../config'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import CandidateChatCard2 from '../components/CandidateChatCard2.vue'
import { createLocal } from 'the-storages'
import moment from "moment";
//import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
const mirror = createLocal()
const storage = mirror._prx
export default {
    name: 'NewChat2',
    components:{
        Dashboardheader,
        Dashboardsidebar,
        CandidateChatCard2
    },
    data() {
        return {
            dummyDiv:false,
            membersBlockModel:false,
            scrollHeightCount:1,
           countDate:1,
           oldDate:'',
           oldCount:'',
            isDisabled:false,
            isShow: false,
            storageData: mirror,
            storage: storage,
            moment: moment,
            allCandidateList: {},
            totalCandidateCount:'',
            current: 1,
            error: '',
            error2:'',
            is_show:false,
			nofound:false,
            is_loader:false,
            jobDetails:{},
            addModal:false,
            imagepath:'',
            experience_new:'',
            area_interest:'',
            comm_interest:'',
            preferred_language:'',
            primary_skill:'',
            country:'',
            userDetails:this.userDetails,
            status:'',
            page:this.page,
            messageVal:'',
            messages:[],
            receiverDetails:this.receiverDetails,
            myId:this.myId,
            receiverId:'',
            blockStataus:'',
            userType:'',
            msgDiv:false,
            today:'',
            yesterday:'',
            msgResult : false,
            last_page:'',
            current_page:'',
            totalpage:this.total,
            qParam:{
                receiverId:null,
                userId:null
            },
        }
    },
    mounted() {
        this.getResults();
    },
    async created(){
        var todaysss = new Date();
        var now = moment.utc();
        console.log("TODAY TIME::",todaysss);
        console.log("NOW TODAY TIME::",now);
        
        this.userType=mirror.USERTYPE;
        this.imagepath = API_IMAGE_PATH+'appliedJobDocument/';
        //this.receiverId = ''; //'11122225';
        //console.log("ReceiverId::::",this.receiverId);
        //const route=useRoute();
        //this.selectCandidate(this.receiverId);

        const param    = {
            userId:mirror.AUTHID,
        }
        const response= await axios.post(API_BASE_URL + 'updateChatCount',param);
        //console.log("CHAT UPDATE: ",response.data);
        this.userDetails = response.data;

        const today = moment.utc();
        this.today = today.format('YYYY-MM-DD');

        const yesterday = moment.utc().add(-1, 'days');
        //console.log(yesterday.format('YYYY-MM-DD'))

        this.yesterday = yesterday.format('YYYY-MM-DD');
        console.log("TODAY: ", this.today);
        console.log("YESTERDAY: ", this.yesterday);
    },
    methods: {
        loadPreviousChat(){
            this.isDisabled=true;
            //alert("CLICKED");
            this.current++;
            const qParam = {
                receiverId:this.receiverId,
                userId:this.myId
            }
			this.fetchPreviousMessage(qParam,this.current);
			//var element = document.getElementById("container");
            //element.scrollIntoView();
        },
        selectBlockUnblock() {
            var x = document.getElementById("blockUnblock");
            if (x.innerHTML === "Hello") {
                x.innerHTML = "Swapped text!";
            } else {
                x.innerHTML = "Hello";
            }
        },
        getResults() {
            // this.page = page;
            // if (typeof page === "undefined") {
            //     page = 1;
            // }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `allJobsCandidateChatList`, inputData)
                .then(response => {
                    this.allCandidateList = response;
                    //this.totalCandidateCount = response.data.total;
                    //console.log("DATA3:",response.data);
                    //this.receiverId = this.allCandidateList.data[0].users.unique_user_id;
                    //this.is_loader=true;
                    //this.is_show=this.allCandidateList.last_page>1?true:false;
                    //console.log("CANDIDATE LIST:",this.allCandidateList.data);
                    if(this.allCandidateList.data.length>0){
                        this.nofound=false;
                        this.is_loader=false;
                    }   else    {
                        this.nofound=true;
                        this.is_loader=false;
                    }
                    //console.log("IS FOUND: ",this.nofound);
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
            //this.selectCandidate(this.receiverId);
            //this.scrollToEnd();
        },
        async userBlock(id,status) {
			//console.log(id)
            const param    = {
                userId:id
            }
            const response= await axios.post(API_BASE_URL + 'userDetailsById',param);
            this.userDetails = response.data;
            this.status = status;
            //console.log("BLOCK DETAILS: ",response.data);
            this.addModal = true;
            
        },
        async confirm(receiverId,status){
            const param    = {
                userId:mirror.AUTHID,
                receiverId:receiverId,
                status:status
            }
            const response= await axios.post(API_BASE_URL + 'blockUnblockUserChat',param);
            this.userDetails = response.data;
            this.status = status;
            //console.log("BLOCK: ",response.data);
            this.getResults();
            this.selectCandidate(this.receiverId);
            this.addModal = false;
            this.blockStataus = (response.data.blockStataus=='Blocked')? 'Block':'Unblock';
            //console.log("USER BLOCK DETAILS: ",this.blockStataus);
            
        },
        close(){
            this.addModal = false
        },
        async sendMessage() {
            this.isDisabled=true;
            if (this.messageVal ) {
                const param    = {
                    userId:mirror.AUTHID,
                    receiverId :this.receiverId,
                    message:this.messageVal
                }
                //console.log("param",param);
                await axios.post(API_BASE_URL + 'messagess', param)
                .then(response => {                     	
                    //console.log("Send MEETING: ",response.data.chat);
                    const datas = response.data;
                    //console.log("CHATTT::",datas);
                    this.messages.unshift(datas.chat);
                    this.messageVal = '';
                    this.isDisabled=false;
                    //setTimeout(this.scrollToEnd,100);
                })
                .catch(error => {
                    console.error(error);
                    this.isDisabled=false;
                });
            } else {
                this.errormessage = 'Message Fields are Mandatory';
                this.isDisabled=false;
            }
            this.scrollToEnd();
        },
        async fetchPreviousMessage(qParam,pagess) {
            this.dummyDiv = true;
            
            this.msgResult = true;
            if(this.messages.length>0){
                this.messages = this.messages;
            } else {
                this.messages = [];
            }
            //this.receiverDetails = {};
            //await axios.post(API_BASE_URL + 'private-messagess',qParam)
            await axios.post(API_BASE_URL + 'private-messagess?page='+ pagess,qParam)
            
            .then(response => {     
                this.dummyDiv = false;                   	
                console.log("Message List: ",response.data);

                this.messages = this.messages.concat(response.data.messageData.data);
                
                this.last_page = response.data.messageData.last_page;
                this.current_page = response.data.messageData.current_page;
                this.isDisabled=false;
                //this.receiverDetails = response.data.receiverData;
                if(this.last_page==this.current_page){
                    this.current = 1;
                }
                //this.messages = [];
            })
            .catch(error => {
                console.error(error);
            });
            if(this.scrollHeightCount>1){
                this.scrollHeightCount = document.getElementById("style-4").scrollHeight;
            }

            var element = document.getElementById("style-4");
            //element.scrollTop = element.scrollHeight = this.scrollHeightCount;
            
            element.scrollTop = element.scrollHeight;
            element.scrollTop = this.scrollHeightCount;

            console.log("SCROLL HEIGHT::",element.scrollTop);
            this.handelScroll();

        },
        async fetchMessage(qParam) {
            this.dummyDiv = true;
            this.current = 1;
            this.msgResult = true;
            
            this.messages = [];
            //this.messages = [];
            this.receiverDetails = {};
            //await axios.post(API_BASE_URL + 'private-messagess',qParam)
            await axios.post(API_BASE_URL + 'private-messagess?page='+ this.current,qParam)
            
            .then(response => { 
                this.dummyDiv = false;                    	
                console.log("Message List: ",response.data);

                this.messages = response.data.messageData.data;

                this.last_page = response.data.messageData.last_page;
                this.current_page = response.data.messageData.current_page;
                this.receiverDetails = response.data.receiverData;
                

            })
            .catch(error => {
                console.error(error);
            });
            this.scrollToEnd();
            //this.scrollToBottom();
            this.handelScroll();
        },
        scrollToEnd(){
            var element = document.getElementById("style-4");
            element.scrollTop = element.scrollHeight;
        },
        async selectCandidate(candidateId){
            this.is_loader = true;
            console.log("RESULT::");
            this.msgDiv=false;
            this.receiverId = candidateId;
            //console.log("RECEIVER ID: ",this.receiverId);
            this.myId = mirror.AUTHID;
            //this.receiverId = '11122225'; //route.params.receiverId;
            const qParam = {
                receiverId:this.receiverId,
                userId:this.myId
            }
            //console.log("AUTH:",mirror)
            this.fetchMessage(qParam);

            this.imagepath = API_IMAGE_PATH;
            
            Pusher.logToConsole = true;
            const pusher = new Pusher('8a7bddea661316a2226c', {
                cluster: 'ap2',
                authEndpoint: "http://executiveprotectionlifestyle.epspecialist.com/admin/api/pusher/auth"
            });
            
            const channel = pusher.subscribe('private-chat.'+this.myId);
            channel.bind('message', data => {
                this.messages.unshift(data.message);
                //this.messages.push(data.message);
            });
            //console.log("AUTH M:",mirror);
            const paramBlock    = {
                userId:mirror.AUTHID,
                receiverId:this.receiverId,
                type:mirror.USERTYPE
            }
            const responseBlock= await axios.post(API_BASE_URL + 'blockUnblockCheckingAuth',paramBlock);
            this.blockStataus = responseBlock.data.blockStataus;
            console.log("BLOCK: ",responseBlock.data.blockStataus);

            if(this.msgResult==true){
                this.is_loader = false;
                this.msgDiv=true;
            }
            //this.handelScroll();
        },
        getValue(date,index){
            var newDate = moment.utc(date).format('YYYY-MM-DD');
            var valueCount = 0;
            //console.log("C--New-",newDate);
            //console.log("C--Old-",this.oldDate);
            if(newDate==this.oldDate){
                
                valueCount = (this.countDate + 1);
                //console.log("C--IF-",this.countDate);
            } else {
                valueCount = this.countDate;
                //console.log("C--ELSE-",this.countDate);
            }
            this.oldCount = valueCount;
            this.oldDate = moment.utc(date).format('YYYY-MM-DD');
            this.index1 = index;
            var daysss;
            if(newDate==this.today){
                //daysss = 'Today';
                if(this.oldCount>1){
                    daysss = '';
                } else {
                    daysss = 'Today';
                }
            } else if(newDate==this.yesterday){
                //daysss = 'Yesterday';
                if(this.oldCount>1){
                    daysss = '';
                } else {
                    daysss = 'Yesterday';
                }
            } else {
                if(this.oldCount>1){
                    daysss = '';
                } else {
                    daysss = moment.utc(date).format('YYYY-MM-DD');
                }
                
            }
            this.daysss=daysss;

            return daysss;
        },
        scrollToBottom() {
            //var element2 = document.getElementById("style-5");
            //this.messages.scrollTop = messages.scrollHeight;
        },
        handelScroll(){
            this.scrollHeightCount = document.getElementById("style-4").scrollHeight;
        }
	},
    beforeRouteEnter(to, from, next) {
        //console.log("TOKENT" ,storage.get('TOKEN'));
        if (storage.get('TOKEN')) {
            next();
        }
        else {
            next('/login');
        }
    }
}
</script>

<style scoped>
.drop-down{
    position: relative;
    display: inline-block;
    right: 0.4em;
}
.dropdown-content{
    position: absolute;
    background-color: #202023;
    min-width: 190px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgb(32 32 35);
    z-index: 1;
    right: 0px;
    top: 28px;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 12px;
}
.previousbtn {
    background: #62522a;
    padding: 5px;
    border-radius: 50px;
    color: #d6dcde;
    cursor: pointer;
}
/* start here */
/* .active{
    background: #62522a !important;
}
.nav-link .active .user_details {
    background: #62522a !important;
} */
.chat-vr-slider {
    background: #272729;
    overflow-y: scroll;
    height: calc(100% - 140px);
}

.submit-area-chat{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.loader-char-area{
    width: 10%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    aspect-ratio: 3/3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('~@/assets/chat_loader.gif');
}
.status-pers{
    display: flex;
    align-items: center;
    column-gap: 4px;
    justify-content: left;
    flex-direction: row-reverse;
}
.status-pers span{
    position: static !important;
}
.main-content-chat .container-fluid .row .chat_user .user .user_details .picture{
    flex-shrink: 0;
}
.rela{position: relative;}
.lod-modal{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    color: #616161;
    font-size: 28px;
    width: 100%;
    font-weight: 700;
    transform: translate(-50%, -50%);
}

/* --------------- */


.message_content_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-ac-btn{
    color: white;
    border: 1px solid #3b3b3c;
    border-radius: 15px;
    padding: 3px 14px;
    font-size: 14px;
}
.type-navbar{
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px ;
    align-items: center;
    padding: 10px;
    background: #202023;
    border-radius: 0 0 8px 8px;
    justify-content: space-between;
}
.type-navbar .srch-g{
    flex-grow: 1;
    position: relative;
}
.type-navbar .srch-g input[type="search"]{
    height: 48px;
    width: 100%;
    padding-left: 20px;
    border-radius: 25px;
}
.type-navbar .srch-g input[type="search"]::placeholder{
    font-size: 16px;
}
.type-navbar .srch-g button {
       border-radius: 50%;
    border: none;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 40px;
    height: 40px;
    background: #AC892A;
    bottom: 0;
}
.type-navbar .wrp-btn button{
    width: 42px;
    height: 42px;
    background: #4A4A4C;
    border-radius: 50%;
}
.px-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.main-content-chat{
    border: 1px solid #3b3b3c;
    border-radius: 10px;
    overflow: hidden;
    height: 78vh;
}
.main-content-chat .container-fluid .row .chat_user-bar{
    height: 78vh !important;
}
.main-content-chat .container-fluid .row .chat_user-bar .user .nav.coll-scroll{
    display: block;
}
.main-content-chat .message-wrapper{
    border-left: 1px solid #3b3b3c;
    height: 78vh;
}
.main-content-chat .container-fluid .row .chat_user .user .active .user_details{
    background: #62522a !important;
}

.main-content-chat .container-fluid .row .chat_user {
    background: #2A2A2C !important;
}
.main-content-chat .container-fluid .row .chat_user .user .nav .nav-link{
    width: 100%;
    margin-bottom: 15px;
    padding: 0 !important;
}
.main-content-chat .container-fluid .row .chat_user .user .nav .nav-link:last-child{
    margin-bottom: 0;
}
.main-content-chat .message-wrapper .message_content_head{
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid #3b3b3c;
    background: #2A2A2C;
}.main-content-chat .message-wrapper .message_content_head .user_det{
    align-items: center;
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
}.main-content-chat .message-wrapper .message_content_head .user_det .picture{
    width: 50px;
    display: flex;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #62522a;
    padding: 3px;
}.main-content-chat .message-wrapper .message_content_head .user_det .picture img{
    display: block;
    border-radius: 50%;
}.main-content-chat .message-wrapper .message_content_head .user_det .details h3{
    line-height: 1;
    margin: 0 0 7px;
    font-size: 18px;
}.main-content-chat .message-wrapper .message_content_head .user_det .details p{
    line-height: 1;
    font-size: 14px;
    font-weight: 300;
}
.main-content-chat .message-wrapper .message_content_head .user_det .details span.active{
    /* width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #42d409; */

    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;

}
.main-content-chat .message-wrapper .message_content_head .user_det .details,
.main-content-chat .message-wrapper{
    position: relative;
}
.chat-vr-slider{
    background: #272729;
    overflow-y: scroll;
    height: 100%;
}
.chat-vr-slider .item .sent-sect{
    display: flex;
    flex-direction: column;
}.chat-vr-slider .item .sent-sect .time-dtls{
    padding-left: 10%;
    margin-bottom: 7px;
}.chat-vr-slider .item .sent-sect .time-dtls h6{
    font-size: 10px;
    margin: 0;
    font-weight: 300;
    line-height: 1;
    color:#b4b4b4;
    
}.chat-vr-slider .item .sent-sect .content{
    display: flex;
    column-gap: 10px;
}.chat-vr-slider .item .sent-sect .content .upx img{
    border-radius: 50%;
    width: 100%;
    display: flex;
}.chat-vr-slider .item .sent-sect .content .upx{
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
}.chat-vr-slider .item .sent-sect .content .text-warrp{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    margin-bottom: 10px;
    }.chat-vr-slider .item .sent-sect .content .text-warrp .text-item{
    background-color: #48484a;
    width: fit-content;
    padding: 8px 10px;
    border-radius: 0 12px 12px 12px;
}.chat-vr-slider .item .sent-sect .content .text-warrp .text-item p{
    font-size: 13px;
    font-weight: 300;
    line-height: 1.4;
    color: #dedede;
}
.chat-vr-slider{
    padding: 20px 15px 128px;
}
.chat-vr-slider .chat-dd-end{
    display: flex;
    position: relative;
    margin: 12px 0;
    border-bottom: 1px solid #3B3B3D;
    justify-content: center;
}
.chat-vr-slider .chat-dd-end h6{
    font-weight: 300;
    font-size: 14px;
    padding: 0 18px;
    color: #c9c9c9;
    position: absolute;
    top: -12px;
    background: #272729;
}
.chat-vr-slider .item .relpy{
     display: flex;
     align-items: flex-end;
     row-gap: 8px;
    flex-direction: column;
}
.chat-vr-slider .item .relpy .time-view h6{
    font-size: 10px;
    margin: 0;
    font-weight: 300;
    line-height: 1;
    color:#b4b4b4;
}
.chat-vr-slider .item .relpy .text-warrp{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
}
.chat-vr-slider .item .relpy .text-warrp .text-item{
    background-color: #62522a;
    width: fit-content;
    padding: 8px 10px;
    border-radius: 12px 12px 0 12px;
}
.chat-vr-slider .item .relpy .text-warrp .text-item p{
    font-size: 13px;
    font-weight: 300;
    line-height: 1.4;
    color: #dedede;
}
.chat-vr-slider .item .relpy .viw-ur{
    width: 15px;
    height: 15px;
    display: flex;
    border-radius: 50%;
}
.chat-vr-slider .item .relpy .viw-ur img{
    display: block;
    width: 100%;
    border-radius: 50%;
}


/* end */

.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
}
.option1 {
    background: #1a1a1a;
    text-decoration: none;
    padding: 5px;
    position: absolute;
    width: 150px;
}
.active1{
    margin-left: 10px;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}
.col-sm-12{
        width: 100%;
}
.distrub1{
    margin-left: 10px;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #999999;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}
.middle-part.dashboard .rigt-part.chat {
    height: 100%;
    overflow-y: hidden;
}

.middle-part.dashboard .rigt-part p {
    padding-top: 0;
}

.middle-part.dashboard .rigt-part.chat h3 {
    margin-bottom: 20px;
}

.main-content-chat .container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.main-content-chat .container-fluid .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
}

.main-content-chat .container-fluid .row .right-sec {
    padding-right: 0;
    padding-left: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.main-content-chat .container-fluid .row .left-sec {
    padding-right: 0;
    padding-left: 2px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.main-content-chat .container-fluid .row .chat_user {
    background: #1a1a1a;
    border: solid 1px #242424;
    height: 480px;
    padding: 25px 20px;
    overflow-y: scroll;
}

.main-content-chat .container-fluid .row .chat_user .force-overflow {
    min-height: 0;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar-track,
.chat-vr-slider::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #f5f5f500;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar,
.chat-vr-slider::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f500;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar-thumb,
.chat-vr-slider::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
}

.main-content-chat .container-fluid .row .chat_user .search {
    margin-bottom: 20px;
}

.main-content-chat .container-fluid .row .chat_user .search input[type=text] {
    position: relative;
    padding: 14px 50px;
    font-size: 15px;
    font-weight: 400;
    background-color: #222222;
    border: solid 1px #565657;
    color: #a3a3a3;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.main-content-chat .container-fluid .row .chat_user .search span {
    position: absolute;
    top: 50px;
    left: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
}

.main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details {
    width: 100%;
    height: 60px;
    border-radius: 50px;
    background: #202023;
    display: flex;
    align-items: center;
    padding: 4px 10px 4px 8px;
    margin-bottom: 15px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details:last-child {
    margin-bottom: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture img {
    width: 53px;
    height: 53px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .active {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .away {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #f7ca2f;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .distrub {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #f72f2f;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details {
    width: 60%;
    height: 50px;
    border-right: 1px solid #cbcbcb;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details h2 {
    font-size: 15px;
    font-weight: 600;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details h3 {
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    color: #e6e6e6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time {
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time .count {
    margin: 0 0 0 10px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time .count h2 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    background: #161618;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details {
    background: #62522a !important;
    border: solid 1px #333333;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details .details_time .count h2 {
    border: 2px solid #fff;
}

.main-content-chat .container-fluid .row .chat_user .user .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: transparent;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details h2 {
    color: #1a1a1a;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details h3 {
    color: #1a1a1a;
}

.main-content-chat .container-fluid .row .message .tab-content>.active {
    display: block;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc; */
    border-bottom: 1px solid #cccccc;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .user_det {
    width: 100%;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected a {
    background: #222 no-repeat scroll right center;
    display: block;
    padding: 6px;
    border: 1px solid #e6e6e6;
    width: 100px;
    border-radius: 2px;
    text-decoration: none;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected a span {
    cursor: pointer;
    display: block;
    padding: 5px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .option {
    position: relative;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul {
    background: #fff none repeat scroll 0 0;
    display: none;
    list-style: none;
    padding: 0px 0px;
    position: absolute;
    left: 0px;
    top: 32px;
    width: auto;
    min-width: 170px;
    border: 1px solid #d7d7d7;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected span.value,
.drop-down .options span.value {
    display: none;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul li a {
    padding: 5px;
    display: block;
    text-decoration: none;
    color: #d9d9d9;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul li a:hover {
    background: #1a1a1a;
    color: #fff;
    transition: 0.2s ease;
}

.main-content-chat .container-fluid .row .message .message_content {
    height: 480px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .picture {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .picture img {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .details {
    position: relative;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
    font-size: 19px;
    font-weight: 600;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
    position: absolute;
    top: 16px;
    left: 25%;
    bottom: 0;
    width: 15px;
    height: 15px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body {
    padding: 0 10px 0 10px;
    height: 325px;
    overflow-y: scroll;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .time {
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    color: #000;
    text-align: right;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .time p {
    padding-top: 0;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .picture {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .picture img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talk-bubble {
    margin: 0;
    /* display: inline-block;
    position: relative;
    width: 100%; */
    display:table-cell;
    height: auto;
    background-color: #161618;
    border: 1px solid transparent;
    border-radius: 10px;
    border-top-left-radius: 0px !important;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
    padding: 6px 8px 6px 8px;
    text-align: left;
    line-height: 1.5em;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #e9e9e9;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #161618 transparent transparent #161618;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .message_block:nth-child(3) .time {
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    color: #000;
    text-align: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received {
    display: flex;
    align-items: center;
    margin: 0 0 40px 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talk-bubble {
    margin: 0;
    /* display: inline-block;
    position: relative;
    width: 100%; */
    float: right;
    display:table-cell;
    height: auto;
    background-color: #e6e6e6;
    border: 1px solid transparent;
    border-radius: 10px;
    border-top-right-radius: 0px !important;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
    padding: 6px 8px 6px 8px;
    text-align: left;
    line-height: 1.5em;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #433630;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 38px;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #e2e8f3 #e2e8f3 transparent transparent;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea {
    position: relative;
    padding: 0 4px 0 4px;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea textarea {
    position: relative;
    padding: 16px 20px;
    height: 50px;
    font-size: 17px;
    font-weight: 400;
    background-color: #ebebeb;
    border: 1px solid #afb8b9;
    color: #161618;
    display: block;
    width: 100%;
    border: none;
    appearance: none;
    resize: none;
    border-radius: 10px;
    border-top-right-radius: 0px;
    /* border-bottom-left-radius: 10px; */
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg {
    width: 90%;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn {
    width: 10%;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn .sign_submit {
    width: 100%;
    height: 50px;
    background: #161618;
    border: 0;
    font-size: 14px;
    line-height: 1;
    color: #e6e6e6;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
    position: relative;
    top: 7px;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea span {
    position: absolute;
    top: 12px;
    right: 0;
}

@media only screen and (max-width:1366px) {
    .main-content-chat .container-fluid .row .chat_user {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 58%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        left: 50%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0 4px 0 4px;
    }

}

@media(max-width:1199px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 50%;
        max-width: 50%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: 570px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .left-sec {
        flex: 50%;
        max-width: 50%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 394px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: 550px;
    }

    

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 23%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

}

@media(max-width:990px){
    .main-content-chat .container-fluid .row .chat_user .user .user_details .details{
        height: auto;
    }
    .main-content-chat .container-fluid .row .chat_user .user .user_details[data-v-15c78484] {
        width: 100%;
        height: auto;
    }
    .main-content-chat .container-fluid .row .chat_user .user .user_details .details h2[data-v-15c78484] {
        line-height: 1.5;
    }
    .main-content-chat .message-wrapper .message_content_head[data-v-15c78484] {
        padding: 10px;
    }
}

@media(max-width:991px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 50%;
        max-width: 50%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: auto;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .left-sec {
        flex: 50%;
        max-width: 50%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 394px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: auto;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 23%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

}

@media(max-width:767px) {
     .main-content-chat{
        height: auto;
    }
    .column-char__bar{
        height: 100vh;
    }
    .chat-vr-slider{
    padding: 20px 15px 30px;
}
    .main-content-chat .message-wrapper{
        height: 100vh;
    }
    .lod-modal[data-v-15c78484] {
        font-size: 16px;
        padding: 0 12px;
    }
    .chat-vr-slider {
        background: #272729;
        overflow-y: scroll;
        height: calc(100% - 145px);
    }
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: 570px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        display: block;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }
    .main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h2{ line-height:16px;}

    .main-content-chat .container-fluid .row .left-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 484px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0px 6px 6px 6px;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: auto;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 60%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn {
        width: 30%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn .sign_submit {
        font-size: 13px;
    }

}



@media(max-width:599px){
    .chat-vr-slider>.item{
        margin-bottom: 12px;
    }
}
.modelPopups .content_wrapper{
    max-width: 600px !important;
    width: 85% !important;
}

@media (max-width:599px){
    .modal_body .form_grp .item_grp{
        display: flex;
        row-gap: 15px;
        flex-direction: column;
    }
    .modal_body .form_grp .item_grp > :nth-child(1),
    .modal_body .form_grp .item_grp > :nth-child(2){
        width: 100% !important;
    }
    #jobpost-form .bottom_cd>.cards{
        display: flex;
        row-gap: 15px;
        flex-wrap: wrap;
        flex-direction: column;
    }
    #jobpost-form .bottom_cd>.cards .card:first-child{
        margin-top: 10px;
    }
    #jobpost-form .bottom_cd>.cards .card{
        width: 100% !important;
        flex-grow: 1;
        margin: 0;
    }
}
</style>