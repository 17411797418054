<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part">
    <h3>Job List</h3>
    <div v-if="jobList && jobList.data?.length > 0">
        <!--<button v-on:click="filter" type="button" class="filter_btn"><i class="fas fa-align-left"></i></button>-->
        <div class="joblist_Wpr">
            <ul class="job_list">
                <JobCardList :callback="clickToGetJobDetails" v-bind:key="job.id" v-for="job in jobList.data" :jobData="job" icon="icon2.png" />
            </ul>
        </div>
			<v-pagination v-if="is_show"
			v-model="page"
			:pages="jobList.last_page"
			:range-size="1"
			active-color="#DCEDFF"
			@update:modelValue="getResults"
			/>
        <!----<ul class="bredcrumbs">
        <li class="active">1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <li><i class="fas fa-ellipsis-h"></i></li>
    </ul>-->
    </div>
    <div class="gt-started-frm" v-if="nofound">
    <p>Sorry! no data found.</p>
    </div>
</div>
</div>
</div>
<!-- <div class="modal" v-if="modalMembership">
    <div class="content_wrapper">
        <div class="modal_header">
        <h2>You have to upgrade your membership plan then you can see the details of job !</h2>
        <button @click="close"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body">
        <div class="form_grp">
            <div class="item_grp">
                <button class="close_btn" @click="close">Cancel</button>
            </div>
        </div>
        </div>
    </div>
</div> -->
</template>

<script>
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import JobCardList from '../components/JobCardList.vue'
import { createLocal } from 'the-storages'
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx

    export default {
        name: 'Joblist',
        components: {
            Dashboardheader,
            Dashboardsidebar,
            JobCardList,
            VPagination
           
        },     

        data() {
            return {
                storageData: mirror,
                storage: storage,
                current: 1,
                jobList: {},
                error: '',
                error2: '',
				is_show:false,
				nofound:false,
                modalMembership:false,
            }
        },
        mounted() {
            this.getResults();
        },
        created(){
            this.userId=mirror.AUTHID;
            this.userType=mirror.USERTYPE;
            this.membershipType=mirror.MEMBERSHIP_TYPE;
            this.hash = location.hash;
            if(this.hash=='#show'){
                this.showToast("Success","Data Added successfully!","success");
                location.hash = '';
            }

            console.log("VAL:: ", this.hash);
        },
        methods: {

            getResults(page) {
                if (typeof page === "undefined") {
                    page = 1;
                }
                axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                    const inputData = {
                        userId: mirror.AUTHID
                    };
                    axios.post(API_BASE_URL + `job_list?page=${page}`, inputData).then(response => {
                        this.jobList = response.data
                        this.is_show=this.jobList.last_page>1?true:false;
                        console.log("JOB_LIST:",this.jobList)
						if(this.jobList.data?.length>0){
                            this.nofound=false;
                            this.is_loader=false;
						} else {
                            this.nofound=true;
                            this.is_loader=false;
						}
                    }).catch(({ response }) => {
                        this.error=response
                    })
                    this.error2=response
                });
                this.is_show = true;
            },
            async clickToGetJobDetails(jobId,slug){
                //const slug = 1;
                console.log("JOB:",jobId,"-SLUG:",slug);
                // if(mirror.MEMBERSHIP_TYPE=='Free') {
                    
                // } else {
                //     this.$router. push('/jobDetails/'+slug);
                // }
                this.$router. push('/jobDetails/'+slug);
            },
            close: function(){
                this.modalMembership = false;
            },
            showToast(title,description,type){
                createToast({
                    title: title,
                    description: description
                },
                {
                    type: type,
                    position: 'bottom-right',
                    // hideProgressBar: 'true',
                    // toastBackgroundColor: color,
                })
            },
        },
        beforeRouteEnter(to, from, next) {
		if (storage.get('TOKEN')) {
			if(storage.USERTYPE==2)
				next();
			else if(storage.USERTYPE==3)
				next('/jobs');
			else
				next('/');	
			}
			else{
				next('/login');
			}
		}
    }

</script>

<style scoped>
.joblist_Wpr{
    background: #28282b;
    padding: 30px 60px;
    margin-top: 20px;
    box-shadow: 0 0 6px rgb(0,0,0,0.5);
    position:relative;
}
.joblist_Wpr:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-image: url('~@/assets/figure-bg.png');
    background-position: 100% 100%;
    background-repeat: no-repeat ;
}
.job_list > li{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    padding: 40px 0;
    position: relative;
    z-index: 2;
}
.job_list > li:last-child{
    border:0;
}
@media(max-width:991px){
    .joblist_Wpr{
      padding: 10px 20px;
  }
  .job_list > li{
    padding: 20px 0;
  }
}
</style>