<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part cd">
<div>
    <h5>Events </h5>
    <div class="bottom_cd">
        <div class="thirty-three" v-bind:key="event.id" v-for="(event) in eventList.data">
            <div class="blog_wpr">
                <router-link :to="'/event/' + event.slug" class="">
                <img  v-if="event.event_featured_image!=''" v-bind:src="imagepath+event.event_featured_image" v-bind:alt="event.event_title" width="250"/>
                <img src="@/assets/b-1.jpg" v-else>	
                </router-link>
                <div class="thirty-three-text">
                    <p><strong><span style="color:#cda53a;">{{ moment(event.event_date).startOf("month").format('MMMM')}}</span> {{ moment(event.event_date).format("Do YYYY") }} - {{ event.event_time }}</strong> </p> 
                    <p class="tt-u sub-text"> Location : <strong>{{event.event_location}}</strong></p> 
                    <h4><router-link :to="'/event/' + event.slug" class="">{{event.event_title}}</router-link></h4>  
                </div>
            </div>
        </div>
        <div class="pagination">
          <pagination :data="laravelData" @pagination-change-page="getResults">
            <a v-if="current>1" @click="prev" class="read_more previous">Previous</a>
            <a v-if="totalpage>current" @click="next()" class="read_more next">Next</a>
            </pagination>
			</div>
    </div>
    
</div>
</div>
</div>
</div>
</template>


<script>
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'

const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'Events',
  components:{
    Dashboardheader,
    Dashboardsidebar
  },
  data() {
    return {
      moment:moment,
			storageData: mirror,
			storage: storage,
			isLoading: true,
			imagepath:{},
			eventList:{},
			visible: false,
      current: 1,
      totalpage:1,
      totalEventCount:this.totalEventCount
    }
  },
  async created () {
      this.AuthUserId = mirror.AUTHID
      this.userType = mirror.USERTYPE
      this.imagepath = API_IMAGE_PATH+'event/'
      this.getResults();
	},
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      return this.blogs.slice(this.indexStart, this.indexEnd);
    }
  },
  mounted() {
      this.getResults();
  },
  methods:{
    prev() {
          this.current--;
          this.getResults();  
        },
        next() {
          this.current++;
          this.getResults();  
        }, 
        getResults() {
            
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `eventList?page=${this.current}`, inputData)
                .then(response => {
                    this.eventList = response.data;
                    this.totalpage = response.data.last_page;
                    console.log("DATA3:",response.data);
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        }
	},
  beforeRouteEnter(to, from, next) {
      if (storage.get('TOKEN')) {
          next();
      }
      else {
          next('/login');
      }
  }
}
</script>

<style scoped>
.pagination {
    cursor: pointer;
		justify-content: center;
		padding: 10px;
	}

	.pagination {
		margin-top: 0;
	}
.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width:100%;
}


.pagination a.next {
	color: #d70b0b;
	border-color: #d70b0b;
	margin-left: 10px;
}

.pagination a.next:hover {
	background: #d70b0b;
	color: #fff;
}
.read_more {
	color: #939393;
	border: solid 1px #939393;
	padding: 7px 25px;
	text-decoration: none;
	display: inline-block;
	margin-top: 20px;
	font-size: 16px;
}

.read_more:hover {
	background: #cfcfcf;
	color: #000;
}

.joblist_Wpr{
  background: #1b1b1b;
  padding: 30px 60px;
  position: relative;
}
.joblist_Wpr:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-image: url('~@/assets/figure-bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat ;
}
.job_list > li{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255,255,255,0.05);
  padding: 40px 0;
  position: relative;
  z-index: 2;
}
.job_list > li:last-child{
  border:0;
}
@media(max-width:991px){
  .joblist_Wpr{
    padding: 10px 20px;
  }
  .job_list > li{
    padding: 20px 0;
  }
}
@media(max-width:575px){
  .srch-lft:first-of-type{
    width: 100%;
    order: 2;
  }
}
</style>