<template>
	<Navigation />
	<div class="login_main">
		<div class="container">
			<div class="login_basic_structure">
				<!-- <h4>Candidate</h4> -->

				<h1 style="color:red;text-transform:uppercase;">Forgot Password</h1>
				<!-- <img src="@/assets/sign.png">  -->

				<form>

					<!-- <div>storageData (storage mirror object): {{ storageData }}</div>
					<div>storageProxyObj (storage proxy object): {{ storage.hello }}</div> -->
					<div v-show="cnfEmailShow==true">
						<div class="form_group">
							<label for="fname">Your Email Id</label>
							<input type="text" placeholder="Enter Email Address" v-model="email">
							<!-- <span v-on="$v.form.email.$touch">hggfhgfhgfhgfhh</span> -->
							<p v-if="errors.length">
								<span v-for="error in errors" v-bind:key="error">{{ error }}</span>
							</p>
						</div>
						<!-- <button v-on:click="checkingEmail" type="button" class="sign_submit">Next</button> -->
						<button :disabled="buttonNextClick" v-on:click="checkingEmail" type="button" class="sign_submit">
							<span v-if="buttonNextClick==true" style="color:white">Checking. . .</span>
							<span v-else style="color:white">Next</span>
						</button>
						<!-- <button v-if="buttonNextClick==true" >Checking,. . .</button>
						<button v-on:click="checkingEmail" v-else type="button" class="sign_submit">Next</button> -->
					</div>
					<div v-show="cnfPasswordShow==true">
						<input type="hidden" v-model="userId">
						<input type="hidden" v-model="userEmail">
						<input type="hidden" v-model="token">
						<div class="form_group">
							<label for="lname">OTP</label>
							<input type="text" placeholder="Enter OTP" v-model="otp">
							<button :disabled="buttonResendClick" v-on:click="reSendOTP" type="button" class="resend_submit" style="cursor:pointer;">
								<span v-if="buttonResendClick==true" style="color:white">Sending. . .</span>
								<span v-else style="color:white">Resend OTP</span>
							</button>
							<!-- <button v-on:click="reSendOTP" type="button" class="resend_submit" style="cursor:pointer;">Resend OTP</button> -->
						</div>
						<div class="form_group">
							<label for="lname">password</label>
							<input :type="passwordFieldType" placeholder="Enter Password" v-model="passwords">
						</div>
						<div class="form_group">
							<label for="lname">confirm password</label>
							<input :type="passwordFieldType" placeholder="Enter Confirm Password" v-model="cnfPassword">
						</div>
						<div class="form_group">
							<p style="color:red">*Use 8 or more characters with a mix of letters, numbers & symbols</p>
							<label class="input"> <input style="width:5%;height:13px;" type="checkbox" v-on:click="switchVisibility"> Show password</label>
						</div>
						<button :disabled="buttonClick" v-on:click="savePassword" type="button" class="sign_submit">
							<span v-if="buttonClick==true" style="color:white">Submitting. . .</span>
							<span v-else style="color:white">Submit</span>
						</button>

					</div>
					
					<!-- <div v-if="errormessage!=''" style="color:red;" class="errormessage">{{errormessage}}</div> -->
					<div class="create_forgot">
						<router-link to="/login" class="forgot_password">Login?</router-link>
					</div>
				</form>
			</div>
		</div>
	</div>
	<Footer />
</template>
<script>
import { BASE_URL } from '../config'
import axios from 'axios'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import { createLocal} from 'the-storages'
import { API_MAIL_URL } from '../config'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx
var cnfEmailShow = true;
var cnfPasswordShow = false;
export default{
	name: 'ForgetPassword',
	components: {
		Navigation,
		Footer
	},
    data(){
        return{
		form:{
			email:'',
			otp:'',
			passwords:'',
			cnfPassword:''
			},
			errors: [],
			errormessage: '',
			cnfEmailShow:cnfEmailShow,
			cnfPasswordShow:cnfPasswordShow,
			userId: '',
            storageData: mirror,
            storage: storage,
			buttonResendClick:false,
			buttonClick:false,
			buttonNextClick:false,
			passwordFieldType:'password'
      }
	},
	methods:{
		checkingEmail: function() {
			this.buttonNextClick = true;
			// if (this.email) {
			// 	return true;
			// }
			this.errors = [];
			if (!this.email) {
				this.errors.push('Enter your mail id.');
			}
			if(this.errors.length===0){
				axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
					axios.post(BASE_URL +'api/emailChecking', {
						email: this.email
					})
					.then(response => {						
						if (response.data.success==true) {
							this.buttonNextClick = false;
						// axios.post(API_MAIL_URL + 'sendotp', 								{
						// 		email: this.email,
						// 		// otp:response.data.otp,
						// 		name:response.data.userName,	
						// 	});
							this.cnfEmailShow = false;
							this.cnfPasswordShow = true;
							this.userId = response.data.userId;
							this.userEmail = response.data.useremail;
                            this.token = response.data.token;
							//console.log('data:',response.data)
							this.errormessage = 'OTP Sent Successfully';
							this.showToast("Success",this.errormessage,"success"); 
						} else {
							this.buttonNextClick = false;
							this.cnfEmailShow = true;
							this.cnfPasswordShow = false;
							this.errormessage = response.data.message
							this.showToast("Warning!",this.errormessage,"warning"); 
						}
					})
					.catch(error => {
						console.error(error);
					});
					console.log(responses);
				});
			}
			// setTimeout(() => {
			// 	this.errormessage = '';
			// 	console.log(this.errormessage);
			// }, 5000);
		},
		savePassword: function() {
			this.buttonClick = true;
			// if (this.email) {
			// 	return true;
			// }
			this.errors = [];
			// if (!this.passwords) {
			// 	this.errors.push('Password required.');
			// }
			// if (!this.cnfPassword) {
			// 	this.errors.push('Confirm Password required.');
			// }
			// if (!this.otp) {
			// 	this.errors.push('OTP required.');
			// }

           // alert(this.cnfPassword + '__' + this.passwords)
			if(this.cnfPassword==this.passwords && this.otp){
				if(this.errors.length===0){
					axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
						axios.post(BASE_URL +'api/passwordChange', {
							userId: this.userId,
                            email: this.userEmail,
							otp: this.otp,
							password: this.passwords,
							password_confirmation: this.cnfPassword,
							token: this.token
						})
						.then(response => {
							
							if (response.data.success==true) {
								console.log('data:',response.data)
								//this.$router.push({ name: "Login" });
								this.buttonClick = false;
								localStorage.setItem('forgetPassword', 'yes');
								this.$router.push('/login');
								//,hash: "#show"
								this.$router.push({ name: "Login" ,hash: "#show"});
								//this.$router.push({name:'Login', params: {slug: slugs}});
							} else {
								localStorage.setItem('forgetPassword', '');
								this.errormessage = response.data.message
								this.buttonClick = false;
								console.log('dataFailed:',this.errormessage)
								this.showToast("Warning!",this.errormessage,"warning"); 
							}
						})
						.catch(error => {
							console.error(error);
						});
						console.log(responses);
					});
				}
			} else {
				this.buttonClick = false;
				this.errormessage = 'Password and Confirm Password must be same';
				this.showToast("Warning!",this.errormessage,"warning"); 
			}
			// setTimeout(() => {
			// 	this.errormessage = '';
			// 	console.log(this.errormessage);
			// }, 5000);
		},
		reSendOTP: function() {
			this.buttonResendClick = true;
			this.errors = [];
				axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
					axios.post(BASE_URL +'api/resendOTP', {
						userId: this.userId
					})
					.then(response => {
						if (response.data.success==true) {
							axios.post(API_MAIL_URL + 'sendotp', 								{
								email: response.data.useremail,
								otp:response.data.otp,
								name:response.data.userName,	
							});
							console.log('data:',response.data)
							//this.$router.push({ name: "Login" });
							this.errormessage = response.data.message;
							this.buttonResendClick = false;
							this.showToast("Success",this.errormessage,"success"); 
						} else {
							this.errormessage = response.data.message
							console.log('dataFailed:',this.errormessage);
							this.buttonResendClick = false;
							this.showToast("Warning!",this.errormessage,"warning"); 
						}
					})
					.catch(error => {
						console.error(error);
					});
					console.log(responses);
				});
			// setTimeout(() => {
			// 	this.errormessage = '';
			// 	console.log(this.errormessage);
			// }, 5000);
		},
		switchVisibility() {
			this.passwordFieldType = this.passwordFieldType==="password"?"text":"password";
		},
		showToast(title,description,type){
			createToast({
				title: title,
				description: description
			},
			{
				type: type,
				position: 'bottom-right',
				// hideProgressBar: 'true',
				// toastBackgroundColor: color,
			})
		},
	},
	beforeRouteEnter(to, from, next) {
		if (storage.get('TOKEN')) {
			console.log('test')
			next('/dashboard');
			//this.$router.push({ name: "Blogs" });
		}
		else {
			next();
		}
	}
}
</script>
<style scoped>
span {
        color: red;
    }
.login_main .login_basic_structure form .resend_submit{
	width: 25%;
    height: 20px;
    background: #c71e1e;
    border-radius: 18px;
    border: none;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    /* top: 7%; */
    margin-top: 5px;
    float: right;
}
.login_main{
    padding:15% 0 10%;
    background: url('~@/assets/log_bg.jpg') no-repeat #111112;
    background-size:cover;
}

.login_main .login_basic_structure {
	width: 400px;
	height: auto;
	background-color: #212122;
	box-shadow: 0 3px 5px #000;
	margin: auto;
	float: none;
	padding: 20px 40px 30px;
	background-image: url(~@/assets/user.png);
	background-position: bottom right;
	background-repeat: no-repeat;
	border-top: solid 2px #b71d1d;
}

.login_main .login_basic_structure h4 {
	margin: 0 0 20px;
	font-size: 22px;
	color: #fff;
	text-transform: uppercase;
}

.login_main .login_basic_structure form {
	margin-top: 30px;
	clear: both;
	width: 100%;
}

.login_main .login_basic_structure form .form_group {
	width: 100%;
	margin-bottom: 20px;
}

.login_main .login_basic_structure form .form_group label {
	width: 100%;
	font-size: 16px;
	color: #fff;
	margin-bottom: 5px;
	display: inline-block;
	text-transform: capitalize;
}

.login_main .login_basic_structure form .form_group input {
	width: 100%;
	height: 50px;
	border: solid 1px #3d3d3d;
	background: transparent;
	box-sizing: border-box;
	color: #fff;
	font-size: 16px;
	padding: 10px;
}

.login_main .login_basic_structure form .sign_submit {
	width: 100%;
	height: 55px;
	background: #c71e1e;
	border-radius: 4px;
	border: none;
	font-size: 19px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
    cursor:pointer;
}

.login_main .login_basic_structure .create_forgot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
}

.login_main .login_basic_structure .create_accout {
	color: #fff;
	font-size: 16px;
	font-weight: 600;
}

.login_main .login_basic_structure .forgot_password {
	color: #c0c0c0;
	font-weight: 500;
}
</style>