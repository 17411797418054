<template>
  <div class="gt-started-frm tabinfo" id="experience_info">
	<!-- <div v-if="message!=''" class="success">{{message}}</div>
	<div v-if="errormessage!=''" class="error">{{errormessage}}</div> -->
    <h4>Cerification</h4>
    <ul class="experience_list" v-if="certificateList">
      <li v-bind:key="certificate.id" v-for="(certificate,key) in certificateList">
        <div class="experience_card">
          <div class="experience_head">
            <h5>Certificate {{key+1}}</h5>
            <div class="action_btn">
              <button class="edit_btn" @click="editPop(certificate.id)"><i class="fas fa-pencil-alt"></i></button>
              <button class="delete_btn" @click="deletePop(certificate.id)"><i class="far fa-trash-alt"></i></button>
            </div>
          </div>
          <div class="experience_body">
            <label for="">Course Name:</label>
            <p>{{certificate.course_name}}</p>
            <label for="">Certificate:</label>
              <p v-if="certificate.certificate_file && (getFileType(certificate.certificate_file)=='jpeg' || getFileType(certificate.certificate_file)=='jpg'|| getFileType(certificate.certificate_file)=='png' )">
                <img v-bind:src="imagepath+certificate.certificate_file" v-bind:alt="certificate.id" >
              </p>
              <p v-else-if="certificate.certificate_file && getFileType(certificate.certificate_file)=='pdf'" >
                <img src="@/assets/pdf.png" alt="" >
              </p>
              <p v-else-if="certificate.certificate_file && getFileType(certificate.certificate_file)=='docx'" >
                <img src="@/assets/docx.png" alt="" >
              </p>
              <p v-else>
                <!-- <img src="@/assets/no-image.jpg" alt="" style="height:300px;width:220px;"> -->
              </p>
          </div>
        </div>
      </li>
     
    </ul>
    <ul v-if="nodata">
     <li>No Data Found</li>
    </ul>
    <!-- <button class="save_btn">Save</button> -->
    <button @click="addPop" class="add_btn"><i class="fas fa-plus"></i> Add Certificate</button>
  </div>
  <!-- Edit Experience -->
  <div class="modal" v-if="modal">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>Edit Certificate</h2>
        <button @click="close"><i class="fas fa-times"></i></button>
      </div>
      <form id="jobpost-form" v-on:submit.prevent="editUserCertificate" enctype="multipart/form-data">
      <div class="modal_body">
        <div class="form_grp"> 
          <div class="item_grp"><label>Course Name:</label>
          <input type="hidden" v-model="stateEdit.editId" name="editId" id="editId"/>
          <input type="text" placeholder="Enter Course Name" v-model="stateEdit.editCourseName" name="editCourseName" id="editCourseName"/>
          
          <span v-if="v2$.editCourseName.$error">
			{{ v2$.editCourseName.$errors[0].$message }}
			</span>
          </div> 
          <div class="item_grp">
            <label>Certificate:</label>
            <input v-if="stateEdit.editCertificate=='' || stateEdit.editCertificate==NULL" type="file" ref="editCertificate" @input="certificateEditUpload" name="editCertificate" id="editCertificate" >
            <span v-if="stateEdit.editCertificate=='' || stateEdit.editCertificate==NULL" >*File must be JPEG,JPG,PDF,DOCX </span>
            <span v-if="v2$.editCertificate.$error">
			{{ v2$.editCertificate.$errors[0].$message }}
			</span>
            <div class="img_wpr" v-else>
              <img v-if="certificatePathEdit && (getFileType(stateEdit.editCertificate)=='jpeg' || getFileType(stateEdit.editCertificate)=='jpg' || getFileType(stateEdit.editCertificate)=='png')" v-bind:src="certificatePathEdit" v-bind:alt="stateEdit.editCertificate" >
              <img v-else-if="certificatePathEdit && getFileType(stateEdit.editCertificate)=='pdf'" src="@/assets/pdf.png" alt="">
              <img v-else-if="certificatePathEdit && getFileType(stateEdit.editCertificate)=='docx'" src="@/assets/docx.png" alt="">
              
              <a @click.prevent="removeImage(stateEdit.editId)" class="removeicon"><i class="fa fa-times" aria-hidden="true"></i></a>
            </div>
            <div class="item_grp">
            <button type="submit" class="add_btn" :disabled="buttonclick">
              <span v-if="buttonclick==true" style="color:white">Loading. . . . .</span>
              <span v-else style="color:white"> Update Certificates</span>
            </button>
          </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <!-- Add New Experience -->
  <div class="modal" v-if="addModal">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>Add New Certificate</h2>
        <button @click="close"><i class="fas fa-times"></i></button>
      </div>
      <form id="jobpost-form" v-on:submit.prevent="addUserCertificate" enctype="multipart/form-data">
      <div class="modal_body">
        <div class="form_grp">  
          <div class="item_grp"><label>Course Name*:</label> 
          <input type="text" placeholder="Enter Course Name" v-model="state.courseName" name="courseName" id="courseName"/>
          <!-- <span>*File must be JPEG,JPG,PDF,DOCX </span> -->
          <span v-if="v$.courseName.$error">
          {{ v$.courseName.$errors[0].$message }}
          </span>
          </div> 
          <div class="item_grp">
            <label>Certificate*:</label>
            <input type="file" ref="certificates" @input="certificateUpload" name="certificates" id="certificates" >
            <span style="display: block;">*File must be JPEG,JPG,PDF,DOCX </span>
            <span v-if="v$.certificates.$error">
              {{ v$.certificates.$errors[0].$message }}
            </span>
          </div>
          <div class="item_grp">
            <button type="submit" class="add_btn" :disabled="buttonclick">
              <span v-if="buttonclick==true" style="color:white">Loading. . . . .</span>
              <span v-else style="color:white"><i class="fas fa-plus" data-v-bf594912=""></i> Add Certificates</span>
             </button>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <!-- Delete -->
  <div class="modal" v-if="deleteModal">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>Are you sure want to remove this certificate ?</h2>
        <!-- <button @click="close"><i class="fas fa-times"></i></button> -->
      </div>
      <div class="modal_body">
        <div class="form_grp">
          <form id="jobpost-form" v-on:submit.prevent="deleteCertificateSubmit">
            <div class="item_grp">
              <input type="hidden" v-model="deleteId" name="deleteId">
              <button type="submit" class="add_btn">Confirm</button>
              <button class="add_btn" @click="close">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { createLocal } from 'the-storages'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'EpC',
  setup() {
    const state = reactive({
        courseName: '',
        certificates: '',
    })
    const stateEdit = reactive({
        editCourseName: '',
        editCertificate: '',
    })
    const rules = computed(() => {
      return {
        courseName: { required },
        certificates: { required },
      }
    })
    const rules2 = computed(() => {
      return {
        editCourseName: { required },
        editCertificate: { required },
      }
    })
    const v2$ = useValidate(rules2, stateEdit)
    const v$ = useValidate(rules, state)
    return {
      state,
      stateEdit,
      v$,
      v2$
    }
  },
  data(){
    return{
      modal: false,
      addModal: false,
      deleteModal: false,
      courseName: '',
      certificates: '',
      errors: [],
      errormessage: '',
      message: '',
      storageData: mirror,
      storage: storage,
      errorStr: null,
      imagepath:{},
      certificateList:this.certificateList,
      deleteId:this.deleteId,
      editCourseName:this.state.editCourseName,
      editCertificate:this.state.editCertificate,
      editId:this.stateEdit.editId,
      certificatePath:this.certificatePath,
      certificatePathEdit:this.certificatePathEdit,
      nodata:false,
      editCertificate2:'',
      buttonclick:false,
    }
  },
  async created() {
    const certificateList = await axios.post(API_BASE_URL + 'certificateList', {userId: mirror.AUTHID});
    console.log("LIST:",certificateList.data);
    this.certificateList = certificateList.data;
    if(certificateList.data?.length<=0){
      this.nodata=true;
    }else{
      this.nodata=false;	
    }
    this.imagepath = API_IMAGE_PATH+"certificates/";
	},
  methods:{
    getFileType(imageName){
      var fileName = imageName;
      var fileExtension = fileName.split('.').pop();
      return fileExtension;
    },
    async addUserCertificate() {
      this.buttonclick = true;
			const config = {
      headers: { 'content-type': 'multipart/form-data' }
      }
      this.v$.$validate(this.v$.$error);
      console.log("1");
      if (!this.v$.$error) {
        console.log("2");
        let formData = new FormData();
        console.log(this.state.certificates);        	 
         
        if(this.state.certificates!=''){			
          formData.append('certificates', this.state.certificates);
        }
        formData.append('userId', mirror.AUTHID);
        formData.append('courseName', this.state.courseName);
        if (this.state.courseName && this.state.certificates ) {
            // console.log("33");
            console.log("3");
            //axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                await axios.post(API_BASE_URL + 'add-certificate', formData,config)
                    .then(response => {                        	
                    console.log(response)                        	
                        if (response.data.result == 'success') {
                            this.errormessage ='';
                            this.message = response.data.message;
                            this.addModal = false
                            //this.$router.push({ name: "JobList" });
                            this.state.courseName = '';
                            this.state.certificates = '';
                            this.buttonclick = false;
                            // setTimeout(() => {
                            //     this.errormessage = '';
                            //     console.log(this.errormessage);
                            // }, 5000);
                            this.showToast("Success!",this.message,"success"); 
                        } else {
                            this.errormessage = response.data.message;
                            this.message = '';
                            this.buttonclick = false;
                            this.showToast("Failed!",this.errormessage,"danger"); 
                        }
                    })
                    .catch(error => {
                        //console.log('error-data:', error)
                        console.error(error);
                        this.buttonclick = false;
                    });
                //console.log(responses);
                const certificateList = await axios.post(API_BASE_URL + 'certificateList', {userId: mirror.AUTHID});
                if(certificateList.data?.length<=0){
                  this.nodata=true;
                }else{
                  this.nodata=false;	
                }
                console.log("LIST:",certificateList.data);
                this.certificateList = certificateList.data;
                var element = document.getElementById("experience_info");
                element.scrollIntoView();
            //});
            
        } else {
          this.errormessage = 'All Fields are Mandatory';
          this.buttonclick = false;
          this.showToast("Warning!",this.errormessage,"warning"); 
        }
      } else {
        this.errormessage = 'All Fields are Mandatory';
        this.buttonclick = false;
        this.showToast("Warning!",this.errormessage,"warning"); 
      }
      // setTimeout(() => {
      //     this.errormessage = '';
      //     console.log(this.errormessage);
      // }, 5000);
    },
    certificateUpload(e) {
      const file = e.target.files[0]
      this.state.certificates = file
      this.state.editCertificate2 = file
      this.certificatePath = URL.createObjectURL(file);
      console.log("image1",this.certificates);
    },
    async deleteCertificateSubmit() {
      await axios.post(API_BASE_URL + 'deleteCertifilate', {"deleteId":this.deleteId})
      .then(response => {
        console.log(response);
          if (response.data.result == 'success') {
              this.errormessage ='';
              this.message = response.data.message;
              this.deleteModal = false
              this.showToast("Success",this.message,"success");
              //this.$router.push({ name: "JobList" });
          } else {
              this.errormessage = response.data.message;
              this.message = '';
              this.showToast("Failed",this.errormessage,"danger");
          }
      })
      .catch(error => {
          //console.log('error-data:', error)
          console.error(error);
      });
      const certificateList = await axios.post(API_BASE_URL + 'certificateList', {userId: mirror.AUTHID});
      if(certificateList.data?.length<=0){
        this.nodata=true;
      }else{
        this.nodata=false;	
      }
      console.log("LIST:",certificateList.data);
      this.certificateList = certificateList.data;
    },
    async editUserCertificate() {
      this.buttonclick = true;
			const config = {
        headers: { 'content-type': 'multipart/form-data' }
        }
      this.v2$.$validate(this.v2$.$error);
      //console.log("1");
      if (!this.v2$.$error) {
        //console.log("2");
        let formDataEdit = new FormData();

        if(this.stateEdit.editCertificate!=''){			
          formDataEdit.append('certificates', this.stateEdit.editCertificate);
        }
        formDataEdit.append('editedId', this.stateEdit.editId);
        formDataEdit.append('userId', mirror.AUTHID);
        formDataEdit.append('courseName', this.stateEdit.editCourseName);
        console.log("IMG",this.stateEdit.editCertificate);
        console.log("NAME",this.stateEdit.editCourseName);
        console.log("3");
        if (this.stateEdit.editCourseName && this.stateEdit.editCertificate ) {
            // console.log("33");
            console.log("3");
            //axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                await axios.post(API_BASE_URL + 'edit-certificate', formDataEdit,config)
                    .then(response => {                        	
                    console.log(response)                        	
                        if (response.data.result == 'success') {
                            this.errormessage ='';
                            this.message = response.data.message;
                            this.modal = false;
                            //this.$router.push({ name: "JobList" });
                            this.buttonclick = false;
                            this.showToast("Success!",this.message,"success");
                        } else {
                            this.errormessage = response.data.message;
                            this.message = '';
                            this.buttonclick = false;
                            this.showToast("Failed",this.errormessage,"danger");
                        }
                    })
                    .catch(error => {
                        //console.log('error-data:', error)
                        console.error(error);
                    });
                //console.log(responses);
                const certificateList = await axios.post(API_BASE_URL + 'certificateList', {userId: mirror.AUTHID});
				if(certificateList.data?.length<=0){
				this.nodata=true;
				}else{
				this.nodata=false;	
				}
                console.log("LIST:",certificateList.data);
                this.certificateList = certificateList.data;
                this.buttonclick = false;
				var element = document.getElementById("experience_info");
				element.scrollIntoView();
            //});
            
        } else {
          this.errormessage = 'All Fields are Mandatory';
          this.buttonclick = false;
          this.showToast("Warning!",this.errormessage,"warning"); 
        }
      } else {
        this.errormessage = 'All Fields are Mandatory';
        this.buttonclick = false;
        this.showToast("Warning!",this.errormessage,"warning"); 
      }
      // setTimeout(() => {
      //     this.errormessage = '';
      //     console.log(this.errormessage);
      // }, 5000);
    },
    certificateEditUpload(e) {
      const file = e.target.files[0]
      this.stateEdit.editCertificate = file
      this.certificatePathEdit = URL.createObjectURL(file);
      console.log("image1",this.editCertificate)
    },
    editPop: async function(id){
      this.modal = true
      this.stateEdit.editId = id
      const certificateDetails = await axios.post(API_BASE_URL + 'certificateDetails', {userId: mirror.AUTHID,"editedId":this.stateEdit.editId});
      console.log("DETAILS:",certificateDetails.data);
      this.stateEdit.editCourseName = certificateDetails.data.course_name;
      this.stateEdit.editCertificate = certificateDetails.data.certificate_file;
      this.certificatePathEdit = API_IMAGE_PATH+"certificates/"+certificateDetails.data.certificate_file;
      //this.certificateList = certificateList.data;
    },
    addPop: function(){
      this.addModal = true
    },
    deletePop: function(id){
      //alert("hii");
      this.deleteModal = true
      this.deleteId = id
    },
    close: function(){
      this.modal = false
      this.addModal = false
      this.deleteModal = false
    },
    removeImage(id){
        const inputData = {
          userId: mirror.AUTHID,
          removeImageId: id,
        };
        axios.post(API_BASE_URL + 'user/removeCertificateimage', inputData)
        .then(response => {                        	
          if (response.data.result == 'success') {
            console.log('data:', response)
            
            this.stateEdit.editCertificate='';
            this.stateEdit.editCertificate2='';
            this.certificatePathEdit='';
            this.errormessage = '';
            this.message = response.data.messase;
            //this.$router.push({ name: "JobList" });
            this.showToast("Success!",this.message,"success"); 
          } else {
            this.errormessage = response.data.message;
            this.message = '';
            this.showToast("Failed",this.errormessage,"danger"); 
          }
        })
        .catch(error => {
        //console.log('error-data:', error)
        console.error(error);
        });
        
    },
    showToast(title,description,type){
      createToast({
          title: title,
          description: description
      },
      {
          type: type,
          position: 'bottom-right',
          // hideProgressBar: 'true',
          // toastBackgroundColor: color,
      })
  },
  },
}
</script>

<style scoped>
.modal .modal_body {

    max-height: 70vh;

}
.modal_body .form_grp .item_grp{
  padding: 5px 10px !important;
}
.modal_body .form_grp .item_grp .img_wpr {
    margin-top: 3px;
    display: flex;
    position: relative;
    width: fit-content;
}
.modal_body .form_grp .item_grp .img_wpr .removeicon {
    width: 20px;
    height: 20px;
    background: #c71e1e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    margin-left: 5px;
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;
}
.modal_body .form_grp .item_grp .img_wpr img{
    max-height: initial !important;
    width: auto;
    max-width: 100%;
  }
.experience_list{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.experience_list li{
  width: 33.333%;
  padding: 15px;
}
.experience_list li .experience_card{
  background: #161618;
}
.experience_head{
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience_head h5{
  font-size: 20px;
  line-height: 26px;
}
.experience_head .action_btn{
  display: flex;
  flex-wrap: wrap;
}
.action_btn .edit_btn{
  border: 1px solid #999;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: transparent;
  color: #fff;
  cursor: pointer
}
.action_btn .delete_btn{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: #c71e1e;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}
.experience_body{
  padding: 15px 25px;
}
.experience_body label{
  font-size: 14px;
  line-height: 17px;
  color: #d3d3d3;
  font-weight: 500;
}
.experience_body p{
  font-size: 13px;
  line-height: 18px;
  color: #999;
  font-weight: 300;
  padding: 2px 0 10px !important;
}
.experience_body p img{
  width: 100%;
  height: 300px;
  object-fit: cover;
}
button.save_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #c71e1e;
  background: #c71e1e;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  cursor:pointer;
}
button.add_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  background: transparent;
  margin-left: 25px;
  cursor:pointer;
  float:right;
}
button.add_btn i{
  margin-right: 5px;
}
@media(max-width:991px){
  .experience_list li{
    flex: 0 0 50%;
  }
}
@media(max-width:670px){
  .experience_list li{
    flex: 0 0 100%;
  }
}
</style>