<template>
 <Navigation/>
    <Banner class="blog_banner" bg="podcast-banner.jpg">
        <div class="inr_text blog">
			<img src="@/assets/podcast-heading.png">	
            <h3 style="background: -webkit-linear-gradient(#ffba00, #816417);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">{{details.title}}</h3>
        </div>
    </Banner>
	<section class="main_podcast prdcast-details">
		<div class="container">
			<h3>{{details.title}}</h3>
			<div class="prdst-list"> <ul><li>by {{details.createdBy}} </li> <li>{{moment(details.created_at).startOf("month").format('DD MMMM YYYY')}} </li>  <li><span class="red-text">{{details.episode}}</span></li>
				</ul>
			</div>
			<div class="podcast_box_pic-detls" v-if="details.video_type=='youtube'">
				<div class="mainscreen" v-html="details.embed_code"></div>
				<div class="podcast_season">
					<span>{{details.podcasts_category.name}} </span>
				</div>  
			</div>
			<div class="podcast_box_pic-detls" v-if="details.video_type=='vimeo'">
				<div class="mainscreen" v-html="details.embed_code"></div>
				<div class="podcast_season">
					<span>{{details.podcasts_category.name}} </span>
				</div>  
			</div>
			<div class="podcast_box_pic-detls" v-if="details.video_type=='uploaded'">
				<div class="mainscreen" >
					<video controls v-bind:src="imagepath+details.uploade_url"></video>
					<img v-if="!details.uploade_url" v-bind:src="imagepath+details.featured_image" v-bind:alt="details.title">
				</div>
				<div class="podcast_season">
					<span>{{details.podcasts_category.name}} </span>
				</div>  
				<!-- <div class="plybtn">
					<div class="play">
						<img src="@/assets/play.png">
					</div>
				</div> -->
			</div>
		<p v-html="details.content"></p>
		<br>
		<h3>Itinerary of information during the episode :</h3>
		<p>{{details.excerpt}} 
			<!-- <strong>00:59</strong> – Introduction<br>
			<strong>05:34</strong> – The Special Task Force book <br>
			<strong>10:07</strong> – Cyber Terrorism <br>
			<strong>15:42</strong> – Security is a way of life <br>
			<strong>21:54</strong> – Personal security starts with you <br>
			<strong>33:55</strong> – Proudest moment on the field <br>
			<strong>58:32</strong> – Success Stories <br>
			<strong>01:13:47</strong> – Favorite Quote <br>
			<strong>01:21:01</strong> – Awareness is Everything <br>
			<strong>01:25:04</strong> – End Credits <br> -->
		</p>
	</div>
</section>
<Buttomads/>
<Footer/>
</template>

<script>
import Banner from '../components/Banner.vue'
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { useRoute } from 'vue-router'
import Buttomads from '../components/Buttomads.vue'
//import Contactform from '../components/Contactform.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'PodcastDetails',
    components:{
        Banner,
        Buttomads,
        Navigation,
		Footer
    },
    data() {
        return {
            isLoading: true,
            imagepath:{},
            details: {},
            slugname:'',
            catname:'',
            //authorname:'',
            moment: moment,
        }
    },
    mounted() {
            // if (!document.getElementById('facebook-jssdk')) {
            //     (function (d, s, id) {
            //         var js, fjs = d.getElementsByTagName(s)[0];
            //         if (d.getElementById(id)) return;
            //         js = d.createElement(s);
            //         js.id = id;
            //         js.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0&appId=347004376636576&autoLogAppEvents=1";
            //         fjs.parentNode.insertBefore(js, fjs);
            //     }(document, 'script', 'facebook-jssdk'));
            // }
        },
        
    async created () {
		const route=useRoute();
		const response = await axios.get(API_BASE_URL + 'podcast/'+route.params.slug);
		this.imagepath=API_IMAGE_PATH
		this.details = response.data;
		console.log("Testtttt",response.data);
		
		this.slugname=response.data.category.slug,
		this.catname=response.data.category.name,
		//this.authorname= response.data.user.fname+' '+response.data.user.lname,
		this.isLoading = false,
		
        this.imagepath=API_IMAGE_PATH
        //console.log(this.imagepath);
	}
}
</script>

<style scoped>
	.prdcast-details {padding:60px 0;}
	.prdcast-details h3{ font-size:24px; line-height:28px; font-weight: 400; color:#fff;}
	.prdcast-details h3 strong{ font-weight:700; color:#eb0404;}
	.prdst-list ul{ padding-top:10px;}
	.prdst-list li{ float:left; font-size:18px; text-transform:uppercase; color:#fff; padding:0 10px;}
	.prdst-list li:first-child{ padding-left:0;}
	.prdst-list li+li{ border-left:solid 1px #ccc;}
	.red-text{ color:#eb0404;}
	.podcast_box_pic-detls{ position:relative; padding-top:20px;}
	.podcast_box_pic-detls .podcast_season {
		background: #e10000;
		padding: 4px 10px;
		text-transform: uppercase;
		color: #fff;
		font-size: 14px;
		display: inline-block;
		position: absolute;
		/* bottom: 15px; */
		/* debraj */
		bottom: 15%;
		left: 10px;
		z-index: 2;
		top:inherit;
	}
	.podcast_box_pic-detls .mainscreen img{width:100%;}
	.prdcast-details p strong{ color:#e10000;}
	.plybtn{ position:absolute; left:50%; top:50%; transform:translate(-50%, -50%);}
	.plybtn .play {
		width: 56px;
		height: 56px;}
		div:after {
		clear: both;
		content: "";
		visibility: hidden;
		display: table;
	}
	@media (max-width: 991px) {
		.podcast_box_pic-detls .podcast_season {
			bottom: -30px;
			left: 0px;
			font-size: 12px;
		}
	}
    </style>
    <style>
    .podcast_box_pic-detls .mainscreen iframe{
		width:100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
    }
    .podcast_box_pic-detls .mainscreen{
		position: relative;
    }
    .podcast_box_pic-detls .mainscreen::before{
		display: block;
		content: "";
		width: 100%;
		padding-top: 55.92%;
    }
    .prdcast-details p *{
	color: #888 !important;
	font-size: 15px !important; 
	line-height:24px !important; 
	padding:30px 0 0 !important;
	}
	.prdcast-details pre{
		white-space: initial;
	}
    </style>