<template>
 <Navigation/>
 <div class="about_us_main">
   <div class="about_us">
    <div class="container">
        <div class="row">
            <div class="about_cont">
                <!-- <img src="@/assets/about_us.png" alt="" draggable="false" /> -->
                <h2>Privacy Policy</h2>
                <p>EP Specialist understands that your privacy is important to you. We are committed to protecting the privacy of your personally-identifiable information as you use this website. This Privacy Policy tells you how we protect and use information that we gather from you. By using this website, you consent to the terms described in the most recent version of this Privacy Policy. You should also read our Terms of Use to understand the general rules about your use of this website, and any additional terms that may apply when you access particular services or materials on certain areas of this website. &ldquo;We,&rdquo; &ldquo;our&rdquo; means EP Specialist and its affiliates. &ldquo;You,&rdquo; &ldquo;your,&rdquo; visitor,&rdquo; or &ldquo;user&rdquo; means the individual accessing this site.<br>
 <br>
 <br>
PERSONAL AND NON-PERSONAL INFORMATION<br>
 <br>
Our Privacy Policy identifies how we treat your personal and non-personal information.<br>
 <br>
 <br>
WHAT IS NON-PERSONAL INFORMATION AND HOW IS IT COLLECTED AND USED?<br>
 <br>
Non personal information is information that cannot identify you. If you visit this web site to read information, such as information about one of our services, we may collect certain non-personal information about you from your computer&rsquo;s web browser. Because non-personal information cannot identify you or be tied to you in any way, there are no restrictions on the ways that we can use or share non-personal information. What is personal information and how is it collected? Personal information is information that identifies you as an individual, such as your name, mailing address, e-mail address, telephone number, and fax number. We may collect personal information from you in a variety of ways: &bull; When you send us an application or other form &bull; When you conduct a transaction with us, our affiliates, or others &bull; When we collect information about in you in support of a transaction, such as credit card information &bull; In some places on this web site you have the opportunity to send us personal information about yourself, to elect to receive particular information, to purchase access to one of our products or services, or to participate in an activity.<br>
 <br>
 <br>
ARE COOKIES OR OTHER TECHNOLOGIES USED TO COLLECT PERSONAL INFORMATION?<br>
 <br>
Yes, we may use cookies and related technologies, such as web beacons, to collect information on our web site. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you register with us, a cookie helps EP Specialist to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same EP Specialist website, the information you previously provided can be retrieved, so you can easily use the features that you customized. A web beacon is a small graphic image that allows the party that set the web beacon to monitor and collect certain information about the viewer of the web page, web-based document or e-mail message, such as the type of browser requesting the web beacon, the IP address of the computer that the web beacon is sent to and the time the web beacon was viewed. Web beacons can be very small and invisible to the user, but, in general, any electronic image viewed as part of a web page or e-mail, including HTML based content, can act as a web beacon. We may use web beacons to count visitors to the web pages on the web site or to monitor how our users navigate the web site, and we may include web beacons in e-mail messages in order to count how many messages sent were actually opened, acted upon or forwarded.<br>
 <br>
Third party vendors also may use cookies on our web site. For instance, we may contract with third parties who will use cookies on our web site to track and analyze anonymous usage and volume statistical information from our visitors and members. Such information is shared externally only on an anonymous, aggregated basis. These third parties use persistent cookies to help us to improve the visitor experience, to manage our site content, and to track visitor behavior. We may also contract with a third party to send e-mail to our registered [users/members].<br>
 <br>
To help measure and improve the effectiveness of our e-mail communications, the third party sets cookies. All data collected by this third party on behalf of EP Specialist is used solely by or on behalf of EP Specialist and is shared externally only on an anonymous, aggregated basis. From time to time we may allow third parties to post advertisements on our web site, and those third-party advertisements may include a cookie or web beacon served by the third party. This Privacy Policy does not cover the use of information collected from you by third party ad servers. We do not control cookies in such third party ads, and you should check the privacy policies of those advertisers and/or ad services to learn about their use of cookies and other technology before linking to an ad. We will not share your personal information with these companies, but these companies may use information about your visits to this and other web sites in order to provide advertisements on this site and other sites about goods and services that may be of interest to you, and they may share your personal information that you provide to them with others.<br>
 <br>
You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the EP Specialist websites you visit.<br>
 <br>
 <br>
HOW DOES EP Specialist USE PERSONAL INFORMATION?<br>
 <br>
EP Specialist may keep and use personal information we collect from or about you to provide you with access to this web site or other products or services, to respond to your requests, to bill you for products/services you purchased, and to provide ongoing service and support, to contact you with information that might be of interest to you, including information about products and services of ours and of others, or ask for your opinion about our products or the products of others, for record keeping and analytical purposes and to research, develop and improve programs, products, services and content.<br>
 <br>
Personal information collected online may be combined with information you provide to us through other sources We may also remove your personal identifiers (your name, email address, social security number, etc). In this case, you would no longer be identified as a single unique individual. Once we have de-identified information, it is non-personal information and we may treat it like other non-personal information. Finally, we may use your personal information to protect our rights or property, or to protect someone&rsquo;s health, safety or welfare, and to comply with a law or regulation, court order or other legal process.<br>
 <br>
 <br>
DOES EP Specialist SHARE PERSONAL INFORMATION WITH OTHERS?<br>
 <br>
We will not share your personal information collected from this web site with an unrelated third party without your permission, except as otherwise provided in this Privacy Policy. In the ordinary course of business, we may share some personal information with companies that we hire to perform services or functions on our behalf. In all cases in which we share your personal information with a third party for the purpose of providing a service to us, we will not authorize them to keep, disclose or use your information with others except for the purpose of providing the services we asked them to provide.<br>
 <br>
We will not sell, exchange or publish your personal information, except in conjunction with a corporate sale, merger, dissolution, or acquisition. For some sorts of transactions, in addition to our direct collection of information, our third party service vendors (such as credit card companies, clearinghouses and banks) who may provide such services as credit, insurance, and escrow services may collect personal information directly from you to assist you with your transaction. We do not control how these third parties use such information, but we do ask them to disclose how they use your personal information before they collect it.</p>

<p>If you submit a review for a third party (person or business) using our Facebook Fan Review Application, during the submission process we ask your permission to gather your basic information (such as name and email address) which we then share with the third party for whom you are submitting the review. We may be legally compelled to release your personal information in response to a court order, subpoena, search warrant, law or regulation.<br>
 <br>
We may cooperate with law enforcement authorities in investigating and prosecuting web site visitors who violate our rules or engage in behavior, which is harmful to other visitors (or illegal). We may disclose your personal information to third parties if we feel that the disclosure is necessary to protect our rights or property, protect someone&rsquo;s health, safety or welfare, or to comply with a law or regulation, court order or other legal process. As discussed in the section on cookies and other technologies, from time to time we may allow a third party to serve advertisements on this web site.<br>
 <br>
If you share information with the advertiser, including by clicking on their ads, this Privacy Policy does not control the advertisers use of your personal information, and you should check the privacy policies of those advertisers and/or ad services to learn about their use of cookies and other technology before linking to an ad.<br>
 <br>
 <br>
HOW IS PERSONAL INFORMATION USED FOR COMMUNICATIONS?<br>
 <br>
We may contact you periodically by e-mail, mail or telephone to provide information regarding programs, products, services and content that may be of interest to you. In addition, some of the features on this web site allow you to communicate with us using an online form. If your communication requests a response from us, we may send you a response via e-mail. The e-mail response or confirmation may include your personal information. We cannot guarantee that our e-mails to you will be secure from unauthorized interception.<br>
 <br>
 <br>
HOW IS PERSONAL INFORMATION SECURED?<br>
 <br>
We have implemented generally accepted standards of technology and operational security in order to protect personally-identifiable information from loss, misuse, alteration, or destruction. Only authorized personnel and third party vendors have access to your personal information, and these employees and vendors are required to treat this information as confidential. Despite these precautions, we cannot guarantee that unauthorized persons will not obtain access to your personal information.<br>
 <br>
 <br>
LINKS<br>
 <br>
This site contains links to other sites that provide information that we consider to be interesting. EP Specialist is not responsible for the privacy practices or the content of such web sites.<br>
 <br>
 <br>
PUBLIC DISCUSSIONS<br>
 <br>
This site may provide public discussions on various business valuation topics. Please note that any information you post in these discussions will become public, so please do not post sensitive information in the public discussions. Whenever you publicly disclose information online, that information could be collected and used by others. We are not responsible for any action or policies of any third parties who collect information that users disclose in any such forums on the web site. EP Specialist does not agree or disagree with anything posted on the discussion board. Also remember that you must comply with our other published policies regarding postings on our public forums.<br>
 <br>
 <br>
HOW CAN A USER ACCESS, CHANGE, AND/OR DELETE PERSONAL INFORMATION?<br>
 <br>
You may access, correct, update, and/or delete any personally-identifiable information that you submit to the web site. You may also unsubscribe from mailing lists or any registrations on the web site. To do so, please either follow instructions on the page of the web site on which you have provided such information or subscribed or registered or contact us at info@epspecialist.com<br>
 <br>
 <br>
CHILDREN&rsquo;S PRIVACY<br>
 <br>
EP Specialist will not intentionally collect any personal information (such as a child&rsquo;s name or email address) from children under the age of 13. If you think that we have collected personal information from a child under the age of 13, please contact us.<br>
 <br>
 <br>
CHANGES</p>

<p>EP Specialist reserves the right to modify this statement at any time. Any changes to this Privacy Policy will be listed in this section, and if such changes are material, a notice will be included on the homepage of the web site for a period of time. If you have any questions about privacy at any websites operated by EP Specialist or about our website practices, please contact us at: info@epspecialist.com</p>

<p>
&copy;2019-2021 EP Specialist</p>
                 <!-- <router-link to="/contact" class="about_btn">Work with us</router-link> -->
            </div>
            <!-- <div class="about_person">
                <div class="person">
                    <img src="@/assets/about_person.png" alt="" draggable="false" />
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- <div class="leadership">
    <div class="container">
        <div class="row">
            <div class="leadership_heading">
                <img src="@/assets/leadership.png" alt="" draggable="false" />
            </div>
            <div class="leadership_cont">
                <div class="row">
                    <div class="block">
                        <h3>Honor</h3>
                        <img src="@/assets/honor.png" alt="" draggable="false" />
                        <p>
                            BRG's conception is rooted in the founder’s experience in the United States Marine Corps, where honor is believed to be sacred. So sacred in fact, that death is affectionately welcomed in lieu of honor escaping
                            him. This stronghold is reflected in all that we do, in who we are and in everything we represent. BRG vows to forever be in keeping with this Marine Corps tradition..
                        </p>
                    </div>
                    <div class="block">
                        <h3>Discipline</h3>
                        <img src="@/assets/discipline.png" alt="" draggable="false" />
                        <p>
                            We believe discipline is the driving factor behind success and is what separates the good from the great. Motivation will fade, and when it does, those with the discipline to be consistent will win. Victories and
                            valuable things may be acquired but they are not long maintained, enjoyed, appreciated or leveraged over time without discipline.
                        </p>
                    </div>
                    <div class="border"></div>
                    <div class="block">
                        <h3>Balance</h3>
                        <img src="@/assets/balance.png" alt="" draggable="false" />
                        <p>
                            Balance is probably our most important attribute and value. We feel that the balance must be applied to all things because it is a requirement for homeostasis. Too much of anything is not good and the only way
                            you know that you have enough is through the harmony that comes from a result of finding the right balance of anything. Because our security strategies are highly customized to our clients, balance followed by
                            flexibility are a primary and secondary consideration in all that we do.
                        </p>
                    </div>
                    <div class="block">
                        <h3>Evolution</h3>
                        <img src="@/assets/evolution.png" alt="" draggable="false" />
                        <p>
                            Executive protection is an ever living and changing ecosystem. As the clientele's preferences change so must the practitioner's focus. By making customer service equally as important as security and evolving our
                            security strategies with the needs of our clients as well as the most salient security industry tactics, we are able to maintain mutually beneficial efficient protective measures with our users long-term.
                        </p>
                    </div>
                    <router-link to="/signup" class="leadership_btn">Work with us</router-link>
                </div>
            </div>
        </div>
    </div>
</div> -->
</div>
<Footer/>
</template>
<script>
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
export default{
name: 'PrivacyAndPolicy',
components:{
Navigation,
Footer
}
}
</script>
<style scoped>
        .about_us_main .row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap; justify-content:space-between;}
        .about_us .row{align-items:center;}
        .about_us{background: url('~@/assets/log_bg.jpg') no-repeat #111112;background-size:cover;padding:160px 0 100px;border-bottom:1px solid #1d1d1d;}
        .about_us .about_cont{flex:1 1 500px;padding-right:8%;}
        .about_us .about_cont img{max-width:100%;height:auto;vertical-align:middle;border-style:none;margin-bottom:40px;}
        .about_us .about_cont h2{font-family:'Play',sans-serif;font-size:28px;line-height:35px;font-weight:400;color:#fff;margin-bottom:50px;}
        .about_us .about_cont h2 strong{color:#d71111;font-weight:700;}
        .about_us .about_cont p{font-family:'Play',sans-serif;font-size:18px;line-height:30px;font-weight:400;color:#6f7070;margin-bottom:30px;}
        .about_us .about_cont p:nth-child(2n){margin-bottom:80px;}
        .about_us .about_cont .about_btn{font-family:'Play',sans-serif;font-size:16px;line-height:1;font-weight:400;background-color:transparent;color:#fff;border:1px solid #fff;padding:17px 33px;border-radius:0;text-transform:uppercase;cursor:pointer;width:205px;letter-spacing:1px;text-decoration:none;}
        .about_us .about_person{flex:1 1 300px; padding:16px 18px 16px 0;}
        .about_us .about_person .person{position:relative;width:100%;background-color:#1c1d1e;padding:60px 0 0 0;}
        .about_us .about_person .person img{max-width:100%;height:auto;vertical-align:middle;border-style:none;display:block;margin-left:auto;margin-right:auto;}
        .about_us .about_person .person:before{content:'';position:absolute;background-color:#262626;width:458px;height:1px;background-color:#262626;top:-16px;right:-18px;}
        .about_us .about_person .person:after{content:'';position:absolute;background-color:#262626;width:1px;height:310px;background-color:#262626;top:-16px;right:-18px;}
        .leadership{background:url('~@/assets/leadership_bg.jpg'),#0f1011;background-repeat:no-repeat;padding:90px 0;}
        .leadership .leadership_heading{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;align-self: flex-start;}
        .leadership .leadership_cont{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
        .leadership .leadership_cont .block{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;padding: 35px 25px;}
        .leadership .leadership_cont .block img{max-width:100%;height:auto;vertical-align:middle;border-style:none;margin-bottom:30px;height:92px;}
        .leadership .leadership_cont .block h3{font-family:'Play',sans-serif;font-size:40px;line-height:35px;font-weight:700;color:#fff;margin-bottom:35px;}
        .leadership .leadership_cont .block p{font-family:'Play',sans-serif;font-size:16px;line-height:24px;font-weight:400;color:#767676;}
        .leadership .leadership_heading img{max-width:100%;height:auto;vertical-align:middle;border-style:none;margin:35px 15px 35px 0;}
        .leadership .leadership_btn{font-family:'Play',sans-serif;font-size:15px;line-height:1;font-weight:400;background-color:transparent;color:#fff;border:1px solid #fff;padding:14px 34px;border-radius:0;text-transform:uppercase;cursor:pointer;letter-spacing:2px;text-decoration:none;margin: 35px 25px;}
        .leadership .leadership_cont .border{width:calc(100% - 50px);height:1px;background:#28292a;margin: 0 auto;}
       
       
        @media(max-width:1199px){
          .about_us{padding:120px 0 80px;}
          .about_us .about_cont img{width:180px;margin-bottom:20px;}
          .about_us .about_cont h2{width:100%;font-size:20px;line-height:26px;font-weight:400;color:#fff;margin-bottom:20px;}
          .about_us .about_cont p{width:100%;font-size:16px;line-height:24px;margin-bottom:20px;}
          .about_us .about_cont p:nth-child(2n){margin-bottom:40px;}
          .about_us .about_person .person:before{width:300px;}
          .about_us .about_cont .about_btn{font-size:14px;padding:15px 33px;}
          .about_us .about_person .person{width:100%;padding:30px 0 0 0;text-align:center;display:block;text-align:center;}
          .leadership{padding:40px 0;}
          .leadership .leadership_cont .block img{height:36px;}
          .leadership .leadership_heading img{width:200px;margin:35px 25px 0;}
          .leadership .leadership_heading{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
          .leadership .leadership_cont{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
          .leadership .leadership_cont .block h3{font-size:25px;line-height:1;margin-bottom:15px;}
          .leadership .leadership_cont .block p{font-size:16px;line-height:24px;}
        }
        @media(max-width: 830px){
            .about_us .about_cont{order:2; flex: 1 1 100%;}
            .about_us .about_person .person img{max-width:400px;height:auto;margin-bottom:25px;}
        }
        @media(max-width:767px){
          .about_us .about_cont{width:100%;  }
          .about_us .about_person{width:300px;display:block;margin:0 auto;}
          .leadership .leadership_cont .block{text-align:center;}
          .leadership .leadership_heading {text-align: center;}
          .leadership .leadership_btn{float:none;margin:35px auto 0 auto;}
        }
        @media(max-width:575px){
            .leadership .leadership_cont .block{flex: 1 1 100%; position: relative;}
            .leadership .leadership_cont .block:after{position:absolute; content:''; left:25px; right:25px; bottom:0; border-bottom: 1px solid #28292a;}
            .leadership .leadership_cont .block:last-of-type:after{display:none;}
            .leadership .leadership_cont .border{display: none;}
        }

    
</style>