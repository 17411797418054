<template>
    <div class="experience_card">
        <div class="experience_head">
            <h5>Experience {{keys+1}}</h5>
            <!-- <div class="action_btn">
                <button class="edit_btn" @click="editPop(podcastData.id)"><i class="fas fa-pencil-alt"></i></button>
                <button class="delete_btn" @click="deletePop(podcastData.id)"><i class="far fa-trash-alt"></i></button>
            </div> -->
        </div>
        <div class="experience_body">
            <label for="">Company Name:</label>
            <p>{{experienceData.company_name}}</p>
            <label for="">Company Logo:</label>
            <p v-if="experienceData.company_logo">
                <img class="logo" v-bind:src="imagepath+experienceData.company_logo" v-bind:alt="experienceData.id" >
                </p>
            <p v-else><img src="@/assets/byron-logo.png" alt="" class="logo"></p>
            <label for="">Job Start Date:</label>
            <p>{{moment(experienceData.start_date).startOf("date").format('DD MMMM YYYY')}}<sup></sup> </p>
            <label for="">Job End date:</label>
            <p>{{moment(experienceData.end_date).startOf("date").format('DD MMMM YYYY')}}<sup></sup> </p>            
            <label for="">Work Details:</label>
            <p>{{experienceData.work_details}}</p>
        </div>
    </div>
</template>

<script>
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
export default{
    name: 'JobCard2',
    props:['experienceData','keys'],
    data() {
        return {
            imagepath:{},
            moment:moment
        }
    },
    async created () {
        this.imagepath=API_IMAGE_PATH+"experiences/"
        //console.log(this.imagepath);
    }
}
</script>
<style scoped>
.experience_list{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.experience_list li{
  width: 33.333%;
  padding: 15px;
}
.experience_list li .experience_card{
  background: #161618;
  border: 1px solid #d9d9d9;
}
.experience_head{
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience_head h5{
  font-size: 20px;
  line-height: 26px;
}
.experience_head .action_btn{
  display: flex;
}
.action_btn .edit_btn{
  border: 1px solid #999;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: transparent;
  color: #fff;
  cursor: pointer
}
.action_btn .delete_btn{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: #c71e1e;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}
.experience_body{
  padding: 15px 25px;
}
.experience_body label{
  font-size: 14px;
  line-height: 17px;
  color: #d3d3d3;
  font-weight: 500;
}
.experience_body p{
  font-size: 13px;
  line-height: 18px;
  color: #999;
  font-weight: 300;
  padding: 2px 0 10px !important;
}
.experience_body p img.logo{
  max-height: 24px;
  width: auto;
}
button.save_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #c71e1e;
  background: #c71e1e;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  cursor:pointer;
}
button.add_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  background: transparent;
  margin-left: 25px;
  cursor:pointer;
  float:right;
}
button.add_btn i{
  margin-right: 5px;
}
.icon img{
    max-width: 105px;
    height: auto;
}
.job_info{
    flex: 0 1 420px;
    padding-left: 20px;
}
.job_date{
    flex: 0 1 150px;
}
.job_info h3{
    font-size: 28px;
    line-height: 35px;
    color: #d9d9d9;
}
.job_info .role{
    display: flex;
    align-items: center;
    margin: 5px -15px 0 -15px;
}
.job_info .role li{
    padding: 5px 15px;
    color: #d9d9d9;
    font-size: 16px;
    line-height: 25px;
    position: relative;
}
.job_info .role li:after{
    position: absolute;
    left: 100%;
    top: 5px;
    content: '.';
    color: #a5a5a5;
}
.job_info .role li:last-child:after{
    display: none;
}
.job_date .date{
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-top:10px;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #c61e1e;
}
.job_date .place{
    font-size: 13px;
    line-height: 17px;
    color: #d1d1d1;
}
@media(max-width:1199px){
    .job_info h3{
        font-size: 20px;
        line-height: 26px;
    }
    .job_info .role li{
        font-size: 14px;
        line-height: 18px;
    }
    .icon img{
        max-width: 60px;
    }
}
@media(max-width:575px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .role{
        justify-content: center;
    }
    .job_date, .icon{
        flex: 0 0 100%;
        text-align: center;
    }
}
</style>