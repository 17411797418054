<template>
  <Navigation2 />
  <section class="bannerFirst">
    <div class="owl-carousel owl-theme bannerslide">
        <carousel :autoplay="5000" :wrap-around="true" :transition="700" :mouseDrag="true">
            <!-- <carousel :wrap-around="true" > -->
            <slide v-for="slide in bannerList" :key="slide">
            <div class="item">
              
                <div class="bannerFirstImage">
                    <img v-bind:src="imagepath+slide.banner_backgroung" style="width:100%">
                    
                </div>
                <div class="bannerFirstText">
                    <div class="container">
                        <div class="row">
                            <img v-bind:src="imagepath+slide.banner_image_name">
                            <p>{{slide.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
            </slide>
            <template #addons>
                <pagination />
            </template>
        </carousel>
    </div>  
  </section>
  <div class="scroll-down" style="cursor:pointer;" @click="scrollToEnd"></div>
  <!-- ----------------------------------- BANNER END1 ---------------------- -->
    <section class="banner" id="style-8">
      <div class="container">
        <div class="row banCenter">
          <div class="bannerText">
            <img class="bannerimgOne" :src="require(`@/assets/images/banner/bannerText1.png`)">
              <div class="bannerTextImage d-flex" style="padding-left: 10px;">
                <!-- <img :src="require(`@/assets/images/banner/bannerText2.png`)" style="width: 175px;height: 60px;"> -->
                <p>The Protector series is a web series about a character called protector zero played by byron Rodgers. The stories are effusion between Byron's real world experiences in the private sector
                  and current events facing professional protectors all over the world</p>
              </div>
              <div class="bannerBtn">
                <a href="https://www.indiegogo.com/" target="_blank">Invest and Get Benefited</a>
              </div>
          </div>
          <div class="bannerIcon">
            <ul>
              <li><a href="https://www.facebook.com/B.c.rodge" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="https://twitter.com/byron_rodgers" target="_blank"><i class="fab fa-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/byron_rodgers" target="_blank"><i class="fab fa-instagram"></i></a></li>
              <li><a href="https://www.linkedin.com/in/byronrodgers" target="_blank"><i class="fab fa-linkedin"></i></a></li>
              <li><a href="https://www.youtube.com/user/TheByronRodgers" target="_blank"><i class="fab fa-youtube"></i></a>
              </li>
            </ul>
          </div>
          <div class="bannerArrow">
            <img :src="require(`@/assets/images/banner/arrow.png`)">
          </div>
        </div>
      </div>
    </section>
  <!-- ----------------------------------- EPISODE ---------------------- -->
    <section class="episod">
      <div class="container">
        <div class="row">
          <div class="heading" style="text-align: center;">
            <!-- <img src="@/assets/images/body2/bodyTwoText.png"> -->
            <h2>WATCH THE EPISODE</h2>
          </div>
          <div class="episodSlider">
            <div class="owl-carousel owl-theme episodslide" style="width: 100%;">

              <Carousel :breakpoints="breakpoints">
                <Slide v-for="(slide,index) in 5" :key="slide">
                  <!-- <div class="carousel__item">{{ slide }}</div> -->
                  <div class="item" v-if="index!=4">
                    <div class="episodBox">
                      <div class="epsodBoxImg" >
                        <!-- <img src="@/assets/images/body2/textthree.png"> -->
                        <h2>EPISODE {{index+1}}</h2>
                      </div>
                      <div class="epsodBoxImage">
                        <!-- <video class="" controls="">
                          <source :src="require(`@/assets/images/video/The Protector_fleur_de_lis_️Chapter One (The Pickup) ( 720 X 1280 ).mp4`)">
                        </video> -->
                        <iframe width="100%" src="https://www.youtube.com/embed/tXS6YzWSD0Q" title="YouTube video player" frameborder="none" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-else>
                    <div class="episodBox">
                      <div class="epsodBoxImg">
                        <h2>EPISODE {{index+1}}</h2>
                      </div>
                      <div class="epsodBoxImage">
                        <!-- <video class="" controls="">
                          <source :src="require(`@/assets/images/video/The Protector_fleur_de_lis_️Chapter One (The Pickup) ( 720 X 1280 ).mp4`)">
                        </video> -->
                        <img src="@/assets/images/body2/image3.jpg">
                        <h3>COMMING SOON</h3>
                      </div>
                    </div>
                  </div>
                </Slide>

                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
          </div>
        </div>
      </div>
      </div>
    </section>

    <span v-for="(content) in contentList" :key="content.id">
      <!-- ----------------------------------- BANNER END2 ---------------------- -->
      <section class="bodyone" v-if=content.id%2>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
              <div class="bodyoneText">
                <img v-bind:src="imageContentpath+content.banner_image_name">
                <div class="bodyoneBtn" >
                   <span style="color:#c0c0c0; display: inline;" v-show="!content.activateReadMore" v-html="strip_html_tags(content.description)" > </span>
                   <hr style="border-bottom: 0.1px solid #353535;margin-top: 20px;" v-show="!content.activateReadMore">
                   <span @click="content.activateReadMore=!content.activateReadMore" v-show="!content.activateReadMore" style="float: right;color:#353535;cursor:pointer;"><i title="read more" style="font-size: 25px;" class="fas fa-sort-down"></i></span>
                   <span style="color:#c0c0c0;c" v-show="content.activateReadMore" v-html="(content.description)"  ></span>
                  <!-- <p>
                    <span>is the story</span> of one man who has answered his call as a protector of the highest order. The characters name is protector zero and he is based off of the real life executive protection agent a.k.a. Bodyguard Byron Rodgers. In the stories and scenarios that are depicted in the series, protector zero overcomes many different adversaries, challenges and dangerous situations all over the world. Some of the content is fiction while much of it is also laced with real life experiences from Byron‘s travels to over 70 different countries around the world conducting protection operations for almost 2 decades.
                  </p>
                  <p>
                    Much of this series is larger than life but it is also laced with real world experiences, tactics and gear used by protectors in reality, We’ve put this body of work together to inspire others to be the type of person who will pick up and defend the weak and innocent amongst us. As a Protector by nature and by trade byron‘s mission is to make the world a safer place by making good people more willing, capable and prepared to defend what they love in these dark times. This protector series is simply one more tool and body of work in media designed to multiply Protectors in the world. Because protectors are the white blood cells in the body of humanity.
                  </p> -->
                  <a href="https://www.indiegogo.com/" target="_blank" style="margin-top:40px">Invest and Get Benefited </a>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="row">
                <div class="bodyoneImage">
                  <img v-bind:src="imageContentpath+content.banner_backgroung">
                  <div class="bodyoneImageText">
                    <img :src="require(`@/assets/images/body1/text2.png`)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ----------------------------------- BODYONE END ---------------------- -->
      <section class="bodytwo" v-else>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="row">
                <div class="bodytwoImage">
                  <img v-bind:src="imageContentpath+content.banner_backgroung">
                  <div class="bodytwoImageText">
                    <img :src="require(`@/assets/images/body1/text2.png`)">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
              <div class="bodytwoText">
                <img v-bind:src="imageContentpath+content.banner_image_name">
                <div class="bodytwoBtn" >
                  <span style="color:#c0c0c0;" v-show="!content.activateReadMore" v-html="strip_html_tags(content.description)" > </span>
                  <hr style="border-bottom: 0.1px solid #353535;margin-top: 20px;" v-show="!content.activateReadMore">
                   <span @click="content.activateReadMore=!content.activateReadMore" v-show="!content.activateReadMore" style="float: right;color:#353535;cursor:pointer;"><i title="read more" style="font-size: 25px;" class="fas fa-sort-down"></i></span>
                   <span style="color:#c0c0c0;c" v-show="content.activateReadMore" v-html="(content.description)"  ></span>
                  <!-- <p><span>will be </span> beautifully produced to give the viewer a feeling that he is watching a movie and is very much with the protector as he executes different missions. The mission of the series is to show the action avatar utilizing real-world equipment and tactics that everyday protectors should consider utilizing in the field. Byron is a known contributor to the tactical space with a legitimate background as a Marine Corps infantryman with Combat deployments and experience. He now is one of if not the most recognized private security/executive protection agents in the world. He has the largest and most listened to executive protection podcast/brand and he also has his own training school.
                  </p>
                  <p>
                    On the civilian side he’s launched the Protector Nation where he hosts live training events with anywhere from 100 to 475 patrons strong which is supported by an online library where good people can go to learn how to become more formidable 24X7. Utilizing Byron as the main character will give this content a very real look and feel because the viewer will not know if this is entertainment or a glimpse into what Byron does at work on a daily basis. We will walk the line between fantasy and reality, we will entertain and equip, we will contribute to making the world a safer place by inspiring good people to become more dangerous. </p> -->
                    <a href="https://www.indiegogo.com/" target="_blank"  style="margin-top:40px">Invest and Get Benefited</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </span>
    <!-- ----------------------------------- BODYTWO END ---------------------- -->
    <section class="viodeBox">
      <div class="container">
        <div class="row">
          <div class="col-12 videoBorder">
            <div class="row">

              <!-- video one -->
              <div class="col-12 mainVideo">
                <iframe width="854" height="480" src="https://www.youtube.com/embed/tXS6YzWSD0Q" title="YouTube video player" frameborder="none" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>

              <!-- video two -->

              <div class="col-12 mainVideoTwo">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="videoTwo">
                     
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="videoTwo">
                      
                    </div>
                  </div>
                </div>
              </div>

              <!-- video three -->

              <div class="col-12 mainVideoThree">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="videoThree">
                     
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="videoThree">
                     
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="videoThree">
                     
                    </div>
                  </div>
                </div>
              </div>


              <!-- video four -->
              
              <div class="col-12 mainVideoFour">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="videoFour">
                     
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="videoFour">
                     
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="videoFour">
                      
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="videoFour">
                     
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ----------------------------------- BODYTHREE END ---------------------- -->
    <section class="support">
      <div class="container">
        <div class="row">
          <div class="col-12 supportHead">
            <img  :src="require(`@/assets/images/body1/text4.png`)">
            <p>We want to invite you to join Forces with us on this mission. This film is free as a gift to ourworld however the production of it does come at a large cost. No matter where you are in life or what capabilities you have you can join the fight. <span>Please consider</span></p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 supportPadding" v-for="(support) in supportList" :key="support.id">
              <div class="supportBox">
                <span style="color:#b7b7b7;" v-html="support.description" ></span>
              </div>
          </div>

          <div class="col-12 invitestmentBox" >
            <!-- <p>Whether you are a professional or civilian Protector, this series is for you and we are honored to join forces with those who are willing to take action in this fight. We were all born and created for such a time as this to set up a standard, safeguard the light and protect order around the world</p> -->
            <!-- <a href="#">God bless and Semper <span>Fi</span></a> -->
          </div>
          <div class="col-12 invitestmentBox">
            <!-- <img :src="require(`@/assets/images/body1/text5.png`)">
            <div class="invitestmentSub">
              <div class="investPayment d-flex">
                <div class="investPaySub d-flex">
                  <span>1</span>
                  <h3>Title Sponsor</h3>
                </div>
                <div class="investPaySu d-flex">
                  <h2>5,000 USD</h2>
                  <a class="paymentnred" href="#">Sponsor now</a>
                </div>
              </div>
              <div class="investPayment d-flex">
                <div class="investPaySub d-flex">
                  <span>2</span>
                  <h3>Tactical Partner</h3>
                </div>
                <div class="investPaySu d-flex">
                  <h2>3,000 USD</h2>
                  <a class="paymentnyellow" href="#">Become a pertner</a>
                </div>
              </div>
            </div> -->
            <div class="col-12 investmentInput" style="text-align:center;">
              <h3>Enter Your Mail Address To Get The Sponsorship Deck</h3>
              <center>
                <div class="piBox d-flex" style="text-align:center;">
                  <div class="inveIn">
                    <input class="investInbox" type="text" placeholder="Enter Your Mail Address">
                  </div>
                  <div class="inveInBtn">
                    <button class="inveInbutt" type="submit">Sponsor Now</button>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ----------------------------------- SUPPORT END ---------------------- -->
    <!-- <div class="container">
    
    <div class="testimonial">
      
      <h3 class="section_header text-center" style="margin: 20px 0 30px 0">Testimonial</h3>
      <TestimonialSlider />
    </div>
  </div> -->
  <Footer />

  <div class="cust-modal" v-show="modalLoginMembership">
    <div class="modal-content">
        <h3>To go to details page please</h3>
        <div class="wk-btn">
            <button><router-link to="/login" >sign in</router-link></button>
            or  
            <button><router-link to="/signup" class="" style="color:black;">sign up</router-link></button>
        </div>
    </div>
    <div class="ftr">
        <button @click="close"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" class="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg></button>
    </div>
  </div>
</template>
<script>

import { Carousel, Slide, Pagination,Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

import axios from "axios";
import { API_BASE_URL } from "../config";
import { API_IMAGE_PATH } from '../config'
//import { useRoute } from 'vue-router'
//import Banner from "../components/Banner.vue";
//import TestimonialSlider from "../components/TestimonialSlider.vue";
import Navigation2 from "../components/Navigation2.vue";
import Footer from "../components/Footer.vue";
//import "../pages/bootstrap.min.css"
import { createLocal } from "the-storages";
const mirror = createLocal();
const storage = mirror._prx;
export default {
  name: "Series2",
  components: {
    Navigation,
    Navigation2,
    Footer,
    Carousel,
    Slide,
    Pagination
  },

  data() {
    return {
      bannerList:{},
      contentList:{},
      supportList:{},
      isBtn: false,
      storageData: mirror,
      storage: storage,
      current: 1,
      jobList: {},
      error: "",
      error2: "",
      is_show: false,
      keyword: "",
      modalLoginMembership: false,
      imagepath:'',
      imageContentpath:'',
      readMoreActivated:false,
      activateReadMore:false,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
          // 700px and up
          700: {
            itemsToShow:3,
            snapAlign: 'center',
          },
          // 1024 and up
          1400: {
            itemsToShow: 5,
            snapAlign: 'start',
          },
        },
    };
  },
  mounted() {
    // const route=useRoute();
    // console.log("PARAMS:: ",route.path);
    this.getBanmerList();
    this.getContentList();
    this.getSupportList();
    this.filter();
    // if(route.path=='/series'){
    //   //document.getElementById("style-8").addEventListener('scroll', this.handleScroll);
    //   window.addEventListener('scroll', this.handleScroll);
    // } else{
    //    window.addEventListener('scroll', this.destroyed);
    // }
  },
  async created() {
     this.imagepath = API_IMAGE_PATH+'banner/';
     this.imageContentpath = API_IMAGE_PATH+'content/';
     
    //const route=useRoute();
    //console.log("PARAMS:: ",route.path);
    //this.imagepath=API_IMAGE_PATH;
    this.userId = mirror.AUTHID;
    //this.userType=mirror.USERTYPE;
    //console.log(this.imagepath);
    //this.DOMReady();
    //const response = await axios.get(API_BASE_URL + 'bannerList');
    //this.bannerList = response.data;
    //console.log("BANNER:::",this.bannerList);
    //console.log("BANNER2:::",response.data);
    //this.doAnimation();
    
    
  },
  
  methods: {
    strip_html_tags(str)
    {
      if ((str===null) || (str===''))
          return false;
      else
        str = this.htmlContentCount = str.toString();
        //return str.replace(/<[^>]*>/g, '');
        str.replace(/<[^>]*>/g, '');
        //console.log("HTML COUNT:: ",str.slice(0,100));
        return str.slice(0,610);
    },
    unescape(string) {

        //create an element with that html
        var e = document.createElement("textarea");
      
        //get the html from the created element
        e.innerHTML = string;
        this.htmlContentCount = e.childNodes.length;
        console.log("HTML COUNT:: ",this.htmlContentCount);
        //return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        return e.childNodes[0].nodeValue.replace(/(<([^>]+)>)/gi, "");
    },
    // activateReadMore(){
    //     this.readMoreActivated = true;
    // },
    getSupportList(){
       axios.get(API_BASE_URL + 'seriesSupportList')
			.then(response => {
        console.log('banners::',response.data)
				this.supportList = response.data;
			});
    },
    getContentList(){
      axios.get(API_BASE_URL + 'seriesContentList')
			.then(response => {
        console.log('banners::',response.data)
				this.contentList = response.data;
			});
    },
    getBanmerList() {			
			axios.get(API_BASE_URL + 'bannerList')
			.then(response => {
        console.log('banners::',response.data)
				this.bannerList = response.data;
			});
		},
    filter() {
      axios
        .get(API_BASE_URL + `feturedjobs`)
        .then((response) => {
          this.jobList = response.data;
          console.log(this.jobList);
        })
        .catch(({ response }) => {
          this.error = response;
        });
      this.is_show = true;
    },
    async clickToGetJobDetails(jobId, slug) {
      //const slug = 1;
      //console.log("JOB:",jobId,"-SLUG:",slug);
      if (this.userId) {
        // this.$router. push('/jobDetails/'+slug);
        if (mirror.MEMBERSHIP_TYPE == "Free") {
          const inputData = {
            userId: mirror.AUTHID,
            jobId: jobId,
          };
          await axios
            .post(API_BASE_URL + `membershipCheckingForJobList`, inputData)
            .then((response) => {
              this.data = response.data;

              console.log("A1: ", this.data);
              if (this.data.result == "success") {
                if (this.data.count > 0 && this.data.count <= 5) {
                  console.log("THIS SECTION 1");
                  this.$router.push("/jobDetails/" + slug);
                } else {
                  console.log("THIS SECTION 2");
                  this.modalMembership = true;
                }
              } else {
                console.log("THIS SECTION 3");
                this.modalMembership = true;
              }
            })
            .catch(({ response }) => {
              this.error = response;
            });
        } else {
          this.$router.push("/jobDetails/" + slug);
        }
      } else {
        this.modalLoginMembership = true;
      }
      //this.$router. push('/jobDetails/'+slug);
    },
    close() {
      this.modalLoginMembership = false;
    },
    videoPlay (){

    }
    ,
    // handleScroll () {
    //   var top  = window.pageYOffset || document.documentElement.scrollTop
    //   if(top>=180 && top<=230){
    //     console.log("RESPONCE1: ",top);
    //     this.scrollToEnd();
    //   } else if(top>560){
    //     console.log("RESPONCE 2: ",top);
    //   }
    // },
    scrollToEnd(){
        //console.log("SCROLL IN: ",window.scrollTo(0, 609.3333129882812));
        //window.scrollTo(0, 609.3333129882812,'smooth');
        window.scrollTo({
          top: 560,
          left: 0,
          behavior: 'smooth'
        });
        
    },
    // destroyed () {
    //   window.removeEventListener('scroll', this.handleScroll);
    // },
    
	}
};
</script>
<style>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
</style>
<style>
.cust-modal .ftr button:hover {
    border: 2px  solid red !important;
}
.modal_header button {
    font-size: 12px !important;
    border: 4px solid #000000ed !important;
}
.modal_header button:hover {
  border: 2px  solid red;
}
/* .sign_submit:hover {
    border: 2px  solid red;
} */
</style>
<style scoped>

    * {
        /* font-family: 'Play', sans-serif !important; */
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        -ms-box-sizing: border-box;
        -o-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
      } 
      
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  li  {
    list-style: none;
  }
  a  {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
    color: #0a58ca;
  }
  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  img,
  svg {
    vertical-align: middle;
  }
  table {
    caption-side: bottom;
    border-collapse: collapse;
  }
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  label {
    display: inline-block;
  }
  select {
    word-wrap: normal;
  }
  button,
  [type=button],
  [type=reset],
  [type=submit] {
    -webkit-appearance: button;
  }
  [type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }
  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
  /* @media (min-width: 576px) {
    .container-sm, .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container-md, .container-sm, .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
      max-width: 960px;
    }
  } */
  /* @media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1320px;
    }
  } */
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .row > * {
    /* flex-shrink: 0;
    width: 100%;
    max-width: 100%; */
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    /* margin-top: var(--bs-gutter-y); */
  }
  .col {
    flex: 1 0 0%;
  }
  .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .g-0,
  .gx-0 {
    --bs-gutter-x: 0;
  }
  .g-0,
  .gy-0 {
    --bs-gutter-y: 0;
  }
  .g-1,
  .gx-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-1,
  .gy-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-2,
  .gx-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-2,
  .gy-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-3,
  .gx-3 {
    --bs-gutter-x: 1rem;
  }
  .g-3,
  .gy-3 {
    --bs-gutter-y: 1rem;
  }
  .g-4,
  .gx-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-4,
  .gy-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-5,
  .gx-5 {
    --bs-gutter-x: 3rem;
  }
  .g-5,
  .gy-5 {
    --bs-gutter-y: 3rem;
  }
  @media (min-width: 576px) {
    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-md-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  @media (min-width: 1400px) {
    .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .fade {
    transition: opacity 0.15s linear;
  }
  .d-flex  {
    display: flex;
  }

  /*banner first start*/

  .bannerFirst  {
    position: relative;
  }
  .bannerFirstImage  {
    position: relative;
    height: 679px;
  }
  .bannerFirstImage img  {
    width: 100%;
    display: block;
    height: 679px;
    object-fit: cover;
    object-position: center;
  }
  .bannerFirstText  {
    position: absolute;
    top: 50%;
    left: 34%;
    transform: translate(-50% , -50%);
        text-align: start;
  }
  .bannerFirstText img  {
    width: 74% !important;
    display: block;
    padding-bottom: 20px;
  }
  .bannerFirstText p {
      font-size: 18px;
      color: #fff;
      font-weight: 300;
      line-height: 22px;
      max-width: 709px;
  }
  .owl-theme .owl-nav.disabled+.owl-dots {
      margin-top: 0px;
      position: absolute;
      bottom: 55px;
      left: 26px;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #ffffff;
  }
  .owl-theme .owl-dots .owl-dot span {
      width: 8px;
      height: 8px;
      margin: 2px 8px;
      background: #ffffff;
      position: relative;
  }
  button.active span:after {
      content: "";
      width: 200%;
      height: 200%;
      top: -4px;
      left: -4px;
      border: 1px solid #fff;
      position: absolute;
      border-radius: 50%;
  }

  /*banner first end*/

        /*banner start*/

  .banner  {
      background-image: url('~@/assets/images/banner/banner1.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      position: relative;
  }
  .banCenter  {
      justify-content: flex-end;
      align-items: center;
  }
  .bannerText  {
      max-width: 651px;
      /* padding: 281px 0 150px 0; */
      padding: 151px 0 150px 0;
  }
  .bannerText img  {
      display: block;
  }
  .bannerimgOne  {
      padding-bottom: 30px;
  }
  .bannerTextImage img  {
      display: block;
      padding-right: 30px;
  }
  .bannerTextImage  {
      padding-bottom: 35px;
  }
  .bannerTextImage p  {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      color: #c0c0c0;
      border-left: 3px solid #c71e1e;
      padding-left: 20px;
      padding-right: 44px;
  }
  .bannerBtn  {
      width: 100%;
      display: flex;
          padding-left: 10px;
      /* justify-content: center; */
  }
  .bannerBtn a  {
    width: 283px;
    height: 55px;
    border-color: rgb(188, 0, 0);
    border-radius: 2px;
    background-color: rgb(199, 30, 30);
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bannerIcon  {
      position: absolute;
      top: 36%;
      right: 0%;
      transform: translate(-50% , -50%);
      z-index: 1;
  }
  .bannerIcon ul li  {
      padding-bottom: 10px;
  }
  .bannerIcon ul li a {
    border: 1px solid #fff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
  }
  .bannerArrow  {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50% , -50%);
      z-index: 1;
  }
  .bannerArrow img  {
      display: block;
  }

  /*banner end*/

  /*bodyone start*/

  .bodyone  {
      background: linear-gradient(0deg, rgba(15,16,17,1) 50%, rgba(14,14,15,1) 100%);
      width: 100%;
      position: relative;
      padding: 100px 0 152px 0;
  }
  .bodyoneText  {
      position: relative;
      margin-left: 264px;
  }
  .bodyoneText img  {
      display: block;
      position: relative;
  }
  .bodyoneBtn {
      max-width: 694px;
      position: relative;
      top: -27px;
      left: 175px;
  }
  .bodyoneBtn p  {
      font-size: 16px;
      font-weight: 300;
      color: #c0c0c0;
      line-height: 25px;
      padding-bottom: 35px;
  }
  .bodyoneBtn a  {
      width: 281px;
      height: 52px;
      font-size: 17px;
      font-weight: 700;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1px;
      background: #c71e1e;
      z-index: 1;
      position: relative;
  }
  .bodyoneImage img {
      width: 100%;
      display: block;
  }
  .bodyoneBtn span  {
      color: #c71e1e;
  }
  .bodyoneImageText  {
      margin-top: -110px;
  }
  .bodyoneImage {
      width: 100%;
  }

  /*bodyone end*/

  /*bodytwo start*/

  .bodytwo  {
      background: linear-gradient(0deg, rgba(15,15,17,1) 0%, rgba(13,14,15,1) 50%);
      width: 100%;
      position: relative;
      padding: 0px 0 50px 0;
  }
  .bodytwoText  {
      position: relative;
      margin-left: 90px;
  }
  .bodytwoText img  {
      display: block;
      position: relative;
  }
  .bodytwoBtn {
      max-width: 694px;
      position: relative;
      top: -27px;
      left: 175px;
  }
  .bodytwoBtn p  {
      font-size: 16px;
      font-weight: 300;
      color: #c0c0c0;
      line-height: 25px;
      padding-bottom: 35px;
  }
  .bodytwoBtn a  {
      width: 281px;
      height: 52px;
      font-size: 17px;
      font-weight: 700;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1px;
      background: #c71e1e;
      z-index: 1;
      position: relative;
  }
  .bodytwoImage img {
      width: 100%;
      display: block;
  }
  .bodytwoBtn span  {
      color: #c71e1e;
  }
  .bodytwoImageText  {
      margin-top: -110px;
  }
  .bodytwoImage {
      width: 100%;
  }

  /*bodytwo end*/

  /*video start*/

  /* .video_box {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-width: 1036px;
      height: 598px;
      margin: 0 auto;
      padding: 15px 15px 0 15px;
      border-radius: 10px;
      border: 1px solid #383838;
      margin-top: -460px;
  }
  .video_img {
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
      width: 100%;
      height: 598px;
      position: relative;
  }
  .video_img img {
    width: 100%;
      height: 563px;
      display: block;
      position: relative;
  }
  .video_box:after {
      content: "";
      width: 1px;
      height: 185px;
      bottom: 0;
      right: 0;
      background: #c71e1e;
      position: absolute;
  }
  .video_box::before {
      content: "";
      width: 185px;
      height: 1px;
      bottom: 0;
      right: 0;
      background: #c71e1e;
      position: absolute;
  }
  .video_wrapper {
    position: absolute;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
      z-index: 1;
  }
  .video {
    width: 100%;
      border-radius: 1px;
  }
  .video_btn_box {
    padding-bottom: 0px;
    position: absolute;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    bottom: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    -o-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
      z-index: 1;
  }
  .video_box.pause .video_btn_box {
    bottom: 45px;
      display: none;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .video_btn_box span {
    font-family: 'Lato', sans-serif;
    white-space: nowrap;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    font-weight: 300;
    position: absolute;
    bottom: -45px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .video_btn_box span:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    right: 100%;
    height: 1px;
    background-color: #fff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  .video_btn:hover.video_btn span:after {
    content: '';
    width: 100%;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .video_btn {
    position: relative;
    padding: 0;
    outline: none;
    background: none;
    border: 2px solid #fff;
      background: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font: normal normal normal 20px/76px FontAwesome;
      box-shadow: 2px 3px 4px 5px rgb(0 0 0 / 20%);
  }
  .video_btn i {
    line-height: 78px;
  }
  .video_btn i.fa-play {
    margin-left: 5px;
  }
  .video_btn:hover {
    background-color: #fff;
    color: #0066cc;
  }
  .video_box.pause .video_img {
    opacity: 0;
    visibility: hidden;
  }
  .video_box.pause .video_wrapper {
    opacity: 1;
    visibility: visible;
      padding-right: 26px;
  }
  button:focus {
      outline: none;
  } */

  .viodeBox  {
    background: linear-gradient(0deg, rgba(15,15,17,1) 0%, rgba(13,14,15,1) 50%);
    width: 100%;
    padding: 50px 0;
  }
  .mainVideo  {
    padding: 15px;
  }
  .videoSubs {
      overflow: hidden;
      width: 100%;
      padding: 15px 0;
  }
  video  {
    width: 100;
    max-width: 100%;
  }
  .videoThree  {
    padding: 15px 0;
  }
  .mainVideo  {
    /* display: none; */
    z-index: 0;
    margin: 0 auto;
    border: 1px solid #383838;
    width: 100%;
    max-width: 884px;
    overflow: hidden;
  }
  .mainVideoTwo  {
    display: none;
    z-index: 0;
    margin: 0 auto;
    border: 1px solid #383838;
    width: 100%;
    max-width: 1036px;
    overflow: hidden;
  }
  .mainVideoThree  {
    display: none;
    z-index: 0;
    margin: 0 auto;
    border: 1px solid #383838;
    width: 100%;
    max-width: 1036px;
    overflow: hidden;
  }
  .mainVideoFour  {
    display: none;
    z-index: 0;
    margin: 0 auto;
    border: 1px solid #383838;
    width: 100%;
    max-width: 1036px;
    overflow: hidden;
  }
  .videoTwo  {
    padding: 15px 0;
  }
  .videoFour  {
    padding: 15px 0;
  }

  /*video end*/

  /*support start*/

  .support  {
      background-image: url('~@/assets/images/body1/background.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
  }
  .supportHead  {
      margin: 0 auto;
      text-align: center;
      padding-top: 80px;
  }
  .supportHead img  {
      display: block;
      margin: 0 auto;
      padding-bottom: 47px;
  }
  .supportHead p  {
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      color: #7a7a7a;
      padding-bottom: 70px;
      max-width: 1240px;
      margin: 0 auto;
  }
  .supportHead span  {
      color: #fff;
  }
  .supportBox  {
      padding: 46px;
      background: rgba(8, 9, 9, 0.7);
      height: 100%;
  }
  .supportPadding  {
      padding: 15px;
  }
  .supportBox p  {
      font-size: 19px;
      font-weight: 300;
      line-height: 25px;
      color: #b7b7b7;
  }
  .supportBox p span  {
      color: #c4940f;
  }
  .supportPara  {
      padding-top: 80px;
      padding-bottom: 90px;
  }
  .supportPara p  {
      font-size: 16px;
      font-size: 300;
      line-height: 25px;
      color: #7a7a7a;
      max-width: 686px;
      padding-bottom: 66px;
  }
  .supportPara a  {
      font-size: 17px;
      font-weight: 300;
      color: #fff;
      border-bottom: 3px solid #c71e1e;
      padding-bottom: 10px;
  }
  .supportPara a span  {
      color: #c71e1e;
  }

  .invitestmentBox img  {
      display: block;
  }
  .invitestmentSub  {
      padding-top: 30px;
      padding-bottom: 30px;
  }
  .investPayment  {
      max-width: 955px;
  }
  .investPaySub,
  .investPaySu  {
      border: 1px solid #4c4c4c;
      padding: 15px;
  }
  .investPaySub  {
      width: 390px;
      align-items: center;
  }
  .investPaySu   {
      width: calc(100% - 390px);
      align-items: center;
      justify-content: space-between;
  }
  .investPaySub span {
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      margin-right: 30px;
  }
  .investPaySub span:after  {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: 1px solid #fff;
      border-radius: 50%;
      position: absolute;
  }
  .investPaySub h3  {
      font-size: 20px;
      color: #fff;
      font-weight: bold;
  }
  .investPaySu h2  {
      font-size: 20px;
      color: #fff;
      font-weight: bold;
  }
  .investPaySu a  {
      width: 200px;
      height: 40px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      letter-spacing: 1px;
      text-transform: uppercase;
  }
  .paymentnred  {
      background: #c71e1e;
  }
  .paymentnyellow  {
      background: #bc8300;
  }
  .investmentInput {
      padding-top: 75px;
      padding-bottom: 130px;
  }
  .investmentInput h3  {
      font-size: 29px;
      color: #fff;
      font-weight: bold;
      padding-bottom: 30px;
  }
  .piBox  {
      width: 100%;
      max-width: 636px;
  }
  .inveIn  {
      width: 431px;
      padding-right: 10px;
  }
  .inveInBtn  {
      width: calc(100% - 431px);
  }
  .investInbox  {
      width: 100%;
      height: 55px;
      background: #292a2a;
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      outline: none;
      padding-left: 30px;
      border: 1px solid #4c4c4c;
  }
  .inveInbutt  {
      width: 205px;
      height: 55px;
      border: 1px solid #fff;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 19px;
      font-weight: 300;
      background: #040404;
      cursor: pointer;
  }

  /*support end*/

  @media only screen and (max-width: 1920px) {
      .banCenter {
          padding-right: 280px;
      }
  }

  @media only screen and (max-width: 1399px) {
      .banCenter {
          padding-right: 50px;
      }
      .bodyoneText {
          margin-left: 0px;
      }
      .bodytwoText {
          margin-left: 0px;
      }
      .bodytwoImageText {
          margin-top: -83px;
      }
      .bannerText img {
          width: 80%;
      }
      .bannerIcon {
          top: 48%;
          right: -2%;
      }
      .bodyoneBtn {
          max-width: 580px;
      }
      .bodytwoBtn {
          max-width: 580px;
      }
  }
              
  @media only screen and (max-width: 990px) {
      .banCenter {
          padding-right: 30px;
      }
      .bodyoneBtn {
          top: 15px;
          left: 0px;
      }
      .bodytwoBtn {
          top: 15px;
          left: 0px;
      }
      /* .video_box {
          border: none;
          margin-top: -460px;
      } */
      .bodyoneImage {
          padding: 15px;
      }
      .bodytwoImage {
          padding: 15px;
      }
      .bodyoneBtn a  {
          margin-bottom: 15px;
      }
      .bodyone {
          padding: 70px 0 30px 0;
      }
      /* .video_box:after {
          display: none;
      }
      .video_box::before {
          display: none;
      } */
      .supportPara {
          padding-top: 30px;
          padding-bottom: 30px;
      }
      .supportPara p {
          padding-bottom: 18px;
      }
      .supportHead img {
          padding-bottom: 20px;
      }
      .supportHead p {
          padding-bottom: 30px;
      }
      .supportBox p {
          font-size: 17px;
          line-height: 23px;
      }
      .adsTextOne img {
          width: 50%;
      }
      .adText img {
          width: 80%;
      }
      .adText img {
          padding-bottom: 15px;
      }










      /* new */
    .bannerFirstText img {
        width: 100% !important;
    }
    .bannerFirstText p {
        font-size: 15px;
    }
    .investPayment {
      width: 100%;
      flex-wrap: wrap;
    }
    .investPaySub,
    .investPaySu {
      width: 100%;
    }
    .investPayment {
      padding-bottom: 30px;
    }
    .piBox {
      width: 100%;
      flex-wrap: wrap;
    }
    .inveInBtn {
      padding-top: 15px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .investmentInput h3 {
      font-size: 20px;
      padding-bottom: 15px;
    }
    .invitestmentSub {
      padding-bottom: 0px;
  } 
  }







            
  @media only screen and (max-width: 599px) {
      .banCenter {
          padding-left: 30px;
      }
      .bannerTextImage {
          padding-bottom: 20px;
          flex-wrap: wrap;
          padding-left: 10px;;
      }
      .bannerTextImage img  {
          width: 46%;
      }
      .bannerText  {
          padding: 70px 15px;
      }
      .bannerTextImage p {
          padding-left: 11px;
          padding-right: 50px;
      }
      .bodytwoImageText {
          margin-top: -55px;
      }
      /* .video_box,
      .video_img,
      .video_img img,
      .video  {
          height: 195px;
      } */
      /* .video_btn {
          width: 50px;
          height: 50px;
          font: normal normal normal 19px/50px FontAwesome;
      } */
      .support {
          z-index: -1;
      }
      /* .video_box  {
          margin-top: -160px;
      } */
      .supportHead img  {
          width: 46%;
      }
      .bodytwoText img {
          width: 60%;
      }
      .bodyoneText img {
          width: 60%;
      }
      /* .bodyoneImageText {
          margin-top: -60px;
      } */
      .bannerBtn a,
      .bodytwoBtn a,
      .bodyoneBtn a {
          width: 230px;
          height: 45px;
          font-size: 15px;
      }
      .banner {
          background-position: unset;
      }
      .adBtn a {
          width: 230px;
          height: 45px;
          font-size: 15px;
      }
      .adText p {
          font-size: 12px;
      }
      .ads {
          background-position: unset;
      }
      .bannerIcon {
          right: -6%;
      }
  }
    </style>


// ___________________________________________________________________________________
<style scoped>
  .scroll-down {
    border: 2px solid #fff;
    border-radius: 20px;
    bottom: 20px;
    height: 50px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 30px;
  }
    .scroll-down::before {
      animation: scrollDownAnimation 2s infinite;
      background-color: #ac892a;
      border-radius: 100%;
      content: '';
      height: 6px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 10px;
      width: 6px;
  }
    @keyframes scrollDownAnimation {
        0% {
          opacity: 0;
          transform: translate(0, 0);
        }

        40%{
          opacity: 1;
        }
        80%{
          opacity: 0;
          transform: translate(0, 20px);
        }
        100%{
          opacity: 0;
        }
      }
    
</style>
<style scoped>
.jobLi {
  background: #1b1b1b;
  margin: 20px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 4px solid #b91e1e;
  /* padding: 40px 0; */
  padding: 25px 25px;
  position: relative;
  z-index: 2;
}
a :hover {
  /* color:black !important; */
}
a {
  color:white;
}
.aaaa :hover{
  color:black !important;
}
.cust-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 200;
    padding: 0 30px;
    width: fit-content;
    transform: translate(-50%, -50%);
}
.cust-modal::after{
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    z-index: -1;
    transform: translate(-50%, -50%);
    height: 100vh;
    background: #000;
    opacity: 79%;
}
.cust-modal .modal-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px 30px;
}
.cust-modal .modal-content h3{
    font-size: 18px;
    font-weight: 300;
    flex-shrink: 0;
}
.cust-modal .modal-content .wk-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.cust-modal .modal-content .wk-btn button{
    color: #fff;
    padding: 7px 23px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 2px;
    transition: all .3s;
    flex-shrink: 0;
    text-transform: uppercase;
}
.cust-modal .modal-content .wk-btn button:first-child{
    border: 1px solid #fff;
    background: none;
}
.cust-modal .modal-content .wk-btn button:first-child:hover{
    background: #AC8F3E;
    border: none;
    color: #000 !important;
    border: 1px solid #AC8F3E;
}
.cust-modal .modal-content .wk-btn button:last-child{
    background: #AC8F3E;
    border: none;
    color: #000;
}
.cust-modal .modal-content{
    background: #212123;
    padding: 18px 30px;
    border-radius: 5px;
    
}
.cust-modal .ftr{
    position: absolute;
    right: 15px;
    top: -15px;
    display: flex;
}
.cust-modal .ftr button{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    border-radius: 50%;
    cursor: pointer;
    border: 5px solid #030303;
}

@media(max-width:599px){
    .cust-modal .modal-content h3{
        flex-shrink: unset;
        text-align: center;
    }
    .cust-modal .modal-content .wk-btn{
        flex-direction: column;
        width: 100%;
    }
    .chat-vr-slider>.item{
        margin-bottom: 12px;
    }
}
</style>
<style scoped>

  i.fab.fa-facebook-f:hover{
        color:#2856b1;
    }
    i.fab.fa-instagram:hover{
        color:#ad1d92;
    }
    i.fab.fa-twitter:hover{
        color:#52c8fb;
    }
    i.fab.fa-linkedin:hover{
        color:#038dcf;
    }
    i.fab.fa-youtube:hover{
        color:red;
    }
.intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0 100px;
  position: relative;
}
.intro_cont h1 {
  font-size: 80px;
  line-height: 85px;
  font-family: "IRON MAN OF WAR 002 NCV";
  font-weight: normal;
  letter-spacing: 50px;
  margin-bottom: 10px;
  background: -webkit-linear-gradient(#ffba00, #50472e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.intro_cont p {
  font-size: 17px;
  line-height: 22px;
  color: #a4a4a4;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.intro_cont p span {
  /* color: #fff; */
  font-weight: 700;
  /* background: -webkit-linear-gradient(#ffba00, #94824f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.social_info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.social_info ul {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.social_info li {
  padding-right: 5px;
}
.social_info li a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: -webkit-linear-gradient(#ffba00, #94824f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.sign_submit {
  padding: 8px 15px;
  background: #c71e1e;
  border-radius: 4px;
  border: none;
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  margin: 4px 4px 0 0;
  border-radius: 0;
  margin-bottom: 20px;
}
.arrow {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 100px;
  border-right: 1px solid #fff;
}
.arrow::before {
  position: absolute;
  content: "";
  left: -6px;
  bottom: 100%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.arrow::after {
  position: absolute;
  content: "";
  left: -4px;
  bottom: 0;
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
}

.training {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 70px 0 0;
  margin-bottom: 25px;
}
.section_img {
  width: 32%;
  height: auto;
}
.content {
  width: 68%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.training .left_area {
  flex: 1 1 520px;
  padding: 0 20px;
  margin: 0 auto;
}
.training .right_area {
  flex: 0 0 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.training .right_area img {
  max-width: 100%;
  height: auto;
}
.content p {
  font-size: 16px;
  line-height: 25px;
  color: #c0c0c0;
}
.read_btn {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-bottom: 5px;
  margin-top: 20px;
  color: #c0c0c0;
  letter-spacing: 0.5px;
  display: inline-block;
}
.read_btn:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 2px;
  right: 0;
  border-bottom: 1px solid #c71e1e;
}

.job_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;
}
.job_section .left_area {
  padding: 15px;
  width: 31%;
}
.job_section .right_area {
  width: 69%;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}
.job_section .joblist_Wpr {
  padding: 30px 60px;
  background: #1b1b1b;
  position: relative;
  border-top: 2px solid #c71e1e;
}
.job_section .joblist_Wpr:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-image: url("~@/assets/figure-bg.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.job_list > li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 4px solid #b91e1e;
  /* padding: 40px 0; */
  padding: 25px 25px;
  position: relative;
  z-index: 2;

  
  background-image: url("~@/assets/figure-bg.png");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 15% 200%;
}
/* .job_list > li:last-child {
  border: 0;
} */
.job_section .read_btn {
  padding-bottom: 15px;
  margin-left: 15px;
}

.testimonial {
  padding: 20px 0 50px;
}

@media (max-width: 1399px) {
  .episod .container .row .heading h2 {
  font-family: 'IRON MAN OF WAR 002 NCV';
    font-size: 75px;
    font-weight: normal;
}
  .intro_cont h1 {
    letter-spacing: 30px;
  }
}
@media (max-width: 1199px) {
  .episod .container .row .heading h2 {
  font-family: 'IRON MAN OF WAR 002 NCV';
    font-size: 75px;
    font-weight: normal;
}
  .job_section .joblist_Wpr {
    padding: 30px;
  }
  .job_list > li {
    padding: 25px 25px;
  }
  .intro {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .social_info {
    margin-top: 30px;
  }
  .social_info .login_btn {
    margin-left: 0;
  }
  .arrow {
    height: 50px;
  }
  .intro_cont h1 {
    font-size: 65px;
    line-height: 70px;
    letter-spacing: 30px;
  }
  .content p {
    font-size: 15px;
    line-height: 21px;
  }
  button.login_btn {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .episod .container .row .heading h2 {
  font-family: 'IRON MAN OF WAR 002 NCV';
    font-size: 75px;
    font-weight: normal;
}
  .training {
    flex-direction: column;
  }
  .section_img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 30px;
  }
  .content {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 50px;
  }
  .training .left_area {
    margin: 0;
  }
  .job_section {
    flex-direction: column;
  }
  .job_section .left_area,
  .job_section .right_area {
    width: 100%;
    text-align: center;
  }
  .job_section .joblist_Wpr {
    padding: 15px 30px;
    text-align: left;
  }
  .testimonial ul li {
    width: 50%;
  }
  .job_list > li {
    padding: 20px 20px;
  }
}
@media (max-width: 767px) {
  .job_section .joblist_Wpr {
    padding: 10px 20px;
  }
  .intro_cont h1 {
    letter-spacing: 15px;
    font-size: 50px;
    line-height: 60px;
  }
  .training .right_area img {
    display: none;
  }
  .training .right_area {
    flex: 0 0 100%;
    align-items: flex-start;
    padding: 20px 0;
  }
  .training .left_area {
    padding: 0;
  }
  .episod .container .row .heading h2 {
    font-family: 'IRON MAN OF WAR 002 NCV';
      font-size: 40px;
      font-weight: normal;
  }
}
@media (max-width: 575px) {
  .intro_cont h1 {
    letter-spacing: 10px;
    font-size: 40px;
    line-height: 50px;
  }
  .social_info {
    flex-direction: column;
    align-items: center;
  }
  .social_info ul {
    margin: 20px 0 0;
  }
  .episod .container .row .heading h2 {
    font-family: 'IRON MAN OF WAR 002 NCV';
      font-size: 40px;
      font-weight: normal;
  }
}
</style>
<style scoped>

/*episod start*/

.episod  {
    width: 100%;
    background: linear-gradient(180deg, rgb(15 16 18) 0%, rgba(22,23,24,1) 50%);
    padding: 132px 0 62px 0;
}
.episod .heading  {
    padding-bottom: 88px;
    width: 100%;
}
.episod .heading img  {
    display: block;
    margin: 0 auto;
}
.episod .episodSlider {
    width: 100%;
}
.episod .episodSlider .item  {
    width: 100%;
    padding: 15px;
}
.episod .episodSlider .item .epsodBoxImg {
    text-align: left;
    padding-bottom: 15px;
}
.episod .episodSlider .item .epsodBoxImg h2 {
  font-family: 'IRON MAN OF WAR 002 NCV';
    font-size: 30px;
    font-weight: normal;
}
/* .episod .container .row .heading h2 {
  font-family: 'IRON MAN OF WAR 002 NCV';
    font-size: 75px;
    font-weight: normal;
} */
.episod .episodSlider .item .epsodBoxImg img  {
  display: block;
}
.episod .episodSlider .episodBox .epsodBoxImage  {
  overflow: hidden;
    height: 243px;
}
.episod .episodSlider .episodBox .epsodBoxImage iframe  {
  height: 221px;
}
.episod .episodSlider .episodBox .epsodBoxImage  {
  border: 10px solid #353535;
}
.episod .episodSlider .episodBox .epsodBoxImage img  {
  object-fit: cover;
  object-position: center;
  position: relative;
}
.episod .episodSlider .episodBox .epsodBoxImage h3  {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50% ,-50%);
      font-family: 'IRON MAN OF WAR 002 NCV';
    font-size: 40px;
    font-weight: normal;
    color: red;
}


/*episod end*/
</style>

