<template>
 <Navigation/>
<div class="signup_main">
<div class="container">
    <div class="sign_up_box">
    <!-- <h4>Candidate</h4> -->
		<img src="@/assets/signup.png">
		<form v-if="submitregisfrom==true">
			<div class="successmessage" >
				<p>Thanks for registering with EP-specialist.</p>
				<p>Your Email Verification link send your inbox</p>
			</div>
		</form>
		
		<form id="signup-form" v-on:submit.prevent="saveRegistration" v-else>
			<div class="form_group">
				<label for="fname">Account Type*</label>
				<div>
					<div class="fifty-div" for="company">
						<input type="radio" v-model="state.role" value="2" name="role" id="company" autocomplete="off">
						<label for="company"> I'm A Company</label>

					</div>
					<div class="fifty-div" for="agent">
						<input type="radio" v-model="state.role" value="3" name="role" id="agent" autocomplete="off"> 
						<label for="agent">I'm an Agent</label>

					</div>
				</div>
				<span v-if="v$.role.$error">
					{{ v$.role.$errors[0].$message }}
				</span>
			</div>
            <div class="form_group">
				<label for="fname">Name*</label>
				<div> 
					<div class="fifty-div">
						<input type="text" placeholder="First Name" v-model="state.firstName" autocomplete="off">
						<span v-if="v$.firstName.$error">
							{{ v$.firstName.$errors[0].$message }}
						</span> 
					</div>
					<div class="fifty-div">
						<input type="text" placeholder="Last Name" v-model="state.lastName" autocomplete="off">
						<span v-if="v$.lastName.$error">
							{{ v$.lastName.$errors[0].$message }}
						</span> 
					</div>
                </div>
			</div>
			<div class="form_group">
				<div class="fifty-div">
					<label for="lname">Email Address *</label>
					<input type="email" placeholder="Email Address" v-model="state.userEmail" autocomplete="off">
					<span v-if="v$.userEmail.$error">
						{{ v$.userEmail.$errors[0].$message }}
					</span>
				</div>
				<div class="fifty-div">
					<label for="lname">Phone Number *</label>
					<input type="text" v-model="state.phone" autocomplete="off" placeholder="Enter Phone Number" @input="acceptNumber">
					<span v-if="v$.phone.$error">
						{{ v$.phone.$errors[0].$message }}
					</span>
				</div>
			</div>
            <div class="form_group">
				<label for="lname">Birthday *</label>
				<div>
					<div class="mnth-box">
						<select v-model="state.year" autocomplete="off" @change="mydatelist()">
							<option value="">Year</option>
							<option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
						</select>
						<span v-if="birthdayerrormessage">
							{{ birthdayerrormessage }}
						</span>
						<span v-if="v$.year.$error">
							{{ v$.year.$errors[0].$message }}
						</span>
					</div>
					<div class="mnth-box">
						<select v-model="state.month" autocomplete="off" @change="mydatelist()">
							<option value="">Month</option> 
							<option value="01">January</option>
							<option value="02">February</option>
							<option value="03">March</option>
							<option value="04">April</option>
							<option value="05">May</option>
							<option value="06">June</option>
							<option value="07">July</option>
							<option value="08">August</option>
							<option value="09">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>

						</select>
						<span v-if="v$.month.$error">
							{{ v$.month.$errors[0].$message }}
						</span> 
					</div>
					<div class="mnth-box">
						<select v-model="state.day" autocomplete="off">
							<option value="">Date</option>
							<option v-for="day in days" v-bind:value="day < 10 ? '0' + day : day" v-bind:key="day">
								{{day>=10 ? day:'0'+day}}
							</option>
						</select>
						<span v-if="v$.day.$error">
							{{ v$.day.$errors[0].$message }}
						</span>
					</div>
					
                </div>
			</div>   
            <div class="form_group">
				<label for="lname">Location  *</label>
				<div>
					<div class="fifty-div">
						<select v-model="state.country" autocomplete="off" class="hlfinput">
							<option value="">Select Country</option>
							<option v-for="country in countryList" :value="country.id" v-bind:key="country.id">{{ country.name }}</option>
						</select>
						<!-- <input type="text" placeholder="Country" v-model="state.country" autocomplete="off"> -->
						<span v-if="v$.country.$error">
							{{ v$.country.$errors[0].$message }}
						</span>
					</div>
					<div class="fifty-div">
						
						<input type="text" placeholder="State" v-model="state.state" autocomplete="off">
						<span v-if="v$.state.$error">
							{{ v$.state.$errors[0].$message }}
						</span>
					</div>
                </div>
			</div>  
            <div class="form_group">
				<div> 
					<div class="fifty-div">
						<label>Password </label>
						<input :type="passwordFieldType" placeholder="" id="userPassword" v-model="state.userPassword" autocomplete="off">
						<span v-if="v$.userPassword.$error">
							{{ v$.userPassword.$errors[0].$message }}
						</span>
					</div>
                    <div class="fifty-div">
						<label> Confirm Password </label>
						<input :type="passwordFieldType" placeholder="" id="cnfPassword" v-model="state.cnfPassword" autocomplete="off">
						<span v-if="v$.cnfPassword.$error">
							{{ v$.cnfPassword.$errors[0].$message }}
						</span>
					</div>
                </div>
			</div>
			<div class="form_group">
			<div class="">
				<p>Use 8 or more characters with a mix of letters, numbers & symbols</p>
				<label class="input"> <input type="checkbox" v-on:click="switchVisibility"> Show password</label>
			</div>
			<div class="">
				<label class="input"> <input type="checkbox" v-on:click="acceptterms" v-model="state.terms" > I agree to the terms and conditions</label>
				<p style="color:red;" v-if="v$.terms2.$error">
					{{ v$.terms2.$errors[0].$message }}
				</p>
			</div>
			</div>
			<!-- <input style="cursor:pointer;" type="submit" value="SUBMIT" class="sign_submit"> -->
			<!-- <button v-on:click="saveRegistration" type="button" class="sign_submit">Submit</button> -->
			<button style="cursor:pointer;" type="submit" value="SUBMIT" class="btnNew" :disabled="buttonclick">
              <span v-if="buttonclick==true" style="color:white">Submitting. . . . </span>
              <span v-else style="color:white"> SUBMIT</span>
             </button>
		</form> 
		<!-- <div v-if="errormessage!=''" style="color:red;" class="errormessage">{{errormessage}}</div> -->
	</div>
</div>
</div>
<Footer/>
</template>
<script>
import { reactive, computed } from "vue"
import moment from "moment";
import useValidate from "@vuelidate/core"
import { required, email,minLength,maxLength } from "@vuelidate/validators"
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import axios from 'axios'
import { createLocal } from 'the-storages'
import { API_MAIL_URL } from '../config'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx
//const passwordField = document.querySelector('#password')
//import Datepicker from 'vue3-datepicker'
//import { ref } from 'vue'
var submitregisfrom = false;

export default {
	name: 'Signup',
	components: {
	Navigation,
	Footer,
	},
	setup(){
		const state = reactive({
            firstName:'',
			lastName:'',
			userEmail:'',
			month:'',
			day:'',
			year:'',
			country:'',
			role:'',
			state:'',
			userPassword:'',
			cnfPassword: '',
			terms:'',
			terms2:'',
		})

		const rules = computed(() => {
			return {
                firstName:{ required },
				lastName:{ required },
				userEmail:{ required, email },
				month:{ required },
				day:{ required, minLength: minLength(1),maxLength: maxLength(2)},
				year:{ required ,minLength: minLength(2),maxLength: maxLength(4)},
				country:{ required },
				phone:{required},
				role:{ required },
				terms2:{ required },
				state:{ required },
				userPassword:{ required ,minLength: minLength(8)},
				cnfPassword:{ required,minLength: minLength(8) }
				// cnfPassword:{ required, sameAsPassword: sameAs("userPassword") },
			}
		})
		const v$ = useValidate(rules, state)

		return {
			state,
			v$
		}
	},
	data(){
        return{
			form:{
				firstName:'',
				lastName:'',
				userEmail:'',
				month:'',
				day:'',
				year:'',
				country:'',
				role:'',
				state:'',
				userPassword:'',
				cnfPassword:'',
			},
			errors: [],
			errormessage: '',
			passwordFieldType: 'password',
			submitregisfrom: submitregisfrom,
            storageData: mirror,
			storage: storage,
			countryList: {},
			moment: moment,
			terms2:'',
			buttonclick:false,
			birthdayerrormessage:'',
			birthdayDate:'',
			currentDate:'',
			message:'',
			//example2: ref(new Date()),
            //example2_from:ref(new Date()),
			//example2_to: ref(new Date())
		}
		},
        computed: {
            years() {
                const year = new Date().getFullYear()
                return Array.from({ length: year - 1900 }, (value, index) => year - index);
			},
			days() {
				//alert(this.state.month)
				const year =this.state.year;
				const month=parseInt(this.state.month);
				var daysno = new Date(year, month, 0).getDate();
				
				//console.log('datalis:',Array.from({ length:daysno }, (value, index) => 1+ index))
                return Array.from({ length:daysno }, (value, index) => 1+ index)
            }
        },
	async created(){
		//console.log("LIST",API_BASE_URL + 'country');
		this.state.year=new Date().getFullYear();
        this.state.day=new Date().getDate();
        this.state.month=new Date().getMonth()+1;
		let countryData = await axios.get(API_BASE_URL + 'country');
        this.countryList = countryData.data;
        //console.log("LIST",countryData.data);
		//console.log(API_BASE_URL + 'country');
	},
	methods:{
		async mydatelist(){ 
				this.state.day='01';
		},
		acceptNumber() {
			var x = this.state.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			this.state.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		},
		timestampConversion(passDate) {
				var myDate = passDate;
				myDate = myDate.split("-");
				var newDate = new Date( myDate[2], myDate[1] - 1, myDate[0]);
				console.log(newDate.getTime());
				return newDate.getTime();
		},
		async saveRegistration() {
			this.buttonclick = true;
			this.v$.$validate(this.v$.$error);
			//console.log("LOG:",);
			this.currentDate = moment(new Date()).format("DD-MM-YYYY");
			this.birthdayDate = this.state.day+"-"+this.state.month+"-"+this.state.year;

			//console.log("Current: ",this.timestampConversion(this.currentDate));
			//console.log("Birthday: ",this.timestampConversion(this.birthdayDate));
			if (!this.v$.$error) {
				
				if(this.timestampConversion(this.currentDate)>this.timestampConversion(this.birthdayDate)){
					//console.log("IF");
					if (this.state.role && this.state.cnfPassword && this.state.userPassword && this.state.firstName && this.state.lastName && this.state.userEmail && this.state.phone && this.state.day && this.state.month && this.state.year && this.state.state && this.state.country){
						if(this.state.cnfPassword==this.state.userPassword){
							axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
								axios.post(BASE_URL +'api/registration', {
									firstName: this.state.firstName,
									lastName:this.state.lastName,
									email:this.state.userEmail,
									month:this.state.month,
									phone:this.state.phone,
									day:this.state.day,
									role:this.state.role,
									year:this.state.year,
									country:this.state.country,
									state:this.state.state,
									password: this.state.userPassword,
									cnfPassword: this.state.cnfPassword
								})
								.then(response => {
									console.log('submitdata',response);
									if (response.data.success==true) {
										axios.post(API_MAIL_URL +'activationlink', {
											activationlink: response.data.activationlink,
											email: this.state.userEmail,
											name: this.state.lastName,
											password: this.state.userPassword,
										});
										this.submitregisfrom=true
										//this.$router.push('/login');
										this.buttonclick = false;
										this.message = "Thanks for registering with EP-specialist. Your Email Verification link send your inbox";
										this.showToast("Success",this.message,"success"); 
									} else {
										this.errormessage = response.data.message;
										this.buttonclick = false;
										//this.message = response.data.messase;
										//console.log('dataFailed:',this.errormessage)
										this.showToast("Failed",this.errormessage,"danger"); 
									}
								})
								.catch(error => {
									console.error(error);
								});
								console.log(responses);
							});
							//e.target.reset();
							//this.message = '';
						} else {
							this.errormessage = 'Password and Confirm Password must be same';
							this.buttonclick = false;
							this.showToast("Warning!",this.errormessage,"warning"); 
						}
					} else {
						this.errormessage = 'All Fields are Mandatory';
						this.buttonclick = false;
						this.showToast("Warning!",this.errormessage,"warning"); 
					}
					this.birthdayerrormessage = '';
				} else {
					//console.log("ELSE");
					this.birthdayerrormessage = 'Birthday must be before current date';
					this.buttonclick = false;
					this.showToast("Warning!",this.birthdayerrormessage,"warning"); 
				}
			} else {
				this.errormessage = 'All Fields are Mandatory';
				this.buttonclick = false;
				this.showToast("Warning!",this.errormessage,"warning"); 
			}
			setTimeout(() => {
				this.errormessage = '';
				//console.log(this.errormessage);
			}, 5000);
		},
		switchVisibility() {
			this.passwordFieldType = this.passwordFieldType==="password"?"text":"password";
		},
		acceptterms() {
			//this.state.terms = this.state.terms=="1"?"":"1"
				
			//alert(this.state.terms2)
			if(this.state.terms)
				this.state.terms2='';
			else
				this.state.terms2=1
		},
		showToast(title,description,type){
			createToast({
				title: title,
				description: description
			},
			{
				type: type,
				position: 'bottom-right',
				// hideProgressBar: 'true',
				// toastBackgroundColor: color,
			})
		},
	},
	beforeRouteEnter(to, from, next) {
            if (storage.get('TOKEN')) {
                next('/dashboard');
            }
            else {
                next();
            }
        }
}

</script>
<style scoped>
.btnNew {
	background: #c71e1e;
    font-size: 19px;
    line-height: 53px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    width: 100%;
    text-align: center;
    border: none;
    margin: 20px 0 0;
}
span { color:red;}
.signup_main{padding:160px 0 80px;background: url('~@/assets/log_bg.jpg') no-repeat #111112;background-size:cover;}
.sign_up_box h4 {margin: 0 0 10px;font-size: 22px;color: #fff;text-transform: uppercase;}
.sign_up_box {min-height: 250px;max-width: 750px;height: auto;background-color: #212122;box-shadow: 0 3px 5px #000;margin: auto;float: none;padding: 20px 40px 30px;background-image: url(~@/assets/user.png);background-position: bottom right;background-repeat: no-repeat;border-top: solid 2px #b71d1d;}
.sign_up_box input[type="text"],input[type="number"], .sign_up_box input[type="email"], .sign_up_box input[type="tel"], .sign_up_box input[type="password"],
.sign_up_box input[type="url"] {width: 100%;height: 50px;border: solid 1px #3d3d3d;background:#1f1f1f;box-sizing: border-box;color: #737373;font-size: 16px;
padding: 10px;}
.sign_up_box select{width: 100%;height: 50px;border: solid 1px #3d3d3d;background:#1f1f1f;box-sizing: border-box;color: #737373;font-size: 16px;
padding: 10px;}
.fifty-div{ width:49.50%;float: left;margin: 0 0 0 1%;}
.fifty-div:nth-child(2n+1){ margin-left:0;}
.mnth-box{ width:32.666%; float:left; margin-left:1%;}
.mnth-box:nth-child(3n+1){ margin-left:0;}
.sign_up_box label{ font-size:16px; letter-spacing:1px; color:#fff; padding:10px 0; display:block;width:100%;float:left;}
.sign_up_box input[type="submit"], .train_in_box input[type="button"]{ background:#c71e1e; font-size:19px; line-height:53px; letter-spacing:2px; text-transform:uppercase; color:#fff; width:100%; text-align:center; border:none; margin:20px 0 0;}
.sign_up_box p{ padding: 12px 0 0;color: #dbdbdb;font-size: 14px;float: left;width: 100%;}
.sign_up_box p + label{float:left;width:100%;color:#dbdbdb;font-size: 14px;}
@media only screen and (min-width:200px) and (max-width:767px) {
.signup_main{padding:80px 0;}
.wrapper {width:98%;}
.sign_up_box{padding:20px 15px;}
.signin_bg {padding: 40px 20px;}
.sign_in_box {width: auto;max-width: 100%;padding: 15px;}
.fifty-div{ width:100%; float:none; margin:10px 0 0;}
.mnth-box{width:100%; float:none; margin:10px 0 0;}
}
</style>