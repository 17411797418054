<template>
    <div class="sign_in_box">
		<h4>Keep In Touch With </h4> <img src="@/assets/byron-logo.png">
		<!-- <div v-if="submitted.successmessage">{{submitted.successmessage}}</div> -->
		<div v-if="submitted.successmessage" class="success">{{submitted.successmessage}}</div>
        <div v-if="submitted.errormessage" class="error">{{submitted.errormessage}}</div>
		<form id="get-tuch-form" v-on:submit.prevent="submitForm">
			<div class="form_group">
				<label for="fname">Name</label>
				<input type="text" placeholder="Name" v-model="state.name" />
				<span v-if="v$.name.$error">
					{{ v$.name.$errors[0].$message }}
				</span> 
			</div>
			<div class="form_group">
				<label for="lname">Email</label>
				<input type="text" placeholder="Email" v-model="state.email" />
				<span v-if="v$.email.$error">
				{{ v$.email.$errors[0].$message }}
				</span> 
			</div>
			<!-- <input type="submit" value="Submit" class="sign_submit"> -->
			<button type="submit" class="login_btn" :disabled="buttonclick" style="width: 100%;margin-left:0px;cursor:pointer;background: #b91e1e;border: #b91e1e;">
				<span v-if="buttonclick==true" style="color:white">Submitting. . .</span>
				<span v-else style="color:white"> Submit</span>
			</button>
			<div class="create_forgot">
				<p>We respect your email privacy</p>
			</div>
		</form>		
	</div>
	<div class="gap-top follow">
		<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbyroninc%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=3796322123783075" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
	</div>
</template>


<script>
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import { API_BASE_URL } from '../config'
import axios from 'axios'

export default {
  name : "Validform",
  setup(){
    const state = reactive({
      email:'',
      name: '',
    })

    const rules = computed(() => {
      return {
        email: { required, email },
        name: { required},
      }
    })
    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
		},
        data() {
			return {
                form: {
					name: '',
					email: '',
					message:''

				},
				submitted: {
				successmessage: '',
				errormessage:'',
				buttonclick:false,
				}
            }
        },
       mounted() {
            
        },
		methods:{
			async submitForm(e) {
			this.buttonclick = true;
			this.v$.$validate()
			if (!this.v$.$error) {
				if(this.state.name && this.state.email){
					const response=await axios.post(API_BASE_URL + 'emailsubscriptions', {
						name: this.state.name,
						email: this.state.email
						}
					);
					if(response.data.result=='success'){
						this.submitted.successmessage = response.data.messase;
						//this.state.name='';
						//this.state.email='';
						this.state = reactive({
						email:'',
						name: '',
						})
						e.target.reset();
						this.buttonclick = false;
					} else {
						this.submitted.errormessage = response.data.messase;
						this.buttonclick = false;
					}
					setTimeout(() => {
						this.submitted.successmessage = '';
						this.submitted.errormessage = '';
						//console.log("HI");
					}, 5000);
				} else {
					this.submitted.errormessage = 'All fields are required';
					this.buttonclick = false;
				}
			} else {
				this.buttonclick = false;
			}
		}
		}
}
</script>


<style scoped>
span {
        color: #b91e1e;
    }
.gap-top.follow{
	display: flex;
	justify-content: center;
}
.blog_details .sign_in_box {
	width: 400px;
	height: auto;
	background-color: #212122;
	box-shadow: 0 3px 5px #000;
	margin: auto;
	float: none;
	padding: 20px 40px 30px;
	background-image: url(~@/assets/user.png);
	background-position: bottom right;
	background-repeat: no-repeat;
	border-top: solid 2px #b71d1d;
}

.blog_details .sign_in_box h4 {
	margin: 0 0 20px;
	font-size: 22px;
	color: #fff;
	text-transform: uppercase;
}
.blog_details .sign_in_box img{
	max-width:100%;
	height: auto;
}

.blog_details .sign_in_box form {
	margin-top: 30px;
	clear: both;
	width: 100%;
}

.blog_details .sign_in_box form .form_group {
	width: 100%;
	margin-bottom: 20px;
}

.blog_details .sign_in_box form .form_group label {
	width: 100%;
	font-size: 16px;
	color: #fff;
	margin-bottom: 5px;
	display: inline-block;
	text-transform: capitalize;
}

.blog_details .sign_in_box form .form_group input {
	width: 100%;
	height: 50px;
	border: solid 1px #3d3d3d;
	background: transparent;
	box-sizing: border-box;
	color: #fff;
	font-size: 16px;
	padding: 10px;
}

.blog_details .sign_in_box form .sign_submit {
	width: 100%;
	height: 55px;
	background: #c71e1e;
	border-radius: 4px;
	border: none;
	font-size: 19px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
}
.blog_details .create_forgot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
}

.blog_details{
	margin:60px 0;
	float:left;
	width:100%;
}
.blog_details .left-part{ 
	float:left; 
	width:72%;
}
.blog_details .right-part-sec { 
	width:26%; 
	float:right;
}
.blog_details .blog-img{
	font-size:0;
}
.blog_details .heading-left{
	width:100%;
	float:left;
}
.blog_details .blog-img img {
width:100%
}

.blog_details .details-box {
background:#1a1a1a;
padding:41px 25px;
border:solid 1px #242424;
float:left
}

.blog_details .details-box h3 {
font-size:50px;
line-height:55px;
color:#fff;
width:50%;
float:left
}

.blog_details .details-box .heading-left h5 {
width:50%;
float:right;
text-align:right;
padding-top:16px
}

.blog_details .details-box .heading-right {
width:100%;
float:left;
margin:10px 0
}

.blog_details .details-box .heading-right h5 {
width:100%;
float:none;
text-align:left;
padding-top:6px
}

.blog_details .details-box p {
color:#939393;
font-size:15px;
padding:12px 0 0
}

.blog_details .blog_content_top span.red-text {
color:#c61e1e
}

.blog_details .details-box h5 {
font-size:15px;
color:#acacac
}

.blog_details .bottom-gap-top {
padding-top:30px
}

.blog_details .right-prt ul li {
float:left;
background:url(~@/assets/icon-arrow.png) no-repeat 0 6px;
padding-left:16px;
padding-right:15px;
color:#fff;
font-size:13px
}

.blog_details .right-part-sec .sign_in_box {
width:100%
}

.blog_details .create_forgot p {
font-size:15px;
color:#fff
}

.blog_details .gap-top {
padding-top:25px
}

.blog_details .follow {
color:#fff
}

.blog_details .follow img {
border:solid 1px #242424;
margin:20px 0 0
}

.blog_details .left-part .left-part {
float:left;
width:40%
}

.blog_details .right-part-sec .right-part {
width:50%;
float:right
}

.blog_pro {
	width: 100%;
	float: left;
	padding: 50px 0;
	background-image: url('~@/assets/blog-pro-bg.jpg');
	background-repeat: no-repeat !important;
	background-position: left top !important;
	background-size: cover !important;	
}

.blo_pro_right {
	width: 40%;
	float: right;
	padding-right: 30px;
	box-sizing: border-box;
}

.blo_pro_right img{
	max-width:600px;
	width:100%;
}
.blo_pro_right p {
	font-size: 15px;
	color: #fff;
	text-transform: uppercase;
	margin: 10px 0;
	line-height: 24px;
}
.read_more {
	color: #939393;
	border: solid 1px #939393;
	padding: 7px 25px;
	text-decoration: none;
	display: inline-block;
	margin-top: 20px;
	font-size: 16px;
}

.read_more:hover {
	background: #cfcfcf;
	color: #000;
}


@media only screen and (min-width:768px) and (max-width:1360px) {
.blog_pro {
    background-position: -160px top !important;
}
.banner {
    min-height: 290px;
}
.inr_text.blog {
    padding: 110px 0 70px;
    top: 160px;
    bottom: 0;
}
.all_blog{
	margin-top:20px;
}
.inr_text.blog img{
    max-width: 260px;
    float: none;
    display: block;
    margin: 0 auto;
}
.inr_text.blog h3 {
    font-size: 25px;
    letter-spacing: 6px;
}
.blog_details .left-part{
    width: 62%;
}
.blog_details .right-part-sec{
    width: 36%;
}
}
@media only screen and (min-width:240px) and (max-width:1024px) {
.blog_details .left-part{
    width: 100%;
}
.blog_details .right-part-sec{
    width: 100%;
}
}
@media only screen and (min-width:240px) and (max-width:767px) {

.blog_pro {
	padding: 30px 0;
}
.blo_pro_right {
	width: 100%;
	padding: 20px;
}
.banner {
    min-height: 290px;
}
.inr_text.blog {
    padding: 110px 0 70px;
    top: 160px;
    bottom: 0;
}
.inr_text.blog img{
    max-width: 260px;
    float: none;
    display: block;
    margin: 0 auto;
}
.inr_text.blog h3 {
    font-size: 25px;
    letter-spacing: 6px;
}
}
</style>
