<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part">
        <h3>Edit Profile</h3>
<div class="tabbtnn_sec edit_prop">
        <ul>
        <li><button @click="activeTab = 'EpA'" :class="{active: activeTab === 'EpA'}">General Information</button></li>
        <li><button @click="activeTab = 'EpB'" :class="{active: activeTab === 'EpB'}">Video</button></li>
        <li><button @click="activeTab = 'EpC'" :class="{active: activeTab === 'EpC'}">Certificate</button></li>
        <li><button @click="activeTab = 'EpD'" :class="{active: activeTab === 'EpD'}">Experience</button></li>
        <li><button @click="activeTab = 'EpE'" :class="{active: activeTab === 'EpE'}">Gallery</button></li>
		<!-- <li v-if="userType == 3 && membershipType=='Premium'" ><button @click="activeTab = 'EpF'" :class="{active: activeTab === 'EpF'}">Training Level</button></li> -->
		<li v-if="userType == 3" ><button @click="activeTab = 'EpF'" :class="{active: activeTab === 'EpF'}">Training Level</button></li>
        </ul>
</div>
        <keep-alive>
	<component :is="activeTab"></component>
	</keep-alive>

</div>
</div>
</div>
</template>


<script>
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import EpA from '@/components/EpA.vue'
import EpB from '@/components/EpB.vue'
import EpC from '@/components/EpC.vue'
import EpD from '@/components/EpD.vue'
import EpE from '@/components/EpE.vue'
import EpF from '@/components/EpF.vue'
import { createLocal } from 'the-storages'
const mirror = createLocal()
const storage = mirror._prx
	export default {
	name: 'DashboardEditprofile',
	components:{
			Dashboardheader,
			Dashboardsidebar,
			EpA,
			EpB,
			EpC,
			EpD,
			EpE,
			EpF
		},
	data(){
		return{
			activeTab: 'EpA',
			storageData: mirror,
			storage: storage,
			membershipType:''
			}
		},
		created(){
			this.membershipType = mirror.MEMBERSHIP_TYPE      
			this.userType = mirror.USERTYPE;
		},
		beforeRouteEnter(to, from, next) {
			if (storage.get('TOKEN')) {
				next();
			}
			else{
				next('/login');
			}
		}  
	}
</script>

