<template>
 <Navigation/>
 <div class="contact_us" id="contact_info">
     <div class="container">
         <div class="contact_us_form">
             <img src="@/assets/contact_us.png" alt="" draggable="false">
             <!-- <div v-if="successmessage!=''" class="success">{{successmessage}}</div>
             <div v-if="errormessage!=''" class="error">{{errormessage}}</div> -->
             <form id="get-tuch-form" v-on:submit.prevent="submitForm" ref="form" name="form">
                 <div class="form-group">
                     <label>Name<span>*</span></label>
                     <div class="d-flex">
                         <div class="group_col">
                             <input type="text" v-model="state.firstName" placeholder="First Name">
                             <span v-if="v$.firstName.$error">Please Enter First Name.</span>
                         </div>
                         <div class="group_col">
                             <input type="text" v-model="state.lastName" placeholder="Last Name">
                             <span v-if="v$.lastName.$error">Please Enter Last Name.</span>
                         </div>
                     </div>
                 </div>
                 <div class="form-group">
                    <label>Email Address<span>*</span></label>
                    <input type="email" v-model="state.email" placeholder="Please Enter your Email Address" >
                    <span v-if="v$.email.$error">Please Enter Valid Email Address.</span>
                 </div>
                 <div class="form-group">
                    <label>Phone Number<span>*</span></label>
                    <input type="tel" v-model="state.phone" placeholder="Please Enter your phone no" @input="acceptNumber">
                    <span v-if="v$.phone.$error">Please Enter Valid Phone no.</span>
                 </div>
                 <div class="form-group">
                    <label>Message<span>*</span></label>
                    <textarea v-model="state.message" placeholder="Please Enter Your message"></textarea>
                    <span v-if="v$.message.$error">Please Enter Your message.</span>
                 </div>
                 <button type="submit" class="login_btn" :disabled="buttonclick" style="width: 100%;margin-left:0px;cursor:pointer;background: #b91e1e;border: #b91e1e;">
                    <span v-if="buttonclick==true" style="color:white">Sending. . .</span>
                    <span v-else style="color:white"> Send us a message</span>
                </button>
                 <!-- <input type="submit" value="Send us a message" name="submit" class="btn_send"> -->
             </form>
         </div>
     </div>
   </div>

<Footer/>
</template>
<script>
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
// import { required, email, minLength, maxLength } from "@vuelidate/validators"
import { required, email ,minLength,maxLength} from "@vuelidate/validators"
import { API_BASE_URL } from '../config'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

//import { API_MAIL_URL } from '../config'
import axios from 'axios'
export default{
    name: 'Contact',
        components:{
        Navigation,
        Footer
        },
        setup() {
            const state = reactive({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message:'',
            })

            const rules = computed(() => {
                return {
                    firstName: { required },
                    lastName: { required },
                    email: { required, email},
                    // phone: { required, minLength: minLength(10), maxLength: maxLength(12) },
                    phone: { required , minLength: minLength(14), maxLength: maxLength(14)},
                    message: { required },
                }
            })
            const v$ = useValidate(rules, state)

            return {
                state,
                v$
            }
        },
        data() {
            return {
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: ''
                },
                errormessage: '',
				successmessage: '',
                buttonclick:false,
            }
        },
        methods: {
            async submitForm() {
                this.buttonclick = true;
                this.v$.$validate()
                if (!this.v$.$error) {

                    const response = await axios.post(API_BASE_URL + 'contactus', 						{
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        message: this.state.message,
                        phone: this.state.phone,
                    });
                    //console.log("DATA- ",response.data);
                    const response2 = await axios.post(API_BASE_URL + 'contactmail', 						{
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        message: this.state.message,
                        phone: this.state.phone,
					});
                    console.log("DATA ",response2.data);
                    if (response.data.status == 'success') {
                        
                        document.getElementById("get-tuch-form").reset();
                        //console.log("IF");
                        //setTimeout(() => { this.$v.$reset() }, 0)
                        //event.reset();
                        this.state= {};
                        this.errormessage = '';
                        this.successmessage = response.data.messase;
                        this.buttonclick = false;
                        this.showToast("Email Sent",this.successmessage,"success"); 
                    } else {
                        //console.log("Else");
                        //console.log("ERROR2:",this.v$.$error)
                        this.successmessage = '';
                        this.errormessage = response.data.messase;
                        this.buttonclick = false;
                        this.showToast("Failed",this.errormessage,"danger"); 
                    }
					
					var element = document.getElementById("contact_info");
					element.scrollIntoView();
                    
                } else {
                    this.errormessage = '* Fields are mandatory.';
                    this.buttonclick = false;
                    this.showToast("Warning!",this.errormessage,"warning"); 
                }
                
            },
            acceptNumber() {
                var x = this.state.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.state.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
			},
            showToast(title,description,type){
                createToast({
                    title: title,
                    description: description
                },
                {
                    type: type,
                    position: 'bottom-right',
                    // hideProgressBar: 'true',
                    // toastBackgroundColor: color,
                })
            },
        }
}
</script>
<style scoped>

    .contact_us{padding:160px 0 100px;background: url('~@/assets/log_bg.jpg') no-repeat #111112;background-size:cover;}
      .contact_us .contact_us_form{
          max-width:710px;border-top:1px solid #b91e1e;padding:40px 50px 50px 50px;margin:0 auto;box-shadow:0px 0px 36px 0px rgba(3,3,3,0.75);background:#212122;
            /* background-image: url(/img/user.94b87d6d.png); */
            background-image: url('~@/assets/user.png');
            background-position: bottom right;
            background-repeat: no-repeat;
          }
      .contact_us .contact_us_form img{max-width:100%;height:auto;vertical-align:middle;border-style:none;margin-bottom:40px;}
      .contact_us .contact_us_form .form-group{padding-bottom:20px;}
      .contact_us .contact_us_form .form-group input[type=text],input[type=tel],input[type=email],input[type=tel],input[type=number],textarea{position:relative;font-family:'Play',sans-serif;padding:15px;font-size:15px;font-weight:400;background-color:#1f1f1f;border:1px solid #3d3d3d;color:#8f8f8f;display:block;width:100%;-webkit-appearance:none;-moz-appearance:none;appearance:none;margin-top:8px;}
      .contact_us .contact_us_form .form-group input[type=text],input[type=email],input[type=tel],input[type=number],textarea:focus{background:#1f1f1f;border:1px solid #3d3d3d;outline:0;box-shadow:none;}
      .contact_us .contact_us_form .form-group textarea{height:110px;resize:none;}
      .contact_us .contact_us_form span{color:#dd0505;}
      .contact_us .contact_us_form .form-group .d-flex{display:flex;flex-wrap:wrap;align-items:center;margin: 0 -15px;}
      .contact_us .contact_us_form .form-group .group_col{width:50%;padding:0 15px;}
      .contact_us .contact_us_form .form-group label{font-family:'Play',sans-serif;font-size:16px;line-height:20px;font-weight:400;color:#d0d0d0;display:block;}
      .contact_us .contact_us_form .btn_send{font-family:'Play',sans-serif;font-size:18px;line-height:1;font-weight:700;background-color:#c71e1e;color:#fff;padding:20px 0;border-radius:2px;text-transform:uppercase;cursor:pointer;border:none;display:block;width:100%;letter-spacing:1px;}
      @media(max-width:1024px){
        .contact_us{padding:100px 0 50px 0;}
        .contact_us .contact_us_form{width:90%;}
        .contact_us .contact_us_form{width:100%;padding:40px 40px;}
        .contact_us .contact_us_form img{width:150px;}
        .contact_us .contact_us_form .form-group label{bottom:10px;}
        .contact_us .contact_us_form .form-group input[type=text],input[type=tel],input[type=email],input[type=tel],input[type=number],textarea{font-size:14px;}
        .contact_us .contact_us_form .form-group textarea{height:100px;}
        .contact_us .contact_us_form .form-group label{font-size:14px;}
        .contact_us .contact_us_form .btn_send{font-size:15px;}
      }
    @media(max-width:767px){
         .contact_us{width:100%;padding:80px 20px 20px;}
    }
    @media(max-width:575px){
        .contact_us .contact_us_form .form-group .group_col{width:100%;}
    }
    
</style>