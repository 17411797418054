<template>
	<div class="area_header">
		<div class="total_comments"><span style="color:#ffc83d">{{totalcomments}}</span>  Comments</div>
		<div class="sortBy" v-if="totalcomments > 0">
			<i class="fas fa-align-left"></i>				   
			<select name="filter" id="filter" @change="getFilterDetails($event,details.id)">
				<option style="background:black" value="DESC">Latest</option>
				<option style="background:black" value="ASC">Older</option>
			</select>
		</div>
	</div>
	<div v-if="storage.get('TOKEN')">
		<div class="add_comment" v-if="addpost">
			<div class="user_img">
				<!-- <img src="@/assets/avatar.png" alt=""> -->
				<img v-if="storage.get('PROFILEIMAGE')" v-bind:src="imagepath+storage.PROFILEIMAGE" v-bind:alt="storage.NAME" />
                <img v-else src="@/assets/avatar.png" v-bind:alt="storage.NAME" />
			</div>
			<div class="comment_field">
				<form id="get-tuch-form" v-on:submit.prevent="submitForm">
					<textarea  v-model="state.messagetext" placeholder="Add a public comment.."></textarea> 
					<button type="submit" class="post_btn"><i class="fas fa-share"></i></button>
					<!--<input type="submit" value="Add Comment" class="sign_submit">-->
				</form>
				<span v-if="commentError">Please enter Your Comment</span>
			</div>
		</div>
		<!-- <div class="" style="background: green;text-align: center;" v-if="successMsg">
		Your Comment Submited.
		</div>  -->
	</div>
	<div v-if="totalcomments > 0">
		<ul class="comment_list">
			<li v-bind:key="comments" v-for="comments in commentslist" >
				<CommentCard :comment="comments"/>
			</li>
		</ul>
		<!-- {{totalpage}}-{{current}}-{{current}} -->
		<a v-if="totalpage>current" @click="getmorecomments()" class="view_btn" style="cursor:pointer;">More</a>
	</div>
	<div style="height: 20px;text-align: center;margin-bottom: 20px" v-else>No Commend Found.</div>
</template>
<script>
	import CommentCard from './CommentCard'
	import axios from 'axios'
	import { BASE_URL } from '../config'
	import { API_BASE_URL } from '../config'
	import { API_IMAGE_PATH } from '../config'
	import moment from "moment";
	import { useRoute } from 'vue-router'
	import { createLocal } from 'the-storages'

	import { createToast } from 'mosha-vue-toastify';
	import 'mosha-vue-toastify/dist/style.css';

	const mirror = createLocal()
	const storage = mirror._prx
	// import { reactive, computed } from "vue"
	// import useValidate from "@vuelidate/core"
	// import { required} from "@vuelidate/validators"

	export default {
		name: 'JobRightBar',
		props:['jobId'],
		components:{
			CommentCard
		},
		data() {
			return { 
				state : {
					messagetext:''
				},
				commentError:false,
				storageData: mirror,
				storage: storage,
				imagepath:{},
				details: {},
				moment: moment,
				commentslist:[],
				totalcomments:0,
				orders:'desc',
				routdata:'',
				current: 1,
				commentslist2:'',
				commentslist3:[],
				totalpage:1,
				messagetext:'',
				postmessage:'',
				addpost:true,
				AuthUserId:'',
				userType:'',
				successMsg:false,
				form:1,
				//jobsId:this.jobId,
			}
		},
		beforeMount() {
			const route=useRoute(); 
			this.routdata=route.params.slug;
			this.getcomments();
		},
		async created () { 
			this.imagepath = API_IMAGE_PATH;
			console.log('jobid',this.jobId);
			const route=useRoute();       
			this.AuthUserId = mirror.AUTHID
			this.userType = mirror.USERTYPE
			this.routdata=route.params.slug;
		},
		methods:{
			async getFilterDetails(event) {
				this.orders = event.target.value;
				this.current=1;
				this.getcomments();
			},
			async getcomments(){ 
				const comments = await axios.get(API_BASE_URL + 'jobComments/'+this.routdata+'/'+this.orders+'?page=1');
				console.log("CMNTTT:",comments.data);
				if(comments.data.data!=''){
					this.commentslist=comments.data.data;
					this.commentslist2=comments.data;
					this.totalcomments=this.commentslist2.total;
					this.totalpage=this.commentslist2.last_page;
					this.form=comments.data.form;
					//if(this.current<=this.commentslist2.last_page){
						this.current=this.commentslist2.current;
					//} else {
					//	this.current=1;
					//}
				}
				
				
				console.log("DATA COMMENT:",comments.data);
			},
			async getmorecomments(){

				//if(this.current<=this.commentslist2.last_page){
					this.current=this.current+1;
				//}
				console.log("PAGE",this.current) ;  
				const comments = await axios.get(API_BASE_URL + 'jobComments/'+this.routdata+'/'+this.orders+'?page='+this.current);
				this.commentslist2=comments.data;
				if(this.current<=this.commentslist2.last_page)
				//this.current=this.current+1;
				this.commentslist3=this.commentslist;
				if(comments.data.data?.length>0){
					for(var i=0;i<comments.data.data?.length;i++)
					this.commentslist.push(comments.data.data[i])
				}
			},
			async submitForm() {
				//this.v1$.$validate()
				if(this.state.messagetext==''){
					this.commentError = true;
				}
				if (!this.commentError) {
					axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => 			{
					axios.post(BASE_URL +'api/Postcomments', {
						userId: mirror.AUTHID,
						jobId:this.jobId,
						jobslug:this.routdata,
						comments:this.state.messagetext
					}).then(response => {
						if (response.data.result=='success') {
							
							console.log('data:',this.message);
							
							this.orders = 'desc';
							this.current=1;
							this.commentError = false;
							this.state.messagetext = '';
							this.addpost=true;
							this.getcomments();
							this.postmessage = response.data.message;
							this.successMsg = true;
							this.showToast("Success",this.postmessage,"success"); 
						} else {
							this.postmessage = response.data.message;
							console.log('dataFailed:',this.message);
							this.showToast("Failed",this.postmessage,"danger"); 
						}
					});		
					console.log(responses);	
					});
					// setTimeout(() => {
					// 	this.successMsg = false;
						
					// }, 5000);
				}
			},
			showToast(title,description,type){
				createToast({
					title: title,
					description: description
				},
				{
					type: type,
					position: 'bottom-right',
					// hideProgressBar: 'true',
					// toastBackgroundColor: color,
				})
			},
		},
	}
</script>

<style scoped>
.area_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 20px;
}
.total_comments{
    font-size: 21px;
    line-height: 25px;
    color: #fff;
    font-weight: 400;
}
.total_comments span{
    color: #c61e1e;
    font-weight: 600;
}
.sortBy select{
    background: transparent;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Play', sans-serif;
    padding: 0 8px;
    appearance: none;
}
.add_comment{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px 0 30px;
}
.add_comment .user_img{
    padding-right: 12px;
}
.add_comment .user_img img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.add_comment .comment_field{
    flex: 1 1 auto;
}
.add_comment .comment_field #get-tuch-form{
    display: flex;
	align-items: flex-end;
}
.add_comment .comment_field textarea{
    flex: 1 1 auto;
    background: transparent;
    border-bottom: 1px solid #444;
    height: 40px;
    color: #d9d9d9;
    font-size: 13px;
    line-height: 19px;
    padding: 10px;
    font-weight: 400;
	font-family: 'Play', sans-serif;
	resize: none;
	appearance: none;
}
.add_comment .comment_field textarea::-webkit-scrollbar {
   display: none;
}
.post_btn{
	cursor: pointer;
	width: 30px;
	height: 30px;
	background: #c71e1e;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	color: #fff;
	margin-left: 5px;
}
.comment_area .comment_list{
    padding: 25px 0 0;
	height: 895px;
overflow-y: scroll;

}

.comment_area .comment_list::-webkit-scrollbar{
	width: 6px;
}
.comment_area .comment_list::-webkit-scrollbar-thumb{
	background-color: #333;
    border-radius:3px;
}




.comment_area .comment_list li{
    padding: 20px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}
.comment_area .comment_list li:nth-child(even){
    background: rgba(255, 255, 255, 0.03);
}
.comment_area .view_btn{
    display: block;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    color: #fff;
    margin:  auto 0;
}
</style>
<style>
 @media(max-width:740px){
.comment_area .comment_list{
    padding: 25px 0 0;
	height: 500px;}
 }
</style>