<template>
    <header :class="{header_sticky: scrollPosition > 100}">
      <div class="container">
        <div class="header_wpr">
          <router-link class="logo" to="/"><img src="../assets/logo.png" alt="" style="width:120px;padding:5px;"></router-link>
          <nav class="nav-bar">
              <ul class="navigator" :class="{open:isBtn}">
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="/about">About</router-link></li>
                  <li v-if="storage.get('TOKEN')"><router-link to="/blogs">Blog</router-link></li>
                  <li v-if="storage.get('TOKEN')"><router-link to="/jobs">Jobs</router-link></li>
                  <li><router-link to="/podcast">Podcast</router-link></li>
                  <li><router-link to="/contact">Contact</router-link></li>
                  <li v-if="storage.get('TOKEN')"> <a href="#" @click.prevent="logout()" class="login">Logout</a></li>
              </ul>
              <router-link to="/dashboard" class="login_btn" v-if="storage.get('TOKEN')"><span>My</span> Account</router-link>
              <router-link to="/login" class="login_btn" v-else><span>Members</span> Login</router-link>
              <button v-on:click="navigate" type="button" class="nav_btn"><i class="fas fa-align-right"></i></button>
          </nav>          
        </div>
      </div>
    </header>
    <span >
      <FloatPublicChat />
    </span>
</template>

<script>
import { createLocal } from 'the-storages'
import FloatPublicChat from '../pages/FloatPublicChat.vue'
import { BASE_URL } from '../config'
import axios from 'axios'
const mirror = createLocal()
const storage = mirror._prx
export default {
    name: 'Navigation2',
    components:{
        FloatPublicChat,
    },
    data(){
        return{
            scrollPosition: null,
            isBtn: false,
            storageData: mirror,
            storage: storage,
            userId: ''
        }
    },
    methods:{
        updateScroll() {
          this.scrollPosition = window.scrollY
        },
        navigate(){
            this.isBtn = !this.isBtn
        },
        created(){
          this.userId = (mirror.AUTHID)? mirror.AUTHID : '';
        },
         logout() {
             axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                 axios.post(BASE_URL + 'api/logout')
                     .then(response => {
                         if (response.data.success) {
                             console.log('dsfdsfsd' + response.data)
                             //storage.removeItem('AUTHID', true)
                              storage.removeItem('NAME', true)
                              storage.removeItem('USEREMAIL', true)
                              storage.removeItem('USERTYPE', true)
                              storage.removeItem('TOKEN', true)
                              storage.clear();
                              this.storage.set('AUTHID', '')
                              this.$router.push({ name: "Login" });
                         }
                     })
                     .catch(error => {
                         console.error(error);
                         //window.location.href = '/login';
                     });
                 console.warn(response)
             })
         }
    },
    mounted() {
      window.addEventListener('scroll', this.updateScroll);
    },
    destroy() {
      window.removeEventListener('scroll', this.updateScroll)
    }
}
</script>
<style scoped>
  header{
    padding-top: 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: all 0.3s ease-in-out;
  }
  header.header_sticky{
    background: #000;
    padding-top: 0;
  }
  .router-link-active .router-link-exact-active .logo img
  {
    width: 80px !important;
  }
  .header_wpr{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo{
    line-height: 0;
  }
  .nav-bar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 0;
  }
  .nav-bar .navigator{
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  .nav-bar .navigator li{
    padding: 10px 25px;
  }
  .nav-bar .navigator li a{
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    display: block;
  }
  .nav-bar .navigator li a.router-link-active{
    color: #cda53a;
    font-weight: 700;
  }
  .nav_btn{
    font-size: 22px;
    background: transparent;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 3px;
    width:40px;
    height: 40px;
    display: none;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    color: #d9d9d9;
    cursor: pointer;
  }
  .nav-bar button.login a{color:#fff;}

  @media(max-width:1199px){
    .nav-bar .navigator li{
        padding: 10px 18px;
    }
    .logo img{
      max-width: 180px;
      height: auto;
    }
    header{
      padding-top: 0;
    }
    .login_btn{
      margin-left: 15px;
      font-size:13px;
      line-height:17px;
      padding: 10px 20px;
    }
  }
  @media(max-width:991px){
    header{
      background: #000;
    }
    .navigator{
      position: absolute;
      width: 280px;
      height: calc(100vh - 66px);
      top: 66px;
      right: -100%;
      flex-direction: column;
      background: #000;
      transition: all 0.3s ease-in-out;
      z-index: -1;
    }
    .nav-bar .navigator li{
      width: 100%;
      text-align: right;
      padding: 0 20px;      
    }
    .nav-bar .navigator li a{
      font-size: 18px;
      line-height: 25px;
      padding: 20px 0;
      border-bottom: 1px solid rgba(255,255,255,0.1)
    }
    .nav-bar .navigator li:last-child a{
      border: 0;
    }
    .navigator.open{
      right: 0;
    }
    .nav_btn{
      display: flex;
    }
    
    .nav-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 0;
  width: 100%;
}

.navigator.open{ overflow-y: scroll;}

.login_btn{padding: 10px 13px;}


  }
  @media(max-width:575px){
    .logo img{
      max-width: 80px;
      height: auto;
    }
    button.login{
      padding: 8px 15px;
    }
    button.login span{
      display: none;
    }
    .nav_btn{
      margin-left: 20px;
    }
  }
</style>
