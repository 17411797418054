<template>
    <div class="comment_card">
        <div class="user_img">
            <img v-bind:src="imagepath+comment.user.profile_image" v-bind:alt="comment.user.fname" v-if="comment.user.profile_image">	
            <img :src="require(`@/assets/avatar.png`)" alt="" v-else>
        </div>
        <div class="comment_wpr">
            <div class="comment_header">
                <h4>{{comment.user.fname}} 
                    <!-- {{comment.user.lname}} -->
                    000{{comment.user.id}}
                    </h4>
                <span class="time">{{moment(comment.created_at).fromNow()}}</span>
            </div>
            <div class="comment_body">
                <p>{{comment.comments}}</p>
                <div class="reply" style="cursor:pointer;color:#ffc83d;" v-if="(replyList.length>0)" v-on:click="replySection = !replySection">Reply ({{replyList.length}})</div>
                <div class="reply" style="cursor:pointer;color:#ffc83d;" v-else v-on:click="replySection = !replySection">Reply </div>
                <div v-show="replySection">
                    <div class="action" style="margin-bottom:10px;" v-if="storage.get('TOKEN')">
                        <!-- <ul class="status">
                            <li><i class="fas fa-thumbs-up"></i>15</li>
                        </ul> -->
                        <div class="user_img">
                            <!-- <img style="width: 30px;height: 30px;" src="@/assets/avatar.png" alt=""> -->
                            <img style="width: 30px;height: 30px;" v-if="storage.get('PROFILEIMAGE')" v-bind:src="imagepath+storage.PROFILEIMAGE" v-bind:alt="storage.NAME" />
                            <img style="width: 30px;height: 30px;" v-else src="@/assets/avatar.png" v-bind:alt="storage.NAME" />
                        </div>
                        <div class="comment_field" style="width:71%">
                            <form id="get-tuch-form" v-on:submit.prevent="submitForm" style="display: flex;align-items: flex-end;">                               
                                <textarea style="width:100%" v-model="state.messagetext" placeholder="Add a Reply.."></textarea> 
                                <button type="submit" class="post_btn"><i class="fas fa-share"></i></button>
                            </form>
                            <!-- <span v-if="v$.messagetext.$error">Please enter Your Comment</span> -->
                        </div>
                    </div>
                    <div class="all_replies" v-on:click="replySectionDiv = !replySectionDiv" style="cursor: pointer;">
                        <h6 v-if="replyList.length>0" v-show="replySectionDiv">
                            <i class="fas fa-caret-up"></i>View {{replyList.length}} replies
                        </h6>
                        <h6 v-if="replyList.length>0" v-show="replySectionDiv==false || replySectionDiv==null">
                            <i class="fas fa-caret-down"></i>View {{replyList.length}} replies
                        </h6>
                    </div>
                    <div v-show="replySectionDiv" v-bind:key="reply.id" v-for="reply in replyList">
                        <div class="user_img" style="float: left;" >
                            <img v-bind:src="imagepath+reply.user.profile_image" v-bind:alt="reply.user.fname" v-if="reply.user.profile_image" style="width: 30px;height: 30px;">
                            <img style="width: 30px;height: 30px;" :src="require(`@/assets/avatar.png`)" alt="" v-else>
                        </div>
                        <div class="comment_wpr">
                            <div class="comment_header">
                                <h4>{{reply.user.fname}} 
                                    <!-- {{reply.user.lname}} -->
                                    000{{reply.user.id}}
                                    </h4>
                                <span class="time">{{moment(reply.created_at).fromNow()}}</span>
                            </div>
                            <div class="comment_body">
                                <p>{{reply.replys}}</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import moment from "moment";
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import { useRoute } from 'vue-router'
import { createLocal } from 'the-storages'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required} from "@vuelidate/validators"

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx
export default{
    name: 'CommentCard',
    props: [ 'comment'],
    setup(){
        const state = reactive({
            messagetext:'',
            commentId:'',
        })

        const rules = computed(() => {
            return {
                messagetext: { required},
            }
        })
        const v$ = useValidate(rules, state)
        return {
            state,
            v$
        }
    },
    data() {
        return {
            imagepath:{},
            moment: moment,
            replySection:false,
            replySectionDiv:false,
            storageData: mirror,
			storage: storage,
            replyList:[],
			totalreply:0,
        }
    },
    async created(){
        this.imagepath=API_IMAGE_PATH;
        console.log('jobid',this.jobId);
        const route=useRoute();       
        this.AuthUserId = mirror.AUTHID
        this.userType = mirror.USERTYPE
        this.routdata=route.params.slug;
        console.log("COMENT: ",this.comment.id);
        this.getReplys(this.comment.id);
    },
    methods:{
        async submitForm(e) {
            this.v$.$validate()
            if (!this.v$.$error) {
                if( this.state.messagetext && this.comment.id){
                    axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
                    axios.post(BASE_URL +'api/PostcommentReplys', {
                        userId: mirror.AUTHID,
                        commentId:this.comment.id,
                        replys:this.state.messagetext
                    }).then(response => {
                        if (response.data.result=='success') {
                            this.postmessage = response.data.message;
                            console.log('data:',this.message);
                            this.addpost=false;
                            this.orders = 'desc';
                            this.current=1;
                            this.state.messagetext='';
                            this.getReplys(this.comment.id);
                            this.showToast("Success",this.postmessage,"success"); 
                        } else {
                            this.postmessage = response.data.message;
                            console.log('dataFailed:',this.message);
                            this.showToast("Failed",this.postmessage,"danger"); 
                        }
                    });		
                        console.log(responses);	
                    });
                    e.target.reset();
                } else {
                    this.message = "reply can't be blank."
                    this.showToast("Warning!",this.message,"warning"); 
                }
            }
        },
        async getReplys(commentId){
            const replyData = {commentiId:commentId};
            const reply = await axios.post(API_BASE_URL + 'jobCommentReplys',replyData);
            this.replyList=reply.data;
            console.log("REPLY LIST:",reply.data);
        },
        showToast(title,description,type){
			createToast({
				title: title,
				description: description
			},
			{
				type: type,
				position: 'bottom-right',
				// hideProgressBar: 'true',
				// toastBackgroundColor: color,
			})
		},
    },
    // beforeMount() {
    //     const route=useRoute(); 
    //     this.routdata=route.params.slug;
    //     this.getcomments();
    // },
}
</script>
<style scoped>
.comment_field textarea{
    flex: 1 1 auto;
    background: transparent;
    border-bottom: 1px solid #444;
    height: 40px;
    color: #d9d9d9;
    font-size: 13px;
    line-height: 19px;
    padding: 10px;
    font-weight: 400;
	font-family: 'Play', sans-serif;
	resize: none;
	appearance: none;
}
.add_comment .comment_field textarea::-webkit-scrollbar {
   display: none;
}
.post_btn{
	width: 36px;
	height: 30px;
	background: #c71e1e;
	border-radius: 50%;
	/* display: flex; */
	justify-content: center;
	align-items: center;
	font-size: 15px;
	color: #fff;
	margin-left: 5px;
    cursor: pointer;
}
.add_comment .comment_field{
    flex: 1 1 auto;
}
.add_comment .comment_field #get-tuch-form{
    display: flex;
	align-items: flex-end;
}
.add_comment .comment_field textarea{
    flex: 1 1 auto;
    background: transparent;
    border-bottom: 1px solid #444;
    height: 40px;
    color: #d9d9d9;
    font-size: 13px;
    line-height: 19px;
    padding: 10px;
    font-weight: 400;
	font-family: 'Play', sans-serif;
	resize: none;
	appearance: none;
}
.add_comment .comment_field textarea::-webkit-scrollbar {
   display: none;
}
    .comment_card{
        display: flex;
    }
    .comment_card .user_img{
        padding: 0 12px 0 0;
        flex: 0 0 45px;
    }
    .comment_card .user_img img{
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }
    .comment_card .comment_wpr{
        flex: 1 1 auto;
    }
    .comment_card .comment_wpr .comment_header{
        padding-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .comment_card .comment_wpr .comment_header h4{
        font-size: 14px;
        line-height: 22px;
        color: #d9d9d9;
        font-weight: 400;
    }
    .comment_card .comment_wpr .comment_header .time{
        font-size: 12px;
        line-height: 18px;
        color: #d1d1d1;
        font-weight: 400;
    }
    .comment_card .comment_wpr .comment_body p{
        font-size: 13px;
        line-height: 18px;
        color: #999;
        font-weight: 400;
        padding-bottom: 10px;
    }
    .comment_card .comment_wpr .comment_body .action{
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        /* justify-content: space-between; */
    }
    .comment_card .comment_wpr .comment_body .action .status{
        display: flex;
        flex-wrap: wrap;
    }
    .comment_card .comment_wpr .comment_body .action .status li{
        font-size: 13px;
        line-height: 15px;
        color: #999;
        padding-right: 12px;
        display: flex;
        align-items: center;
    }
    .comment_card .comment_wpr .comment_body .action .status li i{
        padding-right: 6px;
        font-size: 18px;
    }
    .comment_card .comment_wpr .comment_body .action .reply{
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        color: #d9d9d9;
        font-weight: 400;
    }
    .comment_card .comment_wpr .comment_body .all_replies{
        padding: 10px 0;
    }
    .comment_card .comment_wpr .comment_body .all_replies h6{
        font-size: 13px;
        line-height: 16px;
        color: #999;
        font-weight: 400;
    }
    .comment_card .comment_wpr .comment_body .all_replies h6 i{
        padding-right: 5px;
    }
    @media(max-width:767px){
        .comment_card .comment_wpr .comment_header{
            flex-wrap: wrap;
        }
    }
</style>
