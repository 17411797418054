<template>
  <h3 class="section_title"><span>We are <strong>1%</strong> of the protection industry</span></h3>
  <swiper :slidesPerView="2" :spaceBetween="0" :pagination='{"clickable": true}' :breakpoints='{"1199": {"slidesPerView": 6, "spaceBetween": 30}, "767": {"slidesPerView": 4, "spaceBetween": 20}, "575": {"slidesPerView": 3, "spaceBetween": 20}}' class="brand_slider">
    <swiper-slide><img src="@/assets/dash/byron-rodgers-1.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-2.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-3.png" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-4.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-5.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-6.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-7.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-8.png" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-9.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-10.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-11.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-12.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/dash/byron-rodgers-13.jpg" alt=""></swiper-slide>
  </swiper>
</template>


<script >
import SwiperCore, { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.min.css";

import "swiper/components/navigation/navigation.min.css";


SwiperCore.use([Pagination]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  }  
}
</script>

<style scoped>
  .brand_slider{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 70px 0;
  }
  .brand_slider .swiper-slide{
    /*border-right: 1px solid rgba(255,255,255,0.1);*/
    display: flex;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .brand_slider .swiper-slide img{
    max-width: 100%;
    height: auto;
  }
  .section_title{
    font-size: 30px;
    line-height: 35px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    position: relative;
  }
  .section_title span{
    position: relative;
    padding: 0 15px;
    background: #0e0f10;
    display: inline-block;
  }
  .section_title:before{
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    top:50%;
    border-bottom:1px solid rgba(255,255,255,0.05);
  }
  @media(max-width:767px){
    .section_title{
      font-size: 22px;
      line-height: 28px;
    }
  }
</style>
