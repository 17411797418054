<template>
  <!-- <div class="box-total frame">
    <div class="box">
      <div class="circle-big one">
        <div class="text">
          <img src="@/assets/dash/new-candidate.png" alt=""/>	
        </div>
        <svg>
          <circle class="bg" cx="57" cy="57" r="52"></circle>
          <circle class="progress" cx="57" cy="57" r="52"></circle>
        </svg>
      </div>
      <p>865 (1176)</p><h4>New Candidates</h4>
    </div>
    <div class="box">
      <div class="circle-big two">
        <div class="text">
          <img src="@/assets/dash/response-due.png" alt=""/>	
        </div>
        <svg>
          <circle class="bg" cx="57" cy="57" r="52"></circle>
          <circle class="progress" cx="57" cy="57" r="52"></circle>
        </svg>
      </div>
      <p>378 (402)</p><h4>Response Due</h4>
    </div>
    <div class="box">
      <div class="circle-big three">
        <div class="text">
          <img src="@/assets/dash/waiting-action.png" alt=""/>
        </div>
        <svg>
          <circle class="bg" cx="57" cy="57" r="52"></circle>
          <circle class="progress" cx="57" cy="57" r="52"></circle>
        </svg>
      </div>
      <p>186 (76)</p><h4>Waiting Action</h4>
    </div>
    <div class="box">
      <div class="circle-big four">
        <div class="text">
          <img src="@/assets/dash/pending-task.png" alt=""/>
        </div>
        <svg>
          <circle class="bg" cx="57" cy="57" r="52"></circle>
          <circle class="progress" cx="57" cy="57" r="52"></circle>
        </svg>
      </div>
      <p>411 (385)</p><h4>Pending Tasks</h4>
    </div>
  </div> -->
  <div class="table-box" style="border-bottom: 2px solid #c71e1e;">
    <div class="table-header">
      <div class="table-thirty"> <h3>Interviews</h3></div>
      <div class="table-seventy">
        <ul>
          <li><button @click="activeTab = 'RecruA'" :class="{active: activeTab === 'RecruA'}">All Interviews</button></li>
          <li><button @click="activeTab = 'RecruB'" :class="{active: activeTab === 'RecruB'}">Today</button></li> 
          <li><button @click="activeTab = 'RecruC'" :class="{active: activeTab === 'RecruC'}">Upcoming </button></li> 
          <li><button @click="activeTab = 'RecruD'" :class="{active: activeTab === 'RecruD'}">Completed</button></li>
        </ul>
      </div>
    </div>
    <!-- <keep-alive>
      <component :is="activeTab"></component>
    </keep-alive> -->
    <keep-alive>
    <component :is="activeTab"></component>
    </keep-alive>
  </div>  
  <!-- <div class="pagination">
    <ul>
      <li class="active"><a href="#">1</a></li>  <li><a href="#">2</a></li>  <li><a href="#">3</a></li>  <li><a href="#">4</a></li>
      <li><a href="#">...</a></li>
    </ul>
  </div> -->
</template>
<script>

import RecruA from '../components/RecruA.vue'
import RecruB from '../components/RecruB.vue'
import RecruC from '../components/RecruC.vue'
import RecruD from '../components/RecruD.vue'

export default {
  name: 'DashboardInterview',
  components:{
    RecruA,
    RecruB,
    RecruC,
    RecruD
  },
  data(){
    return{
      activeTab: 'RecruA',
    }
  }
}
</script>