<template>
<table>
    <thead>
      <tr>
        <th>Candidate </th>
        <th>Interview Status </th>
        <th>Interviewer</th>
        <th>Schedule</th>
      </tr>
    </thead>
    <tbody>
    <tr v-for="candidate,index in allCandidateList.data" v-bind:key="index">
      <td data-label="Candidate"> 
        <div class="candidate-sec-table"> 
          <div class="candidate-image">
            <img style="width:55px;height:55px;border-radius: 50%;" v-bind:src="imagepath+candidate.user.profile_image" v-bind:alt="candidate.user.job_title" v-if="candidate.user.profile_image"><img :src="require(`@/assets/avatar.png`)" alt="" v-else>
          </div> 
          <div class="candidate-text"><h4>{{candidate.fname}} 
            <!-- {{candidate.lname}} -->
            000{{(candidate.candidate_id==19)? "0":candidate.candidate_id}}
            </h4>
            <p>{{candidate.email}}</p>
          </div>
        </div>
      </td>
      <td data-label="Interview Status ">
        <a href="#" class="active-btn">{{candidate.interviewStatus}}</a>
      </td>
      <td data-label="Interviewer">
        <h4>{{candidate.contactName}}</h4>
        <p>{{candidate.contactEmail}}</p>
      </td> 
      <td data-label="Schedule">
        <div class="fifty-tbl"><h4>{{candidate.interviewType}}</h4><p>{{candidate.time}}, {{candidate.date}}</p></div>
        <div class="fifty-tbl">
          <a href="tel:{{candidate.phone}}" class="schdule-btn">CALL</a>
          <a  :disabled="buttonclick" style="cursor:pointer;" @click="sendInterviewReminder(candidate.id,index)" class="schdule-btn">
            <span v-if="buttonclick==true && keyValue==index" style="color:white">Sending. . .</span>
            <span v-else style="color:white">Send Reminder</span>
          </a>
        </div>
      </td> 
    </tr>
    <tr v-if="totalCandidateCount==0"><td style="text-align: center;" data-label="Schedule" colspan="5">No Data Found</td></tr>
    </tbody>
  </table>
  <v-pagination
    v-if="is_show" 
		v-model="page"
		:pages="allCandidateList.last_page"
		:range-size="1"
		active-color="#DCEDFF"
		@update:modelValue="getResults"
	/>
</template>
<script>

import moment from "moment";
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import { createLocal } from 'the-storages'
const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'RecruA',
  props:['activeTab'],
  components:{
      VPagination
    },
    data() {
      return {
          storageData: mirror,
          storage: storage,
          moment: moment,
          allCandidateList: {},
          totalCandidateCount:this.totalCandidateCount,
          current: 1,
          error: '',
          error2:'',
          is_show:false,
          nofound:false,
          is_loader:true,
          imagepath:'',
          buttonclick:false,
          keyValue:'',
      }
    },
    mounted() {
      this.getResults();
    },
    created(){
      this.imagepath = API_IMAGE_PATH;
    },
    methods:{
      async sendInterviewReminder(interviewId,index){
        this.keyValue = index;
        console.log("Index: ",index);
        console.log("Key Value : ",this.keyValue);
        this.buttonclick = true;
        const params = {"id":interviewId};
        const interviewData = await axios.post(API_BASE_URL + `sendInterviewReminder`, params);
        console.log("Reminder: ",interviewData.data);
        this.buttonclick = false;
      },
      getResults(page) {
        //console.log("ACTIVATE: ",this.activeTab);
          if (typeof page === "undefined") {
              page = 1;
          }
          axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
              const inputData = {
                  userId: mirror.AUTHID,
                  activeTab:'RecruC'
              };
              axios.post(API_BASE_URL + `getInterviewList?page=${page}`, inputData)
              .then(response => {
                  this.allCandidateList = response.data;
                  this.totalCandidateCount = response.data.total;
                  console.log("DATA3:",response.data);

                  this.is_loader=true;
                  this.is_show=this.allCandidateList.last_page>1?true:false;

                  if(this.allCandidateList.data?.length>0){
                      this.nofound=false;
                      this.is_loader=false;
                  }   else    {
                      this.nofound=true;
                      this.is_loader=false;
                  }
              })
              .catch(({ response }) => {
                  this.error=response
              })
              this.error2=response
          });
      },
	},

}
</script>