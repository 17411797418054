<template>
<li>
	<div class="icon">
	<router-link :to="'/profile/' + candidateData.unique_user_id" v-if="membershipType=='Premium'">
    <img v-bind:src="imagepath+candidateData.profile_image" v-bind:alt="candidateData.job_title" v-if="candidateData.profile_image">
    <img :src="require(`@/assets/avatar.png`)" alt="" v-else>	
        <!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
	</router-link>
    <router-link to="" v-else>
        <img v-bind:src="imagepath+candidateData.profile_image" v-bind:alt="candidateData.job_title" v-if="candidateData.profile_image">	
        <img :src="require(`@/assets/avatar.png`)" alt="" v-else>	
        <!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
	</router-link>
	</div>
		<div class="job_info">
		<div class="job_title"><span>{{candidateData.job_title}}</span></div>
		<h3>
            <router-link :to="'/profile/' + candidateData.unique_user_id" style="color:white" v-if="membershipType=='Premium'">{{candidateData.fname}} 
            <!-- {{candidateData.lname}} -->
            000{{(candidateData.user_id==19)?"0" : candidateData.user_id}}
            </router-link>
            <router-link to="" v-else style="color:white">{{candidateData.fname}} 
            <!-- {{candidateData.lname}} -->
            000{{(candidateData.user_id==19)?"0" : candidateData.user_id}}
            </router-link>
        </h3>
		<ul class="candidate_info">
		<!-- <li><i class="fas fa-envelope" style="color:#cda53a"></i>{{candidateData.email}} </li>           
		<li><i class="fas fa-phone" style="color:#cda53a"></i>{{candidateData.phone_no}}</li> -->
		</ul>

		</div>
    <div class="job_date">
      <!--<div class="date">Job: <span>{{candidateData.job_title}}</span></div>-->
        <div class="date"><span style="color:#cda53a">Apply on: </span> {{moment(candidateData.created_at).startOf("month").format('MMMM')}} {{moment(candidateData.created_at).startOf("day").format('DD')}}, {{moment(candidateData.created_at).startOf("year").format('YYYY')}}</div>
        <div class="action_btnWpr">
            <button class="sign_submit" type="submit" v-on:click="execute(candidateData.id,'applied',candidateData.fname)">View Application</button>
            <button type="button" class="sign_submit" v-if="membershipType=='Premium'"><router-link :to="'/profile/' + candidateData.unique_user_id" style="color:white;cursor:pointer">View Profile</router-link></button>
            <!-- <button v-if="interviewDetails.isScheduled!=='Yes'" type="button" class="sign_submit"><router-link :to="'/interview-schedule/' + candidateData.unique_user_id" style="color:white;cursor:pointer">Schedule Interview</router-link></button>
            <button v-else type="button" class="sign_submit"><router-link :to="'/interview-reschedule/' + candidateData.unique_user_id" style="color:white;cursor:pointer">Reschedule Interview</router-link></button> -->
        </div>
    </div>
</li>
</template>

<script>
//import axios from 'axios'
//import { API_BASE_URL } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'
import { API_IMAGE_PATH } from '../config'
const mirror = createLocal()
const storage = mirror._prx

export default{
    name: 'CandidateCard',
    props:['candidateData', 'icon' ,'callback'],
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            imagepath:{},
            // interviewDetails:{},
            membershipType:''
        }
    },
    async created(){
        this.membershipType = mirror.MEMBERSHIP_TYPE;
        this.imagepath=API_IMAGE_PATH;
        this.userId=mirror.AUTHID;
        this.userType=mirror.USERTYPE;
        //console.log(this.imagepath);

        // const param    = {
        //     userId:mirror.AUTHID,
        //     candidateId:this.candidateData.user_id
        // }
        // const response= await axios.post(API_BASE_URL + 'getInterviewDetails',param);
        // console.log("INTERVIEW UPDATE2: ",response.data);
        // console.log("INTERVIEW UPDATE22: ",this.candidateData.user_id);
        // this.interviewDetails = response.data;
    },
    methods:{
        execute(test,applied,name) {
			if (this.callback) {
				this.callback(test,applied,name)
			}
		}
    }
}
</script>
<style scoped>
    /* .content_wrapper {
        width: 50%;
        height: auto;
        background: #161618;
        border: 1px solid rgba(255, 255, 255, 0.1);
    } */

.icon img{
    width: 100px;
    height: 85px;
    object-fit: cover;
}
.job_info{
    flex: 1 1 290px;
    padding-left: 30px;
}
.job_date{
    flex: 1 1 150px;
}
.job_info h3{
    font-size: 24px;
    line-height: 30px;
    color: #d9d9d9;
}
.job_info .job_title{
    font-size: 16px;
    line-height: 22px;
    color: #d9d9d9;
    margin-bottom:5px;
}
.job_info i{
    color: #c71e1e;
    margin-right: 7px;
    font-size: 16px;
}
.job_info .candidate_info{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}
.job_info .candidate_info li{
    padding: 5px 10px;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
}
.job_date{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.job_date .date{
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-top:10px;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #c71e1e;
}
.job_date .action_btnWpr{
    display:flex;
    flex-direction: row-reverse;
    padding-top: 4px;
}
.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
}

@media(max-width:1199px){
    .job_info{
        padding-left:20px;
    }
    .job_info h3{
        font-size: 20px;
        line-height: 26px;
    }
}
@media(max-width:670px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .candidate_info{
        justify-content: center;
    }
    .job_date {
        align-items: center;
    }
    .icon{
        flex: 0 0 100%;
        text-align: center;
    }
}

</style>