<template>
  <div class="gt-started-frm tabinfo" id="experience_info">
	<!-- <div v-if="message!=''" class="success">{{message}}</div>
	<div v-if="errormessage!=''" class="error">{{errormessage}}</div> -->
    <h4>Experience</h4>
    <ul class="experience_list" v-if="experienceList">
      <li v-bind:key="experience.id" v-for="(experience,key) in experienceList">
        <div class="experience_card">
          <div class="experience_head">
            <h5>Experience {{key+1}}</h5>
            <div class="action_btn">
              <button class="edit_btn" @click="editPop(experience.id)"><i class="fas fa-pencil-alt"></i></button>
              <button class="delete_btn" @click="deletePop(experience.id)"><i class="far fa-trash-alt"></i></button>
            </div>
          </div>
          <div class="experience_body">
            <label for="">Company Name:</label>
            <p>{{experience.company_name}}</p>
            <label for="">Company Logo:</label>
            <p v-if="experience.company_logo">
              <img class="logo" v-bind:src="imagepath+experience.company_logo" v-bind:alt="experience.id" >
              </p>
            <p v-else>
              no image
              <!-- <img src="@/assets/byron-logo.png" alt="" class="logo"> -->
            </p>
            <label for="">Job Start Date:</label>
            <p>{{moment(experience.start_date).startOf("date").format('DD MMMM YYYY')}}<sup></sup> </p>
            <label for="">Job End date:</label>
            <p>{{moment(experience.end_date).startOf("date").format('DD MMMM YYYY')}}<sup></sup> </p>            
            <label for="">Work Details:</label>
            <p>{{experience.work_details}}</p>
          </div>
        </div>
      </li>
      <!-- <li>
        <div class="experience_card">
          <div class="experience_head">
            <h5>Experience 3</h5>
            <div class="action_btn">
              <button class="edit_btn" @click="editPop"><i class="fas fa-pencil-alt"></i></button>
              <button class="delete_btn"><i class="far fa-trash-alt"></i></button>
            </div>
          </div>
          <div class="experience_body">
            <label for="">Company Name:</label>
            <p>XYZ Corporation</p>
            <label for="">Company Logo:</label>
            <p><img src="@/assets/logo.png" alt="" class="logo"></p>
            <label for="">Job Start Date:</label>
            <p>11<sup>th</sup> February, 2012</p>
            <label for="">Job End date:</label>
            <p>17<sup>th</sup> July, 2015</p>
            <label for="">Work Details:</label>
            <p>Aenean scelerisque enim eget posuere molestie. Phasellus eleifend pellentesque erat a congue. Donec sed ultricies ligula, nec fringilla lacus.</p>
          </div>
        </div>
      </li>
      <li>
        <div class="experience_card">
          <div class="experience_head">
            <h5>Experience 3</h5>
            <div class="action_btn">
              <button class="edit_btn" @click="editPop"><i class="fas fa-pencil-alt"></i></button>
              <button class="delete_btn"><i class="far fa-trash-alt"></i></button>
            </div>
          </div>
          <div class="experience_body">
            <label for="">Company Name:</label>
            <p>XYZ Corporation</p>
            <label for="">Company Logo:</label>
            <p><img src="@/assets/poster-heading.png" alt="" class="logo"></p>
            <label for="">Job Start Date:</label>
            <p>11<sup>th</sup> February, 2012</p>
            <label for="">Job End date:</label>
            <p>17<sup>th</sup> July, 2015</p>
            <label for="">Work Details:</label>
            <p>Aenean scelerisque enim eget posuere molestie. Phasellus eleifend pellentesque erat a congue. Donec sed ultricies ligula, nec fringilla lacus.</p>
          </div>
        </div>
      </li> -->
    </ul>
    <ul v-if="nodata">
     <li>No Data Found</li>
    </ul>
    <!-- <button class="save_btn">Save</button> -->
    <button @click="addPop" class="add_btn"><i class="fas fa-plus"></i> Add Experience</button>
  </div>

  <!-- Edit Experience -->
  <div class="modal" v-if="modal">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>Edit Experience</h2>
        <button @click="close"><i class="fas fa-times"></i></button>
      </div>
      <form id="jobpost-form" v-on:submit.prevent="editUserExperience" enctype="multipart/form-data">
      <div class="modal_body">
        <div class="form_grp">
          <div class="col_2">           
            <div class="item_grp">
              <label>Company Name*:</label> 
              <input type="text" placeholder="Enter Company Name" v-model="stateEdit.editCompanyName" name="editCompanyName" id="editCompanyName"/>
              <span v-if="v2$.editCompanyName.$error">{{ v2$.editCompanyName.$errors[0].$message }}</span>
            </div> 
            <div class="item_grp">
              <label>Company Logo:</label>
              <input v-if="stateEdit.editLogo=='' || stateEdit.editLogo==NULL" type="file" ref="editLogo" @input="logoEditUpload" name="editLogo" id="editLogo" accept="image/*" >
              <div class="img_wpr" v-else>
                <img v-bind:src="logoPathEdit" v-bind:alt="stateEdit.editLogo">
                <a @click.prevent="removeImage(stateEdit.editId)" class="removeicon"><i class="fa fa-times" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col_2"> 
            <div class="item_grp">
              <label>Job Start Date:</label>
              <datepicker placeholder="Enter Job Start Date" v-model="stateEdit.editStartDate" :upperLimit="new Date()"/>
              <span v-if="v2$.editStartDate.$error">
                {{ v2$.editStartDate.$errors[0].$message }}
              </span>
            </div>
            <div class="item_grp">
              <label>Job End date:</label>
              <datepicker placeholder="Enter Job End Date" v-model="stateEdit.editEndDate" :upperLimit="new Date()"/>
              <span v-if="v2$.editEndDate.$error">
                {{ v2$.editEndDate.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="item_grp">
            <label>Work Details:</label>
            <textarea cols="30" rows="10" placeholder="Enter Work Details" v-model="stateEdit.editWorkDetail" name="workDetail" id="workDetail"></textarea>
            <span v-if="v2$.editWorkDetail.$error">
              {{ v2$.editWorkDetail.$errors[0].$message }}
            </span>
          </div>
          <div class="item_grp">
            <button type="submit" class="add_btn" :disabled="buttonclick">
              <span v-if="buttonclick==true" style="color:white">Loading. . . . .</span>
              <span v-else style="color:white"> Update Now</span>
            </button>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <!-- Add New Experience -->
  <div class="modal" v-if="addModal">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>Add New Experience</h2>
        <button @click="close"><i class="fas fa-times"></i></button>
      </div>
      <form id="jobpost-form" v-on:submit.prevent="addUserExperience" enctype="multipart/form-data">
      <div class="modal_body">
        <div class="form_grp"> 
          <div class="col_2"> 
            <div class="item_grp">
              <label>Company Name*:</label> 
              <input type="text" placeholder="Enter Company Name" v-model="state.companyName" name="companyName" id="companyName"/>
              <span v-if="v$.companyName.$error">
                {{ v$.companyName.$errors[0].$message }}
              </span>
            </div> 
            <div class="item_grp">
              <label>Company Logo:</label>
              <input type="file" ref="logo" @input="experienceUpload" name="logo" id="logo" accept="image/*" >
            </div>
          </div>
          <div class="col_2"> 
            <div class="item_grp">
              <label>Job Start Date*:</label>
              <datepicker placeholder="Enter Job Start Date" v-model="state.startDate" :upperLimit="new Date()" />
              <span v-if="v$.startDate.$error">
                {{ v$.startDate.$errors[0].$message }}
              </span>
            </div>
            <div class="item_grp">
              <label>Job End date*:</label>
              <datepicker placeholder="Enter Job End Date" v-model="state.endDate" :upperLimit="new Date()" />
              <span v-if="v$.endDate.$error">
                {{ v$.endDate.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="item_grp">
            <label>Work Details*:</label>
            <textarea cols="30" rows="10" placeholder="Enter Work Details" v-model="state.workDetail" name="workDetail" id="workDetail"></textarea>
            <span v-if="v$.workDetail.$error">
              {{ v$.workDetail.$errors[0].$message }}
            </span>
          </div>
          <div class="item_grp">
            
            <button type="submit" class="add_btn" :disabled="buttonclick">
              <span v-if="buttonclick==true" style="color:white">Loading. . . . .</span>
              <span v-else style="color:white"> Add Work Details</span>
            </button>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <!-- Delete -->
  <div class="modal" v-if="deleteModal">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>Are you sure want to remove this Experience ?</h2>
        <!-- <button @click="close"><i class="fas fa-times"></i></button> -->
      </div>
      <div class="modal_body">
        <div class="form_grp">
          <form id="jobpost-form" v-on:submit.prevent="deleteExperienceSubmit">
            <div class="item_grp">
              <input type="hidden" v-model="deleteId" name="deleteId">
              <button type="submit" class="add_btn">Confirm</button>
              <button class="add_btn" @click="close">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { createLocal } from 'the-storages'
import moment from "moment";
import Datepicker from 'vue3-datepicker'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'EpC',
  components: {
		Datepicker
	},
  setup() {
    const state = reactive({
        companyName: '',
        logo: '',
        workDetail: '',
        startDate: '',
        endDate: '',
    })
    const stateEdit = reactive({
        editCompanyName: '',
        editLogo: '',
        editWorkDetail: '',
        editStartDate: '',
        editEndDate: '',
    })
    const rules = computed(() => {
      return {
        companyName: { required },
        //logo: { required },
        workDetail: { required },
        startDate: { required },
        endDate: { required },
      }
    })
    const rules2 = computed(() => {
      return {
        editCompanyName: { required },
       // editLogo: { required },
        editWorkDetail: { required },
        editStartDate: { required },
        editEndDate: { required },
      }
    })
    const v2$ = useValidate(rules2, stateEdit)
    const v$ = useValidate(rules, state)
    return {
      state,
      stateEdit,
      v$,
      v2$
    }
  },
  data(){
    return{
      moment: moment,
      modal: false,
      addModal: false,
      deleteModal: false,
      companyName: '',
      logo: '',
      workDetail: '',
      startDate: new Date(),
      endDate: new Date(),
      errors: [],
      errormessage: '',
      message: '',
      storageData: mirror,
      storage: storage,
      errorStr: null,
      imagepath:{},
      experienceList:[],
      deleteId:this.deleteId,
      editCompanyName: this.stateEdit.editCompanyName,
      editLogo: this.stateEdit.editLogo,
      editWorkDetail: this.stateEdit.editWorkDetail,
      editStartDate: this.stateEdit.editStartDate,
      editEndDate: this.stateEdit.editEndDate,
      editId:this.stateEdit.editId,
      logoPath:this.logoPath,
      logoEditPath:this.logoEditPath,
      nodata:false,
      buttonclick:false,
    }
  },
  async created() {
		const experienceList = await axios.post(API_BASE_URL + 'experienceList', {userId: mirror.AUTHID});
		console.log("LIST:",experienceList.data);
		this.experienceList = experienceList.data;
		if(experienceList.data?.length<=0){
		this.nodata=true;
		}else{
		this.nodata=false;	
		}
		this.imagepath = API_IMAGE_PATH+"experiences/";
		},
  methods:{
    async addUserExperience() {
      this.buttonclick = true;
			const config = {
        headers: { 'content-type': 'multipart/form-data' }
        }
        this.v$.$validate(this.v$.$error);

      if (!this.v$.$error) {

        let formData = new FormData(); 
        if(this.state.logo!=''){			
          formData.append('logo', this.state.logo);
        }
        formData.append('userId', mirror.AUTHID);
        formData.append('companyName', this.state.companyName);
        formData.append('workDetail', this.state.workDetail);
        formData.append('startDate', moment(this.state.startDate).format('YYYY-MM-DD'));
        formData.append('endDate', moment(this.state.endDate).format('YYYY-MM-DD'));
        
        if (this.state.companyName && this.state.workDetail && this.state.startDate && this.state.endDate) {// && this.state.logo
            console.log("3");
            //axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                await axios.post(API_BASE_URL + 'add-experience', formData,config)
                    .then(response => {                        	
                    console.log(response)                        	
                        if (response.data.result == 'success') {
                            this.errormessage ='';
                            this.message = response.data.message;
                            this.addModal = false
                            //this.$router.push({ name: "JobList" });
                            this.state.companyName = '';
                            this.state.workDetail = '';
                            this.state.startDate = '';
                            this.state.endDate = '';
                            this.state.logo = '';
                            this.buttonclick = false;
                            // setTimeout(() => {
                            //     this.errormessage = '';
                            //     console.log(this.errormessage);
                            // }, 5000);
                            this.showToast("Success",this.message,"success"); 
                        } else {
                            this.errormessage = response.data.message;
                            this.message = '';
                            this.buttonclick = false;
                            this.showToast("Failed",this.errormessage,"danger"); 
                        }
                    })
                    .catch(error => {
                        //console.log('error-data:', error)
                        console.error(error);
                    });
                //console.log(responses);
                const experienceList = await axios.post(API_BASE_URL + 'experienceList', {userId: mirror.AUTHID});
                console.log("LIST:",experienceList.data);
				if(experienceList.data?.length<=0){
				this.nodata=true;
				}else{
				this.nodata=false;	
				}
                this.experienceList = experienceList.data;
				var element = document.getElementById("experience_info");
				element.scrollIntoView();
            //});
            
        } else {
          this.errormessage = 'All Fields are Mandatory';
          this.buttonclick = false;
          this.showToast("Warning!",this.errormessage,"warning"); 
        }
      } else {
        this.errormessage = 'All Fields are Mandatory';
        this.buttonclick = false;
        this.showToast("Warning!",this.errormessage,"warning"); 
      }
      // setTimeout(() => {
      //     this.errormessage = '';
      //     console.log(this.errormessage);
      // }, 5000);
    },
    experienceUpload(e) {
      const file = e.target.files[0]
      this.state.logo = file
      this.logoPath = URL.createObjectURL(file);
      console.log("image1",this.logo)
    },
    async deleteExperienceSubmit() {
      await axios.post(API_BASE_URL + 'deleteExperience', {"deleteId":this.deleteId})
      .then(response => {
        console.log(response);
          if (response.data.result == 'success') {
              this.errormessage ='';
              this.message = response.data.message;
              this.deleteModal = false
              this.showToast("Success",this.message,"success"); 
              //this.$router.push({ name: "JobList" });
          } else {
              this.errormessage = response.data.message;
              this.message = '';
              this.showToast("Failed",this.errormessage,"danger"); 
          }
      })
      .catch(error => {
          //console.log('error-data:', error)
          console.error(error);
      });
      const experienceList = await axios.post(API_BASE_URL + 'experienceList', {userId: mirror.AUTHID});
      console.log("LIST:",experienceList.data);
		if(experienceList.data?.length<=0){
		this.nodata=true;
		}else{
		this.nodata=false;	
		}
      this.experienceList = experienceList.data;
	var element = document.getElementById("experience_info");
	element.scrollIntoView();
    },
    async editUserExperience() {
      this.buttonclick = true;
			const config = {
        headers: { 'content-type': 'multipart/form-data' }
        }
      this.v2$.$validate(this.v2$.$error);
      if (!this.v2$.$error) {
        let formDataEdit = new FormData();
        if(this.stateEdit.editLogo!=''){			
          formDataEdit.append('logo', this.stateEdit.editLogo);
        }
        formDataEdit.append('editedId', this.stateEdit.editId);
        formDataEdit.append('userId', mirror.AUTHID);
        
        formDataEdit.append('companyName', this.stateEdit.editCompanyName);
        formDataEdit.append('workDetail', this.stateEdit.editWorkDetail);
        formDataEdit.append('startDate', moment(this.stateEdit.editStartDate).format('YYYY-MM-DD'));
        formDataEdit.append('endDate', moment(this.stateEdit.editEndDate).format('YYYY-MM-DD'));
        if (this.stateEdit.editCompanyName && this.stateEdit.editWorkDetail && this.stateEdit.editStartDate && this.stateEdit.editEndDate ) {//&& this.stateEdit.editLogo
            console.log("3");
            //axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                await axios.post(API_BASE_URL + 'edit-experience', formDataEdit,config)
                    .then(response => {                        	
                    console.log(response)
                        if (response.data.result == 'success') {
                            this.errormessage ='';
                            this.message = response.data.message;
                            this.modal = false;
                            this.buttonclick = false;
                            this.showToast("Success",this.message,"success"); 
                        } else {
                            this.errormessage = response.data.message;
                            this.message = '';
                            this.buttonclick = false;
                            this.showToast("Failed",this.errormessage,"danger"); 
                        }
                    })
                    .catch(error => {
                        //console.log('error-data:', error)
                        console.error(error);
                    });
                //console.log(responses);
                const experienceList = await axios.post(API_BASE_URL + 'experienceList', {userId: mirror.AUTHID});
                console.log("LIST:",experienceList.data);
				if(experienceList.data?.length<=0){
				this.nodata=true;
				}else{
				this.nodata=false;	
				}
                this.experienceList = experienceList.data;
				var element = document.getElementById("experience_info");
        this.buttonclick = false;
				element.scrollIntoView();
            //});
            
        } else {
          this.errormessage = 'All Fields are Mandatory';
          this.buttonclick = false;
          this.showToast("Warning!",this.errormessage,"warning"); 
        }
      } else {
        this.errormessage = 'All Fields are Mandatory';
        this.buttonclick = false;
        this.showToast("Warning!",this.errormessage,"warning"); 
      }
     
    },
    logoEditUpload(e) {
      const file = e.target.files[0]
      this.stateEdit.editLogo = file
      this.logoPathEdit = URL.createObjectURL(file);
      console.log("image1",this.editLogo)
    },
    editPop: async function(id){
      this.modal = true
      this.stateEdit.editId = id
      const experienceDetails = await axios.post(API_BASE_URL + 'experienceDetails', {userId: mirror.AUTHID,"editedId":this.stateEdit.editId});
      console.log("DETAILS:",experienceDetails.data);
      this.stateEdit.editCompanyName = experienceDetails.data.company_name;
      this.stateEdit.editStartDate = new Date(experienceDetails.data.start_date);
      this.stateEdit.editEndDate = new Date(experienceDetails.data.end_date);
      this.stateEdit.editWorkDetail = experienceDetails.data.work_details;
      this.stateEdit.editLogo = experienceDetails.data.company_logo;
      this.logoPathEdit = API_IMAGE_PATH+"experiences/"+experienceDetails.data.company_logo;
      //this.certificateList = certificateList.data;
    },
    addPop: function(){
      this.addModal = true
    },
    deletePop: function(id){
      //alert("hii");
      this.deleteModal = true
      this.deleteId = id
    },
    close: function(){
      this.modal = false
      this.addModal = false
      this.deleteModal = false
    },
    removeImage(id){
        const inputData = {
          userId: mirror.AUTHID,
          removeImageId: id,
        };
        axios.post(API_BASE_URL + 'user/removeExperienceimage', inputData)
        .then(response => {                        	
          if (response.data.result == 'success') {
            console.log('data:', response)
            
            this.stateEdit.editLogo='';
            this.logoPathEdit='';
            this.errormessage = '';
            this.message = response.data.messase;
            //this.$router.push({ name: "JobList" });
            this.showToast("Success!",this.message,"success"); 
          } else {
            this.errormessage = response.data.message;
            this.message = '';
            this.showToast("Failed",this.errormessage,"danger"); 
          }
        })
        .catch(error => {
        //console.log('error-data:', error)
        console.error(error);
        });
        
    },
    showToast(title,description,type){
      createToast({
          title: title,
          description: description
      },
      {
          type: type,
          position: 'bottom-right',
          // hideProgressBar: 'true',
          // toastBackgroundColor: color,
      })
  },
  },
}
</script>

<style scoped>
.experience_list{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.experience_list li{
  width: 33.333%;
  padding: 15px;
}
.experience_list li .experience_card{
  background: #161618;
}
.experience_head{
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience_head h5{
  font-size: 20px;
  line-height: 26px;
}
.experience_head .action_btn{
  display: flex;
}
.action_btn .edit_btn{
  border: 1px solid #999;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: transparent;
  color: #fff;
  cursor: pointer
}
.action_btn .delete_btn{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: #c71e1e;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}
.experience_body{
  padding: 15px 25px;
}
.experience_body label{
  font-size: 14px;
  line-height: 17px;
  color: #d3d3d3;
  font-weight: 500;
}
.experience_body p{
  font-size: 13px;
  line-height: 18px;
  color: #999;
  font-weight: 300;
  padding: 2px 0 10px !important;
}
.experience_body p img.logo{
  max-height: 140px;
  width: auto;
}
button.save_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #c71e1e;
  background: #c71e1e;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  cursor:pointer;
}
button.add_btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  background: transparent;
  margin-left: 25px;
  cursor:pointer;
  float:right;
}
button.add_btn i{
  margin-right: 5px;
}

form .modal_body .form_grp .item_grp{ overflow: visible;}

@media(max-width:991px){
  .experience_list li{
    flex: 0 0 50%;
  }

}
@media(max-width:670px){
  .experience_list li{
    flex: 0 0 100%;
  }
}
</style>