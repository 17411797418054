<template>
 <div>
<div class="candidate-sec">
 <div class="fifty-candidate browse-job" style="z-index:1;"><h2>Browse Jobs</h2><h4>Total Jobs <span style="color:rgb(205, 165, 58);">{{totalJobCount}}</span></h4>
 <p>If you're serious about your success in this industry, I invite you attend this free, invite-only webinar where I'll breakdown the 3 Principals of Executive Protection mastery and how you can start to apply them into your career right now.

Seats are very limited and strictly available on a first come, first served basis. Reserve your seat now and I’ll see you soon!.</p>
  <div class="srch-btm">
    <div class="srch-lft"><router-link to="/jobs" class="search-btn">SEARCH</router-link></div>
    <div class="srch-lft">
      <div class="prfl-total">
        <div class="prfl-box"><img src="@/assets/dash/search-file-img1.jpg" alt=""/></div>
         <div class="prfl-box"><img src="@/assets/dash/search-file-img2.jpg" alt=""/></div>
          <div class="prfl-box"><img src="@/assets/dash/search-file-img3.jpg" alt=""/></div>
            <!-- <a href="#" class="plus"><img src="@/assets/dash/plus.jpg" alt=""/></a> -->
      </div>    
    </div>
  </div>
 </div>
 <div class="fifty-candidate">
 <div class="famly-img">
   <div class="family-text">
     <div>
       <img src="@/assets/dash/apply-joining-text.png" alt=""/>
       <a
					href="https://www.executiveprotectiontrainingday.com/eptd-masters-enrollment1635187064705"
					class="search-btn"
					>Apply Now 
        </a>
        <!-- <router-link to="/training" class="search-btn">Apply Now</router-link> -->
        </div>
   </div>
   </div> 
  
 </div>
</div>

<h5>Executive Protection Podcast </h5>

<div class="prodcast_slider">
<DashboardPodcastslider2 :podcastList="podcastList" />
<div class="stroke_clip"></div>
</div>
 <div v-if="allCandidateList && allCandidateList.data?.length > 0">   
<h5>Recent Applied Jobs </h5> 

<div class="recent_aj">
  <div class="joblist_Wpr">
    <ul class="job_list">
        <JobCard :callback="clickToGetJobDetails" v-bind:key="job.id" v-for="job in allCandidateList.data" :jobData="job" icon="icon2.png" />
    </ul>
  </div>
</div>        
<v-pagination
			v-model="page"
			:pages="allCandidateList.last_page"
			:range-size="1"
			active-color="#DCEDFF"
			@update:modelValue="getResults"
			/>
  
</div>
<br>
 
 <h5>Executive Protection Blog </h5> 

  <div class="bottom_cd" style="clear: both !important;">
    <div class="thirty-three" v-bind:key="blog.id" v-for="(blog) in blogList">
      <div class="blog_wpr">
        <!-- <router-link to="/blog/{{blog.slug}}"> -->
        <router-link :to="'/blog/' + blog.slug" class="">
          <img  v-if="blog.featured_image!=''" v-bind:src="imagepath+blog.featured_image" v-bind:alt="blog.title" width="250"/>
          <img src="@/assets/b-1.jpg" v-else>	
        </router-link>
        <!-- </router-link> -->
        <div class="thirty-three-text">
          <p><strong><span style="color:rgb(205, 165, 58);">{{ moment(blog.created_at).startOf("month").format('MMMM')}}</span> {{ moment(blog.created_at).format("DD,YYYY") }}</strong> </p> 
          <p class="tt-u sub-text"> <router-link :to="'/blog/category/' + blog.category.slug" v-if="blog.category">{{blog.category.name}}</router-link>. <strong>{{blog.user.fname}} {{blog.user.lname}}</strong></p> 
          <h4><router-link :to="'/blog/' + blog.slug" class="">{{blog.title}}</router-link></h4>  
        </div>
      </div>
    </div>
  </div>
 </div>
</template>


<script>
import DashboardPodcastslider2 from '../components/DashboardPodcastslider2.vue'
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'
import JobCard from '../components/JobCardList.vue'

const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'DashboardRecruiter',
  components:{
    DashboardPodcastslider2,
    JobCard
  },
  data() {
    return {
      moment:moment,
			storageData: mirror,
			storage: storage,
			isLoading: true,
			imagepath:{},
			totalJobCount: '',
			totalCandidateCount:this.totalCandidateCount,
			allCandidateList:{},
			podcastList:[],
			blogList:[],
			playVideo: false,
			countryname:'',
			visible: false,
      current: 1,
    }
  },
  async created () {
      this.AuthUserId = mirror.AUTHID
      this.userType = mirror.USERTYPE
      //console.log(mirror);
      const param    = {
          userId:mirror.AUTHID
      }

      this.imagepath = API_IMAGE_PATH
      const response= await axios.post(API_BASE_URL + 'agentWiseDashboardData',param);

      //this.savedCandidateList     = response.data.candidateShortlistData;
      this.totalJobCount          = response.data.totalJobCount;
      this.podcastList            = response.data.podcastList;
      this.blogList               = response.data.blogList;

      console.log("DATA:",response.data);
	},  
  mounted() {
      this.getResults();
  },
  methods:{
        getResults(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `agentJobsCandidateList?page=${page}`, inputData)
                .then(response => {
                    this.allCandidateList = response.data;
                    this.totalCandidateCount = response.data.total;
                    console.log("DATA3:",response.data);
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        },
        async clickToGetJobDetails(jobId,slug){
                //const slug = 1;
                console.log("JOB:",jobId,"-SLUG:",slug);
                if(mirror.MEMBERSHIP_TYPE=='Free') {
                    const inputData = {
                        userId: mirror.AUTHID,
                        jobId: jobId
                    };
                    await axios.post(API_BASE_URL + `membershipCheckingForJobList`, inputData).then(response => {
                        this.data = response.data;
                        
                        console.log("A1: ",this.data);
                        if(this.data.result == 'success') {
                            if(this.data.count>0 && this.data.count<=5){
                                console.log("THIS SECTION 1");
                                this.$router. push('/jobDetails/'+slug);
                            } else {
                                console.log("THIS SECTION 2");
                                this.modalMembership = true;
                            }
                        } else {
                            console.log("THIS SECTION 3");
                            this.modalMembership = true;
                        }
                    }).catch(({ response }) => {
                        this.error=response
                    })
                } else {
                    this.$router. push('/jobDetails/'+slug);
                }
            },
	},
}
</script>

<style scoped>
.middle-part.dashboard .rigt-part.cd .thirty-three a img{
  height: 170px;
}
.joblist_Wpr{
  background: #1b1b1b;
  padding: 30px 60px;
  position: relative;
}
.joblist_Wpr:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-image: url('~@/assets/figure-bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat ;
}
.job_list > li{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255,255,255,0.05);
  padding: 40px 0;
  position: relative;
  z-index: 2;
}
.job_list > li:last-child{
  border:0;
}
@media(max-width:991px){
  .joblist_Wpr{
    padding: 10px 20px;
  }
  .job_list > li{
    padding: 20px 0;
  }
}
@media(max-width:575px){
  .srch-lft:first-of-type{
    width: 100%;
    order: 2;
  }
}
</style>