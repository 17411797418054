<template>
<li>
	<div class="icon">
	<router-link :to="'/profile/' + candidateData.users.unique_user_id" v-if="membershipType=='Premium'">
	<img v-bind:src="imagepath+candidateData.users.profile_image" v-bind:alt="candidateData.job_title" v-if="candidateData.users.profile_image">	
	<img :src="require(`@/assets/avatar.png`)" alt="" v-else>	
	<!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
	</router-link>
    <router-link to="" v-else>
	<img v-bind:src="imagepath+candidateData.users.profile_image" v-bind:alt="candidateData.job_title" v-if="candidateData.users.profile_image">	
	<img :src="require(`@/assets/avatar.png`)" alt="" v-else>	
	<!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
	</router-link>
	</div>
	<div class="job_info">
    <div class="job_title"><span>{{candidateData.jobs.job_title}}</span></div>
	<h3>
        <router-link :to="'/profile/' + candidateData.users.unique_user_id" style="color:white"  v-if="membershipType=='Premium'">{{candidateData.users.fname}} 
        <!-- {{candidateData.user.idlname}} -->
        000{{(candidateData.users.id==19)?"0":candidateData.users.id}}
        </router-link>
        <router-link to="" style="color:white" v-else>{{candidateData.users.fname}} 
        <!-- {{candidateData.user.idlname}} -->
        000{{(candidateData.users.id==19)?"0":candidateData.users.id}}
        </router-link>
    </h3>
	<!-- <ul class="candidate_info">
		<li><i style="color:#cda53a;" class="fas fa-envelope"></i>{{candidateData.users.email}} </li>           
		<li><i style="color:#cda53a;" class="fas fa-phone"></i>{{candidateData.users.phone_no}}</li>
	</ul> -->

	</div>
    <div class="job_date">
       <div class="date"><span style="color:#cda53a;">Apply on:</span> {{moment(candidateData.created_at).startOf("month").format('MM')}} {{moment(candidateData.created_at).startOf("day").format('DD')}}, {{moment(candidateData.created_at).startOf("year").format('YYYY')}}</div>
        <div class="action_btnWpr">
            <button v-if="membershipType=='Premium'" type="button" class="sign_submit"><router-link :to="'/profile/' + candidateData.users.unique_user_id" style="color:white;cursor:pointer">View Profile</router-link></button>
            <button class="sign_submit" type="submit" v-on:click="execute(candidateData.id,candidateData.users.fname)">View Application</button>
            <button :disabled="buttonSaveClick" class="sign_submit" :id="'no_'+candidateData.id" v-on:click="saveCandidateForThisJob(candidateData.id,'yes')" :style="candidateData.is_short_list=='no'?'color:#fff;display: block;':'color:#fff;display: none;'"  title="Save">
                
                <span v-if="buttonSaveClick==true" style="color:white">Short Listing. . .</span>
                <span v-else style="color:white">Add To Short List</span>
            </button>
            <button :disabled="buttonDeleteClick" :id="'yes_'+candidateData.id" v-on:click="saveCandidateForThisJob(candidateData.id,'no')" :style="candidateData.is_short_list=='yes'?'color:#fff;display: block;':'color:#fff;display: none;'" class="sign_submit" title="Unsave">
                
                <span v-if="buttonDeleteClick==true" style="color:white">Deleting. . .</span>
                <span v-else style="color:white">Delete From Short List</span>
            </button>
		</div>  
    </div>
    <br>
    <!-- <div style="color: green;" class="displaymessage" :id="'messsage_'+candidateData.id"></div> -->
</li>
</template>

<script>
import moment from "moment";
import { createLocal } from 'the-storages'
import { API_IMAGE_PATH } from '../config'
const mirror = createLocal()
const storage = mirror._prx
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import axios from 'axios'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

export default{
    name: 'CommentCard',
    props:['candidateData', 'icon' ,'callback'],
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            imagepath:{},
            message:'',
            buttonSaveClick:false,
            buttonDeleteClick:false,
            membershipType:''
        }
    },
    created(){
        this.membershipType = mirror.MEMBERSHIP_TYPE;
        this.imagepath=API_IMAGE_PATH;
        this.userId=mirror.AUTHID;
        this.userType=mirror.USERTYPE;
        //console.log(this.imagepath);
    },
    methods:{
        async saveCandidateForThisJob(jobDetailId,status) {
            if(status=='yes'){
                this.buttonSaveClick = true;
            }
            if(status=='no'){
                this.buttonDeleteClick = true;
            }
            axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
                axios.post(API_BASE_URL +'setSortlistForThisJob', {
                    indexId: jobDetailId,
                    is_short_list: status
                }).then(response => {
                console.log(response.data)
                if(response.data.message=='success'){
                    var x = document.getElementById("no_"+jobDetailId);
                    var y = document.getElementById("yes_"+jobDetailId);
                    // document.getElementById('messsage_'+jobDetailId).innerHTML ='';
                    if(status=='yes'){
                        this.message='Candidate added in your shortlist.';
                        // document.getElementById('messsage_'+jobDetailId).innerHTML ='<p>'+this.message+'</p>';	
                        x.style.display = "none";
                        y.style.display = "block";
                        this.showToast("Success",this.message,"success"); 
                    } else {
                        this.message='Candidate deleted from shortlist.';
                        // document.getElementById('messsage_'+jobDetailId).innerHTML ='<p>'+this.message+'</p>';
                        x.style.display = "block";
                        y.style.display = "none";	
                        this.showToast("Success",this.message,"success"); 
                    }
                    this.buttonSaveClick = false;
                    this.buttonDeleteClick = false;
                    // setTimeout(() => {
                    //     this.message = '';
                    //     document.getElementById('messsage_'+jobDetailId).innerHTML ='';
                    //     console.log(this.message);
                    // }, 5000);
                }
                
                })
                .catch(error => {
                    console.error(error);
                });
                console.log(responses);
            });
            // setTimeout(() => {
            // this.message = '';
            // console.log(this.message);
            // }, 5000);
        },
        execute(test,name) {
			if (this.callback) {
				this.callback(test,name)
			}
		},
        showToast(title,description,type){
            createToast({
                title: title,
                description: description
            },
            {
                type: type,
                position: 'bottom-right',
                // hideProgressBar: 'true',
                // toastBackgroundColor: color,
            })
        },
    },
}
</script>
<style scoped>
.icon img{
    width: 100px;
    height: 85px;
    object-fit: cover;
}
.job_info{
    flex: 1 1 290px;
    padding-left: 30px;
}
.job_date{
    flex: 1 1 150px;
}
.job_info h3{
    font-size: 24px;
    line-height: 30px;
    color: #d9d9d9;
}
.job_info .job_title{
    font-size: 16px;
    line-height: 22px;
    color: #d9d9d9;
    margin-bottom:5px;
}
.job_info i{
    color: #c71e1e;
    margin-right: 7px;
    font-size: 16px;
}
.job_info .candidate_info{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}
.job_info .candidate_info li{
    padding: 5px 10px;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
}
.job_date{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.job_date .date{
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-top:10px;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #c71e1e;
}
.job_date .action_btnWpr{
    display:flex;
    flex-direction: row-reverse;
    padding-top: 4px;
}
.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
    flex: 0 0 38%;
}

@media(max-width:1199px){
    .job_info{
        padding-left:20px;
    }
    .job_info h3{
        font-size: 20px;
        line-height: 26px;
    }
}
@media(max-width:670px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .candidate_info{
        justify-content: center;
    }
    .job_date {
        align-items: center;
    }
    .icon{
        flex: 0 0 100%;
        text-align: center;
    }
}

</style>