<template>
    <!-- <div class="banner" :style="{ backgroundImage: 'url(' + require(`@/assets/images/banner/home-home-banner-main.png`) + ')' }"> -->
    <div class="banner" :style="{ backgroundImage: 'url(' + require(`@/assets/${bg}`) + ')' }">
        <div class="banner_cont">
         <!-- <img src='bg2' alt="" class="section_img"> -->
            <slot></slot>
        </div>
    </div>    
</template>
<script>
export default {
    name: 'Banner',
    props: ['bg','bg2']
}
</script>

<style>
.banner{
    background-size: cover;
    width: 100%;
    padding-top: 100px;
    background-position: top center;
    background-repeat: no-repeat;
}
.banner_cont{
    max-width: 1470px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
}
.banner_img{
    width: calc(100% - 420px);
    line-height: 0;
    padding-top: 70px;
}
.banner_img img{
    max-width: 100%;
    height: auto;
}
.cont_text{
    font-size: 33px;
    line-height: 45px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    align-self: center;
    padding-left: 20px;
    flex: 0 0 420px;
}
.cont_text .box{
    padding: 0 5px;
    font-weight: 700;
    color: #1f1f1f;
    background: #fff;
}
.cont_text .strong{
    font-weight: 700;
    position: relative;
    letter-spacing: 1px;
}
.cont_text .strong:after{
    position: absolute;
    content:'';
    top: 100%;
    left: 2px;
    right: 0;
    border-bottom:3px solid #c71e1e;
}

.figure{
    line-height: 0;
}
.brand{
    align-self: center;
}
.inr_text{
    align-self: center;
    text-align: center;
    position: relative;
}
.inr_text:before{
    position: absolute;
    content: '';
    width: 370px;
    height: 344px;
    background: url('~@/assets/circle.png');
    background-size: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 15px;
    margin-left: 30px;
}

.inr_text.blog:before{
    display:none;
    }
.inr_text.blog{
    text-align:center;
    width:100%;
    padding: 110px 0 70px;
    }
.inr_text.blog h3 {
    font-size: 35px;
    line-height: 50px;
    font-family: 'IRON MAN OF WAR 002 NCV';
    font-weight: normal;
    letter-spacing: 11px;
}
.inr_text.blog h4{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 20px;
    font-weight: 400;
    max-width: 600px;
    color:#e7e7e7;
    margin: 14px auto 0;
    line-height: 27px;
}
.inr_text.blog h4 span{color:#c61e1e;font-weight:600;}
.inr_text h1{
    font-size: 90px;
    line-height: 105px;
    font-family: 'IRON MAN OF WAR 002 NCV';
    font-weight: normal;
}
.inr_text h3{
    font-size: 58px;
    line-height: 70px;
    font-family: 'IRON MAN OF WAR 002 NCV';
    font-weight: normal;
}
.inr_text p{
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.8px;
}
.inr_text p span{
    color: #c71e1e;
}

@media(max-width:1199px){
    /*.inr_text{
        max-width: 500px;
    }*/
    .inr_text:before {
        width: 280px;
        height: 260px;
        margin-top: 13px;
        margin-left: 15px;
    }
    .inr_text h1{
        font-size: 60px;
        line-height: 70px;
    }
    .inr_text p{
        font-size: 14px;
        line-height: 18px;
    }
    
}
@media(max-width:1300px){
    .brand{
        display: none;
    }
}
@media(max-width:767px){
    .banner{
        /* overflow: hidden; */
        position: relative;
        padding-top:190px;
    }
    .figure{
        position: absolute;
        left: 50%;
        top: 80px;
        transform: translateX(-50%);
    }
    .inr_text.blog{
        padding: 0 0 40px;
    }
    .inr_text.blog h3 {
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 6px;
    }
    .inr_text.blog img{
        max-height: 60px;
        width: auto;
        max-width: 100%;
    }
    .cont_text{
        font-size: 27px;
        line-height: 35px;
        flex: 0 0 360px;
    }
    .banner_img {
        width: calc(100% - 360px);
    }
    .inr_text{
        padding: 0 15px 70px 0;
        flex: 1;
    }
    .inr_text:before{
        width: 230px;
        height: 217px;
        top: 30%;
    }
    .inr_text h3{
        font-size: 35px;
        line-height: 45px;
    }
    .inr_text h1 {
        font-size: 45px;
        line-height: 55px;
    }
}
@media(max-width:575px){
    .cont_text {
        font-size: 24px;
        line-height: 30px;
        flex: 0 0 320px;
        padding: 0;
    }
    .cont_text{
        padding-bottom: 50px;
    }
    .banner_img{
        display: none;
    }
    .inr_text:before {
        width: 235px;
        height: 217px;
        margin-top: 8px;
        margin-left: 25px;
    }
    .inr_text h3{
        font-size: 25px;
        line-height: 32px;
    }
    .inr_text h1 {
        font-size: 35px;
        line-height: 44px;
    }
}

</style>
