<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part">
    <h3>Chat</h3>
    <div >
        <div v-if="receiverId==''" class="candidate_list">
            <ul>
                <li >
                    <div class="icon" >No Data Found</div>
                </li>
            </ul>
        </div>
        <div v-else class="candidate_list">
            <ul>
                <li >
                    <div class="icon" >
                        <img v-bind:src="imagepath+receiverDetails.profile_image" v-bind:alt="receiverDetails.fname" style="width:100% !important;height:50px !important;">
                    </div>
                    <div class="job_info" >
                        <h3>{{receiverDetails.fname}} {{receiverDetails.lname}}</h3>
                        <div class="job_title" v-if="receiverDetails.login_history!=null" >
                            <span v-if="receiverDetails.login_history.login_status=='yes'"><i class="fas fa-circle" style="color:green;"></i> Online</span>
                            <span v-else><i class="fas fa-circle" style="color:red;"></i> Offline</span>
                        </div>
                        <div class="job_title" v-else ><span ><i class="fas fa-circle" style="color:red;"></i> Offline</span></div>
                    </div>
                    <div class="job_date" >    
                    </div>
                </li>
                <li v-for="chat in messages" v-bind:key="chat" >
                    
                        <div class="icon" v-if="chat.user.id==myId" >
                            
                        </div>
                        <div class="" v-if="chat.user.id==myId" >
                            <!-- <h3>{{chat.user.fname}} {{chat.user.lname}}</h3> -->
                            <div class="job_title" ><span >{{chat.message}}</span></div>
                            <div class="date">
                                <span>{{chat.created_at}}</span> 
                            </div>
                        </div>
                        
                    
                    <!-- ///////////////////////////////////////////// -->
                    
                        <div class="" v-if="chat.user.id!==myId" >
                            <!-- <h3>{{chat.user.fname}} {{chat.user.lname}}</h3> -->
                            <div class="job_title" ><span >{{chat.message}}</span></div>
                            <div class="date">
                                <span>{{chat.created_at}}</span> 
                            </div>
                        </div>
                        <div class="icon" v-if="chat.user.id!==myId">
                           
                        </div>
                    
                </li>
            </ul>
            <div class="" style="width:100%;" v-if="blockStataus!='Block'">
                <form v-on:submit.prevent="sendMessage">
                    <div class="" style="width:90%;float:left;" >
                        <input type="text" v-model="message" >
                    </div>
                    <div class="" style="width:10%;float:right;">
                        <button type="submit" style="width:100%;line-height:33px;" class="sign_submit">Send</button>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
</div>
</div>
</div>

</template>

<script>
//import mounted from 'vue';
import Pusher from 'pusher-js';

import axios from 'axios'
//import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import { createLocal } from 'the-storages'
import moment from "moment";
import { useRoute } from 'vue-router'
const mirror = createLocal()
const storage = mirror._prx
export default {
    name: 'Chat',
    components:{
        Dashboardheader,
        Dashboardsidebar,
    },
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            error: '',
            error2:'',
            is_show:false,
			nofound:false,
            is_loader:true,
            imagepath:'',
            message:'',
            messages:this.messages,
            receiverDetails:this.receiverDetails,
            myId:this.myId,
            receiverId:'',
            blockStataus:'',
            qParam:{
                receiverId:null,
                userId:null
            },
        }
    },
    mounted(){
        
    },
    async created(){
        const route=useRoute();
        this.myId = mirror.AUTHID;
        this.receiverId = route.params.receiverId;
        const qParam = {
            receiverId:this.receiverId,
            userId:this.myId
        }
        console.log("AUTH:",mirror)
        this.fetchMessage(qParam);
        this.imagepath = API_IMAGE_PATH;
        
        Pusher.logToConsole = true;
        const pusher = new Pusher('8a7bddea661316a2226c', {
            cluster: 'ap2',
            forceTLS: true,
            authEndpoint: "http://executiveprotectionlifestyle.epspecialist.com/admin/api/pusher/auth"
        });
        
        const channel = pusher.subscribe('private-chat.'+this.myId);
        channel.bind('message', data => {
            console.log("DATA-COMING: ",data.message);
            this.messages.push(data.message);
            //alert(JSON.stringify(data.message));
            setTimeout(this.scrollToEnd,100);
        });

        const paramBlock    = {
            receiverId:mirror.AUTHID,
            userId:this.receiverId
        }
        console.log("SingelData: ",paramBlock);
        const responseBlock= await axios.post(API_BASE_URL + 'blockUnblockCheckingAuth',paramBlock);
        this.blockStataus = responseBlock.data.blockStataus;
        console.log("B Details : ",responseBlock.data);
    },
    methods:{
        async sendMessage() {
            // Pusher.logToConsole = true;
            // const pusher = new Pusher('8a7bddea661316a2226c', {
            //     cluster: 'ap2'
            // });
            //const channel = pusher.subscribe('chat');
            
            if (this.message ) {
                const param    = {
                    userId:mirror.AUTHID,
                    receiverId :this.receiverId,
                    message:this.message
                }
                //console.log("param",param);
                await axios.post(API_BASE_URL + 'messagess', param)
                .then(response => {                     	
                    //console.log("Send MEETING: ",response.data.chat);
                    const datas = response.data;
                    console.log(datas);
                    this.messages.push(datas.chat);
                    this.message = '';
                    //console.log("CHAT-LIST: ",this.messages);
                    //this.fetchMessage();
                    

                    // privateChannel.bind('message', data => {
                    //     console.log("DATA-COMING: ",data.message);
                    //     this.messages.push(data.message);
                    //     //alert(JSON.stringify(data.message));
                        
                    // });
                    
                    setTimeout(this.scrollToEnd,100);
                })
                .catch(error => {
                    console.error(error);
                });
            } else {
                this.errormessage = 'Message Fields are Mandatory';
            }
        },
        
        async fetchMessage(qParam) {
            await axios.post(API_BASE_URL + 'private-messagess',qParam)
            .then(response => {                     	
                console.log("MEETING List: ",response);
                this.messages = response.data.messageData;
                this.receiverDetails = response.data.receiverData;
                //this.messages = [];
            })
            .catch(error => {
                console.error(error);
            });
        },
        scrollToEnd(){
            window.scrollTo(0,99999);
        },
	}
}

</script>

<style scoped>
.date {
    font-size: 10px;
    color: red;
}
.modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
}
.candidate_list ul{
    margin-top: 20px;
    background: #28282b;
    
}
.candidate_list ul li{
    padding: 20px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
<style scoped>
.icon img{
    width: 100px;
    height: 85px;
    object-fit: cover;
}
.job_info{
    flex: 1 1 290px;
    padding-left: 30px;
}
.job_date{
    flex: 1 1 150px;
}
.job_info h3{
    font-size: 24px;
    line-height: 30px;
    color: #d9d9d9;
}
.job_info .job_title{
    font-size: 16px;
    line-height: 22px;
    color: #d9d9d9;
    margin-bottom:5px;
}
.job_info i{
    color: #c71e1e;
    margin-right: 7px;
    font-size: 16px;
}
.job_info .candidate_info{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}
.job_info .candidate_info li{
    padding: 5px 10px;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
}
.job_date{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.job_date .date{
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-top:10px;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #c71e1e;
}
.job_date .action_btnWpr{
    display:flex;
    flex-direction: row-reverse;
    padding-top: 4px;
}
.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
}

@media(max-width:1199px){
    .job_info{
        padding-left:20px;
    }
    .job_info h3{
        font-size: 20px;
        line-height: 26px;
    }
}
@media(max-width:670px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .candidate_info{
        justify-content: center;
    }
    .job_date {
        align-items: center;
    }
    .icon{
        flex: 0 0 100%;
        text-align: center;
    }
}

</style>