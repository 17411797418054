<template>
  <div class="left-prt dashboard" id="mySidenav">
    <div class="list-menu">
      <ul>
        <li>
          <router-link to="/dashboard"
            ><img src="@/assets/dash/icon1.png" alt="" /> Dashboard</router-link
          >
        </li>

        <li v-if="storage.USERTYPE == 2">
          <router-link to="/agents"
            ><img src="@/assets/dash/icon3.png" alt="" /> Agents</router-link
          >
        </li>
        <li v-if="storage.USERTYPE == 2">
          <router-link to="/candidates"
            ><img src="@/assets/dash/icon3.png" alt="" /> Shortlisted Candidates</router-link
          >
        </li>
        <li v-if="storage.USERTYPE == 2">
          <router-link to="/jobList"
            ><img src="@/assets/dash/job.png" alt="" /> Jobs</router-link
          >
        </li>
        <li v-if="storage.USERTYPE != 2">
          <router-link to="/jobs"
            ><img src="@/assets/dash/job.png" alt="" /> Jobs</router-link
          >
        </li>
        <li v-if="storage.USERTYPE == 3 && membershipType == 'Premium'">
          <router-link to="/savedJobs"
            ><img src="@/assets/dash/job.png" alt="" />Save Jobs</router-link
          >
        </li>
        <li v-if="storage.USERTYPE == 3 && membershipType == 'Premium'">
          <router-link to="/appliedJobs"
            ><img src="@/assets/dash/icon4.png" alt="" />Applied Jobs</router-link
          >
        </li>
        <li v-if="storage.USERTYPE == 2">
          <router-link to="/post-job"
            ><img src="@/assets/dash/icon4.png" alt="" /> Post Jobs</router-link
          >
        </li>
        <li>
          <router-link to="/dashboard-profile"
            ><img src="@/assets/dash/profile_view.png" alt="" /> Profile
            View</router-link
          >
        </li>
        <li>
          <router-link to="/settings"
            ><img src="@/assets/dash/profile_edit.png" alt="" /> Profile
            Settings</router-link
          >
        </li>
        <li>
          <router-link to="/membership"
            ><img src="@/assets/dash/icon7.png" alt="" /> Membership</router-link
          >
        </li>

        <li>
          <router-link to="/changepassword"
            ><img src="@/assets/dash/icon8.png" alt="" /> Change
            password</router-link
          >
        </li>

        <li >
          <router-link to="/events"
            ><img src="@/assets/dash/icon5.png" alt="" /> Events</router-link
          >
        </li>
        <li >
          <router-link to="/live-events"
            ><img src="@/assets/dash/live.png" alt="" /> Live
            Events</router-link
          >
        </li>
        <li >
          <router-link to="/chat-list"
            ><img style="    width: 33px;"
              src="@/assets/dash/message-icon.png" alt="" /> Chat
            <span
              v-if="chatCount != ''"
              style="
                background: red;
                padding: 5px;
                border-radius: 50%;
                font-size: 12px;
              "
              >{{ chatCount }}</span
            >
          </router-link>
        </li>
        <li v-if="membershipType == 'Premium'">
          <router-link to="/group-chat"
            ><img style="    width: 33px;"
              src="@/assets/dash/message-icon.png" alt="" />
              League Group Chat</router-link
          >
        </li>
        <li>
          <a href="#" title="Logout" @click.prevent="logout()" class="login"
            ><img src="@/assets/dash/logout.png" alt="" /> Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { createLocal } from "the-storages";
import { BASE_URL } from "../config";
import { API_IMAGE_PATH } from "../config";
import axios from "axios";
const mirror = createLocal();
const storage = mirror._prx;
export default {
  name: "Dashboardsidebar",
  data() {
    return {
      scrollPosition: null,
      isBtn: false,
      imagepath: "",
      storageData: mirror,
      storage: storage,
      chatCount: "",
      membershipType: "",
    };
  },
  methods: {
    logout() {
      axios.get(BASE_URL + "sanctum/csrf-cookie").then((response) => {
        axios
          .post(BASE_URL + "api/logout")
          .then((response) => {
            if (response.data.success) {
              console.log("dsfdsfsd" + response.data);
              //storage.removeItem('AUTHID', true)
              storage.removeItem("NAME", true);
              storage.removeItem("USEREMAIL", true);
              storage.removeItem("USERTYPE", true);
              storage.removeItem("TOKEN", true);
              storage.removeItem("PROFILEIMAGE", true);
              storage.removeItem("DESIGNATION", true);
              storage.clear();
              this.storage.set("AUTHID", "");
              this.$router.push({ name: "Home" });
            }
          })
          .catch((error) => {
            console.error(error);
          });
        console.warn(response);
      });
    },
  },
  async created() {
    this.membershipType = mirror.MEMBERSHIP_TYPE;
    this.imagepath = API_IMAGE_PATH;
    if (mirror.AUTHID) {
      axios.get(BASE_URL + "sanctum/csrf-cookie").then((response) => {
        axios
          .post(BASE_URL + "api/countChat", { userId: mirror.AUTHID })
          .then((response) => {
            if (response.data.success) {
              console.log("Chat Details", response.data);
              this.chatCount = response.data.chatCount;
             
            }
          })
          .catch((error) => {
            console.error(error);
            
          });
        console.warn(response);
      });
    } else {
      console.log("User Not Found");
    }
  },
};
</script>

<style>

.dashboard_main {
  /* background-image: url("../eps new/eps/src/assets/EP-image.png"); */
  
  /* url("/img/banner1.a65c51d3.png") */
  height: 100vh;
  background: #161618;
}




.backgroundImages {
    background-image: url(/img/EP-image.a946b151.png);
    width: 330px;
    position: absolute;
    top: 22px;
    right: 0px;
    z-index: 0;
    height: 794px;
    overflow: hidden;
    opacity: 0.6;
}





.middle-part.dashboard .text-align-center {
  text-align: center;
}
.middle-part.dashboard {
  display: flex;
  padding: 0;
  background: #202023;
  width: 100%;
  height: calc(100vh - 88px);
  position: relative;
}
.middle-part.dashboard .rigt-part {
  height: 100%;
  overflow-y: scroll;
}
.middle-part.dashboard .rigt-part::-webkit-scrollbar {
  width: 6px;
  background: rgba(255, 255, 255, 0.05);
}
.middle-part.dashboard .rigt-part::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 3px;
}
.middle-part.dashboard .left-prt {
  width: 300px;
  background: #161618;
  height: 100%;
  position: sticky;
  left: 0;
  top: 0;
  overflow-y: scroll;
}
.middle-part.dashboard .left-prt::-webkit-scrollbar {
  width: 4px;
  background: #202023;
}
.middle-part.dashboard .left-prt::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 2px;
}
.middle-part.dashboard .left-prt h3 {
  text-transform: uppercase;
}
.middle-part.dashboard .left-prt p {
  font-size: 16px;
  line-height: 26px;
  color: #787879;
}
.middle-part.dashboard .admin-img {
  width: 105px;
  height: 105px;
  border-radius: 100%;
  margin: 0 auto 16px;
}
.middle-part.dashboard .admin-img img {
  width: 105px;
  height: 105px;
  object-fit: cover;
  border-radius: 110px;
}
@media (min-width: 1200px) {
  .middle-part.dashboard #mySidenav {
    transition: all 0.3s ease-in-out;
  }
  .middle-part.dashboard #mySidenav.mini li a {
    width: 0;
    padding-left: 67px;
  }
  .middle-part.dashboard #mySidenav.mini ul li a {
    font-size: 0;
  }
  .middle-part.dashboard #mySidenav.mini li img {
    left: 14px;
    transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -webkit-transition: all 500ms ease-in-out;
  }
  .middle-part.dashboard #mySidenav.mini {
    width: 75px;
  }
  .middle-part.dashboard .rigt-part {
    padding: 40px;
    width: calc(100% - 300px);
    transition: all 0.3s ease-in-out;
  }
  .middle-part.dashboard #mySidenav.mini + .rigt-part {
    width: calc(100% - 75px);
  }
}
@media (max-width: 1199px) {
  .middle-part.dashboard .rigt-part {
    width: 100%;
    padding: 40px 20px;
  }
  .middle-part.dashboard #mySidenav {
    position: fixed;
    top: 81px;
    left: -300px;
    transition: all 0.3s ease-in-out;
    height: calc(100vh - 81px);
    z-index: 10;
  }
  .middle-part.dashboard #mySidenav.mini {
    left: 0;
  }
}
@media (max-width: 767px) {
  .middle-part.dashboard .rigt-part {
    padding: 20px;
  }
}
@media (max-width: 575px) {
  .middle-part.dashboard #mySidenav {
    top: auto;
    height: calc(100% - 66px);
  }
  .middle-part.dashboard {
    height: calc(100vh - 66px);
  }
}
.middle-part.dashboard .list-menu li {
  position: relative;
}
.middle-part.dashboard .list-menu li + li {
  border-top: solid 1px #333333;
}
.middle-part.dashboard .list-menu li img {
  position: absolute;
  left: 30px;
  top: 18px;
  width: 40px;
  transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
}
.middle-part.dashboard .list-menu li a {
  font: 400 18px/65px "Play", sans-serif;
  color: #b9b9ba;
  display: block;
  padding-left: 80px;
  transition: 0.3 ease-in-out;
  border-left: solid 4px transparent;
  width: 100%;
}
.middle-part.dashboard .list-menu li:hover a,
.middle-part.dashboard .list-menu li a.router-link-active {
  background: #202023;
  border-left: solid 4px #b81d1d;
}

.middle-part.dashboard .rigt-part h3 {
  font-size: 24px;
}

.middle-part.dashboard .gt-started-frm {
  background: #28282b;
  padding: 50px;
  border: solid 1px #1e1e20;
  box-shadow: 0 0 6px rgba(0, 0, 0, o.5);
  margin: 20px 0 0;
  overflow: hidden;
  min-height: 700px;
}
.middle-part.dashboard .rigt-part label {
  width: 100%;
  display: block;
  padding: 6px 0 4px;
}
.middle-part.dashboard .rigt-part input[type="text"],
.rigt-part input[type="tel"],
.rigt-part input[type="email"],
.rigt-part input[type="date"],
.rigt-part input[type="password"],
.rigt-part input[type="url"] {
  width: 100%;
  background: #212122;
  border: solid 1px #3d3d3d;
  height: 45px;
  font-size: 14px;
  line-height: 45px;
  margin: 3px 0 0;
  color: #818182;
  padding: 10px;
}
.middle-part.dashboard .rigt-part select {
  width: 100%;
  background: #212122;
  border: solid 1px #3d3d3d;
  height: 45px;
  font-size: 14px;
  line-height: 45px;
  margin: 3px 0 0;
  color: #818182;
  text-indent: 2px;
  padding: 0 15px;
}
.middle-part.dashboard .rigt-part input[type="submit"],
.rigt-part input[type="button"] {
  background: #c71e1e;
  font-size: 15px;
  line-height: 51px;
  letter-spacing: 1px;
  border: none;
  padding: 0 30px;
  color: #fff;
  margin: 10px 0 0;
  cursor: pointer;
  text-transform: uppercase;
}
.middle-part.dashboard .rigt-part textarea {
  width: 100%;
  background: #212122;
  border: solid 1px #3d3d3d;
  height: 100px;
  font-size: 14px;
  line-height: 17px;
  margin: 15px 0 0;
  color: #818182;
  padding: 10px;
}
.middle-part.dashboard .rigt-part input.back-btn {
  border: solid 1px #ffffff;
  font-size: 15px;
  line-height: 51px;
  letter-spacing: 1px;
  padding: 0 34px;
  color: #fff;
  margin: 10px 10px 0 0;
  background: none;
  display: inline-block;
  height: 51px;
  cursor: pointer;
}
.middle-part.dashboard .tw-clm {
  flex: 1 1 50%;
  padding: 10px 15px;
}
.middle-part.dashboard .rw-gap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.middle-part.dashboard .map-text {
  background: #2f2f32;
  padding: 10px;
}
.middle-part.dashboard .rigt-part h4 {
  font-size: 23px;
  line-height: 28px;
  padding: 20px 0 0;
}
.middle-part.dashboard .three-clm {
  flex: 1 1 33.333%;
  padding: 10px 15px;
}
.middle-part.dashboard .to-text {
  position: relative;
}
.middle-part.dashboard .to-text:before {
  position: absolute;
  right: -18px;
  top: 50%;
  font-size: 15px;
  color: #fff;
  line-height: 17px;
  content: "to";
}
.middle-part.dashboard .rigt-part p {
  padding-top: 10px;
}
.middle-part.dashboard .rigt-part p.crncy {
  padding-top: 50px;
}
.middle-part.dashboard .pymnt-detls {
  background: #2e2e30;
  padding: 25px;
}
.middle-part.dashboard .brdr-btm {
  border-top: solid 1px #3d3d3d;
  margin: 20px 0 0;
}

/*******************Agent List********************/
.search-box {
  float: left;
  width: 24.2%;
  margin: 0 0 0 1%;
}
div::after {
  clear: both;
  visibility: initial;
  display: block;
      color: white;
    font-size: 20px !important;
}
.search-box:nth-child(4n + 1) {
  margin-left: 0;
}
.srch-prt {
  padding: 22px 0 0;
}
.middle-part.dashboard .rigt-part h3 span {
  font-size: 10px;
  background: #947f08;
  padding: 0 8px;
  border-radius: 15px;
  line-height: 18px;
  display: inline-block;
  margin-left: 40px;
}
.middle-part.dashboard .rigt-part h3 span i {
  color: rgba(255, 255, 255, 0.6);
  font-size: 10px;
  margin: 0;
}

/*******************edit profile********************/
.middle-part.dashboard .rigt-part .tabinfo h4 {
  padding: 0 0 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
}
.middle-part.dashboard .tabbtnn_sec.edit_prop {
  margin: 30px 0 15px;
}
.middle-part.dashboard .tabbtnn_sec.edit_prop ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.middle-part.dashboard .tabbtnn_sec.edit_prop ul li {
  padding: 5px;
}
.middle-part.dashboard .tabbtnn_sec.edit_prop ul li button {
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #999;
  background: none;
  color: #f6f6f6;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.middle-part.dashboard .tabbtnn_sec.edit_prop ul li button.active {
  color: #fff;
  border: 1px solid #dd1e1e;
  background: #dd1e1e;
}

.middle-part.dashboard .rigt-part .headline {
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
}
.middle-part.dashboard .rigt-part .circle-big {
  position: relative;
  width: 114px;
  height: 114px;
  margin: 30px auto 25px;
}
.middle-part.dashboard .rigt-part .circle-big svg {
  width: 114px;
  height: 114px;
  transform: scale(1.2);
}
.middle-part.dashboard .rigt-part .circle-big .bg {
  fill: none;
  stroke-width: 7px;
  stroke: #535355;
}
.middle-part.dashboard .rigt-part .circle-big .progress {
  fill: none;
  stroke-width: 7px;
  stroke-linecap: round;
  stroke-dasharray: 326.56;
  stroke-dashoffset: 60;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  -webkit-animation: big 1.5s ease-in-out;
  animation: big 1.5s ease-in-out;
}
.middle-part.dashboard .rigt-part .circle-big .text {
  position: absolute;
  width: 100%;
  top: 16px;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  line-height: 22px;
}
.middle-part.dashboard .rigt-part .circle-big.one .progress {
  stroke: #c30b0b;
}
.middle-part.dashboard .rigt-part .circle-big.two .progress {
  stroke: #e1a531;
}
.middle-part.dashboard .rigt-part .circle-big.three .progress {
  stroke: #377ee8;
}
.middle-part.dashboard .rigt-part .circle-big.four .progress {
  stroke: #5fb23b;
}

@-webkit-keyframes big {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 60;
  }
}
@keyframes big {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 60;
  }
}
@-webkit-keyframes one {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 80;
  }
}
@keyframes one {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 80;
  }
}
@-webkit-keyframes two {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 140;
  }
}
@keyframes two {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 140;
  }
}
@-webkit-keyframes appear {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appear {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.box-total {
    position: relative;
    z-index: 5;
}
.middle-part.dashboard .rigt-part .table-box {
    position: relative;
    z-index: 5;
}
.middle-part.dashboard .rigt-part {
    position: relative;
    z-index: 1;
}
.middle-part.dashboard .rigt-part .box-total .box {
  width: 22.7%;
  float: left;
  background: #28282b;
  padding: 26px 0;
  text-align: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  margin: 0 0 30px 3%;
}
.middle-part.dashboard .rigt-part .box-total .box:nth-child(4n + 1) {
  margin-left: 0;
  clear: left;
}
.middle-part.dashboard .rigt-part .box-total .box p {
  color: #777;
}
.middle-part.dashboard .rigt-part .box h4 {
  color: #e2e2e2;
  padding: 5px 0 0;
  font-weight: 400;
}
.middle-part.dashboard .rigt-part .table-header {
  padding: 30px 20px;
  background: #28282b;
  float: left;
  width: 100%;
}
.middle-part.dashboard .rigt-part .table-thirty {
  float: left;
  width: 27%;
}
.middle-part.dashboard .rigt-part .table-seventy {
  float: left;
  width: 73%;
}
.middle-part.dashboard .rigt-part .table-thirty h3 {
  float: left;
  font-weight: 400;
  color: #a7a7a8;
}
.middle-part.dashboard .rigt-part .table-seventy ul li {
  list-style-type: none;
  float: left;
  position: relative;
}
.middle-part.dashboard .rigt-part .table-seventy ul li button {
  font-size: 23px;
  line-height: 26px;
  padding: 0 27px;
  color: #a7a7a8;
  display: inline-block;
  background: none;
  font-family: "Play", sans-serif;
  cursor: pointer;
}
.middle-part.dashboard .rigt-part .table-seventy ul li button:after {
  content: "";
  width: 100%;
  bottom: -30px;
  left: 0;
  height: 2px;
  background: none;
  position: absolute;
}
.middle-part.dashboard .rigt-part .table-seventy ul li button.active {
  color: #fff;
}
.middle-part.dashboard .rigt-part .table-seventy ul li button.active:after {
  background: #b81d1d;
}
.middle-part.dashboard .rigt-part .table-seventy ul {
  float: right;
}
.middle-part.dashboard .rigt-part .table-thirty a {
  padding-top: 5px;
  color: #a62425;
  border-bottom: solid 1px #a62425;
  margin-left: 20px;
}
.middle-part.dashboard .rigt-part .candidate-image {
  width: 54px;
  height: 54px;
}
.middle-part.dashboard .rigt-part .candidate-image img{
  object-fit: cover;
}
.middle-part.dashboard .rigt-part .candidate-image {
  float: left;
  margin-right: 10px;
}
.middle-part.dashboard .rigt-part .candidate-text {
  float: left;
}
.middle-part.dashboard .rigt-part .fifty-tbl p {
  color: #e1a531;
}
.middle-part.dashboard .rigt-part .active-btn {
  border: solid 1px #a7a7a8;
  font-size: 14px;
  line-height: 36px;
  color: #a7a7a8;
  padding: 0 20px;
  display: inline-block;
  text-transform: uppercase;
  margin-left: 15px;
}
.middle-part.dashboard .rigt-part .schdule-btn {
  border: solid 1px #a7a7a8;
  font-size: 13px;
  line-height: 36px;
  color: #a7a7a8;
  padding: 0 7px;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 5px;
}
.middle-part.dashboard .rigt-part .schdule-btn + .schdule-btn {
  background: #b01d1e;
  border: solid 1px #b01d1e;
  color: #fff;
}
.middle-part.dashboard .rigt-part .table-box table {
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  width: 100%;
}
.middle-part.dashboard .rigt-part .table-box table tr {
  padding: 0.35em;
  background: #2f2f32;
}
.middle-part.dashboard .rigt-part .table-box table tr {
  color: #a7a7a8;
  background: #1c1c1e;
}

.middle-part.dashboard .rigt-part .table-box table thead {
  background: #1c1c1e;
  padding: 20px 0;
}
.middle-part.dashboard .rigt-part .table-box table th,
.table-box table td {
  padding: 10px;
}
.middle-part.dashboard .rigt-part .table-box table th {
  font-size: 21px;
  text-align: left;
  font-weight: 400;
  color: #d9d9d9;
  padding: 10px;
  white-space: nowrap;
}
.middle-part.dashboard .rigt-part .fifty-tbl {
  float: left;
  width: 40%;
  padding-right: 7px;
}
.middle-part.dashboard .rigt-part .fifty-tbl + .fifty-tbl {
  width: 60%;
}
.middle-part.dashboard .rigt-part .rigt-part .table-box table h4 {
  font-size: 17px;
  line-height: 28px;
  padding: 0;
  font-weight: 400;
  color: #d9d9d9;
}
.middle-part.dashboard .rigt-part .table-box table tr h4 + p {
  color: #ccc;
  font-size: 13px;
}
.middle-part.dashboard .rigt-part .rigt-part .table-box table p {
  padding: 0;
  color: #a7a7a8;
}
.middle-part.dashboard .rigt-part .table-box table .fifty-tbl p {
  color: #84662e;
  font-size: 13px;
}
.middle-part.dashboard .rigt-part .table-box table tbody tr:nth-child(odd) {
  background: #28282b;
}
.middle-part.dashboard .rigt-part .table-box {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.middle-part.dashboard .rigt-part .table-box h4 {
  font-size: 18px;
  line-height: 14px;
  padding: 0;
  font-weight: 500;
  color: #ccc;
  
}
.candidate-sec-table{
  display: flex;
}
.middle-part.dashboard .rigt-part .table-box table tr h4 + p,
.middle-part.dashboard .rigt-part .table-box h4{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.middle-part.dashboard .rigt-part .pagination ul {
  float: right;
  margin: 30px 0 0;
}
.middle-part.dashboard .rigt-part .pagination ul li {
  float: left;
  list-style-type: none;
}
.middle-part.dashboard .rigt-part .pagination ul li a {
  font-size: 14px;
  line-height: 30px;
  padding: 0 10px;
  display: inline-block;
  margin: 0 0 0 5px;
  border: solid 1px #777;
  color: #fff;
}
.middle-part.dashboard .rigt-part .pagination ul li:hover a,
.pagination ul li.active a {
  background: #c61e1e;
}

@media screen and (max-width: 600px) {
  .middle-part.dashboard .rigt-part .table-box table thead {
    display: none;
  }
  .candidate-sec-table {
    display: block;
    text-align: center;
  }
  .middle-part.dashboard .rigt-part .table-box table tr h4 + p,
  .middle-part.dashboard .rigt-part .table-box h4{
    max-width: initial;
  }
  .middle-part.dashboard .rigt-part .table-box table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
  .middle-part.dashboard .rigt-part .table-box table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: center;
  }
  .middle-part.dashboard .rigt-part .table-box table td:before {
    content: attr(data-label);
    float: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    color: #e7e7e7;
  }
  .middle-part.dashboard .rigt-part .table-box table td:last-child {
    border-bottom: 0;
  }
  .middle-part.dashboard .rigt-part .box {
    width: 100%;
    float: none;
    margin: 10px 0 0;
  }
  .middle-part.dashboard .rigt-part .candidate-image {
    float: none;
    margin: 10px auto;
    display: table;
  }
  .middle-part.dashboard .rigt-part .fifty-tbl {
    float: none;
    width: 100%;
  }
  .middle-part.dashboard .rigt-part .table-box table th,
  .table-box table td {
    padding: 10px;
  }
  .middle-part.dashboard .rigt-part .rigt-part .table-box table h4 {
    font-size: 15px;
    line-height: 20px;
  }
  .middle-part.dashboard .rigt-part .schdule-btn {
    margin-bottom: 10px;
    margin-left: 15px;
  }
}
.middle-part.dashboard .rigt-part.cd h5 {
  font-size: 32px;
  line-height: 40px;
  color: #e2e2e2;
  float: left;
  width: 100%;
  margin: 30px 0 5px;
  font-weight: 400;
}

.middle-part.dashboard .rigt-part.cd .candidate-sec .browse-job {
  background: #2d2d30;
  padding: 20px;
  color: #d9d9d9;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .fifty-candidate {
  float: left;
  width: 48.5%;
  margin: 0 0 0 3%;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec {
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}
.middle-part.dashboard
  .rigt-part.cd
  .candidate-sec
  .fifty-candidate:nth-child(2n + 1) {
  margin-left: 0;
  clear: left;
}
.middle-part.dashboard
  .rigt-part.cd
  .candidate-sec
  .fifty-candidate.browse-job
  h2 {
  font-size: 38px;
  line-height: 45px;
  color: #d9d9d9;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .browse-job h4 {
  font-weight: 400;
  padding-top: 0;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .browse-job h4 span {
  color: #c61e1e;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec p {
  padding-top: 10px;
  color: #969698;
  font-size: 15px;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .srch-btm {
  padding: 25px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .search-btn {
  font-size: 17px;
  line-height: 38px;
  padding: 0 30px;
  display: inline-block;
  margin: 15px 15px 0 0;
  background: #b01d1e;
  color: #fff;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .prfl-total {
  display: flex;
  align-items: flex-end;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .prfl-box {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  overflow: hidden;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .prfl-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.middle-part.dashboard .rigt-part.cd .candidate-sec .plus {
  display: inline-block;
  line-height: 0;
}
.middle-part.dashboard .rigt-part.cd .famly-img {
  width: 100%;
  min-height: 324px;
  height: 100%;
  background-size: cover !important;
  position: relative;
  background-position: 23% !important;
  background: url(~@/assets/dash/apply-traing-img.jpg) no-repeat;
}

.middle-part.dashboard .rigt-part.cd .prodcast_slider {
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
  width: 100%;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .stroke_clip {
  width: calc(100% - 75px);
  float: right;
  height: 2px;
  position: relative;
  margin: -18px 0 10px;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .stroke_clip:after,
.middle-part.dashboard .rigt-part.cd .prodcast_slider .stroke_clip:before {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  content: "";
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .stroke_clip:before {
  top: 0;
  background: #0a0a0c;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .stroke_clip:after {
  bottom: 0;
  background: #2f3338;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-prev,
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-next {
  position: absolute !important;
  bottom: -5px !important;
  left: 0 !important;
  top: auto;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-next {
  left: 30px !important;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-prev:after,
.middle-part.dashboard
  .rigt-part.cd
  .prodcast_slider
  .swiper-button-next:after {
  font-size: 16px;
  color: #ccc;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-container {
  padding-bottom: 70px;
}

.middle-part.dashboard .rigt-part.cd .recent_aj {
  margin: 20px 0;
  float: left;
  width: 100%;
}
.middle-part.dashboard .rigt-part.cd .tble-rw {
  background: #28282b;
  padding: 7px;
  margin: 10px 0 0;
  float: left;
  width: 100%;
}
.middle-part.dashboard .rigt-part.cd .tbl-rw-fifty {
  width: 60%;
  float: left;
}
.middle-part.dashboard .rigt-part.cd .tbl-rw-fifty:nth-child(2) {
  width: 400px;
  float: right;
}
.middle-part.dashboard .rigt-part.cd .tbl-brand {
  float: left;
  width: 83px;
  height: 78px;
  background: #202022;
  text-align: center;
}
.middle-part.dashboard .rigt-part.cd .tbl-text {
  float: left;
  padding-left: 18px;
  padding-top: 7px;
}
.middle-part.dashboard .rigt-part.cd .tble-rw h4 {
  font-size: 19px;
  line-height: 28px;
  padding: 5px 0 0;
  font-weight: 400;
  color: #d9d9d9;
}
.middle-part.dashboard .rigt-part.cd .tble-rw .rw-three h4 {
  font-size: 14px;
  line-height: 28px;
  padding: 5px 0 0;
  font-weight: 400;
  color: #d9d9d9;
}
.middle-part.dashboard .rigt-part.cd .tble-rw p {
  padding: 0;
}
.middle-part.dashboard .rigt-part.cd .tble-rw p span {
  color: #c61e1e;
}
.middle-part.dashboard .rigt-part.cd .rw-three {
  width: 33.333%;
  float: left;
  text-transform: uppercase;
}
.middle-part.dashboard .rigt-part.cd .rw-rt {
  float: right;
  width: 100%;
  padding: 20px 0 0;
}
.middle-part.dashboard .rigt-part.cd .svd-us {
  display: inline-block;
  font-size: 14px;
  line-height: 35px;
  border: solid 1px #999;
  padding: 0 20px;
  text-transform: uppercase;
  color: #ccc;
}
.middle-part.dashboard .rigt-part.cd .apply-us {
  display: inline-block;
  font-size: 14px;
  line-height: 35px;
  border: solid 1px #414143;
  padding: 0 20px;
  text-transform: uppercase;
  color: #fff;
  background: #414143;
}
.middle-part.dashboard .rigt-part.cd .total-sec {
  padding: 30px 0 0;
}

.middle-part.dashboard .rigt-part.cd .bottom_cd {
  margin: 40px -15px 0 -15px;
  display: flex;
  flex-wrap: wrap;
  clear: both;
}
.middle-part.dashboard .rigt-part.cd .thirty-three {
  width: 33.333%;
  padding: 15px;
}
.middle-part.dashboard .rigt-part.cd .thirty-three .blog_wpr {
  background: #28282b;
  height: 100%;
}
.middle-part.dashboard .rigt-part.cd .thirty-three-text {
  padding: 20px;
}
.middle-part.dashboard .rigt-part.cd .thirty-three-text h4 {
  font-size: 20px;
  line-height: 28px;
  padding: 20px 0 0;
  font-weight: 400;
  color: #fff;
}
.middle-part.dashboard .rigt-part.cd .tbl-brand img {
  margin: 10px 0 0;
}
.middle-part.dashboard .rigt-part.cd .thirty-three-text p {
  padding: 0;
}
.middle-part.dashboard .rigt-part.cd .thirty-three-text p span {
  color: #c61e1e;
}
.middle-part.dashboard .rigt-part.cd p.sub-text {
  color: #999;
  padding: 4px 0 0;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
}
.middle-part.dashboard .rigt-part.cd p.sub-text strong {
  color: #fff;
  font-weight: 400;
}
.middle-part.dashboard .rigt-part.cd .mb-4 {
  margin-bottom: 40px;
}
.middle-part.dashboard .rigt-part.cd .item {
  margin-bottom: 30px;
}
/*.middle-part.dashboard .rigt-part.cd .famly-img{ width:100%; height:324px; background-size:cover !important; position:relative; background-position: 23% !important;}*/
.middle-part.dashboard .rigt-part.cd .family-text {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.middle-part.dashboard .rigt-part.cd .family-text > div {
  float: right;
  margin: 0 30px 0 0;
}
.middle-part.dashboard .rigt-part.cd .family-text .search-btn {
  display: table;
  text-transform: uppercase;
}
.middle-part.dashboard .rigt-part.cd .thirty-three a {
  line-height: 0;
  display: block;
}
.middle-part.dashboard .rigt-part.cd .thirty-three-text a {
  display: inline-block;
  line-height: initial;
  color: inherit;
}
.middle-part.dashboard .rigt-part.cd .thirty-three a img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .middle-part.dashboard .rigt-part.cd .thirty-three {
    width: 50%;
  }
  .middle-part.dashboard .rigt-part.cd .candidate-sec .fifty-candidate {
    width: 100%;
    margin: 0;
  }
  .middle-part.dashboard
    .rigt-part.cd
    .candidate-sec
    .fifty-candidate.browse-job {
    margin-bottom: 30px;
  }
  .middle-part.dashboard .tabbtnn_sec.edit_prop ul li {
    flex: 1 0 auto;
    max-width: 200px;
  }
  .middle-part.dashboard .tabbtnn_sec.edit_prop ul li button {
    padding: 9px 12px;
    font-size: 12px;
    width: 100%;
  }
  .middle-part.dashboard .gt-started-frm {
    padding: 30px;
  }
  .middle-part.dashboard .rigt-part .table-seventy ul li button {
    padding: 0 10px;
    font-size: 17px;
  }
  .middle-part.dashboard .rigt-part .fifty-tbl + .fifty-tbl {
    width: 100%;
  }
  .middle-part.dashboard .rigt-part .fifty-tbl {
    float: none;
    width: 100%;
  }
  .middle-part.dashboard .rigt-part h4 {
    font-size: 17px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .middle-part.dashboard .tw-clm,
  .middle-part.dashboard .three-clm {
    flex: 1 1 100%;
  }
  .middle-part.dashboard .rigt-part .circle-big {
    transform: scale(0.8);
  }
  .middle-part.dashboard .rigt-part p.crncy {
    padding-top: 10px;
  }
  .ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
    flex-wrap: wrap !important;
  }
  .middle-part.dashboard .rigt-part .table-thirty {
    float: none;
    width: 100%;
  }
  .middle-part.dashboard .rigt-part .table-seventy {
    float: none;
    width: 100%;
  }
  .middle-part.dashboard .rigt-part .table-seventy ul {
    float: none;
    display: table;
    margin: 0 auto;
    text-align: center;
  }
  .middle-part.dashboard .rigt-part .table-seventy ul li {
    list-style-type: none;
    float: none;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
  }
  .middle-part.dashboard .rigt-part .table-seventy ul li button {
    padding: 0 17px;
  }
  .middle-part.dashboard .rigt-part .table-seventy ul li button::after {
    bottom: 0;
  }
  .middle-part.dashboard .rigt-part .table-thirty h3 {
    float: none;
    font-weight: 400;
    color: #a7a7a8;
    text-align: center;
    font-size: 30px;
    padding-bottom: 20px;
  }
  .middle-part.dashboard .rigt-part .candidate-text {
    float: none;
  }
  .middle-part.dashboard .rigt-part .fifty-tbl + .fifty-tbl {
    width: 100%;
    margin: 30px 0 0;
  }
  .search-box {
    float: left;
    width: 48.2%;
  }
  .middle-part.dashboard .rigt-part input[type="submit"],
  .rigt-part input[type="button"] {
    padding: 0 25px;
  }
  span.drop-down {
    font-size: 15px !important;
    color: white !important;
    background: red none repeat scroll 0% 0% !important;
    border-radius: 50px !important;
    padding: 5px !important;
    float: none !important;
    display: inline-block !important;
    margin-bottom: 6px !important;
  }
  .middle-part.dashboard .rigt-part .tabinfo h4 span + span {
    float: none !important;
  }
}
@media (max-width: 575px) {
  .middle-part.dashboard .rigt-part.cd h5 {
    font-size: 25px;
    line-height: 30px;
    margin: 10px 0 0;
  }
  .middle-part.dashboard
    .rigt-part.cd
    .candidate-sec
    .fifty-candidate.browse-job
    h2 {
    font-size: 30px;
    line-height: 38px;
  }
  .middle-part.dashboard .rigt-part h4 {
    font-size: 20px;
    line-height: 28px;
  }
  .middle-part.dashboard .rigt-part.cd .thirty-three {
    width: 100%;
  }
  .middle-part.dashboard .gt-started-frm {
    padding: 20px;
  }
  .middle-part.dashboard .rigt-part.cd .candidate-sec .prfl-box {
    width: 40px;
    height: 40px;
  }
}
</style>