<template>
 <Navigation/>
   <Banner bg="banner2.png">
        <div class="figure">
            <img src="@/assets/guard.png" alt="">
        </div>
        <div class="inr_text">
            <h3>Find Your</h3>
            <h1>Next Protection Job</h1>
            <p>Everything from <span>Protective</span> Intelligent agent jobs to detail leader jobs.</p>
        </div>
        <div class="brand">
            <img src="@/assets/brand.png" alt="">
        </div>
    </Banner>
  <Cmscontent/>
  <Footer/>
</template>

<script>
import Banner from '../components/Banner.vue'
import Cmscontent from '../components/Cmscontent.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
export default{	
    name: 'About',
    components: {
       Banner,	
       Cmscontent,
       Navigation,
		Footer
    },
}
</script>