<template>
    <Navigation/>
    <Banner class="blog_banner" bg="job_bg.jpg">
        <div class="inr_text blog bl">
			<img src="@/assets/job-details.png">
            <h4 style="font-size: 35px;max-width: 100% ;line-height: 35px;">Everything from <span style="background: -webkit-linear-gradient(#ffba00, #94824f);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">Protective</span> Intelligent agent jobs to detail leader jobs.</h4>
        </div>
    </Banner>
    <div class="container">
        <div class="details_area">
            <div class=" job_details">
                <div class="job_logo">
                    <img v-bind:src="userlogo" v-bind:alt="details.job_title" v-if="userlogo">
                    <img src="@/assets/icon7.jpg" alt="" v-else>	
                </div>
                <div class="job_info">
                    <h2>{{details.job_title}}</h2>
                    <ul class="role">
                        <li v-if="details.jobroles">{{details.jobroles.role_name}} </li>           
                        <li v-if="details.jobtype">{{details.jobtype.job_type}}</li>
                        <li v-if="details.experience">{{details.experience.experience}} </li>
                        <li v-if="details.budget">{{details.budget.budget_name}} </li>
                    </ul>
                    <div class="job_date">
                        <div class="date"><span style="color:#ffc83d">{{moment(details.created_at).startOf("month").format('MMMM')}}</span> {{moment(details.created_at).startOf("day").format('DD')}}, {{moment(details.created_at).startOf("year").format('YYYY')}}</div>
                        <span class="place" v-if="membershipType!='Free'">{{details.city}}, {{details.country}}</span>              
                    </div>
                    <div class="address" v-if="membershipType!='Free'">
                        <h4>Address</h4>
                        <h6>{{details.street_address}}</h6>
                    </div>
                    <ul class="contact" v-if="membershipType!='Free'">
                        <li v-if="details.contactPhone"><i style="color:#ffc83d" class="fas fa-phone"></i>Call: <span>{{details.contactPhone}}</span></li>
                        <li v-if="details.contactEmail"><i style="color:#ffc83d" class="fas fa-envelope"></i>Email: <span>{{details.contactEmail}}</span></li>
                    </ul>
                    <div v-html="details.job_description" class="description"></div>
                    <div class="action_btn" >
                        <div class="action_btn" v-if="userType==3">
                            
                            <button class="secondary_btn" style="cursor:pointer" v-on:click="applyJobs" v-if="thisJobAppliedOrNot=='no'" >Apply Now</button>
                            <button class="secondary_btn" style="cursor:pointer" v-if="thisJobAppliedOrNot=='yes'" >Applied</button>
                            
                            <button :disabled="buttonclick" class="login_btn" style="cursor:pointer" v-on:click="saveJobs" v-if="thisJobSavedOrNot=='no'" >
                                <span v-if="buttonclick==true">Saving. . .</span>
                                <span v-else > Save</span>
                            </button>
                            <button class="login_btn" style="cursor:pointer" v-on:click="saveJobs" v-if="thisJobSavedOrNot=='yes'" >Unsave</button>
                            <!-- <div v-if="message!=''" style="color:red;" class="message">{{message}}</div> -->
                        </div>
                        <div class="action_btn" v-if="AuthUserId">
                            <button class="secondary_btn" style="cursor:pointer" v-on:click="goToLogin" v-if="thisJobAppliedOrNot=='no' && !AuthUserId" >Apply Now</button>
                            <button :disabled="buttonclick" class="login_btn" style="cursor:pointer" v-on:click="goToLogin" v-if="thisJobSavedOrNot=='no' && !AuthUserId" >
                                
                                <span v-if="buttonclick==true" >Login Checking. . .</span>
                                <span v-else > Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="comment_area blog_details" style="border-top: 2px solid #b91e1e;min-height:1180px;">
                <JobRightBar :jobId="jobId"/>
           </div>
        </div>
        <!-- Add New Experience -->
        <div class="modelPopups" v-if="addModal" style="display:block">
            <div class="content_wrapper">
            <div class="modal_header">
                <h2>Job Apply Form</h2>
                <button @click="close"><i class="fas fa-times"></i></button>
            </div>
            <form id="jobpost-form" v-on:submit.prevent="applyJobNow" enctype="multipart/form-data">
            <div class="modal_body">
                <div class="form_grp">
                    <input type="hidden" v-model="jobId" >
                    <div class="item_grp">
                        <label>Resume:</label>
                        <!-- <input type="file" ref="resume" @input="documentUpload" name="resume" id="resume" accept="application/*" > -->
                        <input type="file" ref="resume" @input="documentUpload" name="resume" id="resume" >
                        <span v-if="imageError">{{imageError}}</span>
                        <span v-if="resumeError && imageError==''">{{resumeError}}</span>
                    </div>  
                    <div class="item_grp">
                        <div style="width:48%;float:left;">
                            <label>First Name:</label> 
                            <input type="text" placeholder="Enter First Name" v-model="state.fname" name="fname" id="fname"/>
                            <span v-if="v$.fname.$error">
                                {{ v$.fname.$errors[0].$message }}
                            </span>
                        </div>
                        <div style="width:48%;float:right;">
                            <label>Last Name:</label> 
                            <input type="text" placeholder="Enter Last Name" v-model="state.lname" name="lname" id="lname"/>
                            <span v-if="v$.lname.$error">
                                {{ v$.lname.$errors[0].$message }}
                            </span>
                        </div>
                    </div> 
                    <div class="item_grp"><label>Primary Email:</label> 
                        <input type="text" placeholder="Enter Primary Email" v-model="state.primaryEmail" name="primaryEmail" id="primaryEmail"/>
                        <span v-if="v$.primaryEmail.$error">
                            {{ v$.primaryEmail.$errors[0].$message }}
                        </span>
                    </div>
                    <div class="item_grp">
                        <div style="width:48%;float:left;">
                            <label>Experience Level:</label>
                            <select v-model="state.experience" name="experience" id="experience" autocomplete="off">
                                <option value="">Select an Option </option>
                                <option v-bind:key="exper.id" v-for="exper in experienceList" :value="exper.id">{{exper.experience}}</option>
                            </select>
                            <span v-if="v$.experience.$error">
                                {{ v$.experience.$errors[0].$message }}
                            </span>
                        </div>
                        <div style="width:48%;float:right;">
                            <label>Area(s) of Interest:</label> 
                            <select v-model="state.areaInterest" name="areaInterest" id="areaInterest" autocomplete="off">
                                <option value="">Select an Option </option>
                                <option v-bind:key="areaInterest.id" v-for="areaInterest in areaInterestList" :value="areaInterest.id">{{areaInterest.name}}</option>
                            </select>
                            <span v-if="v$.areaInterest.$error">
                                {{ v$.areaInterest.$errors[0].$message }}
                            </span>
                        </div>
                    </div> 
                    
                    <div class="item_grp">
                        <div style="width:48%;float:left;">
                            <label>Communities of Interest:</label> 
                            <select v-model="state.comInterest" name="comInterest" id="comInterest" autocomplete="off">
                                <option value="">Select an Option </option>
                                <option v-bind:key="comInterest.id" v-for="comInterest in comInterestList" :value="comInterest.id">{{comInterest.name}}</option>
                            </select>
                            <span v-if="v$.comInterest.$error">
                                {{ v$.comInterest.$errors[0].$message }}
                            </span>
                        </div>
                        <div style="width:48%;float:right;">
                            <label>Preferred Language:</label> 
                            <select v-model="state.pLanguage" name="pLanguage" id="pLanguage" autocomplete="off">
                                <option value="">Select an Option </option>
                                <option v-bind:key="pLanguage.id" v-for="pLanguage in pLanguageList" :value="pLanguage.id">{{pLanguage.name}}</option>
                            </select>
                            <span v-if="v$.pLanguage.$error">
                                {{ v$.pLanguage.$errors[0].$message }}
                            </span>
                        </div>
                    </div>

                    <div class="item_grp">
                        <div style="width:48%;float:left;">
                            <label>Primary Skill Set:</label> 
                            <select v-model="state.primarySkill" name="primarySkill" id="primarySkill" autocomplete="off">
                                <option value="">Select an Option </option>
                                <option v-bind:key="primarySkill.id" v-for="primarySkill in primarySkillList" :value="primarySkill.id">{{primarySkill.name}}</option>
                            </select>
                            <span v-if="v$.primarySkill.$error">
                                {{ v$.primarySkill.$errors[0].$message }}
                            </span>
                        </div>
                        <div style="width:48%;float:right;">
                            <label>Primary Country:</label> 
                            <select v-model="state.country" name="country" id="country" autocomplete="off">
                                <option value="">Select an Option </option>
                                <option v-bind:key="country.id" v-for="country in countryList" :value="country.id">{{country.name}}</option>
                            </select>
                            <span v-if="v$.country.$error">
                                {{ v$.country.$errors[0].$message }}
                            </span>
                        </div>
                    </div>
                    <div class="item_grp">
                        <button type="submit" :disabled="buttonApplyclick" class="sign_submit">
                            <span v-if="buttonApplyclick==true" style="color:white">Applying. . .</span>
                            <span v-else style="color:white">Apply Now</span>
                        </button>
                    </div>
                </div>
            </div>
            </form>
            </div>
        
        </div>
        <BrandSlider />
    </div>
    <Footer/>
</template>
<script>
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import Banner from '../components/Banner.vue'
import BrandSlider from '../components/BrandSlider.vue'
import JobRightBar from '../components/JobRightBar.vue'
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required , email} from "@vuelidate/validators"
import moment from "moment";
import { useRoute } from 'vue-router'
import { createLocal } from 'the-storages'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'JobDetails',
    components:{
        Navigation, 
        Footer,
        Banner,
        JobRightBar,
        BrandSlider
    },
    setup() {
        const state = reactive({
            fname:'',
            lname:'',
            primaryEmail:'',
            experience:'',
            areaInterest:'',
            comInterest:'',
            pLanguage:'',
            primarySkill:'',
            country:''
        })

        const rules = computed(() => {
            return {
                fname:{required},
                lname:{required},
                primaryEmail:{required , email},
                experience:{required},
                areaInterest:{required},
                comInterest:{required},
                pLanguage:{required},
                primarySkill:{required},
                country:{required}
            }
        })
        const v$ = useValidate(rules, state)
        return {
            state,
            v$
        }
    },
    data() {
        return { 
            membershipType:'',
            buttonclick:false,
            buttonApplyclick:false,
            storageData: mirror,
            storage: storage,
            isLoading: true,
            imagepath:{},
            details: {},
            thisJobAppliedOrNot: this.thisJobAppliedOrNot,
            thisJobSavedOrNot: this.thisJobSavedOrNot,
            slugname:'',
            message:'',
            moment: moment,
            userlogo:'',
            phone_no:'',
            email:'',
            commentslist:[],
            totalcomments:0,
            orders:'desc',
            jobId:'',
            addModal: false,
            imageError:'',
            resumeError:'',
            //state:{
                fname:'',
                lname:'',
                primaryEmail:'',
                experience:'',
                areaInterest:'',
                resume:'',
                comInterest:'',
                pLanguage:'',
                primarySkill:'',
                country:'',
            //},
            experienceList:[],
            areaInterestList:[],
            comInterestList:[],
            pLanguageList:[],
            primarySkillList:[],
            countryList:[]
		}
		},
	async created () { 
		const route=useRoute(); 
        this.membershipType = mirror.MEMBERSHIP_TYPE      
		this.AuthUserId = mirror.AUTHID
		this.userType = mirror.USERTYPE
        const response = await axios.get(API_BASE_URL + 'jobDetails/'+route.params.slug);
        this.details   = response.data;
        this.jobId     = response.data.id;
        this.imagepath = API_IMAGE_PATH;
        if(this.details.user.logo!='' && this.details.user.logo!=null)
        this.userlogo=API_IMAGE_PATH+this.details.user.logo;
        this.phone_no=this.details.user.phone_no;
        this.email=this.details.user.email;
        const param    = {
            userId:mirror.AUTHID,
            jobId:this.jobId
        }
        const response2= await axios.post(API_BASE_URL + 'thisJobAppliedOrNot',param);
        this.thisJobAppliedOrNot = response2.data.thisJobAppliedOrNot;
        const response3= await axios.post(API_BASE_URL + 'thisJobSavedOrNot',param);
        this.thisJobSavedOrNot = response3.data.thisJobSavedOrNot;
        console.log("USERID",this.AuthUserId)
        console.log("SAVE JOB BTN",this.thisJobSavedOrNot)
        //console.log("SAVE JOB BTN",this.thisJobSavedOrNot)
        const response4= await axios.get(API_BASE_URL + 'jobAppliedList');
        this.experienceList = response4.data.experience[0];
        this.areaInterestList = response4.data.areaInterestList[0];
        this.comInterestList = response4.data.commInterestList[0];
        this.pLanguageList = response4.data.pLanguageList[0];
        this.primarySkillList = response4.data.primarySkillList[0];
        this.countryList = response4.data.countryList[0];
        console.log("EXP",response4.data)
	},
	methods:{
		async applyJobNow() {
            this.buttonApplyclick = true;
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            this.v$.$validate(this.v$.$error);
            console.log(this.v$.$error);
            console.log("FNAME",this.state.fname);
            //if (!this.v$.$error) {
                console.log("2");
                let formData = new FormData();        	 
                console.log(this.state.resume);        	 
                
                if(this.state.resume){			
                    formData.append('resume', this.state.resume);
                    this.resumeError = '';
                } else {
                    this.resumeError = 'please choose resume';
                    console.log("ERROR: ",this.resumeError);
                }
                formData.append('userId', mirror.AUTHID);
                formData.append('jobId', this.jobId);
                formData.append('fname', this.state.fname);
                formData.append('lname', this.state.lname);
                formData.append('primaryEmail', this.state.primaryEmail);
                formData.append('experience', this.state.experience);
                formData.append('areaInterest', this.state.areaInterest);
                formData.append('comInterest', this.state.comInterest);
                formData.append('pLanguage', this.state.pLanguage);
                formData.append('primarySkill', this.state.primarySkill);
                formData.append('country', this.state.country);

                if (this.state.resume && this.state.fname && this.state.lname && this.state.primaryEmail && this.state.experience && this.state.areaInterest && this.state.comInterest && this.state.primarySkill && this.state.pLanguage && this.state.country) {
                    console.log("DATA:",formData);
                    this.resumeError = '';
                    console.log("3");
                    //axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                        await axios.post(API_BASE_URL + 'apply-job', formData,config)
                        .then(response => {                        	
                        console.log(response)                        	
                            if (response.data.result == 'success') {
                                this.errormessage ='';
                                this.message = response.data.message;
                                this.addModal = false

                                if(response.data.isSave=='yes') {
                                    this.thisJobAppliedOrNot = 'yes';
                                } else {
                                    this.thisJobAppliedOrNot = 'no';
                                }
                                ////////////////////////////////////////////////////////////
                                // axios.post(BASE_URL +'api/applyJobsPost', {
                                //     userId: mirror.AUTHID,
                                //     jobId:this.jobId
                                // })
                                // .then(response => {
                                //     console.log(response);
                                //     if (response.data.result==true) {
                                //         this.message = response.data.message;
                                //         console.log('data:',this.message);
                                //     } else {
                                //         this.message = response.data.message;
                                //         console.log('dataFailed:',this.message);
                                //     }
                                //     if(response.data.isSave=='yes'){
                                //         this.thisJobAppliedOrNot = 'yes';
                                //     } else {
                                //         this.thisJobAppliedOrNot = 'no';
                                //     }
                                //     console.log(this.thisJobAppliedOrNot);
                                // })
                                // .catch(error => {
                                //     console.error(error);
                                // });
                                //console.log(responses);
                                ////////////////////////////////////////////////////////////
                                //this.$router.push({ name: "JobList" });
                                this.state.resume = '';
                                this.state.fname = '';
                                this.state.lname = '';
                                this.state.primaryEmail = '';
                                this.state.experience = '';
                                this.state.areaInterest = '';
                                this.state.comInterest = '';
                                this.state.pLanguage = '';
                                this.state.primarySkill = '';
                                this.state.country = '';
                                this.buttonApplyclick = false;
                                this.showToast("Success",this.message,"success"); 
                            } else {
                                this.errormessage = response.data.message;
                                this.message = '';
                                this.buttonApplyclick = false;
                                this.showToast("Failed",this.errormessage,"danger"); 
                            }
                        })
                        .catch(error => {
                            //console.log('error-data:', error)
                            console.error(error);
                        });
                        
                        var element = document.getElementById("experience_info");
                        element.scrollIntoView();
                    //});
                    
                } else {
                    this.errormessage = 'All Fields are Mandatory';
                    this.buttonApplyclick = false;
                    this.showToast("Warning!",this.errormessage,"warning"); 
                }
            // } else {
            //     this.errormessage = 'All Fields are Mandatory';
            // }
        },
        documentUpload(e) {
            //console.log("E:",e)
            console.log("FILE TYPE:",e.target.files[0]['type'])
            if(e.target.files[0]['type'] == 'application/pdf' || e.target.files[0]['type'] == 'application/msword' || e.target.files[0]['type'] == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                const file = e.target.files[0]
                this.state.resume = file
                this.state.editResume = file
                this.resumePath = URL.createObjectURL(file);
                this.imageError = '';
                //console.log("IF")
            } else {
                //console.log("ELSE")
                this.state.resume = ''
                this.imageError = 'Uploaded File must be in doc, docx or pdf format'
            }
            
            console.log("image1",this.resume)
        },
        applyJobs: function() {
            this.resumeError = '';
            this.addModal = true;
            console.log("AUTH:",mirror)
            this.myResult = mirror.NAME.split(" ");
            this.state.fname = this.myResult[0]
            this.state.lname = this.myResult[1]
            this.state.primaryEmail = mirror.USEREMAIL
        },
        close: function(){
            this.addModal = false
        },
        async saveJobs() {
            this.buttonclick = true;
            axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
                axios.post(BASE_URL +'api/saveJobsPost', {
                    userId: mirror.AUTHID,
                    jobId:this.jobId
                })
                .then(response => {
                    console.log(response);
                    if (response.data.result==true) {
                        this.message = response.data.message;
                        console.log('data:',this.message);
                        this.showToast("Success",this.message,"success"); 
                    } else {
                        this.message = response.data.message;
                        console.log('dataFailed:',this.message);
                        this.showToast("Success",this.message,"success"); 
                    }
                    if(response.data.isSave=='yes'){
                        this.thisJobSavedOrNot = 'yes';
                    } else {
                        this.thisJobSavedOrNot = 'no';
                    }
                    console.log(this.thisJobSavedOrNot);
                    this.buttonclick = false;
                })
                .catch(error => {
                    console.error(error);
                });
                console.log(responses);
            });
            
			// setTimeout(() => {
			// 	this.message = '';
			// 	console.log(this.message);
			// }, 5000);
		},
        goToLogin(){
            this.buttonclick = true;
            this.$router.push({ name: "Login" });
        },
        showToast(title,description,type){
			createToast({
				title: title,
				description: description
			},
			{
				type: type,
				position: 'bottom-right',
				// hideProgressBar: 'true',
				// toastBackgroundColor: color,
			})
		},
	},
}
</script>
<style>
.job_details .job_info .description table{
    border:1px solid #bfbfbf;
    width: 100%;
    padding: 0 !important;
    border-collapse: collapse;
}
.job_details .job_info .description table *{
    padding: 0 !important;
}
.job_details .job_info .description table tr td{
    border-right:1px solid #bfbfbf;
    border-bottom:1px solid #bfbfbf;
    padding: 10px !important;
}

</style>
<style scoped>

.inr_text .blog .bl h4{
	font-size: 35px;
	max-width: 100% ;
	line-height: 35px;
}
.modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
}
.secondary_btn {
    font-size: 15px;
    line-height: 20px;
    padding: 12px 38px;
    color: #fff;
    border: 1px solid #b01d1e;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    background: transparent;
    background: #b01d1e;
    cursor: pointer;
}
.details_area{
    display: flex;
    padding: 60px 0 90px;
    align-items: flex-start;
}
.job_details{
    background: #1f1f20;
    padding: 50px 40px;
    display: flex;
    flex: 1 1 955px;
    margin-right: 45px;
    border-top: 2px solid #b91e1e;
}
.job_details .job_logo{
    padding: 15px 35px 10px 0;
}
.job_details .job_logo img{
    max-width: 160px;
    height: 160px;
    object-fit: cover;
}
.job_details .job_info{
    padding: 5px 0;
}
.job_details .job_info h2{
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
}
.job_details .job_info .role{
    display: flex;
    flex-wrap: wrap;
    margin: 10px -15px 5px -15px;
}
.job_details .job_info .role li{
    padding: 5px 15px;
    color: #999;
    font-size: 22px;
    line-height: 28px;
    position: relative;
}
.job_details .job_info .role li:after{
    position: absolute;
    left: 100%;
    top: 5px;
    content: '.';
    color: #a5a5a5;
}
.job_details .job_info .role li:last-child:after{
    display: none;
}
.job_details .job_info .job_date{
    font-size: 19px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    padding: 15px 0;
}
.job_details .job_info .job_date .date{
    padding-right: 15px;
}
.job_details .job_info .job_date .date span{
    color: #c61e1e;
    font-weight: 600;
}
.job_details .job_info .job_date .place{
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    color: #d1d1d1;
}
.job_details .job_info .address{
    font-size: 24px;
    line-height: 30px;
    color: #e2e2e2;
    font-weight: 600;
    padding: 10px 0 20px;
}
.job_details .job_info .address h6{
    font-size: 18px;
    line-height: 24px;
    color: #d1d1d1;
    font-weight: 400;
    max-width: 400px;
    padding-top: 10px;
}
.contact li{
    font-size: 21px;
    line-height: 26px;
    font-weight: 600;
    color: #e2e2e2;
    padding: 8px 0;
}
.contact li span{
    font-weight: 400;
    color: #ccc;
}
.contact li i{
    color: #c61e1e;
    margin-right: 10px;
}
.job_details .job_info p{
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #999;
    padding-top: 20px;
}
.job_details .job_info .action_btn{
    padding-top: 40px;
}
.job_details .job_info .action_btn button{
    margin-bottom: 8px;
}
.comment_area{
    flex: 1 1 440px;
    padding: 30px 0 0;
    background: #1f1f20;
    display: flex;
    flex-direction: column;
}
.comment_area .area_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 20px;
}
.total_comments{
    font-size: 21px;
    line-height: 25px;
    color: #fff;
    font-weight: 400;
}
.total_comments span{
    color: #c61e1e;
    font-weight: 600;
}
.sortBy select{
    background: transparent;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Play', sans-serif;
    padding: 0 8px;
    appearance: none;
}
.add_comment{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px 0 30px;
}
.add_comment .user_img{
    padding-right: 15px;
}
.add_comment .user_img img{
    width: 55px;
    height: 55px;
    border-radius: 50%;
}
.add_comment .comment_field{
    flex: 1 1 auto;
}
.add_comment .comment_field #get-tuch-form{
    display: flex;
}
.add_comment .comment_field textarea{
    flex: 1 1 auto;
    background: transparent;
    border-bottom: 1px solid #444;
    height: 40px;
    color: #d9d9d9;
    font-size: 13px;
    line-height: 17px;
    padding: 5px 10px;
    font-weight: 400;
}
.comment_area .comment_list{
    padding: 30px 0 0;
}
.comment_area .comment_list li{
    padding: 25px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}
.comment_area .comment_list li:nth-child(even){
    background: rgba(255, 255, 255, 0.03);
}
.comment_area .view_btn{
    display: block;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    color: #fff;
    margin:  auto 0;
}

@media(max-width:1199px){
    .details_area{
        flex-wrap: wrap;
    }
    .details_area .job_details{
        margin: 0 0 30px 0;
    }
}
@media(max-width:767px){
    .job_details{
        flex-wrap: wrap;
        padding: 20px 20px;
    }
    .job_details .job_logo img{
        max-width: 100px;
        height: auto;
    }
    .comment_area .area_header{
        padding: 0 20px 20px;
    }
    .comment_area .add_comment{
        padding: 20px 20px 0 20px;
    }
    .comment_area .comment_list li{
        padding: 20px 20px 0 20px;
    }
}
@media(min-width: 1200px){
    .inr_text.blog.bl:after {
        position:absolute;bottom:-40px;right:0;content:"";background: url('~@/assets/brand.png') no-repeat;width:145px;height:170px;
    }
}

 @media(max-width:740px){
.job_details .job_info h2{font-size: 29px;
line-height: 36px;}

 }
 @media(max-width:576px){
    .job_details .job_info .action_btn{
        padding-top: 20px;
        text-align: center;
    }
    .job_details .job_info .action_btn button{
        margin-bottom: 8px;
        display: block;
        margin-left: 0;
        width: 100%;
    }
    
}
</style>
<style>
.job_details .job_info > .description{
    max-height: 600px;
    overflow-y: scroll;
    min-height: 600px;
}
.job_details .job_info > .description::-webkit-scrollbar{
	width: 6px;
}
.job_details .job_info > .description::-webkit-scrollbar-thumb{
	background-color: #333;
    border-radius:3px;
}
.job_details .job_info .description *{
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #999 !important;
    padding-top: 20px !important;
    margin-left: 0 !important;
}
.sign_submit {
    padding: 8px 15px;
	background: #c71e1e;
	border-radius: 4px;
	border: none;
	font-size: 10px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
    cursor:pointer;
    margin: 4px 4px 0 0;
    border-radius: 0;
}
</style>
