<template>
    <section class="blog_pro">
		<div class="container">
			<div class="blo_pro_right">
				<img src="@/assets/blog-pro.png">
				<p>Join the league of executive protection specialists and become 1% of the executive protection industry. </p>
				<!-- <router-link :to="'/training'" class="read_more">Join Now</router-link> -->
				<a
					href="https://www.executiveprotectiontrainingday.com/eptd-masters-enrollment1635187064705"
					class="read_more"
					>Join Now</a
				>
			</div>
		</div>
	</section> 
</template>


<style scoped>
.blog_pro {
	width: 100%;
	float: left;
	padding: 50px 0;
	background-image: url('~@/assets/blog-pro-bg.jpg');
	background-repeat: no-repeat;
	background-position: left top;
	background-size: cover;	
}

.blo_pro_right {
	width: 40%;
	float: right;
	padding-right: 30px;
	box-sizing: border-box;
}

.blo_pro_right img{
	max-width:600px;
	width:100%;
}
.blo_pro_right p {
	font-size: 15px;
	color: #fff;
	text-transform: uppercase;
	margin: 10px 0;
	line-height: 24px;
}

.read_more {
	color: #d9d9d9;
	border: solid 1px #d9d9d9;
	padding: 7px 25px;
	text-decoration: none;
	display: inline-block;
	margin-top: 20px;
	font-size: 16px;
}

.read_more:hover {
	background: #cfcfcf;
	color: #000;
}
@media(max-width:1366px){
	.blog_pro {
		background-position: -200px top !important;
		position: relative;
	}
}
@media(max-width:767px){
	.blog_pro:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(to right, transparent, rgba(0,0,0,0.8));
	}
	.blo_pro_right{
		width:240px;
		padding-right:15px;
		position: relative;
	}
}

</style>
