<template>
<Navigation/>
    <Banner bg="banner2.png">
        <div class="figure">
            <img src="@/assets/guard.png" alt="">
        </div>
        <div class="inr_text">
            <h3>Find Your</h3>
            <h1>Next Protection Job</h1>
            <p>Everything from <span  style="background: -webkit-linear-gradient(#ffba00, #816417);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">Protective</span> Intelligent agent jobs to detail leader jobs.</p>
        </div>
        <!-- <div class="brand">
            <img src="@/assets/brand.png" alt="" style="padding: 75px;width: 75%;">
        </div> -->
    </Banner>
    <div class="container" id="container">    	
		<div class="lds-spinner" v-if="is_loader"></div>
        <div class="Jobs_tbl" v-else>
            <div class="left_area" :class="{open:isBtn}" style="height: 3149px !important;">
                <button @click="toggleFunc" type="button" class="close_btn"><i class="fas fa-times"></i></button>
                <form id="signup-form" v-on:submit.prevent="filter" style="height: 3050px !important;">
                <div class="sidebar" style="padding-bottom:0px;">
                    <div class="filter_grp">
                        <h4>Keyword</h4>
                        <input type="text" v-model="keyword"   placeholder="Enter Your Keyword" v-on:keyup="filter">
                    </div>
                    
                </div>
                <div class="sidebar"  style="border:none !important;height: 2960px !important;overflow-y: scroll;">
               
                    <div class="filter_grp">
                        <h4>Experience</h4>
                    
                        <label :for="exper.experience" v-bind:key="exper.id" v-for="exper in experienceList">
                            <input type="checkbox" v-model="experience" :true-value="[]" :value="exper.id" :id="exper.experience" v-on:click="filter">
                            <span><i class="far fa-square"></i><i class="fas fa-check-square"></i></span>
                            {{exper.experience}}
                        </label>
                    
                    </div>
                    <div class="filter_grp">
                        <h4>Hiring Role</h4>
                         <label :for="roles.role_name" v-bind:key="roles.id" v-for="roles in roleList">
                            <input type="checkbox" v-model="hiring_role" :true-value="[]" :value="roles.id" :id="roles.role_name" v-on:click="filter">
                            <span><i class="far fa-square"></i><i class="fas fa-check-square"></i></span>
                            {{roles.role_name}}
                        </label>
                    </div>
                    <div class="filter_grp">
                        <h4>Type Of Job</h4>
                        <label :for="jobType.job_type" v-bind:key="jobType.id" v-for="jobType in jobTypeList">
                            <input type="checkbox" v-model="job_type" :true-value="[]" :value="jobType.id" :id="jobType.job_type" v-on:click="filter">
                            <span><i class="far fa-square"></i><i class="fas fa-check-square"></i></span>
                            {{jobType.job_type}}
                        </label>
                        
                    </div>
                    
                    <div class="filter_grp">
                        <h4>Work Location</h4>
                        <label :for="workReport.work_report_name" v-bind:key="workReport.id" v-for="workReport in workReportList">
                            <input type="checkbox" v-model="location" :true-value="[]" :value="workReport.id" :id="workReport.work_report_name" v-on:click="filter">
                            <span><i class="far fa-square"></i><i class="fas fa-check-square"></i></span>
                            {{workReport.work_report_name}}
                        </label>
                        
                    </div>
                    
                     <div class="filter_grp">
                        <h4>Salary Budget</h4>
                        <label :for="budgets.budget_name" v-bind:key="budgets.id" v-for="budgets in budgetList">
                            <input type="checkbox" v-model="budget" :true-value="[]" :value="budgets.id" :id="budgets.budget_name" v-on:click="filter">
                            <span><i class="far fa-square"></i><i class="fas fa-check-square"></i></span>
                            {{budgets.budget_name}}
                        </label>
                        
                    </div>
                    
                   <!-- <button type="submit" class="primary_btn">Search Jobs <i class="fas fa-long-arrow-alt-right"></i></button>-->
                 
                </div>
                </form>   
            </div>
            <div class="right_area" v-if="jobList && jobList.data?.length > 0" style="">
                <button @click="toggleFunc" type="button" class="filter_btn"><i class="fas fa-align-left"></i></button>
                <div class="joblist_Wpr">
                    <ul class="listing_tbl" >
                        <JobCard :callback="clickToGetJobDetails" v-bind:key="job.id" v-for="job in jobList.data" :jobData="job" icon="icon2.png" />
                    </ul>
                </div>
			<v-pagination v-if="is_show"
			v-model="page"
			:pages="jobList.last_page"
			:range-size="1"
			active-color="#c71e1e"
			@update:modelValue="filter"
            style="margin: 20px;"
			/>
			
            </div>
            <div v-if="nofound">
			<p>Sorry! no data found.</p>
			</div>
            
			
    
        </div>
        <BrandSlider/>
    </div>
    <Buttomads />
    <Footer/>
    <div class="modal" v-show="modalMembership">
        <div class="content_wrapper">
            <div class="modal_header">
            <h2>You have to upgrade your membership plan then you can see the details of job !</h2>
            <button @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
            <div class="form_grp">
                <div class="item_grp">
                    <button class="close_btn" @click="close">Cancel</button>
                </div>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" v-show="modalLoginMembership">
        <div class="content_wrapper">
            <div class="modal_header">
            To go to the details page please <router-link to="/login" class="">Sign in</router-link> or
          <router-link to="/signup" class="">Signup</router-link>
            </div>
            <div class="modal_body">
           
                <button class="sign_submit" style="float:right;" @click="close">Cancel</button>
                
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import { BASE_URL } from '../config'
//import { reactive } from "vue"
import { API_BASE_URL } from '../config'
import Banner from '../components/Banner.vue'
import JobCard from '../components/JobCardList.vue'
import BrandSlider from '../components/BrandSlider.vue'
import Navigation from '../components/Navigation.vue'
import Buttomads from "../components/Buttomads.vue";
import Footer from '../components/Footer.vue'
import { createLocal } from 'the-storages'
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
const mirror = createLocal()
const storage = mirror._prx

export default{
    name: 'Jobs',
    components: {
		Banner,
		JobCard,
		BrandSlider,
		Navigation,
		Footer,
		VPagination,
        Buttomads
    },
    /*setup(){
		const state = reactive({
            keyword:'',
		})
		
		return {
			state,
		}
	},*/
    data(){
        return{
			isBtn: false,
			storageData: mirror,
			storage: storage,
			current: 1,
			jobList: {},
			error: '',
			error2: '',
			is_show:false,
			nofound:false,
			keyword:'',
			experience:[],
			hiring_role:[],
			job_type:[],
			location:[],
			budget:[],
			is_loader:true,
            modalMembership:false,
            modalLoginMembership:false,
        }
    },
		mounted() {
			this.filter();
		},
		methods:{
            toggleFunc(){
                this.isBtn = !this.isBtn
            },
			filter(page){
			//alert(this.experience)
				if (typeof page === "undefined") {
					page = 1;
				}
				axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                    const inputData = {
                        userId: mirror.AUTHID,
                        userType: mirror.USERTYPE,
                        keyword:this.keyword,
                        experience:this.experience,
                        hiring_role:this.hiring_role,
                        job_type:this.job_type,
                        location:this.location,
                        budget:this.budget,
                    };
                    axios.post(API_BASE_URL + `alljob_list?page=${page}`, inputData).then(response => {
                        this.jobList = response.data
                        this.is_loader=true;
                        console.log(this.jobList)
                        this.is_show=this.jobList.last_page>1?true:false;
                        if(this.jobList.data?.length>0){
                            this.nofound=false;
                            this.is_loader=false;
                        }					
                        else{
                            this.nofound=true;
                            this.is_loader=false;
                        }
                        console.log(this.is_show)	
                    }).catch(({ response }) => {
                        this.error=response
                    })
                    this.error2=response
				});
                //event.target.reset();
                var element = document.getElementById("container");
                element.scrollIntoView();
			},
            async clickToGetJobDetails(jobId,slug){
                //const slug = 1;
                //alert("ABCD ", jobId);
                //console.log("JOB:",jobId,"-SLUG:",slug);

                //console.log(this.userId);
                if(this.userId){
                    if(mirror.MEMBERSHIP_TYPE=='Free') {
                        const inputData = {
                            userId: mirror.AUTHID,
                            jobId: jobId
                        };
                        await axios.post(API_BASE_URL + `membershipCheckingForJobList`, inputData).then(response => {
                            this.data = response.data;
                            
                            console.log("A1: ",this.data);
                            if(this.data.result == 'success') {
                                if(this.data.count>0 && this.data.count<=5){
                                    console.log("THIS SECTION 1");
                                    this.$router. push('/jobDetails/'+slug);
                                } else {
                                    console.log("THIS SECTION 2");
                                    this.modalMembership = true;
                                }
                            } else {
                                console.log("THIS SECTION 3");
                                this.modalMembership = true;
                            }
                        }).catch(({ response }) => {
                            this.error=response
                        })
                    } else {
                        this.$router. push('/jobDetails/'+slug);
                    }
                } else {
                    this.modalLoginMembership = true;
                }
            },
            close: function(){
                this.modalLoginMembership = false;
                this.modalMembership = false;
            },
		},
		async created() {
            this.userId=mirror.AUTHID;
            this.userType=mirror.USERTYPE;
            this.membershipType=mirror.MEMBERSHIP_TYPE;

			const response2 = await axios.get(API_BASE_URL + 'job');
			this.jobTypeList = response2.data.jobtypes[0];
			this.roleList = response2.data.hire_role[0];
			this.budgetList = response2.data.budgetdetails[0];
			this.workReportList = response2.data.work_report[0];
			this.experienceList = response2.data.experience[0];
		},
		beforeRouteEnter(to, from, next) {
            if (storage.get('TOKEN')) {
                if(storage.USERTYPE==2)
                    next('/jobList');
                else if(storage.USERTYPE==1)
                    next('/');
                else
                    next();	
            }
            else{
                next('/login');
            }
        }
    }
</script>

<style scoped>
    /* .Pagination li button.Page.Page-active{
        background: red !important;
    }
     .Pagination li .Page-active{
          background: red !important;
     } */
    .left_area .sidebar::-webkit-scrollbar{
        width: 6px;
    }
    .left_area .sidebar::-webkit-scrollbar-thumb{
        background-color: #333;
        border-radius:3px;
    }
    .banner {
    overflow: hidden;
}
    .sign_submit {
    padding: 8px 15px;
	background: #c71e1e;
	border-radius: 4px;
	border: none;
	font-size: 10px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
    cursor:pointer;
    margin: 4px 4px 0 0;
    border-radius: 0;
}
    .modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
}
    .sidebar{
        background: #1f1f20;
        padding: 45px 30px 60px 30px;
        border-top: 2px solid #c71e1e;
    }
    .filter_grp{
        padding-bottom: 50px;
    }
    .filter_grp h4{
        font-size: 23px;
        line-height: 28px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .filter_grp input[type=text], .filter_grp select{
        width: 100%;
        height: 52px;
        background: rgba(0,0,0,0.05);
        border: 1px solid rgba(255,255,255,0.1);
        padding:10px 15px;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
    }
    .filter_grp label{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px 0;
        font-size: 16px;
        line-height: 20px;
        color: #c0c0c0;
    }
    .filter_grp label input[type=checkbox]{
       display: none;
    }
    .filter_grp label span{
        width: 12px;
        height:12px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    }
    .filter_grp label span:before{
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        z-index: 0;
        display: none;
    }
    .filter_grp label span i{
        position: relative;
        z-index: 1;
    }
    .filter_grp label i.fa-check-square{
        display: none;
    }
    .filter_grp label input[type=checkbox]:checked ~ span:before{
        display: block;
    }
    .filter_grp label input[type=checkbox]:checked ~ span .fa-check-square{
        display: block;
        color: #c71e1e;
    }
    .filter_grp label input[type=checkbox]:checked ~ span .fa-square{
        display: none;
    }
    .filter_btn{
        font-size: 22px;
        background: transparent;
        border: 1px solid rgba(255,255,255,0.1);
        border-radius: 3px;
        width:40px;
        height: 40px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        color: #d9d9d9;
        cursor: pointer;
    }
    .close_btn{
        position: absolute;
        left: 100%;
        top:0;
        font-size: 22px;
        background: #c71e1e;
        border-radius: 0 3px 3px 0;
        width:40px;
        height: 40px;
        display: none;
        justify-content: center;
        align-items: center;
        color: #d9d9d9;
        cursor: pointer;
    }

    .Jobs_tbl{
        padding: 100px 0;
        display: flex;
    }
    .Jobs_tbl .left_area{
        width: 31%;
        padding-right: 30px;
    }
    .Jobs_tbl .right_area{
        width: 69%;
        /* padding-left: 30px; */
        background: #1b1b1b;
        border-top: 2px solid #c71e1e;
    }
    .Jobs_tbl .joblist_Wpr{
        background:#1b1b1b;
        position: relative;
    }
    .Jobs_tbl .joblist_Wpr:after{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        background-image: url('~@/assets/figure-bg.png');
        background-position: 100% 100%;
        background-repeat: no-repeat ;
    }
    .listing_tbl > li{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid rgba(255,255,255,0.05);
        padding: 40px;
        position: relative;
        z-index: 2;
    }
    .listing_tbl > li:nth-child(even){
        background: rgba(0,0,0,0.1);
    }
    .listing_tbl > li:last-child{
        border:0;
    }

    @media(max-width:1199px){
        .Jobs_tbl .right_area{
            padding-left: 0;
        }
    }
    @media(max-width:991px){
        .Jobs_tbl{
            padding: 50px 0 70px;
        }
        .Jobs_tbl .left_area{
            position: fixed;
            width: 280px;
            padding-right: 0;
            left: -100%;
            top: 0;
            z-index: 10;
            box-shadow: 5px 0 30px 1px rgba(0,0,0,0.3);
            transition: all 0.3s ease-in-out;
        }        
        .Jobs_tbl .left_area.open{
            left: 0;
        }
        .Jobs_tbl .left_area .sidebar{
            /* height: 100vh; */
            overflow-y: scroll;
        }
        .Jobs_tbl .right_area{
            width: 100%;
        }
        .filter_btn, .close_btn{
            display: flex;
        }
    }
</style>