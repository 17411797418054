<template>
    <Navigation/>
    <Banner class="blog_banner" bg="job_bg.jpg">
        <div class="inr_text blog bl">
			<img src="@/assets/job-details.png">
            <h4 style="font-size: 35px;max-width: 100% ;line-height: 35px;">Everything from <span style="background: -webkit-linear-gradient(#ffba00, #94824f);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">Protective</span> Intelligent agent jobs to detail leader jobs.</h4>
        </div>
    </Banner>
    <div class="container">
        <div class="details_area">
				<div class="job_details">
                    <div class="candidate_area">
                        <div class="area_header">
                            <div class="total_comments"><span style="color:#cda53a;">{{totalCandidateCount}}</span> Candidates Applied For This Job</div>
                                <div class="sortBy" v-if="totalCandidateCount > 0">
                                    <i class="fas fa-align-left"></i>
                                    <select name="filter" id="filter" @change="getFilterDetails($event,details.id)">
                                    <option value="DESC">Latest</option>
                                    <option value="ASC">Older</option>
                                    </select>
                                </div>
                            </div>
                        <div class="candidate_list" v-if="totalCandidateCount > 0">
                        <ul>
                            <CandidateCard :callback="appliedJobsDetails" :candidateData="candidate" v-for="candidate in allCandidateList.data" v-bind:key="candidate" />
                        </ul>
                    </div>
				
                <div v-if="nofound" style="text-align: center;padding: 10px;">
				<span style="">Sorry! no data found.</span>
				</div><!-- <router-link to="/" class="view_btn" v-if="totalCandidateCount>10">View All</router-link> -->
				</div>
                <v-pagination 
                    v-if="is_show"
                    v-model="page"
                    :pages="last_page"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="filter"
                    />
                <div class="job_detailsWpr">
                    <div class="job_logo">
                        <img style="width:160px;height:160px;" v-bind:src="imagepath+details.user.logo" v-bind:alt="details.job_title" v-if="logoimg">
                        <img src="@/assets/icon7.jpg" alt="" v-else>	
                    </div>
                    <div class="job_info">
                        <h2>{{details.job_title}}</h2>
                        <ul class="role">
                        <li v-if="details.jobroles">{{details.jobroles.role_name}} </li>           
                        <li v-if="details.jobtype">{{details.jobtype.job_type}}</li>
                        <li v-if="details.experience">{{details.experience.experience}} </li>
                        <li v-if="details.budget">{{details.budget.budget_name}} </li>
                        </ul>
                        <div class="job_date">
                            <div class="date"><span style="color:#cda53a;">{{moment(details.created_at).startOf("month").format('MMMM')}}</span> {{moment(details.created_at).startOf("day").format('DD')}}, {{moment(details.created_at).startOf("year").format('YYYY')}}</div>
                            <span class="place">{{details.city}}, {{details.country}}</span>              
                        </div>
                        <div class="address">
                            <h4>Address</h4>
                            <h6>{{details.street_address}}</h6>
                        </div>
                        <ul class="contact">
                            <li v-if="details.contactPhone"><i style="color:#cda53a;" class="fas fa-phone"></i>Call: <span>{{details.contactPhone}}</span></li>
                            <li v-if="details.contactEmail"><i style="color:#cda53a;" class="fas fa-envelope"></i>Email: <span>{{details.contactEmail}}</span></li>
                        </ul>
                        <p v-html="details.job_description">
                        
                        </p>
                        <div class="action_btn" v-if="AuthUserId!=details.user_id">
                            <button class="secondary_btn">Apply Now</button>
                            <button class="login" v-on:click="saveJobs" v-if="thisJobAppliedOrNot=='no'" >Save Jobs</button>
                            
                            <div v-if="message!=''" style="color:red;" class="message">{{message}}</div>
                        </div>            
                    </div>
                </div>
				</div>
				
				<div class="comment_area blog_details">
				<JobRightBar :jobId="jobId"/>
              </div>   
        </div>
        <BrandSlider/>
        <div class="modelPopups" v-if="addModal" style="display:block">
            <div class="content_wrapper">
                <div class="modal_header">
                    <h2>View Application of {{MAIN_NAME}} 000{{(user_id==19)?"0":user_id}}</h2>
                    <button @click="close"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body">
                    <div class="form_grp">
                        <input type="hidden" v-model="jobId" >
                         
                        <div class="item_grp">
                            <div style="width:48%;float:left;">
                                <label>First Name: <span>{{firstName}}</span></label> 
                            </div>
                            <div style="width:48%;float:right;">
                                <label>Last Name: <span>000{{(user_id==19)?"0":user_id}}</span></label>
                            </div>
                        </div> 
                        <div class="item_grp"><label>Primary Email: <span>{{primeryEmail}}</span></label> 
                        </div>
                        <div class="item_grp">
                            <div style="width:48%;float:left;">
                                <label>Experience Level: <span>{{experience_new}}</span></label>
                            </div>
                            <div style="width:48%;float:right;">
                                <label>Area(s) of Interest: <span>{{area_interest}}</span></label> 
                            </div>
                        </div> 
                        
                        <div class="item_grp">
                            <div style="width:48%;float:left;">
                                <label>Communities of Interest: <span>{{comm_interest}}</span></label> 
                            </div>
                            <div style="width:48%;float:right;">
                                <label>Preferred Language: <span>{{preferred_language}}</span></label> 
                            </div>
                        </div>

                        <div class="item_grp">
                            <div style="width:48%;float:left;">
                                <label>Primary Skill Set: <span>{{primary_skill}}</span></label> 
                            </div>
                            <div style="width:48%;float:right;">
                                <label>Primary Country: <span>{{country}}</span></label> 
                            </div>
                        </div>
                        <!-- <div class="item_grp">
                            <label>Resume: <span> {{jobDetails.resume}}
                           
                            <a :href="url" target="_blank" v-if="jobDetails.resume!==null">
                                Click To Download
                            </a>
                            </span>
                        </label>
                        </div>  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>
<script>
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import Banner from '../components/Banner.vue'
import CandidateCard from '../components/JobCandidateCard.vue'
import BrandSlider from '../components/BrandSlider.vue'
import JobRightBar from '../components/JobRightBar.vue'
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { useRoute } from 'vue-router'
import { createLocal } from 'the-storages'

const mirror = createLocal()
const storage = mirror._prx
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"

export default {
    name: 'AppliedCandidate',
    components:{
        Navigation, 
        Footer,
        Banner,
        CandidateCard,
        BrandSlider,
        JobRightBar,
        VPagination
    },
    data() {
        return {
            storageData: mirror,
            storage: storage,
            isLoading: true,
            imagepath:{},
            imagepathResume:{},
            details: {},
            slugname:'',
            message:'',
            error:'',
            error2:'',
            jobId:'',
            addModal:false,
            logoimg:false,
            phone_no:'',
            email:'',
            moment: moment,
            allCandidateList: this.allCandidateList,
            totalCandidateCount:this.totalCandidateCount,
            last_page:'1',
            is_show:false,
			nofound:false,
            is_loader:true,
            url:'',
            experience_new:'',
            area_interest:'',
            comm_interest:'',
            preferred_language:'',
            primary_skill:'',
            country:'',
            firstName:'',
            lastName:'',
            primeryEmail:'',
            jobDetails:{},
            user_id:'',
            MAIN_NAME:'',
        }
    },
    mounted() {
        this.getResults();
    },
    async created () {
        //alert("Hi");
       this.imagepathResume = API_IMAGE_PATH+'appliedJobDocument/';
	},
	methods:{
		async getResults(page,filter=''){
            const route=useRoute();
            this.isBtn = !this.isBtn
            if (typeof page === "undefined") {
            page = 1;
            }
            this.AuthUserId = mirror.AUTHID;
			
			axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
				axios.get(API_BASE_URL + 'jobDetails/'+  route.params.slug)
				.then(response => {
				console.log(response.data);
					this.details   = response.data;
					this.jobId     = response.data.id;
					if(this.details.user.logo)
					this.logoimg=true;
					this.phone_no=this.details.user.phone_no,
					this.email=this.details.user.email,
					this.imagepath = API_IMAGE_PATH
					console.log('jobid',this.jobId)
					const inputData = {
					userId: mirror.AUTHID,
					jobId: this.jobId,
                    filter: filter
					};
					
					axios.post(API_BASE_URL + `allCandidateList?page=${page}`, inputData).then(response => {
					this.allCandidateList = response.data;
					this.totalCandidateCount = response.data.total;
					this.last_page=this.allCandidateList.last_page;
					console.log("DATA3:",this.allCandidateList);
                    this.is_loader=true;
                    //console.log(this.jobList)
                    this.is_show=this.allCandidateList.last_page>1?true:false;

                    if(this.allCandidateList.data?.length>0){
                        this.nofound=false;
                        this.is_loader=false;
                    }					
                    else{
                        this.nofound=true;
                        this.is_loader=false;
                    }

					}).catch(({ response }) => {
					this.error=response
					})
				});
				this.error2=response
			});
		},
        async saveCandidateForThisJob(jobDetailId,userId) {
            //var jobDetailId = document.getElementById("jobId").value;
            //var userId = document.getElementById("userId").value;
            axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
                axios.post(BASE_URL +'api/saveCandidateForThisJob', {
                    userId: userId,
                    jobId: jobDetailId
                })
                .then(response => {
                    console.log(response);
                    //if (response.data.result==true) {
                        this.allCandidateList = response.data.data;
                        //this.message = response.data.message;
                        console.log('data:',this.allCandidateList);
                        //this.$router.push('AppliedCandidate');
                    //} else {
                        // this.allCandidateList = response.data.data;
                        // this.message = response.data.message;
                        // console.log('dataFailed:',this.allCandidateList);
                    //}
                })
                .catch(error => {
                    console.error(error);
                });
                console.log(responses);
            });
		},
		async saveCandidate() {
            axios.get(BASE_URL+'sanctum/csrf-cookie').then(responses => {
                axios.post(BASE_URL +'api/saveJobs', {
                    userId: mirror.AUTHID,
                    jobId:this.jobId
                })
                .then(response => {
                    console.log(response);
                    if (response.data.result==true) {
                        this.message = response.data.message;
                        console.log('data:',this.message);
                    } else {
                        this.message = response.data.message;
                        console.log('dataFailed:',this.message);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
                console.log(responses);
            });
            
			setTimeout(() => {
				this.message = '';
				console.log(this.message);
			}, 5000);
		},
        async getFilterDetails(event,jobId) {
            this.filter = event.target.value;
            var page1 = 1;
            console.log(this.filter);
            console.log(jobId);
            const inputData = {
                userId: mirror.AUTHID,
                jobId: jobId,
                filter: this.filter
            };
					
            axios.post(API_BASE_URL + `allCandidateList?page=${page1}`, inputData).then(response => {
                this.allCandidateList = response.data;
                this.totalCandidateCount = response.data.total;
                this.last_page=this.allCandidateList.last_page;
                console.log("DATA3:",this.allCandidateList.last_page);
                this.is_loader=true;
                //console.log(this.jobList)
                this.is_show=this.allCandidateList.last_page>1?true:false;

                if(this.allCandidateList.data?.length>0){
                    this.nofound=false;
                    this.is_loader=false;
                }					
                else{
                    this.nofound=true;
                    this.is_loader=false;
                }
            }).catch(({ response }) => {
                this.error=response
            })
        },
        async appliedJobsDetails(id,name) {
			//console.log(id)
            const param    = {
                userId:mirror.AUTHID,
                jobId:id
            }

            const response2= await axios.post(API_BASE_URL + 'appliedJobDetails',param);
            this.jobDetails = response2.data;
            this.MAIN_NAME = name;
            this.url = response2.data.resume ? this.imagepathResume+response2.data.resume : '';
            console.log("URL:",this.jobDetails);
            //console.log("RESULT",response2.data)
            this.firstName = response2.data.fname==null ? '':response2.data.fname;
            this.user_id = response2.data.user_id==null ? '':response2.data.user_id;
            this.lastName = response2.data.lname==null ? '':response2.data.lname;
            this.primeryEmail = response2.data.email==null ? '':response2.data.email;
            //this.resume = response2.data.primeryEmail==null ? '':response2.data.primeryEmail;

            this.experience_new = response2.data.experience_new==null ? '':response2.data.experience_new.experience;
            this.area_interest = response2.data.area_interest==null ? '':response2.data.area_interest.name;
            this.comm_interest = response2.data.comm_interest==null ? '':response2.data.comm_interest.name;
            this.preferred_language = response2.data.preferred_language==null ? '':response2.data.preferred_language.name;
            this.primary_skill = response2.data.primary_skill==null ? '':response2.data.primary_skill.name;
            this.country = response2.data.country==null ? '':response2.data.country.name;
            this.addModal = true;
        },
        close(){
            this.addModal = false
        },
        // async downloadItem({ url, label }) {
        //     const response = await axios.get(url, { responseType: "blob" });
        //     const blob = new Blob([response.data], { type: "application/pdf" });
        //     const link = document.createElement("a");
        //     link.href = URL.createObjectURL(blob);
        //     link.download = label;
        //     link.click();
        //     URL.revokeObjectURL(link.href);
        // },
	},
	beforeRouteEnter(to, from, next) {
        if (storage.get('TOKEN')) {
            if(storage.USERTYPE==2)
                next();
            else if(storage.USERTYPE==3)
                next('/jobs');
            else
                next('/');	
            }
            else{
                next('/login');
            }
        }
    }
</script>

<style scoped>
.candidate_area {
    background: #1f1f20;
}
.candidate_area .area_header{
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    /*margin-bottom: 20px;*/
}
.candidate_list ul li{
    padding: 20px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.details_area{
    display: flex;
    padding: 60px 0 90px;
    align-items: flex-start;
}
.job_details{
    display: flex;
    flex: 1 1 955px;
    margin-right: 45px;
    flex-direction: column;
}
.job_detailsWpr{
    background: #1f1f20;
    padding: 50px 40px;
    display: flex;
    margin-top:30px;
    min-height: 680px;
}
.job_details .job_logo{
    padding: 15px 35px 10px 0;
}
.job_details .job_info{
    padding: 5px 0;
    width:100%;
}
.job_details .job_info h2{
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
}
.job_details .job_info .role{
    display: flex;
    flex-wrap: wrap;
    margin: 10px -15px 5px -15px;
}
.job_details .job_info .role li{
    padding: 5px 15px;
    color: #999;
    font-size: 22px;
    line-height: 28px;
    position: relative;
}
.job_details .job_info .role li:after{
    position: absolute;
    left: 100%;
    top: 5px;
    content: '.';
    color: #a5a5a5;
}
.job_details .job_info .role li:last-child:after{
    display: none;
}
.job_details .job_info .job_date{
    font-size: 19px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    padding: 15px 0;
}
.job_details .job_info .job_date .date{
    padding-right: 15px;
}
.job_details .job_info .job_date .date span{
    color: #c61e1e;
    font-weight: 600;
}
.job_details .job_info .job_date .place{
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    color: #d1d1d1;
}
.job_details .job_info .address{
    font-size: 24px;
    line-height: 30px;
    color: #e2e2e2;
    font-weight: 600;
    padding: 10px 0 20px;
}
.job_details .job_info .address h6{
    font-size: 18px;
    line-height: 24px;
    color: #d1d1d1;
    font-weight: 400;
    max-width: 400px;
    padding-top: 10px;
}
.contact li{
    font-size: 21px;
    line-height: 26px;
    font-weight: 600;
    color: #e2e2e2;
    padding: 8px 0;
}
.contact li span{
    font-weight: 400;
    color: #ccc;
}
.contact li i{
    color: #c61e1e;
    margin-right: 10px;
}
.job_details .job_info .action_btn{
    padding-top: 40px;
}
.comment_area{
    flex: 1 1 440px;
    padding: 30px 0 0;
    background: #1f1f20;
    display: flex;
    flex-direction: column;
}
.comment_area .area_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 30px 20px; */
}
.total_comments{
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    font-weight: 400;
}
.total_comments span{
    color: #c61e1e;
    font-weight: 600;
}
.sortBy select{
    background: transparent;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Play', sans-serif;
    padding: 0 8px;
    appearance: none;
}
.add_comment{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px 0 30px;
}
.add_comment .user_img{
    padding-right: 15px;
}
.add_comment .user_img img{
    width: 55px;
    height: 55px;
    border-radius: 50%;
}
.add_comment .comment_field{
    flex: 1 1 auto;
}
.add_comment .comment_field input{
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #444;
    height: 50px;
    color: #d9d9d9;
    font-size: 14px;
    font-weight: 400;
}
.comment_area .comment_list{
    padding: 30px 0 0;
}
.comment_area .comment_list li{
    padding: 25px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    widows: 50%;
    float: left;
}
.comment_area .comment_list li:nth-child(even){
    background: rgba(255, 255, 255, 0.03);
    widows: 50%;
    float: left;
}
.comment_area .view_btn{
    display: block;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    color: #fff;
    margin:  auto 0;
}
@media(max-width:1350px){
    .job_details .job_info h2{
        font-size: 35px;
        line-height: 42px;
    }
}
@media(max-width:1199px){
    .details_area{
        flex-wrap: wrap;
    }
    .details_area .job_details{
        margin: 0 0 30px 0;
    }
}
@media(max-width:767px){
    .job_details{
        flex-wrap: wrap;
        padding: 20px 20px;
    }
    .job_details .job_logo img{
        max-width: 100px;
        height: auto;
    }
    .comment_area .area_header{
        padding: 0 20px 20px;
    }
    .comment_area .add_comment{
        padding: 20px 20px 0 20px;
    }
    /* .comment_area .comment_list li{
        padding: 20px 20px 0 20px;
    } */

    .candidate_area .area_header{ display:block;}
    .job_details{ display:block;}
    .job_detailsWpr, .job_details .job_info .job_date{ display:block; padding:50px 20px;}
    .job_details .job_info h2{ font-size:27px; line-height:30px;}
    .job_details .job_info .role li{ padding:5px 9px; font-size:20px;}
    .comment_area .comment_list{ height:500px;}
}


</style>
<style>
.modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
    left: 0;
}
.job_details .job_info > p{
    max-height: 600px;
    overflow-y: scroll;
}
.job_details .job_info > p::-webkit-scrollbar{
	width: 6px;
}
.job_details .job_info > p::-webkit-scrollbar-thumb{
	background-color: #333;
    border-radius:3px;
}
.job_details .job_info p *{
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #999 !important;
    padding-top: 20px !important;
    margin-left: 0 !important;
}
</style>
