<template>
  <router-view></router-view>
</template>
<script>
import { createLocal } from 'the-storages'
const mirror = createLocal()
const storage = mirror._prx
export default {
        name: 'App',
        data() {
            return {
                storageData: mirror,
                storage: storage
            }
        },
        /*created() {
            if (window.Laravel.isLoggedin) {
                this.isLoggedIn = true
            }
        },
        methods: {
            logout(e) {
                console.log('ss')
                e.preventDefault()
                this.$axios.get('http://localhost/epworks/sanctum/csrf-cookie').then(response => {
                    this.$axios.post('http://localhost/epworks/api/logout')
                        .then(response => {
                            if (response.data.success) {
                                window.location.href = "/"
                            } else {
                                console.log(response)
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                })
            }
        },*/
  components: {
  }
}
</script>
<style>
@font-face {
    font-family: 'IRON MAN OF WAR 002 NCV';
    src: url('~@/assets/IRONMANOFWAR002NCV.woff2') format('woff2'),
        url('~@/assets/IRONMANOFWAR002NCV.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
ul, li{
  list-style-type: none;
}
a{
  text-decoration: none;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
}
body{
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Play', sans-serif;
  color: #fff;
  background: #0e0f10;
}
.container{
  max-width: 1470px;
  padding: 0 15px;
  margin: 0 auto;
}

iframe{ width:100%; vertical-align:middle;}
.section_header{
  font-family: 'IRON MAN OF WAR 002 NCV';
  font-size: 55px;
  font-weight: normal;
  line-height: 60px;
  margin-bottom: 30px;
}
.sub_header{
  font-family: 'IRON MAN OF WAR 002 NCV';
  font-size: 38px;
  font-weight: normal;
  line-height: 45px;
}
.text-center{
  text-align: center;
}
.login_btn{
  font-size: 15px;
  line-height: 20px;
  padding: 12px 38px;
  color: #fff;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-family: 'Play', sans-serif;
  background: transparent;
  margin-left: 25px;
  transition: all 0.3s ease-in-out;
}
.login_btn:hover{
  background:#fff;
  color:#000;
}
.primary_btn{
  background: #c71e1e;
  padding: 0 15px;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #fff;
  font-family: 'Play', sans-serif;
  position: relative;
  border-radius: 3px;
}
.primary_btn i{
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
	.ck-editor__editable {
	min-height: 200px;
	}
	.ck .ck-reset .ck-editor .ck-rounded-corners {
    min-height: 200px !important;
    background-color: #212122 !important;
 }
 .ck-editor__editable {
    min-height: 200px !important;
    background-color: #212122 !important;
 }

.ck-editor__editable_inline {
   min-height: 200px !important;
   background-color: #212122 !important;
}

:host ::ng-deep .ck-editor__editable_inline {
   min-height: 200px !important;
   background-color: #212122 !important;
}
.ck-voice-label{
	display: none !important;
}
.Pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 25px !important;
}
.Pagination li{
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background:transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.Pagination li .Page{
  width: 34px;
  height: 34px;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  border-radius: 0;
  background-color: transparent !important;
}
.Pagination li .Page:hover, .Pagination li .Page-active{
  border: 0;
  background: #c71e1e !important;
      padding: 12px;
  /* border: 1px solid #c71e1e; */
}
.Pagination li:hover, .Pagination li.active{
  background: #c71e1e;
  border: 1px solid #c71e1e;
}
.success{
  padding: 10px 20px 10px 45px !important;
  font-size: 16px;
  line-height:20px;
  color: #fff;
  position: relative;
  background: green;
}
.success:before{
  position: absolute;
  left:20px;
  top: 10px;
  content: "\f00c";
  font-size: 16px;
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-weight: 600;

}
.errormessage{
  color: #c71e1e;
  font-size: 16px;
  line-height: 20px;
  margin-left: 25px;
}
/* Modal */
.modal{
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    padding: 15px;
    overflow-y: scroll;
  }
  .modal::-webkit-scrollbar{
    width:6px;
    background: rgba(255,255,255,0.05);
  }
  .modal::-webkit-scrollbar-thumb{
    background-color: #999;
    border-radius:3px;
  }
  .content_wrapper{
    width: 600px;
    background: #161618;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .modal_header {
   /* display: flex;
    justify-content: space-between;
    align-items: center;*/
    padding: 20px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.modal_header h2 {
  float: left;
}

  .modal_header button{
    width: 30px;
    height: 30px;
    background: #c71e1e;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    float:right;
  }
  .modal_body{
    padding: 20px 40px;
    max-height: calc(90vh - 3.5rem);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #cacaca #f4f7fc
  }
  .modal_body::-webkit-scrollbar {
      width: 5px
  }
  .modal_body::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px grey;
      border-radius: 0
  }
  .modal_body::-webkit-scrollbar-thumb {
      background: #cacaca;
      border-radius: 0
  }
  .modal_body .form_grp{
    display: flex;
    flex-direction: column;
    margin: 0 -5px;
  }
  .modal_body .form_grp .col_2{
    display: flex;
    flex-wrap: wrap;
  }
  .modal_body .form_grp .item_grp{
    flex: 1 1 50%;
    margin: 0;
    padding: 7px 5px;
    overflow: hidden;
  }
  .modal_body .form_grp .item_grp .img_wpr{
    margin-top: 3px;
    display: flex;
  }
  .modal_body .form_grp .item_grp .img_wpr img{
    max-height: 45px;
    width: auto;
  }
  .modal_body .form_grp .item_grp .img_wpr .removeicon{
    width: 20px;
    height: 20px;
    background: #c71e1e;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    margin-left: 5px;
    cursor: pointer;
  }
  .modal_body .form_grp .item_grp input{
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
  }
  .modal_body .form_grp .item_grp textarea{
    height: 80px;
    padding: 10px 15px !important;
    margin-top: 3px !important;
  }
  .modal_body .form_grp .item_grp select{
    margin-top: 3px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 20px;
  }
  .modal_body .form_grp .item_grp span{
    color: #c71e1e;
    font-size: 14px;
    line-height: 18px;
  }
  .modal_body .form_grp .item_grp p{
    background: #c71e1e;
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    padding: 5px 10px !important;
    float:left;
    margin-bottom: 10px;
  }
  form .login_btn {
    font-size: 15px;
    line-height: 49px;
    padding: 0 38px;
    color: #fff;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    background: transparent;
    margin-left: 25px;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    margin-top: 10px;
  }
  /* end */
  @media(max-width:1199px){
    .section_header{
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 15px;
    }
    .sub_header{
      font-size: 25px;
      line-height: 30px;
    }
  }
  @media(max-width: 575px){
    .modal_body .form_grp .col_2{
      flex-direction: column;
    }
    .modal_body .form_grp .item_grp div{
      width: 100% !important;
      margin-bottom: 8px;
    }
  }
  @media(max-width: 450px){
    .modal_header, .modal_body{
      padding: 20px;
    }
  }
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>
