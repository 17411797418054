import { createRouter, createWebHistory } from 'vue-router'
import Home from './pages/Home.vue'
import Series from './pages/Series.vue'
import Series2 from './pages/Series2.vue'
import Jobs from './pages/Jobs.vue'
import Blogs from './pages/Blogs.vue'
import Cmscontent from './pages/Cmscontent.vue'
import Blogddetails from './pages/Blogddetails.vue'
import Login from './pages/Login.vue'
import Signup from './pages/Signup.vue'
import About from './pages/About.vue'
import Contact from './pages/Contact.vue'
import BlogCategory from './pages/BlogCategory.vue'
import Podcast from './pages/Podcast.vue'
//import Podcastdetails from './pages/Podcastdetails.vue'
import DashboardBillingaddress from './pages/DashboardBillingaddress.vue'
import DashboardAccountinfo from './pages/DashboardAccountinfo.vue'
import DashboardGetstarted from './pages/DashboardGetstarted.vue'
import Dashboard from './pages/Dashboard.vue'
import ForgetPassword from './pages/ForgetPassword'
import Verifymail from './pages/Verifymail'
import TestimonialDetails from './pages/TestimonialDetails.vue'
import PodcastDetails from './pages/PodcastDetails.vue'
import JobList from './pages/JobList.vue'
import JobDetails from './pages/JobDetails.vue'
import Trainingform from './pages/Trainingform.vue'
import ChangePassword from './pages/ChangePassword'
import AppliedCandidate from './pages/AppliedCandidate'
import Candidates from './pages/Candidates'
import JobEdit from './pages/JobEdit'
import DashboardProfile from './pages/DashboardProfile.vue'
import DashboardEditprofile from './pages/DashboardEditprofile.vue'
import AppliedJobs from './pages/AppliedJobs.vue'
import SavedJobs from './pages/SavedJobs.vue'
import Viewuserfrofile from './pages/Viewuserfrofile.vue'
import DashboardCandidate from './pages/DashboardCandidate.vue'
import DashboardCandidate2 from './pages/DashboardCandidate2.vue'
import TermsAndCondition from './pages/TermsAndCondition.vue'
import PrivacyAndPolicy from './pages/PrivacyAndPolicy.vue'
import ZoomMeetings from './pages/ZoomMeetings.vue'
import Chat from './pages/Chat.vue'
import ChatList from './pages/ChatList.vue'
import NewChat from './pages/NewChat.vue'
import NewChat2 from './pages/NewChat2.vue'
import GroupChat from './pages/GroupChat.vue'
import InterviewSchedule from './pages/InterviewSchedule.vue'
import InterviewReSchedule from './pages/InterviewReSchedule.vue'

//import DashboardCompany11 from './pages/DashboardCompany11.vue'
import Events from './pages/Events.vue'
import EventDetails from './pages/EventDetails.vue'
import Membership from './pages/Membership.vue'
import Agents from './pages/Agents.vue'
import PublicChat from './pages/PublicChat.vue'

const routes = [
    {
        name: 'Home',
        component: Home,
        path: '/'
    }
    ,
    {
        name: 'Series',
        component: Series,
        path: '/series'
    }
    ,
    {
        name: 'Series2',
        component: Series2,
        path: '/series2'
    }
    ,
    {
        name: 'Jobs',
        component: Jobs,
        path: '/jobs'
    }
    ,    
    {
        name: 'About',
        component: Cmscontent,
        path: '/about'
    }
    ,
    {
        name: 'Blogs',
        component: Blogs,
        path: '/blogs'
    }
    ,
    {
        name: 'Dashboard',
        component: DashboardBillingaddress,
        path: '/dashboard'
    }
    ,
    {
        name: 'BlogCategory',
        component: BlogCategory,
        path: '/blog/category/:slug'
    }
    ,
    {
        name: 'Blogddetails',
        component: Blogddetails,
        path: '/blog/:slug'
    }
    ,
    {
        name: 'Login',
        component: Login,
        path: '/login'
    }
    ,
    {
        name: 'Signup',
        component: Signup,
        path: '/signup'
    }
    ,
    {
        name: 'Contact',
        component: Contact,
        path: '/contact'
    }
    ,
    {
        name: 'About',
        component: About,
        path: '/about'
    }
    ,
    {
        name: 'ForgetPassword',
        component: ForgetPassword,
        path: '/forget-password'
    }
    ,
    {
        name: 'Podcast',
        component: Podcast,
        path: '/podcast'
    }
    ,
    {
        name: 'DashboardBillingaddress',
        component: DashboardBillingaddress,
        path: '/dashboard-billingaddress'
    }
    ,
    {
        name: 'DashboardAccountinfo',
        component: DashboardAccountinfo,
        path: '/dashboard-accountinfo'
    }
    ,
    {
        name: 'DashboardGetstarted',
        component: DashboardGetstarted,
        path: '/post-job'
    },
    {
        name: 'Dashboard',
        component: Dashboard,
        path: '/dashboard'
    },
    {
        name: 'Verifymail',
        component: Verifymail,
        path: '/activation/:email'
    },
    {
        name: 'TestimonialDetails',
        component: TestimonialDetails,
        path: '/testimonial-details'
    },
    {
        name: 'PodcastDetails',
        component: PodcastDetails,
        path: '/podcast/:slug'
    },
    {
        name: 'JobList',
        component: JobList,
        path: '/jobList'
    },
    {
        name: 'Trainingform',
        component: Trainingform,
        path: '/training'
    },
	{
        name: 'ChangePassword',
        component: ChangePassword,
        path: '/changepassword'
    },
    {
        name: 'JobEdit',
        component: JobEdit,
        path: '/jobedit/:slug'
    },
    {
        name: 'JobDetails',
        component: JobDetails,
        path: '/jobDetails/:slug'
    },
   
    {
        name: 'AppliedCandidate',
        component: AppliedCandidate,
        path: '/applied-candidate/:slug'
    },
    {
        name: 'Candidates',
        component: Candidates,
        path: '/candidates'
    },
    {
        name: 'DashboardProfile',
        component: DashboardProfile,
        path: '/dashboard-profile'
    }
    ,
    {
        name: 'DashboardEditprofile',
        component: DashboardEditprofile,
        path: '/settings'
    }
,
    {
        name: 'AppliedJobs',
        component: AppliedJobs,
        path: '/appliedJobs'
    }
    ,
    {
        name: 'SavedJobs',
        component: SavedJobs,
        path: '/savedJobs'
    },
    {
        name: 'Viewuserfrofile',
        component: Viewuserfrofile,
        path: '/profile/:email'
    },
    {
        name: 'DashboardCandidate',
        component: DashboardCandidate,
        path: '/dashboard-candidate'
    },
    {
        name: 'DashboardCandidate2',
        component: DashboardCandidate2,
        path: '/dashboard-candidate2'
    }
,
    {
        name: 'TermsAndCondition',
        component: TermsAndCondition,
        path: '/terms-and-condition'
    },
    {
        name: 'PrivacyAndPolicy',
        component: PrivacyAndPolicy,
        path: '/privacy-and-policy'
    },
	{
        name: 'ZoomMeetings',
        component: ZoomMeetings,
        path: '/live-events'
    },
	{
        name: 'NewChat',
        component: NewChat,
        path: '/chat-list2'
    },
    {
        name: 'NewChat2',
        component: NewChat2,
        path: '/chat-list'
    },
    
    {
        name: 'GroupChat',
        component: GroupChat,
        path: '/group-chat'
    },
    {
        name: 'Chat',
        component: Chat,
        path: '/chat/:receiverId'
    },
    {
        name:'ChatList',
        component: ChatList,
        path:'/chat-list'
    },
    {
        name: 'InterviewSchedule',
        component: InterviewSchedule,
        path: '/interview-schedule/:candidateId'
    },
    {
        name:'InterviewReSchedule',
        component:InterviewReSchedule,
        path:'/interview-reschedule/:candidateId'
    },
	{
		name:'Events',
		component:Events,
		path:'/Events'
    },
	{
        name: 'EventDetails',
        component: EventDetails,
        path: '/event/:slug'
    },
	{
		name:'Membership',
		component:Membership,
		path:'/membership'
	},
    {
        name:'Agents',
        component:Agents,
        path:'/agents'
    },
{
        name:'PublicChat',
        component:PublicChat,
        path:'/public-chat'
    },

]
const router = createRouter({
    mode: "history",
    history:createWebHistory(),
    routes,
    scrollBehavior() {
    window.scrollTo(0,0);
  }
})

export default router;