<template>
    <div class="gt-started-frm tabinfo">
      <h4>Videos</h4>
      <!-- <div v-if="message!=''" class="success">{{message}}</div>
      <div v-if="errormessage!=''" class="error">{{errormessage}}</div> -->
      <ul class="video_listing" >
        <li v-bind:key="video.id" v-for="video in videoList">
          <button @click="deleteVideo(video.id)" class="delete_btn"><i class="far fa-trash-alt"></i>Remove</button>
          <div class="video_wpr" v-if="video.type=='youtube'"  @click="showVideo(video.type,video.video_file,video.embed_url)">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <img v-if="video.videoimage" v-bind:src="video.videoimage" alt="" class="thumbimage">
            <a>
              <img src="@/assets/play2.png" alt="">
            </a>
            <!-- <iframe width="560" height="230" src="https://www.youtube.com/embed/46MegoVLEj8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
            </div>
          <div class="video_wpr" v-if="video.type=='vimeo'" @click="showVideo(video.type,video.video_file,video.embed_url)">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <img v-if="video.videoimage" v-bind:src="video.videoimage" alt="" class="thumbimage">
            <a>
              <img src="@/assets/play2.png" alt="">
            </a>           
          </div>
          <div class="video_wpr" v-if="video.type=='upload'" @click="showVideo(video.type,video.video_file,video.embed_url)">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <img src="@/assets/thumb.png" alt="" class="thumbimage">
            <a>
              <img src="@/assets/play2.png" alt="">
            </a>
          </div>
        </li>
        <li v-if="videoList.length==0" >No Data Found</li>
        <!-- <li>
          <button @click="videoPop" class="delete_btn"><i class="far fa-trash-alt"></i>Remove</button>
          <div class="video_wpr">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <iframe width="560" height="230" src="https://www.youtube.com/embed/gybw-yZY308" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </li>
        <li>
          <button @click="videoPop" class="delete_btn"><i class="far fa-trash-alt"></i>Remove</button>
          <div class="video_wpr">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <iframe width="560" height="230" src="https://www.youtube.com/embed/bBLki3_bzTI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </li>
        <li>
          <button @click="videoPop" class="delete_btn"><i class="far fa-trash-alt"></i>Remove</button>
          <div class="video_wpr">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <iframe width="560" height="230" src="https://www.youtube.com/embed/gybw-yZY308" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </li>
        <li>
          <button @click="videoPop" class="delete_btn"><i class="far fa-trash-alt"></i>Remove</button>
          <div class="video_wpr">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <iframe width="560" height="230" src="https://www.youtube.com/embed/bBLki3_bzTI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </li>
        <li>
          <button @click="videoPop" class="delete_btn"><i class="far fa-trash-alt"></i>Remove</button>
          <div class="video_wpr">
            <span class="thumb"><img src="@/assets/thumb.png" alt=""></span>
            <iframe width="560" height="230" src="https://www.youtube.com/embed/46MegoVLEj8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </li> -->
      </ul>
      
      <!-- <button class="save_btn">Save</button> -->
      <button @click="videoPop" class="add_btn"><i class="fas fa-plus"></i> Add Video</button>

      <div class="modal" v-if="modal">
        <div class="content_wrapper">
          <div class="modal_header">
            <h2>Add Video</h2>
            <button @click="close"><i class="fas fa-times"></i></button>
          </div>
          <div class="modal_body">
            <div class="form_grp">
              <form id="jobpost-form" v-on:submit.prevent="updateUserDetails" enctype="multipart/form-data">
                <div class="item_grp">
                  <label>Video Name</label> 
                  <input type="text" placeholder="Enter Video" v-model="state.videoName" name="videoName" id="videoName" autocomplete="off"/>
                  <span v-if="v$.videoName.$error">
                      {{ v$.videoName.$errors[0].$message }}
                  </span>
                </div> 
                <div class="item_grp">
                  <label>Video Type</label>
                  <select @change="search" v-model="state.videoType">
                    <option value="" disabled >Select Video Type</option>
                    <option v-for="option in options" :value="option.name" :key="option.id">
                      {{ option.name.toUpperCase() }}
                    </option>
                  </select>
                  <span v-if="v$.videoType.$error">
                      {{ v$.videoType.$errors[0].$message }}
                  </span>
                </div>
                <div class="item_grp" v-if="state.videoType != 'upload'">
                  <label>Embed Code</label>
                  <textarea placeholder="Enter Embed Code" cols="30" rows="10" v-model="state.embedCode" name="embedCode" id="embedCode" autocomplete="off"></textarea>
                  <!-- <span v-if="v$.embedCode.$error">
                      {{ v$.embedCode.$errors[0].$message }}
                  </span> -->
                </div>
                <div class="item_grp" v-else>
                  <label>Video File</label>
                  <input placeholder="Choose Video File" type="file" ref="video" @input="uploadVideo" name="video" id="video" autocomplete="off">
                </div>
                <div class="item_grp">
                  <p v-if="errormessage">{{errormessage}}</p>
                  <button class="add_btn" :disabled="buttonclick" >
                    <span v-if="buttonclick==true" style="color:white">Loading. . . . .</span>
                    <span v-else style="color:white"><i class="fas fa-plus" data-v-bf594912=""></i> Add Video</span>
                  </button>
                  
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      <div class="modal" v-if="modalDelete">
        <div class="content_wrapper">
          <div class="modal_header">
            <h2>Are you sure want to remove this video ?</h2>
            <!-- <button @click="close"><i class="fas fa-times"></i></button> -->
          </div>
          <div class="modal_body">
            <div class="form_grp">
              <form id="jobpost-form" v-on:submit.prevent="deleteVideoSubmit">
                <div class="item_grp">
                  <input type="hidden" v-model="deleteId" name="deleteId">
                  <button type="submit" class="add_btn">Confirm</button>
                  <button class="add_btn" @click="close">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" v-if="playVideo">
        <div class="content_wrapper">
          <div class="vid_player">
            <button @click="playVideo = false"><i class="fas fa-times"></i></button>
            <span v-if="type_popups=='youtube' || type_popups=='vimeo'" v-html="embed_url_popups"></span>
            <span v-if="type_popups=='upload'">
              <video width="580" controls>
                <source v-bind:src="imagepath+videoFilr_popups" type="video/mp4">
                Your browser does not support HTML video.
              </video>
            </span>
            <!-- <iframe width="560" height="230" src="https://www.youtube.com/embed/46MegoVLEj8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { createLocal } from 'the-storages'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'EpB',
  setup() {
    const state = reactive({
        videoName: '',
        videoType: '',
        video: '',
        embedCode: '',
    })
    const rules = computed(() => {
      return {
        videoName: {required },
        videoType: { required },
        //embedCode: state.videoType=='youtube' || state.videoType=='youtube' ? 'required' : '',
        video: state.videoType=='upload' ? {required} : ''
      }
    })
    const v$ = useValidate(rules, state)
    return {
      state,
      v$
    }
  },
  data(){
    return{
      deleteId: '',
      videoName: '',
      videoType: '',
      video: '',
      embedCode: '',
      select: this.value,
      modal: false,
      modalDelete: false,
      imageurl:'',
      imageurlnew:'',
      playVideo: false,
      buttonclick:false,
      options:[
        {
          name: 'youtube',
          id: 1
        },
        {
          name: 'vimeo',
          id: 2
        },
        {
          name: 'upload',
          id: 3
        }
      ],
      errors: [],
        errormessage: '',
        message: '',
        storageData: mirror,
        storage: storage,
        errorStr: null,
        imagepath:{},
        videoList: [],
        embed_url_popups:this.embed_url_popups,
        type_popups:this.type_popups,
        videoFilr_popups:this.videoFilr_popups,
        
    }
  },
  async created() {
    this.getVideoList();
    this.imagepath = API_IMAGE_PATH;
	},
  methods:{  	
		async getVideoList() {
      const videoDetails = await axios.post(API_BASE_URL + 'userVideoList', {userId: mirror.AUTHID});
      console.log("User Details:",videoDetails.data);
      this.videoList = videoDetails.data;
    },
    videoPop: function(){
      this.modal = true
    },
    showVideo: function(type,video_file,embed_url){
      this.playVideo = true
      this.type_popups = type;
      this.videoFilr_popups = video_file;
      this.embed_url_popups = embed_url;
    },
    deleteVideo: function(dId){
      this.modalDelete = true
      this.deleteId   =   dId;
    },
    close: function(){
      this.modal = false;
      this.modalDelete = false;
    },
    async updateUserDetails() {
      this.buttonclick = true;
      console.log("Enter");
			const config = {
        headers: { 'content-type': 'multipart/form-data' }
        }
        this.v$.$validate(this.v$.$error);
      if(!this.v$.$error){
        console.log("Enter2");
        let formData = new FormData();        	 
				//console.log(this.state.profileImage);        	 
        if(this.state.video!=''){
          formData.append('video', this.state.video);
        }
        formData.append('videoName', this.state.videoName);   
        formData.append('videoType', this.state.videoType);   
        formData.append('userId', mirror.AUTHID);
        formData.append('embedCode', this.state.embedCode);
        console.log("PARAM:",formData);
        if (this.state.videoName && this.state.videoType && (this.state.embedCode || this.state.video)) {
            console.log("Enter3");
				axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
				axios.post(API_BASE_URL + 'addUserVideos', formData,config)
				.then(response => {                        	
				console.log("Enter5");                   	
						console.log(response)                          	
              if (response.data.result == 'success') {
								//this.message ='';
								this.message = response.data.message;
								this.modal = false;
								this.state.video = '';
								this.state.videoName='';
								this.state.videoType='';
								this.state.embedCode='';
              //this.$router.push({ name: "JobList" });
              this.buttonclick = false;
              this.getVideoList();
                // setTimeout(() => {
                //     this.message = '';
                //     this.errormessage = '';
                //     console.log(this.errormessage);
                // }, 5000);
                this.showToast("Success",this.message,"success"); 
              } else {
								this.errormessage = response.data.message;
                this.buttonclick = false;
                this.showToast("Failed!",this.errormessage,"danger"); 
								//this.message = '';
              }
        })
					.catch(error => {
					//console.log('error-data:', error)
					console.error(error);
					});

					console.log(responses); 
        });
          
        } else {
            this.errormessage = 'All Fields are Mandatory';
            this.buttonclick = false;
            this.showToast("Warning!",this.errormessage,"warning"); 
        }
      } else {
        this.errormessage = 'All Fields are Mandatory';
        this.buttonclick = false;
        this.showToast("Warning!",this.errormessage,"warning"); 
      }
      
    },
    async deleteVideoSubmit() {
      await axios.post(API_BASE_URL + 'deleteVideo', {"deleteId":this.deleteId})
      .then(response => {
        console.log(response);
          if (response.data.result == 'success') {
              this.errormessage ='';
              this.message = response.data.message;
              this.modalDelete = false
              //this.$router.push({ name: "JobList" });
              this.showToast("Success!",this.message,"success"); 
          } else {
              this.errormessage = response.data.message;
              this.message = '';
              this.showToast("Failed!",this.errormessage,"danger"); 
          }
      })
      .catch(error => {
          //console.log('error-data:', error)
          console.error(error);
      });

      this.getVideoList();
      
    },
    uploadVideo(e) {
      const file = e.target.files[0]
      this.state.video = file
      this.profilepath=URL.createObjectURL(file);
      console.log("image1",this.video)
    },
    showToast(title,description,type){
      createToast({
          title: title,
          description: description
      },
      {
          type: type,
          position: 'bottom-right',
          // hideProgressBar: 'true',
          // toastBackgroundColor: color,
      })
  },
  },
  watch: {
    value: function (val) {
      this.state.videoType = val;
    }
  },
  
  
  
}
</script>

<style scoped>
  .video_listing{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .video_listing li{
    flex: 0 1 33.333%;
    padding: 15px;
    display: flex;
    flex-direction: column;
  }
  .video_listing li .video_wpr{
    background: #333;
    position: relative;
    line-height: 0;
    height: 230px;
    cursor: pointer;
  }
  .video_listing li .thumb{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .video_listing li .thumb img{
    max-width: 100px;
    height: auto;
    opacity: 0.2;
  }
  .video_listing li .thumbimage{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    object-fit: cover;
  }
  .video_listing li a{
    display: inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 3;
    cursor: pointer;
  }
  .video_listing li a img{
    max-width: 55px;
    height: auto;
  }
  /*.video_listing li iframe{
    width: 100%;
    position: relative;
    z-index: 2;
  }*/
  button.delete_btn{
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    padding: 4px 10px;
    color: #d9d9d9;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    background: #c71e1e;
    cursor:pointer;
    align-self:flex-end;
    margin-bottom: 1px;
  }
  button.add_btn{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 30px;
    color: #fff;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    background: transparent;
    margin-left: 25px;
    cursor:pointer;
    float:right;
  }
  button.save_btn{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 30px;
    color: #fff;
    border: 1px solid #c71e1e;
    background: #c71e1e;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    cursor:pointer;
  }
  button i{
    margin-right: 5px;
  }
  .vid_player{
    padding: 0;
    position: relative;
    line-height:0;
  }
  .vid_player button{
    position: absolute;
    right: -10px;
    top: -10px;
    width: 25px;
    height: 25px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #dd1e1e;
    cursor: pointer;
  }
  .vid_player button i{
    margin: 0;
  }
  .vid_player iframe{
    width: 100%;
    height: 336px;
  }
  @media(max-width:991px){
    .video_listing li{
      flex: 1 0 50%;
    }
  }
  @media(max-width:670px){
  .video_listing li{
    flex: 0 0 100%;
  }
}
</style>