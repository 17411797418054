<template>
    <div class="dashboard_main">
        <Dashboardheader />
        <div class="middle-part dashboard">
            <Dashboardsidebar />
            <div class="rigt-part">
                <h3>Job Post</h3>
                <div class="gt-started-frm tabinfo">
                    <h4>Let's get started</h4>
                    <form id="jobpost-form" v-on:submit.prevent="saveJobpost">
                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Company Name For This Job *</label>
                                <input type="text" placeholder="Enter Company Name For This Job" v-model="state.companyName" name="companyName" id="companyName" autocomplete="off" />
                                <span v-if="v$.companyName.$error">
                                    {{ v$.companyName.$errors[0].$message }}
                                </span>
                            </div>
                            <div class="tw-clm">
                                <label>Your Role In The Hiring Process *</label>
                                <!-- <v-select :options="['Canada', 'United States']"></v-select> -->
                                <!-- <v-select :options="roleList"></v-select> -->
                                <select v-model="state.role" name="role" id="role" autocomplete="off">
                                    <option value="">Select an Option </option>
                                    <option v-bind:key="roles.id" v-for="roles in roleList" :value="roles.id">{{roles.role_name}}</option>
                                </select>
                                <span v-if="v$.role.$error">
                                    {{ v$.role.$errors[0].$message }}
                                </span>
                            </div>
                        </div>

                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Your Typical Hiring Budget</label>
                                <select v-model="state.budget" name="budget" id="budget" autocomplete="off">
                                    <option value="">Select an Option </option>
                                    <option v-bind:key="budgets.id" v-for="budgets in budgetList" :value="budgets.id">{{budgets.budget_name}}</option>
                                </select>
                                <span v-if="v$.budget.$error">
                                    {{ v$.budget.$errors[0].$message }}
                                </span>
                            </div>
                            <div class="tw-clm">
                                <label>Job Title *</label>
                                <input type="text" placeholder="Enter Job Title" v-model="state.title" name="title" id="title" autocomplete="off" />
                                <span v-if="v$.title.$error">
                                    {{ v$.title.$errors[0].$message }}
                                </span>
                            </div>
                        </div>
                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Job Description *</label>                            
                                <ckeditor style="color:black;"  :editor="editor" v-model="state.description" :config="editorConfig" name="description" id="description"></ckeditor>
                            </div>
                        </div>
                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Where will an employee report to work? *</label>
                                <select v-model="state.empWorkReported" name="empWorkReported" id="empWorkReported" autocomplete="off">
                                    <option value="">Select an Option </option>
                                    <option v-bind:key="workReport.id" v-for="workReport in workReportList" :value="workReport.id">{{workReport.work_report_name}}</option>
                                </select>
                                <span v-if="v$.empWorkReported.$error">
                                    {{ v$.empWorkReported.$errors[0].$message }}
                                </span>
                            </div>
                            <div class="tw-clm">
                                <label>Job Experience *</label>
                                <select v-model="state.jobExperience" name="jobExperience" id="jobExperience" autocomplete="off">
                                    <option value="">Select an Option </option>
                                    <option v-bind:key="exper.id" v-for="exper in experienceList" :value="exper.id">{{exper.experience}}</option>
                                </select>
                                <span v-if="v$.jobExperience.$error">
                                    {{ v$.jobExperience.$errors[0].$message }}
                                </span>
                            </div>
                        </div>

                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Enter A Street Address</label>
                                <GMapAutocomplete type="text" placeholder="Enter A Street Address" name="streetAddress" id="streetAddress"
                                                    @place_changed="setPlace">
                                </GMapAutocomplete>
                                <!-- <span v-if="v$.streetAddress.$error">
                                    {{ v$.streetAddress.$errors[0].$message }}
                                </span> -->
                            </div>
                        </div>

                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>How do you want your location displayed to job seekers?</label>
                                <GMapMap :center="center"
                                            :zoom="7"
                                            map-type-id="terrain"
                                            style="width: 100%; height: 800px">
                                    <GMapCluster>
                                        <GMapMarker :key="index"
                                                    v-for="(m, index) in markers"
                                                    :position="m.position"
                                                    :clickable="true"
                                                    :draggable="true"
                                                    @dragend="showLocation"
                                                    @click="center=m.position" />
                                    </GMapCluster>
                                </GMapMap>
                                <div class="map-text">
                                    <p>Display exact location <br>Job seekers will see a map with a location pin.</p>
                                    <input type="hidden" v-bind:value="oLat" name="cLat" id="cLat" />
                                    <input type="hidden" v-bind:value="oLng" name="cLng" id="cLng">
                                </div>
                            </div>
                        </div>

                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Country: *</label>
                                <input type="text" placeholder="Enter Country" v-bind:value="country" name="country" id="country1" autocomplete="off" />

                                <!-- <span v-if="v$.country.$error">
                                    {{ v$.country.$errors[0].$message }}
                                </span> -->
                            </div>
                            <div class="tw-clm">
                                <label>City *</label>
                                <input type="text" placeholder="Enter City" v-bind:value="city" name="city" id="city1" autocomplete="off" />
                                <!-- <span v-if="v$.city.$error">
                                    {{ v$.city.$errors[0].$message }}
                                </span> -->
                            </div>
                        </div>

                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>How many hires? *</label>
                                <input type="text" placeholder="Enter hires" v-model="state.hires" name="hires" autocomplete="off" @input="NumbersOnly"/>
                                <span v-if="v$.hires.$error">
                                    {{ v$.hires.$errors[0].$message }}
                                </span>
                                <p>This helps us match you with the right number of applicants.</p>
                            </div>
                            <div class="tw-clm">
                                <label>Type Of Job *</label>
                                <select v-model="state.jobType" name="jobType" autocomplete="off">
                                    <option value="">Select an Option </option>
                                    <option v-bind:key="jobType.id" v-for="jobType in jobTypeList" :value="jobType.id">{{jobType.job_type}}</option>
                                </select>
                                <span v-if="v$.jobType.$error">
                                    {{ v$.jobType.$errors[0].$message }}
                                </span>
                            </div>
                        </div>
                        <h4>Contact Person</h4>
                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Email </label>
                                <input type="text" placeholder="Enter Email" v-model="state.cemail" name="cemail" autocomplete="off" />
                                <!-- <span v-if="v$.cemail.$error">
                                    {{ v$.cemail.$errors[0].$message }}
                                </span> -->
                                
                            </div>
                            <div class="tw-clm">
                                <label>Phone Number </label>
                                <input type="text" placeholder="Enter Phone Number" v-model="state.cphoneNumber" name="cphoneNumber" autocomplete="off" @input="acceptNumber"/>
                                <!-- <span v-if="v$.cphoneNumber.$error">
                                    {{ v$.cphoneNumber.$errors[0].$message }}
                                </span> -->
                            </div>
                        </div>
                        <div class="rw-gap"> </div>
                        <div class="action_wpr">
                            <!-- <input type="submit" value="CONTINUE"> -->

                            <button type="submit" class="login_btn" :disabled="buttonclick" style="margin-left:0px;cursor:pointer;background: red;border: red;">
                                <span v-if="buttonclick==true" style="color:white">Posting. . . . .</span>
                                <span v-else style="color:white"> Post</span>
                            </button>

                            <button type="button" @click="$router.go(-1)" class="login_btn">Cancel</button>
                            <!-- <div v-if="errormessage!=''" style="color:red;" class="errormessage">{{errormessage}}</div> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable no-undef */
    
    import axios from 'axios'
    // import { BASE_URL } from '../config'
    import { API_BASE_URL } from '../config'
    import { reactive, computed } from "vue"
    import useValidate from "@vuelidate/core"
    import { required } from "@vuelidate/validators"
    import Dashboardheader from '../components/Dashboardheader.vue'
    import Dashboardsidebar from '../components/Dashboardsidebar.vue'
    //import { useGeolocation } from '../useGeolocation'
    // import { Loader } from '@googlemaps/js-api-loader';
	import { createLocal } from 'the-storages'
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	import CKEditor from '@ckeditor/ckeditor5-vue';

    import { createToast } from 'mosha-vue-toastify';
    import 'mosha-vue-toastify/dist/style.css';

    const mirror = createLocal()
    const storage = mirror._prx
    const placeSearch=''
    const autocomplete=''

    //const GOOGLE_MAPS_API_KEY = 'AIzaSyAgo9N4oGMqvdE2A9Pq1ppnqn6Z0DSU98w';
    // const GOOGLE_LINK = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=';

    export default {
        name: 'DashboardGetstarted',
        // inject: ['moshaToast'],
        components: {
            Dashboardheader,
            Dashboardsidebar,
            ckeditor: CKEditor.component
        },
        setup() {
            const state = reactive({
                companyName: '',
                role: '',
                budget: '',
                title: '',
                empWorkReported: '',
                jobExperience: '',
                hires: '',
                jobType: '',
                oLat: '',
                oLng: '',
                cphoneNumber:'',
                cemail:'',
                description:''
                
            })

            const rules = computed(() => {
                return {
                    companyName: { required },
                    role: { required },
                    budget: { required },
                    title: { required },
                    empWorkReported: { required },
                    jobExperience: { required},
                    hires: { required },
                    jobType: { required },
                    description:{required}
                    // cnfPassword:{ required, sameAsPassword: sameAs("userPassword") },
                }
            })
            const v$ = useValidate(rules, state)
            

            return {
                state,
                v$
            }
        },

        data() {
            return {
                companyName: '',
                role: '',
                budget: '',
                title: '',
                empWorkReported: '',
                jobExperience: '',
                country: '',
                city: '',
                hires: '',
                oLat: '',
                oLng: '',
                cphoneNumber:'',
                cemail:'',
                jobType: '',
                roleList: {},
                errors: [],
                errormessage: '',
                storageData: mirror,
                storage: storage,
                placeSearch: placeSearch,
                autocomplete: autocomplete,
                budgetList: {},
                buttonclick:false,
                streetAddress:'',
                center: { lat: 51.093048, lng: 6.842120 },
                markers: [
                    {
                        position: {
                            lat: 51.093048, lng: 6.842120
                        },
                    }
                    , // Along list of clusters
                ],
                location: null,
                gettingLocation: false,
                errorStr: null,
                editor: ClassicEditor,
                description: '',
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        },
      
        async created() {
            //this.showToast("Failed","Data Added successfully!","danger");
            //this.showToast("Success","Data Added successfully!","success");
            
            //console.log(mirror);
            this.state.description='';
            if (!("geolocation" in navigator)) {
                this.errorStr = 'Geolocation is not available.';
                //alert(' Sorry, but the following error occurred:' + this.errorStr)
                return;
            }

            this.gettingLocation = true;
            // get position
             navigator.geolocation.getCurrentPosition(pos => {
                 this.gettingLocation = false;
                 this.location = pos;
                 const geocoder2 = new google.maps.Geocoder();
                 const latlng = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
                 this.oLat = pos.coords.latitude;
                 this.oLng = pos.coords.longitude;

                 geocoder2.geocode({
                     'latLng': latlng
                 }, function (results, status) {
                     if (status == google.maps.GeocoderStatus.OK) {
                         if (results[0]) {
                             console.log(results[0].address_components)
                             document.getElementById("streetAddress").value = results[0].formatted_address
                             var locations3 = results[0].address_components;
                             for (var ii = 0; ii < locations3.length; ii++) {
                                 if (locations3[ii].types[0] == "country") {
                                     document.getElementById("country1").value = locations3[ii].long_name;
                                 }
                                 if (locations3[ii].types[0] == "locality") {
                                    document.getElementById("city1").value = locations3[ii].long_name;
                                    
                                 }

                             }
                         } 
                     }
                 });

                this.center = { lat: this.location.coords.latitude, lng: this.location.coords.longitude };
                this.markers = [
                    {
                        position: {
                            lat: this.location.coords.latitude, lng: this.location.coords.longitude
                        },
                    }
                ];

            }, err => {
                this.gettingLocation = false;
                this.center = { lat: 36.778259, lng: -119.417931 };
                this.markers = [
                    {
                        position: {
                            lat: 36.778259, lng: -119.417931
                        },
                    }
                ];
                this.errorStr = err.message;
                //alert(' Sorry, but the following error occurred:' + this.errorStr)

            })
            
            const response2 = await axios.get(API_BASE_URL + 'job');
            console.log("RES",response2.data);
            this.jobTypeList = response2.data.jobtypes[0];
            this.roleList = response2.data.hire_role[0];
            this.budgetList = response2.data.budgetdetails[0];
            this.workReportList = response2.data.work_report[0];
            this.experienceList = response2.data.experience[0];
        },
        methods: {
            acceptNumber() {
                var x = this.state.cphoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.state.cphoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
			},
            NumbersOnly() {
                this.state.hires = this.state.hires.replace(/[^0-9]/g, "");
            },
            setPlace() {
                const address = document.getElementById("streetAddress").value;
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({ 'address': address }, (result, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                       //document.getElementById("country").value = '';
                        console.log(result[0])
                      //document.getElementById("city").value = result[0].address_components[1].long_name
                       var locations2 = result[0].address_components;
                        for (var ii = 0; ii < locations2.length; ii++) {
                            if (locations2[ii].types[0] === "country") {
                                //document.getElementById("country").value = locations2[ii].long_name;
                                //alert(document.getElementById("country").value);
                                this.country = locations2[ii].long_name;
                                //console.log(this.country);
                            }
                            if (locations2[ii].types[0] === "locality") {
                                //document.getElementById("city").value = locations2[ii].long_name;
                                //alert(document.getElementById("city").value);
                                this.city = locations2[ii].long_name;
                                //console.log(this.city);
                            }
                              
                        }
                        var latitude = result[0].geometry.location.lat();
                        var longitude = result[0].geometry.location.lng();

                        this.oLat = latitude;
                        this.oLng = longitude;

                        this.center= { lat: latitude, lng: longitude },
                        this.markers= [
                            {
                                position: {
                                    lat: latitude, lng: longitude
                                },
                            }
                            , // Along list of clusters
                        ]
                    }
                })
            },
            showLocation: function (evt) {
                console.log('hello1')
                this.oLat = evt.latLng.lat();
                this.oLng = evt.latLng.lng();
                //console.log(this.oLng);
                const posi = { lat: evt.latLng.lat(), lng: evt.latLng.lng()}
                this.geocodePosition(posi)
            },
            geocodePosition: function (pos) {
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({ 'latLng': pos}, (result, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        console.log(result[0].address_components)
                       // document.getElementById("city").value = result[0].address_components[3].long_name
                        var locations = result[0].address_components;
                        for (var i = 0; i < locations.length; i++) {
                            if (locations[i].types[0] == "country") {
                                document.getElementById("country1").value = locations[i].long_name;
                                this.country = locations[i].long_name;
                                //console.log(this.country);
                            } else {
                                //document.getElementById("country1").value = locations[i].long_name
                            }
                            if (locations[i].types[0] == "administrative_area_level_1") {
                                document.getElementById("city1").value = locations[i].long_name;
                                this.city = locations[i].long_name;
                                //console.log(this.city);
                            } else {
                                //document.getElementById("city1").value = locations[i].long_name
                            }
                        }
                        document.getElementById("streetAddress").value = result[0].formatted_address
                    }
                })
            },

            async saveJobpost() {
                this.buttonclick = true;
                var addresss = document.getElementById("streetAddress").value;
                var countryss = document.getElementById("country1").value;
                var cityss = document.getElementById("city1").value;
                var lat = document.getElementById("cLat").value;
                var lng = document.getElementById("cLng").value;

                this.v$.$validate(this.v$.$error);
                // console.log(addresss);
                // console.log(countryss);
                // console.log(cityss);
                // console.log(lat);
                // console.log(lng);

                // console.log(this.state.companyName);
                // console.log(this.state.role);
                // console.log(this.state.budget);
                // console.log(this.state.title);
                // console.log(this.state.empWorkReported);
                // console.log(this.state.jobExperience);
                // console.log(this.state.hires);
                // console.log(this.state.jobType);

                if (!this.v$.$error) {
                    if (addresss && countryss && cityss && this.state.companyName && this.state.role && this.state.budget && this.state.title && this.state.empWorkReported && this.state.jobExperience && this.state.hires && this.state.jobType) {

                        //axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                            const inputData = {
                                userId: mirror.AUTHID,
                                companyName: this.state.companyName,
                                role: this.state.role,
                                budget: this.state.budget,
                                address:addresss,
                                title: this.state.title,
                                description: this.state.description,
                                empWorkReported: this.state.empWorkReported,
                                jobExperience: this.state.jobExperience,
                                hires: this.state.hires,
                                country: countryss,
                                city: cityss,
                                lat: lat,
                                lng: lng,
                                cemail:this.state.cemail,
                                cphoneNumber:this.state.cphoneNumber,
                                jobType: this.state.jobType};
                            //console.log("Input-data",inputData)
                            //console.log("Link",API_BASE_URL + 'add-job/')
                            axios.post(API_BASE_URL + 'add-job', inputData)
                                .then(response => {
                                    console.log('data:', response.data)
                                    if (response.data.result == 'success') {
                                        //console.log('data:', response.data)
                                        this.errormessage = response.data.message;
                                        this.message = response.data.messase;
                                        this.buttonclick = false;
                                        this.$router.push({ name: "JobList",hash: '#show' });
                                    } else {
                                        this.errormessage = response.data.message;
                                        this.message = response.data.messase;
                                        this.buttonclick = false;
                                        this.showToast("Failed",this.errormessage,"danger");
                                        
                                    }
                                })
                                .catch(error => {
                                    console.log('error-data:', error)
                                    console.error(error);
                                });
                            //console.log(responses);
                        //});

                    } else {
                        this.errormessage = 'All * Fields are Mandatory';
                        this.buttonclick = false;
                        this.showToast("Warning!",this.errormessage,"warning"); 
                    }
                } else {
                    this.errormessage = 'All * Fields are Mandatory';
                    this.buttonclick = false;
                    this.showToast("Warning!",this.errormessage,"warning"); 
                    
                }
                // setTimeout(() => {
                //     this.errormessage = '';
                //     console.log(this.errormessage);
                // }, 5000);
            },
            showToast(title,description,type){
                createToast({
                    title: title,
                    description: description
                },
                {
                    type: type,
                    position: 'bottom-right',
                    // hideProgressBar: 'true',
                    // toastBackgroundColor: color,
                })
            },
        },
        beforeRouteEnter(to, from, next) {
		if (storage.get('TOKEN')) {
			if(storage.USERTYPE==2)
				next();
			else if(storage.USERTYPE==3)
				next('/jobs');
			else
				next('/');	
			}
			else{
				next('/login');
			}
		}

    }

</script>
<style scoped>
    .vue-map-container {
        height: 400px;
    }
    span {
        color: red;
    }
    .tw-clm p{
        padding-top: 5px !important;
        color: #999;
        font-size: 14px;
        line-height: 18px;
    }
    .vue-map {
        height: 80vh !important
    }
    .textarea {
        height: 200px
    }
    .action_wpr{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
   @media(max-width:767px){
       .middle-part.dashboard .tw-clm{
           flex: 1 1 100%;
       }
   }
</style>