<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
    <Dashboardsidebar/>
    <div class="rigt-part">
        <h3>Job Post</h3>
        <div class="gt-started-frm">
        <h4>Billing Address</h4>
        <p>Prices do not include taxes, which will be additionally charged where appropriate.</p>
        <div class="rw-gap"> 
        <div class="tw-clm"><label>Country: *</label> <select><option value="usa">USA </option></select></div> 
        <div class="tw-clm"><p class="crncy">Currency: $ (USA Dollar)</p></div>
        </div>

        <div class="rw-gap"> 
        <div class="tw-clm"><label>GSTIN *</label> <input type="text" placeholder=""/> </div> 
        <div class="tw-clm"> </div>
        </div>

        <div class="rw-gap">
        <div class="tw-clm"><label class="input"> <input type="checkbox"> Not a registered person</label></div>
        </div>
        <div class="rw-gap"> 
        <div class="tw-clm"><label>Address Line 1: </label> <input type="text" placeholder=""/> </div> 
        <div class="tw-clm"> <label>Address Line 2: </label> <input type="text" placeholder=""/> </div>
        </div>
        <div class="rw-gap">
        <div class="three-clm"><label>City:</label><input type="text" placeholder=""></div>
        <div class="three-clm"><label>State:</label><input type="text" placeholder=""></div>
        <div class="three-clm"><label>Pincode:</label><input type="text" placeholder=""></div>
        </div>
        <input type="button" class="back-btn" value="Back"> <input type="submit" value="CONTINUE">
        </div>
    </div>
</div>
</div>
</template>


<script>
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
export default {
name: 'DashboardBillingaddress',
components:{
Dashboardheader,
Dashboardsidebar
}
}
</script>