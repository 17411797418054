<template>
    <li>
        <div class="icon">
        <router-link :to="'/profile/' + agentData.unique_user_id" >
        <img v-bind:src="imagepath+agentData.profile_image" v-bind:alt="agentData.job_title" v-if="agentData.profile_image">	
        <img :src="require(`@/assets/avatar.png`)" alt="" v-else>	
        <!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
        </router-link>
        </div>
            <div class="job_info">
            <!-- <div class="job_title"><span>{{agentData.job_title}}</span></div> -->
            <h3>
                <router-link :to="'/profile/' + agentData.unique_user_id" style="color:white" v-if="membershipType=='Premium'">
                    {{agentData.fname}} <b style="color:#bfbaba;">
                    <!-- {{agentData.unique_user_id}} -->
                    000{{(agentData.id==19)? "0":agentData.id}}
                    </b>
                </router-link>
                <router-link to=""  style="color:white" v-else>
                    {{agentData.fname}} <b style="color:#bfbaba;">
                    <!-- {{agentData.unique_user_id}} -->
                    000{{(agentData.id==19)? "0":agentData.id}}
                    </b>
                </router-link>
                <span v-if="agentData.totalPoints=='100'"><i class="fas fa-star" ></i> Verified</span>
                <span style="margin-left:7px !important;"><i class="fas fa-certificate"></i> {{agentData.membershipType}}</span>
                <span style="margin-left:7px !important;" v-if="agentData.is_email_verify=='yes'"><i class="fas fa-check-circle"></i> Email Verified</span>
                <span style="margin-left:7px !important;" v-else><i class="fas fa-times-circle"></i> Email Not Verify</span>
            </h3>
            <p>{{agentData.designation}}</p>
            <ul class="candidate_info">
            <!-- <li><i class="fas fa-envelope"></i>{{agentData.email}} </li>           
            <li><i class="fas fa-phone"></i>{{agentData.phone_no}}</li> -->
            </ul>
    
            </div>
        <div class="job_date">
          <!--<div class="date">Job: <span>{{agentData.job_title}}</span></div>-->
            <div class="date"><span style="color:#cda53a">Created at:</span> {{moment(agentData.created_at).startOf("month").format('MMMM')}} {{moment(agentData.created_at).startOf("day").format('DD')}}, {{moment(agentData.created_at).startOf("year").format('YYYY')}}</div>
            <div class="action_btnWpr">
                <!-- <button class="sign_submit" type="submit" v-on:click="execute(agentData.id)">View Application</button> -->
                <button type="button" class="sign_submit" v-if="membershipType=='Premium'">
                    <router-link :to="'/profile/' + agentData.unique_user_id" style="color:white;cursor:pointer" >View Profile</router-link>
                </button>
                <!-- <button v-if="interviewDetails.isScheduled!=='Yes'" type="button" class="sign_submit"><router-link :to="'/interview-schedule/' + agentData.unique_user_id" style="color:white;cursor:pointer">Schedule Interview</router-link></button>
                <button v-else type="button" class="sign_submit"><router-link :to="'/interview-reschedule/' + agentData.unique_user_id" style="color:white;cursor:pointer">Reschedule Interview</router-link></button> -->
            </div>
        </div>
    </li>
    </template>
    
    <script>
    //import axios from 'axios'
    //import { API_BASE_URL } from '../config'
    import moment from "moment";
    import { createLocal } from 'the-storages'
    import { API_IMAGE_PATH } from '../config'
    const mirror = createLocal()
    const storage = mirror._prx
    
    export default{
        name: 'AgentCardMain',
        props:['agentData', 'icon' ,'callback'],
        data() {
            return {
                storageData: mirror,
                storage: storage,
                moment: moment,
                imagepath:{},
                interviewDetails:{},
                membershipType:''
            }
        },
        async created(){
            this.membershipType = mirror.MEMBERSHIP_TYPE;
            this.imagepath=API_IMAGE_PATH;
            this.userId=mirror.AUTHID;
            this.userType=mirror.USERTYPE;
            //console.log(this.imagepath);
    
            // const param    = {
            //     userId:mirror.AUTHID,
            //     candidateId:this.agentData.user_id
            // }
            // const response= await axios.post(API_BASE_URL + 'getInterviewDetails',param);
            // console.log("INTERVIEW UPDATE1: ",response.data);
            // this.interviewDetails = response.data;
        },
        methods:{
            execute(test) {
                if (this.callback) {
                    this.callback(test)
                }
            }
        }
    }
    </script>
    <style scoped>
    .icon img{
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        background: #c71e1e;
        padding: 2px;
    }
    .job_info{
        flex: 1 1 290px;
        padding-left: 30px;
    }
    .job_date{
        flex: 1 1 150px;
    }
    .job_info h3{
        font-size: 24px;
        line-height: 30px;
        color: #d9d9d9;
    }
    .job_info .job_title{
        font-size: 16px;
        line-height: 22px;
        color: #d9d9d9;
        margin-bottom:5px;
    }
    .job_info i{
        color: #c71e1e;
        margin-right: 7px;
        font-size: 16px;
    }
    .job_info .candidate_info{
        display: flex;
        margin: 0 -10px;
        flex-wrap: wrap;
    }
    .job_info .candidate_info li{
        padding: 5px 10px;
        color: #999;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        display:flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .job_date{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .job_date .date{
        font-size: 15px;
        line-height: 22px;
        color: #fff;
        font-weight: 700;
        margin-top:10px;
        margin-bottom: 5px;
    }
    .job_date .date span{
        color: #c71e1e;
    }
    .job_date .action_btnWpr{
        display:flex;
        flex-direction: row-reverse;
        padding-top: 4px;
    }
    .sign_submit{
        background: #c71e1e;
        border: 0;
        font-size: 11px;
        line-height: 14px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        padding: 6px 8px;
        margin: 2px;
    }
    
    @media(max-width:1199px){
        .job_info{
            padding-left:20px;
        }
        .job_info h3{
            font-size: 20px;
            line-height: 26px;
        }
    }
    @media(max-width:670px){
        .job_info{
            flex: 0 0 100%;
            padding: 10px 0;
            text-align: center;
        }
        .job_info .candidate_info{
            justify-content: center;
        }
        .job_date {
            align-items: center;
        }
        .icon{
            flex: 0 0 100%;
            text-align: center;
        }
    }
    
    </style>