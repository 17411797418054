<template>
<Navigation/>
    <Banner bg="banner2.png">
        <div class="figure">
            <img src="@/assets/guard.png" alt="">
        </div>
        <div class="inr_text">
           <h1>All Testimonial</h1>
        </div>
        <!-- <div class="brand">
            <img src="@/assets/brand.png" alt="" style="padding: 75px;width: 75%;">
        </div> -->
    </Banner>
    <div class="container">
        <div class="testimonial_section">
            <ul class="testimonial_list">
                <li v-bind:key="testimonial.id" v-for="testimonial in testimonials">
                    <TestimonialCardDetails :name="testimonial.name" :description="testimonial.description" :image="testimonial.image" />
                </li>
                <!--<swiper-slide v-bind:key="testimonial.id" v-for="testimonial in testimonials" >
                    <TestimonialCardDetails :name="testimonial.name" :description="testimonial.description" :image="testimonial.image" />
                </swiper-slide>-->
                <!-- <li>
                    <JobCard title="Protective Intelligence Agent" month="August" date="28, 2021" icon="icon1.png"/>
                </li>
                <li>
                    <JobCard title="Static Agent" month="July" date="12, 2021" icon="icon2.png"/>
                </li>
                <li>
                    <JobCard title="Detail Leader" month="April" date="30, 2021" icon="icon3.png"/>
                </li>
                <li>
                    <JobCard title="Tactical Commander" month="March" date="028, 2021" icon="icon4.png"/>
                </li>
                <li>
                    <JobCard title="Protective Intelligence Agent" month="March" date="07, 2021" icon="icon5.png"/>
                </li>
                <li>
                    <JobCard title="Assistant Detail leader" month="March" date="05, 2021" icon="icon6.png"/>
                </li> -->
            </ul>
        </div>
        <!-- <ul class="bredcrumbs">
            <li class="active">1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li><i class="fas fa-ellipsis-h"></i></li>
        </ul> -->
        <BrandSlider/>
    </div>
    <Footer/>
</template>


<script>
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import Banner from '../components/Banner.vue'
//import JobCard from '../components/JobCard.vue'
import BrandSlider from '../components/BrandSlider.vue'
//import TestimonialCard from './TestimonialCard.vue'
import TestimonialCardDetails from '../components/TestimonialCardDetails.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'

export default{
    name: 'Jobs',
    components: {
		Banner,
		BrandSlider,
        TestimonialCardDetails,
		Navigation,
		Footer
    },
    data(){
        return{
            testimonials: {}
        }
    },
    mounted() {
		// Fetch initial results
		this.getResults();
    },
    methods:{
        async getResults() {			
			await axios.get(API_BASE_URL + 'testimonial')
            .then(response => {
                console.log("TESTIMONINAL LIST:",response.data);
                this.laravelData = response.data;
                this.testimonials = response.data;
                this.imagepath=API_IMAGE_PATH
                this.isLoading = false
            });
		}
    }
}
</script>
<style>
.testimonial_section{
    padding: 60px 0;
}
.testimonial_list li{
    padding: 15px 0;
}
</style>
