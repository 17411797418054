<template>
 <Navigation/>
 <div class="about_us_main">
    <div class="about_us">
        <div class="container">
            <div class="row">
                <div class="about_cont" style="padding-right: 8%;">
                    <img src="@/assets/about_us.png" alt="" draggable="false" />
                    <h2>Executive <strong>Protection</strong> Lifestyle </h2>
                    <p>
                    The executive protection lifestyle brand is designed for all agents of the private security industry. Here, private sector professionals can congregate, network, find jobs, be discovered, and keep a pulse on the culture of one of the fastest-growing industries in the world. <br>
                    <br>
                    This is the executive protection industry's first online home, created to foster a community where professionals may advance their skills and careers to the next level. With digital courses and live events, you'll learn how to implement and leverage the tools that have helped the best in our industry do what they do.<br>
                    
                    <br>
                    With your company profile, you'll be able to find the agents you need to forge lasting relationships with your clients, principles, and stakeholders that will position your company for success long-term.
                    <br>
                    <br>
                    This is a place for us, the private security operators, and the companies that help us put food on the table. This is a place for us to see and be seen, to be heard and understood, and to work together to improve ourselves. This is our primary web presence and our primary social media channel.
                    <br>
                    <br>
                    I've said it before and I will say it again, protection is more than just a job, it's a lifestyle and this platform is where we can live that lifestyle in a way that contributes to our careers companies, and the private security industry. Welcome to executiveprotectionlifestyle.com
                    <br>
                </p>
                </div>
                <div class="about_person">
                    <div class="person">
                        <img src="@/assets/about_person2.jpg" alt="" draggable="false" />
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="about_cont" style="flex:1;padding:0 15px;width:100%;margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;">
                    <h2 style="width:890px;margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;">The <strong>Agents</strong> that excel in The <strong>Private Security</strong> Game are not always the ones you would expect</h2>
                    <p style="margin-bottom:20px;">Hello, my name is Byron Rodgers and I am a service-centered Executive Protection Agent, Video Blogger, Published Author, and Veteran on a steadfast mission to improve the quality of the world by making good people more dangerous. Over the span of a decade, I have gained extensive private security experience, conducting private security operations for each available VIP demographic industry-wide in over 60 countries. During my time in the military, I served in the United States Marine Corps infantry with two deployments to Iraq as a Squad Leader for four years. Right afterward, I became an Executive Protection Agent (bodyguard). I was fortunate enough to experience a seamless transition from the military back into the civilian life I was once well-acquainted with. Ultimately, this transition inspired me to author the book, “Finding Meaning After the Military”, and I’m currently working on a few more titles within the personal growth and executive protection categories.</p>
                    <p style="margin-bottom:20;"> Today, I proudly serve as the Chief Executive Officer at Bravo Research Group, a boutique private security company out of Southern California. I’m the founder of Protector Nation, a training company for first responders, private security professionals and civilians designed to help good people become more dangerous. Through my life events, Protector Symposiums we have trained many protectors from all walks of life both domestically and internationally. I own and operate my own executive protection school and training organization called The League of Executive Protection Specialist, where I help private security professionals take their careers to the next level. I am also the one-stop-shop security consultant for many high net worth individuals, their families, and various corporate initiatives. After completing my AA in business management I went on to earn a Bachelor’s degree in the Science of Psychology from the University of Phoenix and finally I acquired my Master’s degree in the art of Psychology with a minor in Forensic Psychology from North Central University. I have also received Strategic Interventionist training at the Tony Robbins Training Center. I live by the values of wisdom, discipline, purpose, freedom, and excellence and my prayer is that my interaction with you and the rest of the world is in total alignment with those principles as often as possible.</p>
                    <p style="margin-bottom:0;"><router-link to="/contact" class="about_btn">Work with us</router-link></p>
                </div>
            </div> -->
        </div>
    </div>
    <div v-if="userId=='' || userId==null" class="about_us" style="background-size: unset;background-size: unset;padding: 50px 0px 50px 0px !important;text-align: center;">
        <h2 style="max-width: 900px; line-height: 35px;margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;margin-bottom:40px;"> Create your profile now and tap into <strong class="golden_color"> The biggest social media network </strong> of agents, companies and opportunities within the private security industry.</h2>
        <p >
            
            <router-link to="/signup" class="buid_your_profile_now_btn"> Build Your Profile Now 
            </router-link>
        </p>
    </div>
    <div class="about_us" style="padding: 100px 0 30px 0 !important;">
        <div class="container">
            <div class="about_contss" style="text-align: center;">
                <img src="@/assets/leadership.png" alt="" draggable="false" />
                    <h2 style="max-width: 950px; margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;">The <strong>Agents</strong> that excel in The <strong>Private Security</strong> Game are not always the ones you would expect</h2>
            </div>
            <div class="row" style="margin-bottom:50px;">
                <div class="about_person">
                    <div class="person">
                        <!-- <img src="@/assets/about_person.jpg" alt="" draggable="false" /> -->
                        <img src="@/assets/abou2.jpg" alt="" draggable="false" />
                    </div>
                </div>
                <div class="about_cont" style="padding-left: 8%;padding-right: 0%;">
                    <p style="margin-bottom:20px;">Hello, my name is Byron Rodgers and I am a service-centered Executive Protection Agent, Video Blogger, Published Author, and Veteran on a steadfast mission to improve the quality of the world by making good people more dangerous. For a decade, I have gained extensive private security experience, conducting private security operations for each available VIP demographic industry-wide in over 60 countries. During my time in the military, I served in the United States Marine Corps infantry with two deployments to Iraq as a Squad Leader for four years. Right afterward, I became an Executive Protection Agent (bodyguard). I was fortunate enough to experience a seamless transition from the military back into the civilian life I was once well-acquainted with. Ultimately, this transition inspired me to author the book, “Finding Meaning After the Military”, and I’m currently working on a few more titles within the personal growth and executive protection categories.</p>
                 <p style="margin-bottom:20;"> Today, I proudly serve as the Chief Executive Officer at Bravo Research Group, a boutique private security company out of Southern California. I’m the founder of Protector Nation, a training company for first responders, private security professionals and civilians designed to help good people become more dangerous. Through my life events, Protector Symposiums we have trained many protectors from all walks of life both domestically and internationally. I own and operate my executive protection school and training organization called The League of Executive Protection Specialist, where I help private security professionals take their careers to the next level. I am also the one-stop-shop security consultant for many high net worth individuals, their families, and various corporate initiatives. After completing my AA in business management I went on to earn a Bachelor’s degree in the Science of Psychology from the University of Phoenix and finally I acquired my Master’s degree in the art of Psychology with a minor in Forensic Psychology from North Central University. I have also received Strategic Interventionist training at the Tony Robbins Training Center. I live by the values of wisdom, discipline, purpose, freedom, and excellence and my prayer is that my interaction with you and the rest of the world is in total alignment with those principles as often as possible.</p>
                 <p style="margin-bottom:0;">
                     <router-link to="/contact" class="about_btn">Contact Byron</router-link></p>
                </div>
                
            </div>
            <!-- <div class="row">
                <div class="about_cont" style="flex:1;padding:0 15px;width:100%;margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;">
                    <h2 style="width:890px;margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;">The <strong>Agents</strong> that excel in The <strong>Private Security</strong> Game are not always the ones you would expect</h2>
                    <p style="margin-bottom:20px;">Hello, my name is Byron Rodgers and I am a service-centered Executive Protection Agent, Video Blogger, Published Author, and Veteran on a steadfast mission to improve the quality of the world by making good people more dangerous. Over the span of a decade, I have gained extensive private security experience, conducting private security operations for each available VIP demographic industry-wide in over 60 countries. During my time in the military, I served in the United States Marine Corps infantry with two deployments to Iraq as a Squad Leader for four years. Right afterward, I became an Executive Protection Agent (bodyguard). I was fortunate enough to experience a seamless transition from the military back into the civilian life I was once well-acquainted with. Ultimately, this transition inspired me to author the book, “Finding Meaning After the Military”, and I’m currently working on a few more titles within the personal growth and executive protection categories.</p>
                    <p style="margin-bottom:20;"> Today, I proudly serve as the Chief Executive Officer at Bravo Research Group, a boutique private security company out of Southern California. I’m the founder of Protector Nation, a training company for first responders, private security professionals and civilians designed to help good people become more dangerous. Through my life events, Protector Symposiums we have trained many protectors from all walks of life both domestically and internationally. I own and operate my own executive protection school and training organization called The League of Executive Protection Specialist, where I help private security professionals take their careers to the next level. I am also the one-stop-shop security consultant for many high net worth individuals, their families, and various corporate initiatives. After completing my AA in business management I went on to earn a Bachelor’s degree in the Science of Psychology from the University of Phoenix and finally I acquired my Master’s degree in the art of Psychology with a minor in Forensic Psychology from North Central University. I have also received Strategic Interventionist training at the Tony Robbins Training Center. I live by the values of wisdom, discipline, purpose, freedom, and excellence and my prayer is that my interaction with you and the rest of the world is in total alignment with those principles as often as possible.</p>
                    <p style="margin-bottom:0;"><router-link to="/contact" class="about_btn">Work with us</router-link></p>
                </div>
            </div> -->
        </div>
    </div>
<!-- <div class="leadership">
    <div class="container">
        <div class="row">
            <div class="leadership_heading">
                <img src="@/assets/leadership.png" alt="" draggable="false" />
            </div>
            <div class="leadership_cont">
                <div class="row">
                    <div class="block">
                        <h3>Honor</h3>
                        <img src="@/assets/honor.png" alt="" draggable="false" />
                        <p>
                            BRG's conception is rooted in the founder’s experience in the United States Marine Corps, where honor is believed to be sacred. So sacred in fact, that death is affectionately welcomed in lieu of honor escaping
                            him. This stronghold is reflected in all that we do, in who we are and in everything we represent. BRG vows to forever be in keeping with this Marine Corps tradition..
                        </p>
                    </div>
                    <div class="block">
                        <h3>Discipline</h3>
                        <img src="@/assets/discipline.png" alt="" draggable="false" />
                        <p>
                            We believe discipline is the driving factor behind success and is what separates the good from the great. Motivation will fade, and when it does, those with the discipline to be consistent will win. Victories and
                            valuable things may be acquired but they are not long maintained, enjoyed, appreciated or leveraged over time without discipline.
                        </p>
                    </div>
                    <div class="border"></div>
                    <div class="block">
                        <h3>Balance</h3>
                        <img src="@/assets/balance.png" alt="" draggable="false" />
                        <p>
                            Balance is probably our most important attribute and value. We feel that the balance must be applied to all things because it is a requirement for homeostasis. Too much of anything is not good and the only way
                            you know that you have enough is through the harmony that comes from a result of finding the right balance of anything. Because our security strategies are highly customized to our clients, balance followed by
                            flexibility are a primary and secondary consideration in all that we do.
                        </p>
                    </div>
                    <div class="block">
                        <h3>Evolution</h3>
                        <img src="@/assets/evolution.png" alt="" draggable="false" />
                        <p>
                            Executive protection is an ever living and changing ecosystem. As the clientele's preferences change so must the practitioner's focus. By making customer service equally as important as security and evolving our
                            security strategies with the needs of our clients as well as the most salient security industry tactics, we are able to maintain mutually beneficial efficient protective measures with our users long-term.
                        </p>
                    </div>
                    <router-link to="/contact" class="leadership_btn">Work with us</router-link>
                   
                </div>
            </div>
        </div>
    </div>
</div> -->
</div>
<Footer/>
</template>
<script>
import Navigation from '../components/Navigation.vue';
import Footer from '../components/Footer.vue';
import { createLocal } from 'the-storages';

const mirror = createLocal()
const storage = mirror._prx;

export default{
    name: 'About',
    components:{
        Navigation,
        Footer
    },
     data() {
        return {
            storageData: mirror,
            storage: storage,
        }
    },
    created(){
        this.userId=mirror.AUTHID;
        
    },
}
</script>
<style scoped>
strong.golden_color {
    color: #cda53a;
    font-weight: 700;
    background: -webkit-linear-gradient(#ffba00, #94824f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.buid_your_profile_now_btn:hover {
    background: #c71e1e;
}
.buid_your_profile_now_btn  {
    font-family: 'Play',sans-serif;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    padding: 17px 33px;
    border-radius: 0;
    text-transform: uppercase;
    cursor: pointer;
    width: 205px;
    letter-spacing: 1px;
    text-decoration: none;
}
        /* .about_us {padding: 160px 0 0px !important;} */
        .about_us .about_contss img{
            max-width: 100%;
        }
        .about_us_main .row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap; justify-content:space-between;}
        /* .about_us .row{align-items:center;} */
        .about_us{background: url('~@/assets/log_bg.jpg') no-repeat #111112;background-size:cover;padding:160px 0 100px;border-bottom:1px solid #1d1d1d;}
        .about_us .about_cont{flex:1 1 500px;}
        .about_us .about_cont img{max-width:100%;height:auto;vertical-align:middle;border-style:none;margin-bottom:40px;}
        
        .about_us .about_contss h2{
            font-family:'Play',sans-serif;font-size:36px;line-height:35px;font-weight:400;color:#fff;margin-bottom:50px;
            word-break: break-all;
            max-width:950px;margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;
        }
        
        .about_us .about_cont h2{font-family:'Play',sans-serif;font-size:36px;line-height:35px;font-weight:400;color:#fff;margin-bottom:50px;
        }
        .about_us .about_contss h2 strong{
            color:#cda53a;font-weight:700;background: -webkit-linear-gradient(#ffba00, #94824f);-webkit-background-clip: text;-webkit-text-fill-color: transparent;
            
            }
        .about_us .about_cont h2 strong{
            color:#cda53a;font-weight:700;background: -webkit-linear-gradient(#ffba00, #94824f);-webkit-background-clip: text;-webkit-text-fill-color: transparent;
            
            }
        .about_us .about_cont p{font-family:'Play',sans-serif;font-size:18px;line-height:30px;font-weight:400;color:#6f7070;margin-bottom:30px;}
        .about_us .about_cont p:nth-child(2n){margin-bottom:80px;}
        .about_us .about_cont .about_btn{font-family:'Play',sans-serif;font-size:16px;line-height:1;font-weight:400;background-color:transparent;color:#fff;border:1px solid #fff;padding:17px 33px;border-radius:0;text-transform:uppercase;cursor:pointer;width:205px;letter-spacing:1px;text-decoration:none;}
        .about_us .about_cont .about_btn:hover{ background: white; color: black;}
        .about_us .about_person{flex:1 1 300px; padding:16px 18px 16px 0;}
        /* .about_us .about_person .person{position:relative;width:100%;background-color:#1c1d1e;padding:60px 0 0 0;} */
        .about_us .about_person .person{position:relative;width:100%;padding:0px 0 0 0;}
        .about_us .about_person .person img{max-width:100%;height:auto;vertical-align:middle;border-style:none;display:block;margin-left:auto;margin-right:auto;}
        .about_us .about_person .person:before{content:'';position:absolute;background-color:#262626;width:458px;height:1px;background-color:#262626;top:-16px;right:-18px;}
        .about_us .about_person .person:after{content:'';position:absolute;background-color:#262626;width:1px;height:310px;background-color:#262626;top:-16px;right:-18px;}
        .leadership{background:url('~@/assets/leadership_bg.jpg'),#0f1011;background-repeat:no-repeat;padding:90px 0;}
        .leadership .leadership_heading{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;align-self: flex-start;}
        .leadership .leadership_cont{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
        .leadership .leadership_cont .block{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;padding: 35px 25px;}
        .leadership .leadership_cont .block img{max-width:100%;height:auto;vertical-align:middle;border-style:none;margin-bottom:30px;height:92px;}
        .leadership .leadership_cont .block h3{font-family:'Play',sans-serif;font-size:40px;line-height:35px;font-weight:700;color:#cda53a;margin-bottom:35px;background: -webkit-linear-gradient(#ffba00, #94824f);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
        .leadership .leadership_cont .block p{font-family:'Play',sans-serif;font-size:16px;line-height:24px;font-weight:400;color:#767676;}
        .leadership .leadership_heading img{max-width:100%;height:auto;vertical-align:middle;border-style:none;margin:35px 15px 35px 0;}
        .leadership .leadership_btn{font-family:'Play',sans-serif;font-size:15px;line-height:1;font-weight:400;background-color:transparent;color:#fff;border:1px solid #fff;padding:14px 34px;border-radius:0;text-transform:uppercase;cursor:pointer;letter-spacing:2px;text-decoration:none;margin: 35px 25px;}
        .leadership .leadership_cont .border{width:calc(100% - 50px);height:1px;background:#28292a;margin: 0 auto;}
       
       
        @media(max-width:1199px){
          .about_us{padding:120px 0 80px;}
          .about_us .about_cont img{width:180px;margin-bottom:20px;}
          .about_us .about_cont h2{width:100%;font-size:20px;line-height:26px;font-weight:400;color:#fff;margin-bottom:20px;}
          .about_us .about_cont p{width:100%;font-size:16px;line-height:24px;margin-bottom:20px;}
          .about_us .about_cont p:nth-child(2n){margin-bottom:40px;}
          .about_us .about_person .person:before{width:300px;}
          .about_us .about_cont .about_btn{font-size:14px;padding:15px 33px;}
          .about_us .about_person .person{width:100%;padding:30px 0 0 0;text-align:center;display:block;text-align:center;}
          .leadership{padding:40px 0;}
          .leadership .leadership_cont .block img{height:36px;}
          .leadership .leadership_heading img{width:200px;margin:35px 25px 0;}
          .leadership .leadership_heading{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
          .leadership .leadership_cont{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
          .leadership .leadership_cont .block h3{font-size:25px;line-height:1;margin-bottom:15px;}
          .leadership .leadership_cont .block p{font-size:16px;line-height:24px;}
        }
        @media(max-width: 830px){
            .about_us .about_cont{order:2; flex: 1 1 100%;}
            .about_us .about_person .person img{max-width:100%;height:auto;margin-bottom:25px;}
        }
        @media(max-width:767px){
          .about_us .about_cont{width:100%;  }
          .about_us .about_person{width:300px;display:block;margin:0 auto;}
          .leadership .leadership_cont .block{text-align:center;}
          .leadership .leadership_heading {text-align: center;}
          .leadership .leadership_btn{float:none;margin:35px auto 0 auto;}
        }
        @media(max-width:575px){
            .leadership .leadership_cont .block{flex: 1 1 100%; position: relative;}
            .leadership .leadership_cont .block:after{position:absolute; content:''; left:25px; right:25px; bottom:0; border-bottom: 1px solid #28292a;}
            .leadership .leadership_cont .block:last-of-type:after{display:none;}
            .leadership .leadership_cont .border{display: none;}
            .about_us .about_contss h2{
            font-family:'Play',sans-serif;font-size:36px;line-height:35px;font-weight:400;color:#fff;margin-bottom:50px;
                margin-top:40px;text-align:center;display:block;margin-left: auto;margin-right:auto;
        }
        }

    
</style>