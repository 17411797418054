<template>
  <swiper :slides-per-view="4" @swiper="onSwiper" :slidesPerView="'auto'" :pagination='{"clickable": true}' class="testimonial_slider" :navigation="true">
    <swiper-slide v-bind:key="meeting.id" v-for="meeting in allMeetingList.data">
      <div style="cursor:pointer;" class="testimnial" v-on:click="execute(meeting.id,meeting.topic,meeting.join_url,JSON.parse(meeting.json_data).id)">
        <div class="test_header">
            <img :src="require(`@/assets/zoom.png`)" alt="">	
            <div class="user">
                <h4 style="font-size:20px !important;color:#cda53a;"> {{meeting.topic}}</h4>
                <h4> <i class="far fa-clock" title="Start Time" style="color:#cda53a;"></i>&nbsp;&nbsp;{{moment(meeting.updated_at).format("YYYY-MM-DD HH:mm:ss")}}</h4>
                <!-- <button class="sign_submit" type="submit" >Join Live Evet Link</button> -->
            </div>
        </div>
        <!-- <div class="">
            <button class="sign_submit" type="submit" >Join Zoom Meeting Link</button>
        </div> -->
      </div>
    </swiper-slide>
    <!-- <div v-if="testimonialCount>5" class="test_footer">
        <router-link to="/testimonial-details" class="login_btn">See More</router-link>
    </div> -->
    </swiper>
    <div class="modelPopups" v-if="addModal" style="display:block">
        <div class="content_wrapper modal-content" style="position: absolute;">
            <!-- <div class="content_wrapper" style="width:100% !important;height:100% !important;"> -->
            <div class="modal_header" style="position:relative">
                <h2 class="block-heading">Join Live Events - {{topic}}</h2>
                <button @click="close" class="sign_submit flow-close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body" v-if="showZoomForm" style="height:90%;">
                <form id="jobpost-form" v-on:submit.prevent="applyForZoomMeeting" >
                    <div class="form_grp">
                        <input type="hidden" v-model="meetingId" >
                        <input type="hidden" v-model="link" >
                        
                        <div class="item_grp">
                            <div style="width:48%;float:left;">
                                <label>First Name* : </label> 
                                <input type="text" v-model="fname" placeholder="Enter First Name" name="fname">
                                
                            </div>
                            <div style="width:48%;float:right;">
                                <label>Last Name* : </label>
                                <input type="text" v-model="lname" placeholder="Enter Last Name" name="lname">
                                
                            </div>
                        </div> 
                        
                        <div class="item_grp">
                            <div >
                                <label>Email* : </label>
                                <input type="text" v-model="userEmail" placeholder="Enter Email" name="userEmail">
                                
                            </div>
                        </div> 
                    </div>
                    <label for="" > Select Level : </label>
                    <div class="bottom_cd" style="width: 100% !important;display: flex;">
                        <div class="cards" style="width: 100%;">
                            
                            <div class="card" style="background: rgb(0, 117, 178);width: 310px;height: 65px !important;"  v-bind:class="{ active: isActive=='level1' }">
                                <label for="level1" style="cursor:pointer;">
                                <input v-on:click="isActiveFunction('level1')" v-model="level" type="radio" name="level" id="level1" value="level1" style="display:;position: absolute;top: 10px;right: 10px;"/>
                                <div class="blog_wpr" style="padding: 10px;">
                                    <div class="thirty-three-text" >
                                        <h3>{{levelList[0].level}}</h3>  
                                        <h4>{{levelList[0].title}}</h4> 
                                        <!-- <p class="tt-u sub-text"> </p>  -->
                                    </div>
                                </div>
                                </label>
                            </div>
                            
                            <div class="card" style="background: rgb(240, 119, 49);width: 310px;height: 65px !important;" v-bind:class="{ active: isActive=='level2' }">
                                <label for="level2" style="cursor:pointer;">
                                <input v-on:click="isActiveFunction('level2')" type="radio" v-model="level" name="level" id="level2" value="level2" style="display:;position: absolute;top: 10px;right: 10px;"/>
                                <div class="blog_wpr" style="padding: 10px;">
                                    <div class="thirty-three-text" >
                                        <h3>{{levelList[1].level}}</h3>  
                                        <h4>{{levelList[1].title}}</h4> 
                                        <!-- <p class="tt-u sub-text"> </p>  -->
                                    </div>
                                </div>
                                </label>
                            </div>
                            
                            <div class="card" style="background: rgb(237, 30, 12);width: 310px;height: 65px !important;"  v-bind:class="{ active: isActive=='level3' }">
                                <label for="level3" style="cursor:pointer;">
                                <input v-on:click="isActiveFunction('level3')" type="radio" v-model="level" name="level" id="level3" value="level3" style="display:;position: absolute;top: 10px;right: 10px;"/>
                                <div class="blog_wpr" style="padding: 10px;">
                                    <div class="thirty-three-text" >
                                        <h3>{{levelList[2].level}}</h3>  
                                        <h4>{{levelList[2].title}}</h4> 
                                        <!-- <p class="tt-u sub-text">  </p>  -->
                                    </div>
                                </div>
                                </label>
                            </div>
                            <br/>
                            
                        </div>
                    
                    </div>
                    <div class="form_grp">
                        <div class="item_grp" style="text-align:center;">
                            <button type="submit" class="sign_submit_new">Join Live Events Now</button>
                        </div>
                        <p style="color:red;" v-if="errormessage!=''">{{errormessage}}</p>
                    </div>
                </form>
            </div>
            <div class="modal_body_iframe" v-else style="height:95%;">
                <iframe :src="`https://zoom.us/wc/${mId}/join?prefer=1&un=${meetingId}`" sandbox="allow-forms allow-scripts" allow="microphone; camera; fullscreen" style="width:100%;height:100%;"></iframe>
                <!-- <iframe name="iframe1" :src="link" style="width:100%;height:100%;" ></iframe> -->
                <input type="hidden" v-model="link" class="sign_submit">
                
                <div class="item_grp">
                    <a class="sign_submit" :href="link" target="iframe1">Join Now</a>
                    <button type="submit" class="sign_submit" @click="close">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script >
import axios from 'axios'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { createLocal } from 'the-storages'
//import { reactive, computed } from "vue"
//import useValidate from "@vuelidate/core"
//import { required , email} from "@vuelidate/validators"
import moment from "moment";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

const mirror = createLocal()
const storage = mirror._prx

SwiperCore.use([Pagination]);
export default {
    name: "ZoomSlider",
    components: {
        Swiper,
        SwiperSlide
    },
    // setup() {
    //     return {
    //     modules: [Navigation],
    //     };
    // },
    // setup() {
    //     const state1 = reactive({
    //         fname:'',
    //         lname:'',
    //         userEmail:''
    //     })
    //     const rules1 = computed(() => {
    //         return {
    //             fname:{required},
    //             lname:{required},
    //             userEmail:{required , email}
    //         }
    //     })
    //     const v2$ = useValidate(rules1, state1)
    //     return {
    //         state1,
    //         v2$
    //     }
    // },
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            isLoading: true,
            allMeetingList: {},
            totalMeetingCount: '',
            index: '' ,
            swiper: null,
            error:'',
            error1: '',
            error2:'',
            topic:this.topic,
            meetingId:this.meetingId,
            link:this.link,
            showZoomForm:true,
            is_show:false,
			nofound:false,
            is_loader:true,
            addModal:false,
            imagepath:'',
            fname:'',
            lname:'',
            userEmail:'',
            errormessage:'',
            level:'',
            isActive: '',
            userNewId:'',
            levelList:[],
            // image: images[0]
        }
    },
    async created(){
        const responseLevel = await axios.get(API_BASE_URL + "levelList");
        this.levelList = responseLevel.data;

        this.userNewId = mirror.AUTHID;
        let levelDetails = await axios.post(API_BASE_URL + "getLevelById",{"userId":this.userNewId});
        
        this.level = levelDetails.data.levelName;
        //console.log("responce: ",levelDetails.data.levelName);
    },
    mounted() {
		// Fetch initial results
        this.imagepath = API_IMAGE_PATH+'appliedJobDocument/';
        this.index=0;
		this.getResults();
        //this.autoChangeSlide();
        
	},
	methods: { 
        isActiveFunction(value){
            if(value=='level1'){
                this.isActive = 'level1';
            } else if(value=='level2') {
                this.isActive = 'level2';
            } else if(value=='level3') {
                this.isActive = 'level3';
            }
        },
        getResults() {
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID,
                    groupArray:(this.groupArray)?this.groupArray:""
                };
                axios.post(API_BASE_URL + `allMeetingListSlider`, inputData)
                .then(response => {
                    this.allMeetingList = response.data;
                    this.totalMeetingCount = response.data.total;
                    console.log("Meeting List:",response.data);

                    this.is_loader=true;
                    this.is_show=this.allMeetingList.last_page>1?true:false;

                    // if(this.allMeetingList.data?.length>0){
                    //     this.nofound=false;
                    //     this.is_loader=false;
                    // }   else    {
                    //     this.nofound=true;
                    //     this.is_loader=false;
                    // }
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        },
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        handleSlideTo() {
            if(this.index < 1){
                this.index = (this.index +1);
                this.swiper.slideTo(5);
            } else {
                this.index = 0;
                this.swiper.slideTo(0);
            }
        },
        autoChangeSlide() {
            setInterval(() => {
                this.handleSlideTo();
            }, 10000);
        },
        async execute(id,topic,link,mId) {
            this.errormessage = '';
            //console.log(link)
            this.meetingId = id;
            this.mId = mId;
            this.topic = topic;
            this.link = link;
            this.level = this.level;
            console.log("newData: ",this.level);
            //console.log("AUTH",mirror);
            // if(mirror!=''){
            //     this.state.fname = (mirror.TOKEN.name)?mirror.TOKEN.name:"";
            //     this.state.lname = (mirror.NAME)?mirror.NAME:"";
            //     this.state.email = (mirror.USEREMAIL)?mirror.USEREMAIL:"";
            // } else {
            //     this.state.fname = '';
            //     this.state.lname = '';
            //     this.state.email = '';
            // }
            this.showZoomForm = true;
            this.addModal = true;
        },
        async applyForZoomMeeting() {
            this.errormessage = '';
            //this.v2$.$validate(this.v2$.$error1);
            //if (!this.v2$.$error1) {
                if (this.fname && this.userEmail && this.lname ) {
                    const param    = {
                        userId:(mirror.AUTHID)? mirror.AUTHID:"",
                        userName:this.fname+' '+this.lname,
                        email:this.userEmail,
                        meetingId:this.meetingId,
                        level:this.level,
                        link:this.link
                    }
                    console.log("param",param);
                    await axios.post(API_BASE_URL + 'joinZoomMeeting', param)
                    
                    .then(response => { 
                        this.showZoomForm = false;  
                        //this.addModal = false;                     	
                        console.log("MEETING: ",response)                        	
                        this.fname = '';
                        this.lname = '';
                        this.userEmail = '';
                        this.level = '';
                    })
                    .catch(error => {
                        console.error(error);
                    });
                } else {
                    //console.log("ELSE");
                    this.errormessage = 'All Fields are Mandatory';
                    console.log("ELSE",this.errormessage);
                }
            //} else {
                //this.errormessage = 'All Fields are Mandatory';
            //}
            //this.addModal = false;
        },
        close(){
            this.addModal = false
        },
	}
}
</script>
<style scoped>
div::after {
  clear: both !important;
  visibility: initial !important;
  /* content: ""; */
  display: block !important;
  font-size: 20px !important;
  color: white !important;
}
.block-heading{
    float: none !important;
}
button.flow-close{
    float: none !important;
    position: absolute;
    right: -18px;
    top: -18px;
    width: 35px;
    height: 35px;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    background: red;
    border-radius: 50%;
    border: 5px solid #030303;
}
.modal-content {
    border-radius: 8px;
}
/* .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
    background: red !important;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    background: red !important;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-prev, .middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-next {
    position: absolute !important;
    bottom: -5px !important;
    left: 0 !important;
    top: auto;
    background: red;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-next {
    left: 30px !important;
} */

.modal_body_iframe {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
    .sign_submit{
        background: #c71e1e;
        border: 0;
        font-size: 11px;
        line-height: 14px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        padding: 6px 8px;
        margin: 2px;
    }
    /* .sign_submit:hover {
        border: 2px  solid red;
        
    } */
    .sign_submit_new{
        font-size: 15px;
        line-height: 20px;
        padding: 12px 38px;
        color: #fff;
        border: 1px solid #fff;
        text-transform: uppercase;
        font-family: 'Play', sans-serif;
        background: transparent;
        margin-top: 25px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .sign_submit_new:hover {
        background: #b91e1e;
        
    }
    .testimnial{
        background: #1e1e1e;
        padding: 15px;
        border-left: 2px solid #b91e1e;
    }
    .test_header{
        display: flex;
        align-items: center;
        /* padding-bottom: 10px; */
        /* padding-left: 10px; */
    }
    .test_header img{
        margin: 0 0px;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }
    .test_header .user h4{
        font-size: 10px;
        line-height: 18px;
        color: #fff;
        padding-bottom: 5px;
        font-weight: 400;
        margin-left:8px;
    }
    .test_body p{
        font-size: 16px;
        line-height: 23px;
        color: #a1a1a1;
        padding-left: 40px;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .test_body p:before{
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 27px;
        height: 21px;
        background: url('~@/assets/quote.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media(max-width:575px){
        .testimnial{
            padding: 15px;
        }
        .test_body p{
            font-size: 14px;
            line-height: 19px;
            padding-left: 30px;
        }
        .test_body p:before{
            width: 18px;
            height: 14px;
        }
        .test_header img{
            margin: 0 12px;
        }
        .test_header .user h4{
            font-size: 18px;
            line-height: 23px;
        }
         .test_header{
        display: block;
       /* align-items: center;
         padding-bottom: 10px; */
        /* padding-left: 10px; */
    }
        
    }
</style>
<style scoped>
    .modal_body .form_grp .item_grp select {
        margin-top: 3px !important;
        border: 1px solid #3d3d3d;
        background: #212122;
        padding: 10px 15px !important;
        width: 100%;
        color: #d9d9d9;
    }
    .modelPopups {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0,0,0,0.9);
        display: flex !important;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        padding: 10px;
    }
    .testimonial_slider .swiper-slide{
        width: 300px;
        margin: 0 15px;
    }
    .test_footer{
      display: flex;
      justify-content: center;
      padding: 30px 0 0;
    }
    @media(max-width:575px){
        .testimonial_slider .swiper-slide{
            width: 100%;
            margin: 0 10px;
        }
    }
</style>
<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 1 0 20%;
  position: relative;
  margin: .5em;
  padding: 0em;
  /* min-height: 4em; */
  /* background: white; */
  /* border: 3px solid grey; */
  border-radius: 5px;
}

.modelPopups .content_wrapper{
    max-width: 600px !important;
    width: 85% !important;
}

@media (max-width:599px){
    .modal_body .form_grp .item_grp >*{
        width: 100%;
        margin: 5px 0;
    }
    .modal_body .form_grp .item_grp{
        display: flex;
        row-gap: 15px;
        flex-direction: column;
    }
    .modal_body .form_grp .item_grp > :nth-child(1),
    .modal_body .form_grp .item_grp > :nth-child(2){
        width: 100% !important;
    }
    #jobpost-form .bottom_cd>.cards{
        display: flex;
        row-gap: 15px;
        flex-wrap: wrap;
        flex-direction: column;
    }
    #jobpost-form .bottom_cd>.cards .card:first-child{
        margin-top: 10px;
    }
    #jobpost-form .bottom_cd>.cards .card{
        width: 100% !important;
        flex-grow: 1;
        margin: 0;
    }
}



/* .active { border: 5px solid green; } */
</style>