<template>
<li style="cursor:pointer;" >
    <div class="icon">
        <img v-bind:src="imagepath+candidateData.users.profile_image" v-bind:alt="candidateData.users.fname" v-if="candidateData.users.profile_image">
        <img :src="require(`@/assets/avatar.png`)" alt="" v-else>
    </div>
    <div class="job_info">
        <router-link :to="'/chat/' + candidateData.users.unique_user_id" >
        <h3>{{candidateData.users.fname}} {{candidateData.users.lname}}</h3>
        <div class="job_title" v-if="candidateData.login_history!=null">
            <span v-if="candidateData.login_history.login_status=='yes'"><i class="fas fa-circle" style="color:green;"></i> Online</span>
            <span v-else><i class="fas fa-circle" style="color:#999999;"></i> Offline</span>
        </div>
        <div v-else class="job_title">
            <span ><i class="fas fa-circle" style="color:#999999;"></i> Offline</span>
        </div>
        <div class="job_title" v-if="lastChatMessage!=''"><span>{{lastChatMessage.message}}</span></div>
        <div class="job_title" v-else ><span></span></div>
        </router-link>
    </div>
    <div class="job_date">
        <div class="date" v-if="lastChatMessage!=''"><span style="color:#999999;">{{moment(lastChatMessage.created_at).startOf("month").format('MM')}}</span> {{moment(lastChatMessage.created_at).startOf("day").format('DD')}}, {{moment(lastChatMessage.created_at).startOf("year").format('YYYY')}}</div>
        <div class="action_btnWpr" v-if="userType==2">
            <!-- <button class="sign_submit" type="button" >Block</button> -->
            <button v-if="blockStataus=='Unblock'" class="sign_submit" type="button" v-on:click="showPopups(candidateData.users.id,'block')">Block</button>
            <button v-if="blockStataus=='Block'" type="button" class="sign_submit" v-on:click="showPopups(candidateData.users.id,'unblock')">Unblock</button>
        </div>
        <div class="action_btnWpr" v-else>
            <button v-if="blockStatausAuth=='Unblock'" class="sign_submit_new" type="button" ></button>
            <button v-if="blockStatausAuth=='Block'" type="button" class="sign_submit_new">Blocked You</button>
        </div>
    </div>
    
</li>
</template>
<script>
import axios from 'axios'
import { API_BASE_URL } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'
import { API_IMAGE_PATH } from '../config'
const mirror = createLocal()
const storage = mirror._prx

export default{
    name: 'CommentCard',
    props:['candidateData', 'icon','callback'],
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            imagepath:{},
            blockStataus:'',
            userType:'',
            lastChatMessage:'',
            blockStatausAuth:''
        }
    },
    async created(){
        this.imagepath=API_IMAGE_PATH;
        this.userId=mirror.AUTHID;
        this.userType=mirror.USERTYPE;
        //console.log("M:",this.candidateData.users);
        this.userType = mirror.USERTYPE;
        if(this.userType==3){
            const authParam    = {
                receiverId:mirror.AUTHID,
                userId:this.candidateData.users.unique_user_id
            }
            const response= await axios.post(API_BASE_URL + 'blockUnblockCheckingAuth',authParam);
            this.blockStatausAuth = response.data.blockStataus;
            console.log("B Details : ",response.data);
        } else {
            this.blockStatausAuth = '';
        }
        const param    = {
            userId:mirror.AUTHID,
            receiverId:this.candidateData.users.id
        }
        const response1= await axios.post(API_BASE_URL + 'blockUnblockChecking',param);
        this.blockStataus = response1.data.blockStataus;
        console.log("Details : ",response1.data);
        
        const response2= await axios.post(API_BASE_URL + 'lastChatMessage',param);
        this.lastChatMessage = response2.data.messageData;
        console.log("Last Chat Details : ",response2.data.messageData);
    },
    methods: {
        // appliedJobsDetails(id) {
        //     this.addModal = true;
        //     console.log("HI",id);
        // },
        // close(){
        //     this.addModal = false
        // },
        showPopups(test,status) {
            //console.log("TEST:",test);
			if (this.callback) {
				this.callback(test,status)
			}
		}
  } 
}
</script>
<style scoped>
.main-content-chat .container-fluid .row .chat_user .user .active .user_details {
    background-color: red !important;
    color:white !important;
}
.icon img{
    width: 100px;
    height: 85px;
    object-fit: cover;
}
.job_info{
    flex: 1 1 290px;
    padding-left: 30px;
}
.job_date{
    flex: 1 1 150px;
}
.job_info h3{
    font-size: 24px;
    line-height: 30px;
    color: #d9d9d9;
}
.job_info .job_title{
    font-size: 16px;
    line-height: 22px;
    color: #d9d9d9;
    margin-bottom:5px;
}
.job_info i{
    color: #c71e1e;
    margin-right: 7px;
    font-size: 16px;
}
.job_info .candidate_info{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}
.job_info .candidate_info li{
    padding: 5px 10px;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
}
.job_date{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.job_date .date{
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-top:10px;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #c71e1e;
}
.job_date .action_btnWpr{
    display:flex;
    flex-direction: row-reverse;
    padding-top: 4px;
}
.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
}

.sign_submit_new{
    background: #28282b;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
}

@media(max-width:1199px){
    .job_info{
        padding-left:20px;
    }
    .job_info h3{
        font-size: 20px;
        line-height: 26px;
    }
}
@media(max-width:670px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .candidate_info{
        justify-content: center;
    }
    .job_date {
        align-items: center;
    }
    .icon{
        flex: 0 0 100%;
        text-align: center;
    }
}

</style>