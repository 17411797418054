<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part cd">
    
    <!-- <div class="backgroundImages">
        
    </div> -->
    <div class="dh-banner">
        <div class="banner-img">
            <img src="@/assets/dashboard-action.png" alt="">
        </div>
        <div class="text-area">
            <h2><span class="dngr-txt">Executive</span> Protection Training Day</h2>
            <p><span class="topic" style="color: #9b7009;font-weight: bold;">{{topic}}</span></p>
            <p>{{startDate}} {{startTime}}  I  Location: <span class="dngr-txt">{{location}}</span></p>
        </div>
        <button class="pus-btn" v-on:click="execute(id,topic,join_url,JSON.parse(json_data).id)">Join the upcoming event</button>
    </div>

    <DashboardCompany v-if="storage.USERTYPE==2" />
    <DashboardAgent v-if="storage.USERTYPE==3" />
</div>
</div>
</div>
<div class="modelPopups" v-if="addModal" style="display:block">
        <div class="content_wrapper modal-content" style="position: absolute;">
            <!-- <div class="content_wrapper" style="width:100% !important;height:100% !important;"> -->
            <div class="modal_header" style="position:relative">
                <h2 class="block-heading">Join Live Events - {{topic}}</h2>
                <button @click="close" class="sign_submit flow-close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body" v-if="showZoomForm" style="height:90%;">
                <form id="jobpost-form" v-on:submit.prevent="applyForZoomMeeting" >
                    <div class="form_grp">
                        <input type="hidden" v-model="meetingId" >
                        <input type="hidden" v-model="link" >
                        
                        <div class="item_grp">
                            <div style="width:48%;float:left;">
                                <label>First Name* : </label> 
                                <input type="text" v-model="fname" placeholder="Enter First Name" name="fname">
                                
                            </div>
                            <div style="width:48%;float:right;">
                                <label>Last Name* : </label>
                                <input type="text" v-model="lname" placeholder="Enter Last Name" name="lname">
                                
                            </div>
                        </div> 
                        
                        <div class="item_grp">
                            <div >
                                <label>Email* : </label>
                                <input type="text" v-model="userEmail" placeholder="Enter Email" name="userEmail">
                                
                            </div>
                        </div> 
                    </div>
                    <label for="" > Select Level : </label>
                    <div class="bottom_cd" style="width: 100% !important;display: flex;">
                        <div class="cards" style="width: 100%;">
                            
                            <div class="card" style="background: rgb(0, 117, 178);width: 310px;height: 65px !important;"  v-bind:class="{ active: isActive=='level1' }">
                                <label for="level1" style="cursor:pointer;">
                                <input v-on:click="isActiveFunction('level1')" v-model="level" type="radio" name="level" id="level1" value="level1" style="display:;position: absolute;top: 10px;right: 10px;"/>
                                <div class="blog_wpr" style="padding: 10px;">
                                    <div class="thirty-three-text" >
                                        <h3>{{levelList[0].level}}</h3>  
                                        <h4>{{levelList[0].title}}</h4> 
                                        <!-- <p class="tt-u sub-text"> </p>  -->
                                    </div>
                                </div>
                                </label>
                            </div>
                            
                            <div class="card" style="background: rgb(240, 119, 49);width: 310px;height: 65px !important;" v-bind:class="{ active: isActive=='level2' }">
                                <label for="level2" style="cursor:pointer;">
                                <input v-on:click="isActiveFunction('level2')" type="radio" v-model="level" name="level" id="level2" value="level2" style="display:;position: absolute;top: 10px;right: 10px;"/>
                                <div class="blog_wpr" style="padding: 10px;">
                                    <div class="thirty-three-text" >
                                        <h3>{{levelList[1].level}}</h3>  
                                        <h4>{{levelList[1].title}}</h4>   
                                        <!-- <p class="tt-u sub-text"> </p>  -->
                                    </div>
                                </div>
                                </label>
                            </div>
                            
                            <div class="card" style="background: rgb(237, 30, 12);width: 310px;height: 65px !important;"  v-bind:class="{ active: isActive=='level3' }">
                                <label for="level3" style="cursor:pointer;">
                                <input v-on:click="isActiveFunction('level3')" type="radio" v-model="level" name="level" id="level3" value="level3" style="display:;position: absolute;top: 10px;right: 10px;"/>
                                <div class="blog_wpr" style="padding: 10px;">
                                    <div class="thirty-three-text" >
                                        <h3>{{levelList[2].level}}</h3>  
                                        <h4>{{levelList[2].title}}</h4> 
                                        <!-- <p class="tt-u sub-text">  </p>  -->
                                    </div>
                                </div>
                                </label>
                            </div>
                            <br/>
                            
                        </div>
                    
                    </div>
                    <div class="form_grp">
                        <div class="item_grp" style="text-align:center;">
                            <button type="submit" class="sign_submit_new">Join Live Events Now</button>
                        </div>
                        <p style="color:red;" v-if="errormessage!=''">{{errormessage}}</p>
                    </div>
                </form>
            </div>
            <div class="modal_body_iframe" v-else style="height:95%;">
                <iframe :src="`https://zoom.us/wc/${mId}/join?prefer=1&un=${meetingId}`" sandbox="allow-forms allow-scripts" allow="microphone; camera; fullscreen" style="width:100%;height:100%;"></iframe>
                <!-- <iframe name="iframe1" :src="link" style="width:100%;height:100%;" ></iframe> -->
                <input type="hidden" v-model="link" class="sign_submit">
                
                <div class="item_grp">
                    <a class="sign_submit" :href="link" target="iframe1">Join Now</a>
                    <button type="submit" class="sign_submit" @click="close">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import { API_BASE_URL } from '../config'
import moment from "moment";
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import DashboardCompany from '../components/DashboardCompany.vue'
import DashboardAgent from '../components/DashboardAgent.vue'
//import Loader from '../components/Loader.vue'
import { createLocal } from 'the-storages';

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx
export default {
name: 'DashboardGetstarted',
    components:{
        Dashboardheader,
        Dashboardsidebar,
        DashboardCompany,
        DashboardAgent,
        //Loader
        },
        
        data() {
            return {
                storageData: mirror,
                storage: storage,
                eventDetails:{},
                join_url:'',
                topic:'',
                startDate:'',
                startTime:'',
                location:'',
                id:'',
                json_data:'',
                moment: moment,
                addModal:false,
                showZoomForm:false,
                meetingId:'',
                mId:'',
                link:'',
                error: '',
                error2:'',
                fname:'',
                lname:'',
                email:'',
                imagepath:'',
                userEmail:'',
                errormessage:'',
                level:'',
                isActive: '',
                userNewId:'',
                levelList:[],
            }
        },
        async created(){
            this.hash = location.hash;
            if(this.hash=='#show'){
                this.showToast("Login Successfully !",`You login successfully as ${(mirror.USERTYPE=='2')?'Company':'Agent'} !`,"success");
                location.hash = '';
            }
            //this.showToast("Failed",this.errormessage,"danger"); 
            const authParam    = {
                userId:mirror.AUTHID
            }
            const response= await axios.post(API_BASE_URL + 'getTodayOrUpcomingEven',authParam);
            this.eventDetails = response.data;
            //console.log("RESULT:::::",response.data);
            this.startDate = moment(this.eventDetails.start_time).format('ddd, MMM Do YYYY');
            this.startTime = moment(this.eventDetails.start_time).format('hh:mm A');
            this.topic = this.eventDetails.topic;
            this.join_url = this.eventDetails.join_url;
            this.id = this.eventDetails.id;
            this.json_data = this.eventDetails.json_data;
            this.location = 'United States';

            const responseLevel = await axios.get(API_BASE_URL + "levelList");
            this.levelList = responseLevel.data;

            console.log("LEVEL LIST:: ", responseLevel.data);

            this.userNewId = mirror.AUTHID;
            let levelDetails = await axios.post(API_BASE_URL + "getLevelById",{"userId":this.userNewId});
        
            this.level = levelDetails.data.levelName;
        }
        ,
        beforeRouteEnter(to, from, next) {
            //console.log("TOKENT" ,storage.get('TOKEN'));
            if (storage.get('TOKEN')) {
                next();
            }
            else {
                next('/login');
            }
        },
        methods:{
            isActiveFunction(value){
                if(value=='level1'){
                    this.isActive = 'level1';
                } else if(value=='level2') {
                    this.isActive = 'level2';
                } else if(value=='level3') {
                    this.isActive = 'level3';
                }
            },
            async execute(id,topic,link,mId) {
                this.errormessage = '';
                //console.log(link)
                this.meetingId = id;
                this.mId = mId;
                this.topic = topic;
                this.link = link;
                this.level = this.level;
                console.log("newData: ",this.level);
               
                this.showZoomForm = true;
                this.addModal = true;
            },
            async applyForZoomMeeting() {
                this.errormessage = '';
                //this.v2$.$validate(this.v2$.$error1);
                //if (!this.v2$.$error1) {
                    if (this.fname && this.userEmail && this.lname ) {
                        const param    = {
                            userId:(mirror.AUTHID)? mirror.AUTHID:"",
                            userName:this.fname+' '+this.lname,
                            email:this.userEmail,
                            meetingId:this.meetingId,
                            level:this.level,
                            link:this.link
                        }
                        console.log("param",param);
                        await axios.post(API_BASE_URL + 'joinZoomMeeting', param)
                        
                        .then(response => { 
                            this.showZoomForm = false;  
                            //this.addModal = false;                     	
                            console.log("MEETING: ",response)                        	
                            this.fname = '';
                            this.lname = '';
                            this.userEmail = '';
                            this.level = '';
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    } else {
                        //console.log("ELSE");
                        this.errormessage = 'All Fields are Mandatory';
                        console.log("ELSE",this.errormessage);
                    }
                //} else {
                    //this.errormessage = 'All Fields are Mandatory';
                //}
                //this.addModal = false;
            },
            close(){
                this.addModal = false
            },
            showToast(title,description,type){
                createToast({
                    title: title,
                    description: description
                },
                {
                    type: type,
                    position: 'bottom-right',
                    // hideProgressBar: 'true',
                    // toastBackgroundColor: color,
                })
            },
        }
}
</script>
<style scoped>
div::after {
  clear: both !important;
  visibility: initial !important;
  /* content: ""; */
  display: block !important;
  font-size: 20px !important;
  color: white !important;
}
.block-heading{
    float: none !important;
}
button.flow-close{
    float: none !important;
    position: absolute;
    right: -18px;
    top: -18px;
    width: 35px;
    height: 35px;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    background: red;
    border-radius: 50%;
    border: 5px solid #030303;
}
.modal-content {
    border-radius: 8px;
}
/* .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
    background: red !important;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    background: red !important;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-prev, .middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-next {
    position: absolute !important;
    bottom: -5px !important;
    left: 0 !important;
    top: auto;
    background: red;
}
.middle-part.dashboard .rigt-part.cd .prodcast_slider .swiper-button-next {
    left: 30px !important;
} */

.modal_body_iframe {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
    .sign_submit{
        background: #c71e1e;
        border: 0;
        font-size: 11px;
        line-height: 14px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        padding: 6px 8px;
        margin: 2px;
    }
    /* .sign_submit:hover {
        border: 2px  solid red;
        
    } */
    .sign_submit_new{
        font-size: 15px;
        line-height: 20px;
        padding: 12px 38px;
        color: #fff;
        border: 1px solid #fff;
        text-transform: uppercase;
        font-family: 'Play', sans-serif;
        background: transparent;
        margin-top: 25px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .sign_submit_new:hover {
        background: #b91e1e;
        
    }
    .testimnial{
        background: #1e1e1e;
        padding: 15px;
        border-left: 2px solid #b91e1e;
    }
    .test_header{
        display: flex;
        align-items: center;
        /* padding-bottom: 10px; */
        /* padding-left: 10px; */
    }
    .test_header img{
        margin: 0 0px;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }
    .test_header .user h4{
        font-size: 10px;
        line-height: 18px;
        color: #fff;
        padding-bottom: 5px;
        font-weight: 400;
        margin-left:8px;
    }
    .test_body p{
        font-size: 16px;
        line-height: 23px;
        color: #a1a1a1;
        padding-left: 40px;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .test_body p:before{
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 27px;
        height: 21px;
        background: url('~@/assets/quote.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media(max-width:575px){
        .testimnial{
            padding: 15px;
        }
        .test_body p{
            font-size: 14px;
            line-height: 19px;
            padding-left: 30px;
        }
        .test_body p:before{
            width: 18px;
            height: 14px;
        }
        .test_header img{
            margin: 0 12px;
        }
        .test_header .user h4{
            font-size: 18px;
            line-height: 23px;
        }
         .test_header{
        display: block;
       /* align-items: center;
         padding-bottom: 10px; */
        /* padding-left: 10px; */
    }
        
    }
</style>
<style scoped>
.modelPopups .content_wrapper{
    max-width: 600px;
    width: 85% !important;
}
    .modal_body .form_grp .item_grp select {
        margin-top: 3px !important;
        border: 1px solid #3d3d3d;
        background: #212122;
        padding: 10px 15px !important;
        width: 100%;
        color: #d9d9d9;
    }
    .modelPopups {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0,0,0,0.9);
        display: flex !important;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        padding: 10px;
    }
    .testimonial_slider .swiper-slide{
        width: 300px;
        margin: 0 15px;
    }
    .test_footer{
      display: flex;
      justify-content: center;
      padding: 30px 0 0;
    }
    @media(max-width:575px){
        .testimonial_slider .swiper-slide{
            width: 100%;
            margin: 0 10px;
        }
    }
</style>
<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 1 0 20%;
  position: relative;
  margin: .5em;
  padding: 0em;
  /* min-height: 4em; */
  /* background: white; */
  /* border: 3px solid grey; */
  border-radius: 5px;
}

/* .active { border: 5px solid green; } */
</style>
<style>
.middle-part.dashboard .rigt-part textarea {
    height: 90px !important;
}
.modal {
    top: 53%;
}
.modal_body .form_grp .item_grp {
    padding: 5px 5px !important;
}
.modal_body {
    padding: 15px 30px !important;
}
.middle-part.dashboard{
    z-index: 0;
}
.modal_header button:hover {
    border: 2px  solid red !important;
}
.modal_header h2{
    float: none !important;
    font-size: 20px;
}
.modal_header {
    position: relative;
    padding: 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}
.modal_header button {
    width: 28px;
    height: 28px;
    background: #c71e1e;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: -16px;
    top: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #000000ed;
}
.modal_header button i{
    margin: 0 !important;
}
/* .sign_submit:hover {
    border: 2px  solid red;
} */
</style>
<style scoped>

    .topic {
        font-size: 10px;
    }
    .dh-banner {
    position: relative;
    width: 100%;
    background: url(~@/assets/dashboard-bg.png);
    background-size: cover;
    background-position: center center;
    border-radius: 2px;
    padding-right: 20px;
    display: flex;
    margin-bottom: 44px;
    row-gap: 15px;
    align-items: center;
    justify-content: space-between
}

.dh-banner .banner-img img {
    display: block;
    height: 140px;
    aspect-ratio: 2.6/1;
}

.dh-banner .text-area h2 {
    font-size: 40px;
    line-height: 1;
    text-transform: uppercase;
    color: #000;
    font-weight: 700
}

.dh-banner .text-area p {
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 20px;
    /* margin-top: 5px */
}

.dh-banner .text-area .dngr-txt {
    color: #b01d1e
}

.dh-banner .pus-btn {
    border: none;
    color: #fff;
    padding: 15px 14px;
    font-size: 16px;
    border-radius: 2px;
    background: #b01d1e;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.middle-part.dashboard{
    z-index: 0;
}
.middle-part.dashboard .rigt-part{
    z-index: 0;
}
header.dashboard .admin-relation ul.nav-menu {
    position: fixed;
    display: none;
    right: 46px;
    top: 80px;
    padding: 0 30px;
    z-index: 99;
    background: #303033;
}

@media(max-width:1660px) {
    .dh-banner .text-area h2 {
        font-size: 25px
    }
    .dh-banner .text-area p {
        font-size: 16px
    }
    .dh-banner .pus-btn {
        padding: 10px 10px;
        font-size: 12px;
    }
}

@media(max-width:990px) {
    .dh-banner {
        flex-wrap: wrap;
    }
    .dh-banner .banner-img{
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .dh-banner .text-area{
        width: 100%;
        text-align: center;
    }
    .dh-banner .pus-btn{
        position: relative;
        display: block;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
    .dh-banner .banner-img img {
        display: block;
        height: unset;
        width: 100%;
        aspect-ratio: unset;
    }
    .dh-banner{
        padding: 10px;
    }


}


@media(min-width:599px) {
    .dh-banner .pus-btn {
        flex-shrink: 0
    }
    .header.dashboard .admin-relation ul.nav-menu {
        padding: 0 20px;
        right: 10px;
    }
    header.dashboard .admin-relation ul.nav-menu a {
        text-align: center;
        font-size: 14px;
        padding: 10px 0;
        color: #fcfcfc;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    header.dashboard .admin-relation ul.nav-menu a img{
        width: 20px;
    }
}

@media(max-width:599px) {
    .header.dashboard .admin-relation ul.nav-menu {
        padding: 0 20px;
        right: 10px;
    }
    header.dashboard .admin-relation ul.nav-menu a {
        text-align: center;
        font-size: 14px !important;
        padding: 10px 0;
        color: #fcfcfc;
        display: flex !important;
        align-items: center;
        flex-direction: column;
    }
    header.dashboard .admin-relation ul.nav-menu a img{
        width: 20px;
    }
}

@media(max-width:599px) {
    .dh-banner .text-area h2 {
        word-break: break-all
    }
}
.modelPopups .content_wrapper{
    max-width: 600px !important;
    width: 85% !important;
}

@media (max-width:599px){
    .modal_body .form_grp .item_grp{
        display: flex;
        row-gap: 15px;
        flex-direction: column;
    }
    .modal_body .form_grp .item_grp > :nth-child(1),
    .modal_body .form_grp .item_grp > :nth-child(2){
        width: 100% !important;
    }
    #jobpost-form .bottom_cd>.cards{
        display: flex;
        row-gap: 15px;
        flex-wrap: wrap;
        flex-direction: column;
    }
    #jobpost-form .bottom_cd>.cards .card:first-child{
        margin-top: 10px;
    }
    #jobpost-form .bottom_cd>.cards .card{
        width: 100% !important;
        flex-grow: 1;
        margin: 0;
    }
}

</style>


