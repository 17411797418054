<template>
  <swiper :slidesPerView="'auto'" :spaceBetween="0" :pagination='{"clickable": true}' class="testimonial_slider">
    <swiper-slide v-bind:key="testimonial.id" v-for="testimonial in testimonials">
      <TestimonialCard :name="testimonial.name" :description="testimonial.description" :image="testimonial.image" />
    </swiper-slide>
    <div v-if="testimonialCount>5" class="test_footer">
        <router-link to="/testimonial-details" class="login_btn">See More</router-link>
    </div>
  </swiper>

</template>


<script >
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import TestimonialCard from './TestimonialCard.vue'

SwiperCore.use([Pagination]);
export default {
  components: {
    Swiper,
    SwiperSlide,
    TestimonialCard
  },
  data() {
        return {
            isLoading: true,
            imagepath:{},
            testimonials: {},
            laravelData: {},
            testimonialCount: ''
        }
    },
    mounted() {
		// Fetch initial results
		this.getResults();
	},
	methods: {     
		async getResults() {			
			await axios.get(API_BASE_URL + 'testimonialHomeData')
        .then(response => {
            console.log("TESTIMONINAL LIST:",response.data);
            this.laravelData = response.data.testimonial;
            this.testimonials = response.data.testimonial;
            this.testimonialCount = response.data.testimonialCount;
            this.imagepath=API_IMAGE_PATH
            this.isLoading = false
        });
		}
	}
}
</script>
<style scoped>
    .testimonial_slider .swiper-slide{
        width: 460px;
        margin: 0 30px 0 0;
    }
    .test_footer{
      display: flex;
      justify-content: center;
      padding: 30px 0 0;
    }
    .test_footer .login_btn{
      margin-left: 0;
    }
    @media(max-width:767px){
        .testimonial_slider .swiper-slide{
            width: 380px;
            margin: 0 10px;
        }
    }
    @media(max-width:575px){
        .testimonial_slider .swiper-slide{
            width: 290px;
            margin: 0 10px;
        }
    }
</style>