<template>
    <div class="testimnial" >
        <div class="test_header">
            <img v-if="!image" class="bg_img" src="@/assets/avatar.png" >
            <img v-if="image" v-bind:src="imagepath+image" v-bind:alt="name">
            <div class="user">
                <h4>{{name}}</h4>
                <div class="review"></div>
            </div>
        </div>
        <div class="test_body">
            
            <p v-if="description.length<300">{{ description }}</p>
            <p v-else>{{ description.substring(0,250)+"..." }}</p>
        </div>
    </div>
</template>

<script>
import { API_IMAGE_PATH } from '../config'
export default {
    data() {
        return {
            imagepath:{}
        }
    },
    name: 'TestimonialCard',
    props: ["name","image","description"],
    async created () {
        this.imagepath=API_IMAGE_PATH
        console.log(this.imagepath);
    }
}
</script>

<style scoped>
    .testimnial{
        background: #1e1e1e;
        padding: 30px;
        border-left: 2px solid #b91e1e;
    }
    .test_header{
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        padding-left: 10px;
    }
    .test_header img{
        margin: 0 25px;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }
    .test_header .user h4{
        font-size: 22px;
        line-height: 28px;
        color: #fff;
        padding-bottom: 5px;
        font-weight: 400;
    }
    .test_body p{
        font-size: 16px;
        line-height: 23px;
        color: #a1a1a1;
        padding-left: 40px;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .test_body p:before{
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 27px;
        height: 21px;
        background: url('~@/assets/quote.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media(max-width:767px){
        .testimnial{
            padding: 20px;
        }
        .test_header img{
            width: 45px;
            height: 45px;
            margin: 0 15px 0 20px;
        }
        .test_body p{
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 22px;
        }
    }
    @media(max-width:575px){
        .testimnial{
            padding: 15px;
        }
        .test_body p{
            font-size: 13px;
            line-height: 19px;
            padding-left: 30px;
        }
        .test_body p:before{
            width: 18px;
            height: 14px;
        }
        .test_header img{
            width: 40px;
            height: 40px;
            margin: 0 12px 0 0;
        }
        .test_header .user h4{
            font-size: 16px;
            line-height: 22px;
        }
    }
</style>