<template>
 <Navigation/>
      <Banner class="blog_banner" bg="blog-banner.jpg">
        <div class="inr_text blog">
			<img src="@/assets/blog-heading.png">	
            <h3>Category: {{catname}}</h3>
        </div>
    </Banner>
   <section class="main_podcast">
        <div class="container">
			<div class="all_blog">
				<div class="blog_part" v-bind:key="blog.id" v-for="blog in blogs">
				<router-link :to="'/blog/' + blog.slug" class="">
					<div class="blog_pic" v-if="blog.featured_image!=''">
						<img v-bind:src="imagepath+blog.featured_image" v-bind:alt="blog.title"/>
					</div>
					
					<div class="blog_pic" v-else>
						<img src="@/assets/b-1.jpg">
					</div>
					</router-link>
					<div class="blog_content">
						<div class="blog_content_top">
							<span>
							<span v-if="blog.category"><router-link :to="'/blog/category/' + blog.category.slug">{{blog.category.name}}</router-link></span>  <b> . {{blog.user.fname}} {{blog.user.lname}}</b></span>
							<div class="date"><b>{{ moment(blog.created_at).startOf("month").format('MMMM')}}</b> {{ moment(blog.created_at).format("DD,YYYY") }}</div>
						</div>
						<div class="blog_content_details">
							<router-link :to="'/blog/' + blog.slug" class=""><h3>{{blog.title}}</h3></router-link>
							<p>{{blog.excerpt}}</p>
							<router-link :to="'/blog/' + blog.slug" class="read_more">Read More</router-link>
					
						</div>
					</div>
				</div>
								
			</div>
			<div class="pagination">
			<pagination :data="laravelData" @pagination-change-page="getResults">
			
			<a v-if="current>1" @click="prev" class="read_more previous">Previous</a>
			<a v-if="totalpage>current" @click="next()" class="read_more next">Next</a>
			</pagination>
			
				
			</div>
		</div>
    </section>
	<Buttomads/>
	<Footer/>
</template>


<script>
import Banner from '../components/Banner.vue'
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { useRoute } from 'vue-router'
import Buttomads from '../components/Buttomads.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Blog_category',
    components:{
		Banner,
		Buttomads,
		Navigation,
		Footer
    },
   data() {
        return {
            isLoading: true,
            imagepath:{},
            blogs: {},
            moment: moment,
            laravelData: {},
            current: 1,
            totalpage:1,
            pageSize: 4,
            catname:''
        }
    },
    computed: {
        indexStart() {
          return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
          return this.indexStart + this.pageSize;
        },
        paginated() {
          return this.blogs.slice(this.indexStart, this.indexEnd);
        }
      },
    mounted() {
		// Fetch initial results
		this.getResults();
	},
	methods: {
		// Our method to GET results from a Laravel endpoint
		prev() {
          this.current--;
          this.getResults();  
        },
        next() {
          this.current++;
          this.getResults();  
        },        
		getResults() {	
			const route=useRoute();		   
			axios.get(API_BASE_URL + 'blog/category/'+route.params.slug)
				.then(response => {
					this.laravelData = response.data;
					this.blogs = response.data.data;
					this.totalpage=response.data.last_page;
					//console.log(response.data.last_page);
					this.imagepath=API_IMAGE_PATH;
					this.isLoading = false
				});
		}
	},
    async created () {
    const route=useRoute();		
	const details = await axios.get(API_BASE_URL + 'blog/categorydetails/'+route.params.slug)
	this.catname=details.data.name;
	console.log(this.blogs)
    }
}
</script>

<style scoped>

.all_blog {
	display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
	margin:80px 0 0;
}

.blog_part {
	width: 31.5%;
	background: #1a1a1a;
	border: solid 1px #242424;
	margin-bottom: 40px;
}

.blog_pic {
	width: 100%;
	height: 280px;
}

.blog_pic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.blog_content {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}
.main_podcast{margin-bottom:50px;}

.blog_content_top {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.blog_content_top span {
	color: #939393;
}

.blog_content_top span b {
	font-weight: 500;
	color:#fff;
}

.blog_content_top .date {
	color: #fff;
}

.blog_content_top .date b {
	color: #c71e1e;
}

.blog_content_details h3 {
	font-size: 26px;
	line-height: normal;
	color: #fff;
	font-weight: 500;
	margin-bottom: 20px;
}

.blog_content_details p {
	color: #939393;
	font-size: 15px;
}

.read_more {
	color: #939393;
	border: solid 1px #939393;
	padding: 7px 25px;
	text-decoration: none;
	display: inline-block;
	margin-top: 20px;
	font-size: 16px;
}

.read_more:hover {
	background: #cfcfcf;
	color: #000;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.pagination a.next {
	color: #d70b0b;
	border-color: #d70b0b;
	margin-left: 10px;
}

.pagination a.next:hover {
	background: #d70b0b;
	color: #fff;
}

.blog_pro {
	width: 100%;
	float: left;
	padding: 50px 0;
	background-image: url('~@/assets/blog-pro-bg.jpg');
	background-repeat: no-repeat !important;
	background-position: left top !important;
	background-size: cover !important;	
}

.blo_pro_right {
	width: 40%;
	float: right;
	padding-right: 30px;
	box-sizing: border-box;
}

.blo_pro_right img{
	max-width:600px;
	width:100%;
}
.blo_pro_right p {
	font-size: 15px;
	color: #fff;
	text-transform: uppercase;
	margin: 10px 0;
	line-height: 24px;
}



@media only screen and (min-width:768px) and (max-width:1360px) {
	.blog_pro {
    background-position: -160px top !important;
}
.banner {
    min-height: 290px;
}
.inr_text.blog {
    padding: 110px 0 70px;
    top: 160px;
    bottom: 0;
}
.all_blog{
	margin-top:20px;
}
.inr_text.blog img{
    max-width: 260px;
    float: none;
    display: block;
    margin: 0 auto;
}
.inr_text.blog h3 {
    font-size: 25px;
    letter-spacing: 6px;
}

}

@media only screen and (min-width:240px) and (max-width:767px) {
.blog_pro {
    background-position: -160px top !important;
}
.banner {
    min-height: 290px;
}
.inr_text.blog {
    padding: 110px 0 70px;
    top: 160px;
    bottom: 0;
}
.all_blog {
	padding: 10px 15px;
}
.all_blog{
	margin-top:20px;
}
.inr_text.blog img{
    max-width: 260px;
    float: none;
    display: block;
    margin: 0 auto;
}
.inr_text.blog h3 {
    font-size: 25px;
    letter-spacing: 3px;
}

.blog_part {
	width: 100%;
	margin-bottom: 20px;
}

.blog_part:last-child {
	margin-bottom: 0;
}

.blog_content {
	padding: 15px 10px;
}

.blog_content_top {
	margin-bottom: 10px;
}

.blog_content_top span, .blog_content_top .date {
	font-size: 12px;
}

.blog_content_details h3 {
	font-size: 18px;
	margin-bottom: 10px;
}

.blog_content_details p {
	font-size: 14px;
}

.blog_content_details a {
	margin-top: 15px;
}

.pagination {
	justify-content: center;
	padding: 10px;
}

.pagination {
	margin-top: 0;
}

.blog_pro {
	padding: 30px 0;
}

.blo_pro_right {
	width: 100%;
	padding: 20px;
}
}
</style>