<template>
<li>
    
    <div class="icon" v-if="jobData.user">
        <img v-bind:src="imagepath+jobData.user.logo" v-bind:alt="jobData.job_title" v-if="jobData.user.logo">	
		<img :src="require(`@/assets/${icon}`)" alt="" v-else>
        <!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
    </div>
    <div class="icon" v-else>
		<img :src="require(`@/assets/${icon}`)" alt="" >
        <!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
    </div>
    <div class="job_info">
        <h3 v-on:click="execute(jobData.id,jobData.slug)" style="cursor:pointer;">{{jobData.job_title}}</h3>
        <ul class="role">
            <li v-if="jobData.jobroles">{{jobData.jobroles.role_name}} </li>           
            <li v-if="jobData.jobtype">{{jobData.jobtype.job_type}}</li>
            <li v-if="jobData.experience">{{jobData.experience.experience}} </li>
            <li v-if="jobData.budget">{{jobData.budget.budget_name}} </li>
            <!-- <li v-if="jobData.job_type.cont=='1'">Contract</li>
            <li v-if="jobData.job_type.full=='1'">Full Time</li>
            <li v-if="jobData.job_type.part=='1'">Part Time</li> --> 
        </ul>
        <div class="action_btnWpr" v-if="jobData.user">
            <button v-if="userId==jobData.user.id && userType==2" type="button" style="float: right;" class="sign_submit"><router-link :to="'/jobedit/' + jobData.slug" style="color:white;cursor:pointer">Edit</router-link></button>
            <button type="button" class="sign_submit" v-on:click="execute(jobData.id,jobData.slug)" style="color:white;cursor:pointer">View Details</button>
            <button v-if="userId==jobData.user.id && userType==2" type="button" style="float: right;margin-right: 10px;" class="sign_submit"><router-link :to="'/applied-candidate/' + jobData.slug" style="color:white;cursor:pointer">Applied Candidate</router-link></button>
        </div>
        
    </div>
    <div class="job_date">
        <div id="newStyle" v-if="moment(jobData.created_at).format('YYYY-MM-DD')==today">
            <div id="click-here" class="neon">NEW</div>
        </div>
        <!-- <i class="fas fa-ellipsis-v" style="float:right;cursor:pointer;"></i> -->
        <div class="date"><span>{{moment(jobData.created_at).startOf("month").format('MMMM')}} </span> {{moment(jobData.created_at).startOf("day").format('DD')}}, {{moment(jobData.created_at).startOf("year").format('YYYY')}}</div>
        <div class="place">{{jobData.city}}, {{jobData.country}}</div>
        <!--
        <button v-if="userId==jobData.user.id && userType==2" type="button" style="float: right;" class="sign_submit"><router-link :to="'/jobedit/' + jobData.slug" style="color:white;cursor:pointer">Edit</router-link></button>
        
        <button  type="button" class="sign_submit"><router-link :to="'/jobDetails/' + jobData.slug" style="color:white;cursor:pointer">View Details</router-link></button>
        
        <button v-if="userId==jobData.user.id && userType==2" type="button" style="float: right;margin-right: 10px;" class="sign_submit"><router-link :to="'/applied-candidate/' + jobData.slug" style="color:white;cursor:pointer">Applied Candidate</router-link></button>
        -->
        <div class="place">
            <div style="color:#cda53a;">{{jobData.whereFrom}}</div>
        </div>
    </div>
    
</li>

</template>

<script>
//import axios from 'axios'
//import { BASE_URL } from '../config'
//import { API_BASE_URL } from '../config'
import moment from "moment";
import { createLocal } from 'the-storages'
import { API_IMAGE_PATH } from '../config'
const mirror = createLocal()
const storage = mirror._prx

export default{
    name: 'JobCardList',
    props:['jobData', 'icon', 'callback'],
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            imagepath:{},
            userId:'',
            userType:'',
            today:'',
        }
    },
    created(){
        this.imagepath=API_IMAGE_PATH;
        this.userId=mirror.AUTHID;
        this.userType=mirror.USERTYPE;
        //console.log(this.imagepath);
        const today = moment();
        this.today = today.format('YYYY-MM-DD');
    },
    methods: {
        execute(jobId,slug) {
            //console.log("TEST",this.callback);
			if (this.callback) {
				this.callback(jobId,slug)
			}
		}
    }
}
</script>
<style scoped>
.icon img{
    max-width: 105px;
    height: auto;
}
.job_info{
    flex: 1 1 420px;
    padding: 0 15px 0 30px;
}
.job_date{
    flex: 0 1 150px;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}
.job_info h3{
    font-size: 24px;
    line-height: 30px;
    color: #d9d9d9;
}
.job_info .role{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px -15px 0 -15px;
}
.job_info .role li{
    padding: 5px 15px;
    color: #d9d9d9;
    font-size: 15px;
    line-height: 22px;
    position: relative;
}
.job_info .role li:after{
    position: absolute;
    left: 100%;
    top: 5px;
    content: '.';
    color: #a5a5a5;
}
.job_info .role li:last-child:after{
    display: none;
}
.job_info .action_btnWpr{
    display:flex;
    justify-content: flex-start;
    padding-top: 4px;
}
.job_date .date{
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #cda53a;
}
.job_date .place{
    font-size: 13px;
    line-height: 17px;
    color: #d1d1d1;
}
.sign_submit {
    padding: 8px 15px;
	background: #c71e1e;
	border-radius: 4px;
	border: none;
	font-size: 10px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
    cursor:pointer;
    margin: 4px 4px 0 0;
    border-radius: 0;
}
@media(max-width:1199px){
    .job_info{
        flex: 1 1 350px;
    }
    .job_info h3{
        font-size: 20px !important;
        line-height: 26px !important;
    }
    .job_info .role li{
        font-size: 14px;
        line-height: 18px;
    }
    .icon img{
        max-width: 80px;
    }
    .job_date .date{
        font-size: 14px;
        line-height: 17px;
    }
    .job_date .place{
        font-size: 11px;
        line-height: 13px;
    }
}
@media(max-width:670px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .role{
        justify-content: center;
    }
    .job_date, .icon{
        flex: 0 0 100%;
        text-align: center;
        align-items: center;
    }
    .job_info .action_btnWpr{
        justify-content: center;
    }
}
</style>

<style scoped>
    /* for page body text */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap"); */

/* for the neon font - Train One */
/* @import url("https://fonts.googleapis.com/css2?family=Train+One&display=swap"); */

/* body {
  background-image: radial-gradient(
    circle at center,
    #460b60,
    #290638,
    #15032a,
    #0f0021
  );
  font-family: "Roboto";
  color: #999;
  text-align: center;
} */

/* #container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
} */

#newStyle {
    position: absolute;
    /* left: 10px; */
    right: 15px;
    top: 14px;
}

#example-link {
  margin-top: 50px;
}

a {
  color: #999;
}

a:hover {
  color: #fff;
}

/* BELOW: CODE FOR NEON FONT EFFECT */

#click-here {
  font-family: "Train One", cursive;
  font-size: 9pt;
  filter: blur(0.2px);
}

.neon {
    border: 1px solid #c71e1e;
    padding: 0px 7px 0 7px;
    border-radius: 20px;
    color:#c71e1e;

    font-family: neon;
    font-size: 20pt;
    animation: glow 1s linear infinite;
    -moz-animation: glow 1s linear infinite;
    -webkit-animation: glow 1s linear infinite;
    -o-animation: glow 1s linear infinite;
}

/* @keyframes glow {
  50% {
    text-shadow: 0 0 0.5vw #8f2626, 0 0 2vw #ff0000, 0 0 3vw #ff3366,
      0 0 3vw #fff, 0 0 5vw #fed641, 0 0 3vw #fed641, 0 0 2vw #ff69b4,
      0 0 0.5vw #ff69b4;
    color: #ff6347;
  }
  0%,
  100% {
    text-shadow: 0 0 0.5vw #e01c1c, 0 0 1vw #e01c1c, 0 0 2vw #ff3366,
      0 0 3vw #ff3366, 0 0 5vw #ff3366, 0 0 2vw #ff3366, 0 0 1vw #ff3366,
      0 0 0.5vw #8f2626;
    color: #ff3366;
    -webkit-text-stroke: 0.1px #ff69b4;
  }
} */

@media (max-width:767px) {
    #click-here {
        font-size: 12px;
    }
}

</style>