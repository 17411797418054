<template>
    <div class="dashboard_main">
        <Dashboardheader />
        <div class="middle-part dashboard">
            <Dashboardsidebar />
            <div class="rigt-part">
                <h3>Edit Job Post</h3>
                <div class="gt-started-frm tabinfo" id="edit_jobpost_form">
				<!-- <div v-if="message!=''" class="success">{{message}}</div> -->
				<!-- <div v-if="errormessage!=''" class="error">{{errormessage}}</div> -->
                <h4>Let's get started</h4>
				<form id="jobpost-form" v-on:submit.prevent="updateJobpost">
                        <input type="hidden" v-model="state.slugs" name="slugs" id="slugs" />
                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Company Name For This Job *</label>
                                <input type="text" placeholder="Enter Company Name For This Job" v-model="state.companyName" name="companyName" id="companyName" autocomplete="off" />
                                <span v-if="v$.companyName.$error">
                                    {{ v$.companyName.$errors[0].$message }}
                                </span>
                            </div>
                            <div class="tw-clm">
                                <label>Your Role In The Hiring Process *</label>
                                <!-- <v-select :options="['Canada', 'United States']"></v-select> -->
                                <!-- <v-select :options="roleList"></v-select> -->
                                <select v-model="state.role" name="role" id="role" autocomplete="off">
                                    <option value="">Select an Option </option>
                                    <option v-bind:key="roles.id" v-for="roles in roleList" :value="roles.id">{{roles.role_name}}</option>
                                </select>
                                <span v-if="v$.role.$error">
                                    {{ v$.role.$errors[0].$message }}
                                </span>
                            </div>
                        </div>

                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Your Typical Hiring Budget</label>
                                <select v-model="state.budget" name="budget" id="budget" autocomplete="off">
                                    <option value="">Select an Option </option>
                                    <option v-bind:key="budgets.id" v-for="budgets in budgetList" :value="budgets.id">{{budgets.budget_name}}</option>
                                </select>
                                <span v-if="v$.budget.$error">
                                    {{ v$.budget.$errors[0].$message }}
                                </span>
                            </div>
                            <div class="tw-clm">
                                <label>Job Title *</label>
                                <input type="text" placeholder="Enter Job Title" v-model="state.title" name="title" id="title" autocomplete="off" />
                                <span v-if="v$.title.$error">
                                    {{ v$.title.$errors[0].$message }}
                                </span>
                            </div>
                        </div>
                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Job Description *</label>
                                <!-- <textarea class="textarea" name="description" id="description" v-html="state.description" v-model="state.description"></textarea> -->
                                <!-- <ckeditor :editor="editor" v-model="state.description" :config="editorConfig"></ckeditor> -->
                                <ckeditor :editor="editor" @ready="onReady" v-model="state.description" :config="editorConfig"></ckeditor>
                            </div>
                        </div>

                            <div class="rw-gap">
                                <div class="tw-clm">
                                    <label>Where will an employee report to work? *</label>
                                    <select v-model="state.empWorkReported" name="empWorkReported" id="empWorkReported" autocomplete="off">
                                        <option value="">Select an Option </option>
                                        <option v-bind:key="workReport.id" v-for="workReport in workReportList" :value="workReport.id">{{workReport.work_report_name}}</option>
                                    </select>
                                    <span v-if="v$.empWorkReported.$error">
                                        {{ v$.empWorkReported.$errors[0].$message }}
                                    </span>
                                </div>
                                <div class="tw-clm">
                                    <label>Job Experience *</label>
                                    <select v-model="state.jobExperience" name="jobExperience" id="jobExperience" autocomplete="off">
                                        <option value="">Select an Option </option>
                                        <option v-bind:key="exper.id" v-for="exper in experienceList" :value="exper.id">{{exper.experience}}</option>
                                    </select>
                                    <span v-if="v$.jobExperience.$error">
                                        {{ v$.jobExperience.$errors[0].$message }}
                                    </span>
                                </div>
                            </div>
                            <div class="rw-gap">
                                <div class="tw-clm">
                                    <label>Enter A Street Address</label>
                                    <GMapAutocomplete type="text" v-model="state.streetAddress" placeholder="Enter A Street Address" name="streetAddress" id="streetAddress"
                                                    @place_changed="setPlace">
                                    </GMapAutocomplete>
                                    <!-- <span v-if="v$.streetAddress.$error">
                                        {{ v$.streetAddress.$errors[0].$message }}
                                    </span> -->
                                </div>
                            </div>
                            <div class="rw-gap">
                                <div class="tw-clm">
                                    <label>How do you want your location displayed to job seekers?</label>
                                    <div ref="mapDiv">
                                        <GMapMap :center="center"
                                                :zoom="8"
                                                map-type-id="terrain"
                                                style="width: 100%; height: 800px">
                                            <GMapCluster>
                                                <GMapMarker :key="index"
                                                            v-for="(m, index) in markers"
                                                            :position="m.position"
                                                            :clickable="true"
                                                            :draggable="true"
                                                            @dragend="showLocation"
                                                            @click="center=m.position" />
                                            </GMapCluster>
                                        </GMapMap>
                                    </div>
                                    <div class="map-text">
                                        <p>Display exact location <br>Job seekers will see a map with a location pin.</p>

                                        <input type="text" v-bind:value="state.oLat" name="cLat" id="cLat" />
                                        <input type="text" v-bind:value="state.oLng" name="cLng" id="cLng">

                                    </div>
                                </div>
                            </div>
                            <div class="rw-gap">
                                <div class="tw-clm">
                                    <label>Country: *</label>
                                   <input type="text" placeholder="Enter Country" v-bind:value="state.country" name="country" id="country1" autocomplete="off" />

                                    <!-- <span v-if="v$.country.$error">
                                        {{ v$.country.$errors[0].$message }}
                                    </span> -->
                                </div>
                                <div class="tw-clm">
                                    <label>City *</label>
                                     <input type="text" placeholder="Enter City" v-bind:value="state.city" name="city" id="city1" autocomplete="off" />
                                    <!-- <span v-if="v$.city.$error">
                                        {{ v$.city.$errors[0].$message }}
                                    </span> -->
                                </div>
                            </div>

                            <div class="rw-gap">
                                <div class="tw-clm">
                                    <label>How many hires? *</label>
                                    <input type="text" placeholder="Enter hires" v-model="state.hires" name="hires" autocomplete="off" />
                                    <span v-if="v$.hires.$error">
                                        {{ v$.hires.$errors[0].$message }}
                                    </span>
                                    <p>This helps us match you with the right number of applicants.</p>
                                </div>
                                <div class="tw-clm">
                                    <label>Type Of Job *</label>
                                    <select v-model="state.jobType" name="jobType" autocomplete="off">
                                        <option value="">Select an Option </option>
                                        <option v-bind:key="jobType.id" v-for="jobType in jobTypeList" :value="jobType.id">{{jobType.job_type}}</option>
                                    </select>
                                    <span v-if="v$.jobType.$error">
                                        {{ v$.jobType.$errors[0].$message }}
                                    </span>
                                </div>
                            </div>
                            <h4>Contact Person</h4>
                        <div class="rw-gap">
                            <div class="tw-clm">
                                <label>Email </label>
                                <input type="text" placeholder="Enter Email" v-model="state.cemail" name="cemail" autocomplete="off" />
                                <!-- <span v-if="v$.cemail.$error">
                                    {{ v$.cemail.$errors[0].$message }}
                                </span> -->
                                
                            </div>
                            <div class="tw-clm">
                                <label>Phone Number </label>
                                <input type="text" @input ="acceptNumber" placeholder="Enter Phone Number" v-model="state.cphoneNumber" name="cphoneNumber" autocomplete="off" />
                                <!-- <span v-if="v$.cphoneNumber.$error">
                                    {{ v$.cphoneNumber.$errors[0].$message }}
                                </span> -->
                            </div>
                        </div>
                            <div class="rw-gap"> </div>
                            <div class="action_wpr">
                                <input type="submit" value="CONTINUE">
                                <div v-if="errormessage!=''" style="color:red;" class="errormessage">{{errormessage}}</div>
                            </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable no-undef */
    
    import axios from 'axios'
    import { BASE_URL } from '../config'
    import { API_BASE_URL } from '../config'
    import { reactive, computed } from "vue"
    import useValidate from "@vuelidate/core"
    import { required } from "@vuelidate/validators"
    import Dashboardheader from '../components/Dashboardheader.vue'
    import Dashboardsidebar from '../components/Dashboardsidebar.vue'
    import { useRoute } from 'vue-router'
    import { createLocal } from 'the-storages'
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue'
    import { createToast } from 'mosha-vue-toastify';
    import 'mosha-vue-toastify/dist/style.css';

    const mirror = createLocal()
    const storage = mirror._prx
    const placeSearch=''
    const autocomplete=''
    
    export default {
        name: 'JobEdit',
        components: {
            Dashboardheader,
            Dashboardsidebar,
            ckeditor: CKEditor.component
        },
        setup() {
            const state = reactive({
                companyName: '',
                role: '',
                budget: '',
                title: '',
                empWorkReported: '',
                jobExperience: '',
                hires: '',
                jobType: '',
                oLat: '',
                oLng: '',
                description:'',
                streetAddress:'',
                country:'',
                city:'',
                cphoneNumber:'',
                cemail:''
            })

            const rules = computed(() => {
                return {
                    companyName: { required },
                    role: { required },
                    budget: { required },
                    title: { required },
                    empWorkReported: { required },
                    jobExperience: { required },
                    hires: { required },
                    jobType: { required },
                    description:{required},
					streetAddress:{required},
					country:{required},
					city:{required}
                    // cnfPassword:{ required, sameAsPassword: sameAs("userPassword") },
                }
            })
            const v$ = useValidate(rules, state)
            

            return {
                state,
                v$
            }
        },
        data() {
            return {
                companyName: '',
                role: '',
                budget: '',
                title: '',
                empWorkReported: '',
                jobExperience: '',
                country: '',
                city: '',
                hires: '',
                oLat: '',
                oLng: '',
                cemail:'',
                cphoneNumber:'',
                jobType: '',
                roleList: {},
                errors: [],
                
                errormessage: '',
                message:'',
                storageData: mirror,
                storage: storage,
                placeSearch: placeSearch,
                autocomplete: autocomplete,
                budgetList: {},
                streetAddress:'',
                center: { lat: 51.093048, lng: 6.842120 },
                markers: [
                    {
                        position: {
                            lat: 51.093048, lng: 6.842120
                        },
                    }
                    , // Along list of clusters
                ],
                location: null,
                gettingLocation: false,
                errorStr: null,
                editor: ClassicEditor,
                description: '',
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        },
        async mounted() {
            this.getDetails();
        },
        async created() {
            //console.log(mirror);
			
            
        },
        methods: {   
            async getDetails(){
                const route=useRoute();
                //console.log("URL: ",API_BASE_URL + 'jobDetails/'+route.params.slug);
                const jobDetails = await axios.get(API_BASE_URL + 'jobDetails/'+route.params.slug);
                if(mirror.AUTHID!=jobDetails.data.user_id){
                this.$router.push({ name: "JobList" });	
                }
                this.state.slugs        =  route.params.slug;
                this.state.companyName  =  jobDetails.data.company_name;
                this.state.role         =  jobDetails.data.hiring_role;
                this.state.budget       =  jobDetails.data.hiring_budget;
                this.state.hires        =  jobDetails.data.how_many_hires;
                this.state.empWorkReported  =  jobDetails.data.employee_report_to_work;
                this.state.description  =  jobDetails.data.job_description;
                this.state.jobExperience=  jobDetails.data.job_experience;
                this.state.title        =  jobDetails.data.job_title;
                this.state.jobType      =  jobDetails.data.job_type;
                this.state.oLat         =  jobDetails.data.lat;
                this.state.oLng         =  jobDetails.data.lng;
                this.state.streetAddress=  jobDetails.data.street_address;
                this.state.country      =  jobDetails.data.country;
                this.state.city         =  jobDetails.data.city;
                this.state.cemail       =  jobDetails.data.contactEmail;
                this.state.cphoneNumber =  jobDetails.data.contactPhone;
                this.editorData         =jobDetails.data.job_description;
                
                console.log(this.state.streetAddress)
                document.getElementById("streetAddress").value = this.state.streetAddress;
            
                const response2 = await axios.get(API_BASE_URL + 'job');
                this.jobTypeList = response2.data.jobtypes[0];
                this.roleList = response2.data.hire_role[0];
                this.budgetList = response2.data.budgetdetails[0];
                this.workReportList = response2.data.work_report[0];
                this.experienceList = response2.data.experience[0];

                this.gettingLocation = true;
                
                this.center = { lat: parseFloat(this.state.oLat), lng: parseFloat(this.state.oLng) };
                this.markers = [
                {
                    position: {
                    lat: parseFloat(this.state.oLat), lng: parseFloat(this.state.oLng)
                    },
                }
                ];

                // const posi = { lat: parseFloat(this.state.oLat), lng: parseFloat(this.state.oLng)}
                // console.log("RESULT2:: ",posi);
                // this.geocodePosition(posi)
            },
            
            acceptNumber() {
                var x = this.state.cphoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.state.cphoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
			},	
            acceptPhoneNumber() {
                var x = this.state.cphoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.state.cphoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
			},
            setPlace()																											{
                const address = document.getElementById("streetAddress").value;
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({ 'address': address }, (result, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        //document.getElementById("country").value = '';
                        console.log(result[0])
                        //document.getElementById("city").value = result[0].address_components[1].long_name
                        var locations2 = result[0].address_components;
                        for (var ii = 0; ii < locations2.length; ii++) {
                            if (locations2[ii].types[0] === "country") {
                                //document.getElementById("country").value = locations2[ii].long_name;
                                //alert(document.getElementById("country").value);
                                this.state.country = locations2[ii].long_name;
                                //console.log(this.country);
                            }
                            if (locations2[ii].types[0] === "locality") {
                                //document.getElementById("city").value = locations2[ii].long_name;
                                //alert(document.getElementById("city").value);
                                this.state.city = locations2[ii].long_name;
                                //console.log(this.city);
                            }
                                
                        }
                        var latitude = result[0].geometry.location.lat();
                        var longitude = result[0].geometry.location.lng();

                        this.state.oLat = latitude;
                        this.state.oLng = longitude;

                        this.center= { lat: latitude, lng: longitude },
                        this.markers= [
                            {
                                position: {
                                    lat: latitude, lng: longitude
                                },
                            }
                            , // Along list of clusters
                        ]
                    }
                })
            },
            showLocation: function (evt) 																					{
                console.log('hello1')
                this.state.oLat = evt.latLng.lat();
                this.state.oLng = evt.latLng.lng();
                //console.log(this.oLng);
                const posi = { lat: evt.latLng.lat(), lng: evt.latLng.lng()}
                console.log("RESULT:: ",posi);
                this.geocodePosition(posi)
                //alert(evt)
            },
            geocodePosition: function (pos) 																																																																														{
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({ 'latLng': pos}, (result, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        console.log(result[0].address_components)
                        // document.getElementById("city").value = result[0].address_components[3].long_name
                        var locations = result[0].address_components;
                        for (var i = 0; i < locations.length; i++) {
                            if (locations[i].types[0] == "country") {
                                //document.getElementById("country1").value = locations[i].long_name;
                                this.state.country = locations[i].long_name;
                                //console.log(this.country);
                            } else {
                                //document.getElementById("country1").value = locations[i].long_name
                            }
                            if (locations[i].types[0] == "administrative_area_level_1") {
                                //document.getElementById("city1").value = locations[i].long_name;
                                this.state.city = locations[i].long_name;
                                //console.log(this.city);
                            } else {
                                //document.getElementById("city1").value = locations[i].long_name
                            }
                        }
                        document.getElementById("streetAddress").value = result[0].formatted_address
                    }
                })
            },

            async updateJobpost() {
                var addresss = document.getElementById("streetAddress").value;
                var countryss = document.getElementById("country1").value;
                var cityss = document.getElementById("city1").value;
                var lat = document.getElementById("cLat").value;
                var lng = document.getElementById("cLng").value;

                this.v$.$validate(this.v$.$error);
                //console.log("Input-Params",slugs)

                if (!this.v$.$error) {
                    if (addresss && countryss && cityss && this.state.companyName && this.state.role && this.state.budget && this.state.title && this.state.empWorkReported && this.state.jobExperience && this.state.hires && this.state.jobType) {
                        axios.get(BASE_URL + 'sanctum/csrf-cookie').then(responses => {
                            const inputData = {
                                slug:this.state.slugs,
                                userId: mirror.AUTHID,
                                companyName: this.state.companyName,
                                role: this.state.role,
                                budget: this.state.budget,
                                address:addresss,
                                title: this.state.title,
                                description: this.state.description,
                                empWorkReported: this.state.empWorkReported,
                                jobExperience: this.state.jobExperience,
                                hires: this.state.hires,
                                country: countryss,
                                city: cityss,
                                lat: lat,
                                lng: lng,
                                contactEmail:this.state.cemail,
                                jobType: this.state.jobType,
                                contactPhone:this.state.cphoneNumber,
                            };
                            //console.log("Input-data",inputData)
                            axios.post(API_BASE_URL + 'edit-job', inputData)
                                .then(response => {
                                    if (response.data.result == 'success') {
                                        //console.log('data:', response.data)
                                       // this.errormessage = 'Your Job updated successfully'//response.data.message;
                                        this.message = 'Your Job updated successfully';
                                        this.showToast("Success",this.message,"success"); 
                                        //this.$router.push({ name: "JobList" });
                                    } else {
                                        this.errormessage = 'Your Job updated faild';
                                        this.showToast("Failed",this.errormessage,"danger"); 
                                       // this.message = response.data.messase;
                                    }
									var element = document.getElementById("edit_jobpost_form");
									element.scrollIntoView();
                                })
                                .catch(error => {
                                    console.log('error-data:', error)
                                    console.error(error);
                                });
                            console.log(responses);
                        });

                    } else {
                        this.errormessage = 'All Fields are Mandatory';
                        this.showToast("Failed",this.errormessage,"danger"); 
                    }
                } else {
                    this.errormessage = 'All Fields are Mandatory';
                    this.showToast("Warning!",this.errormessage,"warning"); 
                }
                // setTimeout(() => {
                //     this.errormessage = '';
                //     console.log(this.errormessage);
                // }, 5000);
            },
            showToast(title,description,type){
                createToast({
                    title: title,
                    description: description
                },
                {
                    type: type,
                    position: 'bottom-right',
                    // hideProgressBar: 'true',
                    // toastBackgroundColor: color,
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            if (storage.get('TOKEN')) {
                if(storage.USERTYPE==2)
                    next();
                else if(storage.USERTYPE==3)
                    next('/jobs');
                else
                    next('/');	
                }
                else{
                    next('/login');
                }
            }
        }
</script>

<style scoped>
    .vue-map-container {
        height: 400px;
    }
    span {
        color: red;
    }
    .gt-started-frm .success{
        margin-bottom: 10px;
    }
    .vue-map {
        height: 80vh !important
    }
    .textarea {
        height: 200px
    }
    .action_wpr{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
</style>