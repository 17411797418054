<template>
  <div class="gt-started-frm tabinfo" id="gallery_info">
	<!-- <div v-if="message!=''" class="success">{{message}}</div>
	<div v-if="errormessage!=''" class="error">{{errormessage}}</div> -->
    <h4>Gallery</h4>
   

    <div class="dragbox">
		<div v-bind="getRootProps()">
		<input v-bind="getInputProps()" accept="image/*" />
        <span >
            <svg xmlns="http://www.w3.org/2000/svg" :class="{ 'animate-bounce': droppedFile === null }" class="w-20 h-20" fill="none" color="#555" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
        </span>
        <h5 >Drag a file here</h5>
        <p >Or if you prefer</p>
        </div>
        <!-- File input -->
        <label for="file" class="browse_btn" @click="open">
            <!-- <input multiple="" type="file" autocomplete="off" tabindex="-1" data-v-bf04bc0c="" style="display: none;" accept="image/*"> -->
            <span>Select a file from your device</span>
           
        </label>
        
    </div> 
		<vue-easy-lightbox
		:visible="visible"
		:imgs="imgs"
		:index="index"
		@hide="handleHide"
		></vue-easy-lightbox>
		<ul class="img_listing" v-show="getGalleryData">
		<li :key="index" v-for="(gallery,index) in gallerys" class="image" >
		<div class="img_wpr pic" @click="() => showImg(index)">
		<img v-bind:src="imagepath+'gallery/'+gallery.photo" alt="">
		</div>
		<button @click="deleteimage(gallery.id)" class="delete_btn"><i class="far fa-trash-alt"></i></button>
		</li>
		</ul>
    
    
  </div>
  <div class="modal" v-if="modalDelete">
        <div class="content_wrapper">
          <div class="modal_header">
            <h2>Are you sure want to remove this image ?</h2>
            <!-- <button @click="close"><i class="fas fa-times"></i></button> -->
          </div>
          <div class="modal_body">
            <div class="form_grp">
              <form id="jobpost-form" v-on:submit.prevent="deleteVideoSubmit">
                <div class="item_grp">
                  <input type="hidden" v-model="deleteId" name="deleteId">
                  <button type="submit" class="add_btn">Confirm</button>
                  <button class="add_btn" @click="closemodal">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import axios from "axios";
import { API_BASE_URL } from '../config'
import { createLocal } from 'the-storages'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const mirror = createLocal()
const storage = mirror._prx
let refdata;
//const gallerys=getResults()
import VueEasyLightbox from 'vue-easy-lightbox'
import { API_IMAGE_PATH } from '../config'
export default {
  name: 'EpE',
  components:{
      VueEasyLightbox
  },
  data(){
    return{
      storageData: mirror,
      storage: storage,
      imagepath:'',
      modal: false,
      gallerys:[],
      modalDelete: false,
      visible: false,
      index: 0, 
      imgs: [],
      errormessage: '',
      message:'',
      getGalleryData:true,
    }
  },
	setup() {
    //refdata.getGalleryData = false;
    //console.log('userid',mirror.AUTHID);
    const url = API_BASE_URL+'galleryupload'; // Your url on the server side
    const saveFiles = (files) => {
      const formData = new FormData(); // pass data as a form
      //console.log(files.length)
      for (var x = 0; x < files.length; x++) {
        // append files as array to the form, feel free to change the array name
        formData.append("images[]", files[x]);
      }
      formData.append('userId', mirror.AUTHID);    
        axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          
          console.info(response.data);
          if (response.data.result == 'success') {
            refdata.errormessage ='';
            refdata.message = response.data.message;
            //refdata.getResults();
            refdata.getResults();
          } else {
            refdata.errormessage = response.data.message;
            refdata.message = '';
          }
          var element = document.getElementById("gallery_info");
          element.scrollIntoView();
          //refdata.getResults();
        })
        .catch((err) => {
          console.error(err);
        });
    };
    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles); // saveFiles as callback
      console.log(rejectReasons);
    }
    //refdata.getGalleryData = true;
    const { getRootProps, getInputProps, isDragActive,...rest } = useDropzone({ onDrop });
    return {
      getRootProps,
      getInputProps,
      isDragActive,
      ...rest,
    };
  },
	// mounted() {
	// // Fetch initial results
	//   this.getResults();
	//   refdata = this
	// },
	methods: {
    async getResults() {		
      this.getGalleryData = false;	
      await axios.get(API_BASE_URL + 'galleryimages/'+mirror.AUTHID)
      .then(response => {
      this.gallerys = response.data;
      for(var i=0;i<this.gallerys.length;i++){
        this.imgs.push(API_IMAGE_PATH+'gallery/'+this.gallerys[i].photo)
      }
      this.imagepath=API_IMAGE_PATH;
      this.getGalleryData = true;	 
      //console.log(this.imgs);
    });
    },
    deleteimage: function(dId){
      this.modalDelete = true
      this.deleteId   =   dId;
    },
    closemodal: function(){
      this.modal = false;
      this.modalDelete = false;
    },
    async deleteVideoSubmit() {
        await axios.post(API_BASE_URL + 'deleteimage', {"deleteId":this.deleteId})
        .then(response => {
          //console.log(response);
            if (response.data.result == 'success') {
                this.errormessage ='';
                this.message = response.data.message;
                this.modalDelete = false
                //this.$router.push({ name: "JobList" });
                this.showToast("Success",this.message,"success"); 
            } else {
                this.errormessage = response.data.message;
                this.message = '';
                this.showToast("Failed",this.errormessage,"danger"); 
            }
            this.getResults();
        var element = document.getElementById("gallery_info");
        element.scrollIntoView();
        })
        .catch(error => {
            //console.log('error-data:', error)
            console.error(error);
        });
    },
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    showToast(title,description,type){
      createToast({
          title: title,
          description: description
      },
      {
          type: type,
          position: 'bottom-right',
          // hideProgressBar: 'true',
          // toastBackgroundColor: color,
      })
  },
},
async created() {
  this.getResults();
  refdata = this
	//console.log('sdfsdfd')
	}
	
}
</script>

<style scoped>
  .dragbox{
    margin-top: 30px; text-align:center;
  }
  .img_listing{
    display: flex;
    flex-wrap: wrap;
    margin: 30px -15px 0;
  }
  .img_listing li{
    width: 16.666%;
    padding: 15px;
    position: relative;
  }
  .img_listing li .img_wpr{
    border: 1px solid #444;
    line-height: 0;
  }
  .img_listing li .delete_btn{
    font-size: 13px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: #fff;
    background: #c71e1e;
    cursor:pointer;
    position: absolute;
    right: 4px;
    top: 4px;
    box-shadow: 0 1px 5px 1px rgba(0,0,0,0.3);
  }
  .img_listing li .img_wpr img{
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
  
  
  .dragbox{
        border: 2px dashed #666;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #161618;
    }
    .dragbox.box_active{
        background: rgba(255,255,255,0.05);
    }
    .dragbox svg{
        width: 70px;
    }
    .dragbox h5{
        font-size: 18px;
        line-height: 23px;
        color: #fff;
        font-weight: 500;
        padding: 0;
    }
    .dragbox p{
        font-size: 15px;
        line-height: 20px;
        color: #999;
        font-weight: 500;
    }
    .dragbox label.browse_btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    .dragbox .browse_btn span{
        border: 1px solid #999;
        padding: 7px 15px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        background: transparent;
        color: #ccc;
        cursor: pointer;
    }
    .dragbox .browse_btn input{
        display: none;
    }
    .file_info{
        display: flex;
        align-items: center;
        padding-top: 5px;
    }
    .file_info span{
        font-size: 15px;
        line-height: 20px;
        color: #fff;
    }
    .file_info .close_btn{
        background: #c71e1e;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        font-size: 15px;
        color: #fff;
        margin-left: 5px;
        cursor: pointer;
    }
    button.add_btn{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 30px;
    color: #fff;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    background: transparent;
    margin-left: 25px;
    cursor:pointer;
    float:right;
  }
  button.save_btn{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 30px;
    color: #fff;
    border: 1px solid #c71e1e;
    background: #c71e1e;
    text-transform: uppercase;
    font-family: 'Play', sans-serif;
    cursor:pointer;
  }
  @media(max-width:991px){
    .img_listing li{
      flex: 0 0 25%;
    }
  }
  @media(max-width:670px){
    .img_listing li{
      flex: 0 0 50%;
    }
  }
</style>
<style>
.vel-modal{
  background: rgba(0,0,0,0.9);
}
</style>