<template>
	<Navigation />
	<div class="login_main">
		<div class="container">
			<div class="login_basic_structure">
				<!-- <h4>Candidate</h4> -->
				<!-- <ul>
					<li v-for="item in error" v-bind:key="item">
						{{item}} not valid
					</li>
				</ul> -->
				<img src="@/assets/sign.png">
				<form id="login-form" v-on:submit.prevent="login">
					<!-- <div v-if="errorNew" class="errorNew">{{errorNew}}</div>
					<div v-if="errormessage!=''" class="errormessage">{{errormessage}}</div> -->
					<div class="form_group">
						<label for="fname">Email</label>
						<input type="text" placeholder="Enter Email Address" name="email" v-model="state.email">
						<span v-if="v$.email.$error">
							{{ v$.email.$errors[0].$message }}
						</span>
					</div>
					<div class="form_group">
						<label for="lname">password</label>
						<input :type="passwordFieldType" placeholder="Enter Password" name="password" v-model="state.password">
						<span v-if="v$.password.$error">
							{{ v$.password.$errors[0].$message }}
						</span>
					</div>
					<div class="form_group">
						<label class="input"> <input style="width:5%;height:13px;" type="checkbox" v-on:click="switchVisibility"> Show password</label>
					</div>
					<!-- <button type="submit" class="sign_submit">Submit</button> -->
					<button type="submit" class="sign_submit" :disabled="buttonclick">
						<span v-if="buttonclick==true" style="color:white">Submitting. . .</span>
						<span v-else style="color:white">Submit</span>
					</button>
					<div class="create_forgot">
						<li><router-link to="/signup" class="create_accout">Create Account</router-link></li>
						<li><router-link to="/forget-password" class="forgot_password">Forgot Password?</router-link></li>
					</div>
				</form>
			</div>
		</div>
	</div>
	<Footer />
</template>
<script>
import { reactive, computed } from "vue"
import { BASE_URL } from '../config'
import useValidate from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import axios from 'axios'
import { createLocal } from 'the-storages'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const mirror = createLocal()
const storage = mirror._prx
export default{
	name: 'Login',
	components: {
		Navigation,
		Footer
	},
	setup(){
		const state = reactive({
            email:'',
			password:''
		})

		const rules = computed(() => {
			return {
				email:{ required, email },
				password:{ required }
			}
		})
		const v$ = useValidate(rules, state)

		return {
			state,
			v$
		}
	},
    data(){
        return{
		form:{
			email:'',
			password:''
		},
		error: [],
		errormessage: '',
		errorNew:'',
		storageData: mirror,
		storage: storage,
		passwordFieldType:'password',
		buttonclick:false
      }
	},
	created (){
		this.hash = location.hash;
		if(this.hash=='#show'){
			this.showToast("Password Change Successfully !",`Now, you can login with new password`,"success");
			location.hash = '';
		}

		var storedData = localStorage.getItem('forgetPassword');
		console.log("DATA ERROR - ",storedData);
		
		if( storedData == 'yes'){
			this.errorNew = 'Password change successfully';
			setTimeout(() => {
				localStorage.setItem('forgetPassword', 'no');
				this.errorNew = '';
				console.log(this.errorNew);
			}, 5000);
		} else {
			localStorage.setItem('forgetPassword', 'no');
			this.errorNew = '';
		}
		storedData = localStorage.getItem('forgetPassword');
		console.log("DATA ERROR 2- ",storedData);
	},
	methods:{
		login() {
			this.buttonclick = true;
			this.v$.$validate(this.v$.$error);
			console.log("LOG:",this.v$.$error);
			console.log("EMAIL:",this.state.email)
			console.log("PASSWORD:",this.state.password)
			if (!this.v$.$error) {
				console.log("IF:");
				axios.get(BASE_URL+'sanctum/csrf-cookie').then(response => {
					axios.post(BASE_URL +'api/login', {
						email: this.state.email,
						password: this.state.password
					})
					.then(response => {
						// console.log(response.data)
						if (response.data.success) {
							console.log(response.data)
							this.errormessage = ''
							this.storage.set('AUTHID', response.data.userId)
							this.storage.set('NAME', response.data.userName)
							this.storage.set('USEREMAIL', response.data.useremail)
							this.storage.set('USERTYPE', response.data.userType)
							this.storage.set('PROFILEIMAGE', response.data.profile_image)
							this.storage.set('DESIGNATION', response.data.designation)
							this.storage.set('TOKEN', response.data.token)
							this.storage.set('MEMBERSHIP_TYPE', response.data.membershipType)
							this.storage.set('PERIOD_TYPE', response.data.periodType)
							
							this.$router.push({ name: "Dashboard" ,hash: "#show"});
							this.buttonclick = false;
						} else {
							this.errormessage = response.data.message;
							this.buttonclick = false;
							this.showToast("Failed",this.errormessage,"danger"); 
						}
					})
					.catch(function (error) {
						console.error(error);
					});
					console.warn(response)
				})
			} else {
				console.log("ELSE:");
				this.errormessage = '';
				this.buttonclick = false;
			}
		},
		switchVisibility() {
			this.passwordFieldType = this.passwordFieldType==="password"?"text":"password";
		},
		showToast(title,description,type){
			createToast({
				title: title,
				description: description
			},
			{
				type: type,
				position: 'bottom-right',
				// hideProgressBar: 'true',
				// toastBackgroundColor: color,
			})
		},
	},
	beforeRouteEnter(to, from, next) {
		if (storage.get('TOKEN')) {
			console.log('test')
			next('/dashboard');
			//this.$router.push({ name: "Blogs" });
		}
		else{
			next();
		}
	}   
}
</script>
<style scoped>
span {
        color: red;
    }
.login_main{
    padding:15% 0 10%;
    background: url('~@/assets/log_bg.jpg') no-repeat #111112;
    background-size:cover;
}

.login_main .login_basic_structure {
	width: 400px;
	height: auto;
	background-color: #212122;
	box-shadow: 0 3px 5px #000;
	margin: auto;
	float: none;
	padding: 20px 40px 30px;
	background-image: url(~@/assets/user.png);
	background-position: bottom right;
	background-repeat: no-repeat;
	border-top: solid 2px #b71d1d;
}

.login_main .login_basic_structure h4 {
	margin: 0 0 20px;
	font-size: 22px;
	color: #fff;
	text-transform: uppercase;
}

.login_main .login_basic_structure form {
	margin-top: 30px;
	clear: both;
	width: 100%;
}

.login_main .login_basic_structure form .form_group {
	width: 100%;
	margin-bottom: 20px;
}

.login_main .login_basic_structure form .form_group label {
	width: 100%;
	font-size: 16px;
	color: #fff;
	margin-bottom: 5px;
	display: inline-block;
	text-transform: capitalize;
}

.login_main .login_basic_structure form .form_group input {
	width: 100%;
	height: 50px;
	border: solid 1px #3d3d3d;
	background: transparent;
	box-sizing: border-box;
	color: #fff;
	font-size: 16px;
	padding: 10px;
}

.login_main .login_basic_structure form .sign_submit {
	width: 100%;
	height: 55px;
	background: #c71e1e;
	border-radius: 4px;
	border: none;
	font-size: 19px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
    cursor:pointer;
}

.login_main .login_basic_structure .create_forgot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
}

.login_main .login_basic_structure .create_accout {
	color: #fff;
	font-size: 16px;
	font-weight: 600;
}
.errorNew {
	background: green;
	color: white;
	padding: 10px;
	text-align: center;
	align-items: center;
}

.login_main .login_basic_structure .forgot_password {
	color: #c0c0c0;
	font-weight: 500;
}

  @media(max-width:740px){
.login_main .login_basic_structure{ width:100%; padding:20px 20px 30px;}

  }

</style>