<template>
  <div class="box-total frame">
    <div class="box">
      <div class="contentWpr">
        <div class="circle-big three">
          <div class="text">
            <img src="@/assets/dash/waiting-action.png" alt=""/>
          </div>
          <svg>
            <circle class="bg" cx="57" cy="57" r="52"></circle>
            <circle class="progress" cx="57" cy="57" r="52"></circle>
          </svg>
        </div>
        <p>{{totalJobCount}} </p><h4>Total Jobs</h4>
      </div>
    </div>
    <div class="box">
      <div class="contentWpr">
        <div class="circle-big one">
          <div class="text">
            <img src="@/assets/dash/new-candidate.png" alt=""/>	
          </div>
          <svg>
            <circle class="bg" cx="57" cy="57" r="52"></circle>
            <circle class="progress" cx="57" cy="57" r="52"></circle>
          </svg>
        </div>
        <p>{{appliedCandidateCount}} </p><h4>Applied Candidates</h4>
      </div>
    </div>
    <div class="box">
      <div class="contentWpr">
        <div class="circle-big two">
          <div class="text">
            <img src="@/assets/dash/response-due.png" alt=""/>	
          </div>
          <svg>
            <circle class="bg" cx="57" cy="57" r="52"></circle>
            <circle class="progress" cx="57" cy="57" r="52"></circle>
          </svg>
        </div>
        <p>{{savedCandidateCount}}</p><h4>Shortlisted Candidates</h4>
      </div>
    </div>
    <div class="box">
      <div class="contentWpr">
      <div class="circle-big three">
        <div class="text">
          <img src="@/assets/dash/pending-task.png" alt=""/>
        </div>
        <svg>
          <circle class="bg" cx="57" cy="57" r="52"></circle>
          <circle class="progress" cx="57" cy="57" r="52"></circle>
        </svg>
      </div>
      <p>{{candidateInterviewCount}}</p><h4>Interviews</h4>
      </div>
    </div>
    <!-- <div class="box">
      <div class="circle-big four">
        <div class="text">
          <img src="@/assets/dash/pending-task.png" alt=""/>
        </div>
        <svg>
          <circle class="bg" cx="57" cy="57" r="52"></circle>
          <circle class="progress" cx="57" cy="57" r="52"></circle>
        </svg>
      </div>
      <p>411 (385)</p><h4>Pending Tasks</h4>
    </div> -->
  </div>
 <DashboardInterview/>
 <div class="table-box" style="margin-top:25px;box-shadow:none;" v-if="totaljobList.length>0">
  <div class="table-header" >
   <h2> Latest Job List </h2>
   <div class="table-thirty"> 
   </div>
    <div class="joblist_Wpr">
        <ul class="job_list">
            <JobCardList :callback="clickToGetJobDetails" v-bind:key="job.id" v-for="job in totaljobList" :jobData="job" icon="icon2.png" />
        </ul>
    </div>
 </div>
</div>
<div class="table-box" style="box-shadow:none;" v-if="totalCandidate.length>0">
 <div class="table-header" style="margin-top:35px;">
   <h2>Latest Applied Candidates </h2>
   <div class="table-thirty"> 
     
     <!-- <h3>Interviews</h3>  -->
   <!-- <a href="#">View all</a>  -->
   </div>
    <!-- <div class="table-seventy">
     <ul>
      <li><button @click="activeTab = 'RecruA'" :class="{active: activeTab === 'RecruA'}">Feedback Due</button></li> 
      <li><button @click="activeTab = 'RecruB'" :class="{active: activeTab === 'RecruB'}">Today</button></li> 
      <li><button @click="activeTab = 'RecruC'" :class="{active: activeTab === 'RecruC'}">Upcoming </button></li> 
      <li><button @click="activeTab = 'RecruD'" :class="{active: activeTab === 'RecruD'}">Completed</button></li>
     </ul>
    </div> -->
    <!-- </div> -->
    <!-- <keep-alive>
    <component :is="activeTab"></component>
    </keep-alive> -->
    <div class="candidate_list">
        <ul>
            <!-- <li > -->
                <CandidateCard :callback="appliedJobsDetails" v-for="candidate in totalCandidate" v-bind:key="candidate" :candidateData="candidate"  />
            <!-- </li> -->
        </ul>
    </div>
 </div>
</div>
<div class="table-box" style="box-shadow:none;" v-if="totalShortlistedCandidate.length>0">
  <div class="table-header" style="margin-top:35px;">
   <h2>Latest Shortlisted Candidates </h2>
   <div class="table-thirty"> 
   </div>
    <div class="candidate_list" style="margin-bottom: 40px;"> 
        <ul>
            <CandidateShortlistCard :callback="appliedJobsDetails" v-for="candidate in totalShortlistedCandidate" v-bind:key="candidate" :candidateData="candidate"  />
        </ul>
    </div>
 </div>
</div>

<!-- <div class="pagination">
  <ul>
  <li class="active"><a href="#">1</a></li>  <li><a href="#">2</a></li>  <li><a href="#">3</a></li>  <li><a href="#">4</a></li>
  <li><a href="#">...</a></li>
  </ul>
</div>          -->
<div class="modelPopups" v-if="addModal" style="display:block">
    <div class="content_wrapper">
        <div class="modal_header">
            <!-- <h2>View Application of {{jobDetails.fname}}  -->
            <h2>View Application of {{MAIN_NAME}} 
              <!-- <span v-if="candidateType=='shortlist'">{{jobDetails.lname}}</span> -->
               <span> 000{{jobDetails.user_id}}</span>
            </h2>
            <button @click="close"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="form_grp">
                <input type="hidden" v-model="jobId" >
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>First Name: <span>{{jobDetails.fname}}</span> </label> 
                    </div>
                    <!-- <div style="width:48%;float:right;" v-if="candidateType=='shortlist'">
                        <label>Last Name: <span>{{jobDetails.id}}</span></label>
                    </div> -->
                    <div style="width:48%;float:right;">
                        <label>Last Name: <span>000{{jobDetails.user_id}}</span></label>
                    </div>
                </div> 
                <div class="item_grp"><label>Primary Email: <span>{{jobDetails.email}}</span></label> 
                </div>
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Experience Level: <span>{{experience_new}}</span></label>
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Area(s) of Interest: <span>{{area_interest}}</span></label> 
                    </div>
                </div> 
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Communities of Interest: <span>{{comm_interest}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Preferred Language: <span>{{preferred_language}}</span></label> 
                    </div>
                </div>

                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Primary Skill Set: <span>{{primary_skill}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Primary Country: <span>{{country}}</span></label> 
                    </div>
                </div>
                <!-- <div class="item_grp" v-if="candidateType=='shortlist'"> -->
                <div class="item_grp" >
                    <label>Resume: 
                        <span> {{jobDetails.resume}}
                       
                        <span @click="clickedDownload(jobDetails.resume)" title="Click here to Download" style="cursor:pointer;background:white;padding: 5px;border-radius: 50px;margin-left: 10px;">  Click To Download <i class="fas fa-download"></i></span>
                        </span>
                    </label>
                </div>  
            </div>
        </div>
    </div>
</div>
  
</template>


<script>

//import RecruA from '@/components/RecruA.vue'
//import RecruB from '@/components/RecruB.vue'
//import RecruC from '@/components/RecruC.vue'
//import RecruD from '@/components/RecruD.vue'
import CandidateCard from '../components/CandidateCard.vue'
import CandidateShortlistCard from '../components/CandidateShortlistCard.vue'
import JobCardList from '../components/JobCardList.vue'
import DashboardInterview from '../components/DashboardInterview.vue'
import axios from "axios";
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import { createLocal } from 'the-storages'
const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'DashboardRecruiter',
  components:{
   // RecruA,
    //RecruB,
    //RecruC,
    //RecruD,
    DashboardInterview,
    CandidateShortlistCard,
    CandidateCard,
    JobCardList
    },
  data(){
    return{
		activeTab: 'RecruA',
		storageData: mirror,
		storage: storage,
		totalCandidate:[],
		totalShortlistedCandidate:[],
		totaljobList:[],
		appliedCandidateCount:'',
		savedCandidateCount:'',
    candidateInterviewCount:'',
		totalJobCount:'',
		imagepath:'',
    addModal:false,
    MAIN_NAME:'',
    }
  },
	async created() {
    this.imagepath=API_IMAGE_PATH;
    this.AuthUserId = mirror.AUTHID
    this.userType = mirror.USERTYPE
    //console.log(mirror);
    const param    = {
    userId:mirror.AUTHID
    }
    const response = await axios.post(API_BASE_URL + 'companyWiseDashboardData',param);
    this.totalCandidate   = response.data.candidateAppliedData;
    this.totalShortlistedCandidate   = response.data.candidateShortlistData;
    this.totaljobList   = response.data.jobList;
    this.appliedCandidateCount   = response.data.appliedCandidateCount;
    this.savedCandidateCount   = response.data.savedCandidateCount;
    this.candidateInterviewCount   = response.data.candidateInterviewCount;
    this.totalJobCount   = response.data.totalJobCount;
    console.log("LATEST APPLIED CANDIDATE:",this.totalCandidate);
    console.log("LATEST SHORT LISTED CANDIDATE:",this.totalShortlistedCandidate);
    //console.log("DATA2:",response);
	},
  methods:{
    async appliedJobsDetails(id,candidateType,name) {
			console.log("NAMMEEE: ",name)
      this.candidateType = candidateType;
        const param    = {
            userId:mirror.AUTHID,
            jobId:id
        }
        const response2= await axios.post(API_BASE_URL + 'appliedJobDetails',param);
        this.jobDetails = response2.data;
        this.MAIN_NAME = name;
        this.experience_new = response2.data.experience_new==null ? '':response2.data.experience_new.experience;
        this.area_interest = response2.data.area_interest==null ? '':response2.data.area_interest.name;
        this.comm_interest = response2.data.comm_interest==null ? '':response2.data.comm_interest.name;
        this.preferred_language = response2.data.preferred_language==null ? '':response2.data.preferred_language.name;
        this.primary_skill = response2.data.primary_skill==null ? '':response2.data.primary_skill.name;
        this.country = response2.data.country==null ? '':response2.data.country.name;
        this.url = response2.data.resume ? this.imagepath+response2.data.resume : '';
        console.log("URL:",this.url);
        console.log("RESULT",response2.data);
        this.addModal = true;
    },
    close(){
      this.addModal = false
    },
    async clickedDownload(name) {
      //console.log(name);
      //console.log(API_IMAGE_PATH+'appliedJobDocument/'+name);
        let linkData = API_IMAGE_PATH+'appliedJobDocument/'+name; //'http://74.207.234.105:8080/img/logo.7b4b26fa.png';
        
        axios.get(linkData,{responseType: 'blob'})
        .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    },
    async clickToGetJobDetails(jobId,slug){
          
          this.$router. push('/jobDetails/'+slug);
      },
  },
}
</script>
<style scoped>
.modelPopups .content_wrapper{
    max-width: 600px !important;
    width: 85% !important;
}
.content_wrapper {
    width: 50%;
    height: auto;
    background: #161618;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
    .modal_body .form_grp .item_grp select {
        margin-top: 3px !important;
        border: 1px solid #3d3d3d;
        background: #212122;
        padding: 10px 15px !important;
        width: 100%;
        color: #d9d9d9;
    }
    .modelPopups {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0,0,0,0.9);
        display: flex !important;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        padding: 10px;
    }
    
.box-total{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 20px -15px;
}
.box-total .box{
  flex: 1 1 25%;
  background: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 15px !important;
}
.box-total .box .contentWpr{
  background: #28282b;
  padding: 26px 0;
  box-shadow: 0 0 6px rgb(0,0,0,0.5);
  border-top: 2px solid #c71e1e;
}
.table-box .table-header{
  padding: 0 !important;
  background: transparent !important;
}
.joblist_Wpr{
  background: #28282b;
  padding: 30px 60px;
  margin-top: 20px;
  box-shadow: 0 0 6px rgb(0,0,0,0.5);
  position: relative;
}
.joblist_Wpr:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-image: url('~@/assets/figure-bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat ;
}
.job_list > li{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255,255,255,0.05);
  padding: 40px 0;
  position: relative;
  z-index: 2;
}
.job_list > li:last-child{
  border:0;
}
.candidate_list ul{
  margin-top: 20px;
  background: #28282b;
  box-shadow: 0 0 6px rgb(0,0,0,0.5);
}
.candidate_list ul li{
  padding: 20px 30px;
  border-bottom: 1px solid rgba(255,255,255,0.05);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media(max-width:991px){
  .joblist_Wpr{
    padding: 10px 20px;
  }
  .job_list > li{
    padding: 20px 0;
  }
}
@media(max-width:767px){
  .box-total .box .contentWpr h4{
    font-size: 18px;
    line-height: 25px;
    padding: 5px 10px 0 10px;
  }
}
@media(max-width:670px){
  .box-total .box{
    flex: 1 1 50%;
  }
}
@media(max-width:450px){
  .box-total .box{
    flex: 1 1 100%;
  }
}
.middle-part.dashboard .rigt-part .box h4 {
  font-size:18px;
}
</style>
