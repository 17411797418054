<template>
         <div class="item_slider_dash">
              <div class="stories-box">
               <div class="stories-img"><img src="@/assets/dash/episode-1.jpg" alt=""/></div>
               <div class="stories-text">
                 <h3>The Power Of Stories</h3>
                 <p>Season 1 <span>Episode 12</span></p>
                 <div class="play-btn">
                 <img src="@/assets/dash/video-play-icon.png" alt=""/> 32.37 </div>
                 
               </div>
              </div>
            </div>
</template>

<script>
export default {
    name: 'DashboardPodcastslidercard',
}
</script>

<style scoped>
.item_slider_dash h3 {font-size: 19px;font-weight:400;color:#fff;}
.item_slider_dash .stories-text {padding:10px;}
.item_slider_dash .stories-img{height: 124px;width: 144px;overflow: hidden;}
.item_slider_dash .stories-img img{width:100%;height:100%;object-fit:cover;}
.item_slider_dash .play-btn{ float:right; background:#1a1a1d; padding:6px; border-radius:5px; margin: 14px 0 0;font-size: 12px;line-height: 17px;}
.item_slider_dash .play-btn img{ float:left;}
.item_slider_dash .play-btn img {float: left;max-width: 100% !important;width:inherit !important; padding: 2px 4px 0;}
.item_slider_dash .stories-box{display: flex;background: #28282b;box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);}
.item_slider_dash .stories-text p span{ color:#c61e1e;}
.item_slider_dash.stories-box {display: flex;background: #28282b;box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);}
</style>