
<template>
<div class="dashboard_main">
    <Dashboardheader />
    <div class="middle-part dashboard">
        <Dashboardsidebar />
        <div class="rigt-part chat">
            <h3>Chat</h3>
            <div class="main-content-chat">
                <div class="container-fluid">
                    <div class="row" v-if="!nofound">
                        <!-- first part -->
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 right-sec">
                            <div class="chat_user scrollbar" id="style-3">
                                <div class="force-overflow">
                                    <!-- <div class="search">
                                        <input type="text" name="" placeholder="Search people" />
                                        <span><img src="@/assets/img-38.png" alt="" class="img-fluid" /></span>
                                    </div> -->
                                    <div class="user">
                                        <div class="nav">
                                            <CandidateChatCard2 :receiverId="receiverId" :callback="userBlock" :candidateData="candidate" v-for="candidate in allCandidateList.data" v-bind:key="candidate" icon="icon2.png" style="cursor:pointer;" v-on:click="selectCandidate(candidate.users.unique_user_id)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- second part -->
                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 left-sec">
                            <div class="message">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active">
                                        <div class="message_content" v-if="msgDiv" style="border: 1px solid #cccccc;border-radius:10px;">
                                            <!-- user details -->
                                            <div class="message_content_head">
                                                <div class="user_det">
                                                    <div class="picture">
                                                        <img v-if="receiverDetails.profile_image" v-bind:src="imagepath+receiverDetails.profile_image" v-bind:alt="receiverDetails.fname" />
                                                        <img :src="require(`@/assets/avatar.png`)" alt="" v-else>
                                                    </div>
                                                    <div class="details">
                                                        <h2>{{receiverDetails.fname}} 
                                                            <!-- {{receiverDetails.lname}} -->
                                                            000{{(receiverDetails.id==19)?"0":receiverDetails.id}}
                                                            </h2>
                                                        <div v-if="receiverDetails.login_history!=null" >
                                                            <div v-if="receiverDetails.login_history.login_status=='yes'" class="active1"></div>
                                                            <div v-else class="distrub1"></div>
                                                        </div>
                                                        <div v-else >
                                                            <div class="distrub1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="drop-down" v-if="userType==2">
                                                    <!-- <div class="selected" style="cursor: pointer;" >
                                                        <a v-if="blockStataus=='Block'" v-on:click="userBlock(receiverDetails.id,'unblock')" style="cursor:pointer;color:white;margin-top:10px;">Unblock</a>
                                                        <a v-if="blockStataus=='Unblock'" v-on:click="userBlock(receiverDetails.id,'block')" style="color:white;">Block</a>
                                                    </div> -->

                                                    <div class="selected" style="cursor: pointer;" v-on:click="membersModel = !membersModel">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </div>
                                                    <div v-show="membersModel" class="dropdown-content">
                                                        <a href="#" @click="membersDetailClick(receiverDetails.id,receiverDetails.group_name)">View Profile</a>
                                                        <a v-if="blockStataus=='Block'" v-on:click="userBlock(receiverDetails.id,'unblock')" style="cursor:pointer;color:white;margin-top:10px;">Unblock</a>
                                                        <a v-if="blockStataus=='Unblock'" v-on:click="userBlock(receiverDetails.id,'block')" style="color:white;">Block</a>
                                                    </div>

                                                </div>
                                                <div class="drop-down" v-else>
                                                    <div class="selected" @click="isShow = !isShow">
                                                        <a v-if="blockStataus=='Block'" style="cursor:pointer;color:white;margin-top:10px;">You Blocked</a>
                                                        <!-- <a v-if="blockStataus=='Unblock'" style="color:white;"></a> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- chat -->
                                            <div class="message_body" id="style-4" style="margin-top:10px;">
                                                <div class="message_block" v-for="chat in messages" v-bind:key="chat">
                                                    
                                                    <!-- chat left -->
                                                    <div class="sent" v-if="chat.user.id!==myId">
                                                        <div class="picture">
                                                            <img v-bind:src="imagepath+receiverDetails.profile_image" v-bind:alt="receiverDetails.fname" />
                                                        </div>

                                                        <div>
                                                            <div class="talk-bubble tri-right round btm-left-in">
                                                                <div class="talktext">
                                                                    <p>{{chat.message}}</p>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="time" style="float:left;"><p style="font-size:10px;"><i class="far fa-clock"></i> {{moment(chat.created_at).fromNow()}}</p></div>
                                                        </div>
                                                        
                                                    </div>
                                                     <!-- chat right -->
                                                    <div class="received" style="float: right;" v-if="chat.user.id==myId">
                                                        <div>
                                                        <div class="talk-bubble tri-right round btm-left-in">
                                                            <div class="talktext">
                                                                <p>{{chat.message}}</p>
                                                                
                                                            </div>
                                                        </div><br>
                                                        <div class="time" style="float:right;">
                                                            <p style="font-size:10px;">
                                                                <i class="far fa-clock"></i> {{moment(chat.created_at).fromNow()}}</p></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <form v-on:submit.prevent="sendMessage">
                                            <div class="message_textarea" v-if="blockStataus!='Block'">
                                                
                                                <div class="send_msg">
                                                    <textarea placeholder="Message" v-model="message" v-on:keyup.enter="sendMessage"/>
                                                </div>
                                                <div class="send_msg_btn">
                                                    <button type="submit" class="sign_submit" :disabled="isDisabled" style="border-radius: 50px;">
                                                        <span v-if="isDisabled==true" style="color:white;position: unset;"> <i class="fas fa-paper-plane"></i> </span>
                                                        <span v-else style="color:white;position: unset;"> <i class="far fa-paper-plane"></i> </span>
                                                    </button>
                                                </div>
                                               
                                            </div>
                                            </form>
                                        </div>
                                        <div class="message_content" v-else style="text-align:center;"> No Candidate Selected Yet !</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else> No Data Found 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modelPopups" v-if="addModal" style="display:block">
    <div class="content_wrapper">
        <div class="modal_header">
            <h2>Do you want to {{status}} {{userDetails.fname}} {{userDetails.lname}} ?</h2>
            <button @click="close"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="form_grp">
                <input type="hidden" v-model="userDetails.id" >
            </div>
            <div class="item_grp" style="text-align: center;">
                <button @click="close" type="submit" class="sign_submit">Cancel</button>
                <button @click="confirm(userDetails.id,status)" type="submit" class="sign_submit">Confirm</button>
            </div>
        </div>
        
    </div>
</div>
</template>

<script>
import Pusher from 'pusher-js';
import axios from 'axios'
// { BASE_URL } from '../config'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import CandidateChatCard2 from '../components/CandidateChatCard2.vue'
import { createLocal } from 'the-storages'
import moment from "moment";
//import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
const mirror = createLocal()
const storage = mirror._prx
export default {
    name: 'Chat',
    components:{
        Dashboardheader,
        Dashboardsidebar,
        CandidateChatCard2
    },
    data() {
        return {
           
            isDisabled:false,
            isShow: false,
            storageData: mirror,
            storage: storage,
            moment: moment,
            allCandidateList: {},
            totalCandidateCount:'',
            current: 1,
            error: '',
            error2:'',
            is_show:false,
			nofound:false,
            is_loader:true,
            jobDetails:{},
            addModal:false,
            imagepath:'',
            experience_new:'',
            area_interest:'',
            comm_interest:'',
            preferred_language:'',
            primary_skill:'',
            country:'',
            userDetails:this.userDetails,
            status:'',
            page:this.page,
            message:'',
            messages:this.messages,
            receiverDetails:this.receiverDetails,
            myId:this.myId,
            receiverId:'',
            blockStataus:'',
            userType:'',
            msgDiv:false,
            qParam:{
                receiverId:null,
                userId:null
            },
        }
    },
    mounted() {
        this.getResults();
    },
    async created(){
        
        this.userType=mirror.USERTYPE;
        this.imagepath = API_IMAGE_PATH+'appliedJobDocument/';
        //this.receiverId = ''; //'11122225';
        //console.log("ReceiverId::::",this.receiverId);
        //const route=useRoute();
        //this.selectCandidate(this.receiverId);

        const param    = {
            userId:mirror.AUTHID,
        }
        const response= await axios.post(API_BASE_URL + 'updateChatCount',param);
        console.log("CHAT UPDATE: ",response.data);
        this.userDetails = response.data;
    },
    methods: {
        selectBlockUnblock() {
            var x = document.getElementById("blockUnblock");
            if (x.innerHTML === "Hello") {
                x.innerHTML = "Swapped text!";
            } else {
                x.innerHTML = "Hello";
            }
        },
        getResults() {
            // this.page = page;
            // if (typeof page === "undefined") {
            //     page = 1;
            // }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `allJobsCandidateChatList`, inputData)
                .then(response => {
                    this.allCandidateList = response;
                    //this.totalCandidateCount = response.data.total;
                    //console.log("DATA3:",response.data);
                    //this.receiverId = this.allCandidateList.data[0].users.unique_user_id;
                    //this.is_loader=true;
                    //this.is_show=this.allCandidateList.last_page>1?true:false;
                    console.log("CANDIDATE LIST:",this.allCandidateList.data);
                    if(this.allCandidateList.data.length>0){
                        this.nofound=false;
                        this.is_loader=false;
                    }   else    {
                        this.nofound=true;
                        this.is_loader=false;
                    }
                    console.log("IS FOUND: ",this.nofound);
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
            //this.selectCandidate(this.receiverId);
            //this.scrollToEnd();
        },
        async userBlock(id,status) {
			//console.log(id)
            const param    = {
                userId:id
            }
            const response= await axios.post(API_BASE_URL + 'userDetailsById',param);
            this.userDetails = response.data;
            this.status = status;
            console.log("BLOCK DETAILS: ",response.data);
            this.addModal = true;
            
        },
        async confirm(receiverId,status){
            const param    = {
                userId:mirror.AUTHID,
                receiverId:receiverId,
                status:status
            }
            const response= await axios.post(API_BASE_URL + 'blockUnblockUserChat',param);
            this.userDetails = response.data;
            this.status = status;
            console.log("BLOCK: ",response.data);
            this.getResults();
            this.selectCandidate(this.receiverId);
            this.addModal = false;
            this.blockStataus = (response.data.blockStataus=='Blocked')? 'Block':'Unblock';
            console.log("USER BLOCK DETAILS: ",this.blockStataus);
            
        },
        close(){
            this.addModal = false
        },
        async sendMessage() {
            this.isDisabled=true;
            if (this.message ) {
                const param    = {
                    userId:mirror.AUTHID,
                    receiverId :this.receiverId,
                    message:this.message
                }
                //console.log("param",param);
                await axios.post(API_BASE_URL + 'messagess', param)
                .then(response => {                     	
                    //console.log("Send MEETING: ",response.data.chat);
                    const datas = response.data;
                    console.log(datas);
                    this.messages.push(datas.chat);
                    this.message = '';
                    this.isDisabled=false;
                    //setTimeout(this.scrollToEnd,100);
                })
                .catch(error => {
                    console.error(error);
                    this.isDisabled=false;
                });
            } else {
                this.errormessage = 'Message Fields are Mandatory';
                this.isDisabled=false;
            }
            this.scrollToEnd();
        },
        
        async fetchMessage(qParam) {
            await axios.post(API_BASE_URL + 'private-messagess',qParam)
            .then(response => {                     	
                //.log("MEETING List: ",response);
                this.messages = response.data.messageData;
                this.receiverDetails = response.data.receiverData;
                //this.messages = [];
            })
            .catch(error => {
                console.error(error);
            });
            this.scrollToEnd();
        },
        scrollToEnd(){
            var element = document.getElementById("style-4");
            element.scrollTop = element.scrollHeight;
        },
        async selectCandidate(candidateId){
            this.msgDiv=true,
            this.receiverId = candidateId;
            //console.log("RECEIVER ID: ",this.receiverId);
            this.myId = mirror.AUTHID;
            //this.receiverId = '11122225'; //route.params.receiverId;
            const qParam = {
                receiverId:this.receiverId,
                userId:this.myId
            }
            //console.log("AUTH:",mirror)
            this.fetchMessage(qParam);
            this.imagepath = API_IMAGE_PATH;
            
            Pusher.logToConsole = true;
            const pusher = new Pusher('8a7bddea661316a2226c', {
                cluster: 'ap2',
                authEndpoint: "http://executiveprotectionlifestyle.epspecialist.com/admin/api/pusher/auth"
            });
            
            const channel = pusher.subscribe('private-chat.'+this.myId);
            channel.bind('message', data => {
                //console.log("DATA-COMING: ",data.message);
                this.messages.push(data.message);
                //alert(JSON.stringify(data.message));
                //setTimeout(this.scrollToEnd,100);
            });
            console.log("AUTH M:",mirror);
            const paramBlock    = {
                userId:mirror.AUTHID,
                receiverId:this.receiverId,
                type:mirror.USERTYPE
            }
            
            console.log("Singel-Data: ",paramBlock);
            const responseBlock= await axios.post(API_BASE_URL + 'blockUnblockCheckingAuth',paramBlock);
            this.blockStataus = responseBlock.data.blockStataus;
            console.log("Block List : ",responseBlock.data);
            //this.scrollToEnd();
            //var element = document.getElementById("style-4");
            //element.scrollIntoView();
        },
        // async userBlock(id,status) {
		// 	//console.log(id)
        //     const param    = {
        //         userId:mirror.AUTHID,
        //         receiverId:id
        //     }
        //     //console.log("BLOCK DETAILS:",param);
        //     const response= await axios.post(API_BASE_URL + 'userDetailsById',param);
        //     this.userDetails = response.data;
        //     this.status = status;
        //     console.log("User Block Details: ",response.data);
        //     this.addModal = true;
            
        // },
        // async confirm(receiverId,status){
        //     const param    = {
        //         userId:mirror.AUTHID,
        //         receiverId:receiverId,
        //         status:status
        //     }
        //     const response= await axios.post(API_BASE_URL + 'blockUnblockUserChat',param);
        //     this.userDetails = response.data;
        //     this.status = status;
        //     console.log("Block Confirm Status: ",response.data);
        //     this.userType = mirror.USERTYPE;
        //     //this.blockStataus = response.data.blockStataus;
        //     this.getResults();
        //     this.selectCandidate(this.receiverId);
        //     this.addModal = false;
        // },
        // close(){
        //     this.addModal = false
        // }
	},
}
</script>

<style scoped>

.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
}
.option1 {
    background: #1a1a1a;
    text-decoration: none;
    padding: 5px;
    position: absolute;
    width: 150px;
}
.active1{
    margin-left: 10px;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}
.distrub1{
    margin-left: 10px;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #999999;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}
.middle-part.dashboard .rigt-part.chat {
    height: 100%;
    overflow-y: hidden;
}

.middle-part.dashboard .rigt-part p {
    padding-top: 0;
}

.middle-part.dashboard .rigt-part.chat h3 {
    margin-bottom: 20px;
}

.main-content-chat .container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.main-content-chat .container-fluid .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
}

.main-content-chat .container-fluid .row .right-sec {
    padding-right: 0;
    padding-left: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.main-content-chat .container-fluid .row .left-sec {
    padding-right: 0;
    padding-left: 2px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.main-content-chat .container-fluid .row .chat_user {
    background: #1a1a1a;
    border: solid 1px #242424;
    height: 480px;
    padding: 35px 36px 209px 20px;
    overflow-y: scroll;
}

.main-content-chat .container-fluid .row .chat_user .force-overflow {
    min-height: 0;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .chat_user#style-3::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.main-content-chat .container-fluid .row .chat_user .search {
    margin-bottom: 20px;
}

.main-content-chat .container-fluid .row .chat_user .search input[type=text] {
    position: relative;
    padding: 14px 50px;
    font-size: 15px;
    font-weight: 400;
    background-color: #222222;
    border: solid 1px #565657;
    color: #a3a3a3;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.main-content-chat .container-fluid .row .chat_user .search span {
    position: absolute;
    top: 50px;
    left: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
}

.main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details {
    width: 100%;
    height: 60px;
    border-radius: 50px;
    background: #202023;
    display: flex;
    align-items: center;
    padding: 4px 10px 4px 8px;
    margin-bottom: 15px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details:last-child {
    margin-bottom: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture img {
    width: 53px;
    height: 53px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .active {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .away {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #f7ca2f;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .picture .distrub {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    background: #f72f2f;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details {
    width: 60%;
    height: 50px;
    border-right: 1px solid #cbcbcb;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details h2 {
    font-size: 15px;
    font-weight: 600;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details h3 {
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time {
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time .count {
    margin: 0 0 0 10px;
}

.main-content-chat .container-fluid .row .chat_user .user .user_details .details_time .count h2 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    background: #161618;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details {
    background: #e6e6e6;
    border: solid 1px #333333;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details .details_time .count h2 {
    border: 2px solid #fff;
}

.main-content-chat .container-fluid .row .chat_user .user .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: transparent;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details h2 {
    color: #1a1a1a;
}

.main-content-chat .container-fluid .row .chat_user .user .active .user_details h3 {
    color: #1a1a1a;
}

.main-content-chat .container-fluid .row .message .tab-content>.active {
    display: block;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc; */
    border-bottom: 1px solid #cccccc;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .user_det {
    width: 100%;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected a {
    background: #222 no-repeat scroll right center;
    display: block;
    padding: 6px;
    border: 1px solid #e6e6e6;
    width: 100px;
    border-radius: 2px;
    text-decoration: none;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected a span {
    cursor: pointer;
    display: block;
    padding: 5px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .option {
    position: relative;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul {
    background: #fff none repeat scroll 0 0;
    display: none;
    list-style: none;
    padding: 0px 0px;
    position: absolute;
    left: 0px;
    top: 32px;
    width: auto;
    min-width: 170px;
    border: 1px solid #d7d7d7;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .selected span.value,
.drop-down .options span.value {
    display: none;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul li a {
    padding: 5px;
    display: block;
    text-decoration: none;
    color: #d9d9d9;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .drop-down .options ul li a:hover {
    background: #1a1a1a;
    color: #fff;
    transition: 0.2s ease;
}

.main-content-chat .container-fluid .row .message .message_content {
    height: 480px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .picture {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .picture img {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .details {
    position: relative;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
    font-size: 19px;
    font-weight: 600;
    line-height: 2;
    color: #e6e6e6;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
    position: absolute;
    top: 16px;
    left: 25%;
    bottom: 0;
    width: 15px;
    height: 15px;
    background: #42d409;
    border: 2px solid #f0f7f8;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body {
    padding: 0 10px 0 10px;
    height: 325px;
    overflow-y: scroll;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.main-content-chat .container-fluid .row .message .message_content .message_body#style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .time {
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    color: #000;
    text-align: right;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .time p {
    padding-top: 0;
    color: #e6e6e6;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .picture {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #eaeaea;
    margin-right: 14px;
    float: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .picture img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talk-bubble {
    margin: 0;
    /* display: inline-block;
    position: relative;
    width: 100%; */
    display:table-cell;
    height: auto;
    background-color: #161618;
    border: 1px solid transparent;
    border-radius: 10px;
    border-top-left-radius: 0px !important;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
    padding: 6px 8px 6px 8px;
    text-align: left;
    line-height: 1.5em;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #e9e9e9;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .sent .tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #161618 transparent transparent #161618;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .message_block:nth-child(3) .time {
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    color: #000;
    text-align: left;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received {
    display: flex;
    align-items: center;
    margin: 0 0 40px 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talk-bubble {
    margin: 0;
    /* display: inline-block;
    position: relative;
    width: 100%; */
    float: right;
    display:table-cell;
    height: auto;
    background-color: #e6e6e6;
    border: 1px solid transparent;
    border-radius: 10px;
    border-top-right-radius: 0px !important;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
    padding: 6px 8px 6px 8px;
    text-align: left;
    line-height: 1.5em;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: #433630;
    margin: 0;
}

.main-content-chat .container-fluid .row .message .message_content .message_body .received .tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 38px;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #e2e8f3 #e2e8f3 transparent transparent;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea {
    position: relative;
    padding: 0 4px 0 4px;
    display: flex;
    align-items: center;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea textarea {
    position: relative;
    padding: 16px 20px;
    height: 50px;
    font-size: 17px;
    font-weight: 400;
    background-color: #ebebeb;
    border: 1px solid #afb8b9;
    color: #161618;
    display: block;
    width: 100%;
    border: none;
    appearance: none;
    resize: none;
    border-radius: 10px;
    border-top-right-radius: 0px;
    /* border-bottom-left-radius: 10px; */
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg {
    width: 90%;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn {
    width: 10%;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn .sign_submit {
    width: 100%;
    height: 50px;
    background: #161618;
    border: 0;
    font-size: 14px;
    line-height: 1;
    color: #e6e6e6;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
    position: relative;
    top: 7px;
}

.main-content-chat .container-fluid .row .message .message_content .message_textarea span {
    position: absolute;
    top: 12px;
    right: 0;
}

@media only screen and (max-width:1366px) {
    .main-content-chat .container-fluid .row .chat_user {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 58%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        left: 50%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0 4px 0 4px;
    }

}

@media(max-width:1199px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: 570px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .left-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 394px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: 550px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 23%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

}

@media(max-width:991px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: auto;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .left-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 394px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: auto;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 23%;
    }

    .middle-part.dashboard .rigt-part p {
        padding-top: 0;
    }

}

@media(max-width:767px) {
    .middle-part.dashboard .rigt-part.chat {
        height: 100%;
        overflow-y: scroll;
    }

    .main-content-chat .container-fluid .row .right-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .chat_user {
        height: 570px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .main-content-chat .container-fluid .row .chat_user .user .nav .nav-link {
        padding: 0.5rem 0;
    }

    .main-content-chat .container-fluid .row .chat_user .user .user_details .details {
        display: block;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }
    .main-content-chat .container-fluid .row .chat_user .user .user_details .details_time h2{ line-height:16px;}

    .main-content-chat .container-fluid .row .left-sec {
        flex: 100%;
        max-width: 100%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head {
        flex-wrap: wrap;
        justify-content: end;
        padding: 10px;
        border-right: 1px solid transparent;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .details h2 {
        font-size: 20px;
        line-height: 54px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext {
        padding: 10px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received .talktext p {
        font-size: 13px;
        line-height: 1.5;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .sent {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body .received {
        margin: 0 0 10px 0;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_body {
        padding: 10px 20px 0 20px;
        height: 484px;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea {
        padding: 0px 6px 6px 6px;
    }

    .main-content-chat .container-fluid .row .message .message_content {
        height: auto;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_content_head .active {
        position: absolute;
        top: 20px;
        left: 60%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg {
        width: 70%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn {
        width: 30%;
    }

    .main-content-chat .container-fluid .row .message .message_content .message_textarea .send_msg_btn .sign_submit {
        font-size: 13px;
    }

}
</style>