<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part">
    <h3>Candidate Chat List</h3>
    <!-- <div class="gt-started-frm" v-if="totalCandidateCount >0"> -->
    <div >
        <!--<button v-on:click="filter" type="button" class="filter_btn"><i class="fas fa-align-left"></i></button>-->
        <div class="candidate_list">
            <ul>
            <CandidateChatCard :callback="userBlock" :candidateData="candidate" v-for="candidate in allCandidateList.data" v-bind:key="candidate" icon="icon2.png" />
            </ul>
        </div>
        <v-pagination
            v-if="is_show" 
			v-model="page"
			:pages="allCandidateList.last_page"
			:range-size="1"
			active-color="#DCEDFF"
			@update:modelValue="getResults"
			/>
    </div>
    <div v-if="nofound">
        <p>Sorry! no data found.</p>
    </div>
</div>
</div>
</div>
<div class="modelPopups" v-if="addModal" style="display:block">
    <div class="content_wrapper">
        <div class="modal_header">
            <h2>Do you want to {{status}} {{userDetails.fname}} {{userDetails.lname}} ?</h2>
            <button @click="close"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="form_grp">
                <input type="hidden" v-model="userDetails.id" >
            </div>
            <div class="item_grp" style="text-align: center;">
                <button @click="close" type="submit" class="sign_submit">Cancel</button>
                <button @click="confirm(userDetails.id,status)" type="submit" class="sign_submit">Confirm</button>
            </div>
        </div>
        
    </div>
</div>
</template>

<script>
import axios from 'axios'
// { BASE_URL } from '../config'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import CandidateChatCard from '../components/CandidateChatCard.vue'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import { createLocal } from 'the-storages'
import moment from "moment";
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'ChatList',
    components:{
        Dashboardheader,
        Dashboardsidebar,
        CandidateChatCard,
        VPagination
    },
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            allCandidateList: {},
            totalCandidateCount:this.totalCandidateCount,
            current: 1,
            error: '',
            error2:'',
            is_show:false,
			nofound:false,
            is_loader:true,
            jobDetails:{},
            addModal:false,
            imagepath:'',
            experience_new:'',
            area_interest:'',
            comm_interest:'',
            preferred_language:'',
            primary_skill:'',
            country:'',
            userDetails:this.userDetails,
            status:'',
            page:this.page
        }
    },
    mounted() {
        this.getResults();
    },
    created(){
        this.imagepath = API_IMAGE_PATH+'appliedJobDocument/';
    },
    methods:{
        getResults(page) {
            this.page = page;
            if (typeof page === "undefined") {
                page = 1;
            }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `allJobsCandidateChatList?page=${page}`, inputData)
                .then(response => {
                    this.allCandidateList = response.data;
                    this.totalCandidateCount = response.data.total;
                    console.log("DATA3:",response.data);
                    //console.log("Fresh Data:",this.totalCandidateCount);
                    this.is_loader=true;
                    this.is_show=this.allCandidateList.last_page>1?true:false;

                    if(this.allCandidateList.data?.length>0){
                        this.nofound=false;
                        this.is_loader=false;
                    }   else    {
                        this.nofound=true;
                        this.is_loader=false;
                    }
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        },
        async userBlock(id,status) {
			//console.log(id)
            const param    = {
                userId:mirror.AUTHID,
                receiverId:id
            }
            const response= await axios.post(API_BASE_URL + 'userDetailsById',param);
            this.userDetails = response.data;
            this.status = status;
            console.log("UserDetails: ",response.data);
            this.addModal = true;
        },
        async confirm(receiverId,status){
            const param    = {
                userId:mirror.AUTHID,
                receiverId:receiverId,
                status:status
            }
            const response= await axios.post(API_BASE_URL + 'blockUnblockUserChat',param);
            this.userDetails = response.data;
            this.status = status;
            console.log("BlockDetails: ",response.data);
            this.getResults(this.page);
            this.addModal = false;
        },
        close(){
            this.addModal = false
        }
	},
	// beforeRouteEnter(to, from, next) {
	// 	if (storage.get('TOKEN')) {
	// 		if(storage.USERTYPE==2)
	// 			next();
	// 		else if(storage.USERTYPE==3)
	// 			next('/jobs');
	// 		else
	// 			next('/');	
	// 		}
	// 		else{
	// 			next('/login');
	// 		}
	// 	}
}

</script>

<style scoped>
.date {
    font-size: 10px;
    color: red;
}
.modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
}
.candidate_list ul{
    margin-top: 20px;
    background: #28282b;
    
}
.candidate_list ul li{
    padding: 20px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
<style scoped>
.icon img{
    width: 100px;
    height: 85px;
    object-fit: cover;
}
.job_info{
    flex: 1 1 290px;
    padding-left: 30px;
}
.job_date{
    flex: 1 1 150px;
}
.job_info h3{
    font-size: 24px;
    line-height: 30px;
    color: #d9d9d9;
}
.job_info .job_title{
    font-size: 16px;
    line-height: 22px;
    color: #d9d9d9;
    margin-bottom:5px;
}
.job_info i{
    color: #c71e1e;
    margin-right: 7px;
    font-size: 16px;
}
.job_info .candidate_info{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}
.job_info .candidate_info li{
    padding: 5px 10px;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
}
.job_date{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.job_date .date{
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-top:10px;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #c71e1e;
}
.job_date .action_btnWpr{
    display:flex;
    flex-direction: row-reverse;
    padding-top: 4px;
}
.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
}

@media(max-width:1199px){
    .job_info{
        padding-left:20px;
    }
    .job_info h3{
        font-size: 20px;
        line-height: 26px;
    }
}
@media(max-width:670px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .candidate_info{
        justify-content: center;
    }
    .job_date {
        align-items: center;
    }
    .icon{
        flex: 0 0 100%;
        text-align: center;
    }
}

</style>