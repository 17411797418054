<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part">
    <h3>Shortlisted Candidate List</h3>
    <!-- <div class="gt-started-frm" v-if="totalCandidateCount >0"> -->
    <div >
        <!--<button v-on:click="filter" type="button" class="filter_btn"><i class="fas fa-align-left"></i></button>-->
        <div class="candidate_list">
            <ul>
            <CandidateShortlistCardMain :callback="appliedJobsDetails" :candidateData="candidate" v-for="candidate in allCandidateList.data" v-bind:key="candidate" icon="icon2.png" />
                
            </ul>
        </div>
        <v-pagination
            v-if="is_show" 
			v-model="page"
			:pages="allCandidateList.last_page"
			:range-size="1"
			active-color="#DCEDFF"
			@update:modelValue="getResults"
			/>
        <!-- <ul class="bredcrumbs">
            <li class="active">1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li><i class="fas fa-ellipsis-h"></i></li>
        </ul> -->

    </div>
    <div v-if="nofound">
        <p>Sorry! no data found.</p>
    </div>
    <!-- <div class="gt-started-frm" v-else>
        <p>Sorry! no data found.</p>
    </div> -->
</div>
</div>
</div>
<div class="modelPopups" v-if="addModal" style="display:block">
    <div class="content_wrapper">
        <div class="modal_header">
            <h2>View Application of {{MAIN_NAME}} <span>000{{(jobDetails.user_id==19)?"0":jobDetails.user_id}} </span></h2>
            <button @click="close"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="form_grp">
                <input type="hidden" v-model="jobId" >
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>First Name: <span>{{jobDetails.fname}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <!-- <label>Last Name: <span>{{jobDetails.lname}}</span></label> -->
                        <label>Last Name: <span>000{{(jobDetails.user_id==19)?"0":jobDetails.user_id}} </span></label>
                    </div>
                </div> 
                <div class="item_grp"><label>Primary Email: <span>{{jobDetails.email}}</span></label> 
                </div>
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Experience Level: <span>{{experience_new}}</span></label>
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Area(s) of Interest: <span>{{area_interest}}</span></label> 
                    </div>
                </div> 
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Communities of Interest: <span>{{comm_interest}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Preferred Language: <span>{{preferred_language}}</span></label> 
                    </div>
                </div>

                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>Primary Skill Set: <span>{{primary_skill}}</span></label> 
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Primary Country: <span>{{country}}</span></label> 
                    </div>
                </div>
                <div class="item_grp">
                    <label>Resume: 
                        <span> {{jobDetails.resume}}
                        <!-- <a :href="url" @click.prevent="downloadItem({url:imagepath+jobDetails.resume,label: jobDetails.resume})"> -->
                        <a v-if="membershipType=='Premium' && jobDetails.resume!==null" :href="url" target="_blank" style="color:red;cursor: pointer;background: white;padding: 5px;border-radius: 50px;margin-left: 10px;">
                            Click To Download <i class="fas fa-download"></i> 
                        </a>
                        </span>
                    </label>
                </div>  
            </div>
        </div>
    </div>
</div>
</template>

<script>

import axios from 'axios'
// { BASE_URL } from '../config'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import CandidateShortlistCardMain from '../components/CandidateShortlistCardMain.vue'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import { createLocal } from 'the-storages'
import moment from "moment";
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'DashboardGetstarted',
    components:{
        Dashboardheader,
        Dashboardsidebar,
        CandidateShortlistCardMain,
        VPagination
    },
    data() {
        return {
            storageData: mirror,
            storage: storage,
            moment: moment,
            allCandidateList: {},
            totalCandidateCount:this.totalCandidateCount,
            current: 1,
            error: '',
            error2:'',
            is_show:false,
			nofound:false,
            is_loader:true,
            jobDetails:{},
            addModal:false,
            imagepath:'',
            experience_new:'',
            area_interest:'',
            comm_interest:'',
            preferred_language:'',
            primary_skill:'',
            country:'',
            membershipType:'',
            MAIN_NAME:'',
        }
    },
    mounted() {
        this.getResults();
    },
    created(){
        this.membershipType = mirror.MEMBERSHIP_TYPE
        this.imagepath = API_IMAGE_PATH+'appliedJobDocument/';
    },
    methods:{
        getResults(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID
                };
                axios.post(API_BASE_URL + `allJobsCandidateList?page=${page}`, inputData)
                .then(response => {
                    this.allCandidateList = response.data;
                    this.totalCandidateCount = response.data.total;
                    console.log("DATA3:",response.data);

                    this.is_loader=true;
                    this.is_show=this.allCandidateList.last_page>1?true:false;

                    if(this.allCandidateList.data?.length>0){
                        this.nofound=false;
                        this.is_loader=false;
                    }   else    {
                        this.nofound=true;
                        this.is_loader=false;
                    }
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        },
        async appliedJobsDetails(id,shortlist,name) {
			//console.log(id)
			//console.log("AAAAAAAAAAAAAAAAA",name)
            const param    = {
                userId:mirror.AUTHID,
                jobId:id
            }
            const response2= await axios.post(API_BASE_URL + 'appliedJobDetails',param);
            this.jobDetails = response2.data;
            this.MAIN_NAME = name;
            this.experience_new = response2.data.experience_new==null ? '':response2.data.experience_new.experience;
            this.area_interest = response2.data.area_interest==null ? '':response2.data.area_interest.name;
            this.comm_interest = response2.data.comm_interest==null ? '':response2.data.comm_interest.name;
            this.preferred_language = response2.data.preferred_language==null ? '':response2.data.preferred_language.name;
            this.primary_skill = response2.data.primary_skill==null ? '':response2.data.primary_skill.name;
            this.country = response2.data.country==null ? '':response2.data.country.name;
            this.url = response2.data.resume ? this.imagepath+response2.data.resume : '';
            console.log("URL:",this.url);
            console.log("RESULT",response2.data);
            this.addModal = true;
        },
        close(){
            this.addModal = false
        },
	},
	beforeRouteEnter(to, from, next) {
		if (storage.get('TOKEN')) {
			if(storage.USERTYPE==2)
				next();
			else if(storage.USERTYPE==3)
				next('/jobs');
			else
				next('/');	
			}
			else{
				next('/login');
			}
		}
}

</script>

<style scoped>
.modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
}
.candidate_list ul{
    margin-top: 20px;
    background: #28282b;
    box-shadow: 0 0 6px rgb(0,0,0,0.5);
}
.candidate_list ul li{
    padding: 20px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>