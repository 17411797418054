<template>
  <swiper :slidesPerView="'auto'" :spaceBetween="0" :pagination='{"clickable": true}' class="dashboard_podcastslider" :navigation="true" >
    <swiper-slide><DashboardPodcastslidercard/></swiper-slide>
    <swiper-slide><DashboardPodcastslidercard/></swiper-slide>
    <swiper-slide><DashboardPodcastslidercard/></swiper-slide>
    <swiper-slide><DashboardPodcastslidercard/></swiper-slide>
    <swiper-slide><DashboardPodcastslidercard/></swiper-slide>
    <swiper-slide><DashboardPodcastslidercard/></swiper-slide>
  </swiper>
</template>


<script >
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import DashboardPodcastslidercard from './DashboardPodcastslidercard.vue'






SwiperCore.use([Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
    DashboardPodcastslidercard
  }  
}
</script>
<style scoped>
    .dashboard_podcastslider .swiper-slide{
        width: 400px;
        margin: 0 15px 0 0;
    }
   
    @media(max-width:575px){
        .dashboard_podcastslider .swiper-slide{
            width: 290px;
            margin: 0 10px 0 0;
        }
    }
</style>