<template>
<div class="dashboard_main">
<Dashboardheader/>
<div class="middle-part dashboard">
<Dashboardsidebar/>
<div class="rigt-part">
    <h3>Live Events List</h3>
    <div >
        <div class="candidate_list">
            <input type="hidden" name="groupArray" v-model="groupArray">
            <ul>
                <li v-for="meeting in allMeetingList.data" v-bind:key="meeting" >
                    <div class="icon">
                        <!-- <img v-bind:src="imagepath+meeting.profile_image" v-bind:alt="meeting.job_title" v-if="meeting.profile_image">	 -->
                        <img :src="require(`@/assets/zoom.png`)" alt="" >	
                        <!-- <img :src="require(`@/assets/${icon}`)" alt=""> -->
                    </div>
                        <div class="job_info">
                            <h3>{{meeting.topic}}</h3>
                            <div class="job_title" v-if="meeting.type==1"><span style="color:rgb(205, 165, 58);">Instant Meeting</span></div>
                            <div class="job_title" v-else><span style="color:rgb(205, 165, 58);">Scheduled Meeting</span></div>
                            <!-- <div class="job_title"><span>Amit Prasad</span></div>
                            <ul class="candidate_info">
                                <li><i class="fas fa-envelope"></i>{{meeting.topic}} </li>           
                                <li><i class="fas fa-phone"></i>{{meeting.topic}}</li>
                            </ul> -->
                        </div>
                    <div class="job_date">
                    <!--<div class="date">Job: <span>{{meeting.job_title}}</span></div>-->
                        <div class="date">
                            <span style="color:rgb(205, 165, 58);">Start Time:</span> {{meeting.start_time}}
                        </div>
                        <div class="action_btnWpr">
                            <button class="sign_submit" type="submit" v-on:click="execute(meeting.id,meeting.topic,meeting.join_url,JSON.parse(meeting.json_data).id)">Join Zoom Meeting Link</button>
                            <!-- <button type="button" class="sign_submit"><router-link :to="'/profile/' + meeting.unique_user_id" style="color:white;cursor:pointer">View Profile</router-link></button> -->
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <v-pagination
            v-if="is_show" 
			v-model="page"
			:pages="allMeetingList.last_page"
			:range-size="1"
			active-color="#DCEDFF"
			@update:modelValue="getResults"
			/>
    </div>
    <div v-if="nofound">
        <p>Sorry! no data found.</p>
    </div>
</div>
</div>
</div>
<div class="modelPopups" v-if="addModal" style="display:block">
    <div class="content_wrapper" style="width:100% !important;height:100% !important;">
        <div class="modal_header">
            <h2>Join Live Events - {{topic}}</h2>
            <button @click="close" class="sign_submit"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal_body" v-if="showZoomForm" style="height:90%;">
            <form id="jobpost-form" v-on:submit.prevent="applyForZoomMeeting" >
            <div class="form_grp">
                <input type="hidden" v-model="meetingId" >
                <input type="hidden" v-model="link" >
                
                <div class="item_grp">
                    <div style="width:48%;float:left;">
                        <label>First Name: </label> 
                        <input type="text" v-model="state.fname" placeholder="Enter First Name" name="fname">
                        <span v-if="v$.fname.$error">
                            {{ v$.fname.$errors[0].$message }}
                        </span>
                    </div>
                    <div style="width:48%;float:right;">
                        <label>Last Name: </label>
                        <input type="text" v-model="state.lname" placeholder="Enter Last Name" name="lname">
                        <span v-if="v$.lname.$error">
                            {{ v$.lname.$errors[0].$message }}
                        </span>
                    </div>
                </div> 
                
                <div class="item_grp">
                    <div >
                        <label>Email: </label>
                        <input type="text" v-model="state.email" placeholder="Enter Email" name="email">
                        <span v-if="v$.email.$error">
                            {{ v$.email.$errors[0].$message }}
                        </span>
                    </div>
                </div> 
                <div class="item_grp">
                    <button type="submit" class="sign_submit">Join Live Events Now</button>
                </div>
            </div>
            </form>
        </div>
        <div class="modal_body" v-else style="height:80%;">
            <iframe name="iframe1" :src="`https://zoom.us/wc/${mId}/join?prefer=1&un=${meetingId}`" sandbox="allow-forms allow-scripts" allow="microphone; camera; fullscreen" style="width:100%;height:100%;"></iframe>
            <!-- <iframe name="iframe1" :src="link" style="width:100%;height:100%;" ></iframe> -->
            <input type="hidden" v-model="link" class="sign_submit">
            
            <div class="item_grp">
                <a class="sign_submit" :href="`https://zoom.us/wc/${mId}/join?prefer=1&un=${meetingId}`" target="iframe1">Join Now</a>
                <!-- <a class="sign_submit" :href="link" target="iframe1">Join Now</a> -->
                <button type="submit" class="sign_submit" @click="close">Cancel</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import axios from 'axios'
// { BASE_URL } from '../config'
import { BASE_URL } from '../config'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import Dashboardheader from '../components/Dashboardheader.vue'
import Dashboardsidebar from '../components/Dashboardsidebar.vue'
import { createLocal } from 'the-storages'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required , email} from "@vuelidate/validators"
import moment from "moment";
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'ZoomMeetings',
    components:{
        Dashboardheader,
        Dashboardsidebar,
        VPagination
    },
    setup() {
        const state = reactive({
            fname:'',
            lname:'',
            email:''
        })
        const rules = computed(() => {
            return {
                fname:{required},
                lname:{required},
                email:{required , email}
            }
        })
        const v$ = useValidate(rules, state)
        return {
            state,
            v$
        }
    },
    data() {
        return {
            groupArray:[],
            storageData: mirror,
            storage: storage,
            moment: moment,
            allMeetingList: {},
            totalMeetingCount:this.totalMeetingCount,
            topic:this.topic,
            meetingId:this.meetingId,
            link:this.link,
            showZoomForm:true,
            current: 1,
            error: '',
            error2:'',
            is_show:false,
			nofound:false,
            is_loader:true,
            addModal:false,
            imagepath:'',
            fname:'',
            lname:'',
            email:''
        }
    },
    mounted() {
        this.getResults();
    },
    async created(){
        this.imagepath = API_IMAGE_PATH+'appliedJobDocument/';
        const groupDetails = await axios.post(API_BASE_URL + 'groupDetails', {userId: mirror.AUTHID});
        this.groupArray = groupDetails.data;
        console.log("Group::",groupDetails.data);
    },
    methods:{
        getResults(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            axios.get(BASE_URL + 'sanctum/csrf-cookie').then(response => {
                const inputData = {
                    userId: mirror.AUTHID,
                    groupArray:(this.groupArray)?this.groupArray:""
                };
                axios.post(API_BASE_URL + `allMeetingList?page=${page}`, inputData)
                .then(response => {
                    this.allMeetingList = response.data;
                    this.totalMeetingCount = response.data.total;
                    console.log("DATA3:",response.data);

                    this.is_loader=true;
                    this.is_show=this.allMeetingList.last_page>1?true:false;

                    if(this.allMeetingList.data?.length>0){
                        this.nofound=false;
                        this.is_loader=false;
                    }   else    {
                        this.nofound=true;
                        this.is_loader=false;
                    }
                })
                .catch(({ response }) => {
                    this.error=response
                })
                this.error2=response
            });
        },
        async execute(id,topic,link,mId) {
            //console.log(link)
            this.meetingId = id;
            this.mId = mId;
            this.topic = topic;
            this.link = link;
            //console.log("AUTH",mirror);
            // if(mirror!=''){
            //     this.state.fname = (mirror.TOKEN.name)?mirror.TOKEN.name:"";
            //     this.state.lname = (mirror.NAME)?mirror.NAME:"";
            //     this.state.email = (mirror.USEREMAIL)?mirror.USEREMAIL:"";
            // } else {
            //     this.state.fname = '';
            //     this.state.lname = '';
            //     this.state.email = '';
            // }
            this.showZoomForm = true;
            this.addModal = true;
        },
        async applyForZoomMeeting() {
            this.v$.$validate(this.v$.$error);
            if (!this.v$.$error) {
                if (this.state.fname && this.state.email && this.state.lname ) {
                    const param    = {
                        userId:(mirror.AUTHID)? mirror.AUTHID:"",
                        userName:this.state.fname+' '+this.state.lname,
                        email:this.state.email,
                        meetingId:this.meetingId,
                        link:this.link
                    }
                    console.log("param",param);
                    await axios.post(API_BASE_URL + 'joinZoomMeeting', param)
                    
                    .then(response => { 
                        this.showZoomForm = false;  
                        //this.addModal = false;                     	
                        console.log("MEETING: ",response)                        	
                        this.state.fname = '';
                        this.state.lname = '';
                        this.state.email = '';
                    })
                    .catch(error => {
                        console.error(error);
                    });
                } else {
                    this.errormessage = 'All Fields are Mandatory';
                }
            } else {
                this.errormessage = 'All Fields are Mandatory';
            }
            //this.addModal = false;
        },
        close(){
            this.addModal = false
        },
	},
	// beforeRouteEnter(to, from, next) {
	// 	if (storage.get('TOKEN')) {
	// 		if(storage.USERTYPE==2)
	// 			next('/zoom-meeting');
	// 		else if(storage.USERTYPE==3)
	// 			next('/zoom-meeting');
	// 		else
	// 			next('/zoom-meeting');	
	// 		}
	// 		else{
	// 			next('/login');
	// 		}
	// 	}
}

</script>

<style scoped>
.modal_body .form_grp .item_grp select {
    margin-top: 3px !important;
    border: 1px solid #3d3d3d;
    background: #212122;
    padding: 10px 15px !important;
    width: 100%;
    color: #d9d9d9;
}
.modelPopups {    
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.9);
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    padding: 10px;
}
.candidate_list ul{
    margin-top: 20px;
    background: #28282b;
    
}
.candidate_list ul li{
    padding: 20px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
<style scoped>
.icon img{
    width: 100px;
    height: 85px;
    object-fit: contain;
}
.job_info{
    flex: 1 1 290px;
    padding-left: 30px;
}
.job_date{
    flex: 1 1 150px;
}
.job_info h3{
    font-size: 24px;
    line-height: 30px;
    color: #d9d9d9;
}
.job_info .job_title{
    font-size: 16px;
    line-height: 22px;
    color: #d9d9d9;
    margin-bottom:5px;
}
.job_info i{
    color: #c71e1e;
    margin-right: 7px;
    font-size: 16px;
}
.job_info .candidate_info{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}
.job_info .candidate_info li{
    padding: 5px 10px;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
}
.job_date{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.job_date .date{
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    font-weight: 700;
    margin-top:10px;
    margin-bottom: 5px;
}
.job_date .date span{
    color: #c71e1e;
}
.job_date .action_btnWpr{
    display:flex;
    flex-direction: row-reverse;
    padding-top: 4px;
}
.sign_submit{
    background: #c71e1e;
    border: 0;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 8px;
    margin: 2px;
}

@media(max-width:1199px){
    .job_info{
        padding-left:20px;
    }
    .job_info h3{
        font-size: 20px;
        line-height: 26px;
    }
}
@media(max-width:670px){
    .job_info{
        flex: 0 0 100%;
        padding: 10px 0;
        text-align: center;
    }
    .job_info .candidate_info{
        justify-content: center;
    }
    .job_date {
        align-items: center;
    }
    .icon{
        flex: 0 0 100%;
        text-align: center;
    }
}

</style>