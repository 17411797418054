<template>
 <Navigation/>
      <Banner class="blog_banner" bg="blog-banner.jpg">
        <div class="inr_text blog">
			<img src="@/assets/blog-heading.png">	
            <h3 style=" background: -webkit-linear-gradient(#ffba00, #50472e);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">{{details.title}}</h3>
        </div>
     </Banner>
    
	<section class="main_podcast blog_details">
	<div class="container">
	<div class="wrapper">
		<div class="left-part">
		
			<div class="blog-img" v-if="details.large_image!='' && details.large_image!=null"><img v-bind:src="imagepath+details.large_image" v-bind:alt="details.title"/>
			</div>
			<div class="blog-img" v-else><img src="@/assets/blog-details.jpg" alt="" /></div>
			
			
			<div class="details-box" style="width: 100%;">
				<div class="blog_content_top">
					<div class="heading-left">
						<h3>{{details.title}}</h3>
						<h6> <span class="red-text">{{ moment(details.created_at).startOf("month").format('MMMM')}}</span> {{ moment(details.created_at).format("DD, YYYY") }}</h6> 
					</div>
					<div class="heading-right">
						<h5><router-link :to="'/blog/category/' + slugname">{{catname}}</router-link> .  <span class="red-text"> {{authorname}}</span></h5>
					</div>
				</div>
				<div class="content" v-html="details.content">
				</div>
				<!--<div class="bottom-gap-top blog_content_top">
					<div class="left-part">
						<h5> <span class="red-text"> Protector By </span> Nature And by trade</h5>
						<h3>Byron</h3> 
						</div>
					<div class="right-prt">
						<ul>
							<li>Muzzle Flash Media </li>
							<li> N-ear </li>
							<li>Gray man & company</li>
							<li>Primary Weapons Systems</li>
						</ul>
					</div>
				</div>-->
			</div>
		</div>
		<div class="right-part-sec">
		<Contactform/>
			
		</div>
	</div>
	</div>
	</section>
	<Buttomads/>
	<Footer/>
</template>


<script>
import Banner from '../components/Banner.vue'
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { API_IMAGE_PATH } from '../config'
import moment from "moment";
import { useRoute } from 'vue-router'
import Buttomads from '../components/Buttomads.vue'
import Contactform from '../components/Contactform.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Blogdetails',
    components:{
        Banner,
        Buttomads,
        Contactform,
        Navigation,
		Footer
    },
    data() {
        return {
            isLoading: true,
            imagepath:{},
            details: {},
            slugname:'',
            catname:'',
            authorname:'',
            moment: moment,
        }
    },
    mounted() {
            if (!document.getElementById('facebook-jssdk')) {
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0&appId=347004376636576&autoLogAppEvents=1";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            }
        },
        
    async created () {
	const route=useRoute();
	const response = await axios.get(API_BASE_URL + 'blog/'+route.params.slug);
	this.imagepath=API_IMAGE_PATH
	this.details = response.data;
	
	this.slugname=response.data.category.slug,
	this.catname=response.data.category.name,
	this.authorname=response.data.user.fname+' '+response.data.user.lname,
	this.isLoading = false,
	
	console.warn(response.data);
	}
}
</script>


<style scoped>
.inr_text .blog {
	font-size: 35px;
	max-width: 100% ;
	line-height: 35px;
}
.blog_details{
	margin:60px 0;
	float:left;
	width:100%;
}
.blog_details .left-part{ 
	float:left; 
	width:72%;
}
.blog_details .right-part-sec { 
	width:26%; 
	float:right;
}
.blog_details .blog-img{
	line-height: 0;
}
.blog_details .heading-left{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.blog_details .blog-img img {
width:100%
}

.blog_details .details-box {
background:#1a1a1a;
padding:25px 40px;
border:solid 1px #242424;
float:left;
border-top: 2px solid #b91e1e;
}

.blog_details .details-box h3 {
font-size:42px;
line-height:50px;
color:#fff;
flex: 1 1 auto;

}

.blog_details .details-box .heading-left h6 {
flex: 0 0 135px;
font-size: 15px;
line-height: 20px;
}

.blog_details .details-box .heading-right {
width:100%;
float:left;
margin:10px 0
}

.blog_details .details-box .heading-right h5 {
width:100%;
float:none;
text-align:left;
padding:6px 0;
font-size: 18px;
line-height: 24px;
}
.blog_details .details-box .heading-right h5 a{
	color: #999;
	transition: all 0.3s ease-in-out;
	display: inline-block;
	margin-right: 5px;
}
.blog_details .details-box .heading-right h5 a:hover{
	color: #fff;
}

.blog_details .details-box p {
color:#939393;
font-size:15px;
padding:12px 0 0;
}

.blog_details .blog_content_top span.red-text {
	color:#cda53a;
}
.blog_details .details-box h5 {
font-size:15px;
color:#d9d9d9;
}
.details-box {
	width: 100%;
}
.blog_details .bottom-gap-top {
padding-top:30px
}

.blog_details .right-prt ul li {
float:left;
background:url(~@/assets/icon-arrow.png) no-repeat 0 6px;
padding-left:16px;
padding-right:15px;
color:#fff;
font-size:13px
}

.blog_details .gap-top {
padding-top:25px
}

.blog_details .follow {
color:#fff
}

.blog_details .follow img {
border:solid 1px #242424;
margin:20px 0 0
}

.blog_details .left-part .left-part {
float:left;
width:40%
}

.blog_details .right-part-sec .right-part {
width:50%;
float:right
}


@media only screen and (min-width:240px) and (max-width:1024px) {
.blog_details .left-part{
    width: 100%;
}
.blog_details .right-part-sec{
    width: 100%;
}
}
 @media(max-width:740px){
.blog_details .heading-left{ display:block;}
.blog_details .details-box, .blog_details .details-box .heading-right{ float:none;}
.blog_details .details-box{ padding:20px; word-wrap:break-word;}
.content iframe{ width:100%;}
.blog_details .details-box h3{ font-size:22px; line-height:26px;}
 }

</style>

<style>
.blog_details .details-box .content p {
	color:#888 !important;
	font-size:15px !important;
	line-height: 24px !important;
	padding:20px 0 0 !important;
	font-weight: 300 !important;
}
</style>