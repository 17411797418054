<template>
  <Navigation />
  <Banner class="blog_banner" bg="podcast-banner.jpg">
    <div class="inr_text blog">
      <img src="@/assets/podcast-heading.png" />
      <h3
        style="
          background: -webkit-linear-gradient(#ffba00, #50472e);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        "
      >
        Executive Protection Lifestyle
      </h3>
    </div>
  </Banner>
  <section class="main_podcast">
    <div class="container" id="container">
      <div class="podcast_category">
        <h2>Browse All Episodes</h2>
        <p style="padding: 10px 135px 20px 135px">
          The Executive Protection Lifestyle Podcast hosted by Byron Rodgers
          takes the brand to another level by educating security practitioners
          and opening the world of private security up to the public.
        </p>
        <div class="tabbtnn_sec">
          <ul>
            <li>
              <button
                @click="clickActiveTab('Taball')"
                :class="{ active: activeTab === 'Taball' }"
              >
                All
              </button>
            </li>
            <li v-bind:key="category.id" v-for="category in categorys">
              <button
                @click="clickActiveTab(category.id)"
                :class="{ active: activeTab === category.id }"
              >
                {{ category.name }}
              </button>
            </li>
            <!-- <li><button @click="activeTab = 'Taball'" :class="{active: activeTab === 'Taball'}">All</button></li>
						<li v-bind:key="category.id" v-for="category in categorys">
							<button @click="activeTab = category.name" :class="{active: activeTab === category.name}">{{category.name}}</button>
						</li> -->
          </ul>
        </div>
        <div class="tab_content">
          <PodcastCard
            :callback="clickToGetPodcastDetails"
            v-bind:key="podcast.id"
            v-for="podcast in podcasts"
            :podcastData="podcast"
          />
        </div>
      </div>
      <div class="pagination">
        <pagination :data="laravelData" @pagination-change-page="getResults">
          <a
            v-if="current > 1"
            href="javascript:void(0);"
            class="read_more previous"
            @click="prev()"
            >Previous</a
          >
          <a
            v-if="totalpage > current"
            href="javascript:void(0);"
            class="read_more next"
            @click="next()"
            >Next</a
          >
        </pagination>
      </div>
    </div>
  </section>
  <!-- <section class="blog_pro">
		<div class="container">
			<div class="blo_pro_right">
				<img src="@/assets/blog-pro.png">
				<p>Join the league of executive protection specialists and become 1% of the executive protection industry. </p>
				<a href="javascript:void(0);" class="read_more">Previous</a>
			</div>
		</div>
	</section> -->
  <Buttomads />
  <Footer />
  <!-- <div class="modal" v-show="modalLoginMembership">
    <div class="content_wrapper">
      <div class="modal_header">
        <h2>
          You have to <router-link to="/login" class="">Sign in</router-link> or
          <router-link to="/signup" class="">Signup</router-link> then you can
          see the details of Podcasts !
        </h2>
        
      </div>
      <div class="modal_body">
        <button class="sign_submit" style="float: right" @click="close">
          Cancel
        </button>
      </div>
    </div>
  </div> -->
  <div class="cust-modal" v-show="modalLoginMembership">
    <div class="modal-content">
        <h3>To go to details page please</h3>
        <div class="wk-btn">
          <button><router-link to="/login" >sign in</router-link></button>
            or  
            <button><router-link to="/signup" class="" style="color:black;">sign up</router-link></button>
        </div>
    </div>
    <div class="ftr">
        <button @click="close"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" class="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg></button>
    </div>
</div>
</template>


<script>
import axios from "axios";
import { API_BASE_URL } from "../config";
import { API_IMAGE_PATH } from "../config";
import Banner from "../components/Banner.vue";
import PodcastCard from "../components/PodcastCard.vue";
// import TabA from '@/components/TabA.vue'
// import TabB from '@/components/TabB.vue'
// import TabC from '@/components/TabC.vue'
//import Taball from '@/components/Taball.vue'
import Navigation from "../components/Navigation.vue";
import Buttomads from "../components/Buttomads.vue";
import Footer from "../components/Footer.vue";
import { createLocal } from "the-storages";
const mirror = createLocal();
const storage = mirror._prx;
export default {
  name: "Podcast",
  components: {
    Banner,
    PodcastCard,
    Navigation,
    Buttomads,
    Footer,
  },
  data() {
    return {
      activeTab: "Taball",
      categorys: {},
      podcasts: {},
      laravelData: {},
      current: 1,
      totalpage: 1,
      pageSize: 4,
      storageData: mirror,
      storage: storage,
      modalLoginMembership: false,
    };
  },
  created() {
    //this.imagepath=API_IMAGE_PATH;
    this.userId = mirror.AUTHID;
    //this.userType=mirror.USERTYPE;
    //console.log(this.imagepath);
  },
  mounted() {
    // Fetch initial results
    this.getResults();
  },
  methods: {
    clickActiveTab(tabName) {
      this.activeTab = tabName;
      this.getResults();
    },
    prev() {
      this.current--;
      this.getResults();
      var element = document.getElementById("container");
      element.scrollIntoView();
    },
    next() {
      this.current++;
      this.getResults();
      var element = document.getElementById("container");
      element.scrollIntoView();
    },
    async getResults() {
      await axios.get(API_BASE_URL + "podcastsCategory").then((response) => {
        console.log("CATEGORY LIST:", response.data);
        this.laravelData = response.data;
        this.categorys = response.data;
        this.imagepath = API_IMAGE_PATH;
        this.isLoading = false;
      });
      await axios
        .post(API_BASE_URL + "podcastsList?page=" + this.current, {
          category: this.activeTab,
        })
        .then((response) => {
          console.log("PODCAST LIST:", response.data);
          this.laravelData = response.data;
          this.podcasts = response.data.data;
          this.totalpage = response.data.last_page;
          this.imagepath = API_IMAGE_PATH;
          this.isLoading = false;
        });
    },
    async clickToGetPodcastDetails(slugs) {
      //alert(slug);

      if (this.userId) {
        var param = {
          slugs: slugs,
          userId: mirror.AUTHID,
        };
        await axios
          .post(API_BASE_URL + "podcastUserCounter", param)
          .then((response) => {
            console.log("DETAILS:", response.data);
            this.$router.push({
              name: "PodcastDetails",
              params: { slug: slugs },
            });
          });
        //this.$router. push('/jobDetails/'+slug);
        //this.$router.push({name:'PodcastDetails', params: {slug: slugs}});
      } else {
        this.modalLoginMembership = true;
      }
      //alert("HII");
      //this.$router. push('/jobDetails/'+slug);
    },
    close: function () {
      this.modalLoginMembership = false;
    },
  },
};
</script>
<style scoped>
.cust-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 200;
    padding: 0 30px;
    width: fit-content;
    transform: translate(-50%, -50%);
}
.cust-modal::after{
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    z-index: -1;
    transform: translate(-50%, -50%);
    height: 100vh;
    background: #000;
    opacity: 79%;
}
.cust-modal .modal-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px 30px;
}
.cust-modal .modal-content h3{
    font-size: 18px;
    font-weight: 300;
    flex-shrink: 0;
}
.cust-modal .modal-content .wk-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.cust-modal .modal-content .wk-btn button{
    color: #fff;
    padding: 7px 23px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 2px;
    transition: all .3s;
    flex-shrink: 0;
    text-transform: uppercase;
}
.cust-modal .modal-content .wk-btn button:first-child{
    border: 1px solid #fff;
    background: none;
}
.cust-modal .modal-content .wk-btn button:first-child:hover{
    background: #AC8F3E;
    border: none;
    color: #000;
    border: 1px solid #AC8F3E;
}
.cust-modal .modal-content .wk-btn button:last-child{
    background: #AC8F3E;
    border: none;
    color: #000;
}
.cust-modal .modal-content{
    background: #212123;
    padding: 18px 30px;
    border-radius: 5px;
    
}
.cust-modal .ftr{
    position: absolute;
    right: 15px;
    top: -15px;
    display: flex;
}
.cust-modal .ftr button{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    border-radius: 50%;
    cursor: pointer;
    border: 5px solid #030303;
}

@media(max-width:599px){
    .cust-modal .modal-content h3{
        flex-shrink: unset;
        text-align: center;
    }
    .cust-modal .modal-content .wk-btn{
        flex-direction: column;
        width: 100%;
    }
    .chat-vr-slider>.item{
        margin-bottom: 12px;
    }
}
</style>
<style scoped>
.main_podcast {
  background: #0d0e0f;
  padding: 60px 0;
  float: left;
  width: 100%;
}

.podcast_category {
  text-align: center;
  margin: 20px 0;
  margin-bottom: 50px;
}

.podcast_category h2 {
  color: #cfcfcf;
  font-size: 42px;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 25px;
}

.podcast_category ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.podcast_category ul li {
  margin: 0 5px;
}

.podcast_category ul li a,
.podcast_category ul li button {
  padding: 9px 12px;
  color: #fff;
  border: solid 1px #565657;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  background: none;
  line-height: 20px;
  cursor: pointer;
}

.podcast_category ul li a:hover,
.podcast_category ul li button:hover,
.podcast_category ul li a.active,
.podcast_category ul li button.active {
  color: #fff;
  background: #ca0606;
  border-color: #ca0606;
}

.read_more {
  color: #939393;
  border: solid 1px #939393;
  padding: 7px 25px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  font-size: 16px;
}

.read_more:hover {
  background: #cfcfcf;
  color: #000;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 20px;
}

.pagination a.next {
  color: #d70b0b;
  border-color: #d70b0b;
  margin-left: 10px;
}

.pagination a.next:hover {
  background: #d70b0b;
  color: #fff;
}

.blog_pro {
  width: 100%;
  float: left;
  padding: 50px 0;
  background-image: url("~@/assets/blog-pro-bg.jpg");
  background-repeat: no-repeat !important;
  background-position: left top !important;
  background-size: cover !important;
}

.blo_pro_right {
  width: 40%;
  float: right;
  padding-right: 30px;
  box-sizing: border-box;
}

.blo_pro_right img {
  max-width: 600px;
  width: 100%;
}
.blo_pro_right p {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  margin: 10px 0;
  line-height: 24px;
}

@media only screen and (min-width: 768px) and (max-width: 1360px) {
  .blog_pro {
    background-position: -160px top !important;
  }
}

@media only screen and (min-width: 240px) and (max-width: 767px) {
  .main_podcast {
    padding: 0;
  }
  .podcast_category h2 {
    font-size: 24px;
  }
  .podcast_category p {
    padding: 0 0 20px 0 !important;
  }
  .podcast_category ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .podcast_category ul li {
    margin: 0;
    width: 100%;
  }

  .podcast_category ul li a,
  .podcast_category ul li button {
    width: 100%;
    margin-bottom: 5px;
  }
  .podcast_box {
    width: 100%;
    height: 300px;
    background: #0d0e0f;
    padding: 10px 0;
    float: left;
  }
  .blog_pro {
    background-position: -160px top !important;
  }
  .pagination {
    justify-content: center;
    padding: 10px;
  }
  .pagination {
    margin-top: 0;
  }
  .blog_pro {
    padding: 30px 0;
  }
  .blo_pro_right {
    width: 100%;
    padding: 20px;
  }
  .podcast_box_content {
    top: 10%;
  }
  .podcast_box_content_left {
    width: 100%;
  }
  .podcast_box_content_left h3 {
    font-size: 15px;
  }
  .podcast_box_content_left h4 {
    font-size: 12px;
    margin: 0;
  }
  .blo_pro_right img {
  max-width: 600px;
  width: 50%;
  }
  .blo_pro_right p {
    font-size: 12px;
    margin: 8px 0;
    line-height: 20px;
  }
  .read_more {
    margin-top: 10px;
}
}
</style>